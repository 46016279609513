<!-- Normal post -->
@if (postEnv == 0) {
  <article class="normal-post-wrapper">
    <div class="postTopWrapper">
      <div class="postTopWrapperSub1">
        <a
          data-testid="posttemplate-userimage-anchor"
          class="subtle-button"
          routerLink="/user/{{ post.userID }}"
          role="link"
          attr.aria-label="Open profile of user {{ post.username }}"
        >
          <img
            data-testid="posttemplate-userimage"
            [src]="post.profileImage$ | async | imgScaleHelper: 50"
            alt="{{ post.username }} photo"
            class="profile-image userImage"
            onerror="src='/assets/default_profile_pic.jpg';onerror='';"
            loading="lazy"
          />
        </a>
        <div>
          <app-usernametemplate
            data-testid="posttemplate-username-wrapper"
            [values]="{ userID: post.userID }"
          />
          <div class="postTopWrapperSub2">
            <div class="post-desc-text">
              @if (post.pinned) {
                <img
                  src="/assets/pin_filled.png"
                  class="dark-icon repostImg"
                  alt="pin icon"
                  loading="lazy"
                />
                <span class="bold">Pinned</span>
                <span class="simple_divider">•</span>
              }
            </div>
            @if (post.rePostID && post.rePostID.length > 0) {
              <a
                class="subtle-button post-desc-text"
                routerLink="/p/{{ post.rePostID }}"
                role="link"
                aria-label="Go to original post"
              >
                <img
                  src="/assets/ic_repost.png"
                  class="dark-icon repostImg"
                  alt="repost icon"
                  loading="lazy"
                />
                Reposted
              </a>
            }
            @if (post.rePostID) {
              <span class="simple_divider">•</span>
            }
            @if (post.location) {
              <a
                data-testid="posttemplate-location-anchor"
                class="subtle-button post-desc-text"
                routerLink="/location/{{
                  encodingService.encodeForUrlArgument(post.location)
                }}"
              >
                <img
                  src="/assets/ic_location.png"
                  class="dark-icon repostImg"
                  alt="location icon"
                  loading="lazy"
                  role="link"
                  attr.aria-label="Open location {{ post.location }}"
                />
                {{ post.location }}
              </a>
            }
            @if (post.location) {
              <span class="simple_divider">•</span>
            }
            <a
              data-testid="posttemplate-timeformat-anchor"
              class="subtle-button post-desc-text"
              routerLink="/p/{{ post.postID }}"
              role="link"
              attr.aria-label="Post on {{ strHlp.getAppName() }} from {{
                post.username
              }}"
              attr.aria-describedby="{{ post.caption }} on {{
                strHlp.getAppName()
              }} from user {{ post.username }}"
            >
              {{ post.timestamp | timeformat }}
            </a>
            @if (containsMultipleMedia) {
              <span class="simple_divider">•</span>
            }
            @if (containsMultipleMedia) {
              <img
                data-testid="posttemplate-multiplemedia-image"
                src="/assets/ic_double_picture.png"
                class="dark-icon repostImg"
                alt="double image collection icon"
                loading="lazy"
              />
            }
            @if (post.wasEdited) {
              <span class="simple_divider">•</span>
            }
            @if (post.wasEdited) {
              <span
                class="post-desc-text"
                data-testid="posttemplate-edited-text"
                >Edited</span
              >
            }
            @if (post.isAd) {
              <span class="simple_divider">•</span>
            }
            @if (post.isAd) {
              <span
                class="post-desc-text"
                data-testid="posttemplate-sponsored-text"
                >Sponsored</span
              >
            }
          </div>
        </div>
      </div>
      <div class="margin-left-auto">
        <button
          data-testid="posttemplate-menu-button"
          class="subtle-button post-action-wrapper menu-button"
          mat-button
          [matMenuTriggerFor]="menu"
          #menuTrigger
          aria-label="options"
          matTooltip="Options"
          [matTooltipShowDelay]="1000"
        >
          <img
            class="dark-icon post-options-button dimmed-icon-strong"
            src="/assets/menu.png"
            alt="options icon"
            loading="lazy"
          />
        </button>
      </div>
      <mat-menu
        class="standard-options-menu bottom-sheet-matmenu-wrapper"
        #menu="matMenu"
        backdropClass="black-backdrop-light"
      >
        <div appBottomSheetDialogContainer>
          <div
            class="standard-options-dialog-item"
            (click)="copyLinkPost(post.postID)"
          >
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img"
                src="/assets/ic_set_copy.png"
                alt="copy icon"
                loading="lazy"
              />
            </span>
            <span class="options-text"> Copy link </span>
          </div>
          @if (post.userID === userID) {
            <div class="standard-options-dialog-item" (click)="pinPost()">
              <span class="options-img-wrap">
                <img
                  class="dark-icon options-img"
                  src="/assets/ic_pin.png"
                  alt="pin icon"
                  loading="lazy"
                />
              </span>
              <span class="options-text"> Pin post </span>
            </div>
          }
          @if (post.imagePath.length > 0) {
            <div
              class="standard-options-dialog-item"
              (click)="openFullscreen()"
            >
              <span class="options-img-wrap">
                <img
                  class="dark-icon options-img"
                  src="/assets/ic_expand.png"
                  alt="expand icon"
                  loading="lazy"
                />
              </span>
              <span class="options-text"> Fullscreen </span>
            </div>
          }
          @if (post.imagePath.length > 0) {
            <div
              class="standard-options-dialog-item"
              (click)="downloadImage(imgHlp.do(post.imagePath, 1100))"
            >
              <span class="options-img-wrap">
                <img
                  class="dark-icon options-img"
                  src="/assets/ic_download_2.png"
                  alt="download icon"
                  loading="lazy"
                />
              </span>
              <span class="options-text"> Save image </span>
            </div>
          }
          @if (post.vid) {
            <div
              class="standard-options-dialog-item"
              (click)="downloadVideo(post.vid)"
            >
              <span class="options-img-wrap">
                <img
                  class="dark-icon options-img"
                  src="/assets/ic_download_2.png"
                  alt="download icon"
                  loading="lazy"
                />
              </span>
              <span class="options-text"> Save video </span>
            </div>
          }
          @if (post.caption) {
            <div
              class="standard-options-dialog-item"
              (click)="copyText(post.caption)"
            >
              <span class="options-img-wrap">
                <img
                  class="dark-icon options-img"
                  src="/assets/ic_copy.png"
                  alt="copy icon"
                  loading="lazy"
                />
              </span>
              <span class="options-text"> Copy text </span>
            </div>
          }
          @if (post.userID === userID) {
            <div
              class="standard-options-dialog-item"
              (click)="startEditingPost(post)"
            >
              <span class="options-img-wrap">
                <img
                  class="dark-icon options-img"
                  src="/assets/ic_set_edit_cut.png"
                  alt="edit icon"
                  loading="lazy"
                />
              </span>
              <span class="options-text"> Edit post </span>
            </div>
          }
          @if (post.userID === userID) {
            <div class="standard-options-dialog-item" (click)="deletePost()">
              <span class="options-img-wrap">
                <img
                  class="dark-icon options-img"
                  src="/assets/ic_set_delete.png"
                  alt="delete icon"
                  loading="lazy"
                />
              </span>
              <span class="options-text"> Delete post </span>
            </div>
          }
          @if (
            !hideMuteButton &&
            post.userID !== userID &&
            post.userID !== 'nwnqQ1LieaaofxvtAg9ojOBRIeD2'
          ) {
            <div
              class="standard-options-dialog-item"
              (click)="showMuteDialog(post.userID)"
            >
              <span class="options-img-wrap">
                <img
                  class="dark-icon options-img"
                  src="/assets/bottomsheet_ic_block.png"
                  alt="mute icon"
                  loading="lazy"
                />
              </span>
              <span class="options-text"> Mute user </span>
            </div>
          }
          @if (post.userID !== userID) {
            <div
              class="standard-options-dialog-item"
              (click)="reportPost(post.postID)"
            >
              <span class="options-img-wrap">
                <img
                  class="dark-icon options-img"
                  src="/assets/ic_set_bug.png"
                  alt="report icon"
                  loading="lazy"
                />
              </span>
              <span class="options-text"> Report </span>
            </div>
          }
          @if (
            strHlp.getAllowsNSFW() &&
            (post.userID === userID || dataService.areYouMod)
          ) {
            <div
              class="standard-options-dialog-item alert-red-bg"
              (click)="markAsNSFW()"
            >
              <span class="options-img-wrap">
                <img
                  class="dark-icon options-img"
                  src="/assets/ic_alert7.png"
                  alt="report icon"
                  loading="lazy"
                />
              </span>
              <span class="options-text"> Mark as NSFW </span>
            </div>
          }
          <app-bottomsheet-bottom-branding-spacer *appShowOnlyOnMobile />
        </div>
      </mat-menu>
    </div>
    @if (post.caption) {
      <div class="postCaption">
        <span
          data-testid="posttemplate-caption-span"
          #captionEl
          class="allow-user-selection"
          [ngClass]="{
            postCaptionCollapsed: captionLineCount != -1 && captionCollapsed,
            postCaptionExpanded: captionLineCount != -1 && !captionCollapsed
          }"
          [innerHTML]="
            post
              ? htmlFormattingService.applyTextFormatting(
                  htmlFormattingService.applyAll(post.caption)
                )
              : ''
          "
        >
        </span>
        @if (captionExceedsLineCount && captionCollapsed) {
          <button
            class="subtle-button seeMoreButton"
            (click)="captionCollapsed = false"
          >
            <b>See more...</b>
          </button>
        }
        @if (post.isAd && post.isSystemAd && post.showMonthlyDonationProgress) {
          <app-donations-progress-bar
            [progressPercentage]="post.monthlyDonationProgressPercent"
            (click)="openAdLink()"
            [useAsButton]="true"
          />
        }
        @if (post.showButton) {
          <button
            class="subtle-button ad-action-button"
            [ngClass]="{ 'red-ad-action-button': post.redButton }"
            (click)="openAdLink()"
          >
            {{ post.buttonText }}
            <img
              src="/assets/ic_arrow_right.png"
              class="light-icon learn-more-ad-icon"
              alt="learn more icon"
              loading="lazy"
            />
          </button>
        }
      </div>
    }
    @if (!containsMultipleMedia && post.imagePath) {
      <div
        data-testid="posttemplate-media-wrapper"
        class="mediaWrapper"
        id="mediaWrapper"
        #mediaWrapper
        (onSingleTap)="onSingleTap($event, 1)"
        (onDoubleTap)="onDoubleTap($event)"
      >
        <img
          #postImage
          height="{{ post.h ? post.h : 500 }}"
          width="{{ post.w ? post.w : 500 }}"
          alt="{{ post.username }} on {{ strHlp.getAppName() }}: {{
            post.caption
          }}"
          class="postImage postImageActual"
          src="{{ post.imagePath | imgScaleHelper: 1100 }}"
          onerror="src='/assets/broken_file.png';onerror='';"
        />
        <!-- For double click like animation (heart on image) -->
        @if (post.imagePath) {
          <div id="heart" class="pos-relative"></div>
        }
      </div>
    }
    @if (containsMultipleMedia && post.imagePath) {
      <div class="mediaWrapper" #mediaWrapper id="mediaWrapper">
        <swiper-container
          #swiperRef
          class="swiper grey-bg"
          pagination-bullet="true"
          pagination-fraction="true"
          [navigation]="!isMobile"
        >
          <!-- No lazy loading on first for better UX !! -->
          @if (post.imagePath) {
            <swiper-slide
              class="swiper-slide"
              (onSingleTap)="onSingleTap($event, 1)"
              (onDoubleTap)="onDoubleTap($event)"
            >
              <img
                height="{{ post.h ? post.h : 500 }}"
                width="{{ post.w ? post.w : 500 }}"
                alt="{{ post.username }} on {{ strHlp.getAppName() }}: {{
                  post.caption
                }}"
                class="postImage postImageActual"
                src="{{ post.imagePath | imgScaleHelper: 1100 }}"
                onerror="src='/assets/broken_file.png';onerror='';"
              />
            </swiper-slide>
          }
          @if (post.image2) {
            <swiper-slide
              lazy="true"
              class="swiper-slide"
              (onSingleTap)="onSingleTap($event, 2)"
              (onDoubleTap)="onDoubleTap($event)"
            >
              <img
                alt="{{ post.username }} on {{ strHlp.getAppName() }}: {{
                  post.caption
                }} second image"
                class="postImage postImageActual"
                src="{{ post.image2 | imgScaleHelper: 1100 }}"
                onerror="src='/assets/broken_file.png';onerror='';"
                loading="lazy"
              />
            </swiper-slide>
          }
          @if (post.image3) {
            <swiper-slide
              lazy="true"
              class="swiper-slide"
              (onSingleTap)="onSingleTap($event, 3)"
              (onDoubleTap)="onDoubleTap($event)"
            >
              <img
                alt="{{ post.username }} on {{ strHlp.getAppName() }}: {{
                  post.caption
                }} third image"
                class="postImage postImageActual"
                src="{{ post.image3 | imgScaleHelper: 1100 }}"
                onerror="src='/assets/broken_file.png';onerror='';"
                loading="lazy"
              />
            </swiper-slide>
          }
          @if (post.image4) {
            <swiper-slide
              lazy="true"
              class="swiper-slide"
              (onSingleTap)="onSingleTap($event, 4)"
              (onDoubleTap)="onDoubleTap($event)"
            >
              <img
                alt="{{ post.username }} on {{ strHlp.getAppName() }}: {{
                  post.caption
                }} fourth image"
                class="postImage postImageActual"
                src="{{ post.image4 | imgScaleHelper: 1100 }}"
                onerror="src='/assets/broken_file.png';onerror='';"
                loading="lazy"
              />
            </swiper-slide>
          }
          @if (post.image5) {
            <swiper-slide
              lazy="true"
              class="swiper-slide"
              (onSingleTap)="onSingleTap($event, 5)"
              (onDoubleTap)="onDoubleTap($event)"
            >
              <img
                alt="{{ post.username }} on {{ strHlp.getAppName() }}: {{
                  post.caption
                }} fifth image"
                class="postImage postImageActual"
                src="{{ post.image5 | imgScaleHelper: 1100 }}"
                onerror="src='/assets/broken_file.png';onerror='';"
                loading="lazy"
              />
            </swiper-slide>
          }
        </swiper-container>
        <!-- For double click like animation (heart on image) -->
        @if (post.imagePath) {
          <div id="heart" class="pos-relative"></div>
        }
      </div>
    }
    <!--
      video thumbnail
      -->
    @if (post.vid) {
      <div class="imageContainer">
        <div class="video-thumbnail-wrapper" (click)="openVideoPlayer()">
          <img
            class="thumbnail-image"
            #imgEl
            src="{{ thumbnail }}"
            onerror="this.src='/assets/video_colored.png';this.onerror='';"
            alt="video thumbnail"
            loading="lazy"
          />
          <div class="video-hint-play-icon-wrapper">
            <img
              src="assets/ic_play.png"
              alt="play video icon"
              class="light-icon video-hint-play-icon"
              loading="lazy"
            />
          </div>
        </div>
        <div class="timestamp-on-image">
          @if (post.vidDuration) {
            <img
              src="assets/ic_play.png"
              alt="play video icon"
              class="light-icon msg-bottom-bar-icon"
              loading="lazy"
            />
          }
          @if (post.vidDuration) {
            <span class="msg-bottom-bar-text">{{ vidDurationString }}</span>
          }
        </div>
      </div>
    }
    @if (post.videoPath && post.videoPath.length > 0) {
      <div class="video-not-supported-hint">
        Video is no longer available since it was not posted with the web app
      </div>
    }
    <div class="postBottomWrapper">
      <div class="postActionButtonsWrapper">
        <!-- Like button -->
        <button
          data-testid="posttemplate-like-button"
          class="subtle-button post-action-wrapper"
          (click)="doLikeAction(false)"
          aria-label="Like"
          matTooltip="Like"
          [matTooltipShowDelay]="1000"
        >
          <img
            class="post-action-icon"
            [ngClass]="{ 'dark-icon dimmed-icon': !post.interaction_isLiked }"
            [src]="
              post.interaction_isLiked
                ? '/assets/ic_heart_like_filled.png'
                : '/assets/ic_heart_regular.png'
            "
            alt="Like"
            loading="lazy"
          />
          <div
            class="post-action-text"
            [ngClass]="{ 'liked-color': post.interaction_isLiked }"
          >
            {{ post.likeCount | numberCommaFormat }}
          </div>
        </button>
        <!-- Comment button -->
        <button
          data-testid="posttemplate-comment-button"
          class="subtle-button post-action-wrapper"
          (click)="openComments()"
          aria-label="Comments"
          matTooltip="Comments"
          [matTooltipShowDelay]="1000"
        >
          <img
            class="dark-icon post-action-icon dimmed-icon"
            src="/assets/ic_comment_2.png"
            alt="Comments"
            loading="lazy"
          />
          <div class="post-action-text">
            {{ post.commentCount | numberCommaFormat }}
          </div>
        </button>
        <!-- Bookmark button -->
        <button
          data-testid="posttemplate-bookmark-button"
          class="subtle-button post-action-wrapper"
          (click)="doBookmarkAction()"
          aria-label="Bookmark"
          matTooltip="Bookmark"
          [matTooltipShowDelay]="1000"
        >
          <img
            class="dark-icon post-action-icon"
            [ngClass]="{ 'dimmed-icon': !post.interaction_isBookmarked }"
            [src]="
              post.interaction_isBookmarked
                ? '/assets/ic_bookmark_filled.png'
                : '/assets/ic_bookmark_2.png'
            "
            alt="Bookmark"
            loading="lazy"
          />
          <div class="post-action-text">
            {{
              (post.bookmarkCount ? post.bookmarkCount : 0) | numberCommaFormat
            }}
          </div>
        </button>
        <!-- Share button -->
        <button
          data-testid="posttemplate-share-button"
          class="subtle-button post-action-wrapper"
          (click)="sharePost()"
          aria-label="Share"
          matTooltip="Share"
          [matTooltipShowDelay]="1000"
        >
          <img
            class="dark-icon post-action-icon dimmed-icon"
            src="/assets/ic_share_send.png"
            alt="Share"
            loading="lazy"
          />
          <div class="post-action-text">
            {{ post.shareCount | numberCommaFormat }}
          </div>
        </button>
      </div>
    </div>
  </article>
}

<!-- Clips post -->
@if (postEnv == 1) {
  <article class="clip-post-wrapper clip-item-height">
    <div
      class="clip-post-side-bar"
      [ngStyle]="{ visibility: showPostControls ? 'visible' : 'hidden' }"
    >
      <!-- Like button -->
      <button
        class="subtle-button clip-post-action-wrapper"
        (click)="doLikeAction(false)"
        aria-label="Like"
        matTooltip="Like"
        [matTooltipShowDelay]="1000"
      >
        <img
          class="light-action-icon clip-post-action-icon"
          [ngClass]="{ 'light-icon dimmed-icon': !post.interaction_isLiked }"
          [src]="
            post.interaction_isLiked
              ? '/assets/ic_heart_like_filled.png'
              : '/assets/ic_heart_like_filled_black.png'
          "
          alt="Like"
          loading="lazy"
        />
        <div class="post-action-text clip-post-action-text clips-text-shadow">
          {{ post.likeCount | numberCommaFormat }}
        </div>
      </button>
      <!-- Comment button -->
      <button
        class="subtle-button clip-post-action-wrapper"
        (click)="openComments()"
        aria-label="Comments"
        matTooltip="Comments"
        [matTooltipShowDelay]="1000"
      >
        <img
          class="light-icon dimmed-icon clip-post-action-icon"
          src="/assets/ic_comment2_filled.png"
          alt="Comments"
          loading="lazy"
        />
        <div class="post-action-text clip-post-action-text clips-text-shadow">
          {{ post.commentCount | numberCommaFormat }}
        </div>
      </button>
      <!-- Bookmark button -->
      <button
        class="subtle-button clip-post-action-wrapper"
        (click)="doBookmarkAction()"
        aria-label="Bookmark"
        matTooltip="Bookmark"
        [matTooltipShowDelay]="1000"
      >
        <img
          class="clip-post-action-icon"
          [ngClass]="{ 'light-icon': !post.interaction_isBookmarked }"
          src="{{
            post.interaction_isBookmarked
              ? '/assets/ic_bookmark_filled_blue.png'
              : '/assets/ic_bookmark_filled.png'
          }}"
          alt="Bookmark"
          loading="lazy"
        />
        <div class="post-action-text clip-post-action-text clips-text-shadow">
          {{
            (post.bookmarkCount ? post.bookmarkCount : 0) | numberCommaFormat
          }}
        </div>
      </button>
      <!-- Share button -->
      <button
        class="subtle-button clip-post-action-wrapper"
        (click)="sharePost()"
        aria-label="Share"
        matTooltip="Share"
        [matTooltipShowDelay]="1000"
      >
        <img
          class="light-icon dimmed-icon clip-post-action-icon"
          src="/assets/ic_reply_filled.png"
          alt="Share"
          loading="lazy"
        />
        <div class="post-action-text clip-post-action-text clips-text-shadow">
          {{ post.shareCount | numberCommaFormat }}
        </div>
      </button>
      <!-- Options button -->
      <button
        class="subtle-button clip-post-action-wrapper"
        mat-button
        [matMenuTriggerFor]="menu"
        #menuTrigger
        aria-label="options"
        matTooltip="Options"
        [matTooltipShowDelay]="1000"
      >
        <img
          class="light-icon dimmed-icon clip-post-action-icon transform-90deg"
          src="/assets/menu.png"
          alt="menu icon three dots"
          loading="lazy"
        />
      </button>
    </div>
    <div
      class="clip-post-bottom-info"
      [ngStyle]="{ visibility: showPostControls ? 'visible' : 'hidden' }"
    >
      <a
        class="subtle-button"
        routerLink="/user/{{ post.userID }}"
        role="link"
        attr.aria-label="Open profile of user {{ post.username }}"
      >
        <img
          [src]="post.profileImage$ | async | imgScaleHelper: 50"
          alt="profile photo"
          class="profile-image userImage-clips"
          onerror="src='/assets/default_profile_pic.jpg';onerror='';"
          loading="lazy"
        />
      </a>
      <div class="clips-bottom-info-second-wrapper">
        <div class="clip-username-wrapper">
          <app-usernametemplate
            [values]="{
              userID: post.userID,
              whiteText: true,
              textShadow: true
            }"
            class="clips-text-shadow"
          />
        </div>
        @if (post.caption) {
          <div class="clip-postCaption">
            <span
              #captionEl
              class="allow-user-selection clips-postCaption clips-text-shadow"
              (click)="openComments()"
              [innerHTML]="captionInnerHTML"
            >
            </span>
          </div>
        }
        <mat-menu
          class="standard-options-menu bottom-sheet-matmenu-wrapper"
          #menu="matMenu"
          backdropClass="black-backdrop-light"
        >
          <div appBottomSheetDialogContainer>
            <div
              class="standard-options-dialog-item"
              (click)="copyLinkPost(post.postID)"
            >
              <span class="options-img-wrap">
                <img
                  class="dark-icon options-img"
                  src="/assets/ic_set_copy.png"
                  alt="copy icon"
                  loading="lazy"
                />
              </span>
              <span class="options-text"> Copy link </span>
            </div>
            @if (post.vid) {
              <div
                class="standard-options-dialog-item"
                (click)="downloadVideo(post.vid)"
              >
                <span class="options-img-wrap">
                  <img
                    class="dark-icon options-img"
                    src="/assets/ic_download_2.png"
                    alt="download icon"
                    loading="lazy"
                  />
                </span>
                <span class="options-text"> Save video </span>
              </div>
            }
            @if (
              !hideMuteButton &&
              post.userID !== userID &&
              post.userID !== 'nwnqQ1LieaaofxvtAg9ojOBRIeD2'
            ) {
              <div
                class="standard-options-dialog-item"
                (click)="showMuteDialog(post.userID)"
              >
                <span class="options-img-wrap">
                  <img
                    class="dark-icon options-img"
                    src="/assets/bottomsheet_ic_block.png"
                    alt="mute icon"
                    loading="lazy"
                  />
                </span>
                <span class="options-text"> Mute user </span>
              </div>
            }
            @if (post.userID !== userID) {
              <div
                class="standard-options-dialog-item"
                (click)="reportPost(post.postID)"
              >
                <span class="options-img-wrap">
                  <img
                    class="dark-icon options-img"
                    src="/assets/ic_set_bug.png"
                    alt="report icon"
                    loading="lazy"
                  />
                </span>
                <span class="options-text"> Report </span>
              </div>
            }
            <app-bottomsheet-bottom-branding-spacer *appShowOnlyOnMobile />
          </div>
        </mat-menu>
      </div>
    </div>
    <!-- Custom player for clips -->
    <app-clipplayer
      [videoID]="post.vid"
      [postID]="post.postID"
      [postID]="post.postID"
      [indexInList]="indexInList"
      [doubleTapCallback]="doubleTapVideoCallback"
      [shouldShowControlsType1]="shouldShowControlsType1"
      [startingTimeSecs]="startingTimeSecs"
    />
    <!-- For double click like animation (heart on image) -->
    <div id="heart" class="pos-absolute clip-video-heart" #heart></div>
  </article>
}
