<div class="clips-tab-wrapper clip-item-height">
  <header class="header">
    <!-- Show back button only if not opened via nav, e.g. by clicking on a normal video
        <div id="side-button-bar" *ngIf="startingPost">
            <div class="side-button">
                <img src="/assets/arrow_back.png" 
                    class="side-button-img" 
                    (click)="goBack()"
                    alt="close">
            </div>
        </div>
        -->

    <!--
        <h1 class="title text-line-in-header swipe-up-hint" *ngIf="showSwipeUpHint">
            <img class="swipe-up-icon light-icon" src="/assets/swipe_up_gesture.png" alt="hand with finger swiping up">
            {{isMobile ? "Swipe up for next video" : "Use mouse wheel to see the next video"}}
        </h1>

            {{isMobile ? "Swipe up for next video" : "Use mouse wheel to see the next video"}}
        -->

    <h1
      class="branding-left-top-wrapper title appname-font text-line-in-header swipe-up-hint"
    >
      <img
        src="assets/applogo_transparent.png"
        class="branding-logo"
        alt="{{ seoHelper.getTitleForClips(isNSFW) }} logo"
      />
      {{ seoHelper.getTitleForClips(isNSFW) }}
    </h1>

    @if (showNSFW) {
      <a
        class="subtle-button"
        role="link"
        aria-label="Open Hentai TikTok"
        [routerLink]="isNSFW ? '/clips' : '/hentai-tiktok'"
        (click)="swapNSFW()"
      >
        <h2
          class="title text-line-in-header swipe-up-hint nsfw-button"
          [ngClass]="{ nsfwActivated: isNSFW }"
        >
          NSFW
        </h2>
      </a>
    }

    <div class="manual-swipe-button" (click)="programmaticallySwipe(false)">
      <img
        src="assets/ic_arro_down_scroll.png"
        class="light-icon rotate-180 manual-swipe-button-icon"
      />
    </div>

    <div class="manual-swipe-button" (click)="programmaticallySwipe(true)">
      <img
        src="assets/ic_arro_down_scroll.png"
        class="light-icon manual-swipe-button-icon"
      />
    </div>
  </header>

  <div class="overlay-on-main">
    <main
      class="content-wrapper clip-item-height"
      #clipContentWrapper
      (touchstart)="onTouchStart($event)"
      (touchend)="onTouchEnd($event)"
    >
      @if (feedDataService.postList.length == 0) {
        @if (emptyPosts) {
          <app-emptyhinttemplate class="loading-hint" />
        } @else {
          <app-loadingcircle class="loading-hint" />
        }
      }

      @for (
        item of feedDataService.postList;
        track item.postID;
        let i = $index
      ) {
        <post-template
          class="clip-item clip-item-height"
          [post]="{
            post: item,
            indexInList: i,
            postEnv: 1,
            startingTimeSecs: i == 0 ? startingTimeSecs : 0
          }"
        />
      }
    </main>
  </div>
</div>
