import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-number-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './number-badge.component.html',
  styleUrl: './number-badge.component.css'
})
export class NumberBadgeComponent {
  @Input()
  number!: number

  /**
   * red or blue
   */
  @Input()
  isBlueInput: boolean = true

  /**
   * If true, will show an empty box if number is 0
   */
  @Input()
  showEvenIfNull: boolean = false

  /**
   * Used to customize/adjust position to specific "host" element (projection element)
   */
  @Input()
  topPx: number = 0

  /**
   * Used to customize/adjust position to specific host element (projection element)
   */
  @Input()
  rightPx: number = 0

  get isBlue() {
    return this.isBlueInput
  }

  get isShowNumber() {
    return this.showEvenIfNull || this.number
  }

  get shownNumber() {
    return this.number ?? ' '
  }
}
