import { Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser'
import { HotToastService } from '@ngneat/hot-toast'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { LoadingDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component'
import { loadStripe } from '@stripe/stripe-js'
import { PremiumComponent } from '../../premium/premium/premium.component'
import { MainComponent } from '../../main/main.component'
import { DonationamountchooseComponent } from '../../dialogs/donationamountchoose/donationamountchoose.component'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-donationsinfo',
  templateUrl: './donationsinfo.component.html',
  styleUrls: [
    './donationsinfo.component.css',
    '../../premium/premium/premium.component.css'
  ]
})
export class DonationsinfoComponent {
  percentGoalReached = 0

  showRecurringOption = AuthService.isUserLoggedIn()

  constructor(
    private toast: HotToastService,
    public strHlp: StrHlp,
    private dialog: MatDialog,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.loadDonationGoalReached()

    this.seoHelper.setForSomePage(
      'Donations',
      'Help us stay online with a donation. You can find all information about donations here.'
    )
  }

  loadDonationGoalReached() {
    const functions = getFunctions()
    const functionToCall = httpsCallable(
      functions,
      'getPercentage_MonthlyDonationGoalReached'
    )
    functionToCall({})
      .then((result) => {
        this.percentGoalReached = result.data as number
      })
      .catch((error) => {
        console.log(error)
      })
  }

  askForAmount() {
    const data = {
      callback: (amount: number) => {
        this.initiateCheckout(amount, true)
      }
    }

    this.dialog.open(DonationamountchooseComponent, {
      data: data
    })
  }

  async initiateCheckout(amount: number, recurring = false) {
    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    try {
      const functions = getFunctions()
      const createCheckoutSession = httpsCallable(
        functions,
        'createCheckoutSessionStripe'
      )

      createCheckoutSession({
        hubname: StrHlp.CLOUD_PATH,
        isDonation: true,
        isDonationRecurring: recurring,
        isPremiumYearly: false,
        amount: amount
      })
        .then(async (response) => {
          loadingDialogRef.close()

          const data = response.data as { sessionId: string }
          const sessionId = data.sessionId

          const stripe = await loadStripe(PremiumComponent.stripe_key)

          const result = await stripe!.redirectToCheckout({
            sessionId: sessionId
          })

          if (result.error) {
            this.toast.error('Error occurred')
            console.error(result.error)
          }
        })
        .catch((error) => {
          loadingDialogRef.close()
          console.log(error)
          this.toast.error('Error occurred')
        })
    } catch (error) {
      loadingDialogRef.close()
      this.toast.error('Error occurred')
      console.error(error)
    }
  }
}
