<div class="wrapper">
  <div id="header-comments" class="universal-padding">
    <div id="header-left-wrapper">
      @if (isReplies) {
        <app-backbuttontemplate [disableAction]="true" matBottomSheetClose />
      }

      <h2>
        <strong>{{ titleString }}</strong>
      </h2>

      @if (
        (!isReplies && commentCount > 0) || (isReplies && repliesCount > 0)
      ) {
        <div class="count-showing-div-wrapper count-wrapper">
          <div class="count-showing-div-text">
            {{
              isReplies
                ? repliesCount > 0
                  ? repliesCount
                  : ''
                : commentCount > 0
                  ? commentCount
                  : ''
            }}
          </div>
        </div>
      }
    </div>

    @if (showCancelButtonAtTop) {
      <app-closebutton [historyBack]="false" matBottomSheetClose />
    }
  </div>

  <div class="comment-content-wrapper universal-padding">
    @if (contentPreviewString !== '') {
      <div id="contentPreview">
        <!--
        <div id="contentPreviewTitle">
          {{contentPreviewTitle}}
        </div>
        -->
        <div
          id="contentPreviewText"
          [innerHTML]="
            post ? htmlFormattingService.applyAll(contentPreviewString) : ''
          "
        ></div>
      </div>
    }

    @for (comment of itemList; track comment; let i = $index) {
      <div id="listWrapper">
        <app-commenttemplate
          [comment]="comment"
          [itemList]="itemList"
          [isReplies]="isReplies"
          [originalCommentID]="originalCommentID"
          [post]="post"
          [replyClickCallback]="replyClickCallback"
          [indexInList]="i"
        >
        </app-commenttemplate>
      </div>
    }

    @if (!empty_comments && itemList.length == 0) {
      <app-loadingcircle> </app-loadingcircle>
    }

    @if (empty_comments) {
      <app-emptyhinttemplate [text]="emptyHintString"> </app-emptyhinttemplate>
    }
  </div>

  @if (authService.isLoggedIn()) {
    <div class="addCommentSection universal-padding">
      <hr class="inline-divider" />
      @if (!areYouBlocked) {
        <div class="input-wrapper">
          <img
            class="dark-icon side-button"
            id="emojiButton"
            (click)="emojiButtonClick()"
            src="{{
              showEmojiPicker
                ? '/assets/ic_keyboard.png'
                : '/assets/ic_emoji_button.png'
            }}"
            alt="pick emoji icon"
          />
          <textarea
            #textareainput
            placeholder="{{ inputPlaceHolder }}"
            maxlength="1000"
            [(ngModel)]="commentEntered"
            [formControl]="textareaControl"
            (keydown.enter)="onEnter()"
          >
          </textarea>
          @if (commentEntered.trim() == '') {
            <div id="gifButtonWrapper" (click)="gifButtonClick()">
              <img
                class="dark-icon side-button"
                id="gifButtonImage"
                src="{{
                  showGifsPicker ? '/assets/ic_keyboard.png' : '/assets/gif.png'
                }}"
                alt="pick gif icon"
              />
            </div>
          }
          @if (commentEntered.trim()) {
            <div id="gifButtonWrapper" (click)="postComment()">
              <img
                class="dark-icon side-button"
                id="gifButtonImage"
                src="/assets/ic_send_message.png"
                alt="send message icon"
              />
            </div>
          }
        </div>
      }
      <hr class="inline-divider" />
      <!--
      <div id="buttonsWrapper" *ngIf="(areYouBlocked_Determined && !areYouBlocked) && commentEntered.length > 0">
        <button class="subtle-button actionButton"
          id="cancelButton"
          (click)="cancelEntering()">
          Cancel
        </button>
        <button class="subtle-button actionButton"
          id="commentButton"
          (click)="postComment()">
          Comment
        </button>
      </div>
      -->
      @if (areYouBlocked) {
        <p id="blockedHint">
          You cannot comment because you are blocked by this user
        </p>
      }
    </div>
  }
</div>
