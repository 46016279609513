import { Injectable } from '@angular/core'
import { child, get, getDatabase, ref } from 'firebase/database'
import { StrHlp } from '../StringGetter/getstring.service'
import { TimeformatPipe } from 'src/app/pipes/timeformat.pipe'

@Injectable({
  providedIn: 'root'
})
export class LastseenService {
  constructor(private timeFormat: TimeformatPipe) {}

  /**
   * isOnlineAlreadyDet:
   * 0 = No.
   * 1 = Yes, the user is online.
   * 2 = Yes, the user is offline.
   *
   * Used for an always-listener for the on/off/lastseen string.
   */
  async loadLastSeenString(
    userID: string,
    isOnlineAlreadyDet: number = 0
  ): Promise<string> {
    const rtdb = getDatabase()
    let skipOnOffCheck = false

    if (isOnlineAlreadyDet == 1) {
      return 'Online'
    } else if (isOnlineAlreadyDet == 2) {
      // user is offline
      skipOnOffCheck = true
    }

    if (!skipOnOffCheck) {
      // Determine here
      const snapShotOnline = await get(
        ref(rtdb, `${StrHlp.CLOUD_PATH}/onlinestatus/${userID}`)
      )

      let isOnline = false
      if (snapShotOnline.exists()) {
        isOnline = snapShotOnline.val()
      }

      if (isOnline) {
        return 'Online'
      }
    }

    // User is offline
    // load privacy setting of user
    const dbPath = `${StrHlp.CLOUD_PATH}/UserSettings/${userID}/showLastSeen`
    const snapshotShowLastSeen = await get(ref(rtdb, dbPath))

    let showLastSeen = true
    if (snapshotShowLastSeen.exists()) {
      showLastSeen = snapshotShowLastSeen.val()
    }

    if (showLastSeen) {
      // Load last seen
      const snapshotLastSeen = await get(
        ref(rtdb, `${StrHlp.CLOUD_PATH}/lastseen/${userID}`)
      )

      if (snapshotLastSeen.exists()) {
        const stamp = snapshotLastSeen.val()
        return `Last seen ${(this.timeFormat.transform(stamp, 0) as string).toLowerCase()}`
      } else {
        // just show: "offline"
        return 'Offline'
      }
    } else {
      // just show: "offline"
      return 'Offline'
    }
  }
}
