import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class StringUsus {
  static CompareTo_Java(t1: string, t2: string): number {
    const len1 = t1.length
    const len2 = t2.length
    const lim = Math.min(t1.length, t2.length)

    const v1 = t1
    const v2 = t2

    let k = 0
    while (k < lim) {
      const c1 = v1[k]
      const c2 = v2[k]
      if (c1 != c2) {
        if (c1.charCodeAt(0) == 32) {
          const charWithSpace = c1.charCodeAt(0) + c2.charCodeAt(0)
          return charWithSpace
        } else {
          return c1.charCodeAt(0) - c2.charCodeAt(0)
        }
      }
      k++
    }
    return len1 - len2
  }
}
