import { Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser'
import { HotToastService } from '@ngneat/hot-toast'
import { get, getDatabase, ref, remove } from 'firebase/database'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { loadStripe } from '@stripe/stripe-js'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { LoadingDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['./premium.component.css', '../../sign-in/sign-in.component.css']
})
export class PremiumComponent {
  // PROD
  public static stripe_key =
    'pk_live_51MSI4lGWrHvGRwmY8UvjmckQwdhn9m7OxrdOe223JRt587Knn7wH9vGwJJMzPhJ8y9I5yUuDHtSFJSqSJWctwcp90076M1ONm4'
  // --

  // TEST
  //public static stripe_key = 'pk_test_51MSI4lGWrHvGRwmY24v6C8V4ZU8k1F8y1bbWAnA6K6WNPcPOmpFIfk1M4vMgppLNVFWoMVtsYLiZ8cwmzdLGGFCN00pygDxIYR';
  // --

  userID: any = null
  dbRTDB = getDatabase()

  static premiumDetermined: boolean = false
  static isPremium: boolean = false
  static premiumLastsUntil: number = 0
  static premiumLastsUntil_AsString: string = '-'

  getPremiumDetermined(): boolean {
    return PremiumComponent.premiumDetermined
  }
  getIsPremium(): boolean {
    return PremiumComponent.isPremium
  }

  getPremiumLastsUntilAsString(): string {
    return PremiumComponent.premiumLastsUntil_AsString
  }

  constructor(
    public authService: AuthService,
    private toast: HotToastService,
    public strHlp: StrHlp,
    private dialog: MatDialog,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.userID = AuthService.getUID()

    this.seoHelper.setForSomePage('Premium', 'Go premium. Go viral.')
  }

  async initiateCheckout(isPremiumYearly: boolean) {
    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    try {
      const functions = getFunctions()
      const createCheckoutSession = httpsCallable(
        functions,
        'createCheckoutSessionStripe'
      )

      createCheckoutSession({
        hubname: StrHlp.CLOUD_PATH,
        isDonation: false,
        isDonationRecurring: false,
        isPremiumYearly: isPremiumYearly
      })
        .then(async (response) => {
          loadingDialogRef.close()

          const data = response.data as { sessionId: string }
          const sessionId = data.sessionId

          const stripe = await loadStripe(PremiumComponent.stripe_key)

          const result = await stripe!.redirectToCheckout({
            sessionId: sessionId
          })

          if (result.error) {
            this.toast.error('Error occurred')
            console.error(result.error)
          }
        })
        .catch((error) => {
          loadingDialogRef.close()
          console.log(error)
          this.toast.error('Error occurred')
        })
    } catch (error) {
      loadingDialogRef.close()
      this.toast.error('Error occurred')
      console.error(error)
    }
  }

  static loadPremium(userID: string): void {
    if (PremiumComponent.premiumDetermined) {
      return
    }

    const dbRTDB = getDatabase()
    get(ref(dbRTDB, `${StrHlp.CLOUD_PATH}/verifiedusers/${userID}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const val = snapshot.val()
          if (val + '' === 'true') {
            // user has life long premium
            PremiumComponent.isPremium = true
            PremiumComponent.premiumDetermined = true

            PremiumComponent.premiumLastsUntil_AsString = 'never'
          } else {
            // check if expired
            const curr = Date.now()
            if (val < curr) {
              // expired
              PremiumComponent.isPremium = false
              PremiumComponent.premiumDetermined = true

              // remove blue check for user
              remove(
                ref(dbRTDB, `${StrHlp.CLOUD_PATH}/verifiedusers/${userID}`)
              )
            } else {
              // premium is still lasting
              PremiumComponent.isPremium = true
              PremiumComponent.premiumDetermined = true

              PremiumComponent.premiumLastsUntil = val

              const date = new Date(val)
              PremiumComponent.premiumLastsUntil_AsString = date.toDateString()
            }
          }
        } else {
          // not premium
          PremiumComponent.isPremium = false
          PremiumComponent.premiumDetermined = true
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
