import { Component, Input, OnInit } from '@angular/core'
import { RouterModule } from '@angular/router'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { ChoosingBarTextItemComponent } from '../choosing-bar-text-item/choosing-bar-text-item.component'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { Observable, of } from 'rxjs'
import { CommonModule } from '@angular/common'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { ImgScaleHelperPipe } from 'src/app/pipes/img-scale-helper.pipe'

@Component({
  selector: 'app-feed-choosing-bar',
  standalone: true,
  imports: [
    RouterModule,
    ChoosingBarTextItemComponent,
    CommonModule,
    ImgScaleHelperPipe
  ],
  templateUrl: './feed-choosing-bar.component.html',
  styleUrls: [
    './feed-choosing-bar.component.css',
    '../../../stylesheets/feed-choosing-bar.css'
  ]
})
export class FeedChoosingBarComponent implements OnInit {
  @Input()
  selectedFeed!: number

  @Input()
  isScrollable = false

  isLoggedIn
  myUid: string | null
  allowsNSFW = StrHlp.ALLOWS_NSFW

  profileImg$?: Observable<string> = of('/assets/default_profile_pic.jpg')
  myUsername$?: Observable<string>

  constructor(
    private authService: AuthService,
    private cacheService: CacheService
  ) {
    this.isLoggedIn = this.authService.isLoggedIn()
    this.myUid = this.authService.getUserID()
  }

  ngOnInit(): void {
    if (this.isLoggedIn) {
      this.profileImg$ = this.cacheService.getProfileImage(this.myUid!)
      this.myUsername$ = this.cacheService.getUsername(this.myUid!)
    }
  }
}
