<div class="choosing-bar">
  <app-choosing-bar-text-item
    class="choosing-bar-child"
    [isSelected]="selectedMode == 0"
    text="Top"
    [handleClickInput]="onSelectTopMode"
    ariaLabel="Top results"
  />

  <app-choosing-bar-text-item
    class="choosing-bar-child"
    [isSelected]="selectedMode == 1"
    text="Users"
    [handleClickInput]="onSelectUsersMode"
    ariaLabel="User results"
  />

  <app-choosing-bar-text-item
    class="choosing-bar-child"
    [isSelected]="selectedMode == 2"
    text="Tags"
    [handleClickInput]="onSelectTagsMode"
    ariaLabel="Tag results"
  />

  <app-choosing-bar-text-item
    class="choosing-bar-child"
    [isSelected]="selectedMode == 3"
    text="Locations"
    [handleClickInput]="onSelectLocationsMode"
    ariaLabel="Location results"
  />
</div>
