import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { RouterModule } from '@angular/router'

@Component({
  selector: 'app-choosing-bar-text-item',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './choosing-bar-text-item.component.html',
  styleUrl: './choosing-bar-text-item.component.css'
})
export class ChoosingBarTextItemComponent {
  @Input({ required: true })
  isSelected!: boolean

  @Input({ required: true })
  text!: string

  @Input()
  link?: string

  @Input({ required: true })
  ariaLabel!: string

  @Input()
  handleClickInput?: () => void

  @Input()
  showInk: boolean = true

  handleClick() {
    if (this.handleClickInput) {
      this.handleClickInput()
    }
  }
}
