<mat-dialog-content>
  <h2 mat-dialog-title>Choose amount</h2>
  <div class="button-container">
    <button mat-button mat-dialog-close (click)="donate(5)">5€</button>
    <button mat-button mat-dialog-close (click)="donate(10)">$10</button>
    <button mat-button mat-dialog-close (click)="donate(20)">$20</button>
    <button mat-button mat-dialog-close (click)="donate(50)">$50</button>
    <button mat-button mat-dialog-close (click)="donate(100)">$100</button>
  </div>
</mat-dialog-content>
