import { Injectable } from '@angular/core'
import { TransferStateHelperService } from '../ssr/transfer-state-helper.service'
import { GLOBAL_CHAT_ID } from '../../constants'
import { StrHlp } from '../StringGetter/getstring.service'
import { get, getDatabase, ref } from 'firebase/database'
import { GroupInfo } from './GroupInfo'
import { lastValueFrom } from 'rxjs'
import { CacheService } from '../caching/cache-service.service'
import { isGlobalChat } from './GroupInfoHelper'
import {
  GroupCreatedTimestampInitValue,
  GroupDescEmptyString,
  GroupDescInitValue
} from './GroupConstants'

@Injectable({
  providedIn: 'root'
})
export class GroupLoadingService {
  private rtdb = getDatabase()

  constructor(
    private tsHelper: TransferStateHelperService,
    private cacheService: CacheService
  ) {}

  public async load(
    groupIdInput: string,
    linkIdInput: string
  ): Promise<GroupInfo | null> {
    const groupInfo = new GroupInfo()
    try {
      // determine groupId and sync with transferstate, maybe even get it from there
      let groupId: string | null = null

      // try transferstate first
      const tsgroupId = this.tsHelper.getFromTransferState<string | null>(
        'groupID',
        null
      )
      if (tsgroupId !== null) {
        groupId = tsgroupId
      } else {
        // no trasferstate, try input
        if (groupIdInput !== '') {
          groupId = groupIdInput
        } else {
          // no groupId input. try getting it from the link
          groupId = await this.loadGroupIdFromLinkId(linkIdInput)
        }
      }

      // if it wasnt successful the inputs do not constitute a valid group
      if (groupId === null) {
        return null
      } else {
        groupInfo.groupId = groupId
        this.tsHelper.addTransferState<string>('groupID', groupId)
      }

      // --

      // check if is global chat
      const isGroupGlobalChat = isGlobalChat(groupIdInput)
      if (isGroupGlobalChat) {
        groupIdInput = GLOBAL_CHAT_ID
      }

      if (!isGroupGlobalChat) {
        // IMPORANT:
        // every group has a created timestamp. We use this info
        // as a check whether or not the groupID exists (is valid).
        // timestamp
        groupInfo.createdTimestamp = this.tsHelper.getFromTransferState<number>(
          'createdTimestamp',
          GroupCreatedTimestampInitValue
        )

        // fetch from server if no transfer state was given
        if (groupInfo.createdTimestamp === GroupCreatedTimestampInitValue) {
          const createdPath = `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupIdInput}/Infos/CreatedTimestamp`
          const createdSnap = await get(ref(this.rtdb, createdPath))

          if (createdSnap.exists()) {
            groupInfo.createdTimestamp = createdSnap.val()
            this.tsHelper.addTransferState<number>(
              'createdTimestamp',
              groupInfo.createdTimestamp
            )
          } else {
            // groupId is not valid, return null
            return null
          }
        }
      }

      // valid groupID, continue loading other info

      groupInfo.desc = await this.loadDescription(groupId)
      groupInfo.membersCount = await this.loadMembersCount(groupId)

      groupInfo.image = this.tsHelper.getFromTransferState<string>('image', '')
      if (groupInfo.image === '') {
        groupInfo.image = await lastValueFrom(
          this.cacheService.getGroupImage(groupIdInput)
        )
        this.tsHelper.addTransferState<string>('image', groupInfo.image)
      }

      groupInfo.name = this.tsHelper.getFromTransferState<string>('name', '')
      if (groupInfo.name === '') {
        groupInfo.name = await lastValueFrom(
          this.cacheService.getGroupName(groupIdInput)
        )
        this.tsHelper.addTransferState<string>('name', groupInfo.name)
      }

      groupInfo.messagesCount = this.tsHelper.getFromTransferState<number>(
        'messagesCount',
        0
      )
      if (groupInfo.messagesCount === 0) {
        groupInfo.messagesCount = await lastValueFrom(
          this.cacheService.getGroupMessageCount(groupIdInput)
        )
        this.tsHelper.addTransferState<number>(
          'messagesCount',
          groupInfo.messagesCount
        )
      }
      return groupInfo
    } catch (e) {
      console.error(e)
      return null
    }
  }

  private async loadGroupIdFromLinkId(linkIdParam: string) {
    const path = `${StrHlp.CLOUD_PATH}/GroupInviteLinks/${linkIdParam}/groupID`
    const groupIdSnap = await get(ref(this.rtdb, path))

    if (groupIdSnap.exists()) {
      return groupIdSnap.val()
    }

    return null
  }

  private async loadDescription(groupId: string): Promise<string> {
    // attempt transferstate first
    const tsDesc = this.tsHelper.getFromTransferState<string>(
      'desc',
      GroupDescInitValue
    )
    if (tsDesc !== GroupDescInitValue) {
      return tsDesc
    } else {
      // no transferstate
      const isGroupGlobalChat = isGlobalChat(groupId)
      if (isGroupGlobalChat) {
        const desc = `The biggest chat room for the ${StrHlp.COMMUNITY_NAME} community. Most active globally`
        return desc
      } else {
        // Not global chat, fetch data
        const descSnap = await get(
          ref(
            this.rtdb,
            `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupId}/Infos/Beschreibung`
          )
        )
        const desc = descSnap.exists() ? descSnap.val() : GroupDescEmptyString
        this.tsHelper.addTransferState<string>('desc', desc)
        return desc
      }
    }
  }

  private async loadMembersCount(groupID: string): Promise<number> {
    const tsMembersCount = this.tsHelper.getFromTransferState<number>(
      'membersCount',
      0
    )
    if (tsMembersCount !== 0) {
      return tsMembersCount
    } else {
      // no transferstate
      if (isGlobalChat(groupID)) {
        this.tsHelper.addTransferState<number>('membersCount', 0)
        return 0
      } else {
        const membersSnap = await get(
          ref(
            this.rtdb,
            `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Infos/memberCount`
          )
        )
        const membersCount = membersSnap.exists() ? membersSnap.val() : 0
        this.tsHelper.addTransferState<number>('membersCount', membersCount)
        return membersCount
      }
    }
  }
}
