import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})

/**
 * we treat errors as not-allowed
 */
export class ImageLoadingService {
  private readonly cdnBasePath = 'https://odhub.b-cdn.net/'
  private readonly dimensions = [50, 90, 150, 250, 420, 500, 750, 1100]

  constructor() {}

  /**
   * Gets CDN path, and gets specified dimension (resize plugin in cloud)
   *
   * @param pathRaw
   */
  do(pathRaw: string, dim: number): string {
    // special case check
    if (pathRaw.includes('/assets')) {
      return pathRaw
    }

    if (this.wasResizePlugInstalled(pathRaw)) {
      const splitBy = '?alt='
      const arr = pathRaw.split(splitBy)
      let base = arr[0]
      const end = arr[1]

      base = base.replaceAll(/_[0-9]{2,}x[0-9]{2,}$/g, '')

      let appendToBase = ''
      if (this.dimensions.includes(dim)) {
        appendToBase = `_${dim}x${dim}`
      } else {
        appendToBase = '_250x250' // default
      }

      return this.getCDN_URL(base + appendToBase + splitBy + end)
    } else {
      // no scaling available for this medium
      return this.getCDN_URL(pathRaw)
    }
  }

  private wasResizePlugInstalled(inp: string) {
    const key = inp.split('?alt')[0]
    return key.replaceAll(/_[0-9]{2,}x[0-9]{2,}$/g, '').endsWith('_rsz_')
  }

  getCDN_URL(inp: string) {
    if (inp.startsWith(this.cdnBasePath)) {
      return inp
    } else if (inp === '') {
      return inp
    } else {
      return this.cdnBasePath.concat(inp.substring(66))
    }
  }
}
