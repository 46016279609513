<div id="container">
  <main id="container-content">
    @if (!redirect) {
      <div id="header">
        <app-branding></app-branding>
      </div>
    }

    <!--
    <app-backbuttontemplate
      *ngIf="redirect"
      [showText]="true"
      [background]="true">
    </app-backbuttontemplate>
    -->

    @if (redirect) {
      <h1 class="redirectHint">
        Login or register to continue.
      </h1>
    }

    <p class="divider-hint">Continue</p>

    <button
      class="action-button red-button"
      mat-dialog-close
      (click)="goToRegister()"
      >
      Create new account
    </button>

    <button
      class="action-button action-button-active"
      mat-dialog-close
      (click)="goToLogin()"
      >
      Log in to account
    </button>

    <p class="divider-hint">Or</p>

    <button
      class="action-button action-button-active"
      (click)="continueWithGoogle()"
      >
      <img
        class="auth-method-icon"
        src="/assets/ic_google.png"
        alt="Google logo"
        />
      Continue with Google
    </button>

    <!--
    <button class="action-button action-button-active" (click)="continueWithGoogle()">
      <img class="dark-icon auth-method-icon" src="/assets/ic_apple_logo.png">
      Continue with Apple
    </button>
    -->

    <span class="rech_links">
      <a class="rech_link" href="/imprint">Imprint</a>
      <a class="rech_link" href="/privacy">Privacy</a>
      <a class="rech_link" href="/terms">Terms</a>
    </span>
  </main>
</div>
