<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Starting page settings</h1>
  </header>

  <main class="fh-main content">
    <p>
      You have the option to choose the starting page for your app. The default
      is set to "home". However, you can change this according to your
      preference.
    </p>

    <fieldset id="group2">
      <label class="radio" (click)="update('1')">
        <input
          type="radio"
          name="group2"
          [checked]="startPage == '1' || startPage == '0'"
        />

        <span class="radio-text">Home/Clips (shows the last opened one)</span>
      </label>

      <label class="radio" (click)="update('2')">
        <input
          class="radiobutton"
          type="radio"
          name="group2"
          [checked]="startPage == '2'"
        />

        <span class="radio-text">Explore</span>
      </label>

      <label class="radio" (click)="update('3')">
        <input
          class="radiobutton"
          type="radio"
          name="group2"
          [checked]="startPage == '3'"
        />

        <span class="radio-text">Chats</span>
      </label>
    </fieldset>
  </main>
</div>
