import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { HotToastService } from '@ngneat/hot-toast'
import { StrHlp } from '../../../shared/services/StringGetter/getstring.service'
import { deleteUser } from 'firebase/auth'
import firebase from 'firebase/compat/app'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { LoadingDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-deleteaccount',
  templateUrl: './deleteaccount.component.html',
  styleUrls: [
    './deleteaccount.component.css',
    '../settingspage/settings.component.css',
    '../../sign-in/sign-in.component.css'
  ]
})
export class DeleteaccountComponent implements OnInit {
  userID: any = null
  user: any = null
  currentEmail: string = ''

  passwordEntered: string = ''
  reasonEntered: string = ''
  passwordVisible: boolean = false
  deleteReason: string = ''

  @ViewChild('password') password!: ElementRef
  @ViewChild('deleteReasonError')
  deleteReasonError!: ElementRef
  @ViewChild('continueButton') continueButton!: ElementRef
  @ViewChild('deleteButton') deleteButton!: ElementRef
  @ViewChild('showpassword') showpassword!: ElementRef
  @ViewChild('showAfterContinue')
  showAfterContinue!: ElementRef
  @ViewChild('passwordInvalidError')
  passwordInvalidError!: ElementRef

  constructor(
    private toast: HotToastService,
    public authService: AuthService,
    public dialog: MatDialog,
    private twobuttonsdialogService: TwobuttonsdialogService,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('Delete account', 'Delete account settings')

    this.userID = AuthService.getUID()
    this.user = AuthService.getUser()

    this.currentEmail = this.user.email
  }

  changePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible

    if (this.passwordVisible) {
      this.showpassword.nativeElement.innerText = 'Hide password'
      this.password.nativeElement.type = 'text'
    } else {
      this.showpassword.nativeElement.innerText = 'Show password'
      this.password.nativeElement.type = 'password'
    }
  }

  continue(): void {
    // ask if user is sure

    this.twobuttonsdialogService.show(
      'Delete Account',
      'Are you sure you want to delete your account? You cannot undo this.',
      () => {
        // nothing
      },
      () => {
        this.showAfterContinue.nativeElement.style.display = 'block'
        this.continueButton.nativeElement.style.display = 'none'
      },
      'Cancel',
      'Delete'
    )
  }

  deleteAccount(): void {
    // init without errors
    this.passwordInvalidError.nativeElement.style.display = 'none'
    this.deleteReasonError.nativeElement.style.display = 'none'

    // check if input is valid
    if (!this.passwordEntered.length) {
      this.passwordInvalidError.nativeElement.innerText =
        'Enter your password to continue'
      this.passwordInvalidError.nativeElement.style.display = 'block'
      return
    }
    if (this.passwordEntered.length < 6) {
      this.passwordInvalidError.nativeElement.innerText = 'Password is wrong'
      this.passwordInvalidError.nativeElement.style.display = 'block'
      return
    }

    if (!this.reasonEntered.length) {
      this.deleteReasonError.nativeElement.innerText =
        'Enter a reason to continue'
      this.deleteReasonError.nativeElement.style.display = 'block'
      return
    }
    if (this.reasonEntered.length < 20) {
      this.deleteReasonError.nativeElement.innerText = 'Text is too short'
      this.deleteReasonError.nativeElement.style.display = 'block'
      return
    }

    this.deleteButton.nativeElement.innerText = 'Loading...'

    // first reauthenticate the user
    firebase
      .auth()
      .signInWithEmailAndPassword(this.currentEmail, this.passwordEntered)
      .then((userCredential) => {
        const currUser = userCredential.user
        if (currUser !== null) {
          this.deleteAccount_Actual(currUser)
        } else {
          this.toast.error('An error has occurred. Email was not updated')
        }
      })
      .catch((error) => {
        this.toast.error(error.message)
        this.toast.error(
          'Logging in to your account failed. Likely the password you entered is wrong'
        )
      })
  }

  deleteAccount_Actual(currUser: any): void {
    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    // call cloud function
    const functions = getFunctions()
    const deleteAccountLogic = httpsCallable(functions, 'deleteAccountLogic')

    deleteAccountLogic({
      hubname: StrHlp.CLOUD_PATH,
      deleteReasonString: this.reasonEntered
    })
      .then((result) => {
        // profile deleted

        // delete the account now
        deleteUser(currUser)
          .then(() => {
            loadingDialogRef.close()

            // account was deleted
            this.toast.show('Account was deleted')
            this.authService.signOut()
          })
          .catch((error) => {
            loadingDialogRef.close()
            this.toast.error('An error occurred. Account was not deleted')
          })
      })
      .catch((error) => {
        console.log(error)
        this.toast.error('Failed')
      })
  }
}
