<nav class="main-side-div" role="navigation" aria-label="primary">
  <app-side-nav-item
    text="Home"
    ariaLabel="Home"
    link="/home/auto"
    [handleClickInput]="navClick_0"
    [isSelected]="selectedTab == 0"
    selectedImgSrc="/assets/nav_home_filled.png"
    notSelectedImgSrc="/assets/nav_home.png"
    imageAlt="Home"
  />

  <app-side-nav-item
    text="Clips"
    ariaLabel="Short videos"
    link="/clips"
    ariaLabel="Clips"
    [handleClickInput]="navClick_1"
    [isSelected]="selectedTab == 1"
    selectedImgSrc="/assets/clips_icon_filled.png"
    notSelectedImgSrc="/assets/clips_icon.png"
    imageAlt="Videos"
  />

  <app-side-nav-item
    text="Explore"
    ariaLabel="Explore"
    link="/explore"
    ariaLabel="Explore"
    imageAlt="Explore"
    [handleClickInput]="navClick_2"
    [isSelected]="selectedTab == 2"
    selectedImgSrc="/assets/navbar_compass_filled_black.png"
    notSelectedImgSrc="/assets/navbar_compass_unfilled.png"
  />

  <app-side-nav-item
    [text]="isLoggedIn ? 'Chats' : 'Groups'"
    ariaLabel="Chats"
    [link]="isLoggedIn ? '/chats' : '/groupchats'"
    [ariaLabel]="isLoggedIn ? 'Chats' : 'Groups'"
    [imageAlt]="isLoggedIn ? 'Chats' : 'Groups'"
    [handleClickInput]="navClick_3"
    [isSelected]="selectedTab == 3"
    selectedImgSrc="/assets/navbar_chat_selected_black.png"
    notSelectedImgSrc="/assets/navbar_chat_unselected.png"
    [badgeNumberInput]="maindataService.newChatsCount"
  />

  @if (showTrump) {
    <app-side-nav-item
      text="Trump"
      ariaLabel="Donald Trump profile"
      imageAlt="Donald J. Trump"
      link="/@donaldtrump"
      [isSelected]="selectedTab == 5"
      selectedImgSrc="/assets/trump_image.jpg"
      notSelectedImgSrc="/assets/trump_image.jpg"
      [isDarkIcon]="false"
      [isProfileImage]="true"
    />
  }

  <app-side-nav-item
    text="Notifications"
    ariaLabel="Notifications"
    imageAlt="Notifications"
    [handleClickInput]="navClick_4"
    [isSelected]="selectedTab == 4"
    selectedImgSrc="/assets/navbar_like_filled.png"
    notSelectedImgSrc="/assets/navbar_like_unfilled.png"
    [badgeNumberInput]="maindataService.newNotificationsCount"
  />

  <app-notificationdetailspreviewbox
    id="notificationdetailspreviewbox"
    *appHideAfter="8000"
    [newNotificationsCount]="maindataService.newNotificationsCount"
    [newLikeNotifCount]="maindataService.newLikeNotifCount"
    [newCommentsNotifCount]="maindataService.newCommentsNotifCount"
    [newFollowNotifCount]="maindataService.newFollowNotifCount"
    [newOtherNotifCount]="maindataService.newOtherNotifCount"
  />

  <!--
    <app-side-nav-item 
        text="Go Premium"
        ariaLabel="Go Premium"
        imageAlt="Verified"
        link="/settings/premium"
        [isSelected]="selectedTab==100"
        selectedImgSrc="/assets/ic_verified.png"
        notSelectedImgSrc="/assets/ic_verified.png"
    />
    -->

  <app-side-nav-item
    text="Bookmarks"
    ariaLabel="Bookmarks"
    imageAlt="Bookmarks"
    link="/saved/bookmarks"
    [handleClickInput]="navClick_7"
    [isSelected]="selectedTab == 7"
    selectedImgSrc="/assets/ic_bookmark_filled.png"
    notSelectedImgSrc="/assets/ic_bookmark.png"
  />

  <app-side-nav-item
    text="Liked"
    ariaLabel="Liked posts"
    imageAlt="Like"
    link="/saved/liked"
    [handleClickInput]="navClick_8"
    [isSelected]="selectedTab == 8"
    selectedImgSrc="/assets/ic_heart_like_filled.png"
    notSelectedImgSrc="/assets/ic_heart_like.png"
  />

  <app-side-nav-item
    text="Settings"
    ariaLabel="Settings"
    imageAlt="Settings"
    link="/settings"
    [handleClickInput]="navClick_9"
    [isSelected]="selectedTab == 9"
    selectedImgSrc="/assets/ic_settings2_filled.png"
    notSelectedImgSrc="/assets/ic_settings2.png"
  />

  @if (isLoggedIn && myUid) {
    <app-side-nav-item
      text="Profile"
      ariaLabel="Your profile"
      imageAlt="Avatar"
      link="/@{{ myUsername$ | async }}"
      [isSelected]="selectedTab == 5"
      [selectedImgSrc]="myProfileImage$ | async | imgScaleHelper: 50"
      [notSelectedImgSrc]="myProfileImage$ | async | imgScaleHelper: 50"
      [isDarkIcon]="false"
      [isProfileImage]="true"
    />
  } @else {
    <div
      (click)="openLogin()"
      class="subtle-button usual-button red-button login-button"
      mat-dialog-close
      dontServerRender
    >
      Login
    </div>
  }

  <a
    class="post-button-with-text"
    routerLink="post"
    role="link"
    aria-label="Make post"
  >
    <span class="text-post-button"> Make a post </span>
  </a>

  <a
    class="post-button-no-text"
    routerLink="post"
    role="link"
    aria-label="Make post"
  >
    <img
      src="{{
        isDarkmode ? '/assets/post_button_light.png' : '/assets/post_button.png'
      }}"
      class="post-button-icon-no-text"
      alt="Make post"
    />
  </a>

  <!--
    <p>
        Other
    </p>

    <button
        class="icon-button"
        id="settings-wrapper" 
        class="subtle-button" 
        (click)="toggleDarkmode()"
        aria-label="Darkmode"
        matTooltip="{{ isDarkmode ? 'Light theme' : 'Dark theme' }}"
        [matTooltipShowDelay]="1000"
    > 
        <img class="dark-icon icon-button"
            id="settings-icon"
            src="{{isDarkmode ? '/assets/ic_sun.png' : '/assets/ic_darkmode_moon.png'}}"
            alt="dark theme">    

    </button>
    -->
</nav>
