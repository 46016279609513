<a
  class="subtle-button explore-item-wrapper no-decor"
  routerLink="/discover/{{ searchQuery }}"
  role="link"
  attr.aria-label="{{ searchQuery }} discover"
>
  <div class="explore-item-header-text">🔎 {{ searchQuery }}</div>

  <div class="explore-item-desc-text">
    {{ count | numberCommaFormat }} {{ count == 1 ? 'search' : 'searches' }}
  </div>
</a>
