import { Component, Input } from '@angular/core'
import { RouterModule } from '@angular/router'
import { NumberBadgeComponent } from 'src/app/components/templates/number-badge/number-badge.component'

@Component({
  selector: 'app-footer-nav-item',
  standalone: true,
  imports: [RouterModule, NumberBadgeComponent],
  templateUrl: './footer-nav-item.component.html',
  styleUrl: './footer-nav-item.component.css'
})
export class FooterNavItemComponent {
  @Input()
  onClickInput!: () => void

  @Input()
  ariaLabel!: string

  @Input()
  link?: string

  @Input()
  isSelected!: boolean

  @Input()
  selectedImgSrc!: string

  @Input()
  notSelectedImgSrc!: string

  @Input()
  imageAlt!: string

  @Input()
  badgeNumberInput?: number

  get badgeNumber() {
    return this.badgeNumberInput ?? 0
  }
}
