import { Component, Input } from '@angular/core'
import { RouterModule } from '@angular/router'
import { NumberCommaFormatPipe } from 'src/app/pipes/number-comma-format.pipe'
import { EncodingService } from 'src/app/shared/services/encoding/encoding.service'

@Component({
  selector: 'app-explore-location-item',
  standalone: true,
  imports: [NumberCommaFormatPipe, RouterModule],
  templateUrl: './explore-location-item.component.html',
  styleUrls: ['./explore-location-item.component.css', '../explore.styles.css']
})
export class ExploreLocationItemComponent {
  @Input()
  location!: string

  @Input()
  countInput?: number

  constructor(private encodingService: EncodingService) {}

  get encodedLocation() {
    return this.encodingService.encodeForUrlArgument(this.location)
  }

  get count() {
    return this.countInput ?? 0
  }
}
