import { Component, Inject, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { AuthService } from '../../shared/services/auth/auth.service'
import { StrHlp } from '../../shared/services/StringGetter/getstring.service'
import { ActivatedRoute, Router } from '@angular/router'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css', '../user/user.component.css']
})
export class SignInComponent implements OnInit {
  redirect = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public authService: AuthService,
    public StrHlp: StrHlp,
    private route: ActivatedRoute,
    private router: Router,
    private seoHelper: SeoHelperService
  ) {
    this.redirect = data.redirectURL
  }

  ngOnInit(): void {
    this.seoHelper.setForSomePage(
      'Log in',
      `Log in or sign up for an account on ${StrHlp.APP_NAME}. ${this.seoHelper.getGenericDesc()}`
    )

    this.route.queryParams.subscribe((params) => {
      const redirectReceived = params['redirect']
      if (redirectReceived) {
        this.redirect = redirectReceived
      }
    })
  }

  goToLogin() {
    this.router.navigate(['/login/email'], {
      queryParams: { redirect: this.redirect }
    })
  }

  goToRegister() {
    this.router.navigate(['/signup'], {
      queryParams: { redirect: this.redirect }
    })
  }

  continueWithGoogle(): void {
    this.authService.continueWithGoogle(this.redirect)
  }
}
