<div
  class="wrapper group-info-wrapper"
  [ngClass]="{ 'fh-page-wrapper': isMobile }"
>
  <header
    class="group-header"
    [ngClass]="{
      'fh-header': isMobile,
      'bottom-border-divider': !scrollIsAtTop && isMobile
    }"
  >
    <app-backbuttontemplate />

    <div class="username-wrapper group-name-wrapper">
      <h1
        class="username-text"
        (longTap)="copyGroupname()"
        (click)="scrollTop()"
      >
        {{ groupInfo.name }}
      </h1>
    </div>

    <div class="options-wrapper" [appSpaceyIf]="isLoggedIn">
      <button
        class="subtle-button action-button-wrapper menu-button"
        aria-label="options"
        mat-button
        [matMenuTriggerFor]="menu"
        matTooltip="Options"
        [matTooltipShowDelay]="1000"
      >
        <img
          class="dark-icon standard-action-button menu-icon"
          src="/assets/menu.png"
          alt="options"
        />
      </button>
    </div>

    <mat-menu
      class="standard-options-menu bottom-sheet-matmenu-wrapper"
      #menu="matMenu"
      backdropClass="black-backdrop-light"
    >
      <div appBottomSheetDialogContainer>
        <div class="standard-options-dialog-item" (click)="report()">
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              alt="report"
              src="/assets/ic_set_bug.png"
            />
          </span>

          <span class="options-text"> Report </span>
        </div>

        <div class="standard-options-dialog-item" (click)="leaveGroupDialog()">
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              alt="leave group"
              src="/assets/ic_set_logout.png"
            />
          </span>
          <span class="options-text"> Leave group </span>
        </div>

        <app-bottomsheet-bottom-branding-spacer *appShowOnlyOnMobile />
      </div>
    </mat-menu>
  </header>

  <main #contentWrapper class="fh-main group-content">
    <section class="groupInfoWrapper1">
      <div id="profileImageWrapper">
        <img
          #profileImg
          alt="{{ groupInfo.name }} chat room image"
          class="profile-image-2 profileImage"
          [src]="groupInfo.image | imgScaleHelper: 250"
          onerror="src='/assets/default_group_pic.jpg';onerror='';"
          (click)="openImageFullscreen()"
        />
        <div id="last-seen-text">
          {{
            groupInfo.createdTimestamp == 0
              ? ''
              : 'Created ' + (groupInfo.createdTimestamp | timeformat)
          }}
        </div>
      </div>

      <div class="profileInfo">
        <div class="profileNumberBoxWrapper">
          @if (!isGlobalChat) {
            <button
              class="subtle-button profileNumberWrapper"
              (click)="showTotalMembersDialog()"
              aria-label="Members count"
            >
              <p class="profileNumberText">
                {{ groupInfo.membersCount | numberAbbr }}
              </p>
              <p class="profileNumberTextDesc">Members</p>
            </button>
          }

          <button
            class="subtle-button profileNumberWrapper"
            (click)="showTotalMessagesDialog()"
            aria-label="Messages count"
          >
            @if (isGlobalChat) {
              <p class="profileNumberText">
                {{ groupInfo.messagesCount | numberCommaFormat }}
              </p>
            } @else {
              <p class="profileNumberText">
                {{ groupInfo.messagesCount | numberAbbr }}
              </p>
            }

            <p class="profileNumberTextDesc">Messages</p>
          </button>
        </div>
      </div>
    </section>

    <hr class="inline-divider2" />

    <section class="profileInfoWrapper2">
      <h2 class="fullname">
        {{ groupInfo.name }}
      </h2>

      <div>
        @if (groupInfo.desc) {
          <h3
            #descEl
            class="bio allow-user-selection"
            [ngClass]="{
              bioCollapsed: descLineCount != -1 && descCollapsed,
              bioExpanded: descLineCount != -1 && !descCollapsed
            }"
            [innerHTML]="
              htmlFormattingService.applyTextFormatting(
                htmlFormattingService.applyAll(groupInfo.desc)
              )
            "
          ></h3>
        } @else {
          <h3 #descEl class="bio allow-user-selection bioExpanded">
            No group description
          </h3>
        }

        @if (descExceedsLineCount && descCollapsed) {
          <button
            class="subtle-button seeMoreButton"
            (click)="descCollapsed = false"
          >
            <b>See more...</b>
          </button>
        }
      </div>

      @if (areYouMember || showJoinSection) {
        <section class="join-group-wrapper">
          @if (showJoinSection) {
            <hr class="inline-divider2" />
            <div class="fullname">Join chat room</div>

            <button
              class="subtle-button interaction-button message-button red-button join-button"
              (click)="attemptToJoin()"
            >
              Join group
            </button>
          }

          @if (areYouMember && !showJoinSection) {
            <hr class="inline-divider2" />
            <div class="fullname">Open chat</div>

            <button
              class="subtle-button interaction-button message-button join-button"
              (click)="openMessages()"
            >
              Open chat messages
            </button>
          }
        </section>
      }

      @if (areYouGroupAdmin || (inviteLink !== '' && linkVisible == 1)) {
        <section class="invLinkWrapper">
          <hr class="inline-divider2" />

          <div class="fullname">Group invitation link</div>
          <p class="bio">
            Other users can join by simply clicking the link. The link even
            works outside of this app.
          </p>
          <hr class="inline-divider2" />

          @if (areYouGroupAdmin && !invitationLinkActivated) {
            <div class="deactived-wrapper">
              <div class="button-and-text" (click)="createLink()">
                <div class="blue-bg button-wrapper">
                  <img
                    class="round-icon light-icon"
                    src="/assets/ic_arro_down_scroll.png"
                    alt="create"
                  />
                </div>
                Create link
              </div>
            </div>
          }

          @if (invitationLinkActivated) {
            <section class="actived-wrapper">
              <div class="button-and-text" (click)="copyLink()">
                <div class="green-bg button-wrapper">
                  <img
                    class="round-icon light-icon"
                    src="/assets/ic_share_send.png"
                    alt="share"
                  />
                </div>
                {{ inviteLink }}
              </div>

              <div class="button-and-text" (click)="copyLink()">
                <div class="button-wrapper">
                  <img
                    class="round-icon dark-icon"
                    src="/assets/ic_set_copy.png"
                    alt="copy"
                  />
                </div>
                Copy
              </div>

              @if (isLoggedIn) {
                <div
                  class="button-and-text"
                  mat-dialog-close
                  (click)="shareToChat()"
                >
                  <div class="button-wrapper">
                    <img
                      class="round-icon dark-icon"
                      src="/assets/ic_share_post.png"
                      alt="forward"
                    />
                  </div>
                  Forward
                </div>
              }

              @if (areYouGroupAdmin) {
                <div class="button-and-text" (click)="deactivateLink()">
                  <div class="button-wrapper">
                    <img
                      class="round-icon dark-icon"
                      src="/assets/bottomsheet_ic_block.png"
                      alt="disable"
                    />
                  </div>
                  Deactivate link
                </div>

                <div class="link-visibility-wrapper">
                  <hr class="inline-divider2" />

                  <div class="fullname">Invitation link visibility</div>

                  <p class="bio">
                    If the invitation link is visible, group members can copy
                    and share the invitation link with other users.
                  </p>
                  <div class="checkbox-wrapper">
                    <label class="switch">
                      <input
                        type="checkbox"
                        (change)="linkVisibilityChanged()"
                        [(ngModel)]="linkVisible"
                        name="action"
                      />
                      <span class="slider round"></span>
                    </label>
                    <span class="checkbox-desc">Link visible</span>
                  </div>
                </div>
              }
            </section>
          }
        </section>
      }

      @if (areYouMember) {
        <hr class="inline-divider2" />

        <section class="buttons-wrapper">
          @if (!isGlobalChat) {
            <button
              class="subtle-button interaction-button message-button"
              (click)="viewMembers()"
            >
              View members
            </button>
          }

          @if (areYouGroupAdmin) {
            <button
              class="subtle-button interaction-button message-button"
              (click)="editGroup()"
            >
              Edit group
            </button>
          }
        </section>
      }
    </section>

    <!--
        <app-loadingcircle *ngIf="isLoading">
        </app-loadingcircle>
        -->
  </main>
</div>
