import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { CommentpagetemplateComponent } from '../templates/commentpagetemplate/commentpagetemplate.component'
import { trigger, state, style, transition, animate } from '@angular/animations'
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet'

/**
 * IN AND OUT ANIMATIONS NOT WORKING YET
 */
@Component({
  selector: 'app-comments-dialog',
  templateUrl: './comments-dialog.component.html',
  styleUrls: ['./comments-dialog.component.css'],
  animations: [
    trigger('dialog', [
      state('enter', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('* => void', [
        animate(
          '400ms cubic-bezier(0.25, 0.8, 0.25, 1)',
          style({ transform: 'translateY(25%)', opacity: 0 })
        )
      ]),
      transition('void => enter', [
        style({ transform: 'translateY(25%)' }),
        animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')
      ])
    ])
  ]
})

/**
 * REQUIRES the full post object to be passed in as data!!
 *
 * One thing to note:
 * In contrast to the android version, this code of the web version will NEVER
 * trigger the cloud function to calculate the (wilson) score of a comment.
 * So (yet), the web-version comments are not a perfect stand alone. But together with android they work,
 * since android users will trigger the function, and that suffices.
 */
export class CommentsDialogComponent implements OnInit {
  diffThresholdForReachedBottom_comments: number = 10

  post: any = null
  openKeyboardAlready: boolean = false
  isReplies: boolean = false
  originalComment: any = null
  repliesCount: number = 0

  @ViewChild('commentpage', { static: true })
  commentpage!: CommentpagetemplateComponent
  @ViewChild('wrapper', { static: true }) wrapper!: ElementRef

  state: 'enter' | 'void' = 'enter'

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private dialogRef: MatDialogRef<CommentsDialogComponent>
  ) {
    this.post = this.data.post
    this.originalComment = this.data.originalComment
    this.isReplies = this.data.isReplies
    this.openKeyboardAlready = this.data.openKeyboardAlready
  }

  ngOnInit(): void {
    this.wrapper.nativeElement.addEventListener('scroll', () => {
      const scrollTop = this.wrapper.nativeElement.scrollTop
      const offsetHeight = this.wrapper.nativeElement.offsetHeight

      const scrollHeight = this.wrapper.nativeElement.scrollHeight
      const diff1 = scrollHeight - offsetHeight

      //console.log(`diff: ${diff1 - scrollTop} since...: diff1: ${diff1}, scrollTop: ${scrollTop}`);
      if (diff1 - scrollTop <= this.diffThresholdForReachedBottom_comments) {
        //console.log('Reached bottom... Load new items.');
        this.commentpage.loadItems()
      }
    })

    this.dialogRef.beforeClosed().subscribe(() => {
      this.state = 'void'
    })
  }

  ngOnDestroy() {
    this.dialogRef.close()
  }
}
