import { Component } from '@angular/core'

@Component({
  selector: 'app-explore-loading-item',
  standalone: true,
  imports: [],
  templateUrl: './explore-loading-item.component.html',
  styleUrls: ['./explore-loading-item.component.css', '../explore.styles.css']
})
export class ExploreLoadingItemComponent {}
