import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './one-button-dialog.component.html',
  styleUrls: [
    './one-button-dialog.component.css',
    '../../../stylesheets/dialogs/dialogstylesheet.css'
  ]
})
export class OneButtonDialog {
  title: string = ''
  desc: string = ''
  buttonText: string = ''
  clickCallback: () => any = () => {}

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<OneButtonDialog>
  ) {
    this.title = data.title
    this.desc = data.desc
    this.clickCallback = data.clickCallback
    this.buttonText = data.buttonText
  }

  onButtonClick(): void {
    this.dialogRef.close()
    this.clickCallback()
  }
}
