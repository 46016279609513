<footer>
  <emoji-mart
    id="emoji-picker"
    class="media-emoji-picker mobile-bottom-space"
    emoji=""
    [darkMode]="isDarkmode"
    [showPreview]="false"
    [emojiSize]="isMobile ? 30 : 33"
    [color]="isDarkmode ? '#fff' : '#000000'"
    (emojiClick)="onClickCallback($event.emoji.native, 0)"
  >
  </emoji-mart>
</footer>
