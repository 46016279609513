import { Component, Input } from '@angular/core'
import { RouterModule } from '@angular/router'
import { NumberCommaFormatPipe } from 'src/app/pipes/number-comma-format.pipe'

@Component({
  selector: 'app-explore-search-item',
  standalone: true,
  imports: [RouterModule, NumberCommaFormatPipe],
  templateUrl: './explore-search-item.component.html',
  styleUrls: ['./explore-search-item.component.css', '../explore.styles.css']
})
export class ExploreSearchItemComponent {
  @Input()
  searchQuery!: string

  @Input()
  countInput?: number

  get count() {
    return this.countInput ?? 0
  }
}
