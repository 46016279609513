import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { GifkeyboardComponent } from 'src/app/components/gif/gifkeyboard/gifkeyboard.component'
import { EmojiKeyboardComponent } from 'src/app/components/keyboards/emoji-keyboard/emoji-keyboard.component'

//export type keyboardType = "emoji" | "gif";

export type onVirtualKeyboardClickCallback =
  | ((url: string, aspectRatio: number) => void)
  | ((text: string) => void)

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {
  private overlayRef: OverlayRef | null = null
  private close$: Subject<void> | null = null // for cleaning up

  constructor(private overlay: Overlay) {}

  openGif(
    onGifClick: onVirtualKeyboardClickCallback,
    onClosedCallback: () => void,
    bottomMargin = false
  ) {
    // make sure cannot open twice
    if (this.close$) {
      return
    } else {
      this.close$ = new Subject<void>()
    }

    const componentPortal = new ComponentPortal<GifkeyboardComponent>(
      GifkeyboardComponent,
      null
    )
    this.open(onGifClick, componentPortal, onClosedCallback, bottomMargin)
  }

  openEmoji(
    onCharacterEmitted: onVirtualKeyboardClickCallback,
    onClosedCallback: () => void,
    bottomMargin = false
  ) {
    // make sure cannot open twice
    if (this.close$) {
      return
    } else {
      this.close$ = new Subject<void>()
    }

    const componentPortal = new ComponentPortal<EmojiKeyboardComponent>(
      EmojiKeyboardComponent,
      null
    )
    this.open(
      onCharacterEmitted,
      componentPortal,
      onClosedCallback,
      bottomMargin
    )
  }

  private open(
    onVirtualKeyboardClickCallback: onVirtualKeyboardClickCallback,
    componentPortal: ComponentPortal<any>,
    onClosedCallback?: () => void,
    bottomMargin = false
  ) {
    const panelClass = bottomMargin
      ? 'virtual-keyboard-panelclass-margin-bottom'
      : 'virtual-keyboard-panelclass'

    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'transparent-background',
      panelClass: panelClass,
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .bottom()
    })

    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.close$!))
      .subscribe(() => {
        this.close()

        if (onClosedCallback) {
          onClosedCallback()
        }
      })

    const compRef = this.overlayRef.attach(componentPortal)
    compRef.setInput('onClickCallback', onVirtualKeyboardClickCallback)
  }

  private close() {
    this.overlayRef?.detach()
    this.close$?.next()
    this.close$?.complete()
    this.close$ = null
  }
}
