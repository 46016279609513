import { Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'

@Component({
  selector: 'app-darkmode',
  templateUrl: './darkmode.component.html',
  styleUrls: [
    './darkmode.component.css',
    '../settingspage/settings.component.css'
  ]
})
export class DarkmodeComponent {
  isDarkmode: boolean = false

  constructor(private seoHelper: SeoHelperService) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('Dark mode settings', 'Dark mode settings')

    this.isDarkmode = SystemService.isDarkmode()
  }

  update(darkMode: boolean) {
    this.isDarkmode = darkMode
    SystemService.updateDarkmode(darkMode)
  }
}
