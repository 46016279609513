import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { StrHlp } from '../../shared/services/StringGetter/getstring.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['../settings/settingspage/settings.component.css']
})
export class TermsComponent implements OnInit {
  companyName = 'SocialHubs UG (haftungsbeschränkt)'
  companyEmail = 'app.hub.app@gmail.com'

  @ViewChild('textwrapper') textwrapper!: ElementRef
  @ViewChild('textwrapper2') textwrapper2!: ElementRef

  constructor(
    public StrHlp: StrHlp,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('Terms', 'Terms of Service')
  }

  ngAfterViewInit(): void {
    this.setUpText()
  }

  setUpText(): void {
    let text: string = this.textwrapper.nativeElement.innerText
    text = text
      .replaceAll('%1$s', this.companyName)
      .replaceAll('%2$s', this.companyEmail)
    this.textwrapper.nativeElement.innerText = text

    if (this.textwrapper2) {
      let text: string = this.textwrapper2.nativeElement.innerText
      text = text.replaceAll('%1$s', this.StrHlp.getAppName())
      this.textwrapper2.nativeElement.innerText = text
    }
  }
}
