<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Terms</h1>
  </header>

  <main class="fh-main content">
    @if (!StrHlp.getAllowsNSFW()) {
      <section>
        <h2>Our terms are the U.S. Constitution.</h2>
        <p #textwrapper2>
          Welcome to %1$s, where freedom of speech is not just a right, but our
          foundation. We believe in the power of open dialogue and the exchange
          of ideas, and we’re committed to providing a platform where these can
          thrive.
          <br />
          <br />Our guiding principle is the First Amendment of the United
          States Constitution, which protects freedom of speech. Just as this
          cornerstone of democracy encourages a free exchange of ideas, we
          invite you to express your thoughts, share your perspectives, and
          engage in healthy debates.
          <br />
          <br />We’re proud to serve a community of patriots who value these
          freedoms. Welcome to %1$s - the home of free speech.
        </p>
        <br />
        Click to read the
        <a
          class="link-blue"
          href="https://www.whitehouse.gov/about-the-white-house/our-government/the-constitution/"
          target="_blank"
          >U.S. Constitution</a
        >.
      </section>
    }

    <h2>Terms & Conditions.</h2>

    <p #textwrapper id="text-wrapper">
      Last updated: November 03, 2022
      <br />
      <br />From everyone at %1$s, thank you for using our app! Because we
      don&#8217;t know every one of our customers personally, we have to put in
      place some Terms of Service to help keep the ship afloat.
      <br />
      <br />When we say &#8220;Company&#8221;, &#8220;we&#8221;,
      &#8220;our&#8221;, or &#8220;us&#8221; in this document, we are referring
      to %1$s.
      <br />
      <br />When we say &#8220;Services&#8221;, we mean any product created and
      maintained by %1$s.
      <br />
      <br />When we say &#8220;You&#8221; or &#8220;your&#8221;, we are
      referring to the people or organizations that own an account with one or
      more of our Services.
      <br />
      <br />We may update these Terms of Service in the future. Whenever we make
      a significant change to our policies, we will refresh the date at the top
      of this page and take any other appropriate steps to notify account
      holders.
      <br />
      <br />When you use our Services, now or in the future, you are agreeing to
      the latest Terms of Service. That&#8217;s true for any of our existing and
      future products and all features that we add to our Services over time.
      There may be times where we do not exercise or enforce any right or
      provision of the Terms of Service; in doing so, we are not waiving that
      right or provision. These terms do contain a limitation of our liability.
      <br />
      <br />If you violate any of the terms, we may terminate your account.
      That&#8217;s a broad statement and it means you need to place a lot of
      trust in us. We do our best to deserve that trust by being open about who
      we are, how we work, and keeping an open door to your feedback.
      <br />
      <br />Account Terms <br />You are responsible for maintaining the security
      of your account and password. The Company cannot and will not be liable
      for any loss or damage from your failure to comply with this security
      obligation. We recommend users set up two-factor authentication for added
      security. <br />You are responsible for all content posted and activity
      that occurs under your account. That includes content posted by others who
      either: (a) have access to your login credentials; or (b) have their own
      logins under your account. <br />You must be a human. Accounts registered
      by &#8220;bots&#8221; or other automated methods are not permitted.
      <br />
      <br />Payment and Plan Changes <br />If you are using a free version of
      one of our Services, it is really free: we do not ask you for your credit
      card and &#8212; just like for customers who pay for our Services &#8212;
      we do not sell your data. <br />For paid Services that offer a free trial,
      we explain the length of trial when you sign up. After the trial period,
      you need to pay in advance to keep using the Service. If you do not pay,
      we will freeze your account and it will be inaccessible until you make
      payment. If your account has been frozen for a while, we will queue it up
      for auto-cancellation. <br />If you are upgrading from a free plan to a
      paid plan, we will charge your card immediately and your billing cycle
      starts on the day of upgrade. For other upgrades or downgrades in plan
      level, the new rate starts from the next billing cycle. <br />All fees are
      exclusive of all taxes, levies, or duties imposed by taxing authorities.
      Where required, we will collect those taxes on behalf of the taxing
      authority and remit those taxes to taxing authorities. Otherwise, you are
      responsible for payment of all taxes, levies, or duties.
      <br />
      <br />Cancellation and Termination <br />You are solely responsible for
      properly canceling your account. Within each of our Services, we provide a
      simple no-questions-asked cancellation link. You can find instructions for
      how to cancel your account in our Cancellation policy. An email or phone
      request to cancel your account is not automatically considered
      cancellation. If you need help cancelling your account, you can always
      contact our Support team. <br />If you cancel the Service before the end
      of your current paid up month, your cancellation will take effect
      immediately, and you will not be charged again. We do not automatically
      prorate unused time in the last billing cycle. <br />We have the right to
      suspend or terminate your account and refuse any and all current or future
      use of our Services for any reason at any time. Suspension means you and
      any other users on your account will not be able to access the account or
      any content in the account. Termination will furthermore result in the
      deletion of your account or your access to your account, and the
      forfeiture and relinquishment of all content in your account. We also
      reserve the right to refuse the use of the Services to anyone for any
      reason at any time. We have this clause because statistically speaking,
      out of the hundreds of thousands of accounts on our Services, there is at
      least one doing something nefarious. There are some things we staunchly
      stand against and this clause is how we exercise that stance.
      <br />Verbal, physical, written or other abuse (including threats of abuse
      or retribution) of Company employee or officer will result in immediate
      account termination.
      <br />
      <br />Modifications to the Service and Prices <br />We make a promise to
      our customers to support our Services until the end of the Internet. That
      means when it comes to security, privacy, and customer support, we will
      continue to maintain any legacy Services. Sometimes it becomes technically
      impossible to continue a feature or we redesign a part of our Services
      because we think it could be better or we decide to close new signups of a
      product. We reserve the right at any time to modify or discontinue,
      temporarily or permanently, any part of our Services with or without
      notice. <br />Sometimes we change the pricing structure for our products.
      When we do that, we tend to exempt existing customers from those changes.
      However, we may choose to change the prices for existing customers. If we
      do so, we will give at least 30 days notice and will notify you. We may
      also post a notice about changes on our websites or the affected Services
      themselves.
      <br />
      <br />Uptime, Security, and Privacy <br />Your use of the Services is at
      your sole risk. We provide these Services on an &#8220;as is&#8221; and
      &#8220;as available&#8221; basis. <br />We reserve the right to
      temporarily disable your account if your usage significantly exceeds the
      average usage of other customers of the Services. Of course, we&#8217;ll
      reach out to the account owner before taking any action except in rare
      cases where the level of use may negatively impact the performance of the
      Service for other customers. <br />We take many measures to protect and
      secure your data through backups, redundancies, and encryption. We enforce
      encryption for data transmission from the public Internet. There are some
      edge cases where we may send your data through our network unencrypted.
      <br />When you use our Services, you entrust us with your data. We take
      that trust to heart. You agree that %1$s may process your data as
      described in our Privacy Policy and for no other purpose. We as humans can
      access your data for the following reasons: <br />To help you with support
      requests you make. We&#8217;ll ask for express consent before accessing
      your account. <br />On the rare occasions when an error occurs that stops
      an automated process partway through. We get automated alerts when such
      errors occur. When we can fix the issue and restart automated processing
      without looking at any personal data, we do. In rare cases, we have to
      look at a minimum amount of personal data to fix the issue. In these rare
      cases, we aim to fix the root cause as much as possible to avoid the
      errors from reoccurring. <br />To safeguard %1$s. We&#8217;ll look at logs
      and metadata as part of our work to ensure the security of your data and
      the Services as a whole. If necessary, we may also access accounts as part
      of an abuse report investigation. <br />To the extent required by
      applicable law. We only preserve or share customer data if compelled by a
      government authority with a legally binding order or proper request. If
      %1$s is audited by a tax authority, we only share the bare minimum billing
      information needed to complete the audit. <br />We use third party vendors
      and hosting partners to provide the necessary hardware, software,
      networking, storage, and related technology required to run the Services.
      You can see a list of all subprocessors who handle personal data.
      <br />Under the California Consumer Privacy Act (&#8220;CCPA&#8221;), %1$s
      is a &#8220;service provider&#8221;, not a &#8220;business&#8221; or
      &#8220;third party&#8221;, with respect to your use of the Services. That
      means we process any data you share with us only for the purpose you
      signed up for and as described in these Terms of Service, Privacy policy,
      and other policies. We do not retain, use, disclose, or sell any of that
      information for any other commercial purposes unless we have your explicit
      permission. And on the flip-side, you agree to comply with your
      requirements under the CCPA and not use our Services in a way that
      violates the regulations.
      <br />
      <br />Copyright and Content Ownership <br />All content posted on the
      Services must comply with U.S. copyright law. We provide details on how to
      file a copyright infringement claim. <br />We claim no intellectual
      property rights over the material you provide to the Services. All
      materials uploaded remain yours. <br />We do not pre-screen content, but
      reserve the right (but not the obligation) in our sole discretion to
      refuse or remove any content that is available via the Service. <br />The
      names, look, and feel of the Services are copyright&#169; to the Company.
      All rights reserved. You may not duplicate, copy, or reuse any portion of
      the Services without express written permission from the Company. You must
      request permission to use the Company&#8217;s logo or any Service logos
      for promotional purposes. Please email us requests to use logos. We
      reserve the right to rescind this permission if you violate these Terms of
      Service. <br />You agree not to reproduce, duplicate, copy, sell, resell
      or exploit any portion of the Services, use of the Services, or access to
      the Services without the express written permission by the Company.
      <br />You must not modify another website so as to falsely imply that it
      is associated with the Services or the Company.
      <br />
      <br />Features and Bugs <br />We design our Services with care, based on
      our own experience and the experiences of customers who share their time
      and feedback. However, there is no such thing as a service that pleases
      everybody. We make no guarantees that our Services will meet your specific
      requirements or expectations.
      <br />
      <br />We also test all of our features extensively before shipping them.
      As with any software, our Services inevitably have some bugs. We track the
      bugs reported to us and work through priority ones, especially any related
      to security or privacy. Not all reported bugs will get fixed and we
      don&#8217;t guarantee completely error-free Services.
      <br />
      <br />Liability <br />We mention liability throughout these Terms but to
      put it all in one section:
      <br />
      <br />You expressly understand and agree that the Company shall not be
      liable, in law or in equity, to you or to any third party for any direct,
      indirect, incidental, lost profits, special, consequential, punitive or
      exemplary damages, including, but not limited to, damages for loss of
      profits, goodwill, use, data or other intangible losses (even if the
      Company has been advised of the possibility of such damages), resulting
      from: (i) the use or the inability to use the Services; (ii) the cost of
      procurement of substitute goods and services resulting from any goods,
      data, information or services purchased or obtained or messages received
      or transactions entered into through or from the Services; (iii)
      unauthorized access to or alteration of your transmissions or data; (iv)
      statements or conduct of any third party on the service; (v) or any other
      matter relating to this Terms of Service or the Services, whether as a
      breach of contract, tort (including negligence whether active or passive),
      or any other theory of liability.
      <br />
      <br />In other words: choosing to use our Services does mean you are
      making a bet on us. If the bet does not work out, that&#8217;s on you, not
      us. We do our darnedest to be as safe a bet as possible through careful
      management of the business; investments in security, infrastructure, and
      talent; and in general giving a damn. If you choose to use our Services,
      thank you for betting on us.
      <br />
      <br />If you have a question about any of the Terms of Service, please
      contact our Support team.
    </p>
  </main>
</div>
