<div class="wrapper">
  <div class="wrapper">
    <img id="alreadyPremiumCheck" src="/assets/check.png" alt="check" />

    <div class="text-wrapper">
      <p id="success-text-1">Donation successful!</p>

      <p id="success-text-2">
        Your payment was successfully processed. We want to thank you from the
        bottom of our hearts for your support!
      </p>
    </div>
  </div>

  <div id="close-button" routerLink="/home">Close</div>
</div>
