import { Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { get, getDatabase, ref } from 'firebase/database'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { OnedialogserviceService } from 'src/app/shared/services/dialogs/onedialogservice.service'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { StrHlp } from '../../../shared/services/StringGetter/getstring.service'
import { LoadingDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-nsfwsettings',
  templateUrl: './nsfwsettings.component.html',
  styleUrls: [
    './nsfwsettings.component.css',
    '../settingspage/settings.component.css'
  ]
})
export class NsfwsettingsComponent {
  db = getDatabase()
  userID: any = null
  settingsActivated: boolean = false

  constructor(
    private toast: HotToastService,
    private router: Router,
    private oneButtonDialogService: OnedialogserviceService,
    public dialog: MatDialog,
    private twobuttonsdialogService: TwobuttonsdialogService,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('NSFW settings', 'NSFW settings')

    if (!StrHlp.ALLOWS_NSFW) {
      history.back()
    }

    this.userID = AuthService.getUID()

    this.init()
  }

  init(): void {
    // check if it is already activated
    get(
      ref(
        this.db,
        `${StrHlp.CLOUD_PATH}/UserSettings/${this.userID}/NsfwAppSettingsActivated`
      )
    )
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.settingsActivated = snapshot.val()
        }
      })
      .catch((error) => {
        console.log(error)
        this.toast.error('An error has occurred')
      })
  }

  onActivateClick(): void {
    // user must confirm
    this.twobuttonsdialogService.show(
      'Warning',
      'You must be 18 years or older to continue. Are you an adult?',
      () => {
        // nothing
      },
      () => {
        // call cloud func
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        const functionToCall = httpsCallable(getFunctions(), 'enableNsfwInApp')
        functionToCall({
          hubname: StrHlp.CLOUD_PATH
        })
          .then((result) => {
            loadingDialogRef.close()
            this.settingsActivated = true
            this.toast.success('NSFW activated')
          })
          .catch((error) => {
            loadingDialogRef.close()
            this.oneButtonDialogService.show('Error occurred', error.message)
          })
      },
      'Cancel',
      'Activate NSFW Settings'
    )
  }

  cancelClick(): void {
    this.router.navigate(['home'])
  }
}
