<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Help us stay online</h1>
  </header>

  <main class="fh-main content">
    <div id="advantages-wrapper">
      <div id="branding-wrapper">
        <div class="flex-align-items-middle">
          <img
            id="premium-benefit-verified"
            src="/assets/ic_thumbs_up_emoji.png"
            alt="emoji thumbs up"
            />
            <img
              id="branding-logo"
              src="/assets/applogo_transparent.png"
              alt="{{ strHlp.getAppName() }} logo"
              />
            </div>

            <div>
              <div class="flex-align-items-middle">
                <span id="branding-text">{{ strHlp.getAppName() }}</span>
                <span id="branding-text-premium">Donation</span>
              </div>
            </div>
          </div>

          <hr class="inline-divider" />

          <div class="premium-desc">
            @if (!strHlp.getAllowsNSFW()) {
              <p class="premium-benefit-desc">
                {{ strHlp.getAppName() }} is committed to protecting free speech and
                refusing to censor your voice. As a result, some ad partners have
                stopped doing business with us. Your donation, no matter how small,
                will help us continue to provide a platform for free expression and
                remain independent of ad partners. God bless America and the 1st
                Amendment!
              </p>
            }
            @if (strHlp.getAllowsNSFW()) {
              <p class="premium-benefit-desc">
                {{ strHlp.getAppName() }} is committed to standing up against
                censorship and protecting the right to watch Hentai. Your donation, no
                matter how small, will help us continue to provide a platform for all
                anime content and remain independent of ad partners. Thank you for
                your support!
              </p>
            }
          </div>

          <hr class="inline-divider" />

          <app-donations-progress-bar
            [progressPercentage]="percentGoalReached"
          ></app-donations-progress-bar>

          <hr class="inline-divider" />

          <div id="pay-button-wrapper">
            <button
              class="subtle-button payment-button"
              (click)="initiateCheckout(0, false)"
              >
              One-time donation
            </button>

            @if (showRecurringOption) {
              <button
                class="subtle-button payment-button"
                (click)="askForAmount()"
                >
                Monthly donation
              </button>
            }
          </div>

          <hr class="inline-divider" />

          @if (!strHlp.getAllowsNSFW()) {
            <div class="premium-desc">
              <p class="premium-benefit-desc">
                • <b>Support free speech.</b><br />
                Help us protect free speech. We will continue to refuse censoring our
                users voices.
              </p>
              <p class="premium-benefit-desc">
                • <b>Be a hero for free speech.</b><br />
                Stand with us against big tech censorship.
              </p>
              <p class="premium-benefit-desc">
                • <b>Keep our app independent.</b><br />
                Help us stay online and independent of ad partners who may try to
                censor us.
              </p>
              <p class="premium-benefit-desc">
                • <b>Join our community.</b><br />
                Become a part of our community of supporters who value free speech and
                the 1st Amendment.
              </p>
            </div>
          }

          @if (strHlp.getAllowsNSFW()) {
            <div class="premium-desc">
              <p class="premium-benefit-desc">
                • <b>Support free expression.</b><br />
                Help us stand up against censorship and protect the right to watch
                Hentai.
              </p>
              <p class="premium-benefit-desc">
                • <b>Be a hero for anime.</b><br />
                Stand with us against censorship and support our mission to provide a
                platform for all anime content.
              </p>
              <p class="premium-benefit-desc">
                • <b>Keep our app independent.</b><br />
                Help us stay online and independent of ad partners who may try to
                censor us.
              </p>
              <p class="premium-benefit-desc">
                • <b>Save waifus.</b><br />
                Become a part of our community of supporters who value free expression
                and the right to watch all anime content.
              </p>
            </div>
          }

          <hr class="inline-divider" />

          <div class="premium-desc">
            <p class="premium-benefit-desc">
              Donations are one-time payments and do not automatically renew. If you
              want to continue supporting us, you can make another donation at any
              time. Thank you for your support! ❤️
            </p>
          </div>

          <hr class="inline-divider" />

          <div id="pay-button-wrapper">
            <button
              class="subtle-button payment-button"
              (click)="initiateCheckout(0, false)"
              >
              Help us stay online
            </button>
          </div>

          <hr class="inline-divider" />
        </div>
      </main>
    </div>
