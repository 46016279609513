import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from './shared/services/auth/auth.service'

@Injectable({
  providedIn: 'root'
})

/**
 * if not logged it, will redirect to login / register and keep in mind to later
 * after login go back to the desired URL.
 */
export class LoggedInRequiredWithRedirectGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isLoggedIn()) {
      return true
    }

    const redirectPath = state.url
    this.authService.showLoginDialog(redirectPath)

    //console.log("redirectPath:", redirectPath);
    //this.router.navigate(["login"], { queryParams: { redirect: redirectPath } });

    return false
  }
}
