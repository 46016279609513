import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { RouterModule } from '@angular/router'

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  selector: 'app-notificationdetailspreviewbox',
  templateUrl: './notificationdetailspreviewbox.component.html',
  styleUrls: ['./notificationdetailspreviewbox.component.css']
})
export class NotificationdetailspreviewboxComponent {
  @Input() newNotificationsCount: number = 0
  @Input() newLikeNotifCount: number = 0
  @Input() newCommentsNotifCount: number = 0
  @Input() newFollowNotifCount: number = 0
  @Input() newOtherNotifCount: number = 0
  @Input() triangleAtTop: boolean = true
}
