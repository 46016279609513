import { Injectable } from '@angular/core'
import { IsBrowserService } from './isbrowser.service'

/**
 * Nodejs friendly settimeout
 * Needed because we now run SSR.
 */
@Injectable({
  providedIn: 'root'
})
export class SetTimeoutService {
  private isBrowser

  constructor(private isBrowserService: IsBrowserService) {
    //const localStorageDefined = typeof localStorage !== 'undefined';
    this.isBrowser = this.isBrowserService.isBrowser()
  }

  /**
   *
   * @param callback
   * @param delay
   * @param runOnServerAlso default = false. If set to true, the code will run,
   *  without delay, on the server nodejs (ssr) also.
   */
  setTimeout(
    callback: () => void,
    delay?: number,
    runOnServerAlso: boolean = false
  ): any {
    if (this.isBrowser) {
      const delayedBy = delay ?? 0
      return setTimeout(callback, delayedBy)
    } else {
      if (runOnServerAlso) {
        callback()
      }
    }
  }

  clearTimeout(namespace: any) {
    if (this.isBrowser) {
      clearTimeout(namespace)
    }
  }
}
