<div id="wrapper">
  @if (!considerZoomedIn && !hideHeader) {
    <header class="header">
      <div id="side-button-bar">
        <div class="side-button">
          <img
            src="/assets/ic_close_2.png"
            class="side-button-img"
            mat-dialog-close
            alt="close"
          />
        </div>
        <div class="side-button">
          <img
            src="/assets/ic_download.png"
            alt="download"
            class="side-button-img"
            (click)="downloadImage()"
          />
        </div>
        @if (isGIF && !gifIsFavorized) {
          <div class="side-button">
            <img
              src="/assets/ic_star.png"
              alt="star"
              class="side-button-img"
              (click)="favorizeGIF()"
            />
          </div>
        }
        @if (isGIF && gifIsFavorized) {
          <div class="side-button">
            <img
              src="/assets/ic_star_filled.png"
              alt="star"
              class="side-button-img"
              (click)="unfavorizeGIF()"
            />
          </div>
        }
      </div>
      @if (title || desc) {
        <div class="text-header">
          @if (title) {
            <h2 class="title text-line-in-header">{{ title }}</h2>
          }
          @if (desc) {
            <p class="desc text-line-in-header">{{ desc }}</p>
          }
        </div>
      }
    </header>
  }

  <div id="image-container" (click)="swapHeaderHiding()">
    <img
      #image
      src="{{ mediaURL }}"
      id="image"
      alt="Fullscreen image"
      onerror="src='/assets/broken_file.png';onerror='';"
      cdkDrag
      cdkDragLockAxis="y"
      (cdkDragEnded)="onDragEnd($event)"
    />
  </div>
</div>
