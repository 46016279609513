import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { HotToastService } from '@ngneat/hot-toast'
import { StrHlp } from '../../../shared/services/StringGetter/getstring.service'
import { child, get, getDatabase, ref, set } from 'firebase/database'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { ResetlatestscoresService } from 'src/app/shared/services/feed/resetlatestscores.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-feedsettings',
  templateUrl: './feedsettings.component.html',
  styleUrls: ['../settingspage/settings.component.css']
})
export class FeedsettingsComponent implements OnInit {
  userID: any = null

  @ViewChild('allowRepostsCheck')
  allowRepostsCheck!: ElementRef

  constructor(
    private toast: HotToastService,
    private twobuttonsdialogService: TwobuttonsdialogService,
    private feedScoreService: ResetlatestscoresService,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('Feed settings', 'Feed settings')

    this.userID = AuthService.getUID()
    this.loadSettings()
  }

  loadSettings(): void {
    const dbRef = ref(getDatabase())

    const dbPath = `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/AllowRepost`
    get(child(dbRef, dbPath))
      .then((snapshot) => {
        let allowRepost: boolean = true
        if (snapshot.exists()) {
          allowRepost = snapshot.val()
        }
        this.allowRepostsCheck.nativeElement.checked = allowRepost
      })
      .catch((error) => {
        this.toast.error('An error has occurred')
        console.error(error)
      })
  }

  onCheckClick(): void {
    const check: boolean = this.allowRepostsCheck.nativeElement.checked
    const dbPath = `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/AllowRepost`
    set(ref(getDatabase(), dbPath), check)
  }

  resetFeedDataDialog(): void {
    // user must confirm
    this.twobuttonsdialogService.show(
      'Reset feed',
      'Do you want to reset your feed data of all feeds (including clips)?',
      () => {
        // nothing
      },
      () => {
        this.feedScoreService.resetAllScores()
        this.toast.success('Success')
      },
      'Cancel',
      'Okay'
    )
  }
}
