import { Component, ElementRef, ViewChild } from '@angular/core'
import { get, getDatabase, ref } from 'firebase/database'
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where
} from 'firebase/firestore'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { FeeddataService } from 'src/app/shared/services/data/feeddata.service'
import { MuteUsersService } from 'src/app/shared/services/muteusers.service'
import { FeedComponent } from '../../feed/feed.component'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { ResetlatestscoresService } from 'src/app/shared/services/feed/resetlatestscores.service'
import { MainComponent } from '../../main/main.component'
import PullToRefresh from 'pulltorefreshjs'
import { Subscription, filter, fromEvent, map, throttleTime } from 'rxjs'
import { ClipsStateService } from 'src/app/shared/services/clips/clips-state.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { LocalstorageService } from 'src/app/shared/services/ssr/localstorage.service'
import { SetTimeoutService } from 'src/app/shared/services/ssr/set-timeout.service'
import { MatDialog } from '@angular/material/dialog'
/*import createScrollSnap from 'scroll-snap'*/

@Component({
  selector: 'app-clips',
  templateUrl: './clips.component.html',
  styleUrls: ['./clips.component.css']
})
export class ClipsComponent {
  scrollTop_Before = 0
  isMobile = SystemService.isMobile()

  // only an estimate, will be calculated
  itemSize = this.vh(100) - 50
  itemSizeCalculated = false

  countLoadPosts = 4
  loadPostsDisabled = false
  emptyPosts = false
  loadPostsInProgress = false

  db = getFirestore()
  rtdb = getDatabase()

  urlHash = ''

  showNSFW = StrHlp.ALLOWS_NSFW

  showSwipeUpHint = true
  indexChangedCount = 0
  thresholdHideSwipeHint = 1

  latestClipScore = Number.MAX_VALUE

  @ViewChild('clipContentWrapper') clipContentWrapper!: ElementRef

  curr_ItemIndex = 0
  latestScrollTop = 0

  isNSFW = false
  clips_nsfw_choice_key = 'clips_nsfw_choice_key'
  isViaUrlNSFW = false

  startingTimeSecs = 0

  pullToRefresh: any

  unbindScrollSnap: () => void = () => {}

  isIOS = SystemService.isIOS()
  showInitPlayButton = true

  private subscriptions = new Subscription()

  /**
   * Pass data:
   * - post: post object. Used for watching a video from the normal feed. Opens in clips.
   */
  startingPost: any

  constructor(
    public feedDataService: FeeddataService,
    private muteUsersService: MuteUsersService,
    public strHlp: StrHlp,
    private _location: Location,
    private route: ActivatedRoute,
    private toast: HotToastService,
    private feedScoreService: ResetlatestscoresService,
    private router: Router,
    private clipsStateService: ClipsStateService,
    public seoHelper: SeoHelperService,
    private localstorageService: LocalstorageService,
    private setTimeoutService: SetTimeoutService,
    private dialog: MatDialog
  ) {
    //const currNav = this.router.getCurrentNavigation();
    const currNav = history.state

    if (currNav !== null) {
      const postInp = currNav.post

      if (postInp) {
        this.startingPost = postInp
      }
    }
  }

  ngOnInit() {
    // check if ID was specified via URL
    const postIdInput = this.route.snapshot.paramMap.get('postID')

    // DEPRECATED
    const nsfwInput = this.route.snapshot.queryParamMap.get('nsfw')
    if (nsfwInput) {
      this.isViaUrlNSFW = nsfwInput === 'true'
    }

    // Check if it is NSFW in new way
    const currentUrlTree = this.router.parseUrl(this.router.url)
    const currentUrlSegments = currentUrlTree.root.children.primary.segments
    if (currentUrlSegments[0].path === 'hentai-tiktok') {
      this.isViaUrlNSFW = true
      this.isNSFW = true
    }
    // --

    // Set SEO
    this.seoHelper.setClips(this.isViaUrlNSFW)

    if (this.isViaUrlNSFW) {
      this.isNSFW = true
    }

    if (postIdInput) {
      // load the post first
      this.loadFromInitPostID(postIdInput)

      // check if timestamp was provided also
      const stampInSecs = this.route.snapshot.queryParamMap.get('t')
      if (stampInSecs) {
        this.startingTimeSecs = +stampInSecs
      }
    } else {
      // load normally
      this.reloadFeed(true)
    }

    if (!SystemService.isMobile()) {
      this.setUpKeyboardControl()
    }
  }

  setUpKeyboardControl() {
    // ssr-guarded
    if (typeof document === 'undefined') {
      return
    }

    // space pauses or continues
    this.subscriptions.add(
      fromEvent(document, 'keydown')
        .pipe(
          throttleTime(200),
          filter((event: Event) => event instanceof KeyboardEvent),
          map((event: Event) => (event as KeyboardEvent).code)
        )
        .subscribe((code) => {
          if (code == 'ArrowDown') {
            this.programmaticallySwipe(true)
          } else if (code == 'ArrowUp') {
            this.programmaticallySwipe(false)
          } else if (code == 'Space') {
            // pause / continue
            this.clipsStateService.emitPausePlay()
          } else if (code == 'l') {
            // pause / continue
            //this.clipsStateService.emitLike();
          }
        })
    )
  }

  ngOnDestroy() {
    MainComponent.scrollSavingMap.set(
      this.urlHash,
      this.clipContentWrapper.nativeElement.scrollTop
    )

    this.unbindScrollSnap()
    this.subscriptions.unsubscribe()
  }

  async loadFromInitPostID(postID: string) {
    // no post input via state in this case allowed
    this.startingPost = null

    try {
      const snapshot = await get(
        ref(this.rtdb, `${StrHlp.CLOUD_PATH}/Photo/${postID}`)
      )

      if (snapshot.exists()) {
        const post = snapshot.val()
        post.postID = postID

        if (post.vid && post.vid !== null && post.vid !== '') {
          // its a video, so valid input postID

          // "abuse" startingPost for adding this
          this.startingPost = post

          // start loading more posts
          this.reloadFeed(true)
        } else {
          // not valid input, show error
          this.toast.error('This post does not contain a video')
          this.toast.show('Loading other clips...')

          // load other clips
          this.reloadFeed(true)
        }
      }
    } catch (error) {
      this.toast.error('Loading post failed')
      this.toast.show('Loading other clips...')

      // load other clips
      this.reloadFeed(true)
    }
  }

  reloadFeed(
    calledFromOnInit: boolean = false,
    forceClearPosts: Boolean = false
  ) {
    // reset curr index
    this.curr_ItemIndex = 0

    // clear items
    if (forceClearPosts) {
      this.feedDataService.clearShownPostList()
    } else {
      // check if there is a "saved state" from this feed
      if (this.isNSFW) {
        if (this.feedDataService.clipsListNsfw.length > 0) {
          this.feedDataService.postList.push(
            ...this.feedDataService.clipsListNsfw
          )
          this.latestClipScore = this.feedDataService.latestScore_Clips_Nsfw
        } else {
          this.feedDataService.clearShownPostList()
        }
      } else {
        if (this.feedDataService.clipsList.length > 0) {
          this.feedDataService.postList.push(...this.feedDataService.clipsList)
          this.latestClipScore = this.feedDataService.latestScore_Clips
        } else {
          this.feedDataService.clearShownPostList()
        }
      }
    }

    // reload everything
    this.loadScoreInit()

    // check if there is an init post,
    // only add it if its ngOnInit
    if (calledFromOnInit) {
      if (this.startingPost) {
        // use unshift for the case that we had a saved state (see above)
        this.feedDataService.postList.unshift(this.startingPost)
      }
    }

    this.loadItems()
  }

  loadScoreInit() {
    // load if nsfw or not
    if (this.isViaUrlNSFW) {
      this.isNSFW = true
    } else {
      const nsfwString = this.localstorageService.getItem(
        this.clips_nsfw_choice_key
      )
      if (nsfwString) {
        this.isNSFW = nsfwString === 'true'
      }
    }

    this.latestClipScore = this.feedScoreService.getLatestSavedScore(
      true,
      this.isNSFW
    )

    if (this.latestClipScore == 0) {
      this.latestClipScore = Number.MAX_VALUE
    }
  }

  setUpScrollSnap() {
    /*
    const { bind, unbind } = createScrollSnap(this.clipContentWrapper.nativeElement, {
      snapDestinationX: '0%',
      snapDestinationY: '100%',
      timeout: 20,
      duration: 80,
      threshold: 0.02,
      snapStop: true,
      easing: t => t<.5 ? 2*t*t : -1+(4-2*t)*t,
    }, 
    () => {
      // NOTE:
      // NO LONGER NEEDED DUE TO INTERSECTION LISTENER!!

      
      // Very simple system:
      // If the latest snap-scroll-top is smaller, the index has increased by 1
      // If the same, index remains equal
      // If larger, it has decreased by 1. 
      // This is ensured to be correct since this callback is only called **after** an actual **snap**.
      // Plus, the library prevents items being scrolled over.
      const scrollTop = this.clipContentWrapper.nativeElement.scrollTop;
      const prevScrollTop = this.latestScrollTop;

      // update var
      this.latestScrollTop = scrollTop;

      const hasChanged = scrollTop !== prevScrollTop;

      if (hasChanged) {
        const scrolledDown = scrollTop > prevScrollTop;

        if (scrolledDown) {
          this.curr_ItemIndex++;
        } else {
          this.curr_ItemIndex--;
        }
        
        this.onIndexChange();
      }
    });

    this.unbindScrollSnap = unbind;
    */
    /*
    const options = {
      container: this.clipContentWrapper.nativeElement,
      panelSelector: '> div',
      directionThreshold: 50,
      delay: 0,
      duration: 100,
      easing: (t: number) => { return t<.5 ? 2*t*t : -1+(4-2*t)*t },
    };
  
    new PanelSnap(options);
    */
  }

  ngAfterViewInit() {
    this.setUpScrollSnap()
    this.setUpScrollingListener()

    // set url hash
    this.urlHash = this.router.url

    // apply scroll state
    let scrollTop = 0
    if (MainComponent.scrollSavingMap.has(this.urlHash)) {
      scrollTop = MainComponent.scrollSavingMap.get(this.urlHash)
    }
    this.setTimeoutService.setTimeout(() => {
      // must be inside of this
      this.clipContentWrapper.nativeElement.scrollTop = scrollTop
    }, 0)
    // --

    // pull to refresh
    this.pullToRefresh = PullToRefresh.init({
      mainElement: '#clipWrapper',
      shouldPullToRefresh: () => {
        return (
          this.clipContentWrapper.nativeElement.scrollTop == 0 &&
          !this.loadPostsInProgress &&
          this.dialog.openDialogs.length == 0
        )
      },
      onRefresh: () => {
        // ssr-guarded
        if (typeof window === 'undefined') {
          return
        }
        console.log('Pull to refresh triggered in clips.')
        window.location.reload()
      }
    })
  }

  /*
  setUpScrollingListener() {
      // Doesnt work (see below)
      //const isScrollDown = scrollTop >= this.scrollTop_Before;

      this.scrollTop_Before = scrollTop;

      let itemIndex_New = this.curr_ItemIndex; // init
      
      // Detect scroll direction
      // We need this "sophisticated" approach because of the scroll snap
      // (we must pretend that first because of the scroll snap,
      // since that makes it impossible to rely on the isScrollDown Bool, since if you scroll up,
      // but not fully, the snap scroll will automatically snap back, i.e. scroll down, 
      // and screw the calculation)
      let directionDetect_scrTop_ModAdjusted = scrollTop - (scrollTop % this.itemSize);
      const directionDetect_itemIndex_New = directionDetect_scrTop_ModAdjusted / this.itemSize;

      const attemptScrollDown = directionDetect_itemIndex_New >= this.curr_ItemIndex;

      //console.log("ssccrr", "---------------------------------------------");
      //console.log("ssccrr", "attemptScrollDown:", attemptScrollDown);

      // now do the actual index calculation with allowed inaccuracies
      if (attemptScrollDown) {
        const allowedInaccuracy = 100;
        const scrollTopAdjusted = scrollTop + allowedInaccuracy;

        const scrTop_ModAdjusted = scrollTopAdjusted - (scrollTopAdjusted % this.itemSize);
        itemIndex_New = scrTop_ModAdjusted / this.itemSize;

      } else {
        // We have confirmed a scrolling up (also snap scroll will fall in this case),
        // so we use the other calculation
        const allowedInaccuracy = 150;

        const scrollTopAdjusted = Math.max(0, scrollTop - allowedInaccuracy);
        let moduloDiff = this.itemSize - (scrollTopAdjusted % this.itemSize);

        if (moduloDiff == this.itemSize) {
          moduloDiff = 0;
        }

        const scrTop_ModAdjusted = scrollTopAdjusted + moduloDiff;
        itemIndex_New = scrTop_ModAdjusted / this.itemSize;
      }

      // ensure max change of index is 1
      if (itemIndex_New < this.curr_ItemIndex-1) {
        itemIndex_New = this.curr_ItemIndex-1;

        // adjust scroll accordingly!!
        this.clipContentWrapper.nativeElement.scrollTop = itemIndex_New * this.itemSize;

      } else if (itemIndex_New > this.curr_ItemIndex+1) {
        itemIndex_New = this.curr_ItemIndex+1;

        // adjust scroll accordingly!!
        this.clipContentWrapper.nativeElement.scrollTop = itemIndex_New * this.itemSize;
      }


      // Apply change
      if (this.curr_ItemIndex !== itemIndex_New) {
        // with time limit
        if (
          TimeLimitsService.isAllowed_Session('index-change-clips-tab', 500)
        ) {
          this.curr_ItemIndex = itemIndex_New;
          this.onIndexChange();
        }
      }
    }
    */

  setUpScrollingListener() {
    const scrollEvent = () => {
      // check if reached bottom
      // we consider the bottom as reached if there is less than 2 item-sizes distance to the bottom.
      const scrollTop = this.clipContentWrapper.nativeElement.scrollTop
      const scrollHeight = this.clipContentWrapper.nativeElement.scrollHeight
      const clientHeight = this.clipContentWrapper.nativeElement.clientHeight

      const diffToBottom = scrollHeight - scrollTop - clientHeight
      const reachedBottom = diffToBottom < 2 * this.itemSize

      //console.log("diffBottom:",diffToBottom,", scrollTop:", scrollTop, ", scrollHeight:",scrollHeight,", clientHeight:",clientHeight);

      if (reachedBottom) {
        this.loadItems()
      }

      // can only get disabled, not re-enabled
      if (this.showSwipeUpHint) {
        this.showSwipeUpHint = scrollTop <= this.itemSize
      }
    }

    this.clipContentWrapper.nativeElement.addEventListener(
      'scroll',
      scrollEvent
    )
  }

  /*
  onIndexChange() {
    this.indexChangedCount++;

    if (this.indexChangedCount >= this.thresholdHideSwipeHint) {
      this.showSwipeUpHint = false;
    }

    //console.log("OnIndexChanged","curr_ItemIndex:",this.curr_ItemIndex,"length:",this.feedDataService.postList.length);
    if (this.curr_ItemIndex >= this.feedDataService.postList.length-2) {
      if (
        TimeLimitsService.isAllowed_Session('on-scroll-load-new-items-clips', 2000)
      ) {
        this.loadItems();
      } 
    }

    if (!this.itemSizeCalculated) {
      this.calculateItemSize();
    }
  }
  */

  calculateItemSize() {
    // ssr-guarded
    if (typeof document === 'undefined') {
      return
    }

    const firstItemElement = document.querySelector('.vs-item')
    if (firstItemElement) {
      this.itemSize = firstItemElement.clientHeight
      this.itemSizeCalculated = true
    }
  }

  async loadItems() {
    if (this.loadPostsDisabled) {
      return
    }
    if (this.loadPostsInProgress) {
      return
    }

    this.loadPostsInProgress = true

    // get query
    const scoreNameString = 'viewLikeRatioScore'

    /*
    const sortMode = 0;
    if (sortMode == 0) {
      scoreNameString = "likeCount_Real";
    } else if (sortMode == 1) {
      scoreNameString = "viewLikeRatioScore";
    }
    */

    const queryPathEnd = this.isNSFW ? 'NSFW' : 'ForYou'
    const timeZyklusString = FeedComponent.getMonthZyklusString()

    const q = query(
      collection(
        this.db,
        `${StrHlp.CLOUD_PATH}/Discover/Trending/Posts/${queryPathEnd}`
      ),
      orderBy(scoreNameString, 'desc'),
      limit(this.countLoadPosts),
      where(scoreNameString, '<', this.latestClipScore),
      where('zyklus', '==', timeZyklusString),
      where('contentType', '==', 1)
    )

    // get docs
    const querySnapshot = await getDocs(q)

    if (querySnapshot.size == 0) {
      this.loadPostsDisabled = true

      if (this.feedDataService.postList.length == 0) {
        this.emptyPosts = true
      }

      this.loadPostsInProgress = false

      this.feedScoreService.resetLatestSavedScore(true, this.isNSFW)
      return
    }

    querySnapshot.forEach((doc) => {
      this.loadPostsInProgress = false
      const postID = doc.id

      // update last score
      this.latestClipScore = doc.data()[scoreNameString!]
      this.feedScoreService.updateLatestSavedScore(
        true,
        this.isNSFW,
        this.latestClipScore
      )

      // load the corresponding post
      get(ref(this.rtdb, `${StrHlp.CLOUD_PATH}/Photo/${postID}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const post = snapshot.val()
            post.postID = postID

            if (!this.muteUsersService.isMuted(post.userID)) {
              // update feed data service
              this.feedDataService.postList.push(post)

              // load profile image and username
              this.feedDataService.easyLoadPostInfo(
                this.feedDataService.postList.length - 1
              )

              this.loadPostsInProgress = false
            }
          }
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }

  // Helper
  vh(percent: number) {
    // ssr-guarded
    if (typeof window === 'undefined') {
      return 0
    }
    if (typeof document === 'undefined') {
      return 0
    }

    const h = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    )
    return (percent * h) / 100
  }

  swapNSFW() {
    // swap
    this.isNSFW = !this.isNSFW

    // swap URL
    if (this.isNSFW) {
      // OLD
      //this._location.replaceState("/clips?nsfw=true");

      // NEW
      this._location.replaceState('hentai-tiktok')
    } else {
      this._location.replaceState('/clips')
    }

    // persist nsfw choice
    this.localstorageService.setItem(
      this.clips_nsfw_choice_key,
      '' + this.isNSFW
    )

    this.reloadFeed(false, true)
  }

  goBack() {
    this._location.back()
  }

  programmaticallySwipe(directionDown: Boolean) {
    const addScroll = directionDown ? this.itemSize : -this.itemSize
    const scrollTop = this.clipContentWrapper.nativeElement.scrollTop

    // apply
    let newScrollTop = scrollTop + addScroll
    if (newScrollTop < 0) {
      newScrollTop = 0
    }

    this.clipContentWrapper.nativeElement.scrollTop = newScrollTop
  }

  onTouchStart(event: TouchEvent) {
    this.clipsStateService.emitTouchStartEvent(event)
  }
  onTouchEnd(event: TouchEvent) {
    this.clipsStateService.emitTouchEndEvent(event)
  }

  startPlayingInitially(event: TouchEvent) {
    this.showInitPlayButton = false
    this.clipsStateService.emitInitialEvent(event)
  }
}
