import { Component } from '@angular/core'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'

@Component({
  selector: 'app-bottomsheet-bottom-branding-spacer',
  templateUrl: './bottomsheet-bottom-branding-spacer.component.html',
  styleUrls: ['./bottomsheet-bottom-branding-spacer.component.css']
})
export class BottomsheetBottomBrandingSpacerComponent {
  appName = StrHlp.APP_NAME
}
