<!--
        Quick patch for angular ssr issue.
        CODE BELOW IS COPIED IN THE IF ELSE CLAUSE!!
        not good, todo.

        read more: 
        point 1 of https://github.com/LukasNiessen/Hub-Docs/blob/master/SSR.md#watch-out-for-these-things
-->

@if (createAsDiv) {
  <div
    class="subtle-button username"
    data-testid="username-wrapper"
    (click)="actionClick()"
    [ngClass]="{
      smallType1: smallType1,
      bigType1: bigType1,
      bigType2: bigType2,
      darkModeLighter: darkModeLighter,
      'grey-background': greyBackground,
      whiteText: whiteText,
      'clips-text-shadow': textShadow
    }"
    role="link"
    [routerLink]="
      disableClick
        ? []
        : [isGroup ? '/group/' + groupID : '/@' + (name$ | async)]
    "
    attr.aria-label="Open {{ isGroup ? 'group' : 'profile of user' }} {{
      name$ | async
    }}"
  >
    {{ name$ | async }}

    @if (verified$ | async) {
      <img
        class="verified-badge"
        [ngClass]="{ smallType1_Verified: smallType1 }"
        src="/assets/ic_verified_blue.png"
        alt="Verified blue badge"
      />
    }
  </div>
} @else {
  <a
    data-testid="username-wrapper"
    class="subtle-button username"
    (click)="actionClick()"
    [ngClass]="{
      smallType1: smallType1,
      bigType1: bigType1,
      bigType2: bigType2,
      darkModeLighter: darkModeLighter,
      'grey-background': greyBackground,
      whiteText: whiteText,
      textShadow: textShadow
    }"
    role="link"
    [routerLink]="
      disableClick
        ? []
        : [isGroup ? '/group/' + groupID : '/@' + (name$ | async)]
    "
    attr.aria-label="Open {{ isGroup ? 'group' : 'profile of user' }} {{
      name$ | async
    }}"
  >
    {{ name$ | async }}

    @if (verified$ | async) {
      <img
        class="verified-badge"
        [ngClass]="{ smallType1_Verified: smallType1 }"
        src="/assets/ic_verified_blue.png"
        alt="Verified blue badge"
      />
    }
  </a>
}
