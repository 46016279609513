import { Component, ElementRef, Inject, ViewChild } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { HotToastService } from '@ngneat/hot-toast'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { ZoomhelperService } from 'src/app/shared/services/images/zoomhelper.service'

@Component({
  selector: 'app-videoplayer',
  templateUrl: './videoplayer.component.html',
  styleUrls: [
    './videoplayer.component.css',
    '../fullscreenimage/fullscreenimage.component.css'
  ]
})

/**
 * Use a dialog, use the service to open this!
 */
export class VideoplayerComponent {
  userID: any = null
  videoID: string | null = null

  title = ''
  desc = ''
  videoPlayerUrl: SafeResourceUrl | null = null

  zoomScale = 1

  no_Zoom_Threshold = 1.1
  considerZoomedIn = false

  @ViewChild('image') image!: ElementRef

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public authService: AuthService,
    private toast: HotToastService,
    public strHlp: StrHlp,
    public sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<VideoplayerComponent>
  ) {
    this.userID = AuthService.getUID()

    if (this.data === undefined || this.data === null) {
      this.toast.error('An error occurred')
      return
    }
    this.videoID = this.data.videoID

    this.title = this.data.title ? this.data.title : ''
    this.desc = this.data.desc ? this.data.desc : ''
  }

  ngAfterViewInit() {
    if (this.videoID) {
      const neededURL = `https://iframe.mediadelivery.net/embed/148025/${this.videoID}?autoplay=true&loop=true`
      this.videoPlayerUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(neededURL)
    }
  }

  download() {
    // ...
  }

  closeDialog() {
    this.dialogRef.close()
  }

  onSwipeDown() {
    if (!this.considerZoomedIn) {
      this.closeDialog()
    }
  }
  onSwipeUp() {
    if (!this.considerZoomedIn) {
      this.closeDialog()
    }
  }
}
