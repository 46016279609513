import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { HotToastService } from '@ngneat/hot-toast'
import { StrHlp } from '../../../shared/services/StringGetter/getstring.service'
import { get, getDatabase, ref } from 'firebase/database'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { OnedialogserviceService } from 'src/app/shared/services/dialogs/onedialogservice.service'
import { LoadingDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { Router } from '@angular/router'
import { Location } from '@angular/common'
import { Observable, Subject, map, merge, startWith } from 'rxjs'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { SITE_PROTOCOL } from 'src/app/shared/constants'

@Component({
  selector: 'app-username',
  templateUrl: './username.component.html',
  styleUrls: [
    'username.component.css',
    '../settingspage/settings.component.css',
    '../../sign-in/sign-in.component.css'
  ]
})
export class UsernameComponent implements OnInit {
  error = ''
  showError = false
  showNotAllowed_TooRecent = false

  buttonDisabled = true

  currUsername$?: Observable<string>
  currProfileURL$?: Observable<string>
  usernameChangeSubject = new Subject<string>()

  usernameEntered: string = ''
  userID: any = null
  dbRTDB = getDatabase()

  dayInMillis = 86400000

  dataReceived: any = null
  closeAfterChange: boolean = false

  constructor(
    private toast: HotToastService,
    private oneButtonDialogService: OnedialogserviceService,
    public dialog: MatDialog,
    private cacheService: CacheService,
    private router: Router,
    private location: Location,
    private seoHelper: SeoHelperService
  ) {
    // This must be in the constructor, not later
    const currNav = this.router.getCurrentNavigation()
    if (currNav) {
      this.dataReceived = currNav.extras.state
    }
  }

  ngOnInit(): void {
    this.seoHelper.setForSomePage(
      'Username settings',
      'Choose a custom username for your profile.'
    )

    if (this.dataReceived) {
      this.closeAfterChange = this.dataReceived.closeAfterChange
    }

    this.userID = AuthService.getUID()

    this.loadIfChangingAllowed()
    this.loadCurrUsername()
  }

  loadCurrUsername(): void {
    this.currUsername$ = merge(
      this.cacheService.getUsername(this.userID).pipe(startWith('...')),
      this.usernameChangeSubject
    )

    this.currProfileURL$ = this.currUsername$.pipe(
      map((name) => `${SITE_PROTOCOL}://${StrHlp.APP_URL}/@${name}`)
    )
  }

  loadIfChangingAllowed(): void {
    const dbPath = `${StrHlp.CLOUD_PATH}/UserLimits/LastTimeUsernameUpdated/${this.userID}`

    get(ref(this.dbRTDB, dbPath))
      .then((snapshot) => {
        let lastChangedStamp: number = 0

        if (snapshot.exists()) {
          lastChangedStamp = snapshot.val()
        }

        if (Date.now() - lastChangedStamp > 7 * this.dayInMillis) {
          // allowed
          this.buttonDisabled = false
        } else {
          this.showNotAllowed_TooRecent = true
        }
      })
      .catch((error) => {
        this.toast.error('An error has occurred')
        console.error(error)

        // but allow user to change
        this.buttonDisabled = false
      })
  }

  changeUsername(): void {
    if (this.checkIfValid()) {
      // attempt change
      this.buttonDisabled = true

      const functions = getFunctions()
      const changeUsername = httpsCallable(functions, 'changeUsername')

      const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
        disableClose: true
      })

      changeUsername({
        newUsername: this.usernameEntered,
        hubname: StrHlp.CLOUD_PATH
      })
        .then((result) => {
          loadingDialogRef.close()
          this.toast.success('Username changed')

          // stay disabled
          this.buttonDisabled = true

          this.usernameChangeSubject.next(this.usernameEntered)

          if (this.closeAfterChange) {
            this.location.back()
          }
        })
        .catch((error) => {
          loadingDialogRef.close()
          this.buttonDisabled = false
          console.log(error)

          // show error message via dialog
          this.oneButtonDialogService.show('Failed', error.message)
        })
    }
  }

  /**
   * Basic syntax check
   */
  checkIfValid(): boolean {
    // first remove error
    this.showError = false

    // username

    // must match this regex, meaning it has only allowed characters
    const regex = /[a-zA-Z0-9_.]/g
    if (!regex.test(this.usernameEntered)) {
      this.showError = true
      this.error = 'Username contains illegal characters'
      return false
    }

    if (this.usernameEntered.length < 4) {
      this.showError = true
      this.error = 'Username must be at least 4 characters'
      return false
    }

    if (this.usernameEntered.length > 40) {
      this.showError = true
      this.error = 'Username must not be longer than 40 characters'
      return false
    }

    const startWithDotRegex = /^[.].*/g
    if (startWithDotRegex.test(this.usernameEntered)) {
      this.showError = true
      this.error = 'Cannot start with a dot'
      return false
    }

    const endWithDotRegex = /.*[.]$/g
    if (endWithDotRegex.test(this.usernameEntered)) {
      this.showError = true
      this.error = 'Cannot end with a dot'
      return false
    }

    if (this.usernameEntered.includes('..')) {
      this.showError = true
      this.error = 'Cannot have more than one dot in a row'
      return false
    }

    if (
      this.usernameEntered.includes('._') ||
      this.usernameEntered.includes('_.')
    ) {
      this.showError = true
      this.error = 'Cannot have . and _ in a row'
      return false
    }

    if (this.usernameEntered.replaceAll(/[^A-Za-z]/g, '').length < 3) {
      this.showError = true
      this.error = 'Must have at least 3 letters (a-z)'
      return false
    }

    if (
      this.usernameEntered === 'support' ||
      this.usernameEntered === 'deleted' ||
      this.usernameEntered === 'lukasniessen'
    ) {
      this.showError = true
      this.error = 'Username not allowed'
      return false
    }

    return true
  }
}
