import { Component, ElementRef, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import {
  get,
  getDatabase,
  limitToFirst,
  orderByKey,
  query,
  ref,
  startAt
} from 'firebase/database'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { MatDialog } from '@angular/material/dialog'
import { map, take } from 'rxjs'

@Component({
  selector: 'app-followrequests',
  templateUrl: './followrequests.component.html',
  styleUrls: [
    './followrequests.component.css',
    '../../user/user.component.css',
    '../../feed/feed.component.css',
    '../../settings/settingspage/settings.component.css',
    '../../user/followers/followers.component.css'
  ]
})
export class FollowrequestsComponent {
  diffThresholdForReachedBottom: number = 1000
  userID: any = null
  db = getDatabase()

  empty: boolean = false
  items: any[] = []
  loadingInProgress: boolean = false
  latestKey: string | null = '!'

  disableLoading = false

  @ViewChild('contentWrapper') contentWrapper!: ElementRef

  constructor(
    private toast: HotToastService,
    public imgHlp: ImageLoadingService,
    public routingHelper: RoutinghelperService,
    private cacheService: CacheService,
    public router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.userID = AuthService.getUID()
  }

  ngAfterViewInit(): void {
    this.loadItems()
    this.setUpScrollListener()
  }

  loadItems() {
    if (this.loadingInProgress) {
      return
    }
    if (this.empty) {
      return
    }
    if (this.disableLoading) {
      return
    }

    this.loadingInProgress = true
    console.log('loadItems()...')

    const countLoadItems = 25

    const path = `${StrHlp.CLOUD_PATH}/FollowRequests/${this.userID}`

    const q = query(
      ref(this.db, path),
      orderByKey(),
      startAt(this.latestKey),
      limitToFirst(countLoadItems)
    )

    get(q)
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnapshot) => {
            const userID = childSnapshot.key

            // Update latest score:
            // Append ~ because 'xxx!' is the smallest lexicographically larger string than 'xxx'.
            // By doing this, we will still catch all posts, but never repeat the latest loaded one upon the next loading.
            this.latestKey = userID + '!'

            this.loadingInProgress = false

            // add "empty" info
            this.items.push({
              userID: userID,
              imgae$: this.cacheService.getProfileImage(userID!)
            })

            this.loadingInProgress = false
          })
        } else {
          if (this.items.length == 0) {
            this.empty = true
          }
          this.disableLoading = true
          this.loadingInProgress = false
        }
      })
      .catch((error) => {
        console.error(error)
        this.toast.error('Loading has failed')
        this.loadingInProgress = false
      })
  }

  zloadFurtherItemInfo(index: number): void {
    const item = this.items[index]
    const userID = item.userID

    this.cacheService
      .getUsername(userID)
      .pipe(take(1))
      .subscribe((username) => {
        item.username = username

        this.items[index] = item
        this.items = [...this.items] // update items, required for CD OnPush
      })

    this.cacheService
      .getProfileImage(userID)
      .pipe(take(1))
      .subscribe((img) => {
        item.profilePhoto = img
        item.profileImgURL = this.imgHlp.do(img, 50)

        this.items[index] = item
        this.items = [...this.items] // update items, required for CD OnPush
      })

    this.cacheService
      .getVerified(userID)
      .pipe(take(1))
      .subscribe((verified) => {
        item.verified = verified

        this.items[index] = item
        this.items = [...this.items] // update items, required for CD OnPush
      })
  }

  denyRequest(index: number) {
    const item = this.items[index]

    // call cloud function
    const functions = getFunctions()
    const deleteFollowRequest = httpsCallable(functions, 'deleteFollowRequest')

    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    deleteFollowRequest({
      otherUserID: item.userID,
      hubname: StrHlp.CLOUD_PATH
    })
      .then(() => {
        loadingDialogRef.close()
        this.toast.success('Deleted')

        this.items.splice(index, 1)
        this.empty = this.items.length == 0
      })
      .catch((error) => {
        loadingDialogRef.close()
        console.log(error)

        // show error message via dialog
        this.toast.error('Failed')
      })
  }

  allowRequest(index: number) {
    const item = this.items[index]

    // call cloud function
    const functions = getFunctions()
    const acceptFollowRequest = httpsCallable(functions, 'acceptFollowRequest')

    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    acceptFollowRequest({
      otherUserID: item.userID,
      hubname: StrHlp.CLOUD_PATH
    })
      .then(() => {
        loadingDialogRef.close()
        this.toast.success('This user is following you now')

        this.items.splice(index, 1)
        this.empty = this.items.length == 0
      })
      .catch((error) => {
        loadingDialogRef.close()
        console.log(error)

        // show error message via dialog
        this.toast.error('Failed')
      })
  }

  setUpScrollListener() {
    this.contentWrapper.nativeElement.addEventListener('scroll', () => {
      const scrollTop = this.contentWrapper.nativeElement.scrollTop
      const scrollHeight = this.contentWrapper.nativeElement.scrollHeight
      const clientHeight = this.contentWrapper.nativeElement.clientHeight

      const diffToBottom = scrollHeight - scrollTop - clientHeight

      //console.log("scrollDiff: "+diffToBottom);

      if (diffToBottom <= 200) {
        // load new items
        this.loadItems()
      }
    })
  }
}
