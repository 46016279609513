import { Injectable, TransferState, makeStateKey } from '@angular/core'
import { child, get, getDatabase, ref } from 'firebase/database'
import { StrHlp } from '../../services/StringGetter/getstring.service'
import { CacheService } from '../../services/caching/cache-service.service'
import { loadFurtherPostInfo } from '../../services/data/feeddata.service'
import { firstValueFrom } from 'rxjs'
import { IsBrowserService } from '../../services/ssr/isbrowser.service'

const KEY_TS_POST = makeStateKey<{ data: any }>('KEY_TS_POST')

@Injectable({
  providedIn: 'root'
})
export class PostLoaderService {
  rtdb = ref(getDatabase())

  constructor(
    private cacheService: CacheService,
    private isBrowserService: IsBrowserService,
    private transferState: TransferState
  ) {}

  async loadPost(postID: string) {
    let post: any = null

    if (this.isBrowserService.isBrowser()) {
      post = this.transferState.get(KEY_TS_POST, null)
    }

    if (post === null) {
      const snapshot = await get(
        child(this.rtdb, `${StrHlp.CLOUD_PATH}/Photo/${postID}`)
      )
      if (snapshot.exists()) {
        post = snapshot.val()
        post.postID = postID

        post = await loadFurtherPostInfo(post)

        post.username = await firstValueFrom(
          this.cacheService.getUsername(post.userID)
        )
        post.fullname = await firstValueFrom(
          this.cacheService.getFullname(post.userID)
        )

        // transfer state logic
        if (this.isBrowserService.isServer()) {
          this.transferState.set(KEY_TS_POST, post)
        }
      }
    }

    return post
  }
}
