import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'app-loadingcircle',
  templateUrl: './loadingcircle.component.html',
  styleUrls: ['./loadingcircle.component.css']
})
export class LoadingcircleComponent {
  @Input() hasMargin: boolean = true
}
