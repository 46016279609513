<div id="wrapper">
  @if (!considerZoomedIn) {
    <header class="header">
      <div id="side-button-bar">
        <div class="side-button">
          <img
            src="/assets/arrow_back.png"
            class="side-button-img"
            mat-dialog-close
            alt="close"
          />
        </div>
      </div>
      @if (title || desc) {
        <div class="text-header">
          @if (title) {
            <h2 class="title text-line-in-header">{{ title }}</h2>
          }
          @if (desc) {
            <p class="desc text-line-in-header">{{ desc }}</p>
          }
        </div>
      }
    </header>
  }

  <div
    id="image-container"
    #image
    (swipedown)="onSwipeDown()"
    (swipeup)="onSwipeUp()"
  >
    @if (videoID) {
      <iframe
        [src]="videoPlayerUrl"
        class="iframe-video-player"
        loading="lazy"
        allow="accelerometer; gyroscope; autoplay; encrypted-media;"
        allowfullscreen="false"
      >
      </iframe>
    }
  </div>
</div>
