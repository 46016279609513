import { Component } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-versionhistory',
  templateUrl: './versionhistory.component.html',
  styleUrls: ['./versionhistory.component.css']
})
export class VersionhistoryComponent {
  constructor(
    public strHlp: StrHlp,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage(
      'Version history',
      `Information about all ${StrHlp.APP_NAME} versions`
    )
  }
}
