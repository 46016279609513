import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})

/**
 * we treat errors as not-allowed
 */
export class NumberFormatService {
  constructor() {}

  numberWithCommas(x: any): string {
    if (typeof x === 'number') {
      x = String(x)
    } else if (typeof x === 'string') {
      // nothing
    } else {
      return '0'
    }

    return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  abbreviate(x: number): string {
    if (x < 0) {
      return '0'
    }
    return Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(x)
  }
}
