<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Privacy Policy</h1>
  </header>

  <main class="fh-main content" #contentWrapper>
    <h2>Privacy Policy</h2>

    <div #textwrapper id="text-wrapper">
      Last updated: December 07, 2022
      <br />
      <br />The privacy of your data&#8212;and it is your data, not
      ours!&#8212;is a big deal to us. In this policy, we lay out: what data we
      collect and why; how your data is handled; and your rights with respect to
      your data. We promise we never sell your data: never have, never will.
      <br />This policy applies to all products built and maintained by %1$s.
      <br />
      <br />
      <br />
      <h1>What we collect and why</h1>
      Our guiding principle is to collect only what we need. Here&#8217;s what
      that means in practice:
      <br />
      <br />
      <h2>Identity &amp; access</h2>
      When you sign up for a %1$s product, we ask for information such as your
      name, email address. That&#8217;s so you can personalize your new account.
      We may also send you optional surveys from time to time to help us
      understand how you use our products and to make improvements. With your
      consent, we will send you our newsletter and other updates. We also give
      you the option to add a profile picture that displays in our products.
      <br />We&#8217;ll never sell your personal information to third parties,
      and we won&#8217;t use your name or company in marketing statements
      without your permission either.
      <br />
      <br />
      <h2>Billing information</h2>
      If you sign up for a paid %1$s product, you will be asked to provide your
      payment information and billing address. Credit card information is
      submitted directly to our payment processor and doesn&#8217;t hit %1$s. We
      store a record of the payment transaction, including the last 4 digits of
      the credit card number, for purposes of account history, invoicing, and
      billing support. We store your billing address so we can charge you for
      service, calculate any sales tax due, send you invoices, and detect
      fraudulent credit card transactions. We occasionally use aggregate billing
      information to guide our marketing efforts.
      <br />
      <br />
      <h2>Product interactions</h2>
      We store on our servers the content that you upload or receive or maintain
      in your %1$s product accounts. This is so you can use our products as
      intended.
      <br />
      <br />
      <h2>Geolocation data</h2>
      For most of our products, we log the full IP address used to sign up a
      product account and retain that for use in mitigating future spammy
      signups. We also log all account access by full IP address for security
      and fraud prevention purposes, and we keep this login data for as long as
      your product account is active.
      <br />
      <br />
      <h2>Website interactions</h2>
      We collect information about your browsing activity for analytics and
      statistical purposes such as conversion rate testing and experimenting
      with new product designs. This includes, for example, your browser and
      operating system versions, your IP address, which web pages you visited
      and how long they took to load, and which website referred you to us. If
      you have an account and are signed in, these web analytics data are tied
      to your IP address and user account until your account is no longer
      active. The web analytics we use are described further in the Advertising
      and Cookies section.
      <br />
      <br />
      <h2>Anti-bot assessments</h2>
      We use third-party CAPTCHA services across our applications to mitigate
      brute force logins and as a means of spam protection. We have a legitimate
      interest in protecting our apps and the broader Internet community from
      credential stuffing attacks and spam. The CAPTCHA service evaluates
      various information (e.g., IP address, how long the visitor has been on
      the app, mouse movements) to try to detect if the activity is from an
      automated program instead of a human. We retain these data via our
      subprocessor indefinitely for use in spam mitigation.
      <br />
      <br />
      <h2>Advertising and Cookies</h2>
      %1$s runs contextual ads on various third-party platforms such as Google,
      Reddit, and LinkedIn. Users who click on one of our ads will be sent to
      our site. Where permissible under law, we may load an ad-company script on
      their browsers that sets a third-party cookie and sends information to the
      ad network to enable evaluation of the effectiveness of our ads, e.g.,
      which ad they clicked and which keyword triggered the ad, and whether they
      performed certain actions such as clicking a button or submitting a form.
      <br />We also use persistent first-party cookies and some third-party
      cookies to store certain preferences, make it easier for you to use our
      applications, and perform A/B testing as well as support some analytics.
      <br />A cookie is a piece of text stored by your browser. It may help
      remember login information and site preferences. It might also collect
      information such as your browser type, operating system, web pages
      visited, duration of visit, content viewed, and other click-stream data.
      You can adjust cookie retention settings and accept or block individual
      cookies in your browser settings, although our apps won&#8217;t work and
      other aspects of our service may not function properly if you turn cookies
      off.
      <br />
      <br />
      <br />
      <br />
      <h1>Correspondence</h1>
      When you email %1$s with a question or to ask for help, we keep that
      correspondence, including your email address, so that we have a history of
      past correspondence to reference if you reach out in the future.
      <br />
      <br />
      <h2>How we approach mobile app permissions</h2>
      We offer mobile apps for some of our products. Because of how the
      platforms are designed, our apps typically must request your consent
      before accessing contacts, calendar, camera, and other privacy-sensitive
      features of your device. Consent is always optional and our apps will
      function without it, though some features may be unavailable.
      <br />
      <br />
      <h2>When we access or share your information</h2>
      To provide products or services you&#8217;ve requested. We use some
      third-party subprocessors to help run our applications and provide the
      Services to you. You can view the third-party subprocessors we use for
      each of our products. We also use third-party processors for other
      business functions such as managing newsletter subscriptions and sending
      customer surveys.
      <br />No %1$s human looks at your content except for limited purposes with
      your express permission, for example, if an error occurs that stops an
      automated process from working and requires manual intervention to fix.
      These are rare cases, and when they happen, we look for root cause
      solutions as much as possible to avoid them recurring. We may also access
      your data if required in order to respond to legal process (see
      &#8220;When required under applicable law&#8221; below). <br />To help you
      troubleshoot or squash a software bug, with your permission. If at any
      point we need to access your content to help you with a support case, we
      will ask for your consent before proceeding. <br />To investigate,
      prevent, or take action regarding restricted uses. Accessing a
      customer&#8217;s account when investigating potential abuse is a measure
      of last resort. We want to protect the privacy and safety of both our
      customers and the people reporting issues to us, and we do our best to
      balance those responsibilities throughout the process. If we discover you
      are using our products for a restricted purpose, we will take action as
      necessary, including notifying appropriate authorities where warranted.
      <br />
      <br />
      <h2>When required under applicable law.</h2>
      Requests for user data. Our policy is to not respond to government
      requests for user data unless we are compelled by legal process or in
      limited circumstances in the event of an emergency request. However, if
      law enforcement authorities have the necessary warrant, criminal subpoena,
      or court order requiring us to share data, we must comply. It is our
      policy to notify affected users before we share data unless we are legally
      prohibited from doing so, and except in some emergency cases.
      <br />Preservation requests. Similarly, we do not share preserved data
      unless required by law or compelled by a court order that we choose not to
      appeal. Furthermore, unless we receive a proper warrant, court order, or
      subpoena before the required preservation period expires, we will destroy
      any preserved copies of customer data at the end of the preservation
      period. <br />If we are audited by a tax authority, we may be required to
      share billing-related information. If that happens, we will share only the
      minimum needed, such as billing addresses and tax exemption information.
      <br />Finally, if %1$s is acquired by or merges with another company
      &#8212; we don&#8217;t plan on that, but if it happens &#8212; we&#8217;ll
      notify you well before any of your personal information is transferred or
      becomes subject to a different privacy policy.
      <br />
      <br />
      <br />
      <h1>Your rights with respect to your information</h1>
      At %1$s, we strive to apply the same data rights to all customers,
      regardless of their location. Some of these rights include:
      <ul>
        <li>
          <strong>Right to Know.</strong> You have the right to know what
          personal information is collected, used, shared or sold. We outline
          both the categories and specific bits of data we collect, as well as
          how they are used, in this privacy policy.
        </li>
        <li>
          <strong>Right of Access.</strong> This includes your right to access
          the personal information we gather about you, and your right to obtain
          information about the sharing, storage, security and processing of
          that information.
        </li>
        <li>
          <strong>Right to Correction.</strong> You have the right to request
          correction of your personal information.
        </li>
        <li>
          <strong>Right to Erasure / &#8220;To Be Forgotten&#8221;.</strong>
          This is your right to request, subject to certain limitations under
          applicable law, that your personal information be erased from our
          possession and, by extension, from all of our service providers.
          Fulfillment of some data deletion requests may prevent you from using
          %1$s services because our applications may then no longer work. In
          such cases, a data deletion request may result in closing your
          account.
        </li>
        <li>
          <strong>Right to Complain.</strong> You have the right to make a
          complaint regarding our handling of your personal information with the
          appropriate supervisory authority.
        </li>
        <li>
          <strong>Right to Restrict Processing.</strong> This is your right to
          request restriction of how and why your personal information is used
          or processed, including opting out of sale of personal information.
          (Again: we never have and never will sell your personal data.)
        </li>
        <li>
          <strong>Right to Object.</strong> You have the right, in certain
          situations, to object to how or why your personal information is
          processed.
        </li>
        <li>
          <strong>Right to Portability.</strong> You have the right to receive
          the personal information we have about you and the right to transmit
          it to another party.
        </li>
        <li>
          <strong>Right to not Be Subject to Automated Decision-Making.</strong>
          You have the right to object to and prevent any decision that could
          have a legal or similarly significant effect on you from being made
          solely based on automated processes. This right is limited if the
          decision is necessary for performance of any contract between you and
          us, is allowed by applicable law, or is based on your explicit
          consent.
        </li>
        <li>
          <strong>Right to Non-Discrimination.</strong> We do not and will not
          charge you a different amount to use our products, offer you different
          discounts, or give you a lower level of customer service because you
          have exercised your data privacy rights. However, the exercise of
          certain rights may, by virtue of your exercising those rights, prevent
          you from using our Services.
        </li>
      </ul>
      Many of these rights can be exercised by signing in and updating your
      account information. If you have questions about exercising these rights
      or need assistance, please contact us at %2$s. If an authorized agent is
      corresponding on your behalf, we will need written consent with a
      signature from the account holder before proceeding. If you are in the EU
      or UK, you can contact your data protection authority to file a complaint
      or learn more about local privacy laws.
      <br />
      <br />
      <br />
      <h1>How we secure your data</h1>
      All data is encrypted when transmitted from our servers to your browser.
      The database backups are also encrypted. In addition, we go to great
      lengths to secure your data at rest.
      <br />
      <br />
      <h1>Location of site and data</h1>
      Our products and other web properties are operated in the United States.
      If you are located in the European Union, UK, or elsewhere outside of the
      United States, please be aware that any information you provide to us will
      be transferred to and stored in the United States. By using our websites
      or Services and/or providing us with your personal information, you
      consent to this transfer.
      <br />When transferring personal data from the EU <br />The European Data
      Protection Board (EDPB) has issued guidance that personal data transferred
      out of the EU must be treated with the same level of protection that is
      granted under EU privacy law. UK law provides similar safeguards for UK
      user data that is transferred out of the UK. Accordingly, %1$s has adopted
      a data processing guideline to help ensure this protection. <br />There
      are also a few ad hoc cases where EU personal data may be transferred to
      the U.S. in connection with %1$s operations, for instance, if an EU user
      signs up for our newsletter or participates in one of our surveys. Such
      transfers are only occasional and data is transferred under the Article
      49(1)(b) derogation under GDPR and the UK version of GDPR.
      <br />
      <br />We use third party services that declare their own Terms and
      Conditions and Privacy Policy. <br />Link to Terms and Conditions of third
      party service providers used by the app <br /><a
        href="https://policies.google.com/terms"
        >Google Play Services</a
      >, <a href="https://developers.google.com/admob/terms">AdMob</a>,
      <a href="https://firebase.google.com/terms/analytics">Google Analytics</a
      >, <a href="https://appodeal.com/privacy-policy/">Appodeal</a>,
      <a
        href="https://developers.is.com/ironsource-mobile/air/ironsource-mobile-privacy-policy/"
        >IronSource</a
      >,
      <a href="https://www.applovin.com/privacy/">AppLovin</a>
      <br />
      <br />
      <br />
      <h1>Changes &amp; questions</h1>
      We may update this policy as needed to comply with relevant regulations
      and reflect any new practices. Whenever we make a significant change to
      our policies, we will refresh the date at the top of this page and take
      any other appropriate steps to notify users.
      <br />Have any questions, comments, or concerns about this privacy policy,
      your data, or your rights with respect to your information? Please get in
      touch by emailing us at %2$s and we&#8217;ll be happy to try to answer
      them!
    </div>
  </main>
</div>
