import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-donations-progress-bar',
  templateUrl: './donations-progress-bar.component.html',
  styleUrls: ['./donations-progress-bar.component.css']
})
export class DonationsProgressBarComponent {
  @Input() progressPercentage: number = 0
  @Input() useAsButton = false

  get progressPercent(): string {
    return `${this.progressPercentage}%`
  }

  constructor() {}

  ngOnInit(): void {}
}
