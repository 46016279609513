import { Component } from '@angular/core'
import { MatDateFormats } from '@angular/material/core'
import { MatDialog } from '@angular/material/dialog'
import { HotToastService } from '@ngneat/hot-toast'
import { LoadingDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component'
import { get, getDatabase, ref, set } from 'firebase/database'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-pushnotificationsettings',
  templateUrl: './pushnotificationsettings.component.html',
  styleUrls: [
    './pushnotificationsettings.component.css',
    '../settingspage/settings.component.css'
  ]
})
export class PushnotificationsettingsComponent {
  activated_UnreadChats = true
  activated_UnreadNotifs = true
  activated_NewComments = true
  activated_ChatsGeneral = true

  rtdb = getDatabase()

  unreadChatsPath = `${
    StrHlp.CLOUD_PATH
  }/UserSettings/${AuthService.getUID()}/NotificationSettings/unreadChatsTurnedOff`
  unreadNotifsPath = `${
    StrHlp.CLOUD_PATH
  }/UserSettings/${AuthService.getUID()}/NotificationSettings/unreadNotificationsTurnedOff`
  newCommentsPath = `${
    StrHlp.CLOUD_PATH
  }/UserSettings/${AuthService.getUID()}/NotificationSettings/commentsTurnedOff`
  generalChatsPath = `${
    StrHlp.CLOUD_PATH
  }/UserSettings/${AuthService.getUID()}/NotificationSettings/chatsTurnedOff`

  isUpdating = false

  constructor(
    private dialog: MatDialog,
    private toast: HotToastService,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage(
      'Push notifications settings',
      'Push notifications settings'
    )
    this.initLoad()
  }

  async initLoad() {
    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    const snap1 = await get(ref(this.rtdb, this.unreadChatsPath))
    if (snap1.exists()) {
      this.activated_UnreadChats = snap1.val()
    }

    const snap2 = await get(ref(this.rtdb, this.unreadNotifsPath))
    if (snap2.exists()) {
      this.activated_UnreadNotifs = snap2.val()
    }

    const snap3 = await get(ref(this.rtdb, this.newCommentsPath))
    if (snap3.exists()) {
      this.activated_NewComments = snap3.val()
    }

    const snap4 = await get(ref(this.rtdb, this.generalChatsPath))
    if (snap4.exists()) {
      this.activated_ChatsGeneral = snap4.val()
    }

    loadingDialogRef.close()
  }

  async update_UnreadChats() {
    if (this.isUpdating) {
      return
    } else {
      this.isUpdating = true
    }

    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    // cloud
    await set(ref(this.rtdb, this.unreadChatsPath), this.activated_UnreadChats)

    loadingDialogRef.close()
    this.toast.success('Saved')
    this.isUpdating = false
  }

  async update_UnreadNotifs() {
    if (this.isUpdating) {
      return
    } else {
      this.isUpdating = true
    }

    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    // cloud
    await set(
      ref(this.rtdb, this.unreadNotifsPath),
      this.activated_UnreadNotifs
    )

    // UI
    loadingDialogRef.close()
    this.toast.success('Saved')
    this.isUpdating = false
  }

  async update_NewComments() {
    if (this.isUpdating) {
      return
    } else {
      this.isUpdating = true
    }

    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    // cloud
    await set(ref(this.rtdb, this.newCommentsPath), this.activated_NewComments)

    // UI
    loadingDialogRef.close()
    this.toast.success('Saved')
    this.isUpdating = false
  }

  async update_ChatsGeneral() {
    if (this.isUpdating) {
      return
    } else {
      this.isUpdating = true
    }

    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    // cloud
    await set(
      ref(this.rtdb, this.generalChatsPath),
      this.activated_ChatsGeneral
    )

    // UI
    loadingDialogRef.close()
    this.toast.success('Saved')
    this.isUpdating = false
  }
}
