import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-closebutton',
  templateUrl: './closebutton.component.html',
  styleUrls: ['./closebutton.component.css']
})
export class ClosebuttonComponent {
  @Input() historyBack: boolean = true

  onClick() {
    if (this.historyBack) {
      this.goBack()
    }
  }

  goBack() {
    history.back()
  }
}
