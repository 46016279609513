import { Injectable } from '@angular/core'
import { ReplaySubject, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class MainStateService {
  /**
   * emitted value:
   * 0 = feed
   * 1 = clips
   * 3 = notifs
   * 4 = liketab
   * 12 = webapp-install-page
   */
  srollUpSubject = new Subject<number>()

  /**
   * Used for main-nav sidebar, main foot-nav-bar and more
   * For example: "explore" etc
   * All numeric.
   *
   * Different numbers here (bad practice indeed, todo):
   * 0 = feed
   * 1 = clips
   * 2 = explore
   * 3 = chats
   * 4 = notifs
   * 5 = trump
   * 7 = bookmarks
   * 8 = liked
   * 9 = settings
   * 5 = profile (double with trump?!)
   * 12 = webapp-install-page
   */
  currentlySelectedNavTabSubject = new ReplaySubject<number>(1)

  constructor() {}

  emitScrollUpCall(tab: number) {
    this.srollUpSubject.next(tab)
  }

  emitSelectedNavTab(tab: number) {
    this.currentlySelectedNavTabSubject.next(tab)
  }

  ngOnDestroy() {
    this.srollUpSubject.complete()
    this.currentlySelectedNavTabSubject.complete()
  }
}
