<div class="branding-wrapper">
  <img
    id="applogo"
    src="/assets/applogo_transparent.png"
    alt="{{ name }} logo"
  />
  <div id="text-wrapper">
    <div id="apptitle">{{ name }}</div>
    <div id="app-desc">Privacy. Free speech. Social.</div>
  </div>
</div>
