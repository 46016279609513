import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatMenuTrigger } from '@angular/material/menu'
import { HotToastService } from '@ngneat/hot-toast'
import { get, getDatabase, ref, remove, set } from 'firebase/database'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Observable, filter, from, map, of, startWith, take } from 'rxjs'
import { ChatPageComponent } from 'src/app/components/chat-page/chat-page.component'
import { GLOBAL_CHAT_ID } from 'src/app/shared/constants'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { ChatDataService } from 'src/app/shared/services/data/chatdata.service'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { HTMLFormattingService } from 'src/app/shared/services/formatting/html/htmlformatting.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'

@Component({
  selector: 'app-chatitemtemplate',
  templateUrl: './chatitemtemplate.component.html',
  styleUrls: ['./chatitemtemplate.component.css']
})
export class ChatitemtemplateComponent {
  name$?: Observable<string>
  image$?: Observable<string>
  verified$?: Observable<boolean>

  private _item: any = null
  @Input()
  set item(value: any) {
    this._item = value.item
    //console.log(this._item);

    this.indexInList = value.indexInList
    this.itemClickCallback = value.itemClickCallback
    this.pinChatCallback = value.pinChatCallback
    this.unpinChatCallback = value.unpinChatCallback
    this.allowLongTap = value.allowLongTap
    this.hideMenuButton = value.hideMenuButton

    this.itemChanged()
  }

  get item(): any {
    return this._item
  }

  // needed for recycling reasons
  itemChanged() {
    this.userID = AuthService.getUID()
    this.globalChatID = GLOBAL_CHAT_ID

    // SHOULD THIS BE HERE OR NOT??
    // Yes, async pipe takes care of unsubbing
    this.loadFurtherData()
  }

  itemClickCallback: ((item: any) => void) | null = null
  pinChatCallback: ((chatID: string) => void) | null = null
  unpinChatCallback: ((chatID: string) => void) | null = null

  allowLongTap: boolean = true
  hideMenuButton: boolean = false
  indexInList: number = 0

  dbRTDB = getDatabase()
  userID: any = null
  globalChatID: string = ''

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger

  constructor(
    private toast: HotToastService,
    public htmlFormattingService: HTMLFormattingService,
    public numberFormatService: NumberFormatService,
    public dialog: MatDialog,
    public imgHlp: ImageLoadingService,
    public authService: AuthService,
    private twobuttonsdialogService: TwobuttonsdialogService,
    public routingHelper: RoutinghelperService,
    public chataDataService: ChatDataService,
    private cdRef: ChangeDetectorRef,
    private cacheService: CacheService
  ) {}

  triggerMenu() {
    if (this.allowLongTap) {
      // haptic feedback
      SystemService.hapticsImpactMedium()

      this.menuTrigger.openMenu()
    }
  }

  onItemClick() {
    if (this.itemClickCallback) {
      this.itemClickCallback(this.item)
    }
  }

  markAsRead(item: any, index: number): void {
    if (item.isMeUser1) {
      set(
        ref(
          this.dbRTDB,
          `${StrHlp.CLOUD_PATH}/ChatUebersicht/${item.chatID}/countNewMessagesUser1`
        ),
        -1
      )
    } else {
      set(
        ref(
          this.dbRTDB,
          `${StrHlp.CLOUD_PATH}/ChatUebersicht/${item.chatID}/countNewMessagesUser2`
        ),
        -1
      )
    }

    // decrease new-chats counter of me
    remove(
      ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/Chats_AnzahlNeue/${this.userID}/${item.chatID}`
      )
    )

    // local for UI
    item.newMessagesCount = -1
    this.chataDataService.chatList[index] = item
  }

  markAsUnread(item: any, index: number): void {
    if (item.isMeUser1) {
      set(
        ref(
          this.dbRTDB,
          `${StrHlp.CLOUD_PATH}/ChatUebersicht/${item.chatID}/countNewMessagesUser1`
        ),
        0
      )
    } else {
      set(
        ref(
          this.dbRTDB,
          `${StrHlp.CLOUD_PATH}/ChatUebersicht/${item.chatID}/countNewMessagesUser2`
        ),
        0
      )
    }

    // increase new-chats counter of me
    set(
      ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/Chats_AnzahlNeue/${this.userID}/${item.chatID}`
      ),
      true
    )

    // local for UI
    item.newMessagesCount = 0
    this.chataDataService.chatList[index] = item
  }

  deleteFromChatList(item: any, index: number): void {
    this.twobuttonsdialogService.show(
      'Remove chat',
      'This will remove the chat from your list. The messages themselves will not be deleted. Do you want to continue?',
      () => {
        // nothing
      },
      () => {
        // call cloud function
        const functions = getFunctions()
        const removeChatFromChatList = httpsCallable(
          functions,
          'removeChatFromChatList'
        )

        removeChatFromChatList({
          chatID: item.chatID,
          hubname: StrHlp.CLOUD_PATH
        })
          .then((result) => {
            // local for UI
            this.chataDataService.chatList.splice(index, 1)

            this.toast.show('Chat removed')
          })
          .catch((error) => {
            console.log(error)
            this.toast.error('Failed')
          })
      },
      'Cancel',
      'Remove'
    )
  }

  leaveGlobalChat() {
    this.twobuttonsdialogService.show(
      'Leave Global Chat',
      'Do you want to leave the Global Chat?',
      () => {
        // nothing
      },
      () => {
        set(
          ref(
            this.dbRTDB,
            `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/HasLeftGC`
          ),
          true
        )
          .then(() => {
            this.toast.success('Left Global Chat')

            // reload since we need the new-chasts-count-listener to update the fact
            // to not count GC as a chat. Other UI things are taken care of by this as well

            // ssr-guarded
            if (typeof window !== 'undefined') {
              window.location.reload()
            }
          })
          .catch((error) => {
            console.log(error)
            this.toast.error('An error has occurred')
          })
      },
      'Cancel',
      'Leave'
    )
  }

  pinChat(chatID: string) {
    if (this.pinChatCallback) {
      this.pinChatCallback(chatID)
    }
  }
  unpinChat(chatID: string) {
    if (this.unpinChatCallback) {
      this.unpinChatCallback(chatID)
    }
  }

  loadFurtherData() {
    const isGroup = this.item.isGroup
    const fallbackImg = isGroup
      ? '/assets/default_group_pic.jpg'
      : '/assets/default_profile_pic.jpg'

    if (isGroup) {
      const groupID = this.item.chatID

      this.name$ = this.cacheService.getGroupName(groupID)

      this.image$ = this.cacheService.getGroupImage(groupID).pipe(
        map((img) => {
          if (img) {
            return this.imgHlp.do(img, 50)
          } else {
            return fallbackImg
          }
        }),
        startWith(fallbackImg)
      )

      this.verified$ = of(false)
    } else {
      let otherUserID: string
      if (this.userID === this.item.userID1) {
        otherUserID = this.item.userID2
      } else {
        otherUserID = this.item.userID1
      }

      this.name$ = this.cacheService.getUsername(otherUserID)

      this.image$ = this.cacheService.getProfileImage(otherUserID).pipe(
        map((img) => {
          if (img) {
            return this.imgHlp.do(img, 50)
          } else {
            return fallbackImg
          }
        }),
        startWith(fallbackImg)
      )

      this.verified$ = this.cacheService
        .getVerified(otherUserID)
        .pipe(startWith(false))
    }
  }
}
