import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { IsBrowserService } from 'src/app/shared/services/ssr/isbrowser.service'

@Directive({
  selector: '[dontServerRender]',
  standalone: true
})
export class DontServerRenderDirective implements OnInit {
  constructor(
    private isBrowserService: IsBrowserService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit(): void {
    if (this.isBrowserService.isBrowser()) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    }
  }
}
