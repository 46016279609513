import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { StrHlp } from '../../../shared/services/StringGetter/getstring.service'
import {
  child,
  get,
  getDatabase,
  limitToFirst,
  orderByKey,
  query,
  ref,
  startAt
} from 'firebase/database'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { LoadingDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { GroupserviceService } from 'src/app/shared/services/groups/groupservice.service'
import { combineLatest, take } from 'rxjs'

@Component({
  selector: 'app-followers',
  templateUrl: './followers.component.html',
  styleUrls: [
    '../user.component.css',
    '../../feed/feed.component.css',
    '../../settings/settingspage/settings.component.css',
    'followers.component.css'
  ]
})
export class FollowersComponent implements OnInit {
  diffThresholdForReachedBottom: number = 1000

  userID: any = null
  basePath: any = ''
  otherUserID: any = null
  username: any = null

  firstLoadingPostsRound: boolean = true
  empty: boolean = false
  itemLoadingInProgress: boolean = false

  title: string = ''

  rtdb = ref(getDatabase())

  items: any[] = []
  itemIDs: any[] = []
  loadingInProgress: boolean = false
  latestKey: string | null = '!'

  dataReceived: any = null
  badgeNumber: number = 0

  @ViewChild('contentWrapper') contentWrapper!: ElementRef
  isMobile: boolean = false
  scrollIsAtTop: boolean = true // init

  isFollowing = false
  isGroupMembers = false
  groupID = ''
  areYouGroupAdmin = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: HotToastService,
    private route: ActivatedRoute,
    public imgHlp: ImageLoadingService,
    public dialog: MatDialog,
    private twobuttonsdialogService: TwobuttonsdialogService,
    public routingHelper: RoutinghelperService,
    private cacheService: CacheService,
    public router: Router,
    private groupsService: GroupserviceService
  ) {
    // This must be in the constructor, not later
    const currNav = this.router.getCurrentNavigation()
    this.dataReceived = currNav?.extras.state
  }

  ngOnInit(): void {
    this.userID = AuthService.getUID()

    this.isFollowing = this.data.isFollowing
    this.otherUserID = this.data.userID

    this.isGroupMembers = this.data.isGroupMembers
    this.groupID = this.data.groupID
    this.areYouGroupAdmin = this.data.areYouGroupAdmin

    this.badgeNumber = this.data.badgeNumber

    this.isMobile = SystemService.isMobile()

    if (this.isGroupMembers) {
      this.title = 'Group members'
    } else {
      if (this.isFollowing) {
        this.basePath = 'Following'
        this.title = 'Following'
      } else {
        this.basePath = 'Followers'
        this.title = 'Followers'
      }
    }

    //this.titleService.setTitle(`${this.title} - ${StrHlp.APP_NAME}`);

    if (!this.otherUserID) {
      console.log('An error has occurred')
      return
    }
  }

  ngAfterViewInit(): void {
    this.loadItems()
    this.setUpScrollListener()
  }

  loadItems() {
    if (this.itemLoadingInProgress) {
      return
    }
    if (this.empty) {
      return
    }

    this.itemLoadingInProgress = true
    console.log('loadItems()...')

    const countLoadItems = 25

    let path
    if (this.isGroupMembers) {
      path = `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupID}/Mitglieder`
    } else {
      path = `${StrHlp.CLOUD_PATH}/${this.basePath}/${this.otherUserID}`
    }

    const q = query(
      child(this.rtdb, path),
      orderByKey(),
      startAt(this.latestKey),
      limitToFirst(countLoadItems)
    )

    get(q)
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.firstLoadingPostsRound = false

          snapshot.forEach((childSnapshot) => {
            const userID = childSnapshot.key

            this.itemIDs.push(userID)

            // Update latest score:
            // Append ~ because 'xxx!' is the smallest lexicographically larger string than 'xxx'.
            // By doing this, we will still catch all posts, but never repeat the latest loaded one upon the next loading.
            this.latestKey = userID + '!'

            this.loadingInProgress = false

            // add "empty" info
            this.items.push({
              userID: userID,
              image$: this.cacheService.getProfileImage(userID!),
              isAdmin: this.isGroupMembers && childSnapshot.val() == 1
            })

            this.itemLoadingInProgress = false
          })
        } else {
          if (this.firstLoadingPostsRound) {
            this.empty = true
          } else {
            this.itemLoadingInProgress = false
          }
        }
      })
      .catch((error) => {
        console.error(error)
        this.toast.error('Loading has failed')
        this.itemLoadingInProgress = false
      })
  }

  removeAsFollower(i: number) {
    // ask if sure
    this.twobuttonsdialogService.show(
      'Remove follower',
      'Do you want to remove this user as a follower?',
      () => {
        // nothing
      },
      () => {
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        const userInfo = this.items[i]
        const userID = userInfo.userID

        // call cloud func
        const functions = getFunctions()
        const removeFollower = httpsCallable(functions, 'removeFollower')

        removeFollower({
          hubname: StrHlp.CLOUD_PATH,
          otherUserID: userID
        })
          .then((result) => {
            // UI
            loadingDialogRef.close()
            this.items.splice(i, 1)
          })
          .catch((error) => {
            loadingDialogRef.close()
            console.log(error)
          })
      },
      'Cancel',
      'Okay'
    )
  }

  removeFromGroup(i: number) {
    // ask if sure
    this.twobuttonsdialogService.show(
      'Remove from group',
      'Do you want to remove this user from the group?',
      () => {
        // nothing
      },
      async () => {
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        try {
          const item = this.items[i]
          await this.groupsService.removeFromGroup(this.groupID, item.userID)

          loadingDialogRef.close()
          this.items.splice(i, 1)

          this.toast.success('Success')
        } catch (error) {
          loadingDialogRef.close()
          console.log(error)
        }
      },
      'Cancel',
      'Okay'
    )
  }

  makeAdmin(i: number) {
    // ask if sure
    this.twobuttonsdialogService.show(
      'Make admin',
      'Do you want to give this user admin rights for the group?',
      () => {
        // nothing
      },
      async () => {
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        const item = this.items[i]

        try {
          await this.groupsService.makeAdmin(this.groupID, item.userID)

          item.isAdmin = true
          this.items[i] = item

          loadingDialogRef.close()
          this.toast.success('Success')
        } catch (error) {
          loadingDialogRef.close()
          console.log(error)
        }
      },
      'Cancel',
      'Okay'
    )
  }

  setUpScrollListener() {
    this.contentWrapper.nativeElement.addEventListener('scroll', () => {
      const scrollTop = this.contentWrapper.nativeElement.scrollTop
      const scrollHeight = this.contentWrapper.nativeElement.scrollHeight
      const clientHeight = this.contentWrapper.nativeElement.clientHeight

      const diffToBottom = scrollHeight - scrollTop - clientHeight
      this.scrollIsAtTop = scrollTop == 0

      //console.log("scrollDiff: "+diffToBottom);

      if (diffToBottom <= 200) {
        // load new items
        this.loadItems()
      }
    })
  }
}
