import { Component, Input } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { getDatabase } from 'firebase/database'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { GroupsdataService } from 'src/app/shared/services/data/groupsdata.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { GroupserviceService } from 'src/app/shared/services/groups/groupservice.service'
import { Observable } from 'rxjs'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { ChatPageComponent } from '../../chat-page/chat-page.component'
import { GLOBAL_CHAT_ID } from 'src/app/shared/constants'

@Component({
  selector: 'app-groupitemtemplate',
  templateUrl: './groupitemtemplate.component.html',
  styleUrls: [
    './groupitemtemplate.component.css',
    '../chats/chatitemtemplate/chatitemtemplate.component.css'
  ]
})

/**
 * Everything DEPRECATED since we need a whole group system revolution and backend code
 */
export class GroupitemtemplateComponent {
  indexInList: number = 0

  groupId?: string
  name: string = ''
  image: string = ''
  messageCount: number = 0
  memberCount: number = 0
  timestamp: number = 0

  isGlobalChat = false
  href = ''

  private _item: any = null
  @Input()
  set item(value: any) {
    this._item = value.item
    //console.log(this._item);

    this.indexInList = value.indexInList

    this.itemChanged()
  }

  get item(): any {
    return this._item
  }

  // needed for recycling reasons
  itemChanged() {
    //this.image$ = this.cacheService.getGroupImage(this.item.groupID);
    //this.messageCount$ = this.cacheService.getGroupMessageCount(this.item.groupID);

    this.groupId = this.item.groupId
    this.name = this.item.name
    this.image = this.item.image
    this.messageCount = this.item.messageCount
    this.memberCount = this.item.memberCount
    this.timestamp = this.item.timestamp

    this.isGlobalChat = this.item.groupID === GLOBAL_CHAT_ID

    if (this.isGlobalChat) {
      this.href = `/group/global-chat-room`
    } else {
      this.href = `/group/${this.item.groupID}`
    }
  }

  rtdb = getDatabase()
  userID = AuthService.getUID()

  constructor(
    public numberFormatService: NumberFormatService,
    public dialog: MatDialog,
    public imgHlp: ImageLoadingService,
    public authService: AuthService,
    public groupsdataService: GroupsdataService,
    private groupsService: GroupserviceService
  ) {}

  onItemClick() {
    this.groupsService.attemptJoinGroup(this.item.groupID)
  }
}
