import { Directive, TemplateRef, ViewContainerRef } from '@angular/core'
import { SystemService } from '../shared/services/system/systemservice.service'

@Directive({
  selector: '[appShowOnlyOnMobile]'
})
export class ShowOnlyOnMobileDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit() {
    if (SystemService.isMobile()) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    }
  }
}
