<!--

Where we (usually) make style changes:
- Normal
- <= 1000px width
- <= 600px width

-->

@if (isEU && !agreedToCookies && agreedToCookies_Loaded) {
  <div
  [ngClass]="{
    'cookie-banner': !cookieBannerFullHeight,
    'cookie-banner-full-height': cookieBannerFullHeight
  }"
    #cookiebanner
    >
    <div class="cookie-header">
      <div class="cookie-title">Cookie settings</div>
      <span class="reject-button" (click)="rejectCookies()"> Reject all </span>
    </div>
    <div class="cookie-desc">
      We use cookies and similar technologies on our website and process your
      personal data (e.g. IP address), for example, to personalize content and
      ads, to integrate media from third-party providers or to analyze traffic on
      our website. Data processing may also happen as a result of cookies being
      set. We share this data with third parties that we name in the privacy
      settings. See our
      <span class="privacy-link" routerLink="/privacy">Privacy Policy</span> for
      details.
    </div>
    <div class="consent-wrapper">
      <span
        class="consent-button"
        (click)="agreeToCookies()"
        data-testid="cookie-consent-button"
        >
        Only allow essential cookies
      </span>
    </div>
  </div>
}

@if (showBackOnlineBanner) {
  <div
    class="internet-connection-banner user-back-online"
    >
    Back online
  </div>
}
@if (showOfflineBanner) {
  <div class="internet-connection-banner user-offline">
    No internet connection
  </div>
}

<!-- App content -->
<router-outlet />

<!-- Overlay for heart animations etc. -->
<div class="app-overlay-wrapper" #appOverlayWrapper></div>
