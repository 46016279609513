import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import { getDatabase, ref, set } from 'firebase/database'
import { StrHlp } from '../../shared/services/StringGetter/getstring.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { TrendingdataService } from 'src/app/shared/services/data/trendingdata.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { DatasharingService } from 'src/app/shared/services/data/datasharing.service'
import { MaindataService } from 'src/app/shared/services/data/maindata.service'
import { Observable, Subscription, defaultIfEmpty, map, startWith } from 'rxjs'
import { PremiumComponent } from '../premium/premium/premium.component'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { MainStateService } from 'src/app/shared/services/main/main-state.service'
import { LocalstorageService } from 'src/app/shared/services/ssr/localstorage.service'
import { IsBrowserService } from 'src/app/shared/services/ssr/isbrowser.service'
import { WaitForService } from 'src/app/shared/services/ssr/wait-for.service'

@Component({
  selector: 'app-root',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  // for scroll. Must be static
  public static scrollSavingMap = new Map()

  public static percentGoalReached_DonationsMonthly = 0

  currTabNr: number = -1

  @ViewChild('body') body!: ElementRef

  suggestedQuery?: Observable<string>

  isLoggedIn = this.authService.isLoggedIn()

  userID: any = null
  rtdb = getDatabase()

  @ViewChild('profileImage') profileImage!: ElementRef

  myProfileImageURL: string = ''
  isMobile: boolean = false
  isDarkmode: boolean = false

  showPWA: boolean = false
  showPWA_CancelButton: boolean = false
  key_stamp_last_dismissed_pwa_ad: string = 'key_stamp_last_dismissed_pwa_ad'

  static tempChatBanned_GC: boolean = false
  static permChatBanned_GC: boolean = false
  static tempChatBanned: boolean = false
  static permChatBanned: boolean = false

  lastSeenNotificationsPath = ''
  totalNotificationsPath = ''
  newAbbosPath = ''
  newLikesPath = ''
  newCommentsPath = ''

  profileImg$: Observable<string> | null = null

  constructor(
    private router: Router,
    public imgHlp: ImageLoadingService,
    public strHlp: StrHlp,
    public authService: AuthService,
    public routingHelper: RoutinghelperService,
    public trendingdataService: TrendingdataService,
    private cacheService: CacheService,
    public maindataService: MaindataService,
    public numberFormatService: NumberFormatService,
    private localstorageService: LocalstorageService,
    private isBrowserService: IsBrowserService
  ) {
    this.lastSeenNotificationsPath = `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/LastNotifiesSeenCount`
    this.totalNotificationsPath = `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/TotalNotifiesCount`
    this.newLikesPath = `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/NewLikesCount`
    this.newCommentsPath = `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/NewCommentsCount`
    this.newAbbosPath = `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/NewAbbosCount`

    // trending, explore things
    if (this.isBrowserService.isBrowser()) {
      // if not "explore page", then already loaded on ssr side and state transfered inside explore component
      if (typeof window !== 'undefined') {
        if (!window.location.href.includes('/explore')) {
          this.trendingdataService.load()
        }
      }
    }
  }

  ngOnInit(): void {
    this.userID = AuthService.getUID()
    this.isMobile = SystemService.isMobile()

    // on client side only
    if (this.isBrowserService.isBrowser()) {
      // if logged in
      if (this.userID) {
        PremiumComponent.loadPremium(this.userID)
        this.sendCurrentVersion()
        this.initProfileButton()
      }

      SystemService.setUpPushNotifs()
      this.loadDonationGoalReached()
    }

    this.suggestedQuery = this.trendingdataService.suggestedSearchQuery
  }

  async sendCurrentVersion() {
    if (!AuthService.getUID()) {
      return
    }

    const db = getDatabase()

    // sends curr used version to cloud
    await set(
      ref(
        db,
        `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${AuthService.getUID()}/CurrentAppVersion`
      ),
      StrHlp.VERSION_CODE
    )

    // and saves that user is using / has used PWA once
    if (SystemService.isPWA()) {
      await set(
        ref(
          db,
          `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${AuthService.getUID()}/PWA_Installed`
        ),
        true
      )
    }
  }

  loadDonationGoalReached() {
    const functions = getFunctions()
    const functionToCall = httpsCallable(
      functions,
      'getPercentage_MonthlyDonationGoalReached'
    )
    functionToCall({})
      .then((result) => {
        MainComponent.percentGoalReached_DonationsMonthly =
          result.data as number
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
   * Simple system.
   *
   * If PWA is used, don't show ever.
   *
   * Else:
   * If user not logged in, show it next to the sign in button at top, always
   *
   * Otherwise:
   * Show iff last time this button was cancelled/hidden is 5+ days ago.
   */
  checkIfShowPwaAd() {
    if (SystemService.isPWA()) {
      return
    }

    if (this.authService.isLoggedIn()) {
      const dayInMillis = 86400000
      let stamp: number = 0
      if (
        this.localstorageService.getItem(
          this.key_stamp_last_dismissed_pwa_ad
        ) !== null
      ) {
        stamp = +this.localstorageService.getItem(
          this.key_stamp_last_dismissed_pwa_ad
        )!
      }
      if (Date.now() - stamp > 5 * dayInMillis) {
        this.showPWA = true
        this.showPWA_CancelButton = true
      }
    } else {
      // always show for not logged in users
      this.showPWA = true

      // New: Allow dismissing also for not logged in users
      this.showPWA_CancelButton = true
    }
  }

  dismissPwaAd() {
    this.showPWA = false
    this.showPWA_CancelButton = false
    this.localstorageService.setItem(
      this.key_stamp_last_dismissed_pwa_ad,
      Date.now() + ''
    )
  }

  initProfileButton() {
    this.profileImg$ = this.cacheService.getProfileImage(this.userID).pipe(
      startWith('/assets/default_profile_pic.jpg'),
      map((img) => this.imgHlp.do(img, 50))
    )
  }

  scrollTop() {
    if (this.body) {
      this.body.nativeElement.scrollTop = 0
    }
  }
}
