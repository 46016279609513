import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ChatDataService } from 'src/app/shared/services/data/chatdata.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { ChatPageComponent } from '../../chat-page/chat-page.component'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { take } from 'rxjs'
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet'

@Component({
  selector: 'app-chataction',
  templateUrl: './chataction.component.html',
  styleUrls: [
    './chataction.component.css',
    '../../../stylesheets/dialogs/dialogstylesheet.css'
  ]
})

/**
 * works very simply:
 *
 * displays all chats.
 *
 * if a search query is entered, it "searches", and displays filtered results.
 * If query empty, shows the orig. full chats list again.
 *
 * For searching details, see below
 */
export class ChatactionComponent {
  userID: any = null

  public pageTitle = 'Choose chat...'

  public clickCallback: (chatItem: any) => void
  disableAutoFocus = true

  // search
  searchMode = false
  emptySearch = false

  searchResultList: any[] = []
  // --

  searchCallback: (arg0: string) => void

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public chataDataService: ChatDataService,
    public imgHlp: ImageLoadingService,
    public numberFormatService: NumberFormatService,
    private cacheService: CacheService
  ) {
    this.clickCallback = data.clickCallback
    this.pageTitle = data.pageTitle
    this.disableAutoFocus = data.disableAutoFocus

    this.searchCallback = (arg0: string) => {
      this.performSearch(arg0)
    }
  }

  ngOnInit(): void {
    this.userID = AuthService.getUID()
  }

  /**
   * works all offline. Simple:
   *
   * if query is 3 or less chars, the chat-name must start with this, case independent
   * otherwise, the chat name must only contain it, also case independent
   */
  performSearch(queryEntered: string) {
    const query = queryEntered.trim().toLowerCase()
    console.log('query:', query)

    if (query.length == 0) {
      this.cancelSearch()
    } else {
      // perform search
      this.searchMode = true

      // first empty the filtered list
      this.searchResultList.length = 0

      // ---

      const checkContains = query.length >= 4

      // add all matching chats
      const containMatches: any[] = []

      for (let i = 0; i < this.chataDataService.chatList.length; i++) {
        const chatData = this.chataDataService.chatList[i]

        //console.log(chatData);

        try {
          if (chatData.isGroup) {
            this.cacheService
              .getGroupName(chatData.groupID)
              .pipe(take(1))
              .subscribe((name) => {
                name = name.toLowerCase()
                if (name.startsWith(query)) {
                  this.searchResultList.push(chatData)
                } else {
                  if (checkContains) {
                    if (name.includes(query)) {
                      containMatches.push(chatData)
                    }
                  }
                }
              })
          } else {
            this.cacheService
              .getUsername(chatData.otherUserID)
              .pipe(take(1))
              .subscribe((name) => {
                name = name.toLowerCase()
                if (name.startsWith(query)) {
                  this.searchResultList.push(chatData)
                } else {
                  if (checkContains) {
                    if (name.includes(query)) {
                      containMatches.push(chatData)
                    }
                  }
                }
              })
          }
        } catch (e) {}
      }

      if (checkContains && containMatches.length > 0) {
        this.searchResultList.push(...containMatches)
      }
    }
  }

  cancelSearch() {
    this.searchMode = false
    this.emptySearch = false
  }
}
