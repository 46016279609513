<div class="progress-container" [ngClass]="{ useAsButton: useAsButton }">
  <div class="progress-bar" [style.width]="progressPercent">
    <div class="middle-container">
      <div class="progress-text">{{ progressPercent }} of monthly goal met</div>

      @if (useAsButton) {
        <img
          src="/assets/ic_arrow_right.png"
          class="light-icon learn-more-ad-icon"
          alt="learn more icon"
          />
      }
    </div>
  </div>
</div>
