<div class="item-wrapper">
  <div class="item" (click)="onItemClick()" (longTap)="triggerMenu()">
    <img
      [src]="image$ | async"
      onerror="this.src='/assets/broken_file.png';this.onerror='';"
      class="chatImage"
      loading="lazy"
      alt="{{ name$ | async }} photo"
      />

    <div class="itemSecondWrapper">
      <div class="item-bar-wrapper">
        <app-usernametemplate
          [values]="{
            nameInp: name$ | async,
            verifiedInp: verified$ | async,
            disableClick: true,
            bigType2: true
          }"
          />

        <!--
        Important note:
        We used to apply htmlFormattingService.applyTextFormatting(...) here
        Testwise, we removed it.
        -->
        <div class="timestamp item-bar-end-wrapper">
          {{ item.lastMessageTimestamp | timeformat }}
        </div>
      </div>

      <div class="item-bar-wrapper second-bar">
        @if (
          !chataDataService.typingInfo[item.chatID] &&
          ((item.isPrivate &&
          ((item.isMeUser1 && item.lastMessageFromUser1) ||
          (!item.isMeUser1 && !item.lastMessageFromUser1))) ||
          (item.isGroup && item.lastMessageUID === userID))
          ) {
          <img
            class="double-check"
          [ngClass]="{
            'dark-icon double-check-unread': !(
              (item.isMeUser1 && item.lastMessageSeenByUser2) ||
              (!item.isMeUser1 && item.lastMessageSeenByUser1)
            )
          }"
          src="{{
            (item.isMeUser1 && item.lastMessageSeenByUser2) ||
            (!item.isMeUser1 && item.lastMessageSeenByUser1)
              ? '/assets/ic_double_check_blue.png'
              : '/assets/ic_double_check.png'
          }}"
            alt="read"
            loading="lazy"
            />
        }

        @if (!chataDataService.typingInfo[item.chatID]) {
          <span
            class="messagePreview"
            [innerHTML]="item.lastMessage$ | async"
            >
          </span>
        }

        @if (chataDataService.typingInfo[item.chatID]) {
          <span
            class="messagePreview typingHint"
            >
            {{
            item.isPrivate
            ? 'is typing...'
            : chataDataService.typingInfoUsername[item.chatID] +
            ' is typing...'
            }}
          </span>
        }

        @if (
          item.isPinned ||
          (item.newMessagesCount != -1 && item.chatID !== globalChatID) ||
          (item.newMessagesCount > 0 && item.chatID === globalChatID)
          ) {
          <div
            class="count-showing-div-wrapper count-wrapper item-bar-end-wrapper"
            >
            @if (item.isPinned) {
              <img
                class="dark-icon options-img chat-pinned-icon"
                src="/assets/ic_pin.png"
                loading="lazy"
                alt="pin"
                />
            }
            @if (
              item.newMessagesCount > 0 ||
              (item.newMessagesCount == 0 && !item.isGroup)
              ) {
              <div
                class="count-showing-div-text"
                >
                {{
                item.newMessagesCount == 0
                ? '&nbsp;&nbsp;&nbsp;'
                : (item.newMessagesCount | numberCommaFormat)
                }}
              </div>
            }
          </div>
        }
      </div>
    </div>
  </div>

  <span
    [ngClass]="{
      'zero-width': hideMenuButton,
      'option-button': !hideMenuButton
    }"
    mat-button
    [matMenuTriggerFor]="menu"
    #menuTrigger="matMenuTrigger"
    matTooltip="Options"
    [matTooltipShowDelay]="1000"
    >
    <img
      class="dark-icon option-icon"
      src="/assets/menu.png"
      alt="options"
      loading="lazy"
      />
  </span>

  <mat-menu
    class="standard-options-menu bottom-sheet-matmenu-wrapper"
    #menu="matMenu"
    backdropClass="black-backdrop-light"
    >
    <div appBottomSheetDialogContainer>
      @if (item.isPrivate && item.newMessagesCount >= 0) {
        <div
          class="standard-options-dialog-item"
          (click)="markAsRead(item, indexInList)"
          >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_double_check.png"
              alt="mark as read"
              loading="lazy"
              />
          </span>
          <span class="options-text"> Mark as read </span>
        </div>
      }

      @if (item.isPrivate && item.newMessagesCount == -1) {
        <div
          class="standard-options-dialog-item"
          (click)="markAsUnread(item, indexInList)"
          >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_unread_message.png"
              alt="mark as unread"
              loading="lazy"
              />
          </span>
          <span class="options-text"> Mark as unread </span>
        </div>
      }

      @if (item.isPrivate) {
        <div
          class="standard-options-dialog-item"
          (click)="routingHelper.user(null, item.otherUserID)"
          >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_set_avatar.png"
              alt="profile"
              loading="lazy"
              />
          </span>
          <span class="options-text"> View profile </span>
        </div>
      }

      @if (!item.isPinned) {
        <div
          class="standard-options-dialog-item"
          (click)="pinChat(item.chatID)"
          >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_pin.png"
              alt="pin"
              loading="lazy"
              />
          </span>
          <span class="options-text"> Pin chat </span>
        </div>
      }

      @if (item.isPinned) {
        <div
          class="standard-options-dialog-item"
          (click)="unpinChat(item.chatID)"
          >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/bottomsheet_ic_block.png"
              alt="Unpin"
              loading="lazy"
              />
          </span>
          <span class="options-text"> Unpin chat </span>
        </div>
      }

      @if (item.chatID === globalChatID) {
        <div
          class="standard-options-dialog-item"
          (click)="leaveGlobalChat()"
          >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_set_logout.png"
              alt="Leave"
              loading="lazy"
              />
          </span>
          <span class="options-text"> Leave Global Chat </span>
        </div>
      }

      @if (item.isPrivate || (item.isGroup && item.chatID !== globalChatID)) {
        <div
          class="standard-options-dialog-item"
          (click)="deleteFromChatList(item, indexInList)"
          >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_set_delete.png"
              alt="delete"
              loading="lazy"
              />
          </span>
          <span class="options-text"> Delete from chat list </span>
        </div>
      }

      <app-bottomsheet-bottom-branding-spacer *appShowOnlyOnMobile />
    </div>
  </mat-menu>
</div>
