import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output
} from '@angular/core'
import { IsBrowserService } from '../shared/services/ssr/isbrowser.service'
import { SystemService } from '../shared/services/system/systemservice.service'

@Directive({
  selector: '[longTap]',
  standalone: true
})
export class LongTapDirective implements AfterViewInit {
  @Input() longTapDuration = 250 // Default duration in milliseconds
  @Input() touchMoveThreshold = 10 // Default touch move threshold in pixels
  @Output() longTap = new EventEmitter()

  private timeout: any
  private touchStartX: number = 0
  private touchStartY: number = 0

  constructor(
    private el: ElementRef,
    private isBrowserService: IsBrowserService
  ) {}

  /**
   * moved in here instead of @hostlistener so we can use "passive: true"
   * thats something modern browsers have improving performance. it tells the
   * browser that the touch stuff will not affect scrolling (i think)
   */
  ngAfterViewInit() {
    if (this.isBrowserService.isBrowser()) {
      if (this.el.nativeElement) {
        this.el.nativeElement.addEventListener(
          'touchstart',
          (e: TouchEvent | MouseEvent) => this.onTouchStart(e),
          { passive: true }
        )

        this.el.nativeElement.addEventListener(
          'mousedown',
          (e: TouchEvent | MouseEvent) => this.onTouchStart(e),
          { passive: true }
        )

        this.el.nativeElement.addEventListener(
          'touchmove',
          (e: TouchEvent | MouseEvent) => this.onTouchMove(e),
          { passive: true }
        )

        this.el.nativeElement.addEventListener(
          'onTouchMove',
          (e: TouchEvent | MouseEvent) => this.onTouchMove(e),
          { passive: true }
        )

        this.el.nativeElement.addEventListener(
          'touchend',
          () => this.onTouchEnd(),
          { passive: true }
        )
        this.el.nativeElement.addEventListener(
          'mouseup',
          () => this.onTouchEnd(),
          { passive: true }
        )
        this.el.nativeElement.addEventListener(
          'mouseleave',
          () => this.onTouchEnd(),
          { passive: true }
        )
      }
    }
  }

  onTouchStart(event: TouchEvent | MouseEvent) {
    if (this.isBrowserService.isBrowser()) {
      if (event instanceof TouchEvent) {
        this.touchStartX = event.touches[0].clientX
        this.touchStartY = event.touches[0].clientY
      } else {
        this.touchStartX = event.clientX
        this.touchStartY = event.clientY
      }
      this.timeout = setTimeout(() => {
        this.longTap.emit(event)
        SystemService.hapticsImpactMedium()
        event.preventDefault()
      }, this.longTapDuration)
    }
  }

  onTouchMove(event: TouchEvent | MouseEvent) {
    if (this.isBrowserService.isBrowser()) {
      let touchMoveX: number
      let touchMoveY: number
      if (event instanceof TouchEvent) {
        touchMoveX = event.touches[0].clientX
        touchMoveY = event.touches[0].clientY
      } else {
        touchMoveX = event.clientX
        touchMoveY = event.clientY
      }
      const touchMoveDeltaX = Math.abs(touchMoveX - this.touchStartX)
      const touchMoveDeltaY = Math.abs(touchMoveY - this.touchStartY)
      if (
        touchMoveDeltaX > this.touchMoveThreshold ||
        touchMoveDeltaY > this.touchMoveThreshold
      ) {
        clearTimeout(this.timeout)
      }
    }
  }

  onTouchEnd() {
    if (this.isBrowserService.isBrowser()) {
      clearTimeout(this.timeout)
    }
  }
}
