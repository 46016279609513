import { Component } from '@angular/core'

@Component({
  selector: 'app-usual-divider',
  standalone: true,
  imports: [],
  templateUrl: './usual-divider.component.html',
  styleUrls: ['./usual-divider.component.css', '../divider.styles.css']
})
export class UsualDividerComponent {}
