import { Component } from '@angular/core'

@Component({
  selector: 'app-explore-load-more-item',
  standalone: true,
  imports: [],
  templateUrl: './explore-load-more-item.component.html',
  styleUrls: ['./explore-load-more-item.component.css', '../explore.styles.css']
})
export class ExploreLoadMoreItemComponent {}
