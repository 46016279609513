<div class="fh-page-wrapper">
  <header class="fh-header bottom-border-divider" id="header">
    <app-closebutton [historyBack]="false" mat-dialog-close="" />

    <button
      class="subtle-button action-button"
      [ngClass]="{
        'action-button-active': groupnameEntered.trim().length > 0,
        'action-button-inactive': groupnameEntered.trim().length == 0
      }"
      [disabled]="groupnameEntered.trim().length == 0"
      (click)="actionClick()"
      >
      {{ isForCreating ? 'Create group' : 'Save' }}
    </button>
  </header>

  <main class="fh-main content" id="container">
    @if (isForCreating) {
      <div>
        <h1>Info</h1>
        <p>
          Group admins can edit this information later. Adding users is possible
          after group creation.
        </p>
        <hr class="inline-divider2" />
      </div>
    }

    <h1>Group image</h1>

    <app-imageuploadtemplate
      [mediaChangedCallback]="imageUploadedCallback"
      [initImageURL]="groupImageURL"
      >
    </app-imageuploadtemplate>

    @if (strHlp.getAllowsNSFW()) {
      <p id="nsfw-not-allowed-hint">
        Nudity not allowed here
      </p>
    }

    @if (groupImageURL.length > 0) {
      <div
        class="photoActionButton"
        (click)="deleteGroupImage()"
        >
        <img
          src="/assets/ic_set_delete.png"
          class="dark-icon photoActionIcon"
          alt="delete"
          />
        <p class="photoActionText">Delete photo</p>
      </div>
    }

    <hr class="inline-divider2" />

    <div class="sub-title-wrapper">
      <h1>Group name</h1>

      <img
        id="emojiButton"
        class="dark-icon"
        (click)="emojiButtonClick_GroupName()"
        src="{{
          showEmojiPicker && !emojiPickerOnDesc
            ? '/assets/ic_keyboard.png'
            : '/assets/ic_emoji_button.png'
        }}"
        alt="emoji picker"
        />
    </div>

    <input
      class="name-input"
      placeholder="Group name..."
      maxlength="40"
      onkeydown="return /^[^\[\]{}@§%#<>]+$/g.test(event.key)"
      [(ngModel)]="groupnameEntered"
      />
    <p #fullnameError class="fullname-error">Cannot be empty</p>

    <hr class="inline-divider2" />

    <div class="sub-title-wrapper">
      <h1>Group description</h1>

      <img
        id="emojiButton"
        class="dark-icon"
        (click)="emojiButtonClick_Desc()"
        src="{{
          showEmojiPicker && emojiPickerOnDesc
            ? '/assets/ic_keyboard.png'
            : '/assets/ic_emoji_button.png'
        }}"
        alt="emoji pick"
        />
    </div>

    <textarea
      class="inputArea"
      placeholder="Add a description to your group..."
      maxlength="2000"
      [(ngModel)]="descEntered"
      >
    </textarea>

    <hr class="inline-divider2" />

    <h1>Public group</h1>
    <p class="">
      If your group is public, other users will see it in the "Groups" tab and
      can join.
    </p>
    <div class="checkbox-wrapper">
      <label class="switch">
        <input
          type="checkbox"
          #publicGroupCheck
          [(ngModel)]="isGroupPublic"
          name="action"
          />

        <span class="slider round"></span>
      </label>
      <span class="checkbox-desc">Public group</span>
    </div>

    <hr class="inline-divider2" />
  </main>
</div>
