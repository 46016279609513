import { ChangeDetectionStrategy, Component } from '@angular/core'
import { StrHlp } from '../../shared/services/StringGetter/getstring.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-aboutpage',
  templateUrl: './aboutpage.component.html',
  styleUrls: ['./aboutpage.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AboutpageComponent {
  constructor(
    public StrHlp: StrHlp,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('About us', 'About us')
  }
}
