import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { SetTimeoutService } from '../shared/services/ssr/set-timeout.service'

@Directive({
  standalone: true,
  selector: '[appHideAfter]'
})
export class HideAfterDirective {
  private timeoutId: any

  /**
   * in millis
   */
  @Input() set appHideAfter(hideAfter: number) {
    this.viewContainer.createEmbeddedView(this.templateRef)

    // hide after xx millis
    this.timeoutId = this.setTimeoutService.setTimeout(() => {
      this.viewContainer.clear()
    }, hideAfter)
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private setTimeoutService: SetTimeoutService
  ) {}

  ngOnDestroy() {
    if (this.timeoutId) {
      this.setTimeoutService.clearTimeout(this.timeoutId)
    }
  }
}
