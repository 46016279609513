/**
 * Height predictor of a post
 *
 * !!!!!!!!!!!!!!
 * !!!!!!!!!!!!!!
 * TODO: For now only in mobile mode. IMPROVE ETC. !!!!!!!!!!!!!!
 * !!!!!!!!!!!!!!
 * !!!!!!!!!!!!!!
 */

/**
 * All just a prediction and plus minus accurate
 *
 * generalData is a general, so non-message specific, dict. Eg: for chat, it must include: isPrivateChat
 */
export const itemHeightPredictor = (
  itemType: number,
  m: any,
  generalData: any
) => {
  if (itemType == 0) {
    return predictPostHeight(m, generalData)
  } else if (itemType == 1) {
    return predictMessageHeight(m, generalData)
  } else {
    // TODO
  }

  // error
  return 0
}

function predictPostHeight(m: any, generalData: any): number {
  // post margin
  //const marginTop = 10;
  //const marginBottom = 10;

  // post top wrapper
  const postTopWrapper = 63

  // post bottom wrapper
  const postBottomWrapper = 46

  // caption
  const rowCharCount = 51
  const rowHeight = 21

  const textHeight = Math.ceil(m.caption.length / rowCharCount) * rowHeight
  const captionPadding = textHeight == 0 ? 0 : 14

  // evtl read more button
  // TODO

  // image
  let mediumHeight = 0

  if (m.imagePath) {
    mediumHeight = m.h ? m.h : 500
  }
  if (m.vid) {
    mediumHeight = 500
  }

  const donationBarHeight = m.showMonthlyDonationProgress ? 35 : 0

  // sum up

  const totalHeight =
    //marginTop +
    //marginBottom +
    postTopWrapper +
    postBottomWrapper +
    textHeight +
    captionPadding +
    mediumHeight +
    donationBarHeight

  //console.log("totalHeight:",totalHeight," caption:",m.caption);

  return totalHeight
}

function predictMessageHeight(m: any, generalData: any): number {
  // TODO:
  // For now only for mobile dimensions

  const isPrivateChat: boolean = generalData.isPrivateChat
  const myUserID = generalData.myUserID
  const msgByMyself = myUserID === m.senderUID // ONLY works for groups.

  //const hasMarginTop = shouldMessageHaveMarginTop_Static(items, isPrivateChat,
  //                          myUserID, m, index);

  //const hasMarginBottom = shouldMessageHaveMarginBottom_Static(items, isPrivateChat,
  //                          myUserID, m, index);

  const hasMarginTop = true
  const hasMarginBottom = true

  const usernameWrapperHeight =
    isPrivateChat || msgByMyself ? 0 : hasMarginTop ? 20 : 0

  const marginTop = hasMarginTop ? 3 : 0
  const marginBottom = hasMarginBottom ? 3 : 0

  const hasMedium = m.imageURL || m.gifURL || m.vid
  const mediaHeight = hasMedium ? 300 : 0
  const pureMedium = !m.message && mediaHeight !== 0

  const msgPadding = m.message ? 10 : 0

  // reply msg
  const reply_rowCharCount = 40
  const reply_rowHeight = 17.5
  const replyMsgBottomMargin = 5
  const replyPadding = 10

  const est_LinesCount_Reply = getEstLineCount(
    reply_rowCharCount,
    m.replyMessage,
    false
  )
  const replytextHeight = est_LinesCount_Reply * reply_rowHeight

  const replyTotalHeight =
    replytextHeight == 0
      ? 0
      : replyMsgBottomMargin + replyPadding + replytextHeight

  // message text itself
  const rowCharCount = 35
  const onlyEmojis = onlyEmojis_DisplayBig(m.message)
  const rowHeight = onlyEmojis ? 40 : 20

  const est_LinesCount = getEstLineCount(rowCharCount, m.message)
  let textHeight = est_LinesCount * rowHeight
  if (!m.messageExpanded && !msgByMyself) {
    textHeight = Math.min(300, textHeight)
  }

  if (textHeight == 0) {
    // check if msg is deleted, then it still has a height
    if (!hasMedium) {
      textHeight = 21
    }
  }

  const bottomBarHeight = pureMedium ? 0 : 20

  const donationBarHeight = m.showMonthlyDonationProgress ? 45 : 0

  const dateHint = m.showDateHint ? 74 : 0

  const emojiReactionWrapper =
    m.countLikes > 0 ||
    m.countDislikes > 0 ||
    m.e3c > 0 ||
    m.e4c > 0 ||
    m.e5c > 0 ||
    m.e6c > 0 ||
    m.e7c > 0 ||
    m.e8c > 0 ||
    m.e9c > 0 ||
    m.e10c > 0 ||
    m.e11c > 0 ||
    m.e12c > 0
      ? 17
      : 0

  // TODO:
  //new-activities-hint

  const totalHeight =
    usernameWrapperHeight +
    marginTop +
    msgPadding +
    mediaHeight +
    replyTotalHeight +
    textHeight +
    bottomBarHeight +
    donationBarHeight +
    emojiReactionWrapper +
    marginBottom +
    dateHint
  //-15;

  /*
        console.log("-----------------------------------------------------------------");
        console.log("-----------------------------------------------------------------");
        console.log("-----------------------------------------------------------------");
        console.log("Message:",m.message);
        console.log("-----------------------------------------------------------------");
        console.log("usernameWrapperHeight:",usernameWrapperHeight);
        console.log("marginTop:",marginTop);
        console.log("marginBottom:",marginBottom);
        console.log("msgPadding:",msgPadding);
        console.log("mediaHeight:",mediaHeight);
        console.log("replytextHeight:",replytextHeight);
        console.log("replyMsgBottomMargin:",replyMsgBottomMargin);
        console.log("replyPadding:",replyPadding);
        console.log("reply_rowHeight:",reply_rowHeight);
        console.log("est_LinesCount_Reply:",est_LinesCount_Reply);
        console.log("replyTotalHeight:",replyTotalHeight);
        console.log("est_LinesCount:",est_LinesCount);
        console.log("textHeight:",textHeight);
        console.log("bottomBarHeight:",bottomBarHeight);
        console.log("donationBarHeight:",donationBarHeight);
        console.log("emojiReactionWrapper:",emojiReactionWrapper);
        console.log("dateHint:",dateHint);
        console.log("-----------------------------------------------------------------");

    console.log("Pred-height:",totalHeight,
                ", message:",m.message, 
                ", textHeight:",textHeight, 
                "msgByMyself:",msgByMyself);
    */

  return totalHeight
}

// helper
function getEstLineCount(
  rowLength: number,
  text: string,
  allowLineBreaks = true
) {
  if (allowLineBreaks) {
    const arr2 = text.split(/\r\n|\r|\n/)
    //console.log("drago-lc", arr2);
    let totalCount = 0
    for (let i = 0; i < arr2.length; i++) {
      const text = arr2[i]
      const linCount = Math.ceil(text.length / rowLength)
      //console.log("drago-lc", "linCount:",linCount);
      totalCount += linCount

      if (linCount > 1) {
        // increase because of the linebreak. But only
        // if there is more than one line, otherwise we
        // would count twice
        totalCount++
      }
    }
    return totalCount
  } else {
    return Math.ceil(text.length / rowLength)
  }
}

export function onlyEmojis_DisplayBig(inp: string): boolean {
  const regex = /^\p{Extended_Pictographic}{1,3}$/u
  return regex.test(inp)
}
