<body>
  <div class="stars">
    <div class="central-body">
      <div>
        <h1 class="text404">404</h1>
        <h2 class="text404_desc">Couldn't find this page... 😓</h2>
      </div>

      <br />
      <p class="btn-go-home" (click)="navigateHome()">Home</p>
    </div>

    <div class="objects">
      <img
        class="object_rocket"
        src="/assets/rocket.svg"
        width="20px"
        alt="rocket"
      />
      <div class="earth-moon">
        <img
          class="object_earth"
          src="/assets/earth.svg"
          width="50px"
          alt="earth"
        />
        <img
          class="object_moon"
          src="/assets/moon.svg"
          width="70px"
          alt="moon"
        />
      </div>
      <div class="box_astronaut">
        <img
          class="object_astronaut"
          src="/assets/astronaut.svg"
          width="100px"
          alt="astronaut"
        />
      </div>
    </div>

    <div class="glowing_stars">
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
    </div>
  </div>
</body>
