import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { TwoButtonsDialog } from 'src/app/components/dialogs/two-buttons-dialog/two-buttons-dialog.component'

@Injectable({
  providedIn: 'root'
})
export class TwobuttonsdialogService {
  constructor(public dialog: MatDialog) {}

  show(
    title: string,
    desc: string,
    click1Callback: () => any,
    click2Callback: () => any,
    button1Text: string,
    button2Text: string,
    cancelable: boolean = true
  ) {
    const data = {
      title: title,
      desc: desc,
      clickCallbackButton1: click1Callback,
      clickCallbackButton2: click2Callback,
      button1Text: button1Text,
      button2Text: button2Text
    }

    this.dialog.open(TwoButtonsDialog, {
      disableClose: !cancelable,
      autoFocus: false,
      data: data
    })
  }
}
