import { Injectable } from '@angular/core'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { StrHlp } from '../StringGetter/getstring.service'

@Injectable({
  providedIn: 'root'
})
export class PostActionsService {
  private functions = getFunctions()

  constructor() {}

  getApiProps(post: any) {
    return {
      postID: post.postID,
      hubname: StrHlp.CLOUD_PATH
    }
  }

  likePost(post: any): Promise<unknown> {
    const api = httpsCallable(this.functions, 'likePost')
    return api(this.getApiProps(post))
  }

  unlikePost(post: any): Promise<unknown> {
    const api = httpsCallable(this.functions, 'unlikePost')
    return api(this.getApiProps(post))
  }

  sharePost(post: any): Promise<unknown> {
    const api = httpsCallable(this.functions, 'sharePost')
    return api(this.getApiProps(post))
  }

  bookmarkPost(post: any): Promise<unknown> {
    const api = httpsCallable(this.functions, 'bookmarkPost')
    return api(this.getApiProps(post))
  }

  unbookmarkPost(post: any): Promise<unknown> {
    const api = httpsCallable(this.functions, 'unbookmarkPost')
    return api(this.getApiProps(post))
  }

  deletePost(post: any): Promise<unknown> {
    const api = httpsCallable(this.functions, 'deletePost')
    return api(this.getApiProps(post))
  }

  editPost(post: any, newCaption: string): Promise<unknown> {
    const api = httpsCallable(this.functions, 'editPost')
    return api({
      ...this.getApiProps(post),
      newCaption: newCaption
    })
  }

  markAsNsfw(post: any): Promise<unknown> {
    const api = httpsCallable(this.functions, 'markPostAsNSFW_AfterFix')
    return api({
      ...this.getApiProps(post),
      userID: post.userID
    })
  }

  pinPost(post: any): Promise<unknown> {
    const api = httpsCallable(this.functions, 'pinPostToProfile')
    return api(this.getApiProps(post))
  }

  unpinPost(post: any): Promise<unknown> {
    const api = httpsCallable(this.functions, 'unpinPostToProfile')
    return api(this.getApiProps(post))
  }
}
