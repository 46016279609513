import { Component, ElementRef, Inject, ViewChild } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog'
import { HotToastService } from '@ngneat/hot-toast'
import { get, getDatabase, push, ref, remove, set } from 'firebase/database'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { LoadingDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component'
import {
  getDownloadURL,
  getStorage,
  ref as refStorage,
  uploadString
} from 'firebase/storage'
import { type DatabaseReference } from '@firebase/database'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import firebase from 'firebase/compat/app'
import { GroupserviceService } from 'src/app/shared/services/groups/groupservice.service'
import { mediumToUpload } from '../../templates/upload/mediumToUpload'
import { KeyboardService } from 'src/app/shared/services/overlay/keyboard-service.service'
import { take } from 'rxjs'

@Component({
  selector: 'app-creategroup',
  templateUrl: './creategroup.component.html',
  styleUrls: [
    './creategroup.component.css',
    '../../groupinfo/groupinfo.component.css',
    '../../editprofile/editprofile.component.css',
    '../../makepost/makepost.component.css'
  ]
})
export class CreategroupComponent {
  isForCreating = false
  groupID = '' // given as input if its not for creating

  userID: string = ''
  rtdb = getDatabase()

  groupImageURL = '' // given as input if its not for creating
  groupnameEntered = '' // given as input if its not for creating
  descEntered = '' // given as input if its not for creating

  isGroupPublic = true // default is public group

  @ViewChild('fullnameError') fullnameError!: ElementRef
  @ViewChild('groupImage') groupImage!: ElementRef
  @ViewChild('publicGroupCheck')
  publicGroupCheck!: ElementRef

  imageUploadedCallback: (arr: mediumToUpload[]) => void

  isMobile: boolean = SystemService.isMobile()
  isDarkmode: boolean = SystemService.isDarkmode()

  emojiPickerOnDesc: boolean = false
  showEmojiPicker: boolean = false

  stringRes = ''
  imageRtdbRef: DatabaseReference | null = null

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: HotToastService,
    private dialog: MatDialog,
    public strHlp: StrHlp,
    private cacheService: CacheService,
    private dialogRef: MatDialogRef<CreategroupComponent>,
    private groupsService: GroupserviceService,
    private keyboardService: KeyboardService
  ) {
    this.isForCreating = data.isForCreating

    const groupIdRaw = data.groupID
    if (groupIdRaw && groupIdRaw !== null && groupIdRaw != '') {
      this.groupID = groupIdRaw
    }
    const groupImageURL_Raw = data.groupImageURL
    if (
      groupImageURL_Raw &&
      groupImageURL_Raw !== null &&
      groupImageURL_Raw != ''
    ) {
      this.groupImageURL = groupImageURL_Raw
    }
    const groupName_Raw = data.groupName
    if (groupName_Raw && groupName_Raw !== null && groupName_Raw != '') {
      this.groupnameEntered = groupName_Raw
    }
    const groupDesc_Raw = data.groupDesc
    if (groupDesc_Raw && groupDesc_Raw !== null && groupDesc_Raw != '') {
      this.descEntered = groupDesc_Raw
    }

    this.imageUploadedCallback = async (arr: mediumToUpload[]) => {
      this.stringRes = arr[0].imageAsString!

      // only start immediate uploading if its not creating
      if (!this.isForCreating) {
        const groupImg = await this.startUploading()

        // locally
        this.groupImageURL = groupImg

        // cloud
        if (this.imageRtdbRef) {
          await set(this.imageRtdbRef, groupImg)
        }

        this.toast.success('Group image updated')
      }
    }

    if (this.groupID !== '') {
      this.setGroupImgPath()
    }
  }

  ngOnInit() {
    this.userID = AuthService.getUID()!

    if (!this.isForCreating) {
      this.loadGroupInfo()
    }
  }

  async loadGroupInfo() {
    // public/private
    const puPrSnap = await get(
      ref(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupID}/Infos/Public`
      )
    )
    if (puPrSnap.exists()) {
      this.isGroupPublic = puPrSnap.val()
    }
  }

  ngAfterViewInit() {
    this.setUpHandleDocumentClick()
  }

  setGroupImgPath() {
    this.imageRtdbRef = ref(
      this.rtdb,
      `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupID}/Infos/Bild`
    )
  }

  emojiButtonClick_Desc() {
    this.showEmojiPicker = true
    this.emojiPickerOnDesc = true

    this.keyboardService.openEmoji(
      (emj: string) => {
        this.addEmoji(emj)
      },
      () => {
        this.showEmojiPicker = false
      }
    )
  }

  emojiButtonClick_GroupName() {
    this.showEmojiPicker = true
    this.emojiPickerOnDesc = false

    this.keyboardService.openEmoji(
      (emj: string) => {
        this.addEmoji(emj)
      },
      () => {
        this.showEmojiPicker = false
      }
    )
  }

  hideEmojiKeyboard() {
    this.showEmojiPicker = false
  }

  addEmoji(emoji: string) {
    if (this.emojiPickerOnDesc) {
      this.descEntered = `${this.descEntered}${emoji}`
    } else {
      this.groupnameEntered = `${this.groupnameEntered}${emoji}`
    }
  }

  /**
   * Only immediate update cloud if its editing and not creating group
   */
  async deleteGroupImage() {
    if (this.isForCreating) {
      // only local changes
      this.groupImageURL = ''
      this.stringRes = ''
    } else {
      // delete in cloud
      await remove(this.imageRtdbRef!)

      // reflect locally
      this.groupImageURL = ''
      this.stringRes = ''
    }
  }

  /**
   *
   * @returns signed URL for uploaded firebase storage file
   */
  async startUploading(): Promise<string> {
    // UI
    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    const storage = getStorage()

    const storageRef = refStorage(
      storage,
      `${StrHlp.CLOUD_PATH}/photos/gruppenchats/${
        this.groupID
      }/GroupImage/${Date.now()}/img_rsz_`
    )

    try {
      const snapshot = await uploadString(
        storageRef,
        this.stringRes,
        'data_url'
      )

      // get download URL
      const downloadURL = await getDownloadURL(snapshot.ref)
      //console.log('File available at', downloadURL);

      // close loading
      loadingDialogRef.close()

      return downloadURL
    } catch (error) {
      console.log(error)
      this.toast.error('An error has occurred')
      loadingDialogRef.close()
      return ''
    }
  }

  async actionClick() {
    if (this.isForCreating) {
      const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
        disableClose: true
      })

      try {
        // create groupID
        this.groupID = push(ref(this.rtdb, `${StrHlp.CLOUD_PATH}/Chats`)).key!

        // set now that we have a groupID
        this.setGroupImgPath()

        this.cacheService
          .getUsername(this.userID)
          .pipe(take(1))
          .subscribe(async (myUsername) => {
            const timestamp = Date.now()

            // order of the following writes is important

            // set yourself as admin
            await set(
              ref(
                this.rtdb,
                `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupID}/Mitglieder/${this.userID}`
              ),
              1
            )

            // set chatueb
            const preview = `👥 ${myUsername} created this group chat!`
            const chatUeb = {
              groupID: this.groupID,
              lastMessage: preview,
              lastMessageUID: '', //this.userID,
              lastMessageTimestamp: timestamp
            }
            await set(
              ref(
                this.rtdb,
                `${StrHlp.CLOUD_PATH}/ChatUebersicht/${this.groupID}`
              ),
              chatUeb
            )

            // chatUeb list
            await set(
              ref(
                this.rtdb,
                `${StrHlp.CLOUD_PATH}/ChatUebersichtListe/${this.userID}/${this.groupID}`
              ),
              -timestamp
            )

            // set members count
            await set(
              ref(
                this.rtdb,
                `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupID}/Infos/memberCount`
              ),
              1
            )

            // messages count
            await set(
              ref(
                this.rtdb,
                `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupID}/Dynamisch/MessageCount`
              ),
              1
            )

            // other info
            await set(
              ref(
                this.rtdb,
                `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupID}/Infos/Name`
              ),
              this.groupnameEntered
            )
            await set(
              ref(
                this.rtdb,
                `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupID}/Infos/Beschreibung`
              ),
              this.descEntered
            )
            await set(
              ref(
                this.rtdb,
                `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupID}/Infos/CreatedTimestamp`
              ),
              timestamp
            )
            await set(
              ref(
                this.rtdb,
                `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupID}/Infos/Public`
              ),
              this.isGroupPublic
            )

            // firestore
            const fs = getFirestore()
            const firestoreRef = doc(
              fs,
              `${StrHlp.CLOUD_PATH}/Discover/GroupChats/${this.groupID}`
            )
            const data = {
              memberCount: firebase.firestore.FieldValue.increment(1),
              timestamp: timestamp,
              groupID: this.groupID
            }
            await setDoc(firestoreRef, data)

            // check if image was specified, upload if so
            if (this.stringRes !== '') {
              const groupImg = await this.startUploading()

              if (groupImg !== '') {
                // update UI (here)
                this.groupImageURL = groupImg

                // cloud
                await set(this.imageRtdbRef!, groupImg)
              }
            }

            // create invitation link automatically
            await this.groupsService.createLink(this.groupID)

            // UI
            loadingDialogRef.close()
            this.toast.success('Group chat was created')
            this.dialogRef.close()
          })
      } catch (error) {
        console.log(error)
        this.toast.error('An error has occurred')
        loadingDialogRef.close()
      }
    } else {
      const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
        disableClose: true
      })

      try {
        await set(
          ref(
            this.rtdb,
            `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupID}/Infos/Name`
          ),
          this.groupnameEntered
        )
        await set(
          ref(
            this.rtdb,
            `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupID}/Infos/Beschreibung`
          ),
          this.descEntered
        )
        await set(
          ref(
            this.rtdb,
            `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupID}/Infos/Public`
          ),
          this.isGroupPublic
        )

        // UI
        loadingDialogRef.close()
        this.toast.success('Group info updated')
        this.dialogRef.close()
      } catch (error) {
        console.log(error)
        this.toast.error('An error has occurred')
        loadingDialogRef.close()
      }
    }
  }

  setUpHandleDocumentClick() {
    // ssr-guarded
    if (typeof document === 'undefined') {
      return
    }

    const handleDocumentClick = (event: MouseEvent): void => {
      // Check if emoji keyboard is open, and if so, then close if the click is outside of it
      let isEmojiClassFound = false

      if (event && event.composedPath) {
        const path = event.composedPath()
        path.forEach((elem: EventTarget | null) => {
          if (elem && (elem as HTMLElement).classList) {
            const classList = (elem as HTMLElement).classList
            if (classList.contains('emoji-mart')) {
              isEmojiClassFound = true
            }
          }
        })
      }

      const targetID = (event.target as HTMLElement).id
      if (!isEmojiClassFound && targetID !== 'emojiButton') {
        this.hideEmojiKeyboard()
      }
    }

    document.addEventListener('click', handleDocumentClick, false)
  }
}
