import {
  Injectable,
  OnDestroy,
  TransferState,
  makeStateKey
} from '@angular/core'
import { Dictionary } from 'src/app/types/Dictionary'

/**
 * Simple helper for state transfer
 * 
 * Motivation:
 * Since route might change without reload of component, but just paramMap observable
 * firing, we need such a boolean. This will be set "true" at the end of a loading process.
 * A loading process will check if transfer state exists and if so, use that, unless its been
 * used before already.
 * 
 * IMPORTANT.
 * Not provided at root level. Is a different instance for each component.
 * Inject into component via constructor, but also do this!!
      @Component({
        providers: [
          TransferStateHelperService
        ]
      })


      ---


 * actually terrible
 * must be rewritten, me must hash the vars in 
 * an **automatic** way that is optimization compatible!
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class TransferStateHelperService implements OnDestroy {
  private variableSyncedMap: Dictionary<boolean> = {}

  constructor(private transferState: TransferState) {}

  addTransferState<T>(varName: string, value: any) {
    try {
      const key = makeStateKey<T>(varName)
      this.transferState.set(key, value)
    } catch (e) {
      console.error(e)
    }
  }

  wasTransferStateSyncedAlready(varName: string) {
    return Boolean(this.variableSyncedMap[varName])
  }

  /**
   *
   * returns defaultValue also when wasTransferStateSyncedAlready is true!
   *
   * @param varName
   * @param defaultValue
   * @param forceValue if set to true, it disregards wasTransferStateSyncedAlready
   * @returns
   */
  getFromTransferState<T>(
    varName: string,
    defaultValue: T,
    forceValue: boolean = false
  ) {
    if (!forceValue && this.wasTransferStateSyncedAlready(varName)) {
      return defaultValue
    }

    const key = makeStateKey<T>(varName)

    // mark as synced
    this.variableSyncedMap[varName] = true

    return this.transferState.get(key, defaultValue)
  }

  ngOnDestroy(): void {
    this.variableSyncedMap = {}
  }
}
