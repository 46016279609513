<div id="wrapper">
  <div class="fh-page-wrapper">
    <header class="fh-header header bottom-border-divider">
      <app-backbuttontemplate></app-backbuttontemplate>
      <h1 class="title">Feed settings</h1>
    </header>

    <main class="fh-main content">
      <h2 class="sub-title">Repost settings</h2>
      <p>
        Users can repost your posts. Other users will then see a repost hint,
        and when they click on it, they will see your original post. You can
        disallow reposts of your posts if you don't want this.
      </p>
      <div class="checkbox-wrapper">
        <label class="switch">
          <input
            type="checkbox"
            #allowRepostsCheck
            checked
            (click)="onCheckClick()"
          />
          <span class="slider round"></span>
        </label>
        <span class="checkbox-desc">Allow reposts</span>
      </div>

      <h2 class="sub-title">Reset feed</h2>
      <p>
        This will reset your feed data. This means that all the data that was
        used to personalize your feed will be deleted and everything will start
        over. This can be a good thing if you're not happy with the posts you
        see, but note that you may see posts you've seen before.
      </p>
      <div class="setting-button" (click)="resetFeedDataDialog()">Reset</div>
    </main>
  </div>
</div>
