import { Component } from '@angular/core'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'

@Component({
  selector: 'app-main-branding',
  standalone: true,
  imports: [],
  templateUrl: './main-branding.component.html',
  styleUrl: './main-branding.component.css'
})
export class MainBrandingComponent {
  name = StrHlp.APP_NAME
}
