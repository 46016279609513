<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Delete account</h1>
  </header>

  <main class="fh-main content">
    <p class="desc-text remove-middle">
      You will no longer be able to login with this account.<br />
      <br />
      Your profile picture, bio, name, social media links will be deleted. Your
      account will be deleted from all databases.<br />
      <br />
      Your posts will remain. If you want your posts to ne deleted as well, you
      need to go to your profile and do that manually.<br />
      <br />
      All profiles on our other platforms that are linked to this email address
      will also be deleted.
    </p>

    <div class="showAfterContinue" #showAfterContinue>
      <div class="formGroup remove-middle">
        <input
          type="password"
          class="formControl"
          placeholder="Password"
          maxlength="500"
          #password
          [(ngModel)]="passwordEntered"
          />
      </div>

      @if (passwordEntered != '') {
        <p
          class="password-options remove-middle"
          id="showpassword"
          #showpassword
          (click)="changePasswordVisibility()"
          >
          Show password
        </p>
      }

      <p #passwordInvalidError class="email-invalid-error remove-middle">
        Invalid password
      </p>

      <div class="formGroup remove-middle">
        <input
          type="text"
          class="formControl"
          placeholder="Why do you want to delete your account?"
          maxlength="5000"
          [(ngModel)]="reasonEntered"
          />
      </div>

      <p #deleteReasonError class="email-invalid-error remove-middle">
        Too short
      </p>

      <button
        class="delete-button remove-middle"
        #deleteButton
        (click)="deleteAccount()"
        >
        Delete Account
      </button>
    </div>

    <button
      class="setting-button remove-middle"
      #continueButton
      (click)="continue()"
      >
      Delete Account
    </button>
  </main>
</div>
