import { Component } from '@angular/core'

@Component({
  selector: 'app-account-banned',
  standalone: true,
  imports: [],
  templateUrl: './account-banned.component.html',
  styleUrls: ['./account-banned.component.css', '../banned.css']
})
export class AccountBannedComponent {}
