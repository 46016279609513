import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HotToastService } from '@ngneat/hot-toast'
import { saveAs } from 'file-saver'
import { IsBrowserService } from '../ssr/isbrowser.service'

@Injectable({
  providedIn: 'root'
})
export class DownloadserviceService {
  constructor(
    private http: HttpClient,
    private toast: HotToastService,
    private isBrowserService: IsBrowserService
  ) {}

  downloadGIF(gifURL: string, downloadFileNameRoot: string) {
    // Make name unique
    const downloadFileName = `${downloadFileNameRoot}_${Date.now()}.gif`
      .toLowerCase()
      .replace(' ', '_')

    this.http.get(gifURL, { responseType: 'blob' }).subscribe({
      next: (blob) => {
        // Save the blob as a file
        saveAs(blob, downloadFileName)
      },
      error: (error) => {
        this.toast.error('Failed')
      },
      complete: () => {
        // ...
      }
    })
  }

  downloadImage(imageUrl: string, downloadFileNameRoot: string): void {
    // ssr-guarded
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    // Make name unique
    const downloadFileName = `${downloadFileNameRoot}_${Date.now()}.jpg`
      .toLowerCase()
      .replace(' ', '_')

    this.http.get(imageUrl, { responseType: 'blob' }).subscribe({
      next: (blob) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          const image = new Image()
          image.src = reader.result as string
          image.onload = () => {
            // Create a canvas element
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')

            if (!context) {
              // Handle error when context is null
              this.toast.error('Failed to download')
              return
            }

            // Set canvas dimensions
            canvas.width = image.width
            canvas.height = image.height

            // Draw the image on the canvas
            context.drawImage(image, 0, 0)

            // Load the logo image
            const logoImage = new Image()
            logoImage.src = 'assets/applogo_watermark.png'
            logoImage.onload = () => {
              const logoWidth = 64
              const logoHeight = 64

              // Determine the position of the logo randomly
              const positions = [
                'top-right',
                'top-left',
                'bottom-left',
                'bottom-right'
              ]
              const randomPosition =
                positions[Math.floor(Math.random() * positions.length)]

              // Calculate the position coordinates
              let x = 0
              let y = 0
              const padding = 10

              if (randomPosition.includes('top')) {
                y = padding
              } else {
                y = canvas.height - logoHeight - padding
              }

              if (randomPosition.includes('left')) {
                x = padding
              } else {
                x = canvas.width - logoWidth - padding
              }

              // Draw the logo on the canvas
              context.drawImage(logoImage, x, y, logoWidth, logoHeight)

              // Convert canvas to image data URL
              const dataUrl = canvas.toDataURL('image/jpeg')

              // Convert data URL to Blob
              const byteString = atob(dataUrl.split(',')[1])
              const ab = new ArrayBuffer(byteString.length)
              const ia = new Uint8Array(ab)
              for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i)
              }
              const blob = new Blob([ab], { type: 'image/jpeg' })

              // Save the blob as a file
              saveAs(blob, downloadFileName)
            }
          }
        }
        reader.readAsDataURL(blob)
      },
      error: (error) => {
        this.toast.error('Failed')
      },
      complete: () => {
        // ...
      }
    })
  }

  downloadVideo(videoURL: string, downloadFileNameRoot: string): void {
    // ssr-guarded
    if (typeof document === 'undefined') {
      return
    }

    // Make name unique
    const downloadFileName = `${downloadFileNameRoot}_${Date.now()}.mp4`
      .toLowerCase()
      .replace(' ', '_')

    fetch(videoURL)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = downloadFileName
        link.click()

        this.toast.close()
        this.toast.success('Video saved')
      })
      .catch((error) => {
        this.toast.close()
        this.toast.error('Failed')
      })
  }
}
