import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { TimeLimitsService } from 'src/app/shared/services/timelimits/timelimits.service'
import { Router } from '@angular/router'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { MainComponent } from '../main/main.component'
import { NotificationsdataService } from 'src/app/shared/services/data/notificationsdata.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { DatasharingService } from 'src/app/shared/services/data/datasharing.service'
import { OnedialogserviceService } from 'src/app/shared/services/dialogs/onedialogservice.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { BreakpointObserver } from '@angular/cdk/layout'
import {
  get,
  getDatabase,
  limitToFirst,
  orderByKey,
  query,
  ref
} from 'firebase/database'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { MatDialog } from '@angular/material/dialog'
import { FollowrequestsComponent } from '../dialogs/followrequests/followrequests.component'
import PullToRefresh from 'pulltorefreshjs'
import { MainStateService } from 'src/app/shared/services/main/main-state.service'
import { Subscription } from 'rxjs'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { SetTimeoutService } from 'src/app/shared/services/ssr/set-timeout.service'

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  @ViewChild('contentWrapper') contentWrapper!: ElementRef
  scrollIsAtTop = true

  userID: any = null
  newNotificationsCount: number = 0
  dataReceived: any = null

  isMobile: boolean = false

  itemSize = 100

  followRequestsCount = 0
  countLoadItems_FollowRequests = 10

  urlHash = ''

  pullToRefresh: any

  scrollUpSubscription: Subscription | null = null

  constructor(
    public imgHlp: ImageLoadingService,
    public router: Router,
    public routingHelper: RoutinghelperService,
    public notificationsdataService: NotificationsdataService,
    public authService: AuthService,
    private cacheService: CacheService,
    public datasharingService: DatasharingService,
    private oneButtonDialogService: OnedialogserviceService,
    public numberFormatService: NumberFormatService,
    breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private mainStateService: MainStateService,
    private seoHelper: SeoHelperService,
    private setTimeoutService: SetTimeoutService
  ) {
    this.seoHelper.setForSomePage('Inbox', 'All notifications at one place.')

    const layoutChanges = breakpointObserver.observe('(max-width: 700px)')
    layoutChanges.subscribe((result) => {
      if (result.matches) {
        this.itemSize = 85 // set the item size for devices with width <= 700px
      }
    })

    // This must be in the constructor, not later
    const currNav = this.router.getCurrentNavigation()
    this.dataReceived = currNav?.extras.state
  }

  ngOnInit(): void {
    this.userID = AuthService.getUID()

    this.isMobile = SystemService.isMobile()

    // get new notif count
    this.newNotificationsCount = this.dataReceived?.newNotificationsCount ?? 0

    // if notif service not init yet, or if new notifs exist: load/reload
    if (
      !this.notificationsdataService.initialized ||
      this.newNotificationsCount > 0
    ) {
      this.notificationsdataService.resetAndLoad()

      // haptic feedback
      if (this.newNotificationsCount > 0) {
        this.setTimeoutService.setTimeout(() => {
          SystemService.hapticsImpactMedium()
        }, 300)
      }
    }

    this.checkFollowRequests()

    // scroll to top listener
    this.scrollUpSubscription = this.mainStateService.srollUpSubject.subscribe(
      (tab) => {
        if (tab == 4) {
          this.scrollTop()
        }
      }
    )
  }

  ngAfterViewInit() {
    this.setUpScrollListener()

    // set url hash
    this.urlHash = this.router.url

    // apply scroll state
    let scrollTop = 0
    if (MainComponent.scrollSavingMap.has(this.urlHash)) {
      scrollTop = MainComponent.scrollSavingMap.get(this.urlHash)
    }
    this.setTimeoutService.setTimeout(() => {
      // must be inside of this
      this.contentWrapper.nativeElement.scrollTop = scrollTop
    }, 0)
    // --

    // pull to refresh
    try {
      this.setUpPullToRefresh()
    } catch (e) {
      console.error(e)
    }
  }

  setUpPullToRefresh() {
    this.pullToRefresh = PullToRefresh.init({
      mainElement: '#body-div',
      shouldPullToRefresh: () => {
        return (
          this.contentWrapper.nativeElement.scrollTop == 0 &&
          (this.notificationsdataService.empty ||
            this.notificationsdataService.itemList.length > 0) &&
          this.dialog.openDialogs.length == 0
        )
      },
      onRefresh: () => {
        // ssr-guarded
        if (typeof window === 'undefined') {
          return
        }
        console.log('Pull to refresh triggered in notifications.')
        window.location.reload()
      }
    })
  }

  scrollTop() {
    if (this.contentWrapper) {
      this.contentWrapper.nativeElement.scrollTop = 0
    }
  }

  saveScrollState() {
    if (this.contentWrapper) {
      MainComponent.scrollSavingMap.set(
        this.urlHash,
        this.contentWrapper.nativeElement.scrollTop
      )
    }
  }

  ngOnDestroy() {
    //console.log("Drago1: router.url="+this.router.url+", scrollTop="+this.body.nativeElement.scrollTop);
    try {
      this.saveScrollState()
    } catch (e) {
      console.error(e)
    }

    // destroy pull to refresh to prevent memory leaks
    if (this.pullToRefresh) {
      this.pullToRefresh.destroy()
    }

    if (this.scrollUpSubscription) {
      this.scrollUpSubscription.unsubscribe()
    }
  }

  async checkFollowRequests() {
    const db = getDatabase()

    const q = query(
      ref(db, `${StrHlp.CLOUD_PATH}/FollowRequests/${this.userID}`),
      orderByKey(),
      limitToFirst(this.countLoadItems_FollowRequests)
    )

    const snap = await get(q)
    if (snap.exists()) {
      this.followRequestsCount = Object.keys(snap.val()).length
    }
  }

  showTotalNotifsDialog() {
    this.oneButtonDialogService.show(
      'Total notifications',
      `You have a total of ${this.numberFormatService.numberWithCommas(
        this.notificationsdataService.totalNotifCount
      )} notifications.`
    )
  }

  onScrollBottom() {
    if (TimeLimitsService.isAllowed_Session('on-scroll-load-new-items', 1000)) {
      this.notificationsdataService.load()
    }
  }

  setUpScrollListener() {
    this.contentWrapper.nativeElement.addEventListener('scroll', () => {
      const scrollTop = this.contentWrapper.nativeElement.scrollTop
      const scrollHeight = this.contentWrapper.nativeElement.scrollHeight
      const clientHeight = this.contentWrapper.nativeElement.clientHeight

      const diffToBottom = scrollHeight - scrollTop - clientHeight
      this.scrollIsAtTop = scrollTop == 0
      //console.log("diffToBottom: "+diffToBottom);

      if (diffToBottom <= 250) {
        // load new items
        this.onScrollBottom()
      }
    })
  }

  openFollowRequests() {
    this.dialog.open(FollowrequestsComponent)
  }
}
