<div id="outter-wrapper">
  <div id="top-branding">
    <img
      id="branding-logo-small"
      alt="Lukas Niessen"
      src="/assets/lukasniessen.jpg"
    />
    <div id="top-braning-text-wrapper">
      <h1>Lukas Niessen</h1>
    </div>
  </div>

  <hr class="inline-divider" />

  <p>
    Please note that I am no longer the owner or CEO of SocialHubs UG.
    <br />
    <br />
    Hello everyone,<br />
    <br />
    I am Lukas Niessen, the founder of SocialHubs UG. I want to take this
    opportunity to thank each and every one of you for using our product. Our
    social media platform is built on the core beliefs of free speech, data
    privacy, and connecting people.<br />
    <br />
    I founded this company back in October 2021 with a vision to create a
    platform that truly values its users. It was not an easy journey, especially
    after the censorship attacks from Google, which included banning all our
    apps and accounts three times and scamming us of more than 30,000 euros. We
    also faced lies from many big media outlets, including Business Insider,
    Yahoo, The Sun, The Independent, Gizmodo, Daily Mail, Chip and many more.
    And we faced censorship and scams from other advertising companies.<br />
    <br />
    Despite all these challenges, I am happy to announce that we have reached
    the milestones of 1 million downloads and 100,000 monthly active users. We
    will continue to do our work despite all the stones put in our way.<br />
    <br />
    Once again, I want to thank each and every one of you for being a part of
    our community. We are happy to have you and provide you with a social
    network that supports free speech, values your privacy, and connects you
    with people from all over the world.<br />
    <br />
    Best, Lukas Niessen
  </p>

  <hr class="inline-divider" />

  <div id="branding-wrapper">
    <img
      id="branding-logo"
      alt="{{ StrHlp.getAppName() }} logo"
      src="/assets/applogo_transparent.png"
    />
    <p id="branding-text">
      {{ StrHlp.getAppName() }} Web {{ StrHlp.getVersionString() }}
    </p>
  </div>
</div>
