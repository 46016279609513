import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { SystemService } from '../system/systemservice.service'

@Injectable({
  providedIn: 'root'
})

/**
 * Helps with not-loading-due-to-same-path issues
 */
export class RoutinghelperService {
  constructor(public router: Router) {}

  user(username: string | null, userID: string) {
    const isMobile = SystemService.isMobile()

    const currentUrlTree = this.router.parseUrl(this.router.url)
    const currentUrlSegments = currentUrlTree.root.children.primary.segments
    const path = currentUrlSegments[0].path

    const data = { userID: userID }
    console.log(data)

    if (username) {
      if (isMobile) {
        this.router.navigate(['/mb-u', username], { state: data })
      } else {
        this.router.navigate([`/@${username}`], { state: data })
      }
    } else if (userID) {
      if (isMobile) {
        this.router.navigate(['/mb-user', userID], { state: data })
      } else {
        this.router.navigate(['/user', userID], { state: data })
      }
    } else {
      console.log('RoutingHelper: Both arguments null or empty...')
    }
  }

  tag(tag: string) {
    const currentUrlTree = this.router.parseUrl(this.router.url)
    const currentUrlSegments = currentUrlTree.root.children.primary.segments
    const path = currentUrlSegments[0].path

    if (path === 'tag') {
      this.router.navigate(['/hashtag', tag])
    } else {
      this.router.navigate(['/tag', tag])
    }
  }
}
