import { CommonModule } from '@angular/common'
import { Component, DestroyRef, inject } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Router, RouterModule } from '@angular/router'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { MaindataService } from 'src/app/shared/services/data/maindata.service'
import { MainStateService } from 'src/app/shared/services/main/main-state.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { NotificationdetailspreviewboxComponent } from '../../templates/notificationdetailspreviewbox/notificationdetailspreviewbox.component'
import { SideNavItemComponent } from './side-nav-item/side-nav-item.component'
import { NumberBadgeComponent } from '../../templates/number-badge/number-badge.component'
import { HideAfterDirective } from 'src/app/directives/hide-after.directive'
import { MatTooltipModule } from '@angular/material/tooltip'
import { Observable } from 'rxjs'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { ImgScaleHelperPipe } from 'src/app/pipes/img-scale-helper.pipe'
import { IsBrowserService } from 'src/app/shared/services/ssr/isbrowser.service'
import { DontServerRenderDirective } from 'src/app/directives/ssr/dont-server-render/dont-server-render.directive'

@Component({
  selector: 'app-main-side-navigation',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NotificationdetailspreviewboxComponent,
    SideNavItemComponent,
    NumberBadgeComponent,
    HideAfterDirective,
    MatTooltipModule,
    ImgScaleHelperPipe,
    DontServerRenderDirective
  ],
  templateUrl: './main-side-navigation.component.html',
  styleUrl: './main-side-navigation.component.css'
})
export class MainSideNavigationComponent {
  selectedTab = 0
  isLoggedIn: boolean = false
  isDarkmode: boolean = false

  showTrump = StrHlp.CLOUD_PATH === 'TESTHUB'

  myUid: string | null = null
  myUsername$?: Observable<string>
  myProfileImage$?: Observable<string>

  private destroyRef = inject(DestroyRef)

  constructor(
    private authService: AuthService,
    private mainStateService: MainStateService,
    public maindataService: MaindataService,
    private router: Router,
    private cacheService: CacheService,
    private isBrowserService: IsBrowserService
  ) {
    this.myUid = this.authService.getUserID()

    if (this.isBrowserService.isBrowser()) {
      this.isLoggedIn = this.authService.isLoggedIn()
      this.isDarkmode = SystemService.isDarkmode()
    }
  }

  ngOnInit(): void {
    if (this.isBrowserService.isBrowser()) {
      this.setUpNavBarLogic()

      if (this.myUid) {
        this.setUpPersonalProfile()
      }
    }
  }

  setUpPersonalProfile() {
    this.myUsername$ = this.cacheService.getUsername(this.myUid!)
    this.myProfileImage$ = this.cacheService.getProfileImage(this.myUid!)
  }

  toggleDarkmode() {
    this.isDarkmode = !this.isDarkmode
    SystemService.updateDarkmode(this.isDarkmode)
  }

  setUpNavBarLogic() {
    this.mainStateService.currentlySelectedNavTabSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((newTabNumber) => (this.selectedTab = newTabNumber))
  }

  openLogin() {
    this.authService.showLoginDialog()
  }

  navClick_0 = () => this.navigationClick(0)
  navClick_1 = () => this.navigationClick(1)
  navClick_2 = () => this.navigationClick(2)
  navClick_3 = () => this.navigationClick(3)
  navClick_4 = () => this.navigationClick(4)
  navClick_5 = () => this.navigationClick(5)
  navClick_7 = () => this.navigationClick(7)
  navClick_8 = () => this.navigationClick(8)
  navClick_9 = () => this.navigationClick(9)

  /**
   * Navigates to this tab if valid input. If already navigated to this tab, scrolls to top.
   */
  navigationClick(tabNr: number) {
    if (this.selectedTab == tabNr) {
      this.mainStateService.emitScrollUpCall(tabNr)
    } else {
      this.mainStateService.emitSelectedNavTab(tabNr)

      if (tabNr == 4) {
        const stateInput = {
          state: {
            newNotificationsCount: this.maindataService.newNotificationsCount
          }
        }
        this.router.navigate(['notifications'], stateInput)
        this.selectedTab = tabNr
      }
    }
  }
}
