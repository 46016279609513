import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { StrHlp } from '../../../shared/services/StringGetter/getstring.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { MainComponent } from '../../main/main.component'
import { Router } from '@angular/router'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { SetTimeoutService } from 'src/app/shared/services/ssr/set-timeout.service'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  userID: any = null

  public isMobile: boolean = false
  public isPWA: boolean = false

  private urlHash = ''
  @ViewChild('contentWrapper') contentWrapper!: ElementRef

  isLoggedIn = this.authService.isLoggedIn()

  constructor(
    public authService: AuthService,
    public StrHlp: StrHlp,
    private twobuttonsdialogService: TwobuttonsdialogService,
    private routingHelper: RoutinghelperService,
    private router: Router,
    private seoHelper: SeoHelperService,
    private setTimeoutService: SetTimeoutService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage(
      'Settings',
      'Settings help you manage your account information, recommended posts, privacy preferences, and profile appereance.'
    )

    this.userID = AuthService.getUID()

    this.isMobile = SystemService.isMobile()
    this.isPWA = SystemService.isPWA()
  }

  ngAfterViewInit() {
    // set url hash
    this.urlHash = this.router.url

    // apply scroll state
    let scrollTop = 0
    if (MainComponent.scrollSavingMap.has(this.urlHash)) {
      scrollTop = MainComponent.scrollSavingMap.get(this.urlHash)
    }
    this.setTimeoutService.setTimeout(() => {
      // must be inside of this
      this.contentWrapper.nativeElement.scrollTop = scrollTop
    }, 0)
    // --
  }

  ngOnDestroy() {
    //console.log("Drago1: router.url="+this.router.url+", scrollTop="+this.body.nativeElement.scrollTop);
    MainComponent.scrollSavingMap.set(
      this.urlHash,
      this.contentWrapper.nativeElement.scrollTop
    )
  }

  askForLogout(): void {
    this.twobuttonsdialogService.show(
      'Log Out',
      'Do you want to log out of your account?',
      () => {
        // nothing
      },
      () => {
        this.authService.signOut()
      },
      'Cancel',
      'Log out'
    )
  }

  helpClick(): void {
    const infoString =
      'To get our help just send us an email. Make sure to include all information so we can help you.'
    const browserInfoString = this.getBrowserInfo()
    const emailStart = `Please do not delete this info: ${StrHlp.APP_NAME} Web 1.0.0 (${browserInfoString})`

    this.twobuttonsdialogService.show(
      'Help',
      infoString,
      () => {
        // nothing
      },
      () => {
        // ssr-guarded
        if (typeof window === 'undefined') {
          return
        }
        window.open('mailto:support@socialhubs.org?body=' + emailStart)
      },
      'Cancel',
      'Send email'
    )
  }

  getBrowserInfo(): string {
    // ssr-guarded
    if (typeof document === 'undefined') {
      return 'unknown'
    }

    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera'
    } else if (navigator.userAgent.indexOf('Edg') != -1) {
      return 'Edge'
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome'
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari'
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox'
    } else if (
      navigator.userAgent.indexOf('MSIE') != -1 ||
      !!document.DOCUMENT_NODE == true
    ) {
      // if IE > 10
      return 'IE'
    } else {
      return 'unknown'
    }
  }

  openSupportAcc() {
    this.routingHelper.user('support', '')
  }

  scrollTop() {
    if (this.contentWrapper) {
      this.contentWrapper.nativeElement.scrollTop = 0
    }
  }
}
