import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './two-buttons-dialog.component.html',
  styleUrls: [
    './two-buttons-dialog.component.css',
    '../../../stylesheets/dialogs/dialogstylesheet.css'
  ]
})
export class TwoButtonsDialog {
  title: string = ''
  desc: string = ''
  button1Text: string = ''
  button2Text: string = ''
  imgSrc = ''
  clickCallbackButton1: () => any = () => {}
  clickCallbackButton2: () => any = () => {}

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TwoButtonsDialog>
  ) {
    this.title = data.title
    this.desc = data.desc
    this.clickCallbackButton1 = data.clickCallbackButton1
    this.clickCallbackButton2 = data.clickCallbackButton2
    this.button1Text = data.button1Text
    this.button2Text = data.button2Text
    this.imgSrc = data.imgSrc
  }

  onButton1Click(): void {
    this.dialogRef.close()
    this.clickCallbackButton1()
  }

  onButton2Click(): void {
    this.dialogRef.close()
    this.clickCallbackButton2()
  }
}
