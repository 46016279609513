import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject
} from '@angular/core'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { HTMLFormattingService } from 'src/app/shared/services/formatting/html/htmlformatting.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { CommentsDialogComponent } from '../../comments-dialog/comments-dialog.component'
import { ReportComponent } from '../../report/report.component'
import { TimeLimitsService } from 'src/app/shared/services/timelimits/timelimits.service'
import { LoadingDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component'
import { HotToastService } from '@ngneat/hot-toast'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { MatDialog } from '@angular/material/dialog'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { FullscreenService } from 'src/app/shared/image/fullscreen.service'
import { TimeformatPipe } from 'src/app/pipes/timeformat.pipe'
import { MatBottomSheet } from '@angular/material/bottom-sheet'

@Component({
  selector: 'app-commenttemplate',
  templateUrl: './commenttemplate.component.html',
  styleUrls: ['./commenttemplate.component.css']
})
export class CommenttemplateComponent {
  private bottomSheet = inject(MatBottomSheet)

  @Input() itemList: any[] = []
  @Input() isReplies: boolean = false
  @Input() originalCommentID: string = ''
  @Input() post: any = null
  @Input() comment: any = null
  @Input() replyClickCallback: ((arg0: string) => void) | null = null
  @Input() indexInList: number = 0

  isLikingInProgress_comments: boolean = false
  userID: any = null

  onDoubleTap: (event: MouseEvent) => void
  onSingleTap: (event: MouseEvent) => void = (e) => {}

  constructor(
    public routingHelper: RoutinghelperService,
    public htmlFormattingService: HTMLFormattingService,
    public numberFormatService: NumberFormatService,
    private toast: HotToastService,
    private twobuttonsdialogService: TwobuttonsdialogService,
    public dialog: MatDialog,
    public imgHlp: ImageLoadingService,
    private authService: AuthService,
    private fullscreenHelper: FullscreenService,
    private timeFormat: TimeformatPipe
  ) {
    this.userID = AuthService.getUID()
    this.onDoubleTap = (event) => this.likeComment(this.indexInList, true)
  }

  dislikeComment(i: number) {
    if (!this.authService.isLoggedIn()) {
      // show login dialog
      this.authService.showLoginDialog()
      return
    }

    if (!TimeLimitsService.isAllowed_Session('give-like', 200)) {
      return
    }

    const comment = this.itemList[i]

    // Do UI anyways
    if (comment.disliked) {
      comment.disliked = false
      comment.dislikeCount--
    } else {
      comment.disliked = true
      comment.dislikeCount++

      if (comment.liked) {
        comment.liked = false
        comment.likeCount--
      }
    }

    // actual UI update
    this.itemList[i] = comment

    // call cloud function
    const functions = getFunctions()
    const likeDislikeComment = httpsCallable(functions, 'likeDislikeComment')

    let replyToCommentID = ''
    if (this.isReplies) {
      replyToCommentID = this.originalCommentID
    }

    likeDislikeComment({
      postID: this.post.postID,
      hubname: StrHlp.CLOUD_PATH,
      commentID: comment.commentID,
      replyToCommentID: replyToCommentID,
      isDislike: 'true'
    })
      .then((result) => {
        this.isLikingInProgress_comments = false
      })
      .catch((error) => {
        this.isLikingInProgress_comments = false
        console.log(error)
      })
  }

  likeComment(i: number, doubleTap: boolean = false) {
    if (!this.authService.isLoggedIn()) {
      // show login dialog
      this.authService.showLoginDialog()
      return
    }

    if (!TimeLimitsService.isAllowed_Session('give-like', 200)) {
      return
    }

    const comment = this.itemList[i]

    // Do UI anyways
    if (comment.liked) {
      if (doubleTap) {
        return
      }

      // else
      comment.liked = false
      comment.likeCount--
    } else {
      comment.liked = true
      comment.likeCount++

      // vibrate phone
      SystemService.hapticsImpactMedium()

      if (comment.disliked) {
        comment.disliked = false
        comment.dislikeCount--
      }
    }

    // actual UI update
    this.itemList[i] = comment

    // call cloud function
    const functions = getFunctions()
    const likeDislikeComment = httpsCallable(functions, 'likeDislikeComment')

    let replyToCommentID = ''
    if (this.isReplies) {
      replyToCommentID = this.originalCommentID
    }

    likeDislikeComment({
      postID: this.post.postID,
      hubname: StrHlp.CLOUD_PATH,
      commentID: comment.commentID,
      replyToCommentID: replyToCommentID,
      isLike: 'true'
    })
      .then((result) => {
        this.isLikingInProgress_comments = false
        const data = result.data
      })
      .catch((error) => {
        this.isLikingInProgress_comments = false
        console.log(error)
      })
  }

  reportComment(
    postID: string,
    commentID: string,
    commentUserID: string
  ): void {
    if (!this.authService.isLoggedIn()) {
      // show login dialog
      this.authService.showLoginDialog()
      return
    }

    const reportID =
      postID +
      ReportComponent.partsDivider +
      commentID +
      ReportComponent.partsDivider +
      commentUserID

    this.bottomSheet.open(ReportComponent)
  }

  openRepliesDialog(comment: any, openKeyboardAlready: boolean = false): void {
    if (!this.authService.isLoggedIn()) {
      // show login dialog
      this.authService.showLoginDialog()
      return
    }

    const data = {
      post: this.post,
      isReplies: true,
      originalComment: comment,
      repliesCount: comment.replyCount,
      openKeyboardAlready: openKeyboardAlready
    }

    this.bottomSheet.open(CommentsDialogComponent, {
      data: data
    })
  }

  replyButtonClick(comment: any): void {
    if (!this.authService.isLoggedIn()) {
      // show login dialog
      this.authService.showLoginDialog()
      return
    }

    if (this.isReplies) {
      if (this.replyClickCallback) {
        this.replyClickCallback(comment.username)
      }
    } else {
      // open replies dialog, with the keyboard opening / focusing immediately
      this.openRepliesDialog(comment, true)
    }
  }

  deleteComment(postID: string, comment: any, index: number) {
    if (!this.authService.isLoggedIn()) {
      // show login dialog
      this.authService.showLoginDialog()
      return
    }

    this.twobuttonsdialogService.show(
      'Delete comment',
      'Do you want to delete this comment? This cannot be undone.',
      () => {
        // nothing
      },
      () => {
        const commentID = comment.commentID

        const functions = getFunctions()
        const deleteComment = httpsCallable(functions, 'deleteComment')

        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        let replyToCommentID = ''
        if (this.isReplies) {
          replyToCommentID = this.originalCommentID
        }

        deleteComment({
          postID: postID,
          commentID: commentID,
          hubname: StrHlp.CLOUD_PATH,
          isReply: this.isReplies + '',
          replyToCommentID: replyToCommentID
        })
          .then((result) => {
            loadingDialogRef.close()
            const data = result.data
            this.toast.success('Deleted')

            // UI
            comment.text = ''
            this.itemList[index] = comment
          })
          .catch((error) => {
            loadingDialogRef.close()
            console.log(error)
            this.toast.error('Failed')
          })
      },
      'Cancel',
      'Delete'
    )
  }

  openImageFullscreen(event: any) {
    this.fullscreenHelper.open(
      '',
      this.comment.gifURL,
      `${this.comment.name}, ${this.timeFormat.transform(this.comment.timestamp, 1) as string}`,
      ''
    )

    // stopPropagation since we do not want to open the option dialog
    // if we just click on the reply message
    event.stopPropagation()
  }
}
