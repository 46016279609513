import { Component } from '@angular/core'
import { StrHlp } from '../../shared/services/StringGetter/getstring.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-aboutlukasniessen',
  templateUrl: './aboutlukasniessen.component.html',
  styleUrls: ['../aboutpage/aboutpage.component.css']
})
export class AboutlukasniessenComponent {
  constructor(
    public StrHlp: StrHlp,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('Lukas Niessen', 'About Lukas Niessen')
  }
}
