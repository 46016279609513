/**
 * hides / shows the host but if hidden, it will still occupy space.
 *
 * IT WILL NOT SAVE RESOURCES AS NG-IF DOES AS THE COMPONENT IS STILL RENDERED
 * AND PART OF CHANGE DETECTION.
 *
 * Works with opacity. So el is always there, only transparent perhaps.
 */

/**
 *
 *
 *
 * NO LONGER WORKING AFTER MIGRATION TO ANGULAR 17!!!
 *
 *
 */
import { Directive, Input, Renderer2, ElementRef } from '@angular/core'

@Directive({
  selector: '[appSpaceyIf]'
})
export class SpaceyIfDirective {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  @Input() set appSpaceyIf(condition: boolean) {
    if (condition) {
      this.renderer.setStyle(this.el.nativeElement, 'opacity', '1')
      this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'auto')
    } else {
      this.renderer.setStyle(this.el.nativeElement, 'opacity', '0')
      this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none')
    }
  }
}
