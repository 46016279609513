<mat-dialog-content id="wrapper">
  <div id="title">Legal issue</div>

  @if (stage1) {
    <div>
      <div id="legal-hint-1">
        This will start a legal process. Enter your password to continue.
      </div>
      <div class="formGroup">
        <input
          type="password"
          class="formControl"
          placeholder="Password"
          maxlength="500"
          #userPassword
          [(ngModel)]="passwordEntered"
          />
      </div>
      @if (passwordEntered != '') {
        <p
          class="password-options"
          id="showpassword"
          #showpassword
          (click)="changePasswordVisibility()"
          >
          Show password
        </p>
      }
      <p #passwordError class="email-invalid-error">Password required</p>
      <button class="login-button-enabled" (click)="continueClick()">
        <div class="loginText-enabled">Continue</div>
      </button>
    </div>
  }

  @if (stage2) {
    <div>
      <div class="wrap1">
        <img id="ic-warning" src="/assets/ic_warning.png" alt="Warning" />
      </div>
      <div id="legal-hint-2">Do not make false claims</div>
      <div id="legal-hint-3">
        Submitting a takedown request will start a legal process. False claims or
        misuse of this action, such as submitting false information, will result
        in the permanent suspension of your account and further legal
        consequences.
      </div>
      <div id="agree-wrapper">
        <input type="checkbox" #agree id="agree-terms-checkbox" />
        <label for="agree-terms-checkbox" id="terms-label-2">
          I have read and acknowledge the above information.
        </label>
      </div>
      <p #agreerequired class="email-invalid-error">Required</p>
      <button class="login-button-enabled" (click)="continueClick2()">
        <div class="loginText-enabled">Continue</div>
      </button>
    </div>
  }

  @if (stage3) {
    <div>
      <p id="top-desc">
        Your contact info may be shared with the person who posted the content
        that you're reporting. This report must come from the copyright owner or
        someone authorized to report on their behalf. This report must be written
        in English. We won't be able to process this report otherwise.
      </p>
      <div class="textInputWrapper">
        <p class="inputTitle">
          Your full legal name <span class="requiredStar">*</span>
        </p>
        <textarea class="inputArea" maxlength="2000" [(ngModel)]="fullLegalName">
        </textarea>
      </div>
      <div class="textInputWrapper">
        <p class="inputTitle">Name of the copyright owner</p>
        <textarea
          class="inputArea"
          maxlength="2000"
          [(ngModel)]="nameCopyrightOwner"
          >
        </textarea>
      </div>
      <div class="textInputWrapper">
        <p class="inputTitle">Trademark owner</p>
        <textarea class="inputArea" maxlength="2000" [(ngModel)]="trademarkOwner">
        </textarea>
      </div>
      <div class="textInputWrapper">
        <p class="inputTitle">
          Your physical address <span class="requiredStar">*</span>
        </p>
        <textarea
          class="inputArea"
          maxlength="2000"
          [(ngModel)]="physicalAddress"
          >
        </textarea>
      </div>
      <div class="textInputWrapper">
        <p class="inputTitle">
          Your phone number <span class="requiredStar">*</span>
        </p>
        <textarea class="inputArea" maxlength="2000" [(ngModel)]="phoneNumber">
        </textarea>
      </div>
      <div class="textInputWrapper">
        <p class="inputTitle">
          Your email address <span class="requiredStar">*</span>
        </p>
        <textarea class="inputArea" maxlength="2000" [(ngModel)]="email">
        </textarea>
      </div>
      <div class="textInputWrapper">
        <p class="inputTitle">
          Provide evidence to proof that you are the copyright/trademark owner or
          someone authorized to report on their behalf.
        </p>
        <textarea
          class="inputArea"
          maxlength="2000"
          [(ngModel)]="proofCopyrightTrademark"
          >
        </textarea>
      </div>
      <div class="textInputWrapper">
        <p class="inputTitle">
          Provide evidence to proof the alleged infringement.
        </p>
        <textarea class="inputArea" maxlength="2000" [(ngModel)]="proofGeneral">
        </textarea>
      </div>
      <div id="agree-wrapper3">
        <input type="checkbox" #agreecheckbox3 id="agree-terms-checkbox3" />
        <label for="agree-terms-checkbox3" id="terms-label-3">
          I state the above information is accurate and complete. I acknowledge
          that all information submitted may be made public in any way by us or a
          third party.
          <span class="requiredStar">*</span>
        </label>
      </div>
      <p #agreerequired3 class="email-invalid-error">Required</p>
      <div class="textInputWrapper">
        <p class="inputTitle">
          Sign your name electronically. <span class="requiredStar">*</span>
        </p>
        <textarea class="inputArea" maxlength="2000" [(ngModel)]="nameSigned">
        </textarea>
      </div>
      <p #invalidInputError class="email-invalid-error">
        You must fill all required fields
      </p>
      <button class="login-button-enabled" (click)="askSubmitClick()">
        <div class="loginText-enabled">Submit</div>
      </button>
    </div>
  }
</mat-dialog-content>
