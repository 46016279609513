<!--
    Two rows.
    Content-row, right-side-row.

    Since on small enough, in particular mobile,
    the main-header (the left side bar) is gone, and so is the right-side row,
    we have the footer-header in the main-row, so in here.
-->
<div class="main-row-container">
  <app-content-row class="main-content-row full-screen-height" />
  <app-right-side-row class="main-right-side-row full-screen-height" />
</div>
