import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { RouterModule } from '@angular/router'
import { NumberBadgeComponent } from 'src/app/components/templates/number-badge/number-badge.component'

@Component({
  selector: 'app-side-nav-item',
  standalone: true,
  imports: [CommonModule, RouterModule, NumberBadgeComponent],
  templateUrl: './side-nav-item.component.html',
  styleUrl: './side-nav-item.component.css'
})
export class SideNavItemComponent {
  @Input()
  handleClickInput?: () => void

  handleClick() {
    if (this.handleClickInput) {
      this.handleClickInput()
    }
  }

  @Input()
  text!: string

  @Input()
  ariaLabel!: string

  @Input()
  link?: string

  @Input()
  isSelected!: boolean

  @Input()
  selectedImgSrc!: string

  @Input()
  notSelectedImgSrc!: string

  @Input()
  imageAlt!: string

  @Input()
  badgeNumberInput?: number

  get badgeNumber() {
    return this.badgeNumberInput ?? 0
  }

  @Input()
  isDarkIcon: boolean = true

  @Input()
  isProfileImage: boolean = false
}
