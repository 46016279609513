<div class="content-row-container full-screen-height">
  <!-- Content comes here via router -->
  <router-outlet />

  <div class="footer-nav-container">
    <app-main-footer-navigation />

    <app-notificationdetailspreviewbox
      id="footer-new-notif-preview-box"
      *appHideAfter="8000"
      [newNotificationsCount]="maindataService.newNotificationsCount"
      [newLikeNotifCount]="maindataService.newLikeNotifCount"
      [newCommentsNotifCount]="maindataService.newCommentsNotifCount"
      [newFollowNotifCount]="maindataService.newFollowNotifCount"
      [newOtherNotifCount]="maindataService.newOtherNotifCount"
      [triangleAtTop]="false"
    />
  </div>
</div>
