<app-usual-divider />

<nav class="main-footer-nav" role="navigation" aria-label="primary">
  <app-footer-nav-item
    ariaLabel="Home"
    link="/home/auto"
    [isSelected]="selectedTab == 0"
    [onClickInput]="navClick_0"
    selectedImgSrc="/assets/nav_home_filled.png"
    notSelectedImgSrc="/assets/nav_home.png"
    imageAlt="Home"
  />

  <app-footer-nav-item
    ariaLabel="Short videos"
    link="/clips"
    [isSelected]="selectedTab == 1"
    [onClickInput]="navClick_1"
    selectedImgSrc="/assets/clips_icon_filled.png"
    notSelectedImgSrc="/assets/clips_icon.png"
    imageAlt="Clips"
  />

  <a
    #postButton
    class="post-button-bottom-nav"
    routerLink="makepost"
    role="link"
  >
    <img
      src="{{
        isDarkmode ? '/assets/post_button_light.png' : '/assets/post_button.png'
      }}"
      class="post-icon-bottom-nav"
      alt="Make post"
    />
  </a>

  <app-footer-nav-item
    ariaLabel="Chats"
    [link]="isLoggedIn ? '/chats' : '/groupchats'"
    [isSelected]="selectedTab == 3"
    [onClickInput]="navClick_3"
    selectedImgSrc="/assets/navbar_chat_selected_black.png"
    notSelectedImgSrc="/assets/navbar_chat_unselected.png"
    imageAlt="Chats"
    [badgeNumberInput]="maindataService.newChatsCount"
  />

  @if (isLoggedIn) {
    <app-footer-nav-item
      ariaLabel="Inbox"
      [isSelected]="selectedTab == 4"
      [onClickInput]="navClick_4"
      selectedImgSrc="/assets/navbar_like_filled.png"
      notSelectedImgSrc="/assets/navbar_like_unfilled.png"
      imageAlt="Inbox"
      [badgeNumberInput]="maindataService.newNotificationsCount"
    />
  } @else {
    <app-footer-nav-item
      ariaLabel="App"
      link="/webapp"
      [isSelected]="selectedTab == 12"
      [onClickInput]="navClick_12"
      selectedImgSrc="/assets/outline_install_desktop_black_36dp_filled.png"
      notSelectedImgSrc="/assets/outline_install_desktop_black_36dp.png"
      imageAlt="App"
    />
  }
</nav>

<div class="footer-safe-area-filler"></div>
