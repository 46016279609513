import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { DatasharingService } from 'src/app/shared/services/data/datasharing.service'
import { TrendingdataService } from 'src/app/shared/services/data/trendingdata.service'
import { EncodingService } from 'src/app/shared/services/encoding/encoding.service'
import { GroupserviceService } from 'src/app/shared/services/groups/groupservice.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { Location } from '@angular/common'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { MainComponent } from '../main/main.component'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { MainStateService } from 'src/app/shared/services/main/main-state.service'
import { Observable, Subscription } from 'rxjs'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { LocalstorageService } from 'src/app/shared/services/ssr/localstorage.service'
import { SetTimeoutService } from 'src/app/shared/services/ssr/set-timeout.service'
import { IsBrowserService } from 'src/app/shared/services/ssr/isbrowser.service'

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.css', '../../stylesheets/sort-choose.css']
})
export class ExploreComponent implements OnDestroy, AfterViewInit, OnInit {
  suggestQueriesIntervalID: any

  inviteLinkID: string | null = null
  urlHash = ''

  @ViewChild('scrollContainer') scrollContainer!: ElementRef

  scrollUpSubscription?: Subscription

  suggestedQuery?: Observable<string>
  isMobile = SystemService.isMobile()

  constructor(
    private router: Router,
    public routingHelper: RoutinghelperService,
    public trendingdataService: TrendingdataService,
    public imgHlp: ImageLoadingService,
    public datasharingService: DatasharingService,
    private route: ActivatedRoute,
    private groupsService: GroupserviceService,
    private location: Location,
    public numberFormatService: NumberFormatService,
    private mainStateService: MainStateService,
    private seoHelper: SeoHelperService,
    private localstorageService: LocalstorageService,
    private setTimeoutService: SetTimeoutService,
    private isBrowserService: IsBrowserService
  ) {}

  /**
   * 0 = this month
   * 1 = all time
   */
  selectedTime: number = 0

  /**
   * 0 = trending
   * 1 = most liked
   * 2 = most viewed
   */
  selectedScore: number = 0

  ngOnInit(): void {
    this.seoHelper.setForSomePage('Explore', this.seoHelper.getGenericDesc())

    // check if an invite link was specified
    this.inviteLinkID = this.route.snapshot.paramMap.get('link')
    if (
      this.inviteLinkID &&
      this.inviteLinkID !== null &&
      this.inviteLinkID !== ''
    ) {
      if (AuthService.isUserLoggedIn()) {
        // was given, show dialog
        this.inviteLinkID = EncodingService.decodeFromURL(this.inviteLinkID)
        this.groupsService.attempJoinGroupWithInvLinkID('', this.inviteLinkID)
      }

      // needed because of going-back and then this being called again endless loop
      this.location.replaceState('/explore/')
    }

    const lastScore: string | null = this.localstorageService.getItem(
      'discover_selected-score'
    )
    if (lastScore !== null) {
      this.selectedScore = +lastScore
    }
    const lastTime = this.localstorageService.getItem('discover_selected-time')
    if (lastTime !== null) {
      this.selectedTime = +lastTime
    }

    this.suggestedQuery = this.trendingdataService.suggestedSearchQuery
  }

  ngAfterViewInit(): void {
    // set url hash
    this.urlHash = this.router.url

    // apply scroll state
    let scrollTop = 0
    if (MainComponent.scrollSavingMap.has(this.urlHash)) {
      scrollTop = MainComponent.scrollSavingMap.get(this.urlHash)
    }
    this.setTimeoutService.setTimeout(() => {
      // must be inside of this
      this.scrollContainer.nativeElement.scrollTop = scrollTop
    }, 0)
    // --

    // scroll to top listener
    this.scrollUpSubscription = this.mainStateService.srollUpSubject.subscribe(
      (tab) => {
        if (tab == 5) {
          this.scrollTop()
        }
      }
    )
  }

  scrollTop() {
    this.scrollContainer.nativeElement.scrollTop = 0
  }

  ngOnDestroy() {
    //console.log("Drago1: router.url="+this.router.url+", scrollTop="+this.body.nativeElement.scrollTop);
    if (this.scrollContainer) {
      MainComponent.scrollSavingMap.set(
        this.urlHash,
        this.scrollContainer.nativeElement.scrollTop
      )
    }

    if (this.scrollUpSubscription) {
      this.scrollUpSubscription.unsubscribe()
    }

    if (!this.isBrowserService.isBrowser()) {
      if (this.suggestQueriesIntervalID) {
        clearInterval(this.suggestQueriesIntervalID)
      }
    }
  }

  startExploring(): void {
    let navigationPath = 'trending' // default

    if (this.selectedScore == 1) {
      navigationPath = 'likes'
    } else if (this.selectedScore == 2) {
      navigationPath = 'views'
    }

    this.router.navigate([`/explore/${navigationPath}`], {
      queryParams: { t: this.selectedTime }
    })
  }

  selectTime(inp: number) {
    this.selectedTime = inp
    this.localstorageService.setItem('discover_selected-time', String(inp))
  }

  selectScore(inp: number) {
    this.selectedScore = inp
    this.localstorageService.setItem('discover_selected-score', String(inp))
  }

  searchQuery(query: string) {
    this.router.navigate([`/discover/${query}`])
  }
}
