import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { StrHlp } from '../../shared/services/StringGetter/getstring.service'
import {
  child,
  get,
  getDatabase,
  limitToFirst,
  orderByKey,
  query,
  ref,
  startAt
} from 'firebase/database'
import { HTMLFormattingService } from 'src/app/shared/services/formatting/html/htmlformatting.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { KeyHelperService } from 'src/app/shared/services/firebase/keyhelper.service'
import { MatDialog } from '@angular/material/dialog'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { TimeLimitsService } from 'src/app/shared/services/timelimits/timelimits.service'
import { EncodingService } from 'src/app/shared/services/encoding/encoding.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { FullscreenService } from 'src/app/shared/image/fullscreen.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { FeeddataService } from 'src/app/shared/services/data/feeddata.service'
import { DatasharingService } from 'src/app/shared/services/data/datasharing.service'
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { ReplaySubject, takeUntil } from 'rxjs'

@Component({
  selector: 'app-liked',
  templateUrl: './liked.component.html',
  styleUrls: [
    './liked.component.css',
    '../feed/feed.component.css',
    '../settings/settingspage/settings.component.css'
  ]
})
export class LikedComponent implements OnInit {
  userID: any = null
  dbRTDB = getDatabase()
  rtdb = ref(getDatabase())
  diffThresholdForReachedBottom: number = 1000
  scrollIsAtTop = true
  @ViewChild('virtualScrollViewport')
  virtualScrollViewport!: CdkVirtualScrollViewport
  private destroyedSubject: ReplaySubject<void> = new ReplaySubject(1)

  // ---
  empty = false
  basePath: string | null = ''
  title: string | null = ''

  // helper list
  postIDs: any[] = []
  postLoadingInProgress = false
  latestKey: string | null = '!'

  itemsCount = 0

  constructor(
    private toast: HotToastService,
    private route: ActivatedRoute,
    public numberFormatService: NumberFormatService,
    public htmlFormattingService: HTMLFormattingService,
    public keyHelperService: KeyHelperService,
    public dialog: MatDialog,
    public imgHlp: ImageLoadingService,
    public encodingService: EncodingService,
    public authService: AuthService,
    public fullscreenHelper: FullscreenService,
    public routingHelper: RoutinghelperService,
    public feedDataService: FeeddataService,
    public datasharingService: DatasharingService,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.userID = AuthService.getUID()

    // initial clean
    this.feedDataService.clearShownPostList()

    this.route.paramMap.pipe(takeUntil(this.destroyedSubject)).subscribe({
      next: (paramMap) => {
        this.reset()

        this.basePath = paramMap.get('basepath')
        if (this.basePath === 'bookmarks') {
          this.basePath = 'Bookmarks'
          this.title = 'Bookmarks'

          this.seoHelper.setForSomePage('Bookmarks', 'Your bookmarked posts')
        } else {
          this.basePath = 'LikedPosts'
          this.title = 'Liked'

          this.seoHelper.setForSomePage('Liked posts', 'Your liked posts')
        }

        this.loadPosts()
        this.loadCount()
      },
      error: (e) => {
        this.toast.error('Error occurred')
        console.log(e)
      }
    })
  }

  reset() {
    this.feedDataService.clearShownPostList()

    this.empty = false
    this.basePath = ''
    this.title = ''

    // helper list
    this.postIDs = []
    this.postLoadingInProgress = false
    this.latestKey = '!'

    this.itemsCount = 0
  }

  ngAfterViewInit(): void {
    this.setUpOnScrollLoader()
  }

  loadCount(): void {
    get(
      child(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/${this.basePath}/${this.userID}/Posts/count`
      )
    )
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.itemsCount = snapshot.val()
        }
      })
      .catch((error) => {
        this.toast.error('An error has occurred')
        console.error(error)
      })
  }

  loadPosts(): void {
    if (this.postLoadingInProgress) {
      return
    }
    this.postLoadingInProgress = true

    const countLoadItems = 10
    let counterLoadedPosts = 0

    const q = query(
      child(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/${this.basePath}/${this.userID}/Posts`
      ),
      orderByKey(),
      startAt(this.latestKey),
      limitToFirst(countLoadItems)
    )

    get(q)
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnapshot) => {
            const postID = childSnapshot.key
            this.postLoadingInProgress = false

            this.postIDs.push(postID)

            // update last score
            this.latestKey = postID + '!'

            //console.log('latestKey: ' + this.latestKey)

            // load the corresponding post
            get(child(this.rtdb, `${StrHlp.CLOUD_PATH}/Photo/${postID}`))
              .then((snapshot) => {
                if (snapshot.exists()) {
                  const post = snapshot.val()
                  post.postID = postID

                  this.feedDataService.appendNewPost(post, -1)

                  // load profile image and username
                  this.feedDataService.easyLoadPostInfo(
                    this.feedDataService.postList.length - 1
                  )
                }

                counterLoadedPosts++
              })
              .catch((error) => {
                console.error(error)
                counterLoadedPosts++
              })
          })
        } else {
          if (this.feedDataService.postList.length == 0) {
            this.empty = true
          }
        }
      })
      .catch((error) => {
        console.error(error)
        this.toast.error('Loading posts has failed')
      })
  }

  setUpOnScrollLoader() {
    this.virtualScrollViewport.elementRef.nativeElement.addEventListener(
      'scroll',
      () => {
        const scrollTop =
          this.virtualScrollViewport.elementRef.nativeElement.scrollTop
        const scrollHeight =
          this.virtualScrollViewport.elementRef.nativeElement.scrollHeight
        const clientHeight =
          this.virtualScrollViewport.elementRef.nativeElement.clientHeight

        this.scrollIsAtTop = scrollTop == 0

        const diffToBottom = scrollHeight - scrollTop - clientHeight
        //console.log("scrollDiff: "+diffToBottom);

        if (diffToBottom <= 400) {
          // load new items
          if (
            TimeLimitsService.isAllowed_Session('on-scroll-load-new-items', 700)
          ) {
            this.loadPosts()
          }
        }
      }
    )
  }
}
