import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import {
  child,
  endAt,
  get,
  getDatabase,
  limitToFirst,
  orderByKey,
  query,
  ref,
  startAt
} from 'firebase/database'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { TimeLimitsService } from 'src/app/shared/services/timelimits/timelimits.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { DatasharingService } from 'src/app/shared/services/data/datasharing.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import {
  query as fsQuery,
  collection,
  getFirestore,
  limit,
  orderBy,
  where,
  getDocs,
  or
} from 'firebase/firestore'
import { MuteUsersService } from 'src/app/shared/services/muteusers.service'
import { FeeddataService } from 'src/app/shared/services/data/feeddata.service'
import { EncodingService } from 'src/app/shared/services/encoding/encoding.service'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { ReplaySubject, takeUntil } from 'rxjs'
import { LocalstorageService } from 'src/app/shared/services/ssr/localstorage.service'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: [
    './search.component.css',
    '../user/user.component.css',
    '../feed/feed.component.css',
    '../settings/settingspage/settings.component.css',
    '../hashtag/hashtag.component.css',
    '../../stylesheets/sort-choose.css'
  ]
})
export class SearchComponent implements OnInit, OnDestroy {
  diffThresholdForReachedBottom: number = 1000
  rtdb = ref(getDatabase())
  db = getFirestore()

  @ViewChild('scrollingEl') scrollingEl?: ElementRef
  scrollIsAtTop = true

  countLoadItems = 14
  countLoadUsers_FY = 3
  countLoadUsers_Tab = 20
  countLoadPosts = 8

  // ---

  userID: any = AuthService.getUID()
  query: any = ''
  queryToSave = ''
  cleanQuery = ''

  emptyUsers = false

  items: any[] = []
  loadingUsersInProgress = false
  latestKey: string | null = '!'
  fulltabUsersLoaded = false // top-tab only loads 3 users

  items_Tags: any[] = []
  loadingInProgress_Tags = false
  latestKey_Tags: string | null = '!'
  fulltabLoaded_Tags = false
  emptyTags = false

  items_Locations: any[] = []
  loadingInProgress_Locations = false
  latestKey_Locations: string | null = '!'
  fulltabLoaded_Locations = false
  emptyLocations = false

  loadPostsInProgress = false
  loadPostsDisabled = false
  latestScore = Number.MAX_VALUE
  emptyPosts = false

  postsQueryString = ''

  /**
   * 0 = most likes
   * 1 = trending
   * 2 = most recent
   */
  sortMode = 0

  /**
   * 0 = all
   * 1 = video
   * 2 = image
   * 3 = text
   */
  contentType = 0

  /**
   * 0 = all
   * 1 = this year
   * 2 = this month
   * 3 = this week
   * 4 = today
   */
  timeframe = 0

  isNSFW = false
  nsfw_choice_key = 'nsfw_choice_key'

  tabselected = 0

  private destroyedSubject: ReplaySubject<void> = new ReplaySubject(1)

  constructor(
    private toast: HotToastService,
    private route: ActivatedRoute,
    public imgHlp: ImageLoadingService,
    public routingHelper: RoutinghelperService,
    private cacheService: CacheService,
    public datasharingService: DatasharingService,
    private muteUsersService: MuteUsersService,
    public feedDataService: FeeddataService,
    public strHlp: StrHlp,
    public encodingService: EncodingService,
    private seoHelper: SeoHelperService,
    private localstorageService: LocalstorageService
  ) {}

  ngOnInit(): void {
    this.loadPersistedNsfwChoice()

    this.route.paramMap
      .pipe(takeUntil(this.destroyedSubject))
      .subscribe((paramMap) => {
        // url has changed
        this.reset()

        if (paramMap.has('q')) {
          this.query = paramMap.get('q')
        } else if (paramMap.has('query')) {
          this.query = paramMap.get('query')
        } else {
          this.toast.error('Error occurred')
          return
        }

        // continue
        this.latestKey = this.query // init
        this.loadData()
      })
  }

  reset() {
    this.query = ''
    this.queryToSave = ''
    this.cleanQuery = ''
    this.emptyUsers = false
    this.items = []
    this.loadingUsersInProgress = false
    this.latestKey = '!'
    this.fulltabUsersLoaded = false
    this.items_Tags = []
    this.loadingInProgress_Tags = false
    this.latestKey_Tags = '!'
    this.fulltabLoaded_Tags = false
    this.emptyTags = false
    this.items_Locations = []
    this.loadingInProgress_Locations = false
    this.latestKey_Locations = '!'
    this.fulltabLoaded_Locations = false
    this.emptyLocations = false
    this.loadPostsInProgress = false
    this.loadPostsDisabled = false
    this.latestScore = Number.MAX_VALUE
    this.emptyPosts = false
    this.postsQueryString = ''
    this.sortMode = 0
    this.contentType = 0
    this.timeframe = 0
    this.isNSFW = false
    this.nsfw_choice_key = 'nsfw_choice_key'
    this.tabselected = 0
  }

  loadData() {
    this.query = this.query
      .replace(/[^a-zA-Z0-9 _.]/g, '')
      .replace('  ', ' ')
      .trim()
    this.query = this.query.replaceAll('.', '@') // since we will use it as a firebase path
    this.query = this.query.replaceAll('\n', '')
    this.query = this.query.toLowerCase()

    this.cleanQuery = this.query.replace(/[^a-zA-Z0-9]/g, '')
    this.latestKey_Tags = this.cleanQuery
    this.latestKey_Locations = this.cleanQuery

    // determine query for post search
    this.postsQueryString = this.getPostQuery(this.query)
    console.log('postsQueryString', this.postsQueryString)

    this.latestKey = this.query // init

    // SEO
    this.seoHelper.setForSomePage(
      `${this.cleanQuery} | ${StrHlp.APP_NAME} Search`,
      `Discover ${this.cleanQuery} videos and images. ${this.seoHelper.getGenericDesc()}. Free ${StrHlp.COMMUNITY_NAME} content`,
      '',
      '',
      true
    )

    this.feedDataService.clearShownPostList()

    this.saveQuery()

    this.loadItems()
  }

  ngAfterViewInit(): void {
    this.setUpOnScrollLoader()
  }

  saveQuery() {
    const functions = getFunctions()
    const functionToCall = httpsCallable(functions, 'onSearchMade')
    functionToCall({
      hubname: StrHlp.CLOUD_PATH,
      searchQuery: this.query
    })
  }

  getPostQuery(input: string): string {
    // Remove all non-alphanumeric characters from the input string
    const cleanedInput = input.replace(/[^a-zA-Z0-9\s]/g, '')

    // Split the cleaned input string into words
    const words = cleanedInput.split(' ')

    // Sort the words in lexicographical order
    words.sort()

    // Join the sorted words into a new string
    const result = words.join(' ')

    return result
  }

  loadItems() {
    // only load users once in here
    if (this.items.length == 0) {
      this.loadUsers(false)
    }

    // posts have load-on-scroll enabled
    this.loadPosts()
  }

  async loadPosts() {
    try {
      if (this.loadPostsDisabled || this.loadPostsInProgress) {
        return
      }
      this.loadPostsInProgress = true

      let queryPathEnd = 'ForYou'
      if (this.isNSFW) {
        queryPathEnd = 'NSFW'
      }

      // get query
      let scoreNameString = 'semiChronoScore'

      if (this.sortMode == 0) {
        scoreNameString = 'likeCount_Real'
      } else if (this.sortMode == 1) {
        scoreNameString = 'viewLikeRatioScore'
      }

      let q = fsQuery(
        collection(
          this.db,
          `${StrHlp.CLOUD_PATH}/Discover/Trending/Posts/${queryPathEnd}`
        ),
        orderBy(scoreNameString, 'desc'),
        limit(this.countLoadPosts),
        where('isVideo', '==', false),
        where(scoreNameString, '<', this.latestScore),
        where('pwsArray', 'array-contains', this.postsQueryString)
      )

      if (q === null || scoreNameString === null) {
        this.toast.error('An error has occurred')
        return
      }

      if (this.contentType == 0) {
        // Keep this in here because all posts are new enough here
        // search was introuced at the same time.
        // Not hashtags tho, hashtags have a lot of posts that dont have
        // contentType specified at all, so we cannot use it for there without
        // excluding all these older posts.
        q = fsQuery(
          q,
          or(
            where('contentType', '==', 1),
            where('contentType', '==', 2),
            where('contentType', '==', 3)
          )
        )
      } else if (this.contentType == 1) {
        q = fsQuery(q, where('contentType', '==', 1))
      } else if (this.contentType == 2) {
        q = fsQuery(q, where('contentType', '==', 2))
      } else if (this.contentType == 3) {
        q = fsQuery(q, where('contentType', '==', 3))
      }

      // Unfortunately, we cannot reduce the amount of
      // indexes by just using timestamp here since
      // then we would have orderBy and where-"<" on different fields
      // so invalid query
      // so we will need many indexes unfortunately
      if (this.timeframe !== 0) {
        const dateAsString = new Date().toISOString() // '2022-05-27T14:51:06.157Z'

        if (this.timeframe == 1) {
          const zyklusString_Jahr = dateAsString.substring(0, 4) // 2022
          q = fsQuery(q, where('zyklusString_Jahr', '==', zyklusString_Jahr))
        } else if (this.timeframe == 2) {
          const zyklusString_Monat = (dateAsString as any)
            .replaceAll('-', '')
            .substring(0, 6) // 202205
          q = fsQuery(q, where('zyklus', '==', zyklusString_Monat))
        } else if (this.timeframe == 3) {
          const zyklusString_Tag = (dateAsString as any)
            .replaceAll('-', '')
            .substring(0, 8) // 20220527
          q = fsQuery(q, where('zyklusString_Tag', '==', zyklusString_Tag))
        }
      }

      // get docs
      const querySnapshot = await getDocs(q)

      if (querySnapshot.size == 0) {
        this.loadPostsDisabled = true

        if (this.feedDataService.postList.length == 0) {
          this.emptyPosts = true
        }

        this.loadPostsInProgress = false
        return
      }

      querySnapshot.forEach((doc) => {
        this.loadPostsInProgress = false
        const postID = doc.id

        // update last score
        this.latestScore = doc.data()[scoreNameString!]

        // load the corresponding post
        get(child(this.rtdb, `${StrHlp.CLOUD_PATH}/Photo/${postID}`))
          .then((snapshot) => {
            if (snapshot.exists()) {
              const post = snapshot.val()
              post.postID = postID

              if (!this.muteUsersService.isMuted(post.userID)) {
                // update feed data service
                this.feedDataService.postList.push(post)

                // load profile image and username
                this.feedDataService.easyLoadPostInfo(
                  this.feedDataService.postList.length - 1
                )

                this.loadPostsInProgress = false

                this.feedDataService.updateForRecycling()
              }
            }
          })
          .catch((error) => {
            console.error(error)
          })
      })
    } catch (e) {
      console.log(e)
      this.loadPostsInProgress = false
    }
  }

  loadUsers(usersTab: boolean) {
    if (this.emptyUsers) {
      return
    }
    if (this.loadingUsersInProgress) {
      return
    }

    this.loadingUsersInProgress = true
    //console.log('loadItems()...');

    const startAtString = this.latestKey

    const endAtString =
      this.query + '~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~'
    const path = `${StrHlp.CLOUD_PATH}/UsernameList`

    const countLoadItems = usersTab
      ? this.countLoadUsers_Tab
      : this.countLoadUsers_FY

    const q = query(
      child(this.rtdb, path),
      orderByKey(),
      startAt(startAtString),
      endAt(endAtString),
      limitToFirst(countLoadItems)
    )

    get(q)
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnapshot) => {
            const username = childSnapshot.key
            const userID = childSnapshot.val()

            // Update latest score:
            // Aappend ~ because 'xxx!' is the smallest lexicographically larger string than 'xxx'.
            // By doing this, we will still catch all posts, but never repeat the latest loaded one upon the next loading.
            this.latestKey = username + '!'
            this.loadingUsersInProgress = false

            // add "empty" info
            const info = {
              userID: userID,
              username: username,
              image$: this.cacheService.getProfileImage(userID)
            }

            this.items.push(info)
          })
        } else {
          if (this.items.length == 0) {
            this.emptyUsers = true
          }
          this.loadingUsersInProgress = false
        }
      })
      .catch((error) => {
        console.error(error)
        this.toast.error('Loading has failed')
        this.loadingUsersInProgress = false
      })
  }

  changeSortTo(sortBy: number) {
    const refreshAfter = this.sortMode !== sortBy
    this.sortMode = sortBy

    if (sortBy == 2) {
      // recent tab does not allow timeframe
      this.timeframe = 0
    }

    if (refreshAfter) {
      this.resetPosts()
      this.loadPosts()
    }
  }

  resetPosts() {
    this.loadPostsInProgress = false
    this.loadPostsDisabled = false

    this.latestScore = Number.MAX_VALUE
    this.feedDataService.clearShownPostList()

    this.emptyPosts = false
  }

  changeContentType(val: number) {
    const refreshAfter = this.contentType !== val
    this.contentType = val

    if (refreshAfter) {
      this.resetPosts()
      this.loadPosts()
    }
  }

  changeTimeFrame(val: number) {
    const refreshAfter = this.timeframe !== val
    this.timeframe = val

    if (refreshAfter) {
      this.resetPosts()
      this.loadPosts()
    }
  }

  changeNSFW(val: boolean) {
    const refreshAfter = this.isNSFW !== val
    this.isNSFW = val

    if (refreshAfter) {
      this.resetPosts()
      this.loadPosts()
    }

    this.persistNsfwChoice()
  }

  persistNsfwChoice() {
    // persist
    this.localstorageService.setItem(this.nsfw_choice_key, String(this.isNSFW))
  }

  loadPersistedNsfwChoice() {
    this.isNSFW =
      this.strHlp.getAllowsNSFW() &&
      this.localstorageService.getItem(this.nsfw_choice_key) === 'true'
  }

  selectTab_0 = () => this.selectTab(0)
  selectTab_1 = () => this.selectTab(1)
  selectTab_2 = () => this.selectTab(2)
  selectTab_3 = () => this.selectTab(3)

  selectTab(tab: number) {
    const valueChanged = this.tabselected != tab

    this.tabselected = tab

    if (valueChanged) {
      if (tab == 1) {
        if (!this.fulltabUsersLoaded) {
          this.fulltabUsersLoaded = true
          this.loadUsers(true)
        }
      } else if (tab == 2) {
        if (!this.fulltabLoaded_Tags) {
          this.fulltabLoaded_Tags = true
          this.loadTags()
        }
      } else if (tab == 3) {
        if (!this.fulltabLoaded_Locations) {
          this.fulltabLoaded_Locations = true
          this.loadLocations()
        }
      }
    }
  }

  loadTags() {
    if (this.emptyTags) {
      return
    }
    if (this.loadingInProgress_Tags) {
      return
    }

    this.loadingInProgress_Tags = true

    const startAtString = this.latestKey_Tags

    const endAtString =
      this.cleanQuery +
      '~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~'
    const path = `${StrHlp.CLOUD_PATH}/Hashtags`

    const countLoadItems = this.countLoadUsers_Tab

    const q = query(
      child(this.rtdb, path),
      orderByKey(),
      startAt(startAtString),
      endAt(endAtString),
      limitToFirst(countLoadItems)
    )

    get(q)
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnapshot) => {
            const hashtag = childSnapshot.key
            const count = childSnapshot.val()['postCount']

            // Update latest score:
            // Aappend ~ because 'xxx!' is the smallest lexicographically larger string than 'xxx'.
            // By doing this, we will still catch all posts, but never repeat the latest loaded one upon the next loading.
            this.latestKey_Tags = hashtag + '!'
            this.loadingInProgress_Tags = false

            const info = {
              hashtag: hashtag,
              count: count
            }

            this.items_Tags.push(info)
          })
        } else {
          if (this.items_Tags.length == 0) {
            this.emptyTags = true
          }
          this.loadingInProgress_Tags = false
        }
      })
      .catch((error) => {
        console.error(error)
        this.toast.error('Loading has failed')
        this.loadingInProgress_Tags = false
      })
  }

  loadLocations() {
    if (this.emptyLocations) {
      return
    }
    if (this.loadingInProgress_Locations) {
      return
    }

    this.loadingInProgress_Locations = true
    //console.log('loadItems()...');

    const startAtString = this.latestKey_Locations

    const endAtString =
      this.cleanQuery +
      '~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~'
    const path = `${StrHlp.CLOUD_PATH}/Locations`

    const countLoadItems = this.countLoadUsers_Tab

    const q = query(
      child(this.rtdb, path),
      orderByKey(),
      startAt(startAtString),
      endAt(endAtString),
      limitToFirst(countLoadItems)
    )

    get(q)
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnapshot) => {
            const location = childSnapshot.key
            const count = childSnapshot.val()['postCount']

            // Update latest score:
            // Aappend ~ because 'xxx!' is the smallest lexicographically larger string than 'xxx'.
            // By doing this, we will still catch all posts, but never repeat the latest loaded one upon the next loading.
            this.latestKey_Locations = location + '!'
            this.loadingInProgress_Locations = false

            const info = {
              location: location,
              count: count
            }

            this.items_Locations.push(info)
          })
        } else {
          if (this.items_Locations.length == 0) {
            this.emptyLocations = true
          }
          this.loadingInProgress_Locations = false
        }
      })
      .catch((error) => {
        console.error(error)
        this.toast.error('Loading has failed')
        this.loadingInProgress_Locations = false
      })
  }

  setUpOnScrollLoader() {
    this.scrollingEl?.nativeElement.addEventListener('scroll', () => {
      const scrollTop = this.scrollingEl!.nativeElement.scrollTop
      const scrollHeight = this.scrollingEl!.nativeElement.scrollHeight
      const clientHeight = this.scrollingEl!.nativeElement.clientHeight

      this.scrollIsAtTop = scrollTop == 0

      const diffToBottom = scrollHeight - scrollTop - clientHeight
      //console.log("scrollDiff: "+diffToBottom);

      if (diffToBottom <= 400) {
        if (
          TimeLimitsService.isAllowed_Session('on-scroll-load-new-items', 1200)
        ) {
          //console.log("Scrolled down... tabselected: "+this.tabselected);
          if (this.tabselected == 0) {
            this.loadPosts()
          } else if (this.tabselected == 1) {
            this.loadUsers(true)
          } else if (this.tabselected == 2) {
            this.loadTags()
          } else if (this.tabselected == 3) {
            this.loadLocations()
          }
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.destroyedSubject.next()
    this.destroyedSubject.complete()
  }
}
