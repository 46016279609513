import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})

/**
 * we treat errors as not-allowed
 */
export class EncodingService {
  constructor() {}

  encodeForUrlArgument(inp: string): string {
    return EncodingService.encodeForUrl(inp)
  }

  private static encodeBrackets(inp: string) {
    return inp
      .replaceAll('(', '%28')
      .replaceAll(')', '%29')
      .replaceAll('[', '%5B')
      .replaceAll(']', '%5D')
  }

  static encodeForUrl(inp: string): string {
    return EncodingService.encodeBrackets(encodeURIComponent(inp))
  }

  static decodeFromURL(inp: string): string {
    return decodeURIComponent(inp)
  }
}
