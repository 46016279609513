import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})

/**
 * we treat errors as not-allowed
 */
export class KeyHelperService {
  private map: any = {}

  constructor() {
    this.initMap()
  }

  private initMap() {
    this.map[0] = '!'
    this.map[1] = '"'
    this.map[2] = '%'
    this.map[3] = "'"
    this.map[4] = '('
    this.map[5] = ')'
    this.map[6] = '*'
    this.map[7] = '+'
    this.map[8] = ','
    this.map[9] = '-'
    // IS "." MISSING?!
    //this.map[10] = "/"; // This does NOT work because child("a/b") means child("a").child("b")
    this.map[10] = '0'
    this.map[11] = '1'
    this.map[12] = '2'
    this.map[13] = '3'
    this.map[14] = '4'
    this.map[15] = '5'
    this.map[16] = '6'
    this.map[17] = '7'
    this.map[18] = '8'
    this.map[19] = '9'
    this.map[20] = ':'
    this.map[21] = ';'
    this.map[22] = '<'
    this.map[23] = '='
    this.map[24] = '>'
    this.map[25] = '?'
    this.map[26] = '@'
    this.map[27] = 'A'
    this.map[28] = 'B'
    this.map[29] = 'C'
    this.map[30] = 'D'
    this.map[31] = 'E'
    this.map[32] = 'F'
    this.map[33] = 'G'
    this.map[34] = 'H'
    this.map[35] = 'I'
    this.map[36] = 'J'
    this.map[37] = 'K'
    this.map[38] = 'L'
    this.map[39] = 'M'
    this.map[40] = 'N'
    this.map[41] = 'O'
    this.map[42] = 'P'
    this.map[43] = 'Q'
    this.map[44] = 'R'
    this.map[45] = 'S'
    this.map[46] = 'T'
    this.map[47] = 'U'
    this.map[48] = 'V'
    this.map[49] = 'W'
    this.map[50] = 'X'
    this.map[51] = 'Y'
    this.map[52] = 'Z'
    // "[" IS MISSING?!
    this.map[53] = '\\'
    // "]" IS MISSING?!
    this.map[54] = '^'
    this.map[55] = '_'
    this.map[56] = '`'
    this.map[57] = 'a'
    this.map[58] = 'b'
    this.map[59] = 'c'
    this.map[60] = 'd'
    this.map[61] = 'e'
    this.map[62] = 'f'
    this.map[63] = 'g'
    this.map[64] = 'h'
    this.map[65] = 'i'
    this.map[66] = 'j'
    this.map[67] = 'k'
    this.map[68] = 'l'
    this.map[69] = 'm'
    this.map[70] = 'n'
    this.map[71] = 'o'
    this.map[72] = 'p'
    this.map[73] = 'q'
    this.map[74] = 'r'
    this.map[75] = 's'
    this.map[76] = 't'
    this.map[77] = 'u'
    this.map[78] = 'v'
    this.map[79] = 'w'
    this.map[80] = 'x'
    this.map[81] = 'y'
    this.map[82] = 'z'
    this.map[83] = '{'
    this.map[84] = '|'
    this.map[85] = '}'
    this.map[86] = '~'
  }

  invertKey(inp: string): string {
    const charArr = inp.split('')
    let inversed = '' // init empty

    let i = 0
    while (i < charArr.length) {
      const char = charArr[i]

      // get numerical value
      let num = this.getNumericalFromChar(char)

      // invert num
      num = 87 - num

      // get inverted char
      const invertedChar = this.map[num]

      // add to inversed
      inversed += invertedChar

      i++
    }

    return inversed
  }

  private getNumericalFromChar(char: string): number {
    for (const key in Object.keys(this.map)) {
      const value = this.map[key]
      if (value === char) {
        return +key
      }
    }

    // never reached
    return -1
  }
}
