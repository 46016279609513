import {
  Component,
  EmbeddedViewRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import {
  getDatabase,
  onDisconnect,
  ref,
  serverTimestamp,
  set
} from 'firebase/database'
import { HotToastService } from '@ngneat/hot-toast'
import { StrHlp } from './shared/services/StringGetter/getstring.service'
import { Meta } from '@angular/platform-browser'
import { SystemService } from './shared/services/system/systemservice.service'
import { AuthService } from './shared/services/auth/auth.service'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { register } from 'swiper/element/bundle'
import { RootStateService } from './shared/services/state/root-state.service'
import { Subject, takeUntil } from 'rxjs'
import { ImageOverlayComponent } from './components/templates/overlays/image-overlay/image-overlay.component'
import { LocalstorageService } from './shared/services/ssr/localstorage.service'
import { SetTimeoutService } from './shared/services/ssr/set-timeout.service'
import { IsBrowserService } from './shared/services/ssr/isbrowser.service'
import { MainStateService } from './shared/services/main/main-state.service'
import { MaindataService } from './shared/services/data/maindata.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

/**
 * Since detecting browser closing is not possible (?), and the user might have multiple windows opened,
 * we do NOT track the online status in the web version. Instead, we simply update the last-seen whenever the
 * website is refreshed. This is not perfect but better than nothing.
 */
export class AppComponent implements OnInit, OnDestroy {
  title = 'webversion' // only needed for spec.ts (test unit)

  @ViewChild('appOverlayWrapper', { read: ViewContainerRef })
  appOverlayWrapper!: ViewContainerRef

  cleanUpSubject = new Subject<void>()

  showOfflineBanner = false
  showBackOnlineBanner = false

  agreedToCookies: boolean = false
  agreedToCookies_Loaded: boolean = false
  cookieBannerFullHeight: boolean = false
  isEU: boolean = false

  agreedToNSFW: boolean = false
  agreedToNSFW_Loaded: boolean = false

  userID: any = null

  constructor(
    public router: Router,
    private toast: HotToastService,
    public strHlp: StrHlp,
    private metaTagService: Meta,
    private authService: AuthService,
    private state: RootStateService,
    private localstorageService: LocalstorageService,
    private setTimeoutService: SetTimeoutService,
    private isBrowserService: IsBrowserService,
    private maindataService: MaindataService,
    private mainStateService: MainStateService
  ) {
    // ssr guarded
    if (this.isBrowserService.isBrowser()) {
      const analytics = getAnalytics()
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          // Since this is a SPA, we need to log screen_view manually
          // NOTE: page_view = page is being reloaded
          //       screen_view = router navigation
          logEvent(analytics, 'screen_view', {
            firebase_screen: event.url,
            firebase_screen_class: event.url
          })
          //console.log("DraugasS: router - screen_view");       }

          this.mainStateService.emitSelectedNavTab(
            this.getSelectedNavTab(event.url)
          )
        }
      })
    }

    // swiperjs
    register()
  }

  /*
   * 0 = feed
   * 1 = clips
   * 2 = explore
   * 3 = chats
   * 4 = notifs
   * 5 = trump
   * 7 = bookmarks
   * 8 = liked
   * 9 = settings
   * 5 = profile (double with trump?!)
   * 12 = webapp-install-page
   */
  getSelectedNavTab(url: string): number {
    if (url === '') {
      return 0
    } else if (url.startsWith('/home')) {
      this.attemptUpdatingStartingPageSettings(true)
      return 0
    } else if (url.startsWith('/explore')) {
      return 2
    } else if (url.startsWith('/notifications')) {
      // reset certain data
      this.maindataService.onOpenNotifTab()
      return 4
    } else if (url === '/chats' || url === '/groupchats') {
      return 3
    } else if (url === '/saved/bookmarks') {
      return 7
    } else if (url === '/saved/liked') {
      return 8
    } else if (url === '/settings') {
      return 9
    } else if (url === '/clips') {
      this.attemptUpdatingStartingPageSettings(false)
      return 1
    } else {
      return -1 // none of the above
    }
  }

  attemptUpdatingStartingPageSettings(normalFeed: boolean) {
    // first check whether or not "home" is the curr user setting
    const setting = this.localstorageService.getItem('startPageSetting')

    let currSetting = 0
    if (setting) {
      currSetting = +setting
    }

    if (currSetting == 0 || currSetting == 1) {
      // its indeed home, so
      // we are allowed to distinguish between "normal" and "clips" as a starting point

      const newSetting = normalFeed ? 1 : 0
      this.localstorageService.setItem('startPageSetting', '' + newSetting)
    }
  }

  rejectCookies() {
    // closing the window is not allowed by the browser
    // instead:
    // make the banner full height and show toast the agreement is necessary to continue
    this.toast.show('To use our website, you must allow the essential cookies')
    this.cookieBannerFullHeight = true
  }

  agreeToCookies() {
    this.agreedToCookies = true
    this.agreedToCookies_Loaded = true
    this.localstorageService.setItem('agreedToCookies', 'true')
  }

  ngOnInit() {
    this.setUpMeta()
    this.userID = this.authService.getUserID()

    // ssr guarded
    if (this.isBrowserService.isBrowser()) {
      this.isEU = SystemService.isInEU()
      this.agreedToCookies =
        this.localstorageService.getItem('agreedToCookies') !== null &&
        this.localstorageService.getItem('agreedToCookies') === 'true'

      this.agreedToCookies_Loaded = true
    } else {
      this.isEU = false
      this.agreedToCookies = true
      this.agreedToCookies_Loaded = true
    }

    // ssr guarded
    if (this.isBrowserService.isBrowser()) {
      SystemService.loadDarkmodeOnStart()
      this.setUpOnlineOfflineListener()
      this.setUpPinchToZoom()

      if (this.authService.isLoggedIn()) {
        this.updateLastSeen()
      }

      this.setUpOverlayLogic()
    }
  }

  setUpPinchToZoom() {
    // ssr guarded
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    // Pinch to zoom enabled except on PWA. That is for SEO (accessibility)
    // On PWA, we disable pinch to zoom for UX
    if (SystemService.isPWA()) {
      if (typeof document !== 'undefined') {
        const metaViewport = document.querySelector('meta[name="viewport"]')
        if (metaViewport) {
          metaViewport.setAttribute(
            'content',
            'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover'
          )
        }
      }
    }
  }

  setUpMeta() {
    this.metaTagService.addTags([
      { name: 'robots', content: 'index, follow' },
      { charset: 'UTF-8' }
    ])
  }

  navigateTo(path: string): void {
    this.router.navigate([path])
  }

  /**
   * Lastseen and online/offline
   */
  async updateLastSeen() {
    // ssr guarded
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    if (!this.userID) {
      return
    }

    // set online
    const rtdb = getDatabase()

    const onlineRef = ref(
      rtdb,
      `${StrHlp.CLOUD_PATH}/onlinestatus/${this.userID}`
    )
    const lastseenRef = ref(
      rtdb,
      `${StrHlp.CLOUD_PATH}/lastseen/${this.userID}`
    )

    await set(onlineRef, true)

    // add disconnect callback
    onDisconnect(onlineRef).set(false)
    onDisconnect(lastseenRef).set(serverTimestamp())
  }

  setUpOnlineOfflineListener() {
    if (!this.isBrowserService.isBrowser()) {
      // SSR guarded
      return
    }

    // init check still needed
    if (!window.navigator.onLine) {
      this.showOfflineBanner = true
    }

    window.addEventListener('online', () => {
      this.onUserHasInternetConnection()
    })

    window.addEventListener('offline', () => {
      this.onUserNoInternetConnection()
    })
  }

  onUserHasInternetConnection() {
    if (!this.isBrowserService.isBrowser()) {
      // SSR guarded
      return
    }

    console.log('> Internet connection established!')

    if (this.showOfflineBanner) {
      this.showBackOnlineBanner = true

      // dissappear with delay
      const delay = 2500
      this.setTimeoutService.setTimeout(() => {
        this.showBackOnlineBanner = false
      }, delay)
    }

    this.showOfflineBanner = false
  }

  onUserNoInternetConnection() {
    console.log('> Internet connection NOT available...')

    this.showOfflineBanner = true
    this.showBackOnlineBanner = false
  }

  setUpOverlayLogic() {
    // ssr guarded
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    this.state.overlays$
      .pipe(takeUntil(this.cleanUpSubject))
      .subscribe((screenOverlay) => {
        const compRef = this.appOverlayWrapper.createComponent(
          ImageOverlayComponent
        )

        // image
        compRef.setInput('imageSource', screenOverlay.assetSource)
        compRef.setInput('small', screenOverlay.small)

        // pos. do 40 do adjust, otherwise it would be right next to the click and right below.
        ;(
          (compRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement
        ).style.position = 'absolute'
        ;(
          (compRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement
        ).style.left = screenOverlay.x - 40 + 'px'
        ;(
          (compRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement
        ).style.top = screenOverlay.y - 40 + 'px'

        // remove from DOM after x time
        this.setTimeoutService.setTimeout(
          () => compRef.destroy(),
          screenOverlay.duration
        )
      })
  }

  ngOnDestroy() {
    this.cleanUpSubject.next()
    this.cleanUpSubject.complete()
  }
}
