import { Component } from '@angular/core'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.css']
})
export class BrandingComponent {
  name = StrHlp.APP_NAME
}
