<div class="main-right-side-row-container full-screen-height">
  <app-searchbar id="search-bar" [suggestedQuery]="suggestedQuery | async">
  </app-searchbar>

  <div class="trending-box usual-grey-rounded-widget overflow-scroll-y">
    <h2 class="trending-title">Hashtags</h2>

    @for (hashtag of trendingdataService.trendingHashtags; track hashtag.tag) {
      <app-explore-hashtag-item
        [tag]="hashtag.tag"
        [countInput]="hashtag.count"
      />
    }

    @if (trendingdataService.currentlyFetchingHashtags) {
      <app-explore-loading-item />
    }

    <h2 class="trending-title">Profiles</h2>

    @for (
      profile of trendingdataService.trendingProfiles;
      track profile.userID
    ) {
      <app-explore-user-item
        [userID]="profile.userID"
        [username]="profile.username"
        [profileImage]="profile.imgURL"
      />
    }

    @if (trendingdataService.currentlyFetchingUsers) {
      <app-explore-loading-item />
    }
  </div>
</div>
