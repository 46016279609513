import { Component, Input } from '@angular/core'
import { Location } from '@angular/common'

@Component({
  selector: 'app-backbuttontemplate',
  templateUrl: './backbuttontemplate.component.html',
  styleUrls: ['./backbuttontemplate.component.css']
})
export class BackbuttontemplateComponent {
  @Input() showText: boolean = false
  @Input() disableAction: boolean = false
  @Input() background: boolean = false

  constructor(private location: Location) {}

  back() {
    if (!this.disableAction) {
      this.location.back()
    }
  }
}
