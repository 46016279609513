<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Password settings</h1>
  </header>

  <main class="fh-main content">
    <div class="formGroup remove-middle" id="username-wrapper">
      <input
        type="password"
        class="formControl"
        placeholder="Current password"
        maxlength="500"
        #currentPassword
        [(ngModel)]="currentPasswordEntered"
        />
    </div>

    @if (currentPasswordEntered != '') {
      <p
        class="password-options remove-middle"
        id="showpassword"
        #showCurrentPassword
        (click)="changeCurrentPasswordVisibility()"
        >
        Show password
      </p>
    }

    <div class="formGroup remove-middle">
      <input
        type="password"
        class="formControl"
        placeholder="New password"
        maxlength="500"
        #password
        [(ngModel)]="passwordEntered"
        />
    </div>

    @if (passwordEntered != '') {
      <p
        class="password-options remove-middle"
        id="showpassword"
        #showpassword
        (click)="changePasswordVisibility()"
        >
        Show password
      </p>
    }

    <p #passwordInvalidError class="email-invalid-error remove-middle">
      Invalid password
    </p>

    <p class="setting-desc remove-middle">
      You can change the password of your account. You must enter your password
      to confirm that is is you.
    </p>

    <button
      class="setting-button remove-middle"
      #changeButton
      (click)="changePassword()"
      >
      Change password
    </button>
  </main>
</div>
