<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Chat settings</h1>
  </header>

  <main class="fh-main content">
    <h2 class="sub-title">DM restrictions</h2>

    <p>Allow these users to send me private messages...</p>

    <fieldset id="group2">
      <label class="radio" (click)="saveSetting_DM_Restrict(0)">
        <input
          type="radio"
          name="group2"
          [checked]="setting_DM_Restriction == 0"
          />
        <span class="radio-text">All users</span>
      </label>

      <label class="radio" (click)="saveSetting_DM_Restrict(1)">
        <input
          class="radiobutton"
          type="radio"
          name="group2"
          [checked]="setting_DM_Restriction == 1"
          />
        <span class="radio-text">Users I'm following</span>
      </label>

      <label class="radio" (click)="saveSetting_DM_Restrict(2)">
        <input
          class="radiobutton"
          type="radio"
          name="group2"
          [checked]="setting_DM_Restriction == 2"
          />
        <span class="radio-text"
          >Users who have been in the app for at least a week</span
          >
        </label>
      </fieldset>

      @if (hasLeftGC) {
        <div>
          <h2>Join Global Chat</h2>
          <p>You have left Global Chat.</p>
          <button class="setting-button remove-middle" (click)="joinGlobalChat()">
            Join Global Chat
          </button>
        </div>
      }
    </main>
  </div>
