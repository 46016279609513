import { CommonModule } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core'
import { FormsModule } from '@angular/forms'
import { Router } from '@angular/router'
import { RecentsearchesService } from 'src/app/shared/services/search/recentsearches.service'
import { SetTimeoutService } from 'src/app/shared/services/ssr/set-timeout.service'

@Component({
  selector: 'app-searchbar',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.css']
})
export class SearchbarComponent {
  @Input() queryInput: string = ''
  @Input() searchCallback: ((arg0: string) => void) | null = null
  @Input() hasBorder: boolean = false
  @Input() disableAutoFocus: boolean = false
  @Input() hint: string = 'Search'
  @Input() suggestedQuery: string | null = null
  @Input() disableRecentsHint: boolean = false

  @ViewChild('inputBar') inputBar!: ElementRef

  searchQueryEntered = ''
  hasFocus = false

  constructor(
    private router: Router,
    public searchService: RecentsearchesService,
    private setTimeoutService: SetTimeoutService
  ) {}

  ngOnInit() {
    this.searchQueryEntered = this.queryInput
  }

  clearInput() {
    this.searchQueryEntered = ''

    // and focus input bar again
    this.inputBar.nativeElement.focus()
  }

  recentQueryClick(query: string) {
    this.searchQueryEntered = query
    this.startSearch()
    this.onSearchLoseFocus()
  }

  startSearch(): void {
    if (this.searchCallback !== null) {
      this.searchCallback(this.searchQueryEntered)
    } else {
      // if query is empty, do not search
      let query = this.searchQueryEntered.trim()

      if (query.length === 0) {
        // if empty entered query, but there is a suggested query,
        // use it as the query
        if (this.suggestedQuery) {
          query = this.suggestedQuery
        }
      }

      if (query.length > 0) {
        const currentUrlTree = this.router.parseUrl(this.router.url)
        const currentUrlSegments = currentUrlTree.root.children.primary.segments

        //if (currentUrlSegments[0].path === 'discover') {
        //this.router.navigate([`/find/${query}`]);
        //} else {
        this.router.navigate([`/discover/${query}`])
        //}

        this.searchService.addSearchQuery(query)
        this.onSearchLoseFocus()
      }
    }
  }

  onEnter(event: any) {
    if (event.keyCode == 13) {
      this.startSearch()
    }
  }

  onSearchFocus() {
    this.hasFocus = true
  }

  onSearchLoseFocus() {
    this.setTimeoutService.setTimeout(() => {
      this.hasFocus = false
    }, 200)
  }

  onClearAll() {
    this.searchService.clearAllSearches()
  }

  onRemoveQuery(index: number) {
    this.searchService.removeSearchQuery(index)
  }
}
