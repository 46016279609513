import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { InputdialogComponent } from 'src/app/components/dialogs/inputdialog/inputdialog.component'
import { SystemService } from '../system/systemservice.service'

@Injectable({
  providedIn: 'root'
})
export class InputdialogService {
  constructor(public dialog: MatDialog) {}

  openDialog(
    textPreInsert: string,
    actionButtonName: string,
    cancelButtonName: string,
    maxTextLength: number,
    actionCallback: (text: string) => any,
    cancelCallback: ((text: string) => any) | null,
    hint: string = '',
    title: string = 'Edit'
  ) {
    const data = {
      textPreInsert: textPreInsert,
      actionButtonName: actionButtonName,
      cancelButtonName: cancelButtonName,
      maxTextLength: maxTextLength,
      cancelCallback: cancelCallback,
      actionCallback: actionCallback,
      title: title,
      hint: hint
    }

    let maxW = '30vw'
    if (SystemService.isMobile()) {
      maxW = '90vw'
    }

    this.dialog.open(InputdialogComponent, {
      maxWidth: maxW,
      width: '100%',
      data: data
    })
  }
}
