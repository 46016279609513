import { Component, OnInit } from '@angular/core'
import { HotToastService } from '@ngneat/hot-toast'
import { StrHlp } from '../../../shared/services/StringGetter/getstring.service'
import { get, getDatabase, limitToFirst, query, ref } from 'firebase/database'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { LoadingDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component'
import { OnedialogserviceService } from 'src/app/shared/services/dialogs/onedialogservice.service'
import { MatDialog } from '@angular/material/dialog'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { take } from 'rxjs'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-manageblockedusers',
  templateUrl: './manageblockedusers.component.html',
  styleUrls: [
    '../../user/followers/followers.component.css',
    '../../feed/feed.component.css',
    '../../settings/settingspage/settings.component.css',
    '../../user/user.component.css'
  ]
})
export class ManageblockedusersComponent implements OnInit {
  userID: any = ''
  blockedusers: any[] = []
  isLoading: boolean = true
  isEmpty: boolean = false

  constructor(
    private toast: HotToastService,
    private oneButtonDialogService: OnedialogserviceService,
    public dialog: MatDialog,
    private twobuttonsdialogService: TwobuttonsdialogService,
    public routingHelper: RoutinghelperService,
    public imgHlp: ImageLoadingService,
    private cacheService: CacheService,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage(
      'Manage blocked users',
      'Manage blocked settings'
    )
    this.userID = AuthService.getUID()
  }

  ngAfterViewInit(): void {
    this.loadItems()
  }

  unblockUser(username: string, uid: string, index: number): void {
    // first ask if sure to unblock
    this.twobuttonsdialogService.show(
      'Unblock',
      'Do you want to unblock ' + username + '?',
      () => {
        // nothing
      },
      () => {
        // call cloud function
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        const functions = getFunctions()
        const blockUser = httpsCallable(functions, 'unblockUser')

        blockUser({
          hubname: StrHlp.CLOUD_PATH,
          otherUserID: uid
        })
          .then((result) => {
            loadingDialogRef.close()

            this.toast.success('Unblocked')
            this.blockedusers.splice(index, 1)

            this.isEmpty = this.blockedusers.length == 0
          })
          .catch((error) => {
            loadingDialogRef.close()
            console.log(error)

            // show error message via dialog
            this.oneButtonDialogService.show('Failed', error.message)
          })
      },
      'Cancel',
      'Unblock'
    )
  }

  loadItems() {
    const db = getDatabase()

    if (this.userID !== null) {
      const dbPath = `${StrHlp.CLOUD_PATH}/BlockedUsers/${this.userID}`
      const getQuery = query(ref(db, dbPath), limitToFirst(20))

      get(getQuery)
        .then((snapshot) => {
          if (!snapshot.exists()) {
            this.isLoading = false
            this.isEmpty = true
          } else {
            snapshot.forEach((childSnapshot) => {
              const blockedUid = childSnapshot.key!

              this.cacheService
                .getUsername(blockedUid)
                .pipe(take(1))
                .subscribe((username) => {
                  this.blockedusers.push({
                    userID: blockedUid,
                    username: username
                  })
                  this.isLoading = false
                })
            })
          }
        })
        .catch((error) => {
          this.toast.error('An error has occurred')
          console.error(error)
        })
    } else {
      this.toast.error('An error has occurred')
      console.log('uid is NULL')
    }
  }
}
