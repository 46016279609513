import { Component } from '@angular/core'

@Component({
  selector: 'app-ip-banned',
  standalone: true,
  imports: [],
  templateUrl: './ip-banned.component.html',
  styleUrls: ['./ip-banned.component.css', '../banned.css']
})
export class IpBannedComponent {}
