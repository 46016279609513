import { Component, Input } from '@angular/core'
import { RouterModule } from '@angular/router'
import { UsernametemplateComponent } from '../../templates/username/usernametemplate.component'
import { ImgScaleHelperPipe } from 'src/app/pipes/img-scale-helper.pipe'

@Component({
  selector: 'app-explore-user-item',
  standalone: true,
  imports: [RouterModule, UsernametemplateComponent, ImgScaleHelperPipe],
  templateUrl: './explore-user-item.component.html',
  styleUrls: ['./explore-user-item.component.css', '../explore.styles.css']
})
export class ExploreUserItemComponent {
  @Input()
  userID!: string

  @Input()
  username!: string

  @Input()
  profileImage!: string
}
