import { Injectable } from '@angular/core'
import {
  child,
  get,
  getDatabase,
  limitToFirst,
  orderByKey,
  query,
  ref,
  startAt
} from 'firebase/database'
import { StrHlp } from '../StringGetter/getstring.service'
import { HotToastService } from '@ngneat/hot-toast'
import { CacheService } from '../caching/cache-service.service'
import { AuthService } from '../auth/auth.service'
import { TimeService } from '../time/time.service'
import { combineLatest, take } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class NotificationsdataService {
  rtdb = ref(getDatabase())

  public totalNotifCount: number | null = null
  userID: any = null
  public loadingInProgress: boolean = false
  public initialized: boolean = false
  latestKey: string | null = '!'
  public itemList: any[] = []
  firstLoadingPostsRound: boolean = true
  public empty: boolean = false

  // helper
  private lastTimestmap: number = 0

  constructor(
    private toast: HotToastService,
    private cacheService: CacheService
  ) {
    this.userID = AuthService.getUID()
  }

  /**
   * returns 0 for "today",
   *        1 for "yesterday",
   *        2 for "2 days ago",
   *        3 for "3 days ago",
   *        the stamp for "custom date" (previously it was "older")
   */
  getDateIndicatorType(stamp: number): number {
    const elapsed = Date.now() - stamp
    const daysElapsed = Math.floor(elapsed / 86400000)

    if (daysElapsed > 3) {
      return stamp
    } else {
      return daysElapsed
    }
  }

  loadTotalCount() {
    const path = `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/TotalNotifiesCount`
    get(child(this.rtdb, path)).then((snapshot) => {
      if (snapshot.exists()) {
        this.totalNotifCount = snapshot.val()
      } else {
        this.totalNotifCount = 0
      }
    })
  }

  reset() {
    this.totalNotifCount = null
    this.initialized = false
    this.latestKey = '!'
    this.itemList = []
    this.firstLoadingPostsRound = true
    this.empty = false
  }

  resetAndLoad() {
    // reset
    this.reset()

    // load
    this.loadTotalCount()
    this.load()
  }

  /*
   * Called either if no data loaded yet, or when new notifs appear and the notif tab is opened
   * in the latter case its "reloading".
   */
  load(): void {
    if (this.loadingInProgress) {
      return
    }
    this.loadingInProgress = true

    const countLoadItems = 30
    const path = `${StrHlp.CLOUD_PATH}/Notifications/${this.userID}`

    const q = query(
      child(this.rtdb, path),
      orderByKey(),
      startAt(this.latestKey),
      limitToFirst(countLoadItems)
    )

    get(q)
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.loadingInProgress = false
          this.firstLoadingPostsRound = false

          snapshot.forEach((childSnapshot) => {
            const key = childSnapshot.key
            const notification = childSnapshot.val()

            // load desc text here because it works sychronously
            const text = notification.text
            if (text === 'LIKED_YOUR_POST') {
              notification.descText = 'liked your post'
            } else if (text.includes('COMMENTED_YOUR_POST')) {
              notification.descText = text.replace(
                'COMMENTED_YOUR_POST',
                'commented'
              )
            } else if (text.includes('REPLIED_YOUR_COMMENT')) {
              notification.descText = text.replace(
                'REPLIED_YOUR_COMMENT',
                'replied to your comment'
              )
            } else if (text === 'STARTED_FOLLOWING_YOU') {
              notification.descText = 'started following you'
            } else if (text === 'REQUESTED_FOLLOW_YOU') {
              notification.descText = 'sent a follow request'
            } else if (text === 'MENTIONED_YOU_IN_GLOBAL_CHAT') {
              notification.descText = 'mentioned you in the Global Chat'
            } else if (text === 'VISITED_PROFILE') {
              notification.descText = 'visited your profile'
            } else {
              // fallback
              // but also "abused" for exceptions (lazy coding, bad style)
              notification.descText = text
            }

            notification.showDateHint = !TimeService.sameDay(
              this.lastTimestmap,
              notification.timestamp
            )
            notification.dateHintType = this.getDateIndicatorType(
              notification.timestamp
            )
            this.lastTimestmap = notification.timestamp

            notification.itemID = key

            if (this.firstLoadingPostsRound) {
              this.itemList.push(notification)
            } else {
              // for virtual scroll, we need to push/assign it differently
              this.itemList.push(notification)
              this.itemList = [...this.itemList]
            }

            // Update latest score:
            // Aappend ~ because 'xxx!' is the smallest lexicographically larger string than 'xxx'.
            // By doing this, we will still catch all posts, but never repeat the latest loaded one upon the next loading.
            this.latestKey = key + '!'

            // load the info now
            this.loadFurtherItemInfo(this.itemList.length - 1)
          })
        } else {
          if (this.firstLoadingPostsRound) {
            this.empty = true
          } else {
            this.loadingInProgress = false
          }
        }

        this.initialized = true
      })
      .catch((error) => {
        console.error(error)
        this.toast.error('Loading has failed')
        this.loadingInProgress = false
        this.initialized = true
      })
  }

  loadFurtherItemInfo(index: number): void {
    const item = this.itemList[index]
    const postID = item.postid
    const isPost = item.ispost

    if (isPost && postID && postID.length > 0) {
      // get post image
      // since we do not yet support video in the web version, also do not load
      // the video thumbnail here if its a video
      get(child(this.rtdb, `${StrHlp.CLOUD_PATH}/Photo/${postID}/imagePath`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            item.previewURL = snapshot.val()
            this.itemList[index] = item // update post info
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
}
