import { Component } from '@angular/core'

@Component({
  selector: 'app-donationreceived',
  templateUrl: './donationreceived.component.html',
  styleUrls: [
    './donationreceived.component.css',
    '../../premium/ordersuccess/ordersuccess.component.css'
  ]
})
export class DonationreceivedComponent {}
