import { Injectable } from '@angular/core'
import { StrHlp } from '../StringGetter/getstring.service'
import { CacheService } from '../caching/cache-service.service'
import { AuthService } from '../auth/auth.service'
import { MainComponent } from 'src/app/components/main/main.component'
import { take } from 'rxjs'

@Injectable({
  providedIn: 'root'
})

/**
 * super simple system.
 *
 * add() --> returns ad-post|null
 * If null, dont add an ad there, otherwise add obj (ad).
 *
 * Based on counters.
 */
export class ChatadsService {
  private count_Msgs = 0
  private count_Inserted_SystemAds = 0
  private count = 0 // for ID

  private count_Posts_Insert_SystemAd_Modulo = 20 // each x-th is a system ad
  private count_Posts_Insert_SystemAd_Modulo_PREMIUM = 40

  private goPremAd = {
    isAd: true,
    isSystemAd: true,
    name: StrHlp.APP_NAME,
    messageID: `systemad_${this.count_Inserted_SystemAds}`,
    showButton: true,
    buttonText: 'Go premium',
    redButton: false,
    actionURL: '/settings/premium',
    senderUID: 'nwnqQ1LieaaofxvtAg9ojOBRIeD2',
    message: StrHlp.GO_PREMIUM_AD_TEXT,
    timestamp: Date.now(),
    replyMessage: '',
    replyMessageID: '',
    replyMessageFrom: '',
    imageURL: '',
    vidURL: '',
    thmbURL: '',
    countLikes: 0,
    countDislikes: 0,
    e3c: 0,
    e4c: 0,
    e5c: 0,
    e6c: 0,
    e7c: 0,
    e8c: 0,
    e9c: 0,
    e10c: 0,
    e11c: 0,
    e12c: 0,
    edited: false,
    forwarded: false,
    vidDuration: 0,
    hintCode: '',
    showMonthlyDonationProgress: false,
    monthlyDonationProgressPercent: 0
  }

  private makeDonationAd = {
    isAd: true,
    isSystemAd: true,
    name: StrHlp.APP_NAME,
    messageID: `systemad_${this.count_Inserted_SystemAds}`,
    showButton: true,
    buttonText: 'Make a donation',
    redButton: true,
    actionURL: '/donate',
    senderUID: 'nwnqQ1LieaaofxvtAg9ojOBRIeD2',
    message: StrHlp.DONATION_TEXT,
    timestamp: Date.now(),
    replyMessage: '',
    replyMessageID: '',
    replyMessageFrom: '',
    imageURL: StrHlp.DONATION_IMAGE_URL,
    vidURL: '',
    thmbURL: '',
    countLikes: 0,
    countDislikes: 0,
    e3c: 0,
    e4c: 0,
    e5c: 0,
    e6c: 0,
    e7c: 0,
    e8c: 0,
    e9c: 0,
    e10c: 0,
    e11c: 0,
    e12c: 0,
    edited: false,
    forwarded: false,
    vidDuration: 0,
    hintCode: '',
    showMonthlyDonationProgress: false,
    monthlyDonationProgressPercent: 0
  }

  private makeDonationAd_MonthlyProgress = {
    isAd: true,
    isSystemAd: true,
    name: StrHlp.APP_NAME,
    messageID: `systemad_${this.count_Inserted_SystemAds}`,
    showButton: false,
    buttonText: '',
    redButton: false,
    actionURL: '/donate',
    senderUID: 'nwnqQ1LieaaofxvtAg9ojOBRIeD2',
    message: `Help keep ${StrHlp.APP_NAME} online. Click the bar to make a donation.`,
    timestamp: Date.now(),
    replyMessage: '',
    replyMessageID: '',
    replyMessageFrom: '',
    imageURL: '',
    vidURL: '',
    thmbURL: '',
    countLikes: 0,
    countDislikes: 0,
    e3c: 0,
    e4c: 0,
    e5c: 0,
    e6c: 0,
    e7c: 0,
    e8c: 0,
    e9c: 0,
    e10c: 0,
    e11c: 0,
    e12c: 0,
    edited: false,
    forwarded: false,
    vidDuration: 0,
    hintCode: '',
    showMonthlyDonationProgress: true,
    monthlyDonationProgressPercent: 0
  }

  areYouPrem = false

  constructor(cacheService: CacheService) {
    // load prem
    cacheService
      .getVerified(AuthService.getUID()!)
      .pipe(take(1))
      .subscribe((verified) => {
        this.areYouPrem = verified

        if (this.areYouPrem) {
          this.count_Posts_Insert_SystemAd_Modulo =
            this.count_Posts_Insert_SystemAd_Modulo_PREMIUM
        }
      })
  }

  add(): any | null {
    this.count_Msgs++

    if (this.count_Msgs % this.count_Posts_Insert_SystemAd_Modulo == 0) {
      // insert system ad. determine which one (go premium or make donation)
      this.count_Inserted_SystemAds++

      let mod = this.count_Inserted_SystemAds % 3
      if (mod == 0 && (this.areYouPrem || !AuthService.isUserLoggedIn())) {
        mod = 1 // dont show premium for already premium users
        // and only to logged in users
      }

      if (mod == 0) {
        const ret = this.goPremAd
        ret.messageID = `systemad_${this.count_Inserted_SystemAds}`
        return this.goPremAd
      } else if (mod == 1) {
        // update percentage
        const ret = this.makeDonationAd_MonthlyProgress
        ret.messageID = `systemad_${this.count_Inserted_SystemAds}`
        ret.monthlyDonationProgressPercent =
          MainComponent.percentGoalReached_DonationsMonthly
        return ret
      } else {
        const ret = this.makeDonationAd
        ret.messageID = `systemad_${this.count_Inserted_SystemAds}`
        return this.makeDonationAd
      }
    } else {
      return null
    }
  }
}
