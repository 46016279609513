import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'timeformat'
})
export class TimeformatPipe implements PipeTransform {
  private dayInMillis = 86400000

  /**
   *
   * @param value millis
   * @param args arg[0] is int:
   *              0 = timeSince
   *              1 = am,pm
   *              2 = chat-style: combination of justNow, amPm, timeSince
   *
   * @returns
   */
  transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value !== 'number') {
      return ''
    }

    const millis = value as number

    let mode = args && args.length > 0 ? (args[0] as number) : 0

    if (mode > 2) {
      mode = 0
    }

    switch (mode) {
      case 0:
        return this.timeSince(millis)

      case 1:
        return this.formatAMPM(millis)

      case 2:
        return this.chatStyle(millis)

      //case 3:
      //return this.getClocktime(millis);

      default:
        throw Error('Error: Default reached in switch of timeformat pip') // for compiler only
    }
  }

  private chatStyle(millis: number) {
    if (this.isToday(millis)) {
      if (this.is_JustNow(millis)) {
        return 'Just now'
      } else {
        return this.formatAMPM(millis)
      }
    } else {
      return this.timeSince(millis)
    }
  }

  /**
   * "Just now" = less than a minute
   */
  private is_JustNow(millis: number): boolean {
    return Date.now() - millis < 1000 * 60
  }

  private isToday(millis: number): boolean {
    return Date.now() - millis < this.dayInMillis
  }

  private formatAMPM(millis: number) {
    return new Date(millis).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
  }

  private formatAMPM_Old(millis: number) {
    const date = new Date(millis)

    let hours = date.getHours()
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'

    const minutes = date.getMinutes()
    const minStr = minutes < 10 ? '0' + minutes : minutes

    const ampm = hours >= 12 ? 'pm' : 'am'
    return hours + ':' + minStr + ' ' + ampm
  }

  private timeSince(millis: number, showSeconds: boolean = false): string {
    const seconds = Math.floor((Date.now() - millis) / 1000)

    let interval = seconds / 31536000
    if (interval >= 1) {
      const count = Math.floor(interval)
      if (count == 1) {
        return count + ' year ago'
      } else {
        return count + ' years ago'
      }
    }

    interval = seconds / 2592000
    if (interval >= 1) {
      const count = Math.floor(interval)
      if (count == 1) {
        return count + ' month ago'
      } else {
        return count + ' months ago'
      }
    }

    interval = seconds / 86400
    if (interval >= 1) {
      const count = Math.floor(interval)
      if (count == 1) {
        return count + ' day ago'
      } else {
        return count + ' days ago'
      }
    }

    interval = seconds / 3600
    if (interval >= 1) {
      const count = Math.floor(interval)
      if (count == 1) {
        return count + ' hour ago'
      } else {
        return count + ' hours ago'
      }
    }

    interval = seconds / 60
    if (interval >= 1) {
      const count = Math.floor(interval)
      if (count == 1) {
        return count + ' minute ago'
      } else {
        return count + ' minutes ago'
      }
    }

    if (showSeconds) {
      let secs = Math.floor(seconds)
      if (secs <= 0) {
        secs = 1
      }

      if (secs == 1) {
        return secs + ' second ago'
      } else {
        return secs + ' seconds ago'
      }
    } else {
      return 'Just now'
    }
  }

  /*
  FALSE CODE
  private getClocktime(inp: number): string {
    let yourDate = new Date(inp);
    console.log("yourDate:",yourDate);
    const offset = yourDate.getTimezoneOffset();
    console.log("offset:",offset);
    yourDate = new Date(yourDate.getTime() + offset * 60 * 1000);
    console.log(yourDate);
    console.log("yourDate:",yourDate);
    const s = yourDate.toISOString().split('T')[1];
    console.log(s);
    console.log("s:",s);
    const arr = s.split(':');
    let hh = +arr[0];
    const mm = +arr[1];

    let amPm = 'AM';
    if (hh > 12) {
      hh -= 12;
      amPm = 'PM';
    }
    return `${hh < 10 ? '0' + hh : hh}:${mm < 10 ? '0' + mm : mm} ${amPm}`;
  }
  */
}
