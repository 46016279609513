<div
  #scrollContainer
  class="explore-page-container full-screen-height overflow-scroll-y"
>
  <header
    class="explore-header"
    [ngClass]="{
      'bottom-border-divider': !datasharingService.mainComponent_ScrollIsAtTop
    }"
  >
    <app-searchbar
      id="search-bar-top"
      [suggestedQuery]="suggestedQuery | async"
    />
  </header>

  <main class="below-header below-header-height">
    <div class="explore-widgets-wrapper">
      <section class="usual-grey-rounded-widget">
        <h2 class="trending-title">Trending tags</h2>

        @for (
          hashtag of trendingdataService.trendingHashtags_Overall;
          track hashtag.tag
        ) {
          <app-explore-hashtag-item
            [tag]="hashtag.tag"
            [countInput]="hashtag.count"
          />
        }
        @if (
          !trendingdataService.currentlyFetchingHashtags &&
          trendingdataService.trendingHashtags_Overall.length == 0
        ) {
          <app-emptyhinttemplate />
        }

        @if (
          !trendingdataService.hashtagsDisabledFetching &&
          !trendingdataService.currentlyFetchingHashtags
        ) {
          <app-explore-load-more-item
            (click)="trendingdataService.loadTrendingHashtags()"
          />
        }

        @if (trendingdataService.currentlyFetchingHashtags) {
          <app-explore-loading-item />
        }
      </section>

      <section class="usual-grey-rounded-widget">
        <h2 class="trending-title">Trending users</h2>

        @for (
          profile of trendingdataService.trendingProfiles_Overall;
          track profile.userID
        ) {
          <app-explore-user-item
            [userID]="profile.userID"
            [username]="profile.username"
            [profileImage]="profile.imgURL"
          />
        }
        @if (
          !trendingdataService.currentlyFetchingUsers &&
          trendingdataService.trendingProfiles_Overall.length == 0
        ) {
          <app-emptyhinttemplate />
        }

        @if (
          !trendingdataService.usersDisabledFetching &&
          !trendingdataService.currentlyFetchingUsers
        ) {
          <app-explore-load-more-item
            (click)="trendingdataService.loadTrendingProfiles()"
          />
        }

        @if (trendingdataService.currentlyFetchingUsers) {
          <app-explore-loading-item />
        }
      </section>

      <section class="usual-grey-rounded-widget">
        <h2 class="trending-title">Trending searches</h2>

        @for (
          query of trendingdataService.trendingSearchQueries;
          track query.query
        ) {
          <app-explore-search-item
            [searchQuery]="query.query"
            [countInput]="query.count"
          />
        }
        @if (
          !trendingdataService.currentlyFetchingSearchQueries &&
          trendingdataService.trendingSearchQueries.length == 0
        ) {
          <app-emptyhinttemplate />
        }

        @if (
          !trendingdataService.searchQueriesDisabledFetching &&
          !trendingdataService.currentlyFetchingSearchQueries
        ) {
          <app-explore-load-more-item
            (click)="trendingdataService.loadTrendingSearchQueries()"
          />
        }

        @if (trendingdataService.currentlyFetchingSearchQueries) {
          <app-explore-loading-item />
        }
      </section>

      <div class="trending-box filter-posts-box usual-grey-rounded-widget">
        <h2 class="trending-title">Explore posts</h2>

        <h3>Posted:</h3>
        <div class="sort-filter-wrapper">
          <button
            class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': selectedTime == 0,
              'sort-filter-button-inactive': selectedTime != 0
            }"
            (click)="selectTime(0)"
          >
            This month
          </button>

          <button
            class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': selectedTime == 1,
              'sort-filter-button-inactive': selectedTime != 1
            }"
            (click)="selectTime(1)"
          >
            All time
          </button>
        </div>

        <h3>Sort by:</h3>
        <div class="sort-filter-wrapper">
          <button
            class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': selectedScore == 0,
              'sort-filter-button-inactive': selectedScore != 0
            }"
            (click)="selectScore(0)"
          >
            Trending
          </button>

          <button
            class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': selectedScore == 1,
              'sort-filter-button-inactive': selectedScore != 1
            }"
            (click)="selectScore(1)"
          >
            Most liked
          </button>
        </div>

        <h3>Start exploring:</h3>

        <div
          id="explore-button"
          class="choose-option usual-border-radius"
          (click)="startExploring()"
        >
          Explore
        </div>
      </div>
    </div>
  </main>
</div>
