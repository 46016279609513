<!--
    Feed choosing bar is fixed.
    When the user scrolls down, it hides by setting opacity to zero (animated)
    When user scrolls up again, it appears.
-->

<div class="feed-container">
  <header
    class="feed-header"
    [ngClass]="{
      'fade-out': headerFadeOut,
      'fade-in': headerFadeIn
    }"
  >
    @if (showFeedChoosingBar) {
      <app-feed-choosing-bar [selectedFeed]="selectedFeed" />
    }

    @if (isExplore && exploreTitle) {
      <h2
        id="explore-title"
        [ngClass]="{ 'bottom-border-divider': !scrollIsAtTop }"
      >
        {{ exploreTitle }}
      </h2>
    }

    <app-usual-divider />
  </header>

  <main>
    @if (empty) {
      <app-emptyhinttemplate text="No posts yet" />
    } @else {
      @if (feedDataService.postList.length == 0) {
        <div id="loading-wrapper">
          <app-loadingcircle id="loading-indicator" />
        </div>
      }
    }

    <cdk-virtual-scroll-viewport
      #virtualScrollViewport
      id="virtualScrollViewport"
      class="post-list feed-post-list-height"
      predictingVirtualScroll
      [data]="{
        items: feedDataService.postList,
        itemType: 0,
        idAttrName: 'postID'
      }"
    >
      <!-- empty div to occupy space -->
      <div class="empty-fill-header-height"></div>

      <div
        class="post"
        *cdkVirtualFor="let post of feedDataService.postList; let i = index"
        [attr.data-hm-id]="post.postID"
      >
        <post-template
          [post]="{
            post: post,
            indexInList: i,
            postEnv: 0
          }"
        />
      </div>
    </cdk-virtual-scroll-viewport>
  </main>
</div>
