import { isPlatformBrowser, isPlatformServer } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class IsBrowserService {
  private isBrowserVar: boolean
  private isServerVar: boolean

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowserVar = isPlatformBrowser(platformId)
    this.isServerVar = isPlatformServer(platformId)
  }

  isBrowser() {
    return this.isBrowserVar
  }

  isServer() {
    return this.isServerVar
  }
}
