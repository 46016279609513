import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling'
import { Directive, forwardRef, Input } from '@angular/core'
import { PredictingVirtualScrollStrategy } from './shared/virtualscroll/strategies/PredictingVirtualScrollStrategy'

@Directive({
  selector: '[predictingVirtualScroll]',
  providers: [
    {
      provide: VIRTUAL_SCROLL_STRATEGY,
      /* We will use `useFactory` and `deps` approach for providing the instance  */
      useFactory: (d: VirtualscrollpredictingstrategyDirective) =>
        d._scrollStrategy,
      deps: [forwardRef(() => VirtualscrollpredictingstrategyDirective)]
    }
  ]
})
export class VirtualscrollpredictingstrategyDirective {
  /* Create an instance of the custom scroll strategy that we are going to provide  */
  _scrollStrategy = new PredictingVirtualScrollStrategy()

  private _idAttrName = '' // eg: for post it is: "postID", for message, its "messageID", or "commentID", ...
  private _items: any[] = []
  private _itemType: number = 0 // see PredictingVirtualScrollStrategy
  private _generalData: any
  private _classNameOfItem = ''
  private _padding = 5

  @Input()
  set data(data: any | null) {
    //console.log(">>> Drago7", "Setting data for virtual scroll directive...");

    // define what a "change means" here
    //if (value && this._items.length !== value.length) {
    //}

    if (data) {
      this._items = data.items
      this._itemType = data.itemType
      this._idAttrName = data.idAttrName
      this._generalData = data.generalData

      if (this._itemType == 0) {
        this._classNameOfItem = 'post'
        this._padding = 5
      } else if (this._itemType == 1) {
        this._classNameOfItem = 'item'
        this._padding = 9
      } else {
        // ...
      }

      //console.log("data:",data);
      //console.log("_itemType:",this._itemType);
      //console.log("_classNameOfItem:",this._classNameOfItem);

      this._scrollStrategy.updateItems(this._items)
      this._scrollStrategy.setItemType(this._itemType)
      this._scrollStrategy.setIdAttrName(this._idAttrName)
      this._scrollStrategy.setGeneralData(this._generalData)
      this._scrollStrategy.setItemClassName(this._classNameOfItem)
      this._scrollStrategy.setPadding(this._padding)
    }
  }
}
