import {
  GroupCreatedTimestampInitValue,
  GroupDescInitValue,
  GroupMembersCountInitValue
} from './GroupConstants'

export class GroupInfo {
  private _groupId: string | null = null
  private _desc: string
  private _createdTimestamp: number
  private _membersCount: number
  private _isLoading: boolean = true
  private _image: string = ''
  private _name: string = ''
  private _messagesCount: number = 0

  constructor() {
    this._desc = GroupDescInitValue
    this._createdTimestamp = GroupCreatedTimestampInitValue
    this._membersCount = GroupMembersCountInitValue
  }

  public get messagesCount(): number {
    return this._messagesCount
  }

  public set messagesCount(value: number) {
    this._messagesCount = value
  }

  public get groupId(): string | null {
    return this._groupId
  }

  public set groupId(value: string) {
    this._groupId = value
  }

  public get name(): string {
    return this._name
  }

  public set name(value: string) {
    this._name = value
  }

  public get image(): string {
    return this._image
  }

  public set image(value: string) {
    this._image = value
  }

  public get desc(): string {
    return this._desc
  }

  public set desc(value: string) {
    this._desc = value
  }

  public get createdTimestamp(): number {
    return this._createdTimestamp
  }

  public set createdTimestamp(value: number) {
    this._createdTimestamp = value
  }

  public get membersCount(): number {
    return this._membersCount
  }

  public set membersCount(value: number) {
    this._membersCount = value
  }

  public get isLoading(): boolean {
    return this._isLoading
  }

  public set isLoading(value: boolean) {
    this._isLoading = value
  }
}
