import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { RouterModule } from '@angular/router'
import { Observable } from 'rxjs'
import { UsualDividerComponent } from 'src/app/components/templates/dividers/usual-divider/usual-divider.component'
import { SearchbarComponent } from 'src/app/components/templates/searchbar/searchbar.component'
import { UsernametemplateComponent } from 'src/app/components/templates/username/usernametemplate.component'
import { ImgScaleHelperPipe } from 'src/app/pipes/img-scale-helper.pipe'
import { NumberCommaFormatPipe } from 'src/app/pipes/number-comma-format.pipe'
import { TrendingdataService } from 'src/app/shared/services/data/trendingdata.service'
import { ExploreHashtagItemComponent } from 'src/app/components/explore/explore-hashtag-item/explore-hashtag-item.component'
import { ExploreUserItemComponent } from 'src/app/components/explore/explore-user-item/explore-user-item.component'
import { ExploreLoadingItemComponent } from 'src/app/components/explore/explore-loading-item/explore-loading-item.component'

@Component({
  selector: 'app-right-side-row',
  standalone: true,
  imports: [
    CommonModule,
    NumberCommaFormatPipe,
    RouterModule,
    UsernametemplateComponent,
    ImgScaleHelperPipe,
    SearchbarComponent,
    UsualDividerComponent,
    ExploreHashtagItemComponent,
    ExploreUserItemComponent,
    ExploreLoadingItemComponent
  ],
  templateUrl: './right-side-row.component.html',
  styleUrl: './right-side-row.component.css'
})
export class RightSideRowComponent implements OnInit {
  // EXTRACT LOGIC (QUICK FIX ONLY)
  suggestedQuery?: Observable<string>

  constructor(public trendingdataService: TrendingdataService) {}

  ngOnInit(): void {
    this.suggestedQuery = this.trendingdataService.suggestedSearchQuery
  }
}
