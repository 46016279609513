import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core'
import {
  collection,
  getDocs,
  getFirestore,
  query as firestoreQuery,
  orderBy,
  limit,
  where
} from 'firebase/firestore'
import { MatDialog } from '@angular/material/dialog'
import { HotToastService } from '@ngneat/hot-toast'
import { HTMLFormattingService } from 'src/app/shared/services/formatting/html/htmlformatting.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import {
  child,
  get,
  getDatabase,
  limitToFirst,
  orderByKey,
  query,
  ref,
  startAt
} from 'firebase/database'
import { KeyHelperService } from 'src/app/shared/services/firebase/keyhelper.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { TimeLimitsService } from 'src/app/shared/services/timelimits/timelimits.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { FormControl } from '@angular/forms'
import { Observable, Subject, map, takeUntil } from 'rxjs'
import { KeyboardService } from 'src/app/shared/services/overlay/keyboard-service.service'
import { combineLatest, take } from 'rxjs'

@Component({
  selector: 'app-commentpagetemplate',
  templateUrl: './commentpagetemplate.component.html',
  styleUrls: ['./commentpagetemplate.component.css']
})
export class CommentpagetemplateComponent {
  @Input() isReplies: boolean = false
  @Input() originalComment: any = null
  @Input() post: any = null
  @Input() openKeyboardAlready: boolean = false
  @Input() showCancelButtonAtTop: boolean = true

  textareaControl = new FormControl()
  filteredText$: Observable<string> | null = null

  sendingCommentInProgress: boolean = false

  originalCommentID: string = ''
  originalCommentText: string = ''
  contentPreviewTitle: string = ''
  repliesCount: number = 0

  userID: any = null
  contentPreviewString: string = ''

  @ViewChild('textareainput') textareainput!: ElementRef

  commentEntered: string = ''
  commentCount: number = 0
  itemList: any[] = []
  empty_comments: boolean = false
  loadingInProgress_comments: boolean = false
  latestKey_comments: string | null = '!'
  areYouBlocked: boolean = false
  areYouBlocked_Determined: boolean = false

  showEmojiPicker: boolean = false
  showGifsPicker = false

  isMobile: boolean = false
  isDarkmode: boolean = false

  rtdb = ref(getDatabase())
  db = getFirestore()

  useFireStoreQuery: boolean = false
  latestScore_FireStore: number = Number.MAX_VALUE
  loadPath: string = ''

  titleString: string = 'Comments'
  inputPlaceHolder: string = 'Add a comment...'
  emptyHintString: string = 'No comments yet...'

  loadingDisabled: boolean = false

  onGifSelectedCallback: (url: string) => void
  replyClickCallback: (s: string) => void = (s: string) => {}

  unsubscriberSubject$ = new Subject<void>()

  constructor(
    private toast: HotToastService,
    public htmlFormattingService: HTMLFormattingService,
    public numberFormatService: NumberFormatService,
    public keyHelperService: KeyHelperService,
    public imgHlp: ImageLoadingService,
    public dialog: MatDialog,
    public authService: AuthService,
    public routingHelper: RoutinghelperService,
    private cacheService: CacheService,
    private keyboardService: KeyboardService
  ) {
    this.onGifSelectedCallback = (url: string) => {
      this.sendGif(url)
    }
  }

  ngOnInit(): void {
    this.userID = AuthService.getUID()

    this.isMobile = SystemService.isMobile()
    this.isDarkmode = SystemService.isDarkmode()

    if (this.isReplies) {
      this.repliesCount = this.originalComment.replyCount
      this.originalCommentText = this.originalComment.text
      this.originalCommentID = this.originalComment.commentID

      this.titleString = 'Replies'
      this.inputPlaceHolder = 'Add a reply...'
      this.emptyHintString = 'No replies yet...'

      this.contentPreviewTitle = 'Comment'
      this.contentPreviewString = this.originalCommentText
    } else {
      this.contentPreviewString = this.post.caption
      this.contentPreviewTitle = 'Caption'
    }

    this.commentCount = this.post.commentCount

    if (this.post == null) {
      // error has occurred
      this.toast.error('An error has occurred')
      history.back()
    }

    this.getLoadPath()

    this.setUpInputFilter()
  }

  setUpInputFilter() {
    this.filteredText$ = this.textareaControl.valueChanges.pipe(
      takeUntil(this.unsubscriberSubject$),
      map((text) => text.replace(/\n/g, ''))
    )

    this.filteredText$.subscribe((filteredText) => {
      this.textareaControl.setValue(filteredText, { emitEvent: false })
    })
  }

  onEnter() {
    if (!SystemService.isMobile()) {
      // send comment on enter, but only on desktop
      this.postComment()
    }
  }

  setUpHandleDocumentClick() {
    // ssr-guarded
    if (typeof document === 'undefined') {
      return
    }

    const handleDocumentClick = (event: MouseEvent): void => {
      // Check if emoji keyboard is open, and if so, then close if the click is outside of it
      let isEmojiClassFound = false
      let isGifClassFound = false

      if (event && event.composedPath) {
        const path = event.composedPath()
        path.forEach((elem: EventTarget | null) => {
          if (elem && (elem as HTMLElement).classList) {
            const classList = (elem as HTMLElement).classList
            if (classList.contains('emoji-mart')) {
              isEmojiClassFound = true
            }
            if (classList.contains('gif-picker-class')) {
              isGifClassFound = true
            }
          }
        })
      }

      const targetID = (event.target as HTMLElement).id
      if (!isEmojiClassFound && targetID !== 'emojiButton') {
        this.hideEmojiKeyboard()
      }
      if (
        !isGifClassFound &&
        targetID !== 'gifButtonWrapper' &&
        targetID !== 'gifButtonImage'
      ) {
        this.hideGifsPicker()
      }
    }

    document.addEventListener('click', handleDocumentClick, false)
  }

  ngAfterViewInit(): void {
    this.loadItems()
    this.loadBlocked()

    if (this.openKeyboardAlready) {
      this.textareainput.nativeElement.focus()
    }

    this.setUpHandleDocumentClick()

    this.replyClickCallback = (s: string) => {
      this.replyClick(s)
    }
  }

  getLoadPath() {
    if (this.isReplies) {
      this.loadPath = `${StrHlp.CLOUD_PATH}/PhotoInteraction/${this.post.postID}/CommentReplies/${this.originalCommentID}`
    } else {
      this.loadPath = `${StrHlp.CLOUD_PATH}/PhotoInteraction/${this.post.postID}/Comments`
    }
  }

  loadBlocked() {
    if (this.userID === null || this.userID === 'null') {
      return
    }

    get(
      child(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/BlockedUsers/${this.post.userID}/${this.userID}`
      )
    )
      .then((snapshot) => {
        this.areYouBlocked = snapshot.exists()
        this.areYouBlocked_Determined = true
      })
      .catch((error) => {
        console.error(error)
        this.toast.error('An error has occurred')
      })
  }

  fireStoreQueryUseable(): boolean {
    // 12th november 2022, where the comment algo was introduced via update
    const stampThreshold = 1668065806859 + 2 * 86400000
    return this.post.timestamp > stampThreshold
  }

  public async loadItems() {
    if (this.loadingInProgress_comments) {
      return
    }
    if (this.loadingDisabled) {
      return
    }
    this.loadingInProgress_comments = true
    console.log('loadItems()...')

    const countLoadItems = 20

    let q = null
    let snapshot = null

    const scoreNameString = 'wilsonScore'
    this.useFireStoreQuery = this.fireStoreQueryUseable() && !this.isReplies
    if (this.useFireStoreQuery) {
      //console.log(">>> Uses firestore query for comments loading.");

      // use firestore query (so the comments are ordered by the wilson score)
      const q = firestoreQuery(
        collection(
          this.db,
          `${StrHlp.CLOUD_PATH}/Triggers/Comment/${this.post.postID}/Comments`
        ),
        orderBy(scoreNameString, 'desc'),
        limit(countLoadItems),
        where(scoreNameString, '<', this.latestScore_FireStore)
      )

      // get docs
      snapshot = await getDocs(q)
      if (snapshot.empty) {
        snapshot = null
        this.loadingDisabled = true
      }
    } else {
      //console.log(">>> Uses RTDB query for comments loading.");

      q = query(
        child(this.rtdb, this.loadPath),
        orderByKey(),
        startAt(this.latestKey_comments),
        limitToFirst(countLoadItems)
      )
      // get data
      snapshot = await get(q)

      if (!snapshot.exists()) {
        snapshot = null
        this.loadingDisabled = true
      }
    }

    if (snapshot != null) {
      this.loadingInProgress_comments = false

      snapshot.forEach((childSnapshot: any) => {
        let key
        let comment

        if (this.useFireStoreQuery) {
          key = childSnapshot.id
          this.latestScore_FireStore = childSnapshot.data()[scoreNameString]

          this.firestoreQuery_ContinueWithCommentID(key)
        } else {
          key = childSnapshot.key
          comment = childSnapshot.val()

          // Update latest score:
          // Aappend ~ because 'xxx!' is the smallest lexicographically larger string than 'xxx'.
          // By doing this, we will still catch all posts, but never repeat the latest loaded one upon the next loading.
          this.latestKey_comments = key + '!'

          this.itemList.push(comment)

          // load the info now
          this.loadFurtherItemInfo(this.itemList.length - 1)
        }
      })
    } else {
      if (this.itemList.length == 0) {
        this.empty_comments = true
      } else {
        this.loadingInProgress_comments = false
      }
    }
  }

  async firestoreQuery_ContinueWithCommentID(commentID: string) {
    // get the comment belonging to the commmentID
    const snapshot = await get(
      child(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/PhotoInteraction/${this.post.postID}/Comments/${commentID}`
      )
    )

    if (snapshot.exists()) {
      const comment = snapshot.val()

      // continue as with RTDB now
      this.itemList.push(comment)

      // load the info now
      this.loadFurtherItemInfo(this.itemList.length - 1)
    }
  }

  loadFurtherItemInfo(index: number): void {
    const item = this.itemList[index]
    const userID = item.userID

    // load if liked / disliked
    get(
      child(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/PhotoInteraction/${this.post.postID}/CommentLikes/${item.commentID}/${this.userID}`
      )
    ).then((snapshot) => {
      item.liked = snapshot.exists()
      this.itemList[index] = item
    })
    get(
      child(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/PhotoInteraction/${this.post.postID}/CommentDislikes/${item.commentID}/${this.userID}`
      )
    ).then((snapshot) => {
      item.disliked = snapshot.exists()
      this.itemList[index] = item
    })

    // load user stuff
    if (userID) {
      combineLatest([
        this.cacheService.getUsername(userID),
        this.cacheService.getProfileImage(userID),
        this.cacheService.getVerified(userID)
      ])
        .pipe(take(1))
        .subscribe(([username, img, verified]) => {
          item.username = username
          item.profilePhoto = img
          item.verified = verified

          this.itemList[index] = item
          this.itemList = [...this.itemList]

          //this.markForCD();
        })
    }
  }

  gifButtonClick() {
    this.showGifsPicker = true

    this.keyboardService.openGif(
      (gifURL: string, ratio: number) => {
        this.sendGif(gifURL)
      },
      () => {
        this.showGifsPicker = false
      },
      true
    )
  }

  hideGifsPicker() {
    this.showGifsPicker = false
  }

  cancelEntering() {
    this.commentEntered = ''
  }

  postComment(gifURL: string = '') {
    if (!TimeLimitsService.isAllowed_Session('make-comment', 1500)) {
      this.toast.error("You're too fast")
      return
    }

    // call cloud function
    if (this.sendingCommentInProgress) {
      return
    } else {
      this.sendingCommentInProgress = true
    }

    if (!this.areYouBlocked_Determined || this.areYouBlocked) {
      return
    }

    if (!Boolean(this.commentEntered.trim()) && !Boolean(gifURL)) {
      return
    }

    const functions = getFunctions()
    let replyToCommentID = ''
    if (this.isReplies) {
      replyToCommentID = this.originalCommentID
    }

    const postComment = httpsCallable(functions, 'postComment')
    postComment({
      postID: this.post.postID,
      commentText: this.commentEntered.trim(),
      isReply: this.isReplies + '',
      hubname: StrHlp.CLOUD_PATH,
      replyToCommentID: replyToCommentID,
      gifURL: gifURL
    })
      .then((result) => {
        const data = result.data
        this.sendingCommentInProgress = false
      })
      .catch((error) => {
        console.log(error)
        this.toast.error('Posting comment has failed')
        this.sendingCommentInProgress = false
      })

    // For UI, show immediately, despite unknown result
    this.toast.success('Comment posted')

    this.cancelEntering()
    this.hideEmojiKeyboard()
    this.hideGifsPicker()
  }

  replyClick(username: string) {
    // scroll to input
    this.textareainput.nativeElement.scrollIntoView()

    // input gets focus (open keyboard)
    this.textareainput.nativeElement.focus()

    // insert '@username'
    if (this.commentEntered.trim() === '') {
      this.commentEntered = this.commentEntered + '@' + username
    } else {
      this.commentEntered = this.commentEntered + ' @' + username
    }
  }

  emojiButtonClick() {
    this.showEmojiPicker = true

    this.keyboardService.openEmoji(
      (emj: string) => {
        this.addEmoji(emj)
      },
      () => {
        this.showEmojiPicker = false
      },
      true
    )
  }

  hideEmojiKeyboard() {
    this.showEmojiPicker = false
  }

  addEmoji(emoji: string) {
    this.commentEntered = `${this.commentEntered}${emoji}`
  }

  sendGif(url: string) {
    this.postComment(url)
  }

  ngOnDestroy() {
    this.unsubscriberSubject$.next()
    this.unsubscriberSubject$.complete()
  }
}
