import { Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: [
    './security.component.css',
    '../settingspage/settings.component.css'
  ]
})
export class SecurityComponent {
  constructor(private seoHelper: SeoHelperService) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('Security settings', 'Security settings')
  }
}
