import { Component, ElementRef, Input, ViewChild } from '@angular/core'
import { HotToastService } from '@ngneat/hot-toast'
import { OnedialogserviceService } from 'src/app/shared/services/dialogs/onedialogservice.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { mediumToUpload } from '../mediumToUpload'
import { FullscreenService } from 'src/app/shared/image/fullscreen.service'
import { LoadingDialogComponent } from 'src/app/components/dialogs/loading-dialog/loading-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { IsBrowserService } from 'src/app/shared/services/ssr/isbrowser.service'

@Component({
  selector: 'app-imageuploadtemplate',
  templateUrl: './imageuploadtemplate.component.html',
  styleUrls: ['./imageuploadtemplate.component.css']
})
export class ImageuploadtemplateComponent {
  maxAllowedMedia = 5

  @Input() uploadInProgress: boolean = false
  @Input() mediaChangedCallback: ((arr: mediumToUpload[]) => void) | null = null
  @Input() initImageURL: string | null = null
  @Input() disabled: boolean = false
  @Input() allowMultipleImages: boolean = false

  @Input() allowVideos: boolean = false
  @Input() allowImage: boolean = true
  @Input() treatGifsLikeVideos: boolean = true

  public static maxImageSize_Bytes = 10 * 1024 * 1024 // 10 mb
  public static maxVideoSize_Bytes = 150 * 1024 * 1024 // 150mb

  mediumName = '[error]'
  input_AcceptsString = 'image/*'

  isMobile: boolean = false

  mediaUploaded: mediumToUpload[] = []

  @ViewChild('uploadImageWrapper') uploadImageWrapper!: ElementRef
  @ViewChild('addImageButton_Hidden') addImageButton_Hidden!: ElementRef
  @ViewChild('ratioError') ratioError!: ElementRef

  constructor(
    private toast: HotToastService,
    private oneButtonDialogService: OnedialogserviceService,
    private fullscreenHelper: FullscreenService,
    private dialog: MatDialog,
    private isBrowserService: IsBrowserService
  ) {}

  ngOnInit() {
    if (!this.allowMultipleImages) {
      this.maxAllowedMedia = 1
    }

    if (this.allowImage && this.allowVideos) {
      this.mediumName = 'image or video'
      this.input_AcceptsString = 'image/*,video/*'
    } else {
      if (this.allowImage) {
        this.mediumName = 'image'
        this.input_AcceptsString = 'image/*'
      } else if (this.allowVideos) {
        this.mediumName = 'video'
        this.input_AcceptsString = 'video/*'
      } else {
        // Error
        // fallback
        this.mediumName = 'image'
        this.input_AcceptsString = 'image/*'
      }
    }
  }

  ngAfterViewInit() {
    this.isMobile = SystemService.isMobile()
    this.setUpDragDrop()
  }

  openSelectImage(): void {
    if (this.disabled || this.uploadInProgress) {
      return
    }
    this.addImageButton_Hidden.nativeElement.click()
  }

  /*
  getDisplayImageURL(): string {
    if (this.allowMultipleImages) {
      return '/assets/ic_upload.png';
    }

    if (this.videoFile) {
      return "/assets/video_colored.png";
    }

    if (this.imageUploadedAsString) {
      return this.imageUploadedAsString;
    }

    if (this.initImageURL) {
      return this.initImageURL;
    }
    
    return '/assets/ic_upload.png';
  }
  */

  setUpDragDrop(): void {
    this.uploadImageWrapper.nativeElement.ondragover = function () {
      return false
    }
    this.uploadImageWrapper.nativeElement.omdragend = function () {
      return false
    }

    this.uploadImageWrapper.nativeElement.ondrop = (e: any) => {
      // ssr-guarded
      if (typeof window === 'undefined') {
        return
      }

      if (!window.FileReader) {
        // Browser is not compatible
        this.toast.error('Your browser is not compatible')
        return
      }

      e.preventDefault()
      this.readfiles(e.dataTransfer.files)
    }

    // also set up the click opening handling
    this.addImageButton_Hidden.nativeElement.onchange = (e: any) => {
      // ssr-guarded
      if (typeof window === 'undefined') {
        return
      }

      if (!window.FileReader) {
        // Browser is not compatible
        this.toast.error('Your browser is not compatible')
        return
      }

      e.preventDefault()
      this.readfiles(e.target.files)
    }
  }

  readfiles(files: any) {
    // ssr-guarded
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    if (this.maxAllowedMedia == this.mediaUploaded.length) {
      this.oneButtonDialogService.show(
        'Limit reached',
        `You can only upload up to ${this.maxAllowedMedia} images.`
      )
      return
    }

    this.ratioError.nativeElement.style.display = 'none'

    if (files.length > 0) {
      const file = files[0]

      const type = file.type
      const bytes = file.bytes

      let isImage = type.startsWith('image/')
      let isVideo = type.startsWith('video/')

      const isGIF = type === 'image/gif'

      if (isGIF && this.treatGifsLikeVideos) {
        isImage = false
        isVideo = true
      }

      if (bytes == 0) {
        // error
        this.toast.error('Error occurred')
        return
      }

      if (isImage) {
        // Check that the first uploaded medium, if exists, was not a video
        if (this.mediaUploaded.length > 0 && this.mediaUploaded[0].isVideo) {
          this.oneButtonDialogService.show(
            'Not allowed',
            'You can only upload multiple images, not videos. Please remove the video first to upload multiple images.'
          )
          return
        }

        if (bytes > ImageuploadtemplateComponent.maxImageSize_Bytes) {
          this.oneButtonDialogService.show(
            'File too large',
            `Please ensure that your image is no larger than 10 MB in size.`
          )
          return
        }

        const reader = new FileReader()
        reader.onload = async (event) => {
          const stringRes = reader.result as string

          const image = new Image()
          image.src = stringRes
          image.onload = () => {
            const width = image.width
            const height = image.height
            const ratio = width / height

            //console.log(`w: ${width}, h: ${height}, ratio: ${ratio}`);

            // check if file is allowed (ratio aspect)
            if (ratio < 0.2 || ratio > 5) {
              // not allowed
              this.ratioError.nativeElement.style.display = 'block'
              return
            } else {
              // set
              if (!this.allowMultipleImages) {
                this.mediaUploaded.length = 0 // delete all others first
              }

              this.mediaUploaded.push({
                isVideo: false,
                videoFile: null,
                isImage: true,
                imageAsString: stringRes,
                videoDuration: 0
              })

              // reset others
              this.initImageURL = null

              if (this.mediaChangedCallback) {
                this.mediaChangedCallback(this.mediaUploaded)
              }
            }
          }
        }
        reader.readAsDataURL(file)
      } else if (isVideo) {
        // only allow video if the array is empty,
        // we only allow multiple media for images (yet)
        if (this.mediaUploaded.length > 0) {
          this.oneButtonDialogService.show(
            'Not allowed',
            'You can only upload multiple images, not videos.'
          )
          return
        }

        if (bytes > ImageuploadtemplateComponent.maxVideoSize_Bytes) {
          this.oneButtonDialogService.show(
            'File too large',
            `Please ensure that your video is no larger than 150 MB in size.`
          )
          return
        }

        const video = document.createElement('video')
        video.preload = 'metadata'

        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        video.onloadedmetadata = () => {
          // ssr-guarded
          if (typeof window === 'undefined') {
            return
          }

          loadingDialogRef.close()

          window.URL.revokeObjectURL(video.src)
          if (video.duration == 0) {
            this.toast.error('Error loading video')
            return
          }
          console.log('video.duration: ', video.duration)

          // set
          if (!this.allowMultipleImages) {
            this.mediaUploaded.length = 0 // delete all others first
          }

          this.mediaUploaded.push({
            isVideo: true,
            videoFile: file,
            isImage: false,
            imageAsString: null,
            videoDuration: video.duration
          })

          // reset others
          this.initImageURL = null

          if (this.mediaChangedCallback) {
            this.mediaChangedCallback(this.mediaUploaded)
          }
        }
        video.src = URL.createObjectURL(file)
      } else {
        this.toast.error('Error occurred')
      }
    }
  }

  // used for array only
  deleteMediumByIndex(index: number) {
    this.initImageURL = null
    this.mediaUploaded.splice(index, 1)

    if (this.mediaChangedCallback) {
      this.mediaChangedCallback(this.mediaUploaded)
    }
  }

  getMediumDisplayURL(medium: mediumToUpload): string {
    if (medium.isImage) {
      return medium.imageAsString!
    } else {
      return '/assets/video_colored.png'
    }
  }

  openPreviewFullscreen(src: string) {
    this.fullscreenHelper.open(src, '', 'Preview', '')
  }
}
