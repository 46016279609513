<video
  #videoPlayer
  id="video"
  loop
  playsinline
  [muted]="muted"
  [poster]="posterURL"
>
  <source [src]="videoUrl_1" type="video/mp4" />
  <source [src]="videoUrl_2" type="video/mp4" />
  <source [src]="videoUrl_3" type="video/mp4" />
  <source [src]="videoUrl_4" type="video/mp4" />
  <source [src]="videoUrl_5" type="video/mp4" />
  <source [src]="videoUrl_6" type="video/mp4" />
</video>

<div
  id="customControls"
  #customControls
  (onSingleTap)="singleTapCallback($event)"
  (onDoubleTap)="doubleTap($event)"
>
  <input
    #scrubber
    class="scrubber"
    type="range"
    min="0"
    max="100"
    value="0"
    (input)="onScrub()"
    (touchstart)="onScrubStart()"
    (touchend)="onScrubEnd()"
    (mousedown)="onScrubStart()"
    (mouseup)="onScrubEnd()"
    [ngClass]="{
      'scrubber-highlighted': isPaused || scrubbing,
      'input-range-subtle': !isPaused && !scrubbing,
      'input-range-highlighted': isPaused || scrubbing
    }"
  />

  <img
    [appSpaceyIf]="isPaused"
    id="playButton"
    class="light-icon big-shadow"
    src="/assets/ic_play.png"
  />

  @if (scrubbing) {
    <div id="timestamp-wrapper">
      <div id="timestamp" class="big-shadow">
        {{ currentTime }} / {{ duration }}
      </div>
    </div>
  }
</div>
