<div class="number-badge-host-container">
  <ng-content></ng-content>

  @if (isShowNumber) {
    <div
      class="number-badge-container"
      [ngClass]="{
        'blue-bg': isBlue,
        'red-bg': !isBlue
      }"
      [style.top.px]="topPx"
      [style.right.px]="rightPx"
    >
      <div class="number-badge-text">
        {{ shownNumber }}
      </div>
    </div>
  }
</div>
