import { AfterViewInit, Component, Input } from '@angular/core'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { GifkeyboarddataService } from 'src/app/shared/services/data/gifkeyboarddata.service'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { onVirtualKeyboardClickCallback } from 'src/app/shared/services/overlay/keyboard-service.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'

@Component({
  selector: 'app-gifkeyboard',
  templateUrl: './gifkeyboard.component.html',
  styleUrls: ['./gifkeyboard.component.css']
})
export class GifkeyboardComponent implements AfterViewInit {
  private apikey = 'AIzaSyA4kKkzM-FpKa-D5YAtascXyJMsoWsG9tU'
  private clientkey = 'hub_webapp'

  private lmt_search = 50
  private limit_featured = 50
  private mediafilter = 'gif'
  private country = 'US'
  private locale = 'en_US'
  private contentfilter = StrHlp.ALLOWS_NSFW ? 'off' : 'medium' // off, low, medium, high
  private ar_range = 'wide'

  public searchButtonCallback: (q: string) => void
  public searchResultsCallback: (res: string) => void

  public featuredResultsCallback: (res: string) => void

  public searchMode = false
  private lastSearch = ''

  @Input()
  onClickCallback!: onVirtualKeyboardClickCallback

  //@Input() onGifClick_Callback: (url: string, aspectRatio: number) => void = (arg1: string, arg2: number) => {};
  @Input() useMiniSize = false

  private nextVal_CurrSearch: string | null = null

  emptyRecommended = false
  emptySearch = false

  indicateLoading = false

  constructor(
    public dataService: GifkeyboarddataService,
    private twobuttonsdialogService: TwobuttonsdialogService
  ) {
    this.searchButtonCallback = (q: string) => {
      this.performSearch(q)
    }

    this.searchResultsCallback = (res: string) => {
      this.handleSearchResults(res)
    }

    this.featuredResultsCallback = (res: string) => {
      this.handleFeaturedResults(res)
    }
  }

  ngOnInit() {
    this.loadFeaturedGIFs(true)
  }

  ngAfterViewInit(): void {}

  loadFeaturedGIFs(isInit: boolean = false) {
    if (isInit && this.dataService.nextVal_Featured !== null) {
      // was already init, dont load again
      return
    }

    let url =
      'https://tenor.googleapis.com/v2/featured?' +
      'key=' +
      this.apikey +
      '&client_key=' +
      this.clientkey +
      '&limit=' +
      this.limit_featured +
      '&country=' +
      this.country +
      '&locale=' +
      this.locale +
      '&contentfilter=' +
      this.contentfilter +
      '&mediafilter=' +
      this.mediafilter +
      '&ar_range=' +
      this.ar_range

    if (this.dataService.nextVal_Featured !== null) {
      url = `${url}&pos=${this.dataService.nextVal_Featured}`
    }

    this.indicateLoading = true
    this.httpGetAsync(url, this.featuredResultsCallback)
  }

  // url Async requesting
  httpGetAsync(theUrl: string, callback: (responseText: string) => void): void {
    // create the request object
    const xmlHttp = new XMLHttpRequest()

    // set the state change callback to capture when the response comes in
    xmlHttp.onreadystatechange = () => {
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
        this.indicateLoading = false
        callback(xmlHttp.responseText)
      }
    }

    // open as a GET call, pass in the url and set async = True
    xmlHttp.open('GET', theUrl, true)

    // call send with no params as they were passed in on the url string
    xmlHttp.send(null)
  }

  // callback for share event
  tenorCallback_share(responsetext: string) {
    // no action is needed in the share callback
  }

  // to call the register share endpoint
  send_share(search_term: string, shared_gifs_id: string) {
    // set the apikey and limit
    const share_url =
      'https://tenor.googleapis.com/v2/registershare?id=' +
      shared_gifs_id +
      '&key=' +
      this.apikey +
      '&client_key=' +
      this.clientkey +
      '&q=' +
      search_term

    this.httpGetAsync(share_url, this.tenorCallback_share)
  }

  performSearch(q: string, isLoadMore: boolean = false) {
    q = q.trim()
    if (q === '') {
      this.cancelSearch()
      return
    }

    // clear search results
    if (!isLoadMore) {
      this.clearSearchResults()
    }

    let url =
      'https://tenor.googleapis.com/v2/search?' +
      'q=' +
      q +
      '&key=' +
      this.apikey +
      '&client_key=' +
      this.clientkey +
      '&limit=' +
      this.lmt_search +
      '&country=' +
      this.country +
      '&locale=' +
      this.locale +
      '&contentfilter=' +
      this.contentfilter +
      '&mediafilter=' +
      this.mediafilter +
      '&ar_range=' +
      this.ar_range

    if (this.nextVal_CurrSearch !== null) {
      url = `${url}&pos=${this.nextVal_CurrSearch}`
    }

    this.indicateLoading = true
    this.httpGetAsync(url, this.searchResultsCallback)

    this.lastSearch = q
  }

  // callback for the top 8 GIFs of search
  handleSearchResults(responseText: string): void {
    this.enterSearchMode()

    // Parse the JSON response
    const response_objects = JSON.parse(responseText)
    this.dataService.searchResults.push(...response_objects['results'])

    this.nextVal_CurrSearch = response_objects['next']

    this.emptySearch = this.dataService.searchResults.length == 0
  }

  // callback for featured GIFs
  handleFeaturedResults(responseText: string): void {
    // Parse the JSON response
    const response_objects = JSON.parse(responseText)

    // add to both, list and caching-list
    const resList: any[] = response_objects['results']

    if (resList.length > 0) {
      this.dataService.featuredGIFs.push(...resList)
    }

    this.dataService.nextVal_Featured = response_objects['next']

    this.emptySearch = this.dataService.featuredGIFs.length == 0
  }

  onGifClick(item: any, image: HTMLImageElement) {
    const aspectRatio = image.naturalWidth / image.naturalHeight
    const gifURL = item['media_formats']['gif']['url']
    const miniURL = item['media_formats']['nanogif']['url']
    this.handleGifClick(miniURL, gifURL, item['id'], aspectRatio)
  }

  handleGifClick_wo_AspectRatio(
    miniURL: string,
    gifURL: string,
    gifID: string | null,
    image: HTMLImageElement
  ) {
    const aspectRatio = image.naturalWidth / image.naturalHeight
    this.handleGifClick(miniURL, gifURL, gifID, aspectRatio)
  }

  handleGifClick(
    miniURL: string,
    gifURL: string,
    gifID: string | null,
    aspectRatio: number
  ) {
    //console.log("aspectRatio: " + aspectRatio);
    if (this.onClickCallback) {
      // haptic feedback
      SystemService.hapticsImpactMedium()

      if (this.useMiniSize) {
        this.onClickCallback(miniURL, aspectRatio)
      } else {
        this.onClickCallback(gifURL, aspectRatio)
      }

      // send share to tenor as they want that
      //console.log("Sending to tenor...: query="+this.lastSearch + ", itemID="+item["id"]);
      if (gifID !== null) {
        this.send_share(this.lastSearch, gifID)
      }

      // update recently used list
      this.dataService.addRecent(gifURL)
    }
  }

  clearSearchResults() {
    this.dataService.searchResults.length = 0
  }

  enterSearchMode() {
    this.searchMode = true
  }

  cancelSearch() {
    this.searchMode = false
    this.nextVal_CurrSearch = null
    this.clearSearchResults()
  }

  loadMore() {
    if (this.dataService.currTab == 0) {
      if (this.searchMode) {
        this.performSearch(this.lastSearch, true)
      } else {
        this.loadFeaturedGIFs()
      }
    }
  }

  switchToRecentTab() {
    this.dataService.currTab = 1
    this.dataService.persistTabChoice(1)
  }

  switchToSearchTab() {
    this.dataService.currTab = 0
    this.dataService.persistTabChoice(0)
  }

  switchToFavoritesTab() {
    this.dataService.currTab = 2
    this.dataService.persistTabChoice(2)
  }

  public showDialog_AddToFavorites_Item(item: any) {
    this.showDialog_AddToFavorites(item['media_formats']['gif']['url'])
  }

  public showDialog_AddToFavorites(gifURL: string) {
    SystemService.hapticsImpactMedium()

    this.twobuttonsdialogService.show(
      'Add to favorites',
      'Do you want to add this GIF to your favorites?',
      () => {
        // nothing
      },
      () => {
        this.dataService.addFavorite(gifURL)
      },
      'Cancel',
      'Yes'
    )
  }

  public showDialog_RemoveFromFavorites(gifURL: string) {
    SystemService.hapticsImpactMedium()

    this.twobuttonsdialogService.show(
      'Remove from favorites',
      'Do you want to remove this GIF from your favorites?',
      () => {
        // nothing
      },
      () => {
        this.dataService.removeFavorite(gifURL)
      },
      'Cancel',
      'Yes'
    )
  }

  onSwipeRight() {
    let newVal = this.dataService.currTab - 1
    if (newVal < 0) {
      newVal = 0
    }

    this.dataService.currTab = newVal
    this.dataService.persistTabChoice(newVal)
  }

  onSwipeLeft() {
    let newVal = this.dataService.currTab + 1
    if (newVal > 2) {
      newVal = 2
    }

    this.dataService.currTab = newVal
    this.dataService.persistTabChoice(newVal)
  }
}
