<button
  class="subtle-button"
  (click)="onClick()"
  aria-label="Close"
  matTooltip="Close"
  [matTooltipShowDelay]="1000"
>
  <img
    src="/assets/ic_close_this.png"
    class="dark-icon"
    id="closeButton"
    alt="close"
  />
</button>
