<!-- xxx-new-messages hint -->
@if (showNewMessagesCountHint) {
  <div class="new-activities-hint">
    {{
      newMessagesIndicatorCount == 1
        ? '1 new message'
        : newMessagesIndicatorCount + ' new messages'
    }}
  </div>
}

<div
  #messageContainer
  class="message-container"
  [ngClass]="{
    'message-container-by-other': msgIsByOther,
    'message-container-by-yourself': msgIsByMe,
    highlighted: item.highlighted,
    'not-highlighted': item.removeHighlighted,
    'msg-margin-top': msgHasMarginTop,
    'msg-margin-bottom': msgHasMarginBottom
  }"
>
  <!--
  On above div
  cdkDrag
  cdkDragLockAxis="x"
  (cdkDragStarted)="onDragStart($event)"
  (cdkDragMoved)="onDragMove($event)"
  (cdkDragEnded)="onDragEnd($event)"
  -->

  <div
    class="swipe-indicator-wrapper transparent"
    [ngClass]="{ 'non-transparent': showReplyIcon }"
  >
    <img
      src="/assets/ic_reply.png"
      alt="reply icon"
      class="light-icon icon-swipe-reply-hint"
      loading="lazy"
    />
  </div>

  <!-- Only show the image if the message below is not by the same user.
  and never show for your own messages, and never in private chats -->
  @if (!isPrivate) {
    <img
      [src]="image$ | async | imgScaleHelper: 90"
      alt="{{ name$ | async }} logo"
      onerror="this.src='/assets/default_profile_pic.jpg';this.onerror='';"
      class="userImage_message"
      (click)="openProfileFromItem(item.name, item.messageUID)"
      [style.visibility]="showUserImage ? 'visible' : 'hidden'"
      loading="lazy"
    />
  }

  <div>
    <div
      class="second-part-message-wrapper"
      #message_second_part_wrapper
      (onSingleTap)="onSingleTap($event)"
      (onDoubleTap)="onDoubleTap($event)"
      appSwipeDetect
      (onSwipeRightDetect)="quoteMessageCallback(item)"
    >
      <div
        class="post-action-wrapper menu-button"
        id="menuTriggerMessage"
        mat-button
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="menu"
        matTooltip="Options"
        [matTooltipShowDelay]="1000"
      >
        <img
          class="dark-icon"
          [ngClass]="{
            'menu-icon-message': msgIsByOther,
            'menu-icon-message-self': msgIsByMe
          }"
          src="/assets/menu.png"
          alt="options"
          loading="lazy"
        />
      </div>

      @if (showUsername) {
        <app-usernametemplate
          [values]="{
            userID: item.messageUID,
            smallType1: true,
            darkModeLighter: true,
            createAsDiv: true
          }"
        />
      }

      <div
        class="general-message-wrapper"
        [ngClass]="{
          'message-by-other': msgIsByOther,
          'message-by-yourself': msgIsByMe,
          'message-with-text': containsText,
          'message-pure-image': pureMediumNoText,
          'message-deleted': msgDeleted
        }"
        (contextmenu)="triggerMenu($event); (false)"
      >
        @if (item.replyMessage.length > 0) {
          <div
            [ngClass]="{
              'reply-text': msgIsByOther,
              'reply-by-yourself-text': msgIsByMe
            }"
            (click)="replyClickCallback(item.replyMessageID, $event)"
          >
            @if (
              item.replyMessageFrom !== null && item.replyMessageFrom !== ''
            ) {
              <div class="reply-from-name">
                {{ item.replyMessageFrom }}
              </div>
            }
            <span
              class="break-word"
              [innerHTML]="
                htmlFormattingService.applyTextFormatting(item.replyMessage)
              "
            >
            </span>
          </div>
        }

        @if (containsImg || containsGif) {
          <div class="imageContainer" (longTap)="triggerMenu($event)">
            <img
              class="messageImage"
              #imgEl
              src="{{ mediaLoadingURL }}"
              alt="message image"
              onerror="this.src='/assets/broken_file.png';this.onerror='';"
              (click)="openImageFullscreen($event)"
              loading="lazy"
              (contextmenu)="triggerMenu($event); (false)"
            />
            @if (!containsText) {
              <div class="timestamp-on-image">
                @if (item.forwarded) {
                  <img
                    src="assets/ic_send_message.png"
                    alt="forwaded"
                    class="light-icon msg-bottom-bar-icon"
                    loading="lazy"
                  />
                }
                <span class="msg-bottom-bar-text">{{
                  item.forwarded ? 'Forwarded' : ''
                }}</span>
                @if (item.forwarded) {
                  <span class="simple-divider">•</span>
                }
                {{ item.timestamp | timeformat: 1 }}
              </div>
            }
          </div>
        }

        @if (containsVideo) {
          <div class="imageContainer" (longTap)="triggerMenu($event)">
            <div
              class="video-thumbnail-wrapper"
              (click)="openVideoPlayer($event)"
            >
              <img
                class="messageImage"
                #imgEl
                src="{{ thumbnail }}"
                onerror="this.src='/assets/video_colored.png';this.onerror='';"
                alt="video thumbnail"
                loading="lazy"
              />
              <div class="video-hint-play-icon-wrapper">
                <img
                  src="assets/ic_play.png"
                  alt="play video icon"
                  class="light-icon video-hint-play-icon"
                  loading="lazy"
                />
              </div>
            </div>
            <div class="timestamp-on-image">
              @if (!containsText && item.forwarded) {
                <img
                  src="assets/ic_send_message.png"
                  alt="forwaded"
                  class="light-icon msg-bottom-bar-icon"
                  loading="lazy"
                />
              }
              @if (!containsText && item.forwarded) {
                <span class="msg-bottom-bar-text">Forwarded</span>
              }
              @if (!containsText && item.forwarded) {
                <span class="simple-divider">•</span>
              }
              @if (item.vidDuration) {
                <img
                  src="assets/ic_play.png"
                  alt="play video icon"
                  class="light-icon msg-bottom-bar-icon"
                  loading="lazy"
                />
              }
              @if (item.vidDuration) {
                <span class="msg-bottom-bar-text">{{ vidDurationString }}</span>
              }
              @if (!containsText && item.vidDuration) {
                <span class="simple-divider">•</span>
              }
              @if (!containsText) {
                <span>{{ item.timestamp | timeformat: 1 }}</span>
              }
            </div>
          </div>
        }

        @if (containsText) {
          <span
            #messageEl
            [ngClass]="{
              messageCollapsed:
                msgIsByOther && messageLineCount != -1 && messageCollapsed,
              messageExpanded:
                msgIsByMe || (messageLineCount != -1 && !messageCollapsed),
              'message-text': msgIsByOther,
              'message-by-yourself-text': msgIsByMe,
              'message-collapsed': !item.messageExpanded,
              'message-expanded': item.messageExpanded,
              'message-display-big-emojis': onlyEmojis(item.message)
            }"
            class="message-text-basic"
            [innerHTML]="
              htmlFormattingService.applyTextFormatting(
                htmlFormattingService.applyAll(item.message, msgIsByMe)
              )
            "
          >
          </span>
        }

        @if (msgIsByOther && messageExceedsLineCount && messageCollapsed) {
          <button
            class="subtle-button seeMoreButton"
            [ngClass]="{ 'seeMoreButton-self': msgIsByMe }"
            (click)="unCollapseMessage($event)"
          >
            <b>See more...</b>
          </button>
        }

        @if (msgDeleted) {
          <div
            [ngClass]="{
              'message-text': msgIsByOther,
              'message-by-yourself-text': msgIsByMe
            }"
            class="deleted-message"
          >
            <img
              src="assets/bottomsheet_ic_block.png"
              alt="deleted"
              class="deleted-message-hint-icon"
              [ngClass]="{ 'dark-icon': msgIsByOther, 'light-icon': msgIsByMe }"
              loading="lazy"
            />
            Deleted message
          </div>
        }

        @if (containsVideoOld) {
          <div class="video-not-supported-hint">
            Videos are temporarily unavailable
          </div>
        }

        @if (item.showButton) {
          <button
            class="subtle-button ad-action-button"
            [ngClass]="{ 'red-ad-action-button': item.redButton }"
            (click)="openAdLink()"
          >
            {{ item.buttonText }}
            <img
              src="/assets/ic_arrow_right.png"
              class="light-icon learn-more-ad-icon"
              alt="learn more icon"
              loading="lazy"
            />
          </button>
        }

        @if (item.isAd && item.isSystemAd && item.showMonthlyDonationProgress) {
          <app-donations-progress-bar
            (click)="openAdLink()"
            [progressPercentage]="item.monthlyDonationProgressPercent"
            [useAsButton]="true"
          >
          </app-donations-progress-bar>
        }

        @if (containsText) {
          <div
            [ngClass]="{
              timestamp: msgIsByOther,
              'timestamp-by-yourself': msgIsByMe
            }"
          >
            @if (item.forwarded) {
              <img
                src="assets/ic_send_message.png"
                alt="forwarded"
                class="dark-icon msg-bottom-bar-icon"
                [ngClass]="{
                  'dark-icon opacity-dim-1': msgIsByOther,
                  'light-icon': msgIsByMe
                }"
                loading="lazy"
              />
            }
            @if (item.forwarded) {
              <span class="msg-bottom-bar-text">Forwarded</span>
            }
            @if (item.forwarded) {
              <span class="simple-divider">•</span>
            }
            @if (item.edited) {
              <img
                src="assets/pencil.png"
                alt="edited"
                class="dark-icon msg-bottom-bar-icon"
                [ngClass]="{
                  'dark-icon opacity-dim-1': msgIsByOther,
                  'light-icon': msgIsByMe
                }"
                loading="lazy"
              />
            }
            @if (item.edited) {
              <span class="msg-bottom-bar-text">Edited</span>
            }
            @if (item.edited) {
              <span class="simple-divider">•</span>
            }
            @if (item.isAd) {
              <span class="msg-bottom-bar-text">Sponsored</span>
            }
            @if (item.isAd) {
              <span class="simple-divider">•</span>
            }
            {{ item.timestamp | timeformat: 1 }}
          </div>
        }
      </div>
    </div>

    @if (
      item.countLikes > 0 ||
      item.countDislikes > 0 ||
      item.e3c > 0 ||
      item.e4c > 0 ||
      item.e5c > 0 ||
      item.e6c > 0 ||
      item.e7c > 0 ||
      item.e8c > 0 ||
      item.e9c > 0 ||
      item.e10c > 0 ||
      item.e11c > 0 ||
      item.e12c > 0
    ) {
      <div class="reaction-wrapper">
        @if (item.countLikes > 0) {
          <div class="reaction-wrapper-emoji-wrap">
            <span>
              {{ item.countLikes }}
            </span>
            <img
              [src]="emjNumImge.n1"
              class="reaction-wrapper-emoji-icon"
              alt="thumbs up"
              loading="lazy"
            />
          </div>
        }
        @if (item.countDislikes > 0) {
          <div class="reaction-wrapper-emoji-wrap">
            <span>
              {{ item.countDislikes }}
            </span>
            <img
              [src]="emjNumImge.n2"
              class="reaction-wrapper-emoji-icon"
              alt="thumbs down"
              loading="lazy"
            />
          </div>
        }
        @if (item.e3c > 0) {
          <div class="reaction-wrapper-emoji-wrap">
            <span>
              {{ item.e3c }}
            </span>
            <img
              [src]="emjNumImge.n3"
              class="reaction-wrapper-emoji-icon"
              alt="emoji laugh"
              loading="lazy"
            />
          </div>
        }
        @if (item.e4c > 0) {
          <div class="reaction-wrapper-emoji-wrap">
            <span>
              {{ item.e4c }}
            </span>
            <img
              [src]="emjNumImge.n4"
              class="reaction-wrapper-emoji-icon"
              alt="emoji surprised"
              loading="lazy"
            />
          </div>
        }
        @if (item.e5c > 0) {
          <div class="reaction-wrapper-emoji-wrap">
            <span>
              {{ item.e5c }}
            </span>
            <img
              [src]="emjNumImge.n5"
              class="reaction-wrapper-emoji-icon"
              alt="emoji angry"
              loading="lazy"
            />
          </div>
        }
        @if (item.e11c > 0) {
          <div class="reaction-wrapper-emoji-wrap">
            <span>
              {{ item.e11c }}
            </span>
            <img
              [src]="emjNumImge.n11"
              class="reaction-wrapper-emoji-icon"
              alt="emoji thinking"
              loading="lazy"
            />
          </div>
        }
        @if (item.e6c > 0) {
          <div class="reaction-wrapper-emoji-wrap">
            <span>
              {{ item.e6c }}
            </span>
            <img
              [src]="emjNumImge.n6"
              class="reaction-wrapper-emoji-icon"
              alt="emoji sad"
              loading="lazy"
            />
          </div>
        }
        @if (item.e7c > 0) {
          <div class="reaction-wrapper-emoji-wrap">
            <span>
              {{ item.e7c }}
            </span>
            <img
              [src]="emjNumImge.n7"
              class="reaction-wrapper-emoji-icon"
              alt="emoji love"
              loading="lazy"
            />
          </div>
        }
        @if (item.e8c > 0) {
          <div class="reaction-wrapper-emoji-wrap">
            <span>
              {{ item.e8c }}
            </span>
            <img
              [src]="emjNumImge.n8"
              class="reaction-wrapper-emoji-icon"
              alt="emoji fire"
              loading="lazy"
            />
          </div>
        }
        @if (item.e9c > 0) {
          <div class="reaction-wrapper-emoji-wrap">
            <span>
              {{ item.e9c }}
            </span>
            <img
              [src]="emjNumImge.n9"
              class="reaction-wrapper-emoji-icon"
              alt="emoji happy"
              loading="lazy"
            />
          </div>
        }
        @if (item.e12c > 0) {
          <div class="reaction-wrapper-emoji-wrap">
            <span>
              {{ item.e12c }}
            </span>
            <img
              [src]="emjNumImge.n12"
              class="reaction-wrapper-emoji-icon"
              alt="emoji hundred"
              loading="lazy"
            />
          </div>
        }
        @if (item.e10c > 0) {
          <div class="reaction-wrapper-emoji-wrap">
            <span>
              {{ item.e10c }}
            </span>
            <img
              [src]="emjNumImge.n10"
              class="reaction-wrapper-emoji-icon"
              alt="emoji poop"
              loading="lazy"
            />
          </div>
        }
      </div>
    }
  </div>

  <mat-menu
    #menu="matMenu"
    class="message-options-menu mat-elevation-z0"
    backdropClass="black-backdrop"
  >
    <div class="message-menu" appPopUpFadeInDialog>
      <div class="message-menu-buttons-wrapper">
        <div
          class="standard-options-dialog-item-type3"
          (click)="setReplyTextCallback(item)"
        >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img-type3"
              src="/assets/ic_reply3_gespiegelt.png"
              alt="reply"
              loading="lazy"
            />
          </span>
          <span class="options-text"> Reply </span>
        </div>

        @if (msgIsByOther && !isPrivate) {
          <div
            class="standard-options-dialog-item-type3"
            (click)="replyPrivately(item)"
          >
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img-type3"
                src="/assets/ic_set_avatar.png"
                alt="reply privately"
                loading="lazy"
              />
            </span>
            <span class="options-text"> Reply privately </span>
          </div>
        }

        <div
          class="standard-options-dialog-item-type3"
          (click)="forwardMessage(item)"
        >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img-type3"
              src="/assets/ic_share_post.png"
              alt="forwad"
              loading="lazy"
            />
          </span>
          <span class="options-text"> Forward </span>
        </div>

        @if (containsText) {
          <div class="standard-options-dialog-item-type3" (click)="copy(item)">
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img-type3"
                src="/assets/ic_copy.png"
                alt="copy"
                loading="lazy"
              />
            </span>
            <span class="options-text"> Copy </span>
          </div>
        }

        @if (item.imageURL) {
          <div
            class="standard-options-dialog-item-type3"
            (click)="downloadImage(imgHlp.do(item.imageURL, 1100))"
          >
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img"
                src="/assets/ic_download_2.png"
                alt="download"
                loading="lazy"
              />
            </span>
            <span class="options-text"> Save image </span>
          </div>
        }

        @if (item.gifURL) {
          <div
            class="standard-options-dialog-item-type3"
            (click)="downloadGif(item.gifURL)"
          >
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img"
                src="/assets/ic_download_2.png"
                alt="download"
                loading="lazy"
              />
            </span>
            <span class="options-text"> Save GIF </span>
          </div>
        }

        <div
          class="standard-options-dialog-item-type3"
          (click)="mentionUserCallback(item)"
        >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img-type3"
              src="/assets/ic_at.png"
              alt="mention user"
              loading="lazy"
            />
          </span>
          <span class="options-text"> Mention user </span>
        </div>

        @if (msgIsByMe) {
          <div
            class="standard-options-dialog-item-type3"
            (click)="startEditingMessage()"
          >
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img-type3"
                src="/assets/ic_set_edit_cut.png"
                alt="edit message"
                loading="lazy"
              />
            </span>
            <span class="options-text"> Edit message </span>
          </div>
        }

        @if (msgIsByMe) {
          <div
            class="standard-options-dialog-item-type3"
            (click)="deleteMessage()"
          >
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img-type3"
                src="/assets/ic_set_delete.png"
                alt="delete message icon"
                loading="lazy"
              />
            </span>
            <span class="options-text"> Delete message </span>
          </div>
        }

        @if (msgIsByOther) {
          <div
            class="standard-options-dialog-item-type3"
            (click)="report(item)"
          >
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img-type3"
                src="/assets/ic_set_bug.png"
                alt="report message icon"
                loading="lazy"
              />
            </span>
            <span class="options-text"> Report </span>
          </div>
        }

        @if (
          !isPrivate &&
          msgIsByOther &&
          item.senderUID !== 'nwnqQ1LieaaofxvtAg9ojOBRIeD2'
        ) {
          <div
            class="standard-options-dialog-item-type3"
            (click)="showMuteDialog()"
          >
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img-type3"
                src="/assets/bottomsheet_ic_block.png"
                alt="mute user icon"
                loading="lazy"
              />
            </span>
            <span class="options-text"> Mute user </span>
          </div>
        }

        @if (!isPrivate && areYouGroupAdmin && msgIsByOther) {
          <div
            class="standard-options-dialog-item-type3 group-option-button"
            (click)="removeFromGroup()"
          >
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img-type3"
                src="/assets/bottomsheet_ic_block.png"
                alt="remove from group icon"
                loading="lazy"
              />
            </span>
            <span class="options-text"> Remove from group </span>
          </div>
        }

        @if (!isPrivate && areYouGroupAdmin && msgIsByOther) {
          <div
            class="standard-options-dialog-item-type3 group-option-button"
            (click)="makeUserAdmin()"
          >
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img-type3"
                src="/assets/ic_alert7.png"
                alt="make admin icon"
                loading="lazy"
              />
            </span>
            <span class="options-text"> Make admin </span>
          </div>
        }
      </div>

      @if (msgIsByOther) {
        <div class="emoji-reactions-container-outter">
          <div class="bubble bubble-very-small"></div>
          <div class="bubble bubble-small"></div>
          <div class="bubble bubble-big"></div>
          <div class="emoji-reactions-wrapper">
            <div>
              <img
                [src]="emjNumImge.n1"
                alt="thumbs up emoji"
                class="emoji-reaction"
                (click)="emojiReact(item, 1, $event)"
                loading="lazy"
              />
              <img
                [src]="emjNumImge.n2"
                alt="thumbs down emoji"
                class="emoji-reaction"
                (click)="emojiReact(item, 2, $event)"
                loading="lazy"
              />
              <img
                [src]="emjNumImge.n3"
                alt="laughing emoji"
                class="emoji-reaction"
                (click)="emojiReact(item, 3, $event)"
                loading="lazy"
              />
              <img
                [src]="emjNumImge.n4"
                alt="surprised emoji"
                class="emoji-reaction"
                (click)="emojiReact(item, 4, $event)"
                loading="lazy"
              />
              <img
                [src]="emjNumImge.n11"
                alt="thinking emoji"
                class="emoji-reaction"
                (click)="emojiReact(item, 11, $event)"
                loading="lazy"
              />
              <img
                [src]="emjNumImge.n5"
                alt="angry emoji"
                class="emoji-reaction"
                (click)="emojiReact(item, 5, $event)"
                loading="lazy"
              />
            </div>
            <div>
              <img
                [src]="emjNumImge.n6"
                alt="sad emoji"
                class="emoji-reaction"
                (click)="emojiReact(item, 6, $event)"
                loading="lazy"
              />
              <img
                [src]="emjNumImge.n7"
                alt="love red heart emoji"
                class="emoji-reaction"
                (click)="emojiReact(item, 7, $event)"
                loading="lazy"
              />
              <img
                [src]="emjNumImge.n8"
                alt="fire emoji"
                class="emoji-reaction"
                (click)="emojiReact(item, 8, $event)"
                loading="lazy"
              />
              <img
                [src]="emjNumImge.n9"
                alt="happy emoji"
                class="emoji-reaction"
                (click)="emojiReact(item, 9, $event)"
                loading="lazy"
              />
              <img
                [src]="emjNumImge.n12"
                alt="hundred emoji"
                class="emoji-reaction"
                (click)="emojiReact(item, 12, $event)"
                loading="lazy"
              />
              <img
                [src]="emjNumImge.n10"
                alt="poop emoji"
                class="emoji-reaction"
                (click)="emojiReact(item, 10, $event)"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      }
    </div>
  </mat-menu>
</div>
