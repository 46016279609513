import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { StrHlp } from '../../shared/services/StringGetter/getstring.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: [
    '../privacy/privacy.component.css',
    '../settings/settingspage/settings.component.css'
  ]
})
export class ImprintComponent implements OnInit {
  companyName = 'SocialHubs UG (haftungsbeschränkt)'
  companyEmail = 'app.hub.app@gmail.com'
  companyAddress = 'Berlin, Germany'

  @ViewChild('textwrapper') textwrapper!: ElementRef

  constructor(
    public StrHlp: StrHlp,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('Imprint', 'Imprint')
  }

  ngAfterViewInit(): void {
    this.setUpText()
  }

  setUpText(): void {
    let text: string = this.textwrapper.nativeElement.innerText
    console.log(text)
    text = text
      .replaceAll('%1$s', this.companyName)
      .replaceAll('%2$s', this.companyAddress)
      .replaceAll('%3$s', this.companyEmail)
    console.log(text)
    this.textwrapper.nativeElement.innerText = text
  }
}
