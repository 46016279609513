<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Imprint</h1>
  </header>

  <main class="fh-main content" #contentWrapper>
    <h2>Imprint</h2>

    <div #textwrapper id="text-wrapper">
      <hr class="solid" />

      <div #textwrapper id="text-wrapper">
        %1$s<br />
        %2$s<br />
        Email: %3$s<br />
        <br />
        <br />
        Die Europ&#228;ische Kommission stellt eine Palltform zur
        Online-Streitbelegung unter http://ec.europa.eu/consumers/odr bereit.
        Wir nehmen nicht an Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teil.
      </div>
    </div>
  </main>
</div>
