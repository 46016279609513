<div class="wrapper">
  <div class="wrapper">
    <img id="alreadyPremiumCheck" src="/assets/check.png" alt="check" />

    <div class="text-wrapper">
      <p id="success-text-1">Order Completed Successfully!</p>

      <p id="success-text-2">
        Your order was successfully processed. Return to
        <span class="path-desc">Settings > Premium</span> or close this to see
        when your premium subscription expires.
      </p>
    </div>
  </div>

  <div id="close-button" routerLink="/settings/premium">Close</div>
</div>
