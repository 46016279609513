<a
  class="nav-link"
  role="link"
  (click)="handleClick()"
  [routerLink]="link"
  [attr.aria-label]="ariaLabel"
>
  <app-number-badge
    [number]="badgeNumber"
    [rightPx]="-10"
    [topPx]="-10"
    [isBlueInput]="false"
  >
    <img
      class="nav-link-image nav-link-image-dimensions"
      [ngClass]="{
        'dark-icon': isDarkIcon,
        'profile-image': isProfileImage
      }"
      [src]="isSelected ? selectedImgSrc : notSelectedImgSrc"
      [alt]="imageAlt"
    />
  </app-number-badge>

  <strong
    class="nav-link-text"
    [ngClass]="{
      'nav-link-text-selected': isSelected,
      'nav-link-text-not-selected': !isSelected
    }"
  >
    {{ text }}
  </strong>
</a>
