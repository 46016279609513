import { trigger, transition, style, animate } from '@angular/animations'
import { CdkDragEnd, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  inject
} from '@angular/core'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { MatDialog } from '@angular/material/dialog'
import { MatMenuTrigger } from '@angular/material/menu'
import { Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Observable, combineLatest, of, take } from 'rxjs'
import { ChatPageComponent } from 'src/app/components/chat-page/chat-page.component'
import { ChatactionComponent } from 'src/app/components/dialogs/chataction/chataction.component'
import { LoadingDialogComponent } from 'src/app/components/dialogs/loading-dialog/loading-dialog.component'
import { ReportComponent } from 'src/app/components/report/report.component'
import { TimeformatPipe } from 'src/app/pipes/timeformat.pipe'
import { GLOBAL_CHAT_ID } from 'src/app/shared/constants'
import { ScreenOverlay } from 'src/app/shared/datatypes/screen-overlay'
import { FullscreenService } from 'src/app/shared/image/fullscreen.service'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { ChatDataService } from 'src/app/shared/services/data/chatdata.service'
import { InputdialogService } from 'src/app/shared/services/dialogs/inputdialog.service'
import { OpenvideoplayerService } from 'src/app/shared/services/dialogs/openvideoplayer.service'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { KeyHelperService } from 'src/app/shared/services/firebase/keyhelper.service'
import { HTMLFormattingService } from 'src/app/shared/services/formatting/html/htmlformatting.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { GroupserviceService } from 'src/app/shared/services/groups/groupservice.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { DownloadserviceService } from 'src/app/shared/services/media/downloadservice.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { SetTimeoutService } from 'src/app/shared/services/ssr/set-timeout.service'
import { RootStateService } from 'src/app/shared/services/state/root-state.service'
import { StringUsus } from 'src/app/shared/services/string/stringusus.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { onlyEmojis_DisplayBig } from 'src/app/shared/virtualscroll/strategies/ItemHeightPredictor'

export const emjNumImge = {
  n1: '/assets/ic_thumbs_up_emoji.png',
  n2: '/assets/ic_thumbs_down_emoji.png',
  n3: '/assets/emoji_react_laugh.png',
  n4: '/assets/emoji_react_surprised.png',
  n11: '/assets/emoji_thinking.png',
  n5: '/assets/emoji_react_angry.png',
  n6: '/assets/emoji_react_sad.png',
  n7: '/assets/ic_emoji_red_heart.png',
  n8: '/assets/emoji_fire.png',
  n9: '/assets/emoji_happy.png',
  n12: '/assets/emoji_hundred.png',
  n10: '/assets/emoji_poop.png'
}

const msgDragMaxDistance = 120
const msgSlowDownDragFactor = 0.5
const msgDragMinTriggerDist = 55
const msgDragMinReflectUiDist = 20

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-messagetemplate',
  templateUrl: './messagetemplate.component.html',
  styleUrls: ['./messagetemplate.component.css']
})
export class MessagetemplateComponent {
  private bottomSheet = inject(MatBottomSheet)

  emjNumImge = emjNumImge

  // for recycling reasons, we omit the built-in loading
  // of username-template and manually load it here
  name$?: Observable<string>
  image$?: Observable<string>

  private _item: any = null
  @Input()
  set item(value: any) {
    this._item = value.item

    this.showNewMessagesCountHint = value.showNewMessagesCountHint
    this.newMessagesIndicatorCount = value.newMessagesIndicatorCount
    this.chatID = value.chatID
    this.isGroup = value.isGroup
    this.isPrivate = value.isPrivate
    this.isMeUser1 = value.isMeUser1
    this.otherUserID = value.otherUserID
    this.chatName = value.chatName
    this.areYouGroupAdmin = value.areYouGroupAdmin
    this.showUsername = value.showUsername
    this.msgHasMarginTop = value.msgHasMarginTop
    this.msgHasMarginBottom = value.msgHasMarginBottom
    this.showUserImage = value.showUserImage

    this.itemChanged()
  }

  get item(): any {
    return this._item
  }

  // needed for recycling reasons
  itemChanged() {
    this.determineData()
    this.getFurtherData()
  }

  showNewMessagesCountHint: boolean = false
  newMessagesIndicatorCount: number = 0
  chatID: string = ''
  isGroup: boolean = false
  isPrivate: boolean = false
  isMeUser1: boolean = false
  otherUserID: string = ''
  chatName: string = ''
  areYouGroupAdmin: boolean = false

  showUsername: boolean = true
  msgHasMarginTop: boolean = false
  msgHasMarginBottom: boolean = false
  showUserImage: boolean = false

  @Input() replyClickCallback: (quotedMessageID: string, event: any) => void =
    () => {}
  @Input() setReplyTextCallback: (item: any) => void = () => {}
  @Input() mentionUserCallback: (item: any) => void = () => {}
  @Input() quoteMessageCallback: (item: any) => void = () => {}
  @Input() editMessageCallback: (item: any, newText: string) => void = () => {}
  @Input() askMuteCallback: (item: any) => void = () => {}
  @Input() askDeleteMessageCallback: (item: any) => void = () => {}

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger
  @ViewChild('messageContainer') messageContainer!: ElementRef

  // Reset because of recycling!!
  emojiReactInProcess: boolean = false
  imageLoadingURL = ''
  gifLoadingURL = ''
  mediaLoadingURL = ''
  containsText = false
  containsMedia = false
  containsImg = false
  containsGif = false
  containsVideo = false
  containsVideoOld = false
  msgIsByOther = false
  msgIsByMe = false
  msgDeleted = false
  pureMediumNoText = false
  thumbnail = ''
  vidDurationString = ''

  showReplyIcon = false
  disableSwipeReplyHaptics = false

  @ViewChild('messageEl') messageEl!: ElementRef
  messageCollapsedLineCount: number = 15
  messageLineCount: number = -1
  messageExceedsLineCount: boolean = false
  messageCollapsed: boolean = true

  @ViewChild('imgEl') imgEl!: ElementRef
  // --

  // helper vars for distinction of single tap and double tap
  message_timer: any
  message_preventSimpleClick: boolean = false
  message_preventSimpleClick_Delay: number = 350

  isGlobalChat: boolean = false
  userID: any = null

  onDoubleTap: (event: MouseEvent) => void = (event) => {
    this.messageDoubleClick(this.item, event)
  }

  onSingleTap: (event: MouseEvent) => void = (event) => {
    this.messageSingleClick()
  }

  constructor(
    private toast: HotToastService,
    public htmlFormattingService: HTMLFormattingService,
    public numberFormatService: NumberFormatService,
    public keyHelperService: KeyHelperService,
    public imgHlp: ImageLoadingService,
    public authService: AuthService,
    public strHlp: StrHlp,
    private router: Router,
    private dialog: MatDialog,
    public fullscreenHelper: FullscreenService,
    private inputDialogService: InputdialogService,
    private twobuttonsdialogService: TwobuttonsdialogService,
    public routingHelper: RoutinghelperService,
    public chataDataService: ChatDataService,
    private downloadService: DownloadserviceService,
    private cacheService: CacheService,
    private groupsService: GroupserviceService,
    private renderer: Renderer2,
    private openVideoPlayerService: OpenvideoplayerService,
    private rootState: RootStateService,
    private cdRef: ChangeDetectorRef,
    private timeFormat: TimeformatPipe,
    private setTimeoutService: SetTimeoutService
  ) {
    this.userID = AuthService.getUID()
  }

  ngOnInit() {
    this.isGlobalChat = GLOBAL_CHAT_ID === this.chatID

    this.itemChanged()
  }

  ngAfterViewInit() {
    this.countLinesCaption()

    // For fixing CLS
    //if (this.containsMedia && this.imgEl) {
    //this.setUpImageWidthHeight();
    //}
  }

  /**
   * we calculate the height and width now
   * because we have the aspect ratio given, at least from imgs from
   * PWA users. We do this to prevent CLS.
   */
  setUpImageWidthHeight() {
    // ssr-guarded
    if (typeof window === 'undefined') {
      return
    }

    const imgEl = this.imgEl.nativeElement

    const minWidth = 50
    const minHeight = 50

    const maxWidth =
      this.messageContainer.nativeElement.clientWidth * (70 / 100) // max 70 "vw"
    const maxHeight = window.innerHeight * (65 / 100) // max 65 "vh"

    let w = this.item.medW
    let h = this.item.medH
    const ar = this.item.medAR

    /*    
    console.log("DragoIm", "----------------------------------------------------");
    console.log("DragoIm", "w: "+w);
    console.log("DragoIm", "h: "+h);
    console.log("DragoIm", "ar: "+ar);
    console.log("DragoIm", "maxW: "+maxWidth);
    console.log("DragoIm", "maxH: "+maxHeight);
    */

    if (w === undefined || w === null || w == 0) {
      w = maxWidth // fallback
    }

    if (w > maxWidth) {
      w = maxWidth
    }
    if (w < minWidth) {
      w = minWidth
    }

    // if ar is given and height was not already specified,
    // use ar to calculate
    if (ar !== undefined && (h === undefined || h === null || h == 0)) {
      h = w * (1 / ar)
    }

    if (h > maxHeight) {
      h = maxHeight
    }
    if (h < minHeight) {
      h = minHeight
    }
    //console.log("DragoIm", "--> w "+w);
    //console.log("DragoIm", "--> h "+h);

    imgEl.style.width = `${w}px`
    imgEl.style.height = `${h}px`
  }

  onDragStart(e: CdkDragStart) {
    // ...
  }

  onDragMove(event: CdkDragMove) {
    let newPositionX = event.source.getFreeDragPosition().x

    if (newPositionX > msgDragMaxDistance) {
      newPositionX = msgDragMaxDistance
    }
    if (newPositionX < msgDragMinReflectUiDist) {
      newPositionX = 0
    }

    if (newPositionX >= msgDragMinTriggerDist) {
      this.disableSwipeReplyHaptics = true
      this.showReplyIcon = true
      this.quoteMessageCallback(this.item)
    }

    event.source._dragRef.setFreeDragPosition({
      x: newPositionX * msgSlowDownDragFactor,
      y: 0 // y drag is disabled so its always 0
    })
  }

  onDragEnd(e: CdkDragEnd) {
    this.showReplyIcon = false
    e.source._dragRef.reset()
  }

  determineData() {
    // determine uid of who sent the msg
    // if its a group chat, this will work
    let messageUID = ''
    if (this.isGroup) {
      messageUID = this.item.senderUID
    } else {
      if (this.item.messageFromUser1) {
        if (this.isMeUser1) {
          messageUID = this.userID
        } else {
          messageUID = this.otherUserID
        }
      } else {
        if (this.isMeUser1) {
          messageUID = this.otherUserID
        } else {
          messageUID = this.userID
        }
      }
    }
    this.item.messageUID = messageUID

    // who sent
    this.msgIsByMe = this.item.messageUID === this.userID
    this.msgIsByOther = this.item.messageUID !== this.userID

    // content of msg
    this.containsText = this.item.message && this.item.message !== ''
    this.containsImg = this.item.imageURL && this.item.imageURL !== ''
    this.containsGif = this.item.gifURL && this.item.gifURL !== ''
    this.containsVideoOld = this.item.vidURL && this.item.vidURL !== ''
    this.containsVideo = this.item.vid && this.item.vid !== ''

    // media loading URL
    if (this.containsGif) {
      this.gifLoadingURL = this.item.gifURL
      this.mediaLoadingURL = this.gifLoadingURL
    } else if (this.containsImg) {
      this.imageLoadingURL = this.imgHlp.do(this.item.imageURL, 1100)
      this.mediaLoadingURL = this.imageLoadingURL
    }
    // no if for video since not available atm

    this.containsMedia =
      this.containsGif ||
      this.containsImg ||
      this.containsVideo ||
      this.containsVideoOld

    // pure media
    this.pureMediumNoText = !this.containsText && this.containsMedia

    // deleted
    this.msgDeleted = !this.containsText && !this.containsMedia

    if (this.containsVideo) {
      this.thumbnail = `https://vz-97291f5c-63e.b-cdn.net/${this.item.vid}/thumbnail.jpg`
      this.vidDurationString = this.getDurationString(this.item.vidDuration)
    }
  }

  openProfileFromItem(username: string | null, uid: string): void {
    if (this.item.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    this.routingHelper.user(null, uid)
  }

  async copy(item: any) {
    const text = item.message
    if (text && text.length > 0) {
      try {
        await navigator.clipboard.writeText(text)
        this.toast.success('Copied to clipboard')
      } catch (err) {
        console.error('Failed to copy')
      }
    }
  }

  report(item: any) {
    if (this.item.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    const reportID = this.chatID + ReportComponent.partsDivider + item.messageID

    const data = {
      reportType: 3,
      reportID: reportID
    }
    this.bottomSheet.open(ReportComponent, {
      data: data
    })
  }

  onMessageLongTap() {
    if (this.pureMediumNoText) {
      // show menu
      if (this.menuTrigger !== null) {
        this.menuTrigger.openMenu()
      }
    } else {
      // TODO
      // select msg and go into select mode
    }
  }

  showMuteDialog() {
    if (this.item.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }
    this.askMuteCallback(this.item)
  }

  async emojiReact(item: any, reactionType: number, event?: MouseEvent) {
    if (this.item.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    if (reactionType < 1 || reactionType > 12) {
      return // invalid input
    }

    if (item.messageUID === this.userID) {
      return
    }

    if (this.emojiReactInProcess) {
      return
    } else {
      this.emojiReactInProcess = true
    }

    const functions = getFunctions()
    const cloudFunction = httpsCallable(functions, 'reactToMessage')

    cloudFunction({
      hubname: StrHlp.CLOUD_PATH,
      reactionType: '' + reactionType,
      chatID: this.chatID,
      isGroup: '' + this.isGroup,
      messageID: item.messageID
    })
      .then(() => {
        // success
        this.emojiReactInProcess = false
      })
      .catch((error) => {
        this.emojiReactInProcess = false
        console.log(error)
      })

    // vibrate phone
    SystemService.hapticsImpactMedium()

    if (event) {
      if (reactionType == 1) {
        this.addLikeOverlay(this.emjNumImge.n1, event)
      } else if (reactionType == 2) {
        this.addLikeOverlay(this.emjNumImge.n2, event)
      } else if (reactionType == 3) {
        this.addLikeOverlay(this.emjNumImge.n3, event)
      } else if (reactionType == 4) {
        this.addLikeOverlay(this.emjNumImge.n4, event)
      } else if (reactionType == 5) {
        this.addLikeOverlay(this.emjNumImge.n5, event)
      } else if (reactionType == 6) {
        this.addLikeOverlay(this.emjNumImge.n6, event)
      } else if (reactionType == 7) {
        this.addLikeOverlay(this.emjNumImge.n7, event)
      } else if (reactionType == 8) {
        this.addLikeOverlay(this.emjNumImge.n8, event)
      } else if (reactionType == 9) {
        this.addLikeOverlay(this.emjNumImge.n9, event)
      } else if (reactionType == 10) {
        this.addLikeOverlay(this.emjNumImge.n10, event)
      } else if (reactionType == 11) {
        this.addLikeOverlay(this.emjNumImge.n11, event)
      } else if (reactionType == 12) {
        this.addLikeOverlay(this.emjNumImge.n12, event)
      }
    }
  }

  downloadImage(url: string) {
    this.downloadService.downloadImage(url, `${StrHlp.APP_NAME}_post`)
  }

  downloadGif(url: string) {
    this.downloadService.downloadGIF(url, `${StrHlp.APP_NAME}_gif`)
  }

  startEditingMessage(): void {
    this.inputDialogService.openDialog(
      this.item.message,
      'Save',
      'Cancel',
      ChatPageComponent.MAX_MESSAGE_LENGTH,
      (text) => {
        this.editMessageCallback(this.item, text)
      },
      null,
      '',
      'Edit message'
    )
  }

  deleteMessage(): void {
    if (this.item.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }
    this.askDeleteMessageCallback(this.item)
  }

  messageSingleClick(): void {
    console.log('messageSingleClick!')
    this.menuTrigger.openMenu()
  }

  messageDoubleClick(item: any, event: MouseEvent): void {
    if (this.item.isAd) {
      return
    }
    this.emojiReact(item, 1, event)
  }

  getFurtherData() {
    if (this.item.isAd) {
      this.name$ = of(this.item.name)
      this.image$ = this.cacheService.getProfileImage(this.item.messageUID)
    } else {
      this.name$ = this.cacheService.getUsername(this.item.messageUID)
      this.image$ = this.cacheService.getProfileImage(this.item.messageUID)
      //this.verified$ = this.cacheService.getVerified(this.item.messageUID);
    }
  }

  openImageFullscreen(event: any) {
    this.name$?.pipe(take(1)).subscribe({
      next: (name) =>
        this.fullscreenHelper.open(
          this.imageLoadingURL,
          this.gifLoadingURL,
          `${name}, 
          ${this.timeFormat.transform(this.item.timestamp, 1) as string}`,
          this.item.message
        ),
      error: console.log
    })

    // stopPropagation since we do not want to open the option dialog
    // if we just click on the reply message
    event.stopPropagation()
  }

  openVideoPlayer(event: any) {
    this.openVideoPlayerService.show(this.item.vid)

    // stopPropagation since we do not want to open the option dialog
    // if we just click on the reply message
    event.stopPropagation()
  }

  forwardMessage(itemMessage: any) {
    if (this.item.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    const data = {
      pageTitle: 'Forward message',
      disableAutoFocus: true,
      clickCallback: (itemChat: any) => {
        const dataForCallback = {
          chatID: itemChat.chatID,
          name: itemChat.name,
          verified: itemChat.verified,
          image: itemChat.image,
          isGroup: itemChat.isGroup,
          isPrivate: itemChat.isPrivate,
          otherUserID: itemChat.otherUserID,
          isMeUser1: itemChat.isMeUser1,
          newMessagesCount: 0,

          // chatOK does not work here
          chatOK1: true,
          chatOK2: true,

          replyMessageFrom: '',
          replyMessage: '',

          // Forward stuff
          forwardMessage: itemMessage.message,
          forwardImageURL: itemMessage.imageURL,
          forwardGifURL: itemMessage.gifURL
        }

        this.router.navigate(['msg'], { state: dataForCallback })
      }
    }

    this.bottomSheet.open(ChatactionComponent, {
      data: data
    })
  }

  onlyEmojis(inp: string) {
    return onlyEmojis_DisplayBig(inp)
  }

  replyPrivately(item: any): void {
    if (this.item.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    let isMeUser1 = false
    if (StringUsus.CompareTo_Java(this.userID, item.messageUID) < 0) {
      isMeUser1 = true
    }
    let chatID = ''
    if (isMeUser1) {
      chatID = this.userID + '_' + item.messageUID
    } else {
      chatID = item.messageUID + '_' + this.userID
    }

    let replyMessage = item.message
    if (item.imageURL !== undefined && item.imageURL.length > 0) {
      replyMessage = '🖼️ Photo'
    }

    const data = {
      chatID: chatID,
      name: item.name,
      verified: item.verified,
      image: item.image,
      isGroup: false,
      isPrivate: true,
      otherUserID: item.messageUID,
      isMeUser1: isMeUser1,
      newMessagesCount: 0,

      // chatOK does not work here
      chatOK1: true,
      chatOK2: true,

      replyMessageFrom: this.chatName,
      replyMessage: replyMessage
    }

    this.router.navigate(['msg'], { state: data })
  }

  countLinesCaption() {
    if (this.messageEl) {
      this.messageLineCount =
        this.messageEl.nativeElement.getClientRects().length
      this.messageExceedsLineCount =
        this.messageLineCount > this.messageCollapsedLineCount
    }
  }

  unCollapseMessage(event: any) {
    this.messageCollapsed = false

    // stop propagation of this event
    event.stopPropagation()
  }

  removeFromGroup() {
    if (this.item.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    // ask if sure
    this.twobuttonsdialogService.show(
      'Remove from group',
      'Do you want to remove this user from the group?',
      () => {
        // nothing
      },
      async () => {
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        try {
          await this.groupsService.removeFromGroup(
            this.chatID,
            this.item.senderUID
          )

          loadingDialogRef.close()

          this.toast.success('Success')
        } catch (error) {
          loadingDialogRef.close()
          console.log(error)
        }
      },
      'Cancel',
      'Okay'
    )
  }

  makeUserAdmin() {
    if (this.item.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    // ask if sure
    this.twobuttonsdialogService.show(
      'Make admin',
      'Do you want to give this user admin rights for the group?',
      () => {
        // nothing
      },
      async () => {
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        try {
          await this.groupsService.makeAdmin(this.chatID, this.item.senderUID)

          loadingDialogRef.close()
          this.toast.success('Success')
        } catch (error) {
          loadingDialogRef.close()
          console.log(error)
        }
      },
      'Cancel',
      'Okay'
    )
  }

  openAdLink() {
    // ssr-guarded
    if (typeof window === 'undefined') {
      return
    }

    if (this.item.isSystemAd) {
      this.router.navigateByUrl(this.item.actionURL)
    } else {
      window.open(this.item.actionURL, '_blank')
    }
  }

  onActionRejectedBecauseItsAd() {
    this.toast.show('Not available for this message')
  }

  getDurationString(durationInSecs: number) {
    if (durationInSecs == ChatPageComponent.gifDetection_Duration_Code) {
      return 'GIF'
    }

    const duration = durationInSecs * 1000

    const seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

    if (hours > 0) {
      return `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
    } else {
      return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`
    }
  }

  triggerMenu(event: any) {
    event.preventDefault()

    // haptic feedback
    SystemService.hapticsImpactMedium()

    this.menuTrigger.openMenu()

    // stopPropagation
    if (event.stopPropagation) {
      event.stopPropagation()
    }
  }

  addLikeOverlay(imageSource: string, event: MouseEvent) {
    const el = Boolean(this.messageEl)
      ? this.messageEl.nativeElement
      : this.imgEl.nativeElement

    let { x, y } = el.getBoundingClientRect()
    const width = el.getBoundingClientRect().width
    const height = el.getBoundingClientRect().height

    // But: these x and y are the left top point of the message. We want the bottom middle.
    x += Math.floor(width * 0.5)
    y += Math.floor(height * 0.95)

    this.rootState.addOverlay(new ScreenOverlay(imageSource, x, y, 2000, true))
  }
}
