import { Injectable } from '@angular/core'
import { HotToastService } from '@ngneat/hot-toast'
import { LocalstorageService } from './ssr/localstorage.service'

@Injectable({
  providedIn: 'root'
})
export class MuteUsersService {
  private storageKey = 'Key_MuteUsersService'

  private storageKey_24hours_userID = '1Key_MuteUsersService_24hours_userID'
  private storageKey_24hours_timestamp =
    '1Key_MuteUsersService_24hours_timestamp'

  private muteUserIDs: string[] = []

  private array_24hours_userID: string[] = []
  private array_24hours_timestamp: number[] = []

  private MAX_LENGTH_ALLOWED: number = 200
  private dayInMillis: number = 1000 * 60 * 2 //86400000;

  constructor(
    private toast: HotToastService,
    private localstorageService: LocalstorageService
  ) {
    this.loadStringArray() // Load the string array on service initialization
  }

  private tooManyMuted(): boolean {
    return (
      this.muteUserIDs.length + this.array_24hours_userID.length >
      this.MAX_LENGTH_ALLOWED
    )
  }

  public muteUser(userID: string) {
    if (this.tooManyMuted()) {
      this.toast.error('You have muted too many users')
      return
    }

    if (this.muteUserIDs.includes(userID)) {
      this.toast.error('You have muted this user already')
      return
    }

    // mute user
    this.muteUserIDs.push(userID)

    // persist
    this.saveStringArray()

    this.toast.success('User muted')
  }

  public muteUser_24hours(userID: string) {
    if (this.tooManyMuted()) {
      this.toast.error('You have muted too many users')
      return
    }

    if (this.array_24hours_userID.includes(userID)) {
      this.toast.error('You have muted this user already')
      return
    }

    // mute user
    this.array_24hours_userID.push(userID)
    this.array_24hours_timestamp.push(Date.now())

    // persist
    this.saveStringArray()

    this.toast.success('User muted')
  }

  private saveStringArray(): void {
    this.localstorageService.setItem(
      this.storageKey,
      JSON.stringify(this.muteUserIDs)
    )

    this.localstorageService.setItem(
      this.storageKey_24hours_userID,
      JSON.stringify(this.array_24hours_userID)
    )

    this.localstorageService.setItem(
      this.storageKey_24hours_timestamp,
      JSON.stringify(this.array_24hours_timestamp)
    )
  }

  private loadStringArray(): void {
    const storedStrings = this.localstorageService.getItem(this.storageKey)
    this.muteUserIDs = storedStrings ? JSON.parse(storedStrings) : []

    // 24 hours muted
    const raw_24hours_userID = this.localstorageService.getItem(
      this.storageKey_24hours_userID
    )
    this.array_24hours_userID = raw_24hours_userID
      ? JSON.parse(raw_24hours_userID)
      : []

    const raw_24hours_timestamp = this.localstorageService.getItem(
      this.storageKey_24hours_timestamp
    )
    this.array_24hours_timestamp = raw_24hours_timestamp
      ? JSON.parse(raw_24hours_timestamp)
      : []

    // filter the ones that expired
    let madeChanges = false
    for (let i = this.array_24hours_userID.length - 1; i >= 0; i--) {
      if (this.array_24hours_timestamp[i] + this.dayInMillis < Date.now()) {
        // expired, remove
        this.array_24hours_userID.splice(i, 1)
        this.array_24hours_timestamp.splice(i, 1)
        madeChanges = true
      }
    }

    if (madeChanges) {
      // persist again
      this.saveStringArray()
    }
  }

  public isMuted(userID: string): boolean {
    return (
      this.muteUserIDs.includes(userID) ||
      this.array_24hours_userID.includes(userID)
    )
  }

  public unmuteUser(userID: string, isTemp: boolean) {
    if (isTemp) {
      const index = this.array_24hours_userID.indexOf(userID)
      if (index != -1) {
        this.array_24hours_userID.splice(index, 1)
        this.saveStringArray()
        this.toast.success('Unmuted')
      } else {
        //this.toast.error("Error occurred");
      }
    } else {
      const index = this.muteUserIDs.indexOf(userID)
      if (index != -1) {
        this.muteUserIDs.splice(index, 1)
        this.saveStringArray()
        this.toast.success('Unmuted')
      } else {
        //this.toast.error("Error occurred");
      }
    }
  }

  public getListPerma(): string[] {
    return this.muteUserIDs
  }

  public getList24h(): string[] {
    return this.array_24hours_userID
  }
}
