import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  makeStateKey
} from '@angular/core'
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit
} from 'firebase/firestore'
import { getDatabase, ref, child, get } from 'firebase/database'
import { HotToastService } from '@ngneat/hot-toast'
import { StrHlp } from '../../shared/services/StringGetter/getstring.service'
import { HTMLFormattingService } from 'src/app/shared/services/formatting/html/htmlformatting.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { _getFocusedElementPierceShadowDom } from '@angular/cdk/platform'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { TimeLimitsService } from 'src/app/shared/services/timelimits/timelimits.service'
import { ActivatedRoute, Router } from '@angular/router'
import { KeyHelperService } from 'src/app/shared/services/firebase/keyhelper.service'
import { MatDialog } from '@angular/material/dialog'
import { EncodingService } from 'src/app/shared/services/encoding/encoding.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { FullscreenService } from 'src/app/shared/image/fullscreen.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { FeeddataService } from 'src/app/shared/services/data/feeddata.service'
import { DatasharingService } from 'src/app/shared/services/data/datasharing.service'
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { ReplaySubject, takeUntil } from 'rxjs'

//const KEY_HASHTAG_LIST = makeStateKey<{data:any}>("KEY_HASHTAG_LIST");

/**
 * Poorly named:
 * Also used for location-based posts
 */

@Component({
  selector: 'app-hashtag',
  templateUrl: './hashtag.component.html',
  styleUrls: [
    'hashtag.component.css',
    '../../stylesheets/sort-choose.css',
    '../liked/liked.component.css',
    '../feed/feed.component.css',
    '../settings/settingspage/settings.component.css'
  ]
})
export class HashtagComponent implements OnInit, OnDestroy {
  diffThresholdForReachedBottom: number = 1000

  userID: any = null
  rtdb = ref(getDatabase())
  db = getFirestore()
  dbRTDB = getDatabase()

  scrollIsAtTop = true
  @ViewChild('virtualScrollViewport')
  virtualScrollViewport!: CdkVirtualScrollViewport

  private destroyedSubject: ReplaySubject<void> = new ReplaySubject(1)

  // ---

  type: string | null = ''

  postLoadingInProgress: boolean = false
  latestScore: number = Number.MAX_VALUE

  // helper list
  postIDs: any[] = []

  title: string = ''
  isNSFW = false
  empty = false

  inputString: any = null
  isHashtag = false
  isLocation = false
  postCount = 0

  /**
   * 0 = most likes
   * 1 = trending
   * 2 = most recent
   */
  sortMode = 0

  /**
   * 0 = all
   * 1 = video
   * 2 = image
   * 3 = text
   */
  contentType = 0

  /**
   * 0 = all
   * 1 = this year
   * 2 = this month
   * 3 = today
   */
  timeframe = 0

  constructor(
    public imgHlp: ImageLoadingService,
    private route: ActivatedRoute,
    private toast: HotToastService,
    public numberFormatService: NumberFormatService,
    public htmlFormattingService: HTMLFormattingService,
    public keyHelperService: KeyHelperService,
    public dialog: MatDialog,
    public encodingService: EncodingService,
    public authService: AuthService,
    public fullscreenHelper: FullscreenService,
    private router: Router,
    public routingHelper: RoutinghelperService,
    public feedDataService: FeeddataService,
    public datasharingService: DatasharingService,
    public strHlp: StrHlp,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.userID = AuthService.getUID()

    this.feedDataService.clearShownPostList()

    this.route.paramMap.pipe(takeUntil(this.destroyedSubject)).subscribe({
      next: (paramMap) => {
        this.reset()

        this.inputString = paramMap.get('i')
        this.type = paramMap.get('type')

        this.loadData()
      },
      error: (e) => {
        this.toast.error('Error occurred')
        console.log(e)
      }
    })
  }

  loadData() {
    if (this.type === 'tag') {
      this.isHashtag = true
    } else if (this.type === 'location') {
      this.isLocation = true
      this.sortMode = 2
    } else {
      // none specified
      // try to determine in another way:
      const url = this.router.url

      if (url.startsWith('/tag') || url.startsWith('/hashtag')) {
        this.isHashtag = true
      } else if (url.startsWith('/location')) {
        this.isLocation = true
        this.sortMode = 2
      } else {
        // check if its "#..."
        const path = this.route.snapshot.url[0].path

        if (path.startsWith('#')) {
          this.isHashtag = true
          this.inputString = path.substring(1)
        } else {
          this.toast.error('Invalid input')
          history.back()
        }
      }
    }

    // check if valid hashtag
    if (!this.inputString || this.inputString.length < 2) {
      this.toast.error('Invalid input')
      history.back()
    }

    if (this.isHashtag) {
      this.title = '#' + this.inputString
    } else if (this.isLocation) {
      this.title = '\uD83D\uDCCD' + ' ' + this.inputString
    }

    this.inputString = this.inputString
      .trim()
      .toLowerCase()
      .replaceAll('.', '')
      .replaceAll('#', '')
      .replaceAll('$', '')
      .replaceAll('[', '')
      .replaceAll(']', '')

    this.loadPosts()
    this.loadPostCount()
  }

  reset() {
    this.feedDataService.clearShownPostList()

    this.postLoadingInProgress = false
    this.latestScore = Number.MAX_VALUE
    this.postIDs = []
    this.title = ''
    this.isNSFW = false
    this.empty = false
    this.inputString = null
    this.isHashtag = false
    this.isLocation = false
    this.postCount = 0
    this.sortMode = 0
    this.contentType = 0
    this.timeframe = 0
  }

  ngAfterViewInit() {
    this.setUpOnScrollLoader()
  }

  loadPostCount(): void {
    let loadPath: string = ''
    if (this.isHashtag) {
      loadPath = `${StrHlp.CLOUD_PATH}/Hashtags/${this.inputString}/postCount`
    } else if (this.isLocation) {
      loadPath = `${StrHlp.CLOUD_PATH}/Locations/${this.inputString}/postCount`
    }

    get(child(this.rtdb, loadPath))
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.postCount = snapshot.val()

          this.doSEO()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  doSEO() {
    if (this.isHashtag) {
      this.seoHelper.setHashtag(this.inputString, this.postCount)
    } else if (this.isLocation) {
      this.seoHelper.setLocation(this.inputString, this.postCount)
    }
  }

  async loadPosts(): Promise<void> {
    if (this.postLoadingInProgress) {
      return
    }
    this.postLoadingInProgress = true
    console.log(`loadPosts()... NSFW=${this.isNSFW}`)

    const countLoadItems = 7

    let queryPathEnd = 'ForYou'
    if (this.isNSFW) {
      queryPathEnd = 'NSFW'
    }

    // get query
    let scoreNameString = 'semiChronoScore'

    if (this.sortMode == 0) {
      scoreNameString = 'likeCount_Real'
    } else if (this.sortMode == 1) {
      scoreNameString = 'viewLikeRatioScore'
    }

    let q = query(
      collection(
        this.db,
        `${StrHlp.CLOUD_PATH}/Discover/Trending/Posts/${queryPathEnd}`
      ),
      orderBy(scoreNameString, 'desc'),
      where(scoreNameString, '<', this.latestScore),
      limit(countLoadItems),
      where('isVideo', '==', false)
    )

    if (this.isHashtag) {
      q = query(q, where('hashtags', 'array-contains', `#${this.inputString}`))
    } else if (this.isLocation) {
      q = query(q, where('location', '==', `${this.inputString}`))
    }

    if (this.contentType == 0) {
      //q = query(q,
      //    or(where('contentType', '==', 1),
      //    where('contentType', '==', 2),
      //  where('contentType', '==', 3))
      //);
    } else if (this.contentType == 1) {
      q = query(q, where('contentType', '==', 1))
    } else if (this.contentType == 2) {
      q = query(q, where('contentType', '==', 2))
    } else if (this.contentType == 3) {
      q = query(q, where('contentType', '==', 3))
    }

    // Unfortunately, we cannot reduce the amount of
    // indexes by just using timestamp here since
    // then we would have orderBy and where-"<" on different fields
    // so invalid query
    // so we will need many indexes unfortunately
    if (this.timeframe !== 0) {
      const dateAsString = new Date().toISOString() // '2022-05-27T14:51:06.157Z'

      if (this.timeframe == 1) {
        const zyklusString_Jahr = dateAsString.substring(0, 4) // 2022
        q = query(q, where('zyklusString_Jahr', '==', zyklusString_Jahr))
      } else if (this.timeframe == 2) {
        const zyklusString_Monat = (dateAsString as any)
          .replaceAll('-', '')
          .substring(0, 6) // 202205
        q = query(q, where('zyklus', '==', zyklusString_Monat))
      } else if (this.timeframe == 3) {
        const zyklusString_Tag = (dateAsString as any)
          .replaceAll('-', '')
          .substring(0, 8) // 20220527
        q = query(q, where('zyklusString_Tag', '==', zyklusString_Tag))
      }
    }

    // get docs
    const querySnapshot = await getDocs(q)

    this.empty =
      querySnapshot.empty && this.feedDataService.postList.length == 0

    querySnapshot.forEach((doc) => {
      this.postLoadingInProgress = false

      const postID = doc.id
      this.postIDs.push(postID)

      // update last score
      this.latestScore = doc.data()[scoreNameString]
      //console.log("latestScore: "  + this.latestScore);

      // load the corresponding post
      get(child(this.rtdb, `${StrHlp.CLOUD_PATH}/Photo/${postID}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const post = snapshot.val()
            post.postID = postID

            this.feedDataService.appendNewPost(post, -1)

            // load profile image and username
            this.feedDataService.easyLoadPostInfo(
              this.feedDataService.postList.length - 1
            )
          }
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }

  changeSortTo(sortBy: number) {
    const refreshAfter = this.sortMode !== sortBy
    this.sortMode = sortBy

    if (refreshAfter) {
      this.resetPosts()
      this.loadPosts()
    }
  }

  resetPosts() {
    this.postLoadingInProgress = false

    this.latestScore = Number.MAX_VALUE
    this.postIDs = []
    this.feedDataService.clearShownPostList()

    this.empty = false
  }

  changeContentType(val: number) {
    const refreshAfter = this.contentType !== val
    this.contentType = val

    if (refreshAfter) {
      this.resetPosts()
      this.loadPosts()
    }
  }

  changeTimeFrame(val: number) {
    const refreshAfter = this.timeframe !== val
    this.timeframe = val

    if (refreshAfter) {
      this.resetPosts()
      this.loadPosts()
    }
  }

  changeNSFW(val: boolean) {
    const refreshAfter = this.isNSFW !== val
    this.isNSFW = val

    if (refreshAfter) {
      this.resetPosts()
      this.loadPosts()
    }
  }

  setUpOnScrollLoader() {
    this.virtualScrollViewport.elementRef.nativeElement.addEventListener(
      'scroll',
      () => {
        const scrollTop =
          this.virtualScrollViewport.elementRef.nativeElement.scrollTop
        const scrollHeight =
          this.virtualScrollViewport.elementRef.nativeElement.scrollHeight
        const clientHeight =
          this.virtualScrollViewport.elementRef.nativeElement.clientHeight

        this.scrollIsAtTop = scrollTop == 0

        const diffToBottom = scrollHeight - scrollTop - clientHeight
        //console.log("scrollDiff: "+diffToBottom);

        if (diffToBottom <= 400) {
          // load new items
          if (
            TimeLimitsService.isAllowed_Session('on-scroll-load-new-items', 700)
          ) {
            this.loadPosts()
          }
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.destroyedSubject.next()
    this.destroyedSubject.complete()
  }
}
