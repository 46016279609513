import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  ChangeDetectorRef
} from '@angular/core'
import { Subject, firstValueFrom, takeUntil } from 'rxjs'
import { Location } from '@angular/common'
import { ClipsStateService } from 'src/app/shared/services/clips/clips-state.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { FeeddataService } from 'src/app/shared/services/data/feeddata.service'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'

@Component({
  selector: 'app-clipplayer',
  templateUrl: './clipplayer.component.html',
  styleUrls: ['./clipplayer.component.css']
})
export class ClipplayerComponent {
  @ViewChild('videoPlayer') videoPlayer!: ElementRef
  @ViewChild('scrubber') scrubber!: ElementRef
  @ViewChild('customControls') customControls!: ElementRef

  // URL and fallback urls if that resolution does not exist
  videoUrl_1: string = ''
  videoUrl_2: string = ''
  videoUrl_3: string = ''
  videoUrl_4: string = ''
  videoUrl_5: string = ''
  videoUrl_6: string = ''

  posterURL = ''

  @Input() videoID: string = ''
  @Input() postID: string = ''

  // TODO (remove to service state)
  @Input() doubleTapCallback?: (event: MouseEvent) => void
  doubleTap: (event: MouseEvent) => void

  singleTapCallback: (event: MouseEvent) => void

  @Input() indexInList!: number

  // caption, interaction etc. not directly video related, but post related controls
  @Input() shouldShowControlsType1: ((b: boolean) => void) | null = null

  @Input() startingTimeSecs = 0

  isPaused = false // we have autoplay
  tapCount = 0
  scrubbing = false
  currentTime = ''
  duration = ''

  muted = this.clipsStateService.isMuted

  private observer: IntersectionObserver | null = null

  closeSubject = new Subject<void>()

  isShownClip = false

  constructor(
    private clipsStateService: ClipsStateService,
    private cdRef: ChangeDetectorRef,
    private location: Location,
    private seoHelper: SeoHelperService,
    public feedDataService: FeeddataService,
    private cacheService: CacheService
  ) {
    this.singleTapCallback = (event) => this.playPause()

    this.doubleTap = (event) => {
      if (this.doubleTapCallback) {
        this.doubleTapCallback(event)
      }
    }
  }

  ngOnInit() {
    if (this.startingTimeSecs == 0) {
      this.videoUrl_1 = `https://vz-97291f5c-63e.b-cdn.net/${this.videoID}/play_720p.mp4`
      this.videoUrl_2 = `https://vz-97291f5c-63e.b-cdn.net/${this.videoID}/play_480p.mp4`
      this.videoUrl_3 = `https://vz-97291f5c-63e.b-cdn.net/${this.videoID}/play_842p.mp4`
      this.videoUrl_4 = `https://vz-97291f5c-63e.b-cdn.net/${this.videoID}/play_640p.mp4`
      this.videoUrl_5 = `https://vz-97291f5c-63e.b-cdn.net/${this.videoID}/play_360p.mp4`
      this.videoUrl_6 = `https://vz-97291f5c-63e.b-cdn.net/${this.videoID}/play_352p.mp4`
    } else {
      this.videoUrl_1 = `https://vz-97291f5c-63e.b-cdn.net/${this.videoID}/play_720p.mp4#t=${this.startingTimeSecs}`
      this.videoUrl_2 = `https://vz-97291f5c-63e.b-cdn.net/${this.videoID}/play_480p.mp4#t=${this.startingTimeSecs}`
      this.videoUrl_3 = `https://vz-97291f5c-63e.b-cdn.net/${this.videoID}/play_842p.mp4#t=${this.startingTimeSecs}`
      this.videoUrl_4 = `https://vz-97291f5c-63e.b-cdn.net/${this.videoID}/play_640p.mp4#t=${this.startingTimeSecs}`
      this.videoUrl_5 = `https://vz-97291f5c-63e.b-cdn.net/${this.videoID}/play_360p.mp4#t=${this.startingTimeSecs}`
      this.videoUrl_6 = `https://vz-97291f5c-63e.b-cdn.net/${this.videoID}/play_352p.mp4#t=${this.startingTimeSecs}`
    }

    this.posterURL = `https://vz-97291f5c-63e.b-cdn.net/${this.videoID}/thumbnail.jpg`

    this.clipsStateService.pausePlay$
      ?.pipe(takeUntil(this.closeSubject))
      .subscribe(() => {
        if (this.isShownClip) {
          this.playPause()
        }
      })

    this.clipsStateService.touchEndEventCurrentClipIndex_Obs$
      ?.pipe(takeUntil(this.closeSubject))
      .subscribe(([index, event]) => {
        if (this.indexInList === index) {
          //console.log("dr-CL. index:",index);
          console.log('dr-ev:', index, event instanceof TouchEvent)
          this.getFocus()
          this.play('touchEndEventCurrentClipIndex_Obs')
        }
      })
  }

  ngAfterViewInit() {
    this.videoPlayer.nativeElement.addEventListener('timeupdate', () => {
      // only update if not scrubbing
      if (!this.scrubbing) {
        const progress =
          this.videoPlayer.nativeElement.currentTime /
          this.videoPlayer.nativeElement.duration

        this.currentTime = this.formatTime(
          this.videoPlayer.nativeElement.currentTime
        )
        this.duration = this.formatTime(this.videoPlayer.nativeElement.duration)

        this.scrubber.nativeElement.value = progress * 100
      }
    })

    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.isShownClip = true

            // Do NOT use play() here.
            // we use our zip architecture with rxjs to only use
            // play() in the context of a given TouchEvent
            // (Because of iOS autoplay restrictions)

            this.clipsStateService.emitShownClipIndex(this.indexInList)
          } else {
            console.log(
              '>>> Index:',
              this.indexInList,
              'is pausing because no longer shown clip.'
            )
            this.isShownClip = false
            this.pause()
          }
        })
      },
      { threshold: 0.95 }
    )

    this.observer.observe(this.videoPlayer.nativeElement)
  }

  getFocus() {
    this.customControls.nativeElement.focus()
  }

  unmuteVideo(event: any) {
    this.videoPlayer.nativeElement.muted = false
    this.muted = false
    this.clipsStateService.isMuted = false

    // Prevent the event from bubbling up the DOM tree
    event.stopPropagation()
  }

  ngOnDestroy() {
    this.observer?.disconnect()

    this.closeSubject.next()
    this.closeSubject.complete()
  }

  updateTimestampOnScrubbing() {
    const percentage = this.scrubber.nativeElement.value
    const stamp = this.videoPlayer.nativeElement.duration * (percentage / 100)
    this.currentTime = this.formatTime(stamp)
  }

  onScrubStart() {
    this.scrubbing = true

    // hide post controls
    if (this.shouldShowControlsType1) {
      this.shouldShowControlsType1(false)
    }
  }

  onScrubEnd() {
    this.updateVideoWithScrubberValue()

    this.scrubbing = false

    //this.play("onScrubEnd");

    // show post controls
    if (this.shouldShowControlsType1) {
      this.shouldShowControlsType1(true)
    }
  }

  onScrub() {
    // we will update the video itself on scrub-end only
    this.updateTimestampOnScrubbing()
  }

  updateVideoWithScrubberValue() {
    this.videoPlayer.nativeElement.currentTime =
      (this.scrubber.nativeElement.value / 100) *
      this.videoPlayer.nativeElement.duration

    this.currentTime = this.formatTime(
      this.videoPlayer.nativeElement.currentTime
    )
  }

  play(callerDesc: string) {
    //console.log(">>> Index:",this.indexInList,"was called play()... from:",callerDesc);

    this.videoPlayer.nativeElement.play()
    this.isPaused = false
    this.cdRef.markForCheck()

    this.updateCurrentURL()
    this.updateMetaSEO()
  }

  updateCurrentURL() {
    this.location.replaceState(`clip/${this.postID}`)
  }

  async updateMetaSEO() {
    const post = this.feedDataService.getPostByID(this.postID)
    const caption = post ? post.caption : `${StrHlp.APP_NAME} post`
    const userID = post ? post.userID : ''

    post.username = await firstValueFrom(this.cacheService.getUsername(userID))
    post.fullname = await firstValueFrom(this.cacheService.getFullname(userID))

    if (userID) {
      this.seoHelper.setPost(
        this.postID,
        '',
        this.videoID,
        caption,
        post.username,
        post.fullname
      )
    }
  }

  pause() {
    this.videoPlayer.nativeElement.pause()
    this.isPaused = true
    this.cdRef.markForCheck()
  }

  playPause() {
    if (this.videoPlayer.nativeElement.paused) {
      this.play('playPause')
    } else {
      this.pause()
    }
  }

  playPause_Alternative(event?: MouseEvent) {
    this.clipsStateService.emitPausePlay()
  }

  formatTime(timeInSeconds: number) {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = Math.floor(timeInSeconds - minutes * 60)
    return minutes + ':' + (seconds < 10 ? '0' + seconds : seconds)
  }
}
