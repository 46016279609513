import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-image-overlay',
  templateUrl: './image-overlay.component.html',
  styleUrls: ['./image-overlay.component.css']
})
export class ImageOverlayComponent implements AfterViewInit {
  /**
   * string url to an asset image. Must be in assets.
   */
  @Input()
  imageSource!: string

  @Input()
  x!: number

  @Input()
  y!: number

  @Input()
  small: boolean = false

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private cd: ChangeDetectorRef
  ) {
    this.elementRef.nativeElement.style.left = `${this.x}px`
    this.elementRef.nativeElement.style.top = `${this.y}px`

    const initialRotation = this.getRandomRotation() // random initial rotation
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'transform',
      `rotate(${initialRotation}deg)`
    )
  }

  // max angle: +- 30 degree
  getRandomRotation() {
    // rnd between -1 and 1
    const rnd = 2 * Math.random() - 1
    return rnd * 30
  }

  ngAfterViewInit() {
    this.cd.detectChanges()
  }
}
