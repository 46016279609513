import { Injectable } from '@angular/core'
import { AuthService } from '../auth/auth.service'
import {
  DataSnapshot,
  get,
  getDatabase,
  limitToFirst,
  onChildAdded,
  onChildChanged,
  onValue,
  orderByValue,
  query,
  ref,
  remove,
  set
} from 'firebase/database'
import { StrHlp } from '../StringGetter/getstring.service'
import { ChatPageComponent } from 'src/app/components/chat-page/chat-page.component'
import { CacheService } from '../caching/cache-service.service'
import { PinnedchatsService } from '../pinnedchats.service'
import { HotToastService } from '@ngneat/hot-toast'
import { map, of } from 'rxjs'
import { GLOBAL_CHAT_ID } from '../../constants'

@Injectable({
  providedIn: 'root'
})

// New system to fix the reloading issue after you open and close a chat:
// We have this service that loads this data all the time and is always available,
// so no reloads ever needed
export class ChatDataService {
  callback_ChatID_Loaded: ((obj: any) => void) | null = null
  callback_IsFor_ChatID: string | null = null

  setChatdataLoadedCallback(forChatID: string, callback: (obj: any) => void) {
    this.callback_ChatID_Loaded = callback
    this.callback_IsFor_ChatID = forChatID
  }

  db = getDatabase()

  alreadyInit_ChatListeners: boolean = false

  hasLeftGC: boolean = false
  isBannedGC: boolean = false

  chatList: any[] = []
  listEmpty: boolean = false

  typingInfo: any = {}
  typingInfoUsername: any = {}

  caching: any = {
    name: {},
    image: {},
    verified: {}
  }

  userID: any = null

  pinnedChats: string[] = this.pinnedChatsService.getChats()

  chatID_GC = GLOBAL_CHAT_ID
  chatDataRaw_GC = ChatPageComponent.GLOBAL_CHATLIST_OBJ

  currentlyOpenedChat_ID: string | null = null

  constructor(
    authService: AuthService,
    private cacheService: CacheService,
    private pinnedChatsService: PinnedchatsService,
    private toast: HotToastService
  ) {
    if (authService.isLoggedIn()) {
      // load chat data if is logged in
      this.userID = AuthService.getUID()
      this.checkHasLeftGC()
    }
  }

  locallyRemoveByID(chatID: string) {
    for (let i = 0; i < this.chatList.length; i++) {
      if (this.chatList[i].chatID === chatID) {
        this.chatList.splice(i, 1)

        this.reflectListChange()

        break
      }
    }
  }

  /**
   * New method of resetting the new-msg-count to zero:
   * Advantages of this method: Fixing important bug, and more efficient (hence also cost saving)
   *
   * Works this way:
   * We specifiy the currently opened chatID. When a listener for a new msg is fired in here,
   * it checks whether the chatID matches the one specified. If so, it updates both, the cloud
   * and the local data, to have no new messages ("setting it to zero").
   *
   * To remove this behavior, use remove_NewMsgCountToZero_Callback.
   */
  set_NewMsgCountToZero_Callback(chatID: string) {
    this.currentlyOpenedChat_ID = chatID

    // an inital reset is needed since the
    // resets are only triggered upon a new message
    for (let i = 0; i < this.chatList.length; i++) {
      const chatData = this.chatList[i]
      if (chatData.chatID === chatID) {
        this.setToZero_NewMsgCount(chatData)
        break
      }
    }
  }

  remove_NewMsgCountToZero_Callback() {
    this.currentlyOpenedChat_ID = null
  }

  // chat stuff
  checkHasLeftGC(): void {
    // first check if user left GC
    get(
      ref(
        this.db,
        `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/HasLeftGC`
      )
    )
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.hasLeftGC = snapshot.val()
        }

        if (this.hasLeftGC) {
          this.loadChats()
        } else {
          this.checkIsBannedGC()
        }
      })
      .catch((error) => {
        console.log(error)

        // treat as did not leave GC
        this.checkIsBannedGC()
      })
  }

  checkIsBannedGC(): void {
    get(
      ref(
        this.db,
        `${StrHlp.CLOUD_PATH}/UserLimits/Blocked/${this.userID}/GlobalChat`
      )
    )
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.isBannedGC = true
        }
        this.loadChats()
      })
      .catch((error) => {
        console.log(error)

        // treat as user is banned from GC
        this.isBannedGC = true

        this.loadChats()
      })
  }

  addGC(): void {
    this.chatDataRaw_GC = Object.assign(ChatPageComponent.GLOBAL_CHATLIST_OBJ, {
      isPinned: this.pinnedChats.includes(this.chatID_GC)
    })

    // add always-listener
    const gcMessageCountRef = ref(
      this.db,
      `${StrHlp.CLOUD_PATH}/GlobalChat/MessageCount`
    )

    onValue(gcMessageCountRef, (snapshot) => {
      this.onNewMessageGC(snapshot)
    })

    // add typing listener GC
    const refTyping = ref(this.db, `${StrHlp.CLOUD_PATH}/GlobalChat/isTyping`)

    onValue(refTyping, (snapshot) => {
      if (snapshot.exists()) {
        this.typingInfo[this.chatID_GC] = snapshot.val().length > 0
        this.typingInfoUsername[this.chatID_GC] = snapshot.val()
      } else {
        this.typingInfo[this.chatID_GC] = ''
        this.typingInfoUsername[this.chatID_GC] = ''
      }
    })
  }

  async onNewMessageGC(snapshot: DataSnapshot) {
    if (snapshot.exists()) {
      const totalMessagesCount = snapshot.val()

      // get the new-msg-count
      const snapshotSeenCount = await get(
        ref(
          this.db,
          `${StrHlp.CLOUD_PATH}/GlobalChat/${this.userID}/LastMessageCount`
        )
      )
      let seenCount = 0
      if (snapshotSeenCount.exists()) {
        seenCount = snapshotSeenCount.val()
      }

      // new = total - seen
      const countNewMessages = totalMessagesCount - seenCount
      this.chatDataRaw_GC.newMessagesCount = countNewMessages

      // get the chat data
      get(ref(this.db, `${StrHlp.CLOUD_PATH}/ChatUebersicht/${this.chatID_GC}`))
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            const chatData = snapshot.val()

            let lastMsg = chatData.lastMessage
            if (lastMsg === '') {
              lastMsg = '🗑️ Deleted'
            }

            const wasLastMessageByMe = chatData.lastMessageUID === this.userID
            chatData.wasLastMessageByMe = wasLastMessageByMe

            chatData.totalMessagesCount = totalMessagesCount

            if (!wasLastMessageByMe) {
              // load the username of the last-message-user
              chatData.lastMessage$ = this.cacheService
                .getUsername(chatData.lastMessageUID)
                .pipe(map((name) => name + ': ' + lastMsg))
            } else {
              chatData.lastMessage$ = of(lastMsg)
            }

            Object.assign(this.chatDataRaw_GC, chatData)

            // iterate over list, remove the GC entry, and add this GC entry to the top
            for (let i = 0; i < this.chatList.length; i++) {
              if (GLOBAL_CHAT_ID === this.chatList[i].chatID) {
                // remove
                this.chatList.splice(i, 1)
                this.reflectListChange()
                break
              }
            }

            // add to the top, except pinned chats
            // so iterate through chats until we find a not pinned chat. Put it before that chat
            let inserted = false
            for (let i = 0; i < this.chatList.length; i++) {
              const chat = this.chatList[i]
              if (chat.isPinned) {
                continue
              } else {
                this.chatList.splice(i, 0, this.chatDataRaw_GC)
                inserted = true
                break
              }
            }

            // if not inserted, insert to the very end
            if (!inserted) {
              this.chatList.push(this.chatDataRaw_GC)
            }

            this.reflectListChange()

            this.checkForCallback(this.chatDataRaw_GC)

            // check if need to set-to-zero new msg count
            if (this.chatID_GC === this.currentlyOpenedChat_ID) {
              this.setToZero_NewMsgCount(this.chatDataRaw_GC)
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  setToZero_NewMsgCount(chatData: any) {
    const isGroup = chatData.isGroup
    const isMeUser1 = chatData.isMeUser1
    const chatID = chatData.chatID
    const totalMessagesCount = chatData.totalMessagesCount

    const isGC = this.chatID_GC === chatID

    /*
    console.log("DraugasD", "setToZero_NewMsgCount_Group...");
    console.log("DraugasD", "chatID ", chatID);
    console.log("DraugasD", "isGroup ", isGroup);
    console.log("DraugasD", "isMeUser1 ", isMeUser1);
    console.log("DraugasD", "isGC ", isGC);
    console.log("DraugasD", "totalMessagesCount ", totalMessagesCount);
    */

    if (chatID == null) {
      return
    }

    // cloud
    if (isGroup) {
      if (isGC) {
        set(
          ref(
            this.db,
            `${StrHlp.CLOUD_PATH}/GlobalChat/${this.userID}/LastMessageCount`
          ),
          totalMessagesCount
        )
      } else {
        set(
          ref(
            this.db,
            `${StrHlp.CLOUD_PATH}/ChatGruppen/${chatID}/NutzerDaten/${this.userID}/MessageCount`
          ),
          totalMessagesCount
        )
      }
    } else {
      // private

      // new messages count
      const path = isMeUser1 ? 'countNewMessagesUser1' : 'countNewMessagesUser2'
      const messageCountRef = ref(
        this.db,
        `${StrHlp.CLOUD_PATH}/ChatUebersicht/${chatID}/${path}`
      )
      set(messageCountRef, -1)

      // new chats count
      const newChatsCountRef = ref(
        this.db,
        `${StrHlp.CLOUD_PATH}/Chats_AnzahlNeue/${this.userID}/${chatID}`
      )
      remove(newChatsCountRef)
    }

    // locally
    let index = -1
    for (let i = 0; i < this.chatList.length; i++) {
      const chat = this.chatList[i]
      if (chat.chatID === chatID) {
        index = i
        break // we have all we need
      }
    }
    if (index > -1) {
      const chatData = this.chatList[index]
      const isGroup = chatData.isGroup

      if (isGroup) {
        chatData.newMessagesCount = 0
      } else {
        chatData.newMessagesCount = -1
      }

      this.chatList[index] = chatData

      this.reflectListChange()
    }
  }

  loadChats(): void {
    console.log('loadChats()...')

    const loadGC = !this.hasLeftGC && !this.isBannedGC
    if (loadGC) {
      this.addGC()
    }

    // Add child listener for all chats
    // limit to max xxx chats
    const maxChats = 150
    const chatsRef = query(
      ref(this.db, `${StrHlp.CLOUD_PATH}/ChatUebersichtListe/${this.userID}`),
      orderByValue(),
      limitToFirst(maxChats)
    )

    onChildAdded(chatsRef, (data) => {
      //console.log("Chats: onChildAdded...");

      // load the ChatUebersicht(Gruppen)-object and add it to the list
      const chatID = data.key!
      const stamp = -data.val()

      const basicData = {
        chatID: chatID,
        lastMessageTimestamp: stamp
      }

      get(ref(this.db, `${StrHlp.CLOUD_PATH}/ChatUebersicht/${chatID}`))
        .then(async (snapshot) => {
          const chatData = snapshot.val()

          Object.assign(chatData, basicData)

          // load further chat data, such as username/groupnaame and count of new messages
          const furtherData = await this.loadFurtherChatData(chatData, chatID!)

          // merge chatData and furtherData (for UI displaying - might not be good style though)
          Object.assign(chatData, furtherData)

          // check if chat is pinned
          chatData.isPinned = this.pinnedChats.includes(chatData.chatID)

          if (chatData.isPinned) {
            // insert at the beginning
            this.chatList.unshift(chatData)
          } else {
            // simply iterate over the list and add the chat to the chronologically correct position
            // however, pinned chats are disregarded since no chat can be inserted above them
            let inserted = false
            for (let i = 0; i < this.chatList.length; i++) {
              const chat = this.chatList[i]

              if (chat.isPinned) {
                continue
              } else {
                if (stamp > chat.lastMessageTimestamp) {
                  this.chatList.splice(i, 0, chatData)
                  inserted = true
                  break
                }
              }
            }

            if (!inserted) {
              // was not inserted yet, means it's older than all other chats yet, so insert at the end
              this.chatList.push(chatData)
            }
          }

          this.checkForCallback(chatData)

          // add a typing listener for the chat
          if (chatData.isPrivate) {
            const refTyping = ref(
              this.db,
              `${StrHlp.CLOUD_PATH}/Chats_TypingInfo/${chatID}/${chatData.otherUserID}/isTyping`
            )
            onValue(refTyping, (snapshot) => {
              if (snapshot.exists()) {
                this.typingInfo[chatID] = snapshot.val()
              } else {
                this.typingInfo[chatID] = false
              }
            })
          } else {
            const refTyping = ref(
              this.db,
              `${StrHlp.CLOUD_PATH}/ChatGruppen/${chatID}/Dynamisch/isTyping`
            )

            onValue(refTyping, (snapshot) => {
              if (snapshot.exists()) {
                this.typingInfo[chatID] = snapshot.val().length > 0
                this.typingInfoUsername[chatID] = snapshot.val()
              } else {
                this.typingInfo[chatID] = ''
                this.typingInfoUsername[chatID] = ''
              }
            })
          }

          this.reflectListChange()
        })
        .catch((error) => {
          console.log(error)
        })
    })

    // note: in here, we dont check "isPinned" since it was already determined in onChildAdded
    onChildChanged(chatsRef, async (data) => {
      //console.log("Chats: onChildChanged...");

      // changed means: the lastMessageTimestamp has changed
      // so the only thing we will do are:
      // - reload the count of new messages
      // - if needed adjust the chats order
      const chatID = data.key
      const stamp = -data.val()

      let chatData: any = null

      // if not pinned:
      // find the position it needs to be moved to
      let posNeeded = -1
      let currPos = -1

      // note: if a chat is pinned, disregard it here, unless its the chat that fired this event
      for (let i = 0; i < this.chatList.length; i++) {
        const chat = this.chatList[i]

        if (chat.isPinned) {
          if (chat.chatID == chatID) {
            // the chat that fired this event is pinned. Just leave the position the same
            posNeeded = i
            currPos = i
            chatData = chat
            break
          } else {
            continue
          }
        } else {
          if (posNeeded == -1 && stamp > chat.lastMessageTimestamp) {
            posNeeded = i
          }

          if (chat.chatID === chatID) {
            chatData = chat
            currPos = i
          }

          if (posNeeded != -1 && chatData !== null) {
            break // we have all we need
          }
        }
      }

      // we have to get the info from the cloud again
      get(ref(this.db, `${StrHlp.CLOUD_PATH}/ChatUebersicht/${chatID}`))
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            const chatDataNew = snapshot.val()

            // set new timestamp and message
            chatData.lastMessageTimestamp = stamp

            // assign new data
            Object.assign(chatData, chatDataNew)

            // now fix list order if needed
            if (currPos != posNeeded && currPos != -1 && posNeeded != -1) {
              // first set the new chatData to the old index
              this.chatList[currPos] = chatData

              // now change the position of that element
              this.arraymove(this.chatList, currPos, posNeeded)

              this.reflectListChange()
            }

            // now just reload the new-messages-count
            // since we have caching of usernames, group-image, verified etc.
            // we just call the same function as in onChildAdded()
            // load further chat data, such as username/groupname and count of new messages
            const furtherData = await this.loadFurtherChatData(
              chatData,
              chatID!
            )

            // merge chatData and furtherData (for UI displaying - might not be good style though)
            Object.assign(chatData, furtherData)

            // apply the new data
            this.chatList[posNeeded] = chatData

            this.reflectListChange()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    })
  }

  /**
   * Loads the following data:
   * - name (groupname/username-of-private-chat)
   * - count of new messages
   * - user-/group- image
   * - verified in case it's private chat
   *
   * @param chatData
   * @param chatID
   */
  async loadFurtherChatData(chatData: any, chatID: string): Promise<any> {
    let newMessagesCount = 0
    let isMeUser1 = false
    let otherUserID = ''
    let isPrivate = false
    let isGroup = false
    const groupLastMessageUsername = 'username'
    let wasLastMessageByMe = false

    // first determine if chat is group chat or private chat
    if (chatData.groupID !== undefined) {
      // it's a group chat
      isGroup = true

      wasLastMessageByMe = chatData.lastMessageUID === this.userID

      // new messages count
      // get the total messages count
      const snapshotCount = await get(
        ref(
          this.db,
          `${StrHlp.CLOUD_PATH}/ChatGruppen/${chatID}/Dynamisch/MessageCount`
        )
      )
      let totalMessagesCount = 0
      if (snapshotCount.exists()) {
        totalMessagesCount = snapshotCount.val()
      }
      chatData.totalMessagesCount = totalMessagesCount

      // get the seen-count of this user
      const snapshotSeenCount = await get(
        ref(
          this.db,
          `${StrHlp.CLOUD_PATH}/ChatGruppen/${chatID}/NutzerDaten/${this.userID}/MessageCount`
        )
      )
      let seenMessagesCount = 0
      if (snapshotSeenCount.exists()) {
        seenMessagesCount = snapshotSeenCount.val()
      }
      // new = total - seen
      newMessagesCount = totalMessagesCount - seenMessagesCount

      let lastMsg = chatData.lastMessage
      if (lastMsg === '') {
        lastMsg = '🗑️ Deleted'
      }

      // load groupLastMessageUsername
      // but only, if it is not yourself, because in that case we display the checks and not the username
      if (!wasLastMessageByMe) {
        // FIX HERE 111
        chatData.lastMessage$ = this.cacheService
          .getUsername(chatData.lastMessageUID)
          .pipe(map((name) => name + ': ' + lastMsg))
      } else {
        chatData.lastMessage = lastMsg
      }

      // check if need to set-to-zero new msg count
      if (chatID === this.currentlyOpenedChat_ID) {
        this.setToZero_NewMsgCount(chatData)
      }
    } else {
      // private chat
      isPrivate = true

      wasLastMessageByMe =
        (chatData.isMeUser1 && chatData.lastMessageFromUser1) ||
        (!chatData.isMeUser1 && !chatData.lastMessageFromUser1)

      if (this.userID === chatData.userID1) {
        isMeUser1 = true
        otherUserID = chatData.userID2
      } else {
        isMeUser1 = false
        otherUserID = chatData.userID1
      }

      // load message count is not needed for private chats because it's already in the ChatData-object
      // but we need to put the actual number to "newMessagesCount"
      if (isMeUser1) {
        newMessagesCount = chatData.countNewMessagesUser1
      } else {
        newMessagesCount = chatData.countNewMessagesUser2
      }

      let lastMsg = chatData.lastMessage
      if (lastMsg === '') {
        lastMsg = '🗑️ Deleted'
      }
      chatData.lastMessage$ = of(lastMsg)

      // check if need to set-to-zero new msg count
      if (chatID === this.currentlyOpenedChat_ID) {
        this.setToZero_NewMsgCount(chatData)
      }
    }

    return {
      newMessagesCount: newMessagesCount,
      isMeUser1: isMeUser1,
      otherUserID: otherUserID,
      isPrivate: isPrivate,
      isGroup: isGroup
    }
  }

  // help function
  arraymove(arr: any[], fromIndex: number, toIndex: number) {
    const element = arr[fromIndex]
    arr.splice(fromIndex, 1)
    arr.splice(toIndex, 0, element)
  }

  pinChat(chatID: string) {
    this.pinnedChats.push(chatID)
    this.pinnedChatsService.addChat(chatID)

    // rearrange chats: move this chat to the very top
    let indexCurr = -1
    for (let i = 0; i < this.chatList.length; i++) {
      const chat = this.chatList[i]
      if (chat.chatID === chatID) {
        indexCurr = i
        break // we have all we need
      }
    }

    if (indexCurr > -1) {
      this.arraymove(this.chatList, indexCurr, 0)

      // local UI reflect
      this.chatList[0].isPinned = true

      this.toast.success('Chat pinned')
    } else {
      this.toast.error('Error occurred')
    }

    this.reflectListChange()
  }

  unpinChat(chatID: string) {
    const index = this.pinnedChats.indexOf(chatID)
    if (index > -1) {
      this.pinnedChats.splice(index, 1)
    }
    this.pinnedChatsService.removeChat(chatID)

    // rearrange chats: restore chronological order
    let posNeeded = -1
    let currPos = -1
    let chatData = null
    let inserted = false

    // first, get the chatData
    for (let i = 0; i < this.chatList.length; i++) {
      const chat = this.chatList[i]
      if (chat.chatID === chatID) {
        chatData = chat
        currPos = i
        break
      }
    }

    if (chatData !== null) {
      const stamp = chatData.lastMessageTimestamp

      for (let i = 0; i < this.chatList.length; i++) {
        const chat = this.chatList[i]

        if (chat.isPinned) {
          continue
        } else {
          if (posNeeded == -1 && stamp > chat.lastMessageTimestamp) {
            posNeeded = i
            break
          }
        }
      }

      if (posNeeded > -1) {
        this.arraymove(this.chatList, currPos, posNeeded)
        inserted = true

        // local UI reflect
        this.chatList[posNeeded].isPinned = false

        this.toast.success('Chat unpinned')
      }
    }
    // --

    if (!inserted) {
      this.toast.error('Error occurred')
    }

    this.reflectListChange()
  }

  checkForCallback(chatData: any) {
    if (this.callback_ChatID_Loaded && this.callback_IsFor_ChatID) {
      const loadedChatID = chatData.chatID

      if (loadedChatID === this.callback_IsFor_ChatID) {
        //console.log("Draugas1: --------------------------------------------------");

        // callback
        this.callback_ChatID_Loaded(chatData)

        // reset
        this.callback_ChatID_Loaded = null
        this.callback_IsFor_ChatID = null
      }
    }
  }

  // Needed because of recycling
  reflectListChange() {
    this.chatList = [...this.chatList]
  }
}
