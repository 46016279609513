import { Component } from '@angular/core'

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: [
    './loading-dialog.component.css',
    '../../../stylesheets/dialogs/dialogstylesheet.css'
  ]
})
export class LoadingDialogComponent {}
