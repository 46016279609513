import { Injectable } from '@angular/core'
import { SystemService } from '../system/systemservice.service'
import { get, getDatabase, ref } from 'firebase/database'
import { StrHlp } from '../StringGetter/getstring.service'
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class DatasharingService {
  public mainComponent_ScrollIsAtTop: boolean = true
  public mainComponent_ScrollIsAtBottom: boolean = false
  public routerEventCount: number = 0

  public areYouMod: boolean = false

  constructor() {
    this.loadAreYouMod()
  }

  /**
   * since the first page loading also counts as routing, we need >= 2
   */
  hasRoutingHappened(): boolean {
    return this.routerEventCount >= 2
  }

  async loadAreYouMod() {
    const db = getDatabase()
    const userID = AuthService.getUID()

    if (!userID) {
      return
    }

    const snapIsGod = await get(ref(db, `Moderators/${userID}`))
    if (snapIsGod.exists()) {
      this.areYouMod = true
    } else {
      const snapIsMod = await get(ref(db, `Moderators_LeitPos/${userID}`))
      const isLeitPos = snapIsMod.exists()

      if (isLeitPos) {
        const snapshotHubrights = await get(
          ref(db, `Moderators_HubRechte/${userID}/${StrHlp.CLOUD_PATH}`)
        )
        const hasHubRights = snapshotHubrights.exists()

        if (hasHubRights) {
          this.areYouMod = true
        }
      }
    }
  }
}
