import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { HotToastService } from '@ngneat/hot-toast'
import { updatePassword } from 'firebase/auth'
import firebase from 'firebase/compat/app'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-passwordsettings',
  templateUrl: './passwordsettings.component.html',
  styleUrls: [
    '../settingspage/settings.component.css',
    '../../sign-in/sign-in.component.css'
  ]
})
export class PasswordsettingsComponent implements OnInit {
  userID: any = null
  user: any = null
  currentEmail: string = ''

  @ViewChild('currentPassword') currentPassword!: ElementRef
  @ViewChild('password') password!: ElementRef
  @ViewChild('passwordInvalidError')
  passwordInvalidError!: ElementRef
  @ViewChild('showCurrentPassword')
  showCurrentPassword!: ElementRef
  @ViewChild('showpassword') showpassword!: ElementRef
  @ViewChild('changeButton') changeButton!: ElementRef

  currentPasswordEntered: string = ''
  passwordEntered: string = ''
  passwordVisible: boolean = false
  currentPasswordVisible: boolean = false

  constructor(
    private toast: HotToastService,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('Password settings', 'Password settings')
    this.userID = AuthService.getUID()
    this.user = AuthService.getUser()
    this.currentEmail = this.user.email
  }

  changeCurrentPasswordVisibility(): void {
    this.currentPasswordVisible = !this.currentPasswordVisible

    if (this.currentPasswordVisible) {
      this.showCurrentPassword.nativeElement.innerText = 'Hide password'
      this.currentPassword.nativeElement.type = 'text'
    } else {
      this.showCurrentPassword.nativeElement.innerText = 'Show password'
      this.currentPassword.nativeElement.type = 'password'
    }
  }

  changePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible

    if (this.passwordVisible) {
      this.showpassword.nativeElement.innerText = 'Hide password'
      this.password.nativeElement.type = 'text'
    } else {
      this.showpassword.nativeElement.innerText = 'Show password'
      this.password.nativeElement.type = 'password'
    }
  }

  isValidInput(): boolean {
    this.passwordInvalidError.nativeElement.style.display = 'none'

    if (!this.passwordEntered) {
      this.passwordInvalidError.nativeElement.innerText =
        'Enter a new password to continue'
      this.passwordInvalidError.nativeElement.style.display = 'block'
      return false
    }

    if (this.passwordEntered.length < 6) {
      this.passwordInvalidError.nativeElement.innerText =
        'New password must be at least 6 characters'
      this.passwordInvalidError.nativeElement.style.display = 'block'
      return false
    }

    if (!this.currentPasswordEntered) {
      this.passwordInvalidError.nativeElement.innerText =
        'Enter your current password to continue'
      this.passwordInvalidError.nativeElement.style.display = 'block'
      return false
    }

    if (this.currentPasswordEntered.length < 6) {
      this.passwordInvalidError.nativeElement.innerText =
        'Current password is wrong'
      this.passwordInvalidError.nativeElement.style.display = 'block'
      return false
    }

    return true
  }

  changePassword(): void {
    // check if entered info is valid
    if (!this.isValidInput()) {
      return
    }

    this.changeButton.nativeElement.innerText = 'Loading...'

    // attempt to change the password
    firebase
      .auth()
      .signInWithEmailAndPassword(
        this.currentEmail,
        this.currentPasswordEntered
      )
      .then((userCredential) => {
        const currUser = userCredential.user

        if (currUser !== null) {
          updatePassword(currUser as any, this.passwordEntered)
            .then(() => {
              this.toast.success('Password updated')
              history.back()
            })
            .catch((error) => {
              this.changeButton.nativeElement.innerText = 'Change password'
              this.toast.error(
                'An error has occurred. Password was not updated'
              )
              console.log(error)
            })
        } else {
          this.changeButton.nativeElement.innerText = 'Change password'
          this.toast.error('An error has occurred. Password was not updated')
        }
      })
      .catch((error) => {
        this.changeButton.nativeElement.innerText = 'Change password'
        this.toast.error(error.message)
        this.toast.error(
          'Logging in to your account failed. Likely the password you entered is wrong'
        )
      })
  }
}
