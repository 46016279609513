<div id="wrapper">
  <header
    class="header"
    [ngClass]="{ 'bottom-border-divider': !scrollIsAtTop }"
  >
    <div class="title-wrapper" (click)="scrollTop()">
      <h1 class="page-title">Inbox</h1>

      @if (newNotificationsCount == 0) {
        <p class="up-to-date">Up to date</p>
      }
    </div>

    <div class="header-right-part-wrapper">
      @if (authService.isLoggedIn()) {
        <a
          routerLink="/saved/bookmarks"
          class="dark-icon header-button"
          matTooltip="Bookmarked posts"
          [matTooltipShowDelay]="1000"
        >
          <img
            class="header-icon"
            src="/assets/ic_bookmark.png"
            alt="Bookmarks"
          />
        </a>

        <a
          routerLink="/saved/liked"
          class="dark-icon header-button"
          matTooltip="Liked posts"
          [matTooltipShowDelay]="1000"
        >
          <img
            class="header-icon"
            src="/assets/ic_heart_like.png"
            alt="Liked"
          />
        </a>

        <a
          routerLink="/mb-settings"
          class="dark-icon header-button"
          matTooltip="Settings"
          [matTooltipShowDelay]="1000"
        >
          <img
            class="header-icon"
            src="/assets/ic_settings2.png"
            alt="Settings"
          />
        </a>
      }
    </div>
  </header>

  <div
    id="body-div"
    class="below-header-height"
    #contentWrapper
    cdkVirtualScrollingElement
  >
    <div id="quickInfoWrapper">
      @if (notificationsdataService.totalNotifCount) {
        <div class="infoBoxWrapper" (click)="showTotalNotifsDialog()">
          <p class="info-title">
            {{ notificationsdataService.totalNotifCount | numberCommaFormat }}
          </p>
          <p class="info-desc">Total notifications</p>
        </div>
      }

      @if (newNotificationsCount > 0) {
        <div class="infoBoxWrapper">
          <p class="info-title">
            {{ newNotificationsCount | numberCommaFormat }}
          </p>
          <p class="info-desc">New notifications</p>
        </div>
      }
    </div>

    @if (followRequestsCount > 0) {
      <div id="followRequestsHintWrapper" (click)="openFollowRequests()">
        {{
          followRequestsCount == countLoadItems_FollowRequests
            ? countLoadItems_FollowRequests - 1 + '+'
            : followRequestsCount
        }}
        following {{ followRequestsCount == 1 ? 'request' : 'requests' }}
      </div>
    }

    @if (notificationsdataService.itemList.length > 0) {
      <cdk-virtual-scroll-viewport itemSize="{{ itemSize }}">
        <div
          *cdkVirtualFor="
            let item of notificationsdataService.itemList;
            let i = index
          "
          class="vs-item"
        >
          <app-notificationitemtemplate
            [item]="{
              item: item,
              showNewNotificationsHint: i + 1 == newNotificationsCount,
              indexInList: i,
              hideMenuButton: isMobile,
              newNotificationsCount: newNotificationsCount
            }"
          />
        </div>
      </cdk-virtual-scroll-viewport>
    }

    @if (
      !notificationsdataService.empty &&
      notificationsdataService.itemList.length == 0
    ) {
      <app-loadingcircle />
    }

    @if (notificationsdataService.empty) {
      <app-emptyhinttemplate [text]="'No notifications yet...'" />
    }
  </div>
</div>
