<a
  class="item-wrapper"
  role="link"
  aria-label="Open group page"
  [routerLink]="href"
>
  <div class="group-item">
    <img
      [src]="image | imgScaleHelper: 150"
      alt="Group image"
      onerror="this.src='/assets/default_group_pic.jpg';this.onerror='';"
      class="groupChatImage"
      loading="lazy"
    />

    <div class="itemSecondWrapper">
      <app-usernametemplate
        mat-dialog-close
        [values]="{
          nameInp: name,
          groupID: groupId,
          disableClick: true,
          bigType1: true,
          createAsDiv: true
        }"
      />

      <div class="info-bar messagePreview">
        <img
          src="/assets/ic_group.png"
          class="dark-icon info-icon"
          alt="group chat icon"
          loading="lazy"
        />
        <span class="bold-text">
          {{ memberCount === -1 ? 'Many' : (memberCount | numberCommaFormat) }}
        </span>
      </div>

      <div class="info-bar messagePreview">
        <img
          src="/assets/navbar_chat_selected_black.png"
          class="dark-icon info-icon"
          alt="chat message icon"
          loading="lazy"
        />

        <span class="bold-text">
          {{ messageCount | numberCommaFormat }} messages
        </span>
      </div>

      <div class="info-bar messagePreview">
        <img
          src="/assets/ic_clock_filled.png"
          class="dark-icon info-icon"
          alt="group created icon clock"
          loading="lazy"
        />
        {{ timestamp === 0 ? 'Long time ago' : (timestamp | timeformat) }}
      </div>
    </div>
  </div>
</a>
