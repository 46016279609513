import { Component } from '@angular/core'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { LocalstorageService } from 'src/app/shared/services/ssr/localstorage.service'

@Component({
  selector: 'app-startingpagesettings',
  templateUrl: './startingpagesettings.component.html',
  styleUrls: [
    './startingpagesettings.component.css',
    '../lastseensettings/lastseensettings.component.css',
    '../darkmode/darkmode.component.css',
    '../settingspage/settings.component.css'
  ]
})
export class StartingpagesettingsComponent {
  startPage = '1'
  isHome = false
  isExplore = false
  isChats = false

  constructor(
    private seoHelper: SeoHelperService,
    private localstorageService: LocalstorageService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('Starting page', 'Choose a starting page.')

    // load curr setting
    const setting = this.localstorageService.getItem('startPageSetting')

    if (setting) {
      this.startPage = setting
    }
  }

  update(newVal: string) {
    this.startPage = newVal
    this.localstorageService.setItem('startPageSetting', newVal)
  }
}
