import { Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { child, get, getDatabase, ref, set } from 'firebase/database'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { LoadingDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { HotToastService } from '@ngneat/hot-toast'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-lastseensettings',
  templateUrl: './lastseensettings.component.html',
  styleUrls: [
    './lastseensettings.component.css',
    '../darkmode/darkmode.component.css',
    '../settingspage/settings.component.css'
  ]
})
export class LastseensettingsComponent {
  userID: any = null
  showLastSeen: boolean = true
  showLastSeen_Det: boolean = false
  dbPath = ''

  constructor(
    private dialog: MatDialog,
    private toast: HotToastService,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('Last seen settings', 'Last seen settings')

    this.userID = AuthService.getUID()

    // fetch showLastSeen
    const dbRef = ref(getDatabase())
    this.dbPath = `${StrHlp.CLOUD_PATH}/UserSettings/${this.userID}/showLastSeen`

    get(child(dbRef, this.dbPath)).then((snapshot) => {
      let showLastSeen = true
      if (snapshot.exists()) {
        showLastSeen = snapshot.val()
      }

      this.showLastSeen_Det = true
    })
  }

  update() {
    if (!this.showLastSeen_Det) {
      return
    }

    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    set(ref(getDatabase(), this.dbPath), this.showLastSeen)
      .then(() => {
        loadingDialogRef.close()
        //this.toast.success('Saved')
      })
      .catch((error) => {
        loadingDialogRef.close()
        this.toast.error('Error occurred')
      })
  }
}
