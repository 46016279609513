<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Account security</h1>
  </header>

  <main class="fh-main content">
    <h2 class="sub-title">Keep your account secure</h2>
    <p>
      To protect your account, choose a strong password. We have introduced a
      password strength indicator in recent versions, which shows you an
      estimated strength of your password when you create an account or change
      your password.
      <br /><br />
      To increase security, MAGA Hub does not allow users to log in with their
      username, but only with their email. This increases the security of your
      account as other users cannot see your email. To make your account as
      secure as possible, don't let other users know what email you used for
      your account.
      <br /><br />
      You can add two-factor authentication to your account. Two-factor
      authentication is an extra layer of security for your MAGA Hub account. It
      ensures that even if your password is guessed or hacked, you're still the
      only person who can access your account.
    </p>
  </main>
</div>
