import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class TimeLimitsService {
  /**
   * Uses session storage! So if the user restarts the browser, the saved timestamps will be gone!
   *
   * Updates the timestamp if and only if it returns true!
   *
   * @param key describing the action (for example 'make-comment')
   * @param minBreak mind time in ms that needs to be between two actions
   */
  static isAllowed_Session(key: string, minBreak: number): boolean {
    const lastTimestamp: string | null = sessionStorage.getItem(key)
    let stamp = 0
    if (lastTimestamp !== null) {
      stamp = +lastTimestamp
    }
    const stampNow = Date.now()
    const ret = stampNow - stamp > minBreak
    if (ret) {
      sessionStorage.setItem(key, String(stampNow))
    }
    return ret
  }

  /**
   * Uses localStorage. So it will save the timestamps even if the browser was closed
   *
   * Updates the timestamp if and only if it returns true!
   *
   * @param key describing the action (for example 'make-comment')
   * @param minBreak mind time in ms that needs to be between two actions
   */
  static isAllowed_General(key: string, minBreak: number): boolean {
    if (typeof localStorage === 'undefined') {
      // ssr guard
      return true
    }

    const lastTimestamp: string | null = localStorage.getItem(key)
    let stamp = 0
    if (lastTimestamp !== null) {
      stamp = +lastTimestamp
    }
    const stampNow = Date.now()
    const ret = stampNow - stamp > minBreak
    if (ret) {
      localStorage.setItem(key, String(stampNow))
    }
    return ret
  }
}
