export class ScreenOverlay {
  constructor(
    private _assetSource: string,
    private _x: number,
    private _y: number,
    private _duration: number,
    private _small: boolean = false
  ) {}

  get assetSource() {
    return this._assetSource
  }

  get duration() {
    return this._duration
  }

  get x() {
    return this._x
  }

  get y() {
    return this._y
  }

  get small() {
    return this._small
  }
}
