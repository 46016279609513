<button
  class="subtle-button wrapper"
  [ngClass]="{ background: background }"
  (click)="back()"
  aria-label="Go back"
  >
  <div id="goback-button" matTooltip="Go back" [matTooltipShowDelay]="1000">
    <img
      class="dark-icon"
      id="arrow-back"
      src="/assets/arrow_back.png"
      alt="go back"
      />
  </div>

  @if (showText) {
    <p class="text">Go back</p>
  }
</button>
