<div id="wrapper">
  @if (title.length > 0) {
    <p id="title">{{ title }}</p>
  }

  @if (hint.length > 0) {
    <p class="desc">{{ hint }}</p>
  }

  <hr />

  <textarea
    class="inputArea"
    placeholder="Enter text..."
    maxlength="{{ maxTextLength }}"
    [(ngModel)]="textEntered"
    >
  </textarea>

  <div id="buttonsWrapper">
    <div class="button" id="actionButton" (click)="action()">
      {{ actionButtonName }}
    </div>

    <div class="button" id="cancelButton" (click)="cancel()">
      {{ cancelButtonName }}
    </div>
  </div>
</div>
