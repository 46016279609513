import { environment } from 'src/environments/environment'

/**
 * prnt = print
 *
 */
export function prnt(input: any, tag: string | null = null, stringify = false) {
  if (isBrowser() && !environment.production) {
    if (stringify) {
      if (tag) {
        console.log(tag, JSON.stringify(input))
      } else {
        console.log(JSON.stringify(input))
      }
    } else {
      if (tag) {
        console.log(tag, input)
      } else {
        console.log(input)
      }
    }
  }
}

/**
 * prE = print error
 */
export function prntError(
  input: any,
  tag: string | null = null,
  stringify = false
) {
  if (stringify) {
    if (tag) {
      console.error(tag, JSON.stringify(input))
    } else {
      console.error(JSON.stringify(input))
    }
  } else {
    if (tag) {
      console.log(tag, input)
    } else {
      console.log(input)
    }
  }
}

/**
 * Poor check if we are inside a browser. Better would be platform thing of
 * angular, but I want to avoid the need to inject above methods. I want simple
 * importing to suffice.
 */
function isBrowser() {
  return typeof window !== 'undefined' && typeof window !== 'undefined'
}
