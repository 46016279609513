import { Injectable, NgZone, TransferState, makeStateKey } from '@angular/core'
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where
} from 'firebase/firestore'
import { StrHlp } from '../StringGetter/getstring.service'
import { get, getDatabase, ref } from 'firebase/database'
import { firstValueFrom, from, take } from 'rxjs'
import { GLOBAL_CHAT_ID, SITE_PROTOCOL } from '../../constants'
import { CacheService } from '../caching/cache-service.service'
import { IsBrowserService } from '../ssr/isbrowser.service'

const KEY_TS_GROUPS_LIST = makeStateKey<any[]>('KEY_TS_GROUPS_LIST')

const countLoadItems = 100 //300;
const sortString = 'memberCount'
const maxMembersLimit = 5000

@Injectable({
  providedIn: 'root'
})
export class GroupsdataService {
  empty = false
  isLoading = true
  itemList: any[] = []

  rtdb = getDatabase()
  userID: string | null = null

  wasAddedGC = false

  constructor(
    private cacheService: CacheService,
    private transferState: TransferState,
    private isBrowserService: IsBrowserService
  ) {}

  async transferStateData() {
    const list = this.transferState.get(KEY_TS_GROUPS_LIST, [])

    if (list.length > 0) {
      this.itemList = list
      this.empty = this.itemList.length == 0
    } else {
      if (this.itemList.length == 0) {
        this.loadGroups()
      }
    }
  }

  async loadFromServerSide() {
    this.addGC()
    await this.loadGroups()
  }

  /**
   * As of now, a user is auto added to the GC. Therefore, we show the GC in this list,
   * iff he either left the GC or is not logged in
   */
  determineIfAddGC() {
    if (this.userID) {
      from(
        get(
          ref(
            this.rtdb,
            `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/HasLeftGC`
          )
        )
      )
        .pipe(take(1))
        .subscribe({
          next: (hasLeftSnap) => {
            if (hasLeftSnap.exists() && hasLeftSnap.val()) {
              this.addGC()
            }
          }
        })
    } else {
      // not logged in
      this.addGC()
    }
  }

  addGC() {
    if (!this.wasAddedGC) {
      // puts in at first place
      const obj = {
        groupID: GLOBAL_CHAT_ID,
        memberCount: -1,
        timestamp: 0
      }
      this.loadAdditionalGroupDataByRawdata(obj, true)
      this.updateForCD()
      this.wasAddedGC = true
    }
  }

  /**
   * needs to be improved: only loads the xx groups with the most members. No load on scroll or anything.
   */
  async loadGroups(): Promise<void> {
    const fs = getFirestore()

    const q = query(
      collection(fs, `${StrHlp.CLOUD_PATH}/Discover/GroupChats/`),
      orderBy(sortString, 'desc'),
      limit(countLoadItems),
      where(sortString, '<', maxMembersLimit)
    )

    // get docs
    const querySnapshot = await getDocs(q)

    this.empty = querySnapshot.empty

    const promises: Promise<void>[] = []
    querySnapshot.forEach((doc) => {
      const groupDataRaw = doc.data()
      promises.push(this.loadAdditionalGroupDataByRawdata(groupDataRaw))
    })

    await Promise.all(promises)
  }

  async loadAdditionalGroupDataByRawdata(groupDataRaw: any, unshift = false) {
    const id = groupDataRaw.groupID
    let additionalData

    if (id === GLOBAL_CHAT_ID) {
      const messageCount = await firstValueFrom(
        this.cacheService.getGroupMessageCount(id)
      )
      const siteUrl = `${SITE_PROTOCOL}://${StrHlp.APP_URL}`

      additionalData = {
        name: `Global ${StrHlp.COMMUNITY_NAME} Chat`,
        image: `${siteUrl}/assets/favico.png`,
        messageCount: messageCount
      }
    } else {
      const name = await firstValueFrom(this.cacheService.getGroupName(id))
      const image = await firstValueFrom(this.cacheService.getGroupImage(id))
      const messageCount = await firstValueFrom(
        this.cacheService.getGroupMessageCount(id)
      )

      additionalData = {
        name: name,
        image: image,
        messageCount: messageCount
      }
    }

    const data = {
      ...groupDataRaw,
      ...additionalData
    }

    if (unshift) {
      this.itemList.unshift(data)
    } else {
      this.itemList.push(data)
    }

    this.updateForCD()
    this.isLoading = false

    // update after every adding since the timeout could interrupt
    if (this.isBrowserService.isServer()) {
      this.transferState.set(KEY_TS_GROUPS_LIST, this.itemList)
    }
  }

  updateForCD() {
    this.itemList = [...this.itemList]
  }
}
