<div id="container">
  <main id="container-content">
    <div id="header">
      <app-branding></app-branding>
    </div>

    <form>
      <p class="divider-hint">Enter information</p>

      <input
        data-testid="email-login-input-email"
        type="email"
        placeholder="Email"
        maxlength="500"
        name="something1"
        [(ngModel)]="emailEntered"
        />

      <input
        data-testid="email-login-input-password"
        type="{{ passwordVisible ? 'text' : 'password' }}"
        placeholder="Password"
        maxlength="500"
        name="something2"
        [(ngModel)]="passwordEntered"
        />

      @if (passwordEntered != '') {
        <p
          class="password-options"
          (click)="changePasswordVisibility()"
          >
          {{ passwordVisible ? 'Hide password' : 'Show password' }}
        </p>
      }

      <div
        class="subtle-button password-options"
        (click)="forgotPasswordDialog()"
        >
        Forgot password?
      </div>

      <p class="divider-hint">Continue</p>

      <button
        data-testid="email-login-login-button"
        class="action-button"
        [ngClass]="{
          'action-button-disabled':
            indicateLoading || emailEntered == '' || passwordEntered == '',
          'red-button': !(
            indicateLoading ||
            emailEntered == '' ||
            passwordEntered == ''
          )
        }"
        [disabled]="
          indicateLoading || emailEntered == '' || passwordEntered == ''
        "
        (click)="login()"
        >
        {{ indicateLoading ? 'Loading...' : 'Log in' }}
      </button>
    </form>

    @if (errorHint) {
      <p class="error-hint">
        {{ errorHint }}
      </p>
    }

    <div class="back-wrapper">
      <app-backbuttontemplate [showText]="true" [background]="true" />
    </div>

    <div id="recaptcha-container-id"></div>
  </main>
</div>
