<div class="wrapper-outer full-screen-height respect-safe-area-bottom">
  <div #wrapper class="wrapper">
    <div class="css-doodle-container">
      @if (isDarkmode) {
        <css-doodle>
          &#64;grid: 27 / 100vmax / #0f0f0f; &#64;content: &#64;unicode.r(0x21,
          0x7e); color: hsla(&#64;r30, 5%, 15%, &#64;r.1); font-size: 7vmin;
          font-family: sans-serif;
        </css-doodle>
      } @else {
        <css-doodle>
          &#64;grid: 27 / 100vmax / #e3e3e3; &#64;content: &#64;unicode.r(0x21,
          0x7e); color: hsla(&#64;r30, 5%, 85%, &#64;r.6); font-size: 7vmin;
          font-family: sans-serif;
        </css-doodle>
      }
    </div>

    <input
      id="addImageButton_Hidden"
      style="display: none"
      #addImageButton_Hidden
      type="file"
      name="img"
      accept="image/*,video/*"
    />

    <header class="header">
      <div id="header-content">
        <app-backbuttontemplate />

        <a
          class="subtle-button"
          [routerLink]="isGroup ? '/group/' + chatID : '/user/' + otherUserID"
          role="link"
          aria-label="Open profile of this user"
        >
          <img
            [src]="image$ | async"
            alt="{{ chatName }} profile image"
            onerror="src='/assets/broken_file.png';onerror='';"
            class="profile-image chatImage"
          />
        </a>

        <div id="title-wrapper">
          @if (isGroup) {
            <app-usernametemplate
              [values]="{
                groupID: chatID,
                clickAction: openProfileCallback,
                disableClick: true
              }"
            />
          } @else {
            <app-usernametemplate
              [values]="{
                userID: otherUserID
              }"
            />
          }

          @if (chatName === '') {
            <p class="loading-text">Loading...</p>
          }

          <div id="below-username-wrapper">
            @if (currentlyLoadingOnScroll) {
              <div id="loadingindicator-onscroll">
                <span class="small-loading-cycle"></span>
                Loading new messages...
              </div>
            }

            @if (!isGroup) {
              @if (
                !currentlyLoadingOnScroll &&
                !(
                  chataDataService.typingInfo[chatID] &&
                  chataDataService.typingInfoUsername[chatID] !== myUsername
                )
              ) {
                <div id="quick-info-wrapper">
                  {{
                    quickInfoString.length > 0
                      ? quickInfoString
                      : strHlp.getAppName() + ' chat'
                  }}
                </div>
              }
            }

            @if (isGroup) {
              @if (
                !currentlyLoadingOnScroll &&
                !(
                  chataDataService.typingInfo[chatID] &&
                  chataDataService.typingInfoUsername[chatID] !== myUsername
                )
              ) {
                <div id="quick-info-wrapper">
                  {{ messageCount$ | async | numberCommaFormat }} messages
                </div>
              }
            }

            @if (
              !currentlyLoadingOnScroll &&
              chataDataService.typingInfo[chatID] &&
              chataDataService.typingInfoUsername[chatID] !== myUsername
            ) {
              <div id="typing-wrapper">
                {{
                  (chataDataService.typingInfoUsername[chatID] &&
                  chataDataService.typingInfoUsername[chatID].length > 0
                    ? chataDataService.typingInfoUsername[chatID] + ' '
                    : '') + 'is typing...'
                }}
              </div>
            }
          </div>
        </div>

        <div class="options-wrapper">
          <button
            class="subtle-button action-button-wrapper menu-button"
            aria-label="push notifications"
            id="push-notif-button"
            (click)="notifSettingClick()"
            matTooltip="Notifications"
            [matTooltipShowDelay]="1000"
          >
            <img
              class="dark-icon standard-action-button push-notif-icon"
              src="{{
                isChatMuted
                  ? '/assets/ic_muted_chat.png'
                  : '/assets/ic_notification_on_2.png'
              }}"
              alt="Mute"
            />
          </button>

          <button
            class="subtle-button action-button-wrapper menu-button"
            aria-label="Options"
            mat-button
            [matMenuTriggerFor]="menu"
            matTooltip="Options"
            [matTooltipShowDelay]="1000"
          >
            <img
              class="dark-icon standard-action-button menu-icon"
              src="/assets/menu.png"
              alt="options"
            />
          </button>
        </div>

        <mat-menu
          class="standard-options-menu bottom-sheet-matmenu-wrapper"
          #menu="matMenu"
          backdropClass="black-backdrop-light"
        >
          <div appBottomSheetDialogContainer>
            @if (isGlobalChat) {
              <div
                class="standard-options-dialog-item"
                (click)="askLeaveGlobalChat()"
              >
                <span class="options-img-wrap">
                  <img
                    class="dark-icon options-img"
                    src="/assets/ic_set_logout.png"
                    alt="leave group"
                  />
                </span>
                <span class="options-text"> Leave Global Chat </span>
              </div>
            }
            <div
              class="standard-options-dialog-item"
              routerLink="/settings/chats"
            >
              <span class="options-img-wrap">
                <img
                  class="dark-icon options-img"
                  src="/assets/ic_settings2.png"
                  alt="chat settings"
                />
              </span>
              <span class="options-text"> Chat settings </span>
            </div>

            @if (isPrivate) {
              <div
                class="standard-options-dialog-item"
                (click)="askBlockUser()"
              >
                <span class="options-img-wrap">
                  <img
                    class="dark-icon options-img"
                    src="/assets/bottomsheet_ic_block.png"
                    alt="block user"
                  />
                </span>
                <span class="options-text"> Block user </span>
              </div>
            }

            <app-bottomsheet-bottom-branding-spacer *appShowOnlyOnMobile />
          </div>
        </mat-menu>
      </div>
    </header>

    <div
      id="chat-body"
      [ngClass]="{
        'no-interaction': currentlyLoadingOnScroll
      }"
    >
      <div id="chat-body-wrapper">
        @if (
          isPrivate &&
          ((isMeUser1 && !chatOK1) || (!isMeUser1 && !chatOK2)) &&
          !(!chatOK1 && !chatOK2)
        ) {
          <div id="chat-okay-ask-wrapper">
            <p class="chat-okay-text">
              This is a new chat. Do you want to block this user and delete the
              chat? You can also restrict your chat settings. For example, you
              can allow only people you follow to write messages to you.
            </p>
            <div class="chat-okay-button-wrapper1">
              <div class="chat-okay-button" routerLink="/settings/chats">
                Settings
              </div>
              <div class="chat-okay-button" (click)="blockAndDeleteChat()">
                Block & Delete
              </div>
            </div>
            <div class="chat-okay-button" (click)="markChatAsOkay()">
              Seems fine
            </div>
          </div>
        }

        <cdk-virtual-scroll-viewport
          id="body"
          #scrollContainer
          class="message-list viewport"
          predictingVirtualScroll
          [data]="{
            items: itemList,
            itemType: 1,
            generalData: {
              isPrivateChat: isPrivate,
              myUserID: userID
            },
            idAttrName: 'messageID'
          }"
        >
          <div id="message-list">
            <div
              class="item"
              *cdkVirtualFor="let item of itemList; let i = index"
              [attr.data-hm-id]="item.messageID"
            >
              <div class="date-hint-wrapper">
                @if (item.showDateHint) {
                  <p class="date-hint">
                    {{ getDateHintString(item) }}
                  </p>
                }
              </div>

              <app-messagetemplate
                class="message-wrapper"
                id="{{ 'message-' + item.messageID }}"
                [item]="{
                  item: item,
                  showNewMessagesCountHint:
                    newMessagesCount > 0 &&
                    itemList.length - i == newMessagesCount,
                  newMessagesIndicatorCount: itemList.length - i,
                  chatID: chatID,
                  isGroup: isGroup,
                  isPrivate: isPrivate,
                  isMeUser1: isMeUser1,
                  otherUserID: otherUserID,
                  chatName: chatName,
                  areYouGroupAdmin: areYouGroupAdmin,
                  showUsername: shouldShowUsername(item, i),
                  showUserImage: shouldShowUserImage(item, i),
                  msgHasMarginTop: shouldMessageHaveMarginTop(item, i),
                  msgHasMarginBottom: shouldMessageHaveMarginBottom(item, i)
                }"
                [replyClickCallback]="replyClickCallback"
                [setReplyTextCallback]="setReplyTextCallback"
                [mentionUserCallback]="mentionUserCallback"
                [quoteMessageCallback]="quoteMessageCallback"
                [askMuteCallback]="askMuteCallback"
                [editMessageCallback]="editMessageCallback"
                [askDeleteMessageCallback]="askDeleteMessageCallback"
              >
              </app-messagetemplate>
            </div>

            @if (
              !currentlyLoadingOnScroll &&
              itemList.length > 0 &&
              itemList[itemList.length - 1].messageUID === userID &&
              hasOtherUserSeenLastMessage
            ) {
              <div
                id="user-has-seen-your-message-wrapper"
                [attr.data-hm-id]="'msg-read-hint'"
              >
                <img
                  class="double-check-read"
                  src="/assets/ic_double_check_blue.png"
                  alt="read"
                />
                <p>Read</p>
              </div>
            }
          </div>

          @if (itemList.length == 0 && !empty) {
            <app-loadingcircle> </app-loadingcircle>
          }

          @if (empty) {
            <div class="empty-hint-wrapper" (click)="openKeyboard()">
              <img src="{{ emptyGifURL }}" class="empty-gif" alt="Empty" />
              <div class="empy-hint-text">Send the first message!</div>
              <div class="e2ee-hint-wrapper">
                <img
                  src="/assets/ic_set_password2.png"
                  class="dark-icon e2ee-icon"
                  alt="Encrypted"
                />
                Chats are end-to-end encrypted by default
              </div>
            </div>
          }
        </cdk-virtual-scroll-viewport>

        <!--@if (scroll_isAtBottom) {-->
        <div
          id="scroll-down-button-wrapper"
          [ngClass]="{
            'scroll-bottom-button-disappear': shouldBottomScrollButtonDisappear,
            'scroll-bottom-button-appear': !scroll_isAtBottom
          }"
          (click)="scrollToBottom()"
        >
          @if (newMessagesToDiscover > 0) {
            <div
              class="count-showing-div-wrapper count-wrapper count-messages-to-discover"
            >
              <div class="count-showing-div-text">
                {{ newMessagesToDiscover | numberCommaFormat }}
              </div>
            </div>
          }

          <div id="scroll-down-button">
            <img
              class="dark-icon"
              src="/assets/ic_arro_down_scroll.png"
              id="scroll-down-img"
              alt="scroll down"
            />
          </div>
        </div>
        <!--}-->

        <div id="date-indicator-wrapper">
          <div
            class="date-hint date-hint-top"
            [ngClass]="{
              'fade-in-anim': showDateIndicator,
              'fade-out-anim': fadeOutDateIndicator
            }"
          >
            {{ dateIndicatorString }}
          </div>
        </div>

        <!-- Bottom bar -->
        <div id="footer">
          <div id="footer-content">
            @if (replyText) {
              <div class="reply-wrapper-box">
                <div class="swipe-indicator-wrapper">
                  <img
                    src="/assets/ic_reply.png"
                    alt="reply icon"
                    class="light-icon icon-swipe-reply-hint"
                  />
                </div>

                <div
                  class="reply-text-send-message"
                  [innerHTML]="
                    htmlFormattingService.applyTextFormatting(replyText)
                  "
                ></div>

                <img
                  class="dark-icon remove-reply-text"
                  src="/assets/ic_close_this.png"
                  (click)="removeReplyText()"
                  alt="close"
                />
              </div>
            }

            @if (imageUploadedAsString) {
              <div class="reply-wrapper-box">
                <img
                  class="chosen-image-preview"
                  src="{{ imageUploadedAsString ? imageUploadedAsString : '' }}"
                  alt="Image preview"
                  (click)="openPreviewFullscreen(imageUploadedAsString)"
                />

                <p class="image-send-message">Send image</p>
                <img
                  class="dark-icon remove-reply-text"
                  src="/assets/ic_close_this.png"
                  (click)="removeMediumChosen()"
                  alt="remove image"
                />
              </div>
            }

            @if (videoFile) {
              <div class="reply-wrapper-box">
                <img
                  class="chosen-image-preview"
                  src="/assets/video_colored.png"
                  alt="video youtube"
                />
                <p class="image-send-message">Send video</p>
                <img
                  class="dark-icon remove-reply-text"
                  src="/assets/ic_close_this.png"
                  (click)="removeMediumChosen()"
                  alt="remove image"
                />
              </div>
            }

            <div class="message-box-wrapper">
              <div
                class="message-bar-button message-box-child"
                (click)="emojiButtonClick()"
              >
                <img
                  class="dark-icon message-bar-button-icon"
                  id="emojiButtonImage"
                  src="{{
                    showEmojiPicker
                      ? '/assets/ic_keyboard.png'
                      : '/assets/ic_emoji_button.png'
                  }}"
                  alt="pick emoji"
                />
              </div>

              @if (!areYouBlocked) {
                <textarea
                  #messageinput
                  id="message-input"
                  class="formControl message-box-child"
                  maxlength="{{ maxMsgLength }}"
                  placeholder="Type a message..."
                  oninput='style.height = "";style.height = scrollHeight + "px"'
                  [(ngModel)]="messageEntered"
                  (keydown)="onTextChange($event)"
                >
                </textarea>
              }

              @if (areYouBlocked_Determined && !areYouBlocked) {
                <div class="message-bar-end-options-wrapper">
                  <div
                    class="message-bar-button message-box-child"
                    (click)="openSelectImage()"
                  >
                    <img
                      class="dark-icon message-bar-button-icon"
                      src="/assets/ic_camera2.png"
                      alt="camera"
                    />
                  </div>

                  <div
                    class="pos-relative message-box-end-options-wrapper message-bar-button message-box-child"
                  >
                    <div
                      (click)="gifButtonClick()"
                      class="absolute-middle-100w-100h message-bar-button"
                      [ngClass]="{
                        'fade-out-anim-msg-send': shouldFadeInSendingButton,
                        'fade-in-jumpy-anim-msg-send':
                          shouldFadeOutSendingButton,
                        'non-clickable': shouldFadeInSendingButton,
                        clickable: shouldFadeOutSendingButton
                      }"
                    >
                      <img
                        (click)="gifButtonClick()"
                        class="dark-icon message-bar-button-icon"
                        id="gifButtonImage"
                        src="{{
                          showGifsPicker
                            ? '/assets/ic_keyboard.png'
                            : '/assets/gif.png'
                        }}"
                        alt="pick gif"
                      />
                    </div>

                    <div
                      (click)="sendMessage()"
                      class="absolute-middle-100w-100h message-bar-button"
                      [ngClass]="{
                        'opacity-0': isInputBarUntouched,
                        'fade-out-anim-msg-send': shouldFadeOutSendingButton,
                        'fade-in-jumpy-anim-msg-send':
                          shouldFadeInSendingButton,
                        'non-clickable':
                          shouldFadeOutSendingButton || isInputBarUntouched,
                        clickable: shouldFadeInSendingButton
                      }"
                    >
                      <img
                        id="send-button"
                        class="message-bar-button-icon"
                        src="/assets/ic_send_message.png"
                        alt="send message icon"
                      />
                    </div>
                  </div>
                </div>
              }
            </div>

            @if (areYouBlocked) {
              <p id="blockedHint">
                You cannot comment because you are blocked by this user
              </p>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
