import { Directive, EventEmitter, HostListener, Output } from '@angular/core'

/**
 * Must move at least ... in x direction to the left
 */
const xDistanceThreshold = 50

/**
 * If moves more than ... in any y direction, not considered a swipe anymore
 */
const yMaxDistanceThreshold = 25

/**
 * If gesture exceeds this time limit its not considered a swipe anymore
 */
const maxTimeLimit = 400

/**
 * Directive to detect swipe left or right gestures
 */
@Directive({
  selector: '[appSwipeDetect]'
})
export class SwipeLeftDetectDirective {
  @Output() onSwipeLeftDetect = new EventEmitter<void>()
  @Output() onSwipeRightDetect = new EventEmitter<void>()

  private touchStartX: number = 0
  private touchStartY: number = 0
  private touchStartTime: number = 0

  constructor() {}

  // Add listeners using @HostListener for touch events

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent): void {
    const touch = event.touches[0]
    this.touchStartX = touch.clientX
    this.touchStartY = touch.clientY
    this.touchStartTime = new Date().getTime() // capture timestamp
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent): void {
    const touchEndTime = new Date().getTime()
    const timeDiff = touchEndTime - this.touchStartTime

    if (timeDiff > maxTimeLimit) {
      // Exceeded the max time limit for swipe
      return
    }

    const touch = event.changedTouches[0]
    const deltaX = touch.clientX - this.touchStartX
    const deltaY = touch.clientY - this.touchStartY

    // Check if the swipe is primarily horizontal and not too much vertical movement
    if (Math.abs(deltaY) > yMaxDistanceThreshold) {
      return // Not a valid swipe because of too much vertical movement
    }

    // Check for swipe left
    if (deltaX < -xDistanceThreshold) {
      this.onSwipeLeftDetect.emit()
    }
    // Check for swipe right
    else if (deltaX > xDistanceThreshold) {
      this.onSwipeRightDetect.emit()
    }
  }
}
