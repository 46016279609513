import { Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { HotToastService } from '@ngneat/hot-toast'
import { get, getDatabase, limitToFirst, query, ref } from 'firebase/database'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { LoadingDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: [
    './subscriptions.component.css',
    '../settingspage/settings.component.css',
    '../../user/followers/followers.component.css',
    '../../feed/feed.component.css',
    '../../user/user.component.css'
  ]
})
export class SubscriptionsComponent {
  itemList: any[] = []
  empty = false
  isLoading = true

  constructor(
    private toast: HotToastService,
    private twobuttonsdialogService: TwobuttonsdialogService,
    private dialog: MatDialog,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage(
      'Manage subscriptions',
      'Manage active subscriptions'
    )
    this.loadItems()
  }

  async loadItems() {
    // load max. 100 items
    const db = getDatabase()
    const q = query(
      ref(
        db,
        `${StrHlp.CLOUD_PATH}/ActiveSubscriptions/${AuthService.getUID()}`
      ),
      limitToFirst(20)
    )

    const snapshot = await get(q)

    if (!snapshot.exists()) {
      this.isLoading = false
      this.empty = true
    } else {
      snapshot.forEach((childSnapshot) => {
        const subscInfo = childSnapshot.val()
        this.itemList.push(subscInfo)
        this.isLoading = false
      })
    }
  }

  cancelSubscription(item: any, index: number) {
    // confirm dialog
    this.twobuttonsdialogService.show(
      'Confirm',
      'Do you want to cancel this subscription?',
      () => {},
      () => {
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        const functions = getFunctions()
        const cancelSubscription = httpsCallable(
          functions,
          'cancelSubscription'
        )

        cancelSubscription({
          hubname: StrHlp.CLOUD_PATH,
          subscriptionId: item.id
        })
          .then(() => {
            loadingDialogRef.close()
            this.toast.success('Subscription cancelled')
            this.itemList.splice(index, 1)

            this.empty = this.itemList.length == 0
          })
          .catch((error) => {
            loadingDialogRef.close()
            console.log(error)

            // show error message via dialog
            this.toast.error('Error occurred')
          })
      },
      'No',
      'Yes'
    )
  }
}
