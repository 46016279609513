import {
  CdkVirtualScrollViewport,
  VirtualScrollStrategy
} from '@angular/cdk/scrolling'

import { distinctUntilChanged, Observable, Subject } from 'rxjs'
import { itemHeightPredictor } from './ItemHeightPredictor'

let PaddingAbove = 5
let PaddingBelow = 5

interface ItemHeight {
  value: number
  source: 'predicted' | 'actual'
}

export class PredictingVirtualScrollStrategy implements VirtualScrollStrategy {
  /**
   * 0 = post
   * 1 = message
   * 2 = comment
   * ... TODO
   */
  private _itemType = 0
  private _idAttrName = ''
  private _generalData: any
  private _classNameOfItem = ''

  private _wrapper!: ChildNode | null

  _scrolledIndexChange$ = new Subject<number>()
  scrolledIndexChange: Observable<number> = this._scrolledIndexChange$.pipe(
    distinctUntilChanged()
  )

  private _heightCache = new Map<string, ItemHeight>()

  private _items: any[] = []

  private _viewport!: CdkVirtualScrollViewport | null

  attach(viewport: CdkVirtualScrollViewport): void {
    this._viewport = viewport
    this._wrapper = viewport.getElementRef().nativeElement.childNodes[0]

    if (this._items) {
      this._viewport.setTotalContentSize(this._getTotalHeight())
      this._updateRenderedRange()
    }
  }

  detach(): void {
    this._viewport = null
  }

  updateItems(items: any[]) {
    this._items = items

    if (this._viewport) {
      this._viewport.checkViewportSize()
    }
  }

  setItemType(itemType: number) {
    this._itemType = itemType
  }
  setIdAttrName(idAttrName: string) {
    this._idAttrName = idAttrName
  }
  setGeneralData(generalData: any) {
    this._generalData = generalData
  }
  setItemClassName(itemClassName: string) {
    this._classNameOfItem = itemClassName
  }
  setPadding(padding: number) {
    PaddingAbove = padding
    PaddingBelow = padding
  }

  private _getItemHeight(item: any): number {
    const id = item[this._idAttrName]
    //console.log("this._idAttrName:",this._idAttrName, ", ID is:",id);

    let height = 0
    const cachedHeight = this._heightCache.get(id)

    if (!cachedHeight) {
      height = itemHeightPredictor(this._itemType, item, this._generalData)

      // Values from the height predictor will be marked as `predicted`
      this._heightCache.set(id, { value: height, source: 'predicted' })
    } else {
      height = cachedHeight.value
    }

    return height
  }

  private _measureItemsHeight(items: any[]): number {
    return items
      .map((item) => this._getItemHeight(item))
      .reduce((a, c) => a + c, 0)
  }

  /**
   * Returns the total height of the scrollable container
   * given the size of the elements.
   */
  private _getTotalHeight(): number {
    return this._measureItemsHeight(this._items)
  }

  /**
   * Returns the offset relative to the top of the container
   * by a provided message index.
   *
   * @param idx
   * @returns
   */
  private _getOffsetByItemIdx(idx: number): number {
    return this._measureItemsHeight(this._items.slice(0, idx))
  }

  /**
   * Returns the message index by a provided offset.
   *
   * @param offset
   * @returns
   */
  private _getItemIdxByOffset(offset: number): number {
    let accumOffset = 0

    for (let i = 0; i < this._items.length; i++) {
      const item = this._items[i]
      const height = this._getItemHeight(item)
      accumOffset += height

      if (accumOffset >= offset) {
        return i
      }
    }

    return 0
  }

  private _determineItemsCountInViewport(startIdx: number): number {
    if (!this._viewport) {
      return 0
    }

    let totalSize = 0
    // That is the height of the scrollable container (i.e. viewport)
    const viewportSize = this._viewport.getViewportSize()

    for (let i = startIdx; i < this._items.length; i++) {
      const item = this._items[i]
      totalSize += this._getItemHeight(item)

      if (totalSize >= viewportSize) {
        return i - startIdx + 1
      }
    }

    return 0
  }

  private _updateRenderedRange() {
    if (!this._viewport) {
      return
    }

    const scrollOffset = this._viewport.measureScrollOffset()
    const scrollIdx = this._getItemIdxByOffset(scrollOffset)
    const dataLength = this._viewport.getDataLength()
    const renderedRange = this._viewport.getRenderedRange()
    const range = {
      start: renderedRange.start,
      end: renderedRange.end
    }

    range.start = Math.max(0, scrollIdx - PaddingAbove)
    range.end = Math.min(
      dataLength,
      scrollIdx + this._determineItemsCountInViewport(scrollIdx) + PaddingBelow
    )

    this._viewport.setRenderedRange(range)
    this._viewport.setRenderedContentOffset(
      this._getOffsetByItemIdx(range.start)
    )
    this._scrolledIndexChange$.next(scrollIdx)

    this._updateHeightCache()
  }

  onDataLengthChanged(): void {
    if (!this._viewport) {
      return
    }

    this._viewport.setTotalContentSize(this._getTotalHeight())
    this._updateRenderedRange()
  }

  onContentScrolled(): void {
    if (this._viewport) {
      this._updateRenderedRange()
    }
  }

  onContentRendered(): void {
    /** no-op */
  }

  onRenderedOffsetChanged(): void {
    /** no-op */
  }

  scrollToIndex(index: number, behavior: ScrollBehavior): void {
    if (!this._viewport) {
      return
    }

    const offset = this._getOffsetByItemIdx(index)
    this._viewport.scrollToOffset(offset, behavior)
  }

  scrollToBottom(behavior: ScrollBehavior): void {
    if (!this._viewport) {
      return
    }

    const offset = this._getTotalHeight()
    this._viewport.scrollToOffset(offset, behavior)
  }

  /**
   * TODO: The classname thing will break in production when we bring back optimizer
   */
  private _updateHeightCache() {
    if (!this._wrapper || !this._viewport) {
      return
    }

    // Get a reference of the child nodes/list items
    let nodes: any = []

    if (this._itemType == 0) {
      nodes = this._wrapper.childNodes
    } else if (this._itemType == 1) {
      nodes = this._wrapper.childNodes[0].childNodes
    }

    let cacheUpdated: boolean = false

    //console.log("CC1", "this._wrapper:", this._wrapper);
    //console.log("CC1", "this._wrapper.childNodes:", this._wrapper.childNodes);

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i] as HTMLElement

      //console.log("CC1", "Checking for:",`${this._classNameOfItem} ng-star-inserted`, ", against:",node.className," => equal: ",(node && node.className == `${this._classNameOfItem} ng-star-inserted`));

      // Check if the node is what we need
      if (
        node &&
        node.className == `${this._classNameOfItem} ng-star-inserted`
      ) {
        // Get the message ID
        const id = node.getAttribute('data-hm-id') as string
        const cachedHeight = this._heightCache.get(id)

        // Update the height cache, if the existing height is predicted
        if (!cachedHeight || cachedHeight.source !== 'actual') {
          const height = node.clientHeight

          //console.log("CC1", "actualHeight for updating cache:",height);

          this._heightCache.set(id, { value: height, source: 'actual' })
          cacheUpdated = true

          //console.log("CC1", "heightsCache:",this._heightCache);
        }
      }
    }

    // Reset the total content size only if there has been a cache change
    if (cacheUpdated) {
      this._viewport.setTotalContentSize(this._getTotalHeight())
    }
  }
}
