<div
  class="container respect-safe-area-top respect-safe-area-bottom"
  [ngClass]="{ 'container-fullscreen-mode': isMobile }"
  >
  <div id="header">
    <app-closebutton data-testid="makepost-close-button" />

    <button
      data-testid="makepost-post-button"
      class="subtle-button action-button"
      [ngClass]="{
        'action-button-inactive':
          captionEntered.trim().length == 0 &&
          mediaToUpload.length == 0 &&
          repostVideoID == '' &&
          repostImageURL_Array.length == 0,

        'action-button-active': !(
          captionEntered.trim().length == 0 &&
          mediaToUpload.length == 0 &&
          repostVideoID == '' &&
          repostImageURL_Array.length == 0
        )
      }"
      (click)="attemptPost()"
      >
      {{ isRepost ? 'Repost' : 'Post' }}
    </button>
  </div>

  @if (isRepost) {
    <div id="repost-hint">
      <img src="/assets/ic_alert7.png" class="dark-icon alert-icon" />
      You cannot edit the image, caption or location because this is a repost.
    </div>
  }

  @if (isRepost) {
    <div class="repostImagePreview">
      <div class="mediumPreviewWrapper">
        @for (medium of repostImageURL_Array; track medium; let i = $index) {
          <div
            class="mediumPreview"
            (click)="openPreviewFullscreen(medium)"
            >
            <img
              class="mediumPreviewImage"
              alt="image preview"
              src="{{ medium }}"
              />
          </div>
        }
        @if (repostVideoID) {
          <div class="mediumPreview">
            <img
              class="mediumPreviewImage"
              alt="video icon"
              src="/assets/video_colored.png"
              />
          </div>
        }
      </div>
    </div>
  }

  @if (!isRepost) {
    <app-imageuploadtemplate
      [uploadInProgress]="uploadInProgress"
      [mediaChangedCallback]="mediaChangedCallback"
      [disabled]="isRepost"
      [allowVideos]="true"
      [allowImage]="true"
      [allowMultipleImages]="true"
      />
  }

  <div class="main-wrapper">
    <hr class="inline-divider" />

    <div class="main">
      <div class="button-bar">
        <img
          id="profileImage"
          alt="Profile photo"
          [src]="profileImg$ | async"
          onerror="this.src='/assets/default_profile_pic.jpg';this.onerror='';"
          />

        <img
          class="dark-icon side-button"
          id="emojiButton"
          (click)="emojiButtonClick()"
          src="{{
            showEmojiPicker
              ? '/assets/ic_keyboard.png'
              : '/assets/ic_emoji_button.png'
          }}"
          alt="emoji pick"
          />

        <img
          data-testid="makepost-copy-button"
          class="dark-icon side-button copy-button"
          (click)="copyCaption()"
          src="/assets/ic_copy.png"
          alt="copy"
          />

        <img
          data-testid="makepost-open-help-button"
          class="dark-icon side-button copy-button"
          (click)="openHelpDialog()"
          src="/assets/ic_help.png"
          alt="help icon"
          />
      </div>

      <textarea
        data-testid="makepost-textarea"
        cdkTextareaAutosize
        class="inputArea captionInput"
        #captionTextarea
        placeholder="What's on your mind?! &#10;Click the square above to post a photo or video."
        [maxlength]="maxPostLength"
        [(ngModel)]="captionEntered"
        [readonly]="isRepost"
        autofocus
        >
      </textarea>
    </div>
  </div>

  <div class="footer">
    <hr class="inline-divider" />

    <div class="footer-content">
      <div class="locationWrapper">
        <img
          src="assets/ic_map.png"
          alt="Location map"
          class="footer-icon-img"
          />

        <input
          data-testid="makepost-location-input"
          class="inputArea"
          placeholder="Location..."
          maxlength="15"
          onkeydown="return /[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_.0123456749 ]/g.test(event.key)"
          [(ngModel)]="locationEntered"
          [readonly]="isRepost"
          />
      </div>

      <div class="footer-divider"></div>

      <div class="textLimitInfoWrapper">
        <img
          src="assets/abc.png"
          class="dark-icon footer-icon-img"
          alt="text"
          />

        <p class="textLimitInfo">
          {{
          captionEntered
          ? captionEntered.length + '/' + maxPostLength
          : 'Empty...'
          }}
        </p>
      </div>
    </div>

    <hr class="inline-divider" />
  </div>
</div>
