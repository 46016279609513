import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { HotToastService } from '@ngneat/hot-toast'
import firebase from 'firebase/compat/app'
import { child, getDatabase, ref, set } from 'firebase/database'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'

@Component({
  selector: 'app-copyright-claim',
  templateUrl: './copyright-claim.component.html',
  styleUrls: [
    './copyright-claim.component.css',
    '../makepost/makepost.component.css',
    '../settings/settingspage/settings.component.css',
    '../sign-in/sign-in.component.css'
  ]
})
export class CopyrightClaimComponent implements OnInit {
  passwordEntered: string = ''
  passwordVisible: boolean = false

  @ViewChild('showpassword') showpassword!: ElementRef
  @ViewChild('userPassword') userPassword!: ElementRef
  @ViewChild('passwordError') passwordError!: ElementRef
  @ViewChild('agreerequired') agreerequired!: ElementRef
  @ViewChild('agree') agree!: ElementRef
  @ViewChild('agreecheckbox3') agreecheckbox3!: ElementRef
  @ViewChild('invalidInputError')
  invalidInputError!: ElementRef

  userID: any = null
  user: any = null
  currentEmail: string = ''

  fullLegalName: string = ''
  nameCopyrightOwner: string = ''
  trademarkOwner: string = ''
  physicalAddress: string = ''
  phoneNumber: string = ''
  email: string = ''
  proofCopyrightTrademark: string = ''
  proofGeneral: string = ''
  nameSigned: string = ''

  reportTypeAsString: string = ''
  reportID: string = ''

  stage1: boolean = true
  stage2: boolean = false
  stage3: boolean = false

  rtdb = ref(getDatabase())

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: HotToastService,
    private dialogRef: MatDialogRef<CopyrightClaimComponent>,
    private twobuttonsdialogService: TwobuttonsdialogService
  ) {}

  ngOnInit(): void {
    this.userID = AuthService.getUID()
    this.user = AuthService.getUser()
    this.currentEmail = this.user.email

    this.reportTypeAsString = this.data.reportTypeAsString
    this.reportID = this.data.reportID // e.g. the postID, commentID, profileID
  }

  changePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible

    if (this.passwordVisible) {
      this.showpassword.nativeElement.innerText = 'Hide password'
      this.userPassword.nativeElement.type = 'text'
    } else {
      this.showpassword.nativeElement.innerText = 'Show password'
      this.userPassword.nativeElement.type = 'password'
    }
  }

  continueClick(): void {
    this.passwordError.nativeElement.style.display = 'none'

    if (this.passwordEntered.length == 0) {
      this.passwordError.nativeElement.style.display = 'block'
      return
    }

    // check if password correct
    firebase
      .auth()
      .signInWithEmailAndPassword(this.currentEmail, this.passwordEntered)
      .then((userCredential) => {
        const currUser = userCredential.user
        if (currUser !== null) {
          // password is correct, continue
          this.stage1 = false
          this.stage2 = true
        } else {
          this.toast.error('An error has occurred')
        }
      })
      .catch((error) => {
        this.toast.error(error.message)
        this.toast.error('Failed. Likely the password you entered is wrong')
      })
  }

  continueClick2(): void {
    this.agreerequired.nativeElement.style.display = 'none'
    if (!this.agree.nativeElement.checked) {
      this.agreerequired.nativeElement.style.display = 'block'
      return
    }

    this.stage2 = false
    this.stage3 = true
  }

  askSubmitClick(): void {
    this.invalidInputError.nativeElement.style.display = 'none'

    // check if all fields are filled
    if (
      this.fullLegalName.length == 0 ||
      this.physicalAddress.length == 0 ||
      this.phoneNumber.length == 0 ||
      this.email.length == 0 ||
      this.nameSigned.length == 0 ||
      !this.agreecheckbox3.nativeElement.checked
    ) {
      this.invalidInputError.nativeElement.style.display = 'block'
      return
    }

    // ask if sure
    this.twobuttonsdialogService.show(
      'Submit',
      'Do you want to submit this and start a legal process?',
      () => {
        // nothing
      },
      () => {
        this.submitClick()
      },
      'Cancel',
      'Submit'
    )
  }

  submitClick(): void {
    this.toast.show('Loading...')
    this.dialogRef.close()

    const finalReportID = this.userID + '_' + this.reportID

    const submit = {
      issueID: this.reportID,
      reportID: finalReportID,
      fullLegalName: this.fullLegalName,
      reportType: this.reportTypeAsString,
      reporterUserID: this.userID,
      nameOfCopyrightOwner: this.nameCopyrightOwner,
      nameOfTrademarkOwner: this.trademarkOwner,
      phoneNumber: this.phoneNumber,
      primaryEmail: this.email,
      fullAddress: this.physicalAddress,
      digitalSignature: this.nameSigned,
      explanationIncludingProof: this.proofGeneral
    }

    set(
      child(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/LegalIssueReports/${this.userID}/${finalReportID}`
      ),
      submit
    )
      .then(() => {
        this.toast.success('Submitted')
      })
      .catch((error) => {
        console.log(error)
        this.toast.error(
          'An error has occurred. Please try again later or send us an email'
        )
      })
  }
}
