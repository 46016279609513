<div class="wrapper">
  <div id="header">
    <p id="title">{{ pageTitle }}</p>

    <img
      id="cancel-button"
      class="dark-icon"
      src="/assets/ic_close_2.png"
      alt="close"
      matBottomSheetClose
    />
  </div>

  <div class="content-wrapper">
    <div class="search-wrapper">
      @if (searchMode) {
        <app-backbuttontemplate
          [disableAction]="true"
          (click)="cancelSearch()"
        />
      }

      <app-searchbar
        id="search-bar"
        [searchCallback]="searchCallback"
        [disableAutoFocus]="disableAutoFocus"
        [disableRecentsHint]="true"
      />
    </div>

    @if (!searchMode) {
      <div class="no-search-wrapper">
        @if (chataDataService.chatList.length > 0) {
          <div>
            @for (
              item of chataDataService.chatList;
              track item;
              let i = $index
            ) {
              <div>
                <app-chatitemtemplate
                  class="item"
                  matBottomSheetClose
                  [item]="{
                    item: item,
                    indexInList: i,
                    hideMenuButton: true,
                    allowLongTap: false,
                    itemClickCallback: clickCallback
                  }"
                >
                </app-chatitemtemplate>
              </div>
            }
          </div>
        }
        @if (
          !chataDataService.listEmpty && chataDataService.chatList.length == 0
        ) {
          <app-loadingcircle />
        }
        @if (chataDataService.listEmpty) {
          <app-emptyhinttemplate />
        }
      </div>
    }

    @if (searchMode) {
      <div class="serch-wrapper">
        <h2 class="search-results-title">Search results:</h2>
        @if (searchResultList.length > 0) {
          <div>
            @for (item of searchResultList; track item; let i = $index) {
              <div>
                <app-chatitemtemplate
                  class="item"
                  matBottomSheetClose
                  [item]="{
                    item: item,
                    indexInList: i,
                    hideMenuButton: true,
                    allowLongTap: false,
                    itemClickCallback: clickCallback
                  }"
                />
              </div>
            }
          </div>
        }
        @if (emptySearch) {
          <app-emptyhinttemplate />
        }
        @if (searchResultList.length == 0) {
          <app-loadingcircle />
        }
      </div>
    }
  </div>
</div>
