<div id="outter-wrapper">
  <div id="top-branding">
    <app-branding></app-branding>
  </div>

  <hr class="inline-divider" />

  <h2>Our Vision.</h2>
  <p>
    We believe Social Media should focus on connecting - not on addiction.
    SocialHubs offers a truly social app for every community worldwide. We have
    over 100,000 monthly active users, and 1 million total downloads.
  </p>

  <hr class="inline-divider" />

  <h2>Data Privacy. Freedom of Speech. Social.</h2>
  <p>
    Total Downloads: 1,000,000+<br />
    Monthly Downloads: 50,000+<br />
    Monthly Active Users: 50,000+<br />
  </p>

  <hr class="inline-divider" />

  @if (
    StrHlp.getCloudPath() == 'TESTHUB' || StrHlp.getCloudPath() == 'PATRIOTS'
    ) {
    <div
      >
      <h2>About Us.</h2>
      <p>
        Welcome to {{ StrHlp.getAppName() }}, the #1 social media network
        dedicated to upholding the values of freedom, liberty, and the
        Constitution. We are proud to be the platform where patriots can come
        together to express their thoughts, opinions, and unwavering support for
        our great nation.<br /><br />
        At {{ StrHlp.getAppName() }}, we firmly believe in the fundamental
        principle of freedom of speech. We understand that the ability to express
        oneself openly and without fear is a cornerstone of any free society. We
        refuse to be silenced or censored, and we provide a safe space for
        individuals who have been marginalized, dismissed, or targeted for holding
        conservative beliefs.<br />
        We stand firmly in support of the Constitution of the United States,
        recognizing it as the guiding document that outlines the rights and
        liberties bestowed upon each and every citizen. <br /><br />
        On {{ StrHlp.getAppName() }}, you will find a diverse range of features
        and tools to enhance your experience. Share your thoughts, engage with
        like-minded individuals, discover inspirational content, and connect with
        patriots from all walks of life. Whether you're looking for political
        discussions, news updates, or simply a supportive community,
        {{ StrHlp.getAppName() }} is the destination for you.<br /><br />
        Together, we can shape a brighter future, one rooted in freedom, liberty,
        and unwavering dedication to the Constitution.
      </p>
      <hr class="inline-divider" />
    </div>
  }

  <!--
  <h2>Lukas Niessen.</h2>
  <p>
    In October 2021, Lukas Niessen, a 22-year-old German math student, founded our company with the belief that freedom of speech and expression, as written in the constitution, should be valued and protected. With his dedication to preserving our fundamental rights, Lukas created a platform that empowers individuals to speak their minds, connect with like-minded people, and have control over their data privacy. Unlike otsher social networks, our platform does not use AI algorithms and puts users in charge.

    <a class="optionButton"
      mat-dialog-close
      routerLink="/lukasniessen">
      Read more...
    </a>
  </p>

  <hr class="inline-divider">
  -->

  <div id="branding-wrapper">
    <img
      id="branding-logo"
      src="/assets/applogo_transparent.png"
      alt="{{ StrHlp.getAppName() }} logo"
      />
    <p id="branding-text">
      {{ StrHlp.getAppName() }} Web {{ StrHlp.getVersionString() }}
    </p>
  </div>
</div>
