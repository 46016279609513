<a
  class="subtle-button bottom-nav-option-wrapper"
  (click)="onClickInput()"
  [routerLink]="link"
  role="link"
  [attr.aria-label]="ariaLabel"
>
  <app-number-badge
    [number]="badgeNumber"
    [rightPx]="-10"
    [topPx]="-10"
    [isBlueInput]="false"
  >
    <img
      class="dark-icon bottom-nav-option-image"
      src="{{ isSelected ? selectedImgSrc : notSelectedImgSrc }}"
      alt="{{ imageAlt }}"
    />
  </app-number-badge>
</a>
