<div id="wrapper">
  <h1>Statistics</h1>
  <p>
    This page displays a limited set of statistics from all Hubs. It is
    important to acknowledge that the following numbers are combined numbers
    from all of our Hubs and not only from {{ strHlp.getAppName() }}.
    Furthermore it is not an exhaustive representation.
  </p>

  <hr class="inline-divider" />
  <div class="version">
    <h2>Overall</h2>
    <ul>
      <li><b>Total screen views:</b> 128,000,000</li>
      <li><b>Monthly screen views:</b> 400,000</li>
      <li><b>Total users:</b> 1,500,000</li>
      <li><b>Monthly active users:</b> 20,000</li>
    </ul>
    <p>Last updated: 21/09/2024</p>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>Website</h2>
    <ul>
      <!--
        Important note:

        HOW TO CALCULATE:

        Since we have a SPA, we need to implement the counting process. Before it only counted
        **page-views** and no navigation then at all. Screen views were added after some time only.
        They do account for all clicks, not the page views from before.

        Page views before: 50,000
        Estimated screen views from that: 250,000 (50,000 * 5)

        Hence the formula is:
        Total-Screenviews = Measured-Screenviews + 250,000
    -->
      <li><b>Total screen views:</b> 1,450,000</li>
      <li><b>Monthly screen views:</b> 100,000</li>
    </ul>
    <p>Web App released: 02/01/2023</p>
    <p>Stats last updated: 21/09/2024</p>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>Android App</h2>
    <ul>
      <li><b>Total screen views:</b> 127,000,000</li>
      <li><b>Monthly screen views:</b> 300,000</li>
    </ul>
    <p>Android App released: 01/10/2021</p>
    <p>Last updated: 21/09/2024</p>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>iOS App</h2>
    <ul>
      <li>-empty-</li>
    </ul>
    <p>iOS App released: -</p>
    <p>Last updated: -</p>
  </div>

  <hr class="inline-divider" />
</div>
