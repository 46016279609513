import { Injectable } from '@angular/core'
import { LoadingDialogComponent } from 'src/app/components/dialogs/loading-dialog/loading-dialog.component'
import { StrHlp } from '../StringGetter/getstring.service'
import {
  get,
  getDatabase,
  increment,
  push,
  ref,
  remove,
  set
} from 'firebase/database'
import { AuthService } from '../auth/auth.service'
import { HotToastService } from '@ngneat/hot-toast'
import { NumberFormatService } from '../formatting/number/numberformat.service'
import { MatDialog } from '@angular/material/dialog'
import { ImageLoadingService } from '../imageloading/imageloading.service'
import { TwobuttonsdialogService } from '../dialogs/twobuttonsdialogservice.service'
import { OnedialogserviceService } from '../dialogs/onedialogservice.service'
import { GroupsdataService } from '../data/groupsdata.service'
import { Router } from '@angular/router'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import firebase from 'firebase/compat/app'

@Injectable({
  providedIn: 'root'
})
export class GroupserviceService {
  rtdb = getDatabase()
  userID = AuthService.getUID()
  MAX_MEMBER_COUNT = 5000

  constructor(
    private toast: HotToastService,
    public numberFormatService: NumberFormatService,
    public dialog: MatDialog,
    public imgHlp: ImageLoadingService,
    public authService: AuthService,
    private twobuttonsdialogService: TwobuttonsdialogService,
    private oneButtonDialogService: OnedialogserviceService,
    public groupsdataService: GroupsdataService,
    private router: Router
  ) {}

  async attemptJoinGroup(groupID: string) {
    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    // get invitelinkID because maybe that user is blocked
    const invLinkSnap = await get(
      ref(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Infos/inviteLink`
      )
    )
    if (invLinkSnap.exists()) {
      const invLink = invLinkSnap.val()

      loadingDialogRef.close()
      await this.attempJoinGroupWithInvLinkID(groupID, invLink)
    } else {
      loadingDialogRef.close()
      this.oneButtonDialogService.show(
        'Error occurred',
        'Loading information has failed (error-2).'
      )
      return
    }
  }

  async createLink(groupID: string) {
    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    const key = push(
      ref(this.rtdb, `${StrHlp.CLOUD_PATH}/GroupInviteLinks/`)
    ).key

    await set(
      ref(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Infos/inviteLink`
      ),
      key
    )
    await set(
      ref(this.rtdb, `${StrHlp.CLOUD_PATH}/GroupInviteLinks/${key}/groupID`),
      groupID
    )

    loadingDialogRef.close()

    return key
  }

  async attempJoinGroupWithInvLinkID(groupID: string, invLinkID: string) {
    if (groupID === '') {
      // load it
      const groupIdSnap = await get(
        ref(
          this.rtdb,
          `${StrHlp.CLOUD_PATH}/GroupInviteLinks/${invLinkID}/groupID`
        )
      )
      if (groupIdSnap.exists()) {
        groupID = groupIdSnap.val()
      }

      if (groupID === '') {
        this.toast.error('Error occurred')
        return
      }
    }

    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    // check if user is not on the blocked list
    const blockedSnap = await get(
      ref(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/GroupInviteLinks/${invLinkID}/BlockedUIDs/${this.userID}`
      )
    )
    const blocked = blockedSnap.exists()

    if (blocked) {
      loadingDialogRef.close()

      // blocked from joining, show error message
      this.oneButtonDialogService.show(
        'Blocked',
        'You cannot use this invitation link because a group admin has removed you from the group. If the admin removed you by mistake, ask the admin to add you manually.'
      )
      return
    } else {
      // check if group limit is reached
      const memberCountSnap = await get(
        ref(
          this.rtdb,
          `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Infos/memberCount`
        )
      )
      if (!memberCountSnap.exists()) {
        // blocked from joining, show error message
        loadingDialogRef.close()
        this.oneButtonDialogService.show(
          'Error occurred',
          'Loading information has failed (error-1).'
        )
        return
      } else {
        const memberCount = memberCountSnap.val()

        if (memberCount > this.MAX_MEMBER_COUNT) {
          // too many members
          loadingDialogRef.close()
          this.oneButtonDialogService.show(
            'Group is full',
            'You cannot join since this group has reached the limit of ' +
              this.MAX_MEMBER_COUNT +
              ' members.'
          )
          return
        } else {
          // check if already a member
          const areYouMemberSnap = await get(
            ref(
              this.rtdb,
              `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Mitglieder/${this.userID}`
            )
          )
          const areYouMember = areYouMemberSnap.exists()

          if (areYouMember) {
            // open chat via url
            loadingDialogRef.close()
            this.router.navigateByUrl('/message/' + groupID)
          } else {
            // ask if wants to join
            // load group name
            let groupName = '[loading-failed]'
            const groupNameSnap = await get(
              ref(
                this.rtdb,
                `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Infos/Name`
              )
            )
            if (groupNameSnap.exists()) {
              groupName = groupNameSnap.val()
            }

            loadingDialogRef.close()
            this.twobuttonsdialogService.show(
              'Join group',
              'Do you want to join this group: ' + groupName,
              () => {},
              async () => {
                // join the group and open chat afterwards
                const stamp = Date.now()

                await set(
                  ref(
                    this.rtdb,
                    `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Mitglieder/${this.userID}`
                  ),
                  0
                )
                await set(
                  ref(
                    this.rtdb,
                    `${StrHlp.CLOUD_PATH}/ChatUebersichtListe/${this.userID}/${groupID}`
                  ),
                  -stamp
                )

                // increase member count
                await set(
                  ref(
                    this.rtdb,
                    `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Infos/memberCount`
                  ),
                  increment(1)
                )

                // also in firestore if its a public group
                const isPublicSnap = await get(
                  ref(
                    this.rtdb,
                    `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Infos/Public`
                  )
                )
                let isPublic = false
                if (isPublicSnap.exists()) {
                  isPublic = isPublicSnap.val()
                }

                if (isPublic) {
                  const fs = getFirestore()
                  const ref = doc(
                    fs,
                    `${StrHlp.CLOUD_PATH}/Discover/GroupChats/${groupID}`
                  )
                  await updateDoc(ref, {
                    memberCount: firebase.firestore.FieldValue.increment(1)
                  })
                }

                // disregard "show-Joined-Left".
                // This is disabled as it only brings boring noise to the chat room.

                // open chat
                this.router.navigateByUrl('/message/' + groupID)
              },
              'Cancel',
              'Yes'
            )
          }
        }
      }
    }
  }

  async removeFromGroup(groupID: string, userID: string) {
    // decr members count
    await set(
      ref(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Infos/memberCount`
      ),
      increment(-1)
    )

    // actual removing
    await remove(
      ref(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Mitglieder/${userID}`
      )
    )

    // remove chatUeb stuff for the user
    // -- not working because of permission issue, fix by moving to cloud --
    //await remove(ref(this.rtdb, `${StrHlp.CLOUD_PATH}/ChatUebersichtListe/${userID}/${groupID}`));

    // check if group is a public group
    // if so, also decrease the count in firestore
    const publicSnap = await get(
      ref(this.rtdb, `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Infos/Public`)
    )
    if (publicSnap.exists()) {
      const isPublic = publicSnap.val()

      if (isPublic) {
        const fs = getFirestore()
        const ref = doc(
          fs,
          `${StrHlp.CLOUD_PATH}/Discover/GroupChats/${groupID}`
        )
        await updateDoc(ref, {
          memberCount: firebase.firestore.FieldValue.increment(-1)
        })
      }
    }

    // if there is an invite link, add the user to the blocked users list
    const invLinkSnap = await get(
      ref(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Infos/inviteLink`
      )
    )
    if (invLinkSnap.exists()) {
      const invLinkID = invLinkSnap.val()
      await set(
        ref(
          this.rtdb,
          `${StrHlp.CLOUD_PATH}/GroupInviteLinks/${invLinkID}/BlockedUIDs/${userID}`
        ),
        true
      )
    }
  }

  async makeAdmin(groupID: string, userID: string) {
    await set(
      ref(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Mitglieder/${userID}`
      ),
      1
    )
  }
}
