import { Injectable } from '@angular/core'
import { HotToastService } from '@ngneat/hot-toast'
import { OnedialogserviceService } from '../dialogs/onedialogservice.service'
import { LocalstorageService } from '../ssr/localstorage.service'

@Injectable({
  providedIn: 'root'
})
export class GifkeyboarddataService {
  public featuredGIFs: any[] = []
  public searchResults: any[] = []

  public emptyFavorites = false
  public favorites: any[] = []

  public emptyRecent = false
  public recentGIFs: any[] = []

  public nextVal_Featured: string | null = null

  private storageKey_lastChosenTab = 'storageKey_lastChosenTab'
  public currTab = 0 // default: search

  constructor(
    private toast: HotToastService,
    private oneButtonDialogService: OnedialogserviceService,
    private localstorageService: LocalstorageService
  ) {
    this.loadChosenTab()
    this.loadRecentGIFs()
    this.loadFavoriteGIFs()
  }

  /**
   * we persist the tab choice
   */
  loadChosenTab() {
    const val = this.localstorageService.getItem(this.storageKey_lastChosenTab)
    if (val) {
      this.currTab = +val
    }
  }

  /**
   *
   * @param inp 0 = search/featured, 1 = recent, 2 = favorites
   */
  persistTabChoice(inp: number) {
    if (inp < 0 || inp > 2) {
      console.error('persistTabChoice failed because bad input')
      return
    }

    this.localstorageService.setItem(this.storageKey_lastChosenTab, inp + '')
  }

  /**
   * saves the URL of the gif only.
   */
  private storageKey_recent_gifs = '1Key_Recent_GIFs'
  private storageKey_favorite_gifs = '1Key_Favorite_GIFs'
  private RECENT_MAX_LENGTH_ALLOWED: number = 100
  private FAVORITE_MAX_LENGTH_ALLOWED: number = 200

  public addRecent(gifURL: string) {
    if (this.recentGIFs.length > this.RECENT_MAX_LENGTH_ALLOWED) {
      this.recentGIFs.pop()
    }

    const index = this.recentGIFs.indexOf(gifURL)
    if (index > -1) {
      // meaning:
      // the list already contains the GIF
      // Instead of just adding it, we will remove it from its position
      // and put it at the very beginning
      this.recentGIFs.splice(index, 1)
    }

    // add locally to the very beginning
    this.recentGIFs.unshift(gifURL)
    this.emptyRecent = false

    // persist
    this.persistRecentGIFs()
  }

  private persistFavoriteGIFs(): void {
    this.localstorageService.setItem(
      this.storageKey_favorite_gifs,
      JSON.stringify(this.favorites)
    )
  }

  public addFavorite(gifURL: string) {
    if (this.favorites.length > this.FAVORITE_MAX_LENGTH_ALLOWED) {
      this.oneButtonDialogService.show(
        'Failed',
        `You can have no more than ${this.FAVORITE_MAX_LENGTH_ALLOWED} favorites. Please remove other GIFs first in order to continue. To remove a GIF from your favorits, long tap on it.`
      )
      return
    }

    if (this.isGifFavorized(gifURL)) {
      this.toast.show('This GIF is already in your favorites')
    } else {
      // add locally to the very beginning
      this.favorites.unshift(gifURL)
      this.emptyFavorites = false

      // persist
      this.persistFavoriteGIFs()

      // toast
      this.toast.success('Added to favorites')
    }
  }

  public removeFavorite(gifURL: string) {
    const index = this.favorites.indexOf(gifURL)
    if (index > -1) {
      this.favorites.splice(index, 1)

      // persist
      this.persistFavoriteGIFs()

      // UI
      this.toast.success('Removed from favorites')

      // update empty var
      this.emptyFavorites = this.favorites.length == 0
    } else {
      this.toast.error('Error occurred')
    }
  }

  private persistRecentGIFs(): void {
    this.localstorageService.setItem(
      this.storageKey_recent_gifs,
      JSON.stringify(this.recentGIFs)
    )
  }

  private loadRecentGIFs(): void {
    const storedStrings = this.localstorageService.getItem(
      this.storageKey_recent_gifs
    )
    this.recentGIFs = storedStrings ? JSON.parse(storedStrings) : []

    this.emptyRecent = this.recentGIFs.length == 0
  }

  private loadFavoriteGIFs(): void {
    const storedStrings = this.localstorageService.getItem(
      this.storageKey_favorite_gifs
    )
    this.favorites = storedStrings ? JSON.parse(storedStrings) : []

    this.emptyFavorites = this.favorites.length == 0
  }

  isGifFavorized(gifURL: string): boolean {
    return this.favorites.indexOf(gifURL) > -1
  }
}
