import { Injectable } from '@angular/core'
import { Observable, Subject, throttleTime } from 'rxjs'
import { ScreenOverlay } from '../../datatypes/screen-overlay'

@Injectable({
  providedIn: 'root'
})
export class RootStateService {
  private addingThrottleTime = 300

  private nextOverlaySubject = new Subject<ScreenOverlay>()
  overlays$: Observable<ScreenOverlay>

  constructor() {
    this.overlays$ = this.nextOverlaySubject.pipe(
      throttleTime(this.addingThrottleTime)
    )
  }

  addOverlay(screenOverlay: ScreenOverlay) {
    this.nextOverlaySubject.next(screenOverlay)
  }
}
