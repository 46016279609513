import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { getDatabase, ref } from 'firebase/database'
import { HTMLFormattingService } from 'src/app/shared/services/formatting/html/htmlformatting.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { KeyHelperService } from 'src/app/shared/services/firebase/keyhelper.service'
import { MatDialog } from '@angular/material/dialog'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { EncodingService } from 'src/app/shared/services/encoding/encoding.service'
import { getFirestore } from 'firebase/firestore'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { FullscreenService } from 'src/app/shared/image/fullscreen.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { FeeddataService } from 'src/app/shared/services/data/feeddata.service'
import { CommentpagetemplateComponent } from '../templates/commentpagetemplate/commentpagetemplate.component'
import { DatasharingService } from 'src/app/shared/services/data/datasharing.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

/*
 * MAKE LOADING FASTER BY USING PROMISE.ALL
 * THOSE THAT ARE NOT REQUIRED FOR OTHERS TO LOAD SHOULD BE BUNDLED
 */
@Component({
  selector: 'app-single-post',
  templateUrl: './single-post.component.html',
  styleUrls: ['./single-post.component.css', '../feed/feed.component.css']
})
export class SinglePostComponent implements OnInit {
  diffThresholdForReachedBottom_comments: number = 10

  metaIsFinished: boolean = false
  sendingCommentInProgress: boolean = false

  userID: any = null
  postID: any = null
  postIDs: any[] = []

  post: any = null

  rtdb = ref(getDatabase())
  dbRTDB = getDatabase()
  db = getFirestore()

  @ViewChild('commentpage') commentpage!: CommentpagetemplateComponent
  @ViewChild('wrapper', { static: true }) wrapper!: ElementRef

  public showBackButton: boolean = this.datasharingService.hasRoutingHappened()

  constructor(
    private route: ActivatedRoute,
    public numberFormatService: NumberFormatService,
    public htmlFormattingService: HTMLFormattingService,
    public keyHelperService: KeyHelperService,
    public dialog: MatDialog,
    public imgHlp: ImageLoadingService,
    public encodingService: EncodingService,
    public authService: AuthService,
    public fullscreenHelper: FullscreenService,
    public routingHelper: RoutinghelperService,
    public datasharingService: DatasharingService,
    public feedDataService: FeeddataService,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.userID = AuthService.getUID()

    this.post = this.route.snapshot.data['postData']

    if (this.post) {
      this.doSEO()
    } else {
      // ?
    }

    // set up scrolling listener
    /*
    this.wrapper.nativeElement.addEventListener("scroll", () => {
      const scrollTop = this.wrapper.nativeElement.scrollTop;
      const offsetHeight = this.wrapper.nativeElement.offsetHeight;

      const scrollHeight = this.wrapper.nativeElement.scrollHeight;
      const diff1 = (scrollHeight - offsetHeight);
      
      //console.log(`diff: ${diff1 - scrollTop} since...: diff1: ${diff1}, scrollTop: ${scrollTop}`);

      if (diff1 - scrollTop <= this.diffThresholdForReachedBottom_comments) {
        //console.log('Reached bottom... Load new items.');
        //this.commentpage.loadItems();
      }
    });
    */
  }

  doSEO() {
    this.seoHelper.setPost(
      this.postID,
      this.imgHlp.do(this.post.imagePath, 1100),
      this.post.vid,
      this.post.caption,
      this.post.username,
      this.post.fullname
    )
  }
}
