import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { StrHlp } from '../../shared/services/StringGetter/getstring.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['../settings/settingspage/settings.component.css']
})
export class PrivacyComponent implements OnInit {
  companyName = 'SocialHubs UG (haftungsbeschränkt)'
  companyEmail = 'app.hub.app@gmail.com'

  @ViewChild('textwrapper') textwrapper!: ElementRef

  constructor(
    public StrHlp: StrHlp,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage(
      'Privacy Policy',
      'We use reasonable measures to protect information from loss, alteration, misuse, unauthorized access, and more.'
    )
  }

  ngAfterViewInit(): void {
    this.setUpText()
  }

  setUpText(): void {
    let text: string = this.textwrapper.nativeElement.innerText
    console.log(text)
    text = text
      .replaceAll('%1$s', this.companyName)
      .replaceAll('%2$s', this.companyEmail)
    console.log(text)
    this.textwrapper.nativeElement.innerText = text
  }
}
