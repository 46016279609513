import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../shared/services/auth/auth.service'
import { StrHlp } from '../../shared/services/StringGetter/getstring.service'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { Subscription } from 'rxjs'
import { ActivatedRoute } from '@angular/router'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['../sign-in/sign-in.component.css']
})
export class SignUpComponent implements OnInit {
  formGroup

  emailControl = new FormControl('', [Validators.required, Validators.email])

  passwordControl = new FormControl('', [
    Validators.required,
    Validators.minLength(4)
  ])

  agreedControl = new FormControl(false, [Validators.required])

  indicateLoading: boolean = false

  errorHint: string = ''

  passwordVisible: boolean = false
  agreedToTerms: boolean = false

  formGroupSubscription: Subscription | null = null

  redirect = ''
  doRedirect = false

  constructor(
    public authService: AuthService,
    public StrHlp: StrHlp,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private seoHelper: SeoHelperService
  ) {
    this.formGroup = this.builder.group({
      email: this.emailControl,
      password: this.passwordControl,
      agreed: this.agreedControl
    })
  }

  ngOnInit(): void {
    this.seoHelper.setForSomePage(
      'Sign up',
      `Sign up for an account on ${StrHlp.APP_NAME}. ${this.seoHelper.getGenericDesc()}`
    )

    this.route.queryParams.subscribe((params) => {
      const redirectReceived = params['redirect']
      if (typeof redirectReceived === 'undefined') {
        this.redirect = '' // default
        this.doRedirect = false
      } else {
        this.redirect = redirectReceived
        this.doRedirect = true
      }
    })

    /*
    this.formGroupSubscription = this.formGroup.valueChanges.subscribe(val => {
      
    });
    */
  }

  changePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible
  }

  register(): void {
    // check if agrees terms
    if (!this.formGroup.value.agreed) {
      this.errorHint = 'You must agree to the terms in order to continue'
      return
    }

    this.indicateLoading = true

    this.authService.signUp(
      this.formGroup.value.email!,
      this.formGroup.value.password!,
      this.redirect,
      (msg: string) => {
        this.indicateLoading = false
      }
    )
  }

  ngOnDestroy() {
    this.formGroupSubscription?.unsubscribe()
  }
}
