import { Pipe, PipeTransform } from '@angular/core'
import { NumberFormatService } from '../shared/services/formatting/number/numberformat.service'

@Pipe({
  standalone: true,
  name: 'numberCommaFormat'
})
export class NumberCommaFormatPipe implements PipeTransform {
  constructor(private numberFormatService: NumberFormatService) {}

  transform(x: unknown, ...args: unknown[]): string {
    return this.numberFormatService.numberWithCommas(x)
  }
}
