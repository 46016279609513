import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '../services/auth/auth.service'
import { StrHlp } from '../services/StringGetter/getstring.service'
import { Dialog } from '@angular/cdk/dialog'
import { NsfwConfirmationComponent } from 'src/app/components/nsfw-confirmation/nsfw-confirmation.component'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { LocalstorageService } from '../services/ssr/localstorage.service'

@Injectable({
  providedIn: 'root'
})

/**
 * checks if is confirmed already (for logged out users only), if not,
 * allow navigation still, but show a dialog.
 */
export class NsfwConfirmGuardGuard {
  constructor(
    private dialog: MatDialog,
    private localstorageService: LocalstorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (AuthService.isUserLoggedIn()) {
      return true
    } else {
      if (StrHlp.ALLOWS_NSFW) {
        const agreedToNSFW =
          this.localstorageService.getItem('agreedToNSFW') !== null &&
          this.localstorageService.getItem('agreedToNSFW') === 'true'

        if (agreedToNSFW) {
          return true
        } else {
          // show dialog
          const dialogConfig = new MatDialogConfig()

          dialogConfig.maxWidth = '100vw'
          dialogConfig.maxHeight = '100vh'
          dialogConfig.width = '100vw'
          dialogConfig.height = '100vh'
          dialogConfig.panelClass = 'no-max-width'
          dialogConfig.backdropClass = 'bdrop'

          this.dialog.open(NsfwConfirmationComponent, dialogConfig)

          return true
        }
      } else {
        return false
      }
    }
  }
}
