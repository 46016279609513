import { Injectable } from '@angular/core'
import { FeedComponent } from 'src/app/components/feed/feed.component'
import { InteractionhelperService } from '../interaction/interactionhelper.service'
import { child, get, getDatabase, ref } from 'firebase/database'
import { StrHlp } from '../StringGetter/getstring.service'
import { AuthService } from '../auth/auth.service'
import { CacheService } from '../caching/cache-service.service'
import { FeedadsService } from '../ads/feedads.service'
import { Observable, take } from 'rxjs'

@Injectable({
  providedIn: 'root'
})

/**
 * For the sake of saving time, the loading logic stays in the feed component (todo)
 *
 * How this works: We want all feed tabs (3 in total - or 4 for anime), to stay "loaded", i.e.
 * switching the component and switching back will not reload the posts.
 * Hence only scrolling will (once init-loaded).
 *
 * When feed comp. attempts a "load" of posts after being loaded (so not scrolled), it firsts
 * checks if the type is one of the types caught by this service, and if so, it first checks if there
 * are posts saved here. If so, they get copied the array there. If not, it attempts a new load bcs maybe
 * there are posts now (either not init yet or empty).
 * Upon each new posts-load from the feed comp., if the type is caught here, it appends them to
 * the corresponding array here as well.
 *
 * NOTE:
 * ALL code related to this is BAD BAD style. TODO!
 */
export class FeeddataService {
  public fyList: any[] = []
  public followingList: any[] = []
  public recentList: any[] = []
  public nsfwList: any[] = []
  public recentNsfwList: any[] = []
  public clipsList: any[] = []
  public clipsListNsfw: any[] = []

  public latestScore_FY = Number.MAX_VALUE
  public latestScore_Following = Number.MAX_VALUE
  public latestScore_Recent = Number.MAX_VALUE
  public latestScore_Nsfw = Number.MAX_VALUE
  public latestScore_NsfwRecent = Number.MAX_VALUE
  public latestScore_Clips = Number.MAX_VALUE
  public latestScore_Clips_Nsfw = Number.MAX_VALUE

  // the list that is currently shown / used
  public postList: any[] = []
  public feedType: number = -1

  userID: any = null
  rtdb = ref(getDatabase())

  constructor(private feedadsService: FeedadsService) {
    this.userID = AuthService.getUID()
  }

  public clearShownPostList() {
    this.postList.length = 0
    this.updateForRecycling()
  }

  public updateForRecycling() {
    this.postList = [...this.postList]
  }

  /*
   * @param type 0 = fy
   *              1 = following
   *              2 = recent
   *              3 = nsfw
   *              4 = recent nsfw
   *              5 = clips
   *              6 = clips nsfw
   */
  public updateLatestScore(score: number, type: number) {
    if (type < 0 || type > 6) {
      return
    }

    if (type == 0) {
      this.latestScore_FY = score
    } else if (type == 1) {
      this.latestScore_Following = score
    } else if (type == 2) {
      this.latestScore_Recent = score
    } else if (type == 3) {
      this.latestScore_Nsfw = score
    } else if (type == 4) {
      this.latestScore_NsfwRecent = score
    } else if (type == 5) {
      this.latestScore_Clips = score
    } else if (type == 6) {
      this.latestScore_Clips_Nsfw = score
    }
  }

  /**
   *
   * @param list the post
   * @param type see above function
   */
  public appendNewPost(post: any, type: number, blockAdInserting = false) {
    // append to list
    this.postList.push(post)

    // append to storage list
    if (type != -1) {
      if (type < 0 || type > 6) {
        console.log("appendNewPosts: Bad input for arg 'number'")
        return
      }

      if (type == 0) {
        this.fyList.push(post)
      } else if (type == 1) {
        this.followingList.push(post)
      } else if (type == 2) {
        this.recentList.push(post)
      } else if (type == 3) {
        this.nsfwList.push(post)
      } else if (type == 4) {
        this.recentNsfwList.push(post)
      } else if (type == 5) {
        this.clipsList.push(post)
      } else if (type == 6) {
        this.clipsListNsfw.push(post)
      }
    }

    if (!blockAdInserting) {
      // hookup here:
      // check if ads should be added
      const obj = this.feedadsService.add()
      if (obj !== null) {
        this.appendNewPost(obj, type, true)
      }
    }

    this.updateForRecycling()
  }

  /**
   * For furtherInfos of the post
   *
   * @param type see above funcs
   */
  public updatePost(index: number, post: any, type: number) {
    // update post
    this.postList[index] = post

    // update storage list
    if (type != -1) {
      if (type < 0 || type > 6) {
        console.log("appendNewPosts: Bad input for arg 'number'")
        return
      }

      if (type == 0) {
        this.fyList[index] = post
      } else if (type == 1) {
        this.followingList[index] = post
      } else if (type == 2) {
        this.recentList[index] = post
      } else if (type == 3) {
        this.nsfwList[index] = post
      } else if (type == 4) {
        this.recentNsfwList[index] = post
      } else if (type == 5) {
        this.clipsList[index] = post
      } else if (type == 6) {
        this.clipsListNsfw[index] = post
      }
    }
  }

  async easyLoadPostInfo(index: number) {
    // update post info and also make "new list" for CD
    const newPost = await loadFurtherPostInfo(this.postList[index])

    if (newPost) {
      this.postList[index] = newPost
      this.updateForRecycling()
    }
  }

  /**
   *
   * REWRITE:
   * ASYNC. DECOUPLE FROM THE LIST IN HERE. ASYNC SO WE CAN USE AWAIT, IMPORTANT FOR SSR
   */
  loadFurtherPostInfo_old(index: number): void {
    const post = this.postList[index]

    // trigger view
    if (AuthService.isUserLoggedIn()) {
      InteractionhelperService.triggerPostView(post.postID)
    }

    if (AuthService.isUserLoggedIn()) {
      // bookmarked
      get(
        child(
          this.rtdb,
          `${StrHlp.CLOUD_PATH}/Bookmarks/${this.userID}/Posts/${post.postID}`
        )
      )
        .then((snapshot) => {
          if (snapshot.exists()) {
            const isBookmarked = snapshot.val()

            // create new object because of OnPush CD
            const newPost = { ...post, interaction_isBookmarked: isBookmarked }

            // update post info and also make "new list" for CD
            this.postList[index] = newPost
            this.updateForRecycling()
          }
        })
        .catch((error) => {
          console.error(error)
        })

      // liked
      get(
        child(
          this.rtdb,
          `${StrHlp.CLOUD_PATH}/PhotoInteraction/${post.postID}/Likes/${this.userID}`
        )
      )
        .then((snapshot) => {
          if (snapshot.exists()) {
            const isLiked = snapshot.val()

            // create new object because of OnPush CD
            const newPost = { ...post, interaction_isLiked: isLiked }

            // update post info and also make "new list" for CD
            this.postList[index] = newPost
            this.updateForRecycling()
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  getPostByID(postID: string): any {
    for (let i = 0; i < this.postList.length; i++) {
      if (this.postList[i].postID === postID) {
        return this.postList[i]
      }
    }
    return null
  }
}

export async function loadFurtherPostInfo(post: any) {
  const rtdb = ref(getDatabase())
  const myUid = AuthService.getUID()

  // create new object because of OnPush CD
  const returnedPost = { ...post }

  if (myUid) {
    try {
      // trigger view
      InteractionhelperService.triggerPostView(post.postID)

      // bookmarked
      const snapshotBookmarked = await get(
        child(
          rtdb,
          `${StrHlp.CLOUD_PATH}/Bookmarks/${myUid}/Posts/${post.postID}`
        )
      )

      if (snapshotBookmarked.exists()) {
        const isBookmarked = snapshotBookmarked.val()
        returnedPost.interaction_isBookmarked = isBookmarked
      }

      // liked
      const snapshotLiked = await get(
        child(
          rtdb,
          `${StrHlp.CLOUD_PATH}/PhotoInteraction/${post.postID}/Likes/${myUid}`
        )
      )
      if (snapshotLiked.exists()) {
        const isLiked = snapshotLiked.val()
        returnedPost.interaction_isLiked = isLiked
      }
    } catch (error) {
      console.error(error)
    }
  }
  return returnedPost
}
