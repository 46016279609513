import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-donationamountchoose',
  templateUrl: './donationamountchoose.component.html',
  styleUrls: ['./donationamountchoose.component.css']
})
export class DonationamountchooseComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data !== undefined) {
      this.callback = data.callback
    }
  }

  callback: (amount: number) => void = (arg0: number) => {}

  donate(amount: number) {
    this.callback(amount)
  }
}
