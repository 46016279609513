import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ThreeButtonsDialogComponent } from 'src/app/components/dialogs/three-buttons-dialog/three-buttons-dialog.component'

@Injectable({
  providedIn: 'root'
})
export class ThreebuttonsdialogService {
  constructor(public dialog: MatDialog) {}

  show(
    title: string,
    desc: string,
    click1Callback: () => any,
    click2Callback: () => any,
    click3Callback: () => any,
    button1Text: string,
    button2Text: string,
    button3Text: string,
    cancelable: boolean = true
  ) {
    const data = {
      title: title,
      desc: desc,
      clickCallbackButton1: click1Callback,
      clickCallbackButton2: click2Callback,
      clickCallbackButton3: click3Callback,
      button1Text: button1Text,
      button2Text: button2Text,
      button3Text: button3Text
    }

    this.dialog.open(ThreeButtonsDialogComponent, {
      disableClose: !cancelable,
      autoFocus: false,
      data: data
    })
  }
}
