<a
  class="subtle-button explore-item-wrapper no-decor explore-user-container"
  routerLink="/@{{ username }}"
  role="link"
  attr.aria-label="{{ username }} profile"
>
  <img
    src="{{
      profileImage
        ? (profileImage | imgScaleHelper: 50)
        : '/assets/default_profile_pic.jpg'
    }}"
    class="profile-image profile-image-size"
    onerror="src='/assets/default_profile_pic.jpg';onerror='';"
    alt="{{ username }} profile"
  />

  <app-usernametemplate
    [values]="{
      userID: userID,
      nameInp: username,
      createAsDiv: true
    }"
  />
</a>
