import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core'
import { HotToastService } from '@ngneat/hot-toast'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { KeyHelperService } from 'src/app/shared/services/firebase/keyhelper.service'
import { HTMLFormattingService } from 'src/app/shared/services/formatting/html/htmlformatting.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import {
  child,
  get,
  getDatabase,
  increment,
  limitToFirst,
  onChildAdded,
  onChildChanged,
  onValue,
  orderByKey,
  push,
  query,
  ref,
  remove,
  serverTimestamp,
  set,
  startAt
} from 'firebase/database'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { TimeLimitsService } from 'src/app/shared/services/timelimits/timelimits.service'
import firebase from 'firebase/compat/app'
import { MainComponent } from '../main/main.component'
import { ActivatedRoute, Router } from '@angular/router'
import { LastseenService } from 'src/app/shared/services/firebase/lastseen.service'
import {
  getStorage,
  uploadString,
  ref as refStorage,
  getDownloadURL
} from 'firebase/storage'
import { MatDialog } from '@angular/material/dialog'
import { FullscreenService } from 'src/app/shared/image/fullscreen.service'
import { LoadingDialogComponent } from '../dialogs/loading-dialog/loading-dialog.component'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { OnedialogserviceService } from 'src/app/shared/services/dialogs/onedialogservice.service'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { ChatDataService } from 'src/app/shared/services/data/chatdata.service'
import { MuteUsersService } from 'src/app/shared/services/muteusers.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { TimeService } from 'src/app/shared/services/time/time.service'
import { type DatabaseReference } from '@firebase/database'
import { ChatadsService } from 'src/app/shared/services/ads/chatads.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { ThreebuttonsdialogService } from 'src/app/shared/services/dialogs/threebuttonsdialogservice.service'
import { BunnyserviceService } from 'src/app/shared/services/uploading/bunnyservice.service'
import { ImageuploadtemplateComponent } from '../templates/upload/imageuploadtemplate/imageuploadtemplate.component'
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import { KeyboardService } from 'src/app/shared/services/overlay/keyboard-service.service'
import { Observable, map, of, startWith, take } from 'rxjs'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { LocalstorageService } from 'src/app/shared/services/ssr/localstorage.service'
import { SetTimeoutService } from 'src/app/shared/services/ssr/set-timeout.service'
import { IsBrowserService } from 'src/app/shared/services/ssr/isbrowser.service'
import { GLOBAL_CHAT_ID } from 'src/app/shared/constants'

const MESSAGE_HIGHLIGHTED_DURATION = 1300

@Component({
  selector: 'app-chat-page',
  templateUrl: './chat-page.component.html',
  styleUrls: ['./chat-page.component.css']
})
export class ChatPageComponent {
  static MAX_MESSAGE_LENGTH: number = 2000

  static get GLOBAL_CHATLIST_OBJ() {
    return {
      name: 'Global Chat',
      image: '/assets/favico.png',
      verified: false,
      isMeUser1: false,
      otherUserID: '',
      isPrivate: false,
      isGroup: true,
      groupID: GLOBAL_CHAT_ID,
      chatID: GLOBAL_CHAT_ID,
      lastMessage: '',
      groupLastMessageUsername: 'username',
      wasLastMessageByMe: false,
      newMessagesCount: 0,
      lastMessageUID: '',
      lastMessageTimestamp: 0
    }
  }

  maxMsgLength = ChatPageComponent.MAX_MESSAGE_LENGTH

  LOAD_X_MESSAGES = 30
  LOAD_X_MESSAGES_ONSCROLL = 20

  @ViewChild('wrapper') wrapper!: ElementRef
  @ViewChild('addImageButton_Hidden') addImageButton_Hidden!: ElementRef
  @ViewChild('messageinput') messageinput!: ElementRef
  @ViewChild('scrollContainer') scrollContainer!: CdkVirtualScrollViewport

  isDarkmode: boolean = false

  diffThresholdForReachedBottom: number = 20

  chatName: string = ''
  dataReceived: any = null
  chatID: string = ''
  verified: boolean = false
  chatImage: string = ''
  isGroup: boolean = false
  isPrivate: boolean = false
  otherUserID: string = ''
  isMeUser1: boolean = false
  isGlobalChat: boolean = false
  newMessagesCount: number = 0
  chatOK1: boolean = false
  chatOK2: boolean = false

  isInputBarUntouched = true
  isSendingMessagePossible = false
  shouldFadeOutSendingButton = false
  shouldFadeInSendingButton = false

  lastVal_ScrollTop = 0
  messagesCount_OpeningTime = 0

  currentlyLoadingOnScroll: boolean = false

  allow_UploadMediaButton: boolean = false
  showUploadMediaButton_Determined: boolean = false

  // reply privately feature
  replyPrivately_replyMessageFrom: string = ''
  replyPrivately_replyMessage: string = ''

  replyText: string = ''
  replyMessageID: string = ''
  imageUploadedAsString: string = ''
  videoFile: any = null
  videoDuration: number = 0

  showEmojiPicker = false
  showGifsPicker = false

  messageEntered: string = ''
  areYouBlocked: boolean = false
  areYouBlocked_Determined: boolean = true
  empty: boolean = false
  empty_Confirmed: boolean = false // just for technical details, but is important
  messageSendingInProgress: boolean = false

  /**
   * aspect ratio of the medium to be uploaded (gif or image at the moment)
   * Used to prevent cumulative layout shift (CLS)
   */
  medAR = 0
  medW = 0
  medH = 0

  emptyGifsList = [
    'https://media.tenor.com/YiKncPT-moEAAAAj/astronaut-spacesuit.gif',
    'https://media.tenor.com/F5HuMYvDP3oAAAAj/puppy.gif',
    'https://media.tenor.com/Su_9gel-FTcAAAAj/excited-puppy.gif',
    'https://media.tenor.com/FPOvHOyWZZcAAAAj/cute-blinking-puppy.gif',
    'https://media.tenor.com/Q5xwRQjMg8EAAAAj/%E5%93%88%E5%9B%89-hello.gif',
    'https://media.tenor.com/A0FkICorh-EAAAAj/bt21-hello.gif',
    'https://media.tenor.com/BVBgvydKE4gAAAAj/hello-yellow-bird.gif',
    'https://media.tenor.com/WK6wYXKMS4EAAAAj/hello-wave.gif',
    'https://media.tenor.com/qjzkA3-z4cIAAAAj/hug-hi.gif',
    'https://media.tenor.com/or7dYahB5t8AAAAj/pikachu-pokemon.gif',
    'https://media.tenor.com/vIM-SxPXzdsAAAAj/koala-hi.gif',
    'https://media.tenor.com/BL1LStOEOE4AAAAj/cute-koala.gif',
    'https://media.tenor.com/mcUCl5IrbPgAAAAj/because-baby-animals-cute.gif',
    'https://media.tenor.com/M6wcc7tZQn0AAAAj/cute-cat.gif',
    'https://media.tenor.com/OF9BjNQsOfAAAAAj/tiger.gif',
    'https://media.tenor.com/eknIYgMj1UkAAAAj/tiger-giphy.gif'
  ]
  emptyGifURL = this.emptyGifsList[0]

  alwaysListenersRef_List: any[] = []

  quickInfoString: string = ''

  // init as true so it scrolls down
  scroll_isAtBottom: boolean = true
  shouldBottomScrollButtonDisappear = false
  newMessagesToDiscover: number = 0

  onScrollLastMessageKey: string | null = null
  disableOnScrollLoading: boolean = false

  isMeTyping: boolean = false
  lastTypingTimerID: any = null

  lastMessageClick_TimerID: any = null
  hasOtherUserSeenLastMessage: boolean = false

  userID: any = null
  dbRTDB = getDatabase()

  itemList: any[] = []

  myUsername: string = ''

  dateIndicatorString: string = ''
  showDateIndicator: boolean = false
  fadeOutDateIndicator: boolean = false

  lastDateTimerID: any = null
  dateAtTopTimeout: any
  dateAtTop_Timeout_Delay: number = 800

  DM_Restriction_Error___TooNew: boolean = false

  lastMsgTimestamp = 0

  forward_Message = ''
  forward_ImageURL = ''
  forward_GifURL = ''
  forwardVideoID = ''
  forwardVideoDuration = 0

  msg_draft_key: string = 'msg_draft_key'
  draftMsg: string | null = null

  isMobile: boolean = false

  lastAddedTimestamp: number = 0

  // bad style code for: its a gif in timestamp determinatiom
  public static gifDetection_Duration_Code = -789 // negative normally not possible, so its a "safe operation"

  replyClickCallback: (quotedMessageID: string, event: any) => void
  setReplyTextCallback: (item: any) => void
  mentionUserCallback: (item: any) => void
  quoteMessageCallback: (item: any) => void
  askMuteCallback: (item: any) => void
  editMessageCallback: (item: any, newText: string) => void
  askDeleteMessageCallback: (item: any) => void

  onGifSelectedCallback: (url: string, aspectRatio: number) => void

  openProfileCallback: () => void = () => {
    this.openProfile()
  }

  // GROUP CHAT STUFF - DEPRECATED
  DEPRECATED_userID_List_Group: string[] = []
  areYouGroupAdmin = false
  // --

  private isChatMutedRef: DatabaseReference | null = null
  public isChatMuted = false // default

  image$?: Observable<string>
  messageCount$?: Observable<number>

  constructor(
    private route: ActivatedRoute,
    private toast: HotToastService,
    public htmlFormattingService: HTMLFormattingService,
    public numberFormatService: NumberFormatService,
    public keyHelperService: KeyHelperService,
    public imgHlp: ImageLoadingService,
    public authService: AuthService,
    public strHlp: StrHlp,
    private router: Router,
    private lastseenService: LastseenService,
    private dialog: MatDialog,
    public fullscreenHelper: FullscreenService,
    private oneButtonDialogService: OnedialogserviceService,
    private twobuttonsdialogService: TwobuttonsdialogService,
    public routingHelper: RoutinghelperService,
    public chataDataService: ChatDataService,
    private renderer: Renderer2,
    private chatadsService: ChatadsService,
    private cacheService: CacheService,
    private threebuttonsdialogService: ThreebuttonsdialogService,
    private muteUsersService: MuteUsersService,
    private bunnyService: BunnyserviceService,
    private keyboardService: KeyboardService,
    private seoHelper: SeoHelperService,
    private localstorageService: LocalstorageService,
    private setTimeoutService: SetTimeoutService,
    private isBrowserService: IsBrowserService
  ) {
    this.replyClickCallback = (quotedMessageID: string, event: any) => {
      this.onReplyClick(quotedMessageID, event)
    }
    this.setReplyTextCallback = (item: any) => {
      this.setReplyText(item)
    }
    this.mentionUserCallback = (item: any) => {
      this.mentionUser(item)
    }
    this.onGifSelectedCallback = (url: string, aspectRatio: number) => {
      this.sendGif(url, aspectRatio)
    }
    this.quoteMessageCallback = (item: any) => {
      this.setReplyText(item)
    }
    this.askMuteCallback = (item: any) => {
      this.askMute(item)
    }
    this.editMessageCallback = (item: any, newText: string) => {
      this.startEditingMessage(item, newText)
    }
    this.askDeleteMessageCallback = (item: any) => {
      this.askDeleteMessage(item)
    }

    // check if url param chatID was passed
    const raw_ChatID: string | null = this.route.snapshot.paramMap.get('chatID')
    if (raw_ChatID !== null) {
      this.chatID = raw_ChatID
    }

    // This must be in the constructor, not later
    const currNav = this.router.getCurrentNavigation()

    if (currNav !== null) {
      this.dataReceived = currNav.extras.state
    }
  }

  /**
   * We didnt receive the info from dataReceived, but only chatID from url param.
   * So we need to load all the info. How it works:
   * We leverage chatData service and check if the chat is loaded already,
   * otherwise we add a callback when it finished init-loading.
   */
  initViaChatID() {
    let alreadyLoaded = false
    let chatData: any = null

    if (this.chatID === GLOBAL_CHAT_ID) {
      const obj = ChatPageComponent.GLOBAL_CHATLIST_OBJ

      alreadyLoaded = true
      chatData = obj
    } else {
      for (let i = 0; i < this.chataDataService.chatList.length; i++) {
        const obj = this.chataDataService.chatList[i]

        if (obj.chatID === this.chatID) {
          alreadyLoaded = true
          chatData = obj
          break
        }
      }
    }

    if (alreadyLoaded) {
      this.continueInitWithChatdata(chatData)
    } else {
      const callback = (chatData: any) => {
        this.continueInitWithChatdata(chatData)
      }
      this.chataDataService.setChatdataLoadedCallback(this.chatID, callback)
    }
  }

  continueInitWithChatdata(chatData: any) {
    console.log(chatData)

    this.chatName = chatData.name
    this.chatImage = chatData.image
    this.verified = chatData.verified
    this.isGroup = chatData.isGroup
    this.isPrivate = chatData.isPrivate
    this.otherUserID = chatData.otherUserID
    this.isMeUser1 = chatData.isMeUser1
    this.newMessagesCount = chatData.newMessagesCount
    this.chatOK1 = chatData.chatOK1
    this.chatOK2 = chatData.chatOK2

    this.setUpAfterDataInit()

    this.loadImageURL()
  }

  setUpAfterDataInit() {
    if (this.chatID === '') {
      this.toast.error('An error occurred')
      return
    }

    console.log(`Draugas1: setUpAfterDataInit for chatID: ${this.chatID}`)

    // "abuse" this function
    this.seoHelper.setGroupPage(this.chatID, 'Group chat', 'Group chat', '')

    this.userID = AuthService.getUID()
    this.showUploadMediaButton_Determined = true
    this.isGlobalChat = GLOBAL_CHAT_ID === this.chatID

    // either group/GC or other used must have marked chat as okay
    this.allow_UploadMediaButton =
      !this.isPrivate ||
      (this.chatOK1 && !this.isMeUser1) ||
      (this.chatOK2 && this.isMeUser1)

    // fix new message count if needed
    if (this.newMessagesCount === undefined) {
      this.newMessagesCount = 0
    }

    // check drafted msg
    this.draftMsg = this.localstorageService.getItem(this.msg_draft_key)
    if (this.draftMsg) {
      this.messageEntered = this.draftMsg
      this.onMessageAltered()
    }

    this.loadMyUsername()
    this.setUpSeenIndicator()
    this.setUpChatMutedStuff()
    this.loadLastSeen()
    this.setUpDragDrop()
    this.setUpHandleDocumentClick()

    // set up "actual" chat after checking if allowed
    this.checkIfAllowed()

    // group stuff (deprecated)
    if (this.isGroup && !this.isGlobalChat) {
      this.DEPRECATED_loadGroupMembers()
      this.loadIfGroupAdmin()
    }

    this.loadImageURL()
  }

  loadImageURL() {
    if (this.isGlobalChat) {
      this.image$ = of('/assets/favico.png')
    } else {
      if (this.chatImage && this.chatImage !== '') {
        this.image$ = of(this.imgHlp.do(this.chatImage, 50))
      } else {
        if (this.isGroup) {
          const defaultImage = '/assets/default_group_pic.jpg'

          this.image$ = this.cacheService.getGroupImage(this.chatID).pipe(
            map((img) => this.imgHlp.do(img, 50)),
            startWith(defaultImage)
          )
        } else {
          const defaultImage = '/assets/default_profile_pic.jpg'

          this.image$ = this.cacheService
            .getProfileImage(this.otherUserID)
            .pipe(
              map((img) => this.imgHlp.do(img, 50)),
              startWith(defaultImage)
            )
        }
      }
    }
  }

  setUpChatMutedStuff() {
    let chatMutedPath = ''
    if (this.isGroup) {
      chatMutedPath = this.chatID
    } else {
      chatMutedPath = this.otherUserID
    }

    this.isChatMutedRef = ref(
      this.dbRTDB,
      `${StrHlp.CLOUD_PATH}/UserSettings/${this.userID}/NotificationSettings/ChatsMuted/${chatMutedPath}`
    )

    const unsubCallback = onValue(this.isChatMutedRef, (snapshot) => {
      this.isChatMuted = snapshot.exists()
    })

    this.alwaysListenersRef_List.push(unsubCallback)
  }

  loadMyUsername(): void {
    get(
      ref(this.dbRTDB, `${StrHlp.CLOUD_PATH}/Users/${this.userID}/username`)
    ).then((snapshot) => {
      if (snapshot.exists()) {
        this.myUsername = snapshot.val()
      }
    })
  }

  ngAfterViewInit(): void {
    if (this.dataReceived === null && this.chatID === null) {
      this.toast.error('An error occurred')
      return
    }

    this.isMobile = SystemService.isMobile()
    this.isDarkmode = SystemService.isDarkmode()

    if (this.dataReceived) {
      // basic info

      this.chatID = this.dataReceived.chatID
      this.chatName = this.dataReceived.name
      this.chatImage = this.dataReceived.image
      this.verified = this.dataReceived.verified
      this.isGroup = this.dataReceived.isGroup
      this.isPrivate = this.dataReceived.isPrivate
      this.otherUserID = this.dataReceived.otherUserID
      this.isMeUser1 = this.dataReceived.isMeUser1
      this.newMessagesCount = this.dataReceived.newMessagesCount

      // forward stuff
      this.forward_Message = this.dataReceived.forwardMessage
      this.forward_ImageURL = this.dataReceived.forwardImageURL
      this.forward_GifURL = this.dataReceived.forwardGifURL
      this.forwardVideoID = this.dataReceived.forwardVideoID
      this.forwardVideoDuration = this.dataReceived.forwardVideoDuration
      console.log(this.dataReceived)

      // for reply-privately-feature
      if (this.dataReceived.replyMessageFrom !== undefined) {
        this.replyPrivately_replyMessageFrom =
          this.dataReceived.replyMessageFrom
      }
      if (this.dataReceived.replyMessage !== undefined) {
        this.replyPrivately_replyMessage = this.dataReceived.replyMessage
      }
      this.replyText = this.replyPrivately_replyMessage

      // Chat OK stuff
      if (this.dataReceived.chatOK1 !== undefined) {
        this.chatOK1 = this.dataReceived.chatOK1
      } else {
        this.chatOK1 = false // in case of groups and old-chats
      }
      if (this.dataReceived.chatOK2 !== undefined) {
        this.chatOK2 = this.dataReceived.chatOK2
      } else {
        this.chatOK2 = false // in case of groups and old-chats
      }
      //console.log(this.dataReceived);

      this.setUpAfterDataInit()
    } else {
      // i.e. chatID was passed as URL param, so we need to load the above data ourselves
      // as far as possible
      this.initViaChatID()
    }

    this.setUpScrollListener()
  }

  onSwipeRight(item: any) {
    this.setReplyText(item)
  }

  setUpSeenIndicator(): void {
    let refSeenIndicator
    if (this.isMeUser1) {
      refSeenIndicator = ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/ChatUebersicht/${this.chatID}/lastMessageSeenByUser2`
      )
    } else {
      refSeenIndicator = ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/ChatUebersicht/${this.chatID}/lastMessageSeenByUser1`
      )
    }

    const unsubCallback = onValue(refSeenIndicator, (snapshot) => {
      if (snapshot.exists()) {
        this.hasOtherUserSeenLastMessage = snapshot.val()
      }
    })
    this.alwaysListenersRef_List.push(unsubCallback)
  }

  setUpHandleDocumentClick() {
    // ssr-guarded
    if (typeof document === 'undefined') {
      return
    }

    const handleDocumentClick = (event: MouseEvent): void => {
      // Check if emoji keyboard is open, and if so, then close if the click is outside of it
      let isEmojiClassFound = false
      let isGifClassFound = false

      if (event && event.composedPath) {
        const path = event.composedPath()
        path.forEach((elem: EventTarget | null) => {
          if (elem && (elem as HTMLElement).classList) {
            const classList = (elem as HTMLElement).classList
            if (classList.contains('emoji-mart')) {
              isEmojiClassFound = true
            }
            if (classList.contains('gif-picker-class')) {
              isGifClassFound = true
            }
          }
        })
      }

      const targetID = (event.target as HTMLElement).id
      if (
        !isEmojiClassFound &&
        targetID !== 'emojiButtonWrapper' &&
        targetID !== 'emojiButtonImage'
      ) {
        this.hideEmojiKeyboard()
      }
      if (
        !isGifClassFound &&
        targetID !== 'gifButtonWrapper' &&
        targetID !== 'gifButtonImage'
      ) {
        this.hideGifsPicker()
      }
    }

    document.addEventListener('click', handleDocumentClick, false)
  }

  openSelectImage(): void {
    // check if allowed
    if (!this.showUploadMediaButton_Determined) {
      this.toast.error('Loading, please wait...')
      return
    }

    if (!this.allow_UploadMediaButton) {
      this.oneButtonDialogService.show(
        'Not allowed',
        `${this.chatName} hasn't marked the chat as 'OK' yet. Please ask the user to do so.`
      )
      return
    }

    this.addImageButton_Hidden.nativeElement.click()
  }

  setUpDragDrop(): void {
    this.wrapper.nativeElement.ondragover = function () {
      return false
    }
    this.wrapper.nativeElement.omdragend = function () {
      return false
    }

    this.wrapper.nativeElement.ondrop = (e: any) => {
      if (typeof window === 'undefined') {
        return
      }
      if (!window.FileReader) {
        // Browser is not compatible
        this.toast.error('Your browser is not compatible')
        return
      }

      e.preventDefault()
      this.readfiles(e.dataTransfer.files)
    }

    // also set up the click opening handling
    this.addImageButton_Hidden.nativeElement.onchange = (e: any) => {
      if (typeof window === 'undefined') {
        return
      }
      if (!window.FileReader) {
        // Browser is not compatible
        this.toast.error('Your browser is not compatible')
        return
      }

      e.preventDefault()
      this.readfiles(e.target.files)
    }
  }

  readfiles(files: any) {
    // ssr-guarded
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    if (files.length > 0) {
      const file = files[0]

      const type = file.type
      const bytes = file.bytes

      let isImage = type.startsWith('image/')
      let isVideo = type.startsWith('video/')
      const isGIF = type === 'image/gif'

      // GIFs are treated just like videos (except timestamp)
      if (isGIF) {
        isImage = false
        isVideo = true
      }

      if (bytes == 0) {
        // error
        this.toast.error('Error occurred')
        return
      }

      if (isImage && !isGIF) {
        if (bytes > ImageuploadtemplateComponent.maxVideoSize_Bytes) {
          this.oneButtonDialogService.show(
            'File too large',
            `Please ensure that your image is no larger than 10 MB in size.`
          )
          return
        }

        const reader = new FileReader()
        reader.onload = () => {
          const stringRes = reader.result as string

          const image = new Image()
          image.src = stringRes
          image.onload = () => {
            const width = image.width
            const height = image.height
            const ratio = width / height

            console.log(`w: ${width}, h: ${height}, ratio: ${ratio}`)

            // check if file is allowed (ratio aspect)
            if (ratio < 0.2 || ratio > 5) {
              // not allowed
              this.toast.error(
                'Image has a height width ratio that is not allowed'
              )
              return
            } else {
              // allowed
              this.imageUploadedAsString = stringRes
              this.medW = width
              this.medH = height
              this.medAR = ratio

              // remove video
              this.videoFile = null
            }
          }
        }
        reader.readAsDataURL(files[0])
      } else if (isVideo) {
        if (bytes > ImageuploadtemplateComponent.maxVideoSize_Bytes) {
          this.oneButtonDialogService.show(
            'File too large',
            `Please ensure that your video is no larger than 150 MB in size.`
          )
          return
        }

        // load video duration, except its a gif
        if (isGIF) {
          // set
          this.videoDuration = ChatPageComponent.gifDetection_Duration_Code
          this.videoFile = file

          // remove img
          this.imageUploadedAsString = ''
          this.medAR = 0
        } else {
          const video = document.createElement('video')
          video.preload = 'metadata'

          const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
            disableClose: true
          })

          video.onloadedmetadata = () => {
            if (typeof window === 'undefined') {
              return
            }

            loadingDialogRef.close()

            window.URL.revokeObjectURL(video.src)
            if (video.duration == 0) {
              this.toast.error('Error loading video')
              return
            }
            console.log('video.duration: ', video.duration)

            // set
            this.videoDuration = video.duration
            this.videoFile = file

            // remove img
            this.imageUploadedAsString = ''
            this.medAR = 0
          }
          video.src = URL.createObjectURL(file)
        }
      }
    }
  }

  async loadLastSeen() {
    if (this.isPrivate) {
      // always listener
      const refOnOff = ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/onlinestatus/${this.otherUserID}`
      )
      const unsubCallback = onValue(refOnOff, async (snapShotOnline) => {
        let isOnline = false
        if (snapShotOnline.exists()) {
          isOnline = snapShotOnline.val()
        }

        // on/off already determined
        const alreadyDetVal = isOnline ? 1 : 2

        this.quickInfoString = await this.lastseenService.loadLastSeenString(
          this.otherUserID,
          alreadyDetVal
        )
      })
      this.alwaysListenersRef_List.push(unsubCallback)
    } else {
      this.messageCount$ = this.cacheService.getGroupMessageCount(this.chatID)
    }
  }

  checkIfAllowed(): void {
    if (this.isGlobalChat) {
      // continue to check mod-restrictions
      this.checkBlocked()
    } else if (this.isGroup) {
      // continue to check mod-restrictions
      this.checkBlocked()
    } else {
      // first check if the other user has DM restrictions
      get(
        ref(
          this.dbRTDB,
          `${StrHlp.CLOUD_PATH}/UserSettings/${this.otherUserID}/DMRestrictionSettings`
        )
      )
        .then((snapshot) => {
          let setting = 0
          if (snapshot.exists()) {
            setting = snapshot.val()
          }

          if (setting == 0) {
            // default, no restrictions. Continue
            this.checkBlocked()
          } else {
            // get if that user is following me
            get(
              ref(
                this.dbRTDB,
                `${StrHlp.CLOUD_PATH}/Following/${this.otherUserID}/${this.userID}`
              )
            )
              .then((snapshot) => {
                if (snapshot.exists()) {
                  // the user is following me, allow anyways. continue
                  this.checkBlocked()
                } else {
                  // not following me

                  if (setting == 1) {
                    // user only allows DMs from users he is following
                    this.toast.error(
                      'You cannot text this user because this user does not follow you'
                    )
                    history.back()
                    return
                  } else {
                    // user only allows users that have been in the app for at least 7 days
                    get(
                      ref(
                        this.dbRTDB,
                        `${StrHlp.CLOUD_PATH}/Users/${this.userID}/timestampJoined`
                      )
                    )
                      .then((snapshot) => {
                        let timestamp = 0
                        if (snapshot.exists()) {
                          timestamp = snapshot.val()
                        }
                        if (timestamp == 0) {
                          timestamp = 1641419038660 // 05.01.2022
                        }
                        const curr = Date.now()

                        if (curr - timestamp < 7 * 86400000) {
                          // you are too new to text this user
                          this.toast.error(
                            'You cannot text this user because this user restricts DMs'
                          )
                          history.back()
                          return
                        } else {
                          // is allowed, continue
                          this.checkBlocked()
                        }
                      })
                      .catch((error) => {
                        console.log(error)
                        this.toast.error('An error has occurred')
                      })
                  }
                }
              })
              .catch((error) => {
                console.log(error)
                this.toast.error('An error has occurred')
              })
          }
        })
        .catch((error) => {
          console.log(error)
          this.toast.error('An error has occurred')
        })
    }
  }

  checkBlocked(): void {
    if (this.isPrivate) {
      // check if that user has blocked me, and if I have blocked that user. Both via always-listeners
      const refAreYouBlocked = ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/BlockedUsers/${this.otherUserID}/${this.userID}`
      )
      const unsubCallback = onValue(refAreYouBlocked, (snapshot) => {
        if (snapshot.exists()) {
          this.toast.error('This user has blocked you')
          history.back()
          return
        }
      })
      this.alwaysListenersRef_List.push(unsubCallback)

      const refHaveYouBlocked = ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/BlockedUsers/${this.userID}/${this.otherUserID}`
      )
      const unsubCallback2 = onValue(refHaveYouBlocked, (snapshot) => {
        if (snapshot.exists()) {
          this.toast.error('You have blocked this user')
          history.back()
          return
        }
      })
      this.alwaysListenersRef_List.push(unsubCallback2)
    }

    this.setUpEverything()
  }

  setUpEverything(): void {
    this.setUpAlwaysListener()
    this.setUpMarkAsRead()

    // pass data to chatDataService
    this.chataDataService.set_NewMsgCountToZero_Callback(this.chatID)

    // forward message check
    if (
      this.forward_Message ||
      this.forward_ImageURL ||
      this.forward_GifURL ||
      this.forwardVideoID
    ) {
      // open dialog to confirm forwarding this message
      this.twobuttonsdialogService.show(
        'Forward message',
        'Do you want to forward this message to this chat?',
        () => {
          this.forward_Message = ''
          this.forward_ImageURL = ''
          this.forward_GifURL = ''
          this.forwardVideoDuration = 0
          this.forwardVideoID = ''
        },
        () => {
          this.sendMessage()
        },
        'Cancel',
        'Forward'
      )
    }
  }

  /**
   * while the chat is opened, mark as read automatically
   */
  setUpMarkAsRead(): void {
    // All the group chat related things are handled in
    // chat data service

    if (this.isPrivate) {
      // "reset new messages count to -1" and
      // "adjust count for new chats"
      // are now handled in chat data service

      // last-message-seen
      const path = this.isMeUser1
        ? 'lastMessageSeenByUser1'
        : 'lastMessageSeenByUser2'
      const messageSeenRef = ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/ChatUebersicht/${this.chatID}/${path}`
      )

      const unsubCallback = onValue(messageSeenRef, (snapshot) => {
        if (snapshot.exists()) {
          const val = snapshot.val()
          if (val === false) {
            set(messageSeenRef, true)
          }
        }
      })
      this.alwaysListenersRef_List.push(unsubCallback)
    }
  }

  checkIfEmpty(): void {
    const emptyRef = query(
      ref(this.dbRTDB, `${StrHlp.CLOUD_PATH}/Chats/${this.chatID}`),
      limitToFirst(1)
    )
    get(emptyRef).then((snapshot) => {
      // only do this if the other async loader has not provided results already
      // otherwise we might get contradictions
      if (!this.empty_Confirmed) {
        if (!snapshot.exists()) {
          this.empty = true
          this.empty_Confirmed = true

          // choose random gif to display
          const rndIndex = Math.floor(Math.random() * this.emptyGifsList.length)
          this.emptyGifURL = this.emptyGifsList[rndIndex]
        }
      }
    })
  }

  async loadNewMessagesOnScroll() {
    if (this.onScrollLastMessageKey === null) {
      return
    }

    if (this.disableOnScrollLoading || this.currentlyLoadingOnScroll) {
      return
    }

    // indicate loading
    this.currentlyLoadingOnScroll = true

    const loadingRef = query(
      ref(this.dbRTDB, `${StrHlp.CLOUD_PATH}/Chats/${this.chatID}`),
      orderByKey(),
      startAt(this.onScrollLastMessageKey),
      limitToFirst(this.LOAD_X_MESSAGES_ONSCROLL)
    )

    const snapshot = await get(loadingRef)

    this.disableOnScrollLoading = snapshot.size <= 1

    // do not add the very first message again because it is already in the list
    let firstChildSnapshot: boolean = true
    const listSnaps: any[] = []

    const initScrollHeight =
      this.scrollContainer.elementRef.nativeElement.scrollHeight

    snapshot.forEach((childSnapshot: any) => {
      if (firstChildSnapshot) {
        firstChildSnapshot = false
      } else {
        listSnaps.push(childSnapshot)
      }
    })

    if (listSnaps.length > 0) {
      const tempList = []

      for (let i = 0; i < listSnaps.length; i++) {
        // load the ChatUebersicht(Gruppen)-object and add it to the list
        const messageID = listSnaps[i].key!
        const message = listSnaps[i].val()

        // set for recycling reasons
        message.isAd = false

        // check is the user was muted
        if (this.muteUsersService.isMuted(message.senderUID)) {
          continue
        }

        this.onScrollLastMessageKey = messageID

        tempList.push(message)
      }

      for (let i = 0; i < tempList.length; i++) {
        const obj = tempList[i]

        // check if msg should have a date indicator above
        // two reasons: either different date than previous, or last msg
        let showDateHint = false

        if (i == tempList.length - 1) {
          showDateHint = true
        } else {
          if (this.lastAddedTimestamp != 0) {
            if (!TimeService.sameDay(this.lastAddedTimestamp, obj.timestamp)) {
              showDateHint = true
            }
          }
        }
        this.lastAddedTimestamp = obj.timestamp
        // --

        obj.showDateHint = showDateHint

        this.itemList.unshift(obj)
        this.updateVirtualScroll()

        this.maybeInsertAd(true)
      }

      // Issue:
      // The scroll changes after the items get inserted which makes a TERRIBLE UI.
      // So we scroll back to the offset we had before the inserting
      this.setTimeoutService.setTimeout(() => {
        try {
          this.scrollContainer.elementRef.nativeElement.scrollTop =
            this.scrollContainer.elementRef.nativeElement.scrollHeight -
            initScrollHeight
        } catch (err) {
          console.log(err)
        }
      }, 100) // little delay needed
    }

    // hide loading
    this.currentlyLoadingOnScroll = false
  }

  updateVirtualScroll() {
    this.itemList = [...this.itemList]
  }

  maybeInsertAd(atBeginning: boolean) {
    if (this.isGroup) {
      const msgAd = this.chatadsService.add()
      if (msgAd !== null) {
        if (atBeginning) {
          this.itemList.unshift(msgAd)
        } else {
          this.itemList.push(msgAd)
        }

        this.updateVirtualScroll()
      }
    }
  }

  setUpAlwaysListener(): void {
    // we need to check if there are any messages in the chat already or if its empty.
    // it does not work with child event listener, so we do this
    this.checkIfEmpty()

    const chatsRef = query(
      ref(this.dbRTDB, `${StrHlp.CLOUD_PATH}/Chats/${this.chatID}`),
      limitToFirst(this.LOAD_X_MESSAGES)
    )

    const unsubCallback1 = onChildAdded(chatsRef, async (data) => {
      this.empty = false
      this.empty_Confirmed = true

      const scrollDownAfterInsert = this.scroll_isAtBottom

      // load the ChatUebersicht(Gruppen)-object and add it to the list
      const messageID = data.key!
      const message = data.val()

      // set for recycling reasons
      message.isAd = false

      // check is the user was muted
      if (this.muteUsersService.isMuted(message.senderUID)) {
        return
      }

      const insertAtEnd = this.lastMsgTimestamp < message.timestamp

      if (insertAtEnd) {
        this.lastMsgTimestamp = message.timestamp
      }

      //console.log(message);
      if (insertAtEnd) {
        this.itemList.push(message)

        this.maybeInsertAd(false)
      } else {
        // only if appending to top, not at bottom for new sent messages:
        // check if msg should have a date indicator above
        const showDateHint =
          this.lastAddedTimestamp != 0 &&
          !TimeService.sameDay(this.lastAddedTimestamp, message.timestamp)

        this.lastAddedTimestamp = message.timestamp
        message.showDateHint = showDateHint

        this.itemList.unshift(message)

        // for the scroll loading set up
        this.onScrollLastMessageKey = messageID

        this.maybeInsertAd(true)
      }

      this.updateVirtualScroll()

      //console.log(`scollDownAfterInsert: ${scrollDownAfterInsert}`);
      if (scrollDownAfterInsert) {
        // scroll down with a little delay (it's needed)
        // perhaps its needed because the UI needs a little time to display after the push
        this.setTimeoutService.setTimeout(() => {
          this.scrollToBottom()
        }, 100)

        this.setTimeoutService.setTimeout(() => {
          this.scrollToBottom()
        }, 700)
      } else {
        this.newMessagesToDiscover++
      }

      // for UI: increase new messages count if there are any
      if (this.newMessagesCount > 0) {
        this.newMessagesCount++
      }
    })
    this.alwaysListenersRef_List.push(unsubCallback1)

    const unsubCallback2 = onChildChanged(chatsRef, async (data) => {
      // load the ChatUebersicht(Gruppen)-object and add it to the list

      const messageID = data.key!
      const message = data.val()

      // set for recycling reasons
      message.isAd = false

      // MOVED TO MESSAGETEMPLATE
      //const furtherData = await this.getFurtherData(messageID, message);
      //console.log(furtherData);
      //Object.assign(message, furtherData);

      // set to array
      for (let i = 0; i < this.itemList.length; i++) {
        if (this.itemList[i].messageID === messageID) {
          this.itemList[i] = message
          this.updateVirtualScroll()

          break
        }
      }
    })
    this.alwaysListenersRef_List.push(unsubCallback2)
  }

  addEmoji(emoji: string) {
    this.messageEntered = `${this.messageEntered}${emoji}`
    this.onMessageAltered()
  }

  emojiButtonClick() {
    this.showGifsPicker = true

    this.keyboardService.openEmoji(
      (emj: string) => {
        this.addEmoji(emj)
      },
      () => {
        this.showGifsPicker = false
      },
      true
    )
  }

  hideEmojiKeyboard() {
    this.showEmojiPicker = false
  }

  gifButtonClick() {
    this.showGifsPicker = true

    this.keyboardService.openGif(
      (gifURL: string, ratio: number) => {
        this.onGifSelectedCallback(gifURL, ratio)
      },
      () => {
        this.showGifsPicker = false
      },
      true
    )
  }

  hideGifsPicker() {
    this.showGifsPicker = false
  }

  scrollToBottom(): void {
    try {
      this.scrollContainer.elementRef.nativeElement.scrollTop =
        this.scrollContainer.elementRef.nativeElement.scrollHeight
    } catch (err) {
      console.log(err)
    }
  }

  setUpScrollListener() {
    // ssr-guarded
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    this.scrollContainer.elementRef.nativeElement.addEventListener(
      'scroll',
      () => {
        const scrollTop =
          this.scrollContainer.elementRef.nativeElement.scrollTop
        const offsetHeight =
          this.scrollContainer.elementRef.nativeElement.offsetHeight

        const scrollHeight =
          this.scrollContainer.elementRef.nativeElement.scrollHeight
        const diff1 = scrollHeight - offsetHeight

        //console.log(`diff: ${diff1 - scrollTop} since...: diff1: ${diff1}, scrollTop: ${scrollTop}`);

        this.scroll_isAtBottom =
          diff1 - scrollTop <= this.diffThresholdForReachedBottom
        this.shouldBottomScrollButtonDisappear = this.scroll_isAtBottom

        const scroll_isAtTop = scrollTop <= this.diffThresholdForReachedBottom

        if (this.scroll_isAtBottom) {
          // reset messages count
          this.newMessagesToDiscover = 0
        }

        if (scroll_isAtTop) {
          // load new items on scroll
          this.loadNewMessagesOnScroll()
        }

        const is_scrollDirection_ToTop = this.lastVal_ScrollTop > scrollTop
        this.lastVal_ScrollTop = scrollTop

        if (is_scrollDirection_ToTop) {
          //this.hideKeyboard();
        }

        // determine highest visible message (for date top indicator)
        const elements = document.querySelectorAll('[id^="message-"]')
        let highestVisibleElement
        for (let i = 0; i < elements.length; i++) {
          const rect = elements[i].getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            highestVisibleElement = elements[i]
            break
          }
        }
        if (highestVisibleElement) {
          const messageID = highestVisibleElement.id.substring(8)

          for (let i = this.itemList.length - 1; i >= 0; i--) {
            const obj = this.itemList[i]
            if (obj.messageID == messageID) {
              this.updateDateTopIndicator(obj)
              break
            }
          }
        }
      }
    )
  }

  updateDateTopIndicator(item: any) {
    this.dateIndicatorString = this.getDateHintString(item)
    this.showDateIndicator = true
    this.fadeOutDateIndicator = false

    if (this.dateAtTopTimeout) {
      this.setTimeoutService.clearTimeout(this.dateAtTopTimeout)
    }

    this.dateAtTopTimeout = this.setTimeoutService.setTimeout(() => {
      this.showDateIndicator = false
      this.fadeOutDateIndicator = true
    }, this.dateAtTop_Timeout_Delay)
  }

  async sendMessage(gifURL: string = '', aspectRatio: number = 0) {
    // check restrictions
    if (MainComponent.permChatBanned || MainComponent.tempChatBanned) {
      this.toast.error('You have reached your daily chat limit')
      return
    }
    if (
      this.isGlobalChat &&
      (MainComponent.permChatBanned_GC || MainComponent.tempChatBanned_GC)
    ) {
      this.toast.error('You have reached your daily chat limit')
      return
    }

    if (this.messageSendingInProgress) {
      return
    } else {
      this.messageSendingInProgress = true
    }

    let message = this.messageEntered.trim()
    let forwarded = false
    let forwardImageURL = ''
    let forwardGifURL = ''
    let forwardVideoID = ''
    let forwardVideoDuration = 0

    if (this.forward_Message && this.forward_Message.length > 0) {
      message = this.forward_Message
      this.forward_Message = ''
      forwarded = true
    }
    if (this.forward_ImageURL && this.forward_ImageURL.length > 0) {
      forwardImageURL = this.forward_ImageURL
      this.forward_ImageURL = ''
      forwarded = true
    }
    if (this.forward_GifURL && this.forward_GifURL.length > 0) {
      forwardGifURL = this.forward_GifURL
      this.forward_GifURL = ''
      forwarded = true
    }

    if (this.forwardVideoID && this.forwardVideoID.length > 0) {
      forwardVideoID = this.forwardVideoID
      this.forwardVideoID = ''

      forwardVideoDuration = this.forwardVideoDuration
      this.forwardVideoDuration = 0

      forwarded = true
    }

    if (
      message === '' &&
      this.imageUploadedAsString === '' &&
      !forwarded &&
      gifURL === '' &&
      this.videoFile === null
    ) {
      this.messageSendingInProgress = false
      return // empty message
    }

    // check not too fast
    if (!TimeLimitsService.isAllowed_Session('send-message', 800)) {
      this.toast.error("You're too fast")
      this.messageSendingInProgress = false
      return
    }

    // allowed sending, reset message
    this.messageEntered = ''
    this.onMessageAltered()

    // UI
    this.hideEmojiKeyboard()
    //this.hideGifsPicker();

    // UX: open keyboard again because it closes itself
    // Unless: Gif or image, then dont do this
    const containsImage = this.imageUploadedAsString !== ''
    const isGIF = gifURL !== ''
    const isVideo = this.videoFile !== null

    if (aspectRatio !== 0) {
      this.medAR = aspectRatio
      this.medH = 0
      this.medW = 0
    }

    if (!containsImage && !isGIF && !forwarded) {
      this.openKeyboard()
    }

    // prepare message for sending
    message = message.replace(/[\n]{2,}/, '\n\n').trim()

    let key = push(child(ref(this.dbRTDB), `${StrHlp.CLOUD_PATH}/Chats`)).key
    key = this.keyHelperService.invertKey(key!)

    const replyMessage = this.replyText
    const replyMessageID = this.replyMessageID
    const replyMessageFrom = this.replyPrivately_replyMessageFrom
    this.removeReplyText()

    let imageURL = ''
    let vidID = ''

    if (containsImage) {
      // message has image
      const storage = getStorage()
      const storageRef = refStorage(
        storage,
        `${StrHlp.CLOUD_PATH}/photos/chats/${this.chatID}/userID/${this.userID}/${key}/img_rsz_`
      )

      const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
        disableClose: true
      })

      try {
        const snapshot = await uploadString(
          storageRef,
          this.imageUploadedAsString,
          'data_url'
        )

        // get download URL
        const downloadURL = await getDownloadURL(snapshot.ref)
        imageURL = downloadURL

        this.removeMediumChosen()

        loadingDialogRef.close()
        //this.toast.success("Uploaded");
      } catch (e) {
        console.log(e)

        loadingDialogRef.close()
        this.toast.error('Error occurred')

        this.messageSendingInProgress = false
        return
      }
    } else if (forwarded) {
      if (forwardImageURL.length > 0) {
        imageURL = forwardImageURL
      }
      if (forwardGifURL.length > 0) {
        gifURL = forwardGifURL
      }
      if (forwardVideoID.length > 0) {
        vidID = forwardVideoID
        if (forwardVideoDuration) {
          this.videoDuration = forwardVideoDuration
        }
      }
    } else if (isVideo) {
      const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
        disableClose: true
      })

      try {
        // upload video
        vidID = await this.bunnyService.do(this.videoFile)

        this.removeMediumChosen()
        loadingDialogRef.close()
      } catch (error) {
        console.log(error)
        this.toast.error('Video upload failed')
        loadingDialogRef.close()
      }
    }

    let messageObj: any = {}
    if (this.isPrivate) {
      messageObj = {
        messageID: key,
        message: message,
        timestamp: serverTimestamp(),
        replyMessage: replyMessage,
        imageURL: imageURL,
        messageFromUser1: this.isMeUser1,
        countLikes: 0,
        countDislikes: 0,
        edited: false,
        forwarded: forwarded,
        replyMessageID: replyMessageID,
        replyMessageFrom: replyMessageFrom,
        hintCode: '',
        vidURL: '',
        thmbURL: '',
        gifURL: gifURL,
        medAR: this.medAR,
        medW: this.medW,
        medH: this.medH,
        vid: vidID,
        vidDuration: this.videoDuration
      }
    } else {
      messageObj = {
        messageID: key,
        message: message,
        timestamp: serverTimestamp(),
        replyMessage: replyMessage,
        imageURL: imageURL,
        senderUID: this.userID,
        countLikes: 0,
        countDislikes: 0,
        edited: false,
        forwarded: forwarded,
        replyMessageID: replyMessageID,
        replyMessageFrom: replyMessageFrom,
        hintCode: '',
        vidURL: '',
        thmbURL: '',
        gifURL: gifURL,
        medAR: this.medAR,
        medW: this.medW,
        medH: this.medH,
        vid: vidID,
        vidDuration: this.videoDuration
      }
    }

    // send message
    await set(
      ref(this.dbRTDB, `${StrHlp.CLOUD_PATH}/Chats/${this.chatID}/${key}`),
      messageObj
    )

    // after sending message, it should scroll down
    // with delay
    this.setTimeoutService.setTimeout(() => {
      this.scrollToBottom()

      // reset input height
      this.messageinput.nativeElement.style.height = '30px'
    }, 100)

    this.messageSendingInProgress = false

    // reset drafted msg after sending a msg (in case there was a draft)
    this.resetDraftedMsg()

    // READ:
    // Group chats need a whole revolution. So instead of programming it the right way
    // on the backend, we will add logic for it here. Poorly coded stuff on the front end
    // as it was on the android app. TODO: Remove all the code here and completely revolutionize
    // group chats and put it all to the backend.
    if (this.isGroup && !this.isGlobalChat) {
      this.DEPRECATED_sndMsg_Grp_Logic(message, imageURL, gifURL)
    }
  }

  async loadIfGroupAdmin() {
    const snap = await get(
      ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.chatID}/Mitglieder/${this.userID}`
      )
    )
    if (snap.exists()) {
      this.areYouGroupAdmin = snap.val() == 1
    }
  }

  /**
   * @deprecated
   */
  async DEPRECATED_sndMsg_Grp_Logic(
    lastMsg: string,
    imageURL: string,
    gifURL: string
  ) {
    // iterate over members list and update chatUebersicht for all members
    const timestamp = Date.now()

    // no await logic because that would massively slow things down
    // instead, we use an async func in here which is executed each time, WITHOUT await.
    for (let i = 0; i < this.DEPRECATED_userID_List_Group.length; i++) {
      this.DEPRECATED_sendMsg_Grp_Logic_PerUser(
        this.DEPRECATED_userID_List_Group[i],
        timestamp
      )
    }

    // update the chatUebersicht as a whole
    let msgPreview = lastMsg
    if (msgPreview == '') {
      if (imageURL !== '') {
        msgPreview = '🖼️ Photo'
      } else if (gifURL !== '') {
        msgPreview = '🎬 GIF'
      }
    }

    const chatUeb = {
      groupID: this.chatID,
      lastMessage: msgPreview,
      lastMessageUID: this.userID,
      lastMessageTimestamp: timestamp
    }
    await set(
      ref(this.dbRTDB, `${StrHlp.CLOUD_PATH}/ChatUebersicht/${this.chatID}`),
      chatUeb
    )

    await set(
      ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.chatID}/Dynamisch/MessageCount`
      ),
      increment(1)
    )
  }

  async DEPRECATED_sendMsg_Grp_Logic_PerUser(
    userID_temp: string,
    timestamp: number
  ) {
    const snap = await get(
      ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/Chat_NeueNachrichtIndikator/${userID_temp}`
      )
    )
    let b = true
    if (snap.exists()) {
      b = snap.val()
    }
    // set opposite value so that the other user know something happened
    await set(
      ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/Chat_NeueNachrichtIndikator/${userID_temp}`
      ),
      !b
    )

    // also update chat list
    await set(
      ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/ChatUebersichtListe/${userID_temp}/${this.chatID}`
      ),
      -timestamp
    )
  }

  async DEPRECATED_loadGroupMembers() {
    const snapshot = await get(
      ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.chatID}/Mitglieder`
      )
    )
    snapshot.forEach((childSnapshot) => {
      this.DEPRECATED_userID_List_Group.push(childSnapshot.key!)
    })
  }

  openProfile(): void {
    if (this.isPrivate) {
      this.router.navigate(['@' + this.chatName])
    } else {
      // groups except GC
      if (this.isGlobalChat) {
        this.router.navigate(['/group/global-chat-room'])
      } else {
        this.router.navigate(['/group/' + this.chatID])
      }
    }
  }

  removeReplyText(): void {
    this.replyText = ''
    this.replyMessageID = ''
    this.replyPrivately_replyMessageFrom = ''
  }

  removeMediumChosen(): void {
    this.imageUploadedAsString = ''
    this.medAR = 0
    this.videoFile = null
  }

  setReplyText(item: any): void {
    let text = item.message
    if (text.length == 0) {
      if (item.imageURL) {
        text = '🖼️ Photo'
      } else if (item.vid) {
        text = '🎥 Video'
      } else if (item.gifURL) {
        text = '🎬 GIF'
      }
    }
    this.replyText = text
    this.replyMessageID = item.messageID

    this.openKeyboard()
  }

  openKeyboard() {
    this.messageinput.nativeElement.focus()
  }

  hideKeyboard() {
    const element = this.messageinput.nativeElement

    this.renderer.setProperty(element, 'readonly', true)
    this.renderer.setProperty(element, 'disabled', true)

    this.setTimeoutService.setTimeout(() => {
      element.blur()
      this.renderer.setProperty(element, 'readonly', false)
      this.renderer.setProperty(element, 'disabled', false)
    }, 100)
  }

  mentionUser(item: any) {
    this.cacheService
      .getUsername(item.senderUID)
      .pipe(take(1))
      .subscribe((name) => {
        if (this.messageEntered.trim().length == 0) {
          this.messageEntered = `@${name}`
        } else {
          this.messageEntered = this.messageEntered + ` @${name}`
        }
        this.onMessageAltered()

        this.openKeyboard()
      })
  }

  ngOnDestroy() {
    // remove data from chatDataService
    this.chataDataService.remove_NewMsgCountToZero_Callback()

    // remove all listeners
    for (let i = 0; i < this.alwaysListenersRef_List.length; i++) {
      try {
        const unsubCallback = this.alwaysListenersRef_List[i]
        unsubCallback()
      } catch (error) {
        console.log(error)
      }
    }

    // stop typing if still was typing
    if (this.isMeTyping) {
      this.stopTyping()
    }

    // check if msg should be persisted
    const msg = this.messageEntered.trim()
    if (msg && this.draftMsg !== msg) {
      this.localstorageService.setItem(this.msg_draft_key, msg)
    }
  }

  resetDraftedMsg() {
    this.localstorageService.removeItem(this.msg_draft_key)
  }

  back() {
    history.back()
  }

  onTextChange(event: any) {
    // ssr guarded
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    // enter has keyCode = 13
    if (event.keyCode == 13) {
      // do not send the message by enter if it mobile, or shift is also pressed
      if (!event.shiftKey && !SystemService.isMobile()) {
        event.preventDefault()
        this.sendMessage()
      }
    }

    const key = event.key
    const isAllowedKey =
      /^[a-zA-Z0-9,.\-;:_<>|^°!"§$%&\/()=?´*'+~²³{}\[\]\\\\€@µ]$/g.test(key) // Check if the key is a-z, A-Z, 0-9, space, or ()

    if (isAllowedKey) {
      //console.log("onTextChange...: key=" +key);

      const typingDelay = 1700

      // this.messageEntered has changed
      // send typing info

      // cancel last delayed typing-stopper
      if (this.lastTypingTimerID !== null) {
        this.setTimeoutService.clearTimeout(this.lastTypingTimerID)
      }

      // send typing to cloud
      this.sendTyping()

      // start delayed typing-stopped
      this.lastTypingTimerID = this.setTimeoutService.setTimeout(() => {
        this.stopTyping()
      }, typingDelay)
    }

    // this needs to wait for event queue
    setTimeout(() => {
      this.onMessageAltered()
    }, 50)
  }

  onMessageAltered() {
    this.isInputBarUntouched = false

    this.isSendingMessagePossible =
      this.messageEntered.trim().length > 0 ||
      this.imageUploadedAsString.length > 0 ||
      this.videoFile

    this.shouldFadeInSendingButton = this.isSendingMessagePossible
    this.shouldFadeOutSendingButton = !this.isSendingMessagePossible
  }

  sendTyping(): void {
    if (this.myUsername === '') {
      return
    }

    this.isMeTyping = true

    // if currently someone is typing, do not send
    if (!this.chataDataService.typingInfo[this.chatID]) {
      if (this.isGlobalChat) {
        set(
          ref(this.dbRTDB, `${StrHlp.CLOUD_PATH}/GlobalChat/isTyping`),
          this.myUsername
        )
      } else if (this.isGroup) {
        set(
          ref(
            this.dbRTDB,
            `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.chatID}/Dynamisch/isTyping`
          ),
          this.myUsername
        )
      } else if (this.isPrivate) {
        //console.log("sendTyping: " + this.myUsername);
        set(
          ref(
            this.dbRTDB,
            `${StrHlp.CLOUD_PATH}/Chats_TypingInfo/${this.chatID}/${this.userID}/isTyping`
          ),
          true
        )
      }
    }
  }

  stopTyping(): void {
    this.isMeTyping = false

    if (this.isGlobalChat) {
      set(ref(this.dbRTDB, `${StrHlp.CLOUD_PATH}/GlobalChat/isTyping`), '')
    } else if (this.isGroup) {
      set(
        ref(
          this.dbRTDB,
          `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.chatID}/Dynamisch/isTyping`
        ),
        ''
      )
    } else {
      set(
        ref(
          this.dbRTDB,
          `${StrHlp.CLOUD_PATH}/Chats_TypingInfo/${this.chatID}/${this.userID}/isTyping`
        ),
        false
      )
    }
  }

  markChatAsOkay(): void {
    if (this.isMeUser1 && this.chatOK1) {
      // is already marked as okay
      return
    }
    if (!this.isMeUser1 && this.chatOK2) {
      // is already marked as okay
      return
    }

    if (this.isMeUser1) {
      this.chatOK1 = true
    } else {
      this.chatOK2 = true
    }

    // call cloud func
    const functions = getFunctions()
    const functionToCall = httpsCallable(functions, 'markChatAsOK')
    functionToCall({
      hubname: StrHlp.CLOUD_PATH,
      chatID: this.chatID
    })
      .then(() => {
        // --
      })
      .catch((error) => {
        console.log(error)
      })

    // mark chat as read locally for UI
    const chatItem: any = this.chataDataService.chatList[this.chatID as any]
    chatItem.chatOK1 = this.chatOK1
    chatItem.chatOK2 = this.chatOK2
    this.chataDataService.chatList[chatItem.chatID] = chatItem
  }

  blockAndDeleteChat(): void {
    this.twobuttonsdialogService.show(
      'Block & Delete',
      'Do you want to block this user and delete the chat?',
      () => {
        // nothing
      },
      () => {
        // call cloud function
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        const functions = getFunctions()
        const blockUser = httpsCallable(functions, 'blockUser')

        blockUser({
          hubname: StrHlp.CLOUD_PATH,
          otherUserID: this.otherUserID
        })
          .then(() => {
            loadingDialogRef.close()
            this.toast.success('User blocked')

            // delete the chat
            remove(
              ref(
                this.dbRTDB,
                `${StrHlp.CLOUD_PATH}/ChatUebersichtListe/${this.userID}/${this.chatID}`
              )
            )
          })
          .catch((error) => {
            loadingDialogRef.close()
            console.log(error)

            // show error message via dialog
            this.oneButtonDialogService.show('Failed', error.message)
          })
      },
      'Cancel',
      'Yes'
    )
  }

  askLeaveGlobalChat() {
    this.twobuttonsdialogService.show(
      'Leave Global Chat',
      'Do you want to leave the Global Chat?',
      () => {},
      () => {
        set(
          ref(
            this.dbRTDB,
            `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/HasLeftGC`
          ),
          true
        )
          .then(() => {
            this.toast.success('Left Global Chat')

            // navigate back
            history.back()
          })
          .catch((error) => {
            console.log(error)
            this.toast.error('An error has occurred')
          })
      },
      'Cancel',
      'Leave'
    )
  }

  onReplyClick(quotedMessageID: string, event: any): void {
    // determine msg index (now we need that because of the virtual scroll)
    let scrollToIndex = -1
    for (let i = 0; i < this.itemList.length; i++) {
      if (this.itemList[i].messageID === quotedMessageID) {
        scrollToIndex = i
        break
      }
    }

    // scroll to the msg
    this.scrollContainer.scrollToIndex(scrollToIndex)

    if (scrollToIndex == -1) {
      this.toast.show('Quoted messages not yet loaded')
    } else {
      // Due to OnPush CD we need to create a new obj
      const newItem = {
        ...this.itemList[scrollToIndex],
        highlighted: true,
        removeHighlighted: false
      }
      this.itemList[scrollToIndex] = newItem

      this.updateVirtualScroll()

      // unhighlight after some time
      this.setTimeoutService.setTimeout(() => {
        // we need to re-determine the index as it might have changed
        for (let j = 0; j < this.itemList.length; j++) {
          if (this.itemList[j].messageID === quotedMessageID) {
            // update
            // "removeHighlighted" is for the removal animation
            const newItem = {
              ...this.itemList[j],
              highlighted: false,
              removeHighlighted: true
            }
            this.itemList[j] = newItem

            this.updateVirtualScroll()
            break
          }
        }
      }, MESSAGE_HIGHLIGHTED_DURATION)
    }

    // stopPropagation since we do not want to open the option dialog
    // if we just click on the reply message
    event.stopPropagation()
  }

  updateListForOnPushCD() {
    this.itemList = [...this.itemList]
  }

  getDateHintString(item: any) {
    const dayInMillis = 86400000
    const timestamp = item.timestamp
    const date = new Date(timestamp)

    if (TimeService.isToday(timestamp)) {
      return 'Today'
    } else if (TimeService.isYesterday(timestamp)) {
      return 'Yesterday'
    } else if (Date.now() - timestamp <= 7 * dayInMillis) {
      const weekdays = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ]
      return weekdays[date.getDay()]
    } else {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
      return `${months[date.getMonth()]} ${date.getDate()}`
    }
  }

  sendGif(url: string, aspectRatio: number) {
    console.log('aspectRatio: ' + aspectRatio)
    this.sendMessage(url, aspectRatio)
  }

  notifSettingClick() {
    if (this.isChatMuted) {
      this.twobuttonsdialogService.show(
        'Unmute this chat',
        'Do you want to unmute this chat? You will get push notifications again when this user texts you.',
        () => {},
        async () => {
          await remove(this.isChatMutedRef!)
          this.toast.success('Unmuted')
        },
        'Cancel',
        'Yes'
      )
    } else {
      this.twobuttonsdialogService.show(
        'Mute this chat',
        'Do you want to mute this chat? You will no longer get push notifications when this user texts you.',
        () => {},
        async () => {
          await set(this.isChatMutedRef!, true)
          this.toast.success('Muted')
        },
        'Cancel',
        'Yes'
      )
    }
  }

  askBlockUser() {
    this.twobuttonsdialogService.show(
      'Block user',
      'Do you want to block this user?',
      () => {},
      () => {
        // call cloud function
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        const functions = getFunctions()
        const blockUser = httpsCallable(functions, 'blockUser')

        blockUser({
          hubname: StrHlp.CLOUD_PATH,
          otherUserID: this.otherUserID
        })
          .then(() => {
            this.toast.success('User blocked')
          })
          .catch((error) => {
            loadingDialogRef.close()
            console.log(error)

            // show error message via dialog
            this.oneButtonDialogService.show('Failed', error.message)
          })
      },
      'Cancel',
      'Yes'
    )
  }

  trackMessageById(index: number, item: any) {
    return item.messageID
  }

  getCurrIndexOfItem(item: any) {
    for (let i = 0; i < this.itemList.length; i++) {
      if (this.itemList[i].messageID === item.messageID) {
        return i
      }
    }
    return -1
  }

  startEditingMessage(item: any, newText: string) {
    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    const currIndexOfItem = this.getCurrIndexOfItem(item)
    const isLastMessage = currIndexOfItem == this.itemList.length - 1

    // call cloud function
    const functions = getFunctions()
    const editMessage = httpsCallable(functions, 'editMessage')

    editMessage({
      hubname: StrHlp.CLOUD_PATH,
      messageID: item.messageID,
      newMessageText: newText,
      chatID: this.chatID,
      isLastMessageInChat: String(isLastMessage),
      isGroup: String(this.isGroup)
    })
      .then(() => {
        // UI
        loadingDialogRef.close()
        this.toast.success('Message edited')
      })
      .catch((error) => {
        loadingDialogRef.close()
        console.log(error)
        this.toast.error('Failed')
      })
  }

  removeMessagesFromMutedUsers(userID: string) {
    for (let i = this.itemList.length - 1; i >= 0; i--) {
      if (this.muteUsersService.isMuted(this.itemList[i].senderUID)) {
        // remove message
        this.itemList.splice(i, 1)
        this.updateVirtualScroll()
      }
    }
  }

  askMute(item: any) {
    const mute24h = () => {
      this.muteUsersService.muteUser_24hours(item.senderUID)
      this.removeMessagesFromMutedUsers(item.senderUID)
    }
    const mutePerma = () => {
      this.muteUsersService.muteUser(item.senderUID)
      this.removeMessagesFromMutedUsers(item.senderUID)
    }

    this.threebuttonsdialogService.show(
      'Mute user',
      "Do you want to mute this user? You will no longer see any activity of this user. You will no longer see messages of this user in chat rooms. \n\nNote that this user can stil write private messages to you. If you don't want that, you need to block the user instead.",
      mute24h,
      mutePerma,
      () => {},
      'Mute user for 24 hours',
      'Mure user permanently',
      'Cancel'
    )
  }

  askDeleteMessage(item: any) {
    this.twobuttonsdialogService.show(
      'Delete message',
      'Do you want to delete this message?',
      () => {
        // nothing
      },
      () => {
        // call cloud function
        const functions = getFunctions()
        const deleteMessage = httpsCallable(functions, 'deleteMessage')

        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        const currIndexOfItem = this.getCurrIndexOfItem(item)

        deleteMessage({
          messageID: item.messageID,
          chatID: this.chatID,
          isGroup: (this.isGroup || this.isGlobalChat) + '',
          isLastMessageInChat:
            (currIndexOfItem == this.itemList.length - 1) + '',
          hubname: StrHlp.CLOUD_PATH
        })
          .then(() => {
            loadingDialogRef.close()
            this.toast.success('Message deleted')
          })
          .catch((error) => {
            loadingDialogRef.close()
            console.log(error)

            // show error message via dialog
            this.oneButtonDialogService.show('Failed', error.message)
          })
      },
      'Cancel',
      'Delete'
    )
  }

  /**
   * Only show the username if the message above is not by the same user
   */
  shouldShowUsername(item: any, index: number) {
    return shouldShowUsername_Static(
      this.itemList,
      this.isPrivate,
      this.userID,
      item,
      index
    )
  }

  shouldMessageHaveMarginTop(item: any, index: number) {
    return shouldMessageHaveMarginTop_Static(
      this.itemList,
      this.isPrivate,
      this.userID,
      item,
      index
    )
  }

  shouldMessageHaveMarginBottom(item: any, index: number) {
    return shouldMessageHaveMarginBottom_Static(
      this.itemList,
      this.isPrivate,
      this.userID,
      item,
      index
    )
  }

  shouldShowUserImage(item: any, index: number) {
    return shouldShowUserImage_Static(
      this.itemList,
      this.isPrivate,
      this.userID,
      item,
      index
    )
  }
  openPreviewFullscreen(src: string) {
    this.fullscreenHelper.open(src, '', 'Preview', '')
  }
}

// ------------

export function shouldShowUsername_Static(
  itemList: any[],
  isPrivate: boolean,
  myUserID: string,
  item: any,
  index: number
) {
  return (
    !isPrivate &&
    (index == 0 || item.messageUID !== itemList[index - 1].messageUID) &&
    item.messageUID !== myUserID
  )
}

export function shouldMessageHaveMarginTop_Static(
  itemList: any[],
  isPrivate: boolean,
  myUserID: string,
  item: any,
  index: number
) {
  return index == 0 || item.messageUID !== itemList[index - 1].messageUID
}

export function shouldMessageHaveMarginBottom_Static(
  itemList: any[],
  isPrivate: boolean,
  myUserID: string,
  item: any,
  index: number
) {
  return (
    index == itemList.length - 1 ||
    item.messageUID !== itemList[index + 1].messageUID
  )
}

export function shouldShowUserImage_Static(
  itemList: any[],
  isPrivate: boolean,
  myUserID: string,
  item: any,
  index: number
) {
  return (
    item.messageUID !== myUserID &&
    (index == itemList.length - 1 ||
      item.messageUID !== itemList[index + 1].messageUID)
  )
}
