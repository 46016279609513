import { Component, OnInit, ViewChild } from '@angular/core'
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  getDoc,
  doc
} from 'firebase/firestore'
import {
  getDatabase,
  ref,
  child,
  get,
  limitToLast,
  query as queryRTDB
} from 'firebase/database'
import { HotToastService } from '@ngneat/hot-toast'
import { StrHlp } from '../../shared/services/StringGetter/getstring.service'
import { HTMLFormattingService } from 'src/app/shared/services/formatting/html/htmlformatting.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { MatDialog } from '@angular/material/dialog'
import { KeyHelperService } from 'src/app/shared/services/firebase/keyhelper.service'
import { _getFocusedElementPierceShadowDom } from '@angular/cdk/platform'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { TimeLimitsService } from 'src/app/shared/services/timelimits/timelimits.service'
import { ActivatedRoute, Router } from '@angular/router'
import { EncodingService } from 'src/app/shared/services/encoding/encoding.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { FullscreenService } from 'src/app/shared/image/fullscreen.service'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { MainComponent } from '../main/main.component'
import { MuteUsersService } from 'src/app/shared/services/muteusers.service'
import { FeeddataService } from 'src/app/shared/services/data/feeddata.service'
import { DatasharingService } from 'src/app/shared/services/data/datasharing.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import PullToRefresh from 'pulltorefreshjs'
import { ResetlatestscoresService } from 'src/app/shared/services/feed/resetlatestscores.service'
import { MainStateService } from 'src/app/shared/services/main/main-state.service'
import {
  Observable,
  ReplaySubject,
  Subscription,
  combineLatest,
  map,
  of,
  startWith,
  takeUntil
} from 'rxjs'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { Location } from '@angular/common'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { LocalstorageService } from 'src/app/shared/services/ssr/localstorage.service'
import { IsBrowserService } from 'src/app/shared/services/ssr/isbrowser.service'
import { SetTimeoutService } from 'src/app/shared/services/ssr/set-timeout.service'

const KEY_HANDLE_SCROLL_DIRECTION = 'KEY_HANDLE_SCROLL_DIRECTION'
const KEY_HANDLE_SCROLL_DIRECTION_MIN_PAUSE = 700

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: [
    './feed.component.css',
    '../settings/settingspage/settings.component.css'
  ]
})
export class FeedComponent implements OnInit {
  ttr_LastStamp = Date.now()
  ttr_Count = 1

  postLoadingInProgress: boolean = false
  latestScore: number = Number.MAX_VALUE
  diffThresholdForReachedBottom: number = 1000
  allowFeedChangeThreshold: number = 200

  db = getFirestore()
  rtdb = ref(getDatabase())
  dbRTDB = getDatabase()
  userID: any = null

  scrollIsAtTop = true
  @ViewChild('virtualScrollViewport')
  virtualScrollViewport!: CdkVirtualScrollViewport
  pullToRefresh: any
  scrollUpSubscription: Subscription | null = null
  private destroyedSubject: ReplaySubject<void> = new ReplaySubject(1)

  countLoadItems: number = 9 // default
  isMobile = false
  urlHash = ''

  // ---

  isNSFW = false
  nsfw_Determined = true // true since default is: no nsfw

  // helper lists. The "real" postList is in feeddataservice
  postIDs: any[] = []
  followingFeed_Posts: any[] = []

  // auto feed
  isAuto = false

  indicateLoading = false
  metaIsFinished = false

  isExplore = false
  exploreTitle = ''
  explore_MostLiked = false
  explore_Trending = false
  explore_Views = false
  showFeedChoosingBar = false
  use_zyklus = false
  latestScore_Init = false

  followingFeed_PostListLoaded = false
  followingFeed_LatestIndexLoaded = 0

  empty = false

  /**
   * 0 = for you
   * 1 = following
   * 2 = recent
   * 3 = nsfw
   * 4 = new (nsfw recent)
   */
  selectedFeed: number = -1

  /**
   * 0 = this month
   * 1 = all time
   */
  explore_TimeOption: number = 0

  profileImg$?: Observable<string>
  myUsername$?: Observable<string>

  isLoggedIn = this.authService.isLoggedIn()
  allowsNSFW = this.StrHlp.getAllowsNSFW()

  headerFadeOut = false
  headerFadeIn = false
  lastScrollTop = 0

  constructor(
    private toast: HotToastService,
    public htmlFormattingService: HTMLFormattingService,
    public numberFormatService: NumberFormatService,
    public dialog: MatDialog,
    public keyHelperService: KeyHelperService,
    public imgHlp: ImageLoadingService,
    private route: ActivatedRoute,
    public encodingService: EncodingService,
    public authService: AuthService,
    public fullscreenHelper: FullscreenService,
    private twobuttonsdialogService: TwobuttonsdialogService,
    public routingHelper: RoutinghelperService,
    public StrHlp: StrHlp,
    private muteUsersService: MuteUsersService,
    public feedDataService: FeeddataService,
    public datasharingService: DatasharingService,
    private router: Router,
    private feedScoreService: ResetlatestscoresService,
    private mainStateService: MainStateService,
    private location: Location,
    private seoHelper: SeoHelperService,
    private localstorageService: LocalstorageService,
    private isBrowserService: IsBrowserService,
    private setTimeoutService: SetTimeoutService,
    private cacheService: CacheService
  ) {}

  ngOnInit(): void {
    this.userID = AuthService.getUID()
    this.isMobile = SystemService.isMobile()

    combineLatest([this.route.paramMap, this.route.queryParams])
      .pipe(takeUntil(this.destroyedSubject))
      .subscribe(([paramMap, params]) => {
        // url has changed
        this.reset()

        if (paramMap.has('feedtype')) {
          const receivedFeedtype = paramMap.get('feedtype')

          if (receivedFeedtype === 'auto') {
            this.isAuto = true
          } else if (receivedFeedtype === 'foryou') {
            this.selectedFeed = 0
            this.saveAutoFeed_Latest(0)
          } else if (receivedFeedtype === 'following') {
            this.selectedFeed = 1
            this.saveAutoFeed_Latest(1)
          } else if (receivedFeedtype === 'recent') {
            this.selectedFeed = 2
            this.saveAutoFeed_Latest(2)
          } else if (
            receivedFeedtype === 'nsfw' ||
            receivedFeedtype === 'hentai'
          ) {
            this.selectedFeed = 3
            this.saveAutoFeed_Latest(3)
          } else if (
            receivedFeedtype === 'nsfw-recent' ||
            receivedFeedtype === 'hentai-recent'
          ) {
            this.selectedFeed = 4
            this.saveAutoFeed_Latest(4)
          }
        }

        //console.log("selFeed:", this.selectedFeed,", isAuto:",this.isAuto, ", isExplore:"+this.isExplore);

        // place this as early as possible
        this.setUpMetaSEO()

        // handle redirect
        const redirectReceived = params['redirect']
        if (redirectReceived) {
          // needed because of going-back and then this being called again endless loop
          this.location.replaceState('/home/auto')
          this.router.navigateByUrl(redirectReceived)
        }

        const exploreType = this.route.snapshot.paramMap.get('explore')
        if (exploreType != null) {
          this.isExplore = true

          if (exploreType === 'likes') {
            this.explore_MostLiked = true
            this.exploreTitle = 'Most Liked'
          } else if (exploreType === 'trending') {
            this.explore_Trending = true
            this.exploreTitle = 'Trending'
          } else if (exploreType === 'views') {
            this.explore_Views = true
            this.exploreTitle = 'Most Viewed'
          }

          // get time specified
          const t = params['t']
          if (typeof t === 'undefined') {
            this.explore_TimeOption = 0 // default
          } else {
            this.explore_TimeOption = t
          }
        }

        // continue loading posts
        if (this.isExplore) {
          this.use_zyklus = false

          if (StrHlp.ALLOWS_NSFW) {
            // first: ask if explore should be NSFW or not
            this.nsfw_Determined = false

            this.twobuttonsdialogService.show(
              'Continue to Explore',
              'Do you want to see regular posts or NSFW posts that may contain nudity?',
              () => {
                this.isNSFW = true
                this.nsfw_Determined = true
                this.loadPosts()
              },
              () => {
                this.isNSFW = false
                this.nsfw_Determined = true
                this.loadPosts()
              },
              'NSFW',
              'Regular',
              false
            )
          } else {
            this.isNSFW = false
            this.loadPosts()
          }
        } else {
          // not explore
          // Load all info we need
          this.determineFeedInformation()

          // Finally load the posts, except its following feed (that calls loadPosts itself at a different point)
          this.loadPosts()
        }
      })

    // scroll to top listener
    this.scrollUpSubscription = this.mainStateService.srollUpSubject.subscribe(
      (tab) => {
        if (tab == 0) {
          this.scrollTop()
        }
      }
    )

    this.setUpProfileIcon()
  }

  setUpProfileIcon() {
    if (this.authService.isLoggedIn() && this.userID) {
      this.profileImg$ = this.cacheService.getProfileImage(this.userID).pipe(
        startWith('/assets/default_profile_pic.jpg'),
        map((img) => this.imgHlp.do(img, 50))
      )

      this.myUsername$ = this.cacheService.getUsername(this.userID)
    } else {
      this.profileImg$ = of('/assets/default_profile_pic.jpg')
      this.myUsername$ = of('you')
    }
  }

  setUpMetaSEO() {
    // prefix with something dependend on this.selectedFeed
    let prefix = ''
    let title = `${StrHlp.APP_NAME}: Trending ${StrHlp.COMMUNITY_NAME} Posts`

    if (this.isAuto) {
      prefix = `For You: Personalized ${StrHlp.COMMUNITY_NAME} content.`
      //prefix = "We remember your latest feed, start browsing where you left.";
    } else if (this.selectedFeed == 0) {
      // for you
      prefix = `For You: Personalized ${StrHlp.COMMUNITY_NAME} content.`
      title = `For You: ${StrHlp.COMMUNITY_NAME} Posts`
    } else if (this.selectedFeed == 1) {
      // for you
      prefix = `Following: See what your friends posted.`
      title = `Following: ${StrHlp.COMMUNITY_NAME} Posts`
    } else if (this.selectedFeed == 2) {
      // for you
      prefix = `Recent: The newest ${StrHlp.COMMUNITY_NAME} content - in chronological order.`
      title = `Recent: ${StrHlp.COMMUNITY_NAME} Posts`
    } else if (this.selectedFeed == 3) {
      // for you
      prefix = `NSFW Hentai Feed.`
      title = `Hentai: ${StrHlp.COMMUNITY_NAME} Posts`
    } else if (this.selectedFeed == 4) {
      // for you
      prefix = `Recent: The newest Hentai.`
      title = `Newest Hentai: ${StrHlp.COMMUNITY_NAME} Posts`
    }

    let desc = this.seoHelper.getGenericDesc()
    if (prefix) {
      desc = `${prefix} ${desc}`
    }

    this.seoHelper.setForSomePage(title, desc, '', '', true)
  }

  reset() {
    this.scrollTop()
    this.feedDataService.clearShownPostList()

    this.isNSFW = false
    this.nsfw_Determined = true
    this.postIDs = []
    this.followingFeed_Posts = []
    this.isAuto = false
    this.indicateLoading = false
    this.metaIsFinished = false
    this.isExplore = false
    this.exploreTitle = ''
    this.explore_MostLiked = false
    this.explore_Trending = false
    this.explore_Views = false
    this.showFeedChoosingBar = false
    this.use_zyklus = false
    this.latestScore_Init = false
    this.followingFeed_PostListLoaded = false
    this.followingFeed_LatestIndexLoaded = 0
    this.empty = false
    this.selectedFeed = -1
    this.explore_TimeOption = 0
  }

  ngAfterViewInit(): void {
    this.setUpOnScrollLoader()

    // set url hash
    this.urlHash = this.router.url

    // apply scroll state
    let scrollTop = 0
    if (MainComponent.scrollSavingMap.has(this.urlHash)) {
      scrollTop = MainComponent.scrollSavingMap.get(this.urlHash)
    }
    this.setTimeoutService.setTimeout(() => {
      // must be inside of this
      this.virtualScrollViewport.elementRef.nativeElement.scrollTop = scrollTop
    }, 0)
    // --

    // pull to refresh
    // ssr-guarded
    if (this.isBrowserService.isBrowser()) {
      this.pullToRefresh = PullToRefresh.init({
        mainElement: '#virtualScrollViewport',
        shouldPullToRefresh: () => {
          return (
            this.virtualScrollViewport.elementRef.nativeElement.scrollTop ==
              0 &&
            !this.indicateLoading &&
            this.dialog.openDialogs.length == 0
          )
        },
        onRefresh: () => {
          // ssr-guarded
          if (typeof window === 'undefined') {
            return
          }
          console.log('Pull to refresh triggered in feed.')
          window.location.reload()
        }
      })
    }
  }

  ngOnDestroy() {
    //console.log("Drago1: router.url="+this.router.url+", scrollTop="+this.body.nativeElement.scrollTop);
    MainComponent.scrollSavingMap.set(
      this.urlHash,
      this.virtualScrollViewport.elementRef.nativeElement.scrollTop
    )

    // destroy pull to refresh to prevent memory leaks
    if (this.isBrowserService.isBrowser()) {
      this.pullToRefresh.destroy()
    }

    if (this.scrollUpSubscription) {
      this.scrollUpSubscription.unsubscribe()
    }

    this.destroyedSubject.next()
    this.destroyedSubject.complete()
  }

  scrollTop() {
    if (this.virtualScrollViewport) {
      this.virtualScrollViewport.elementRef.nativeElement.scrollTop = 0
    }
  }

  setUpOnScrollLoader() {
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    this.virtualScrollViewport.elementRef.nativeElement.addEventListener(
      'scroll',
      () => {
        const scrollTop =
          this.virtualScrollViewport.elementRef.nativeElement.scrollTop
        const scrollHeight =
          this.virtualScrollViewport.elementRef.nativeElement.scrollHeight
        const clientHeight =
          this.virtualScrollViewport.elementRef.nativeElement.clientHeight

        const diffToBottom = scrollHeight - scrollTop - clientHeight
        this.scrollIsAtTop = scrollTop == 0

        if (this.scrollIsAtTop) {
          // if scroll is at top, show bar anyways
          this.makeHeaderFadeIn()
        } else {
          const scrollDirectionIsTop = scrollTop < this.lastScrollTop
          this.lastScrollTop = scrollTop
          this.handleScrollDirection(scrollDirectionIsTop)
          //console.log("scrollDiff: "+diffToBottom);
        }

        if (diffToBottom <= 400) {
          // load new items
          if (
            TimeLimitsService.isAllowed_Session('on-scroll-load-new-items', 700)
          ) {
            if (this.nsfw_Determined) {
              this.loadPosts(true)
            }
          }
        }
      }
    )
  }

  handleScrollDirection(scrollDirectionIsTop: boolean) {
    if (
      TimeLimitsService.isAllowed_Session(
        KEY_HANDLE_SCROLL_DIRECTION,
        KEY_HANDLE_SCROLL_DIRECTION_MIN_PAUSE
      )
    ) {
      this.headerFadeIn = scrollDirectionIsTop
      this.headerFadeOut = !scrollDirectionIsTop
    }
  }

  makeHeaderFadeIn() {
    this.headerFadeIn = true
    this.headerFadeOut = false
  }

  loadLatestFeedLookedAt(): void {
    const localSaved_LatestAutoFeed: string | null =
      this.localstorageService.getItem('localSaved_LatestAutoFeed')

    if (localSaved_LatestAutoFeed) {
      this.selectedFeed = +localSaved_LatestAutoFeed
    }

    if (this.selectedFeed < 0 || this.selectedFeed > 4) {
      // bad input
      this.selectedFeed = 0 // default
    }
  }

  updateInfoNSFW(): void {
    // determine: NSFW
    this.isNSFW = this.selectedFeed == 3 || this.selectedFeed == 4
  }

  updateZyklusUseOrNot(): void {
    this.use_zyklus = this.selectedFeed == 0 || this.selectedFeed == 3
  }

  determineFeedInformation(): void {
    if (this.isAuto) {
      this.loadLatestFeedLookedAt()
    } else {
      // only if not determined already
      if (this.selectedFeed == -1) {
        this.loadLatestFeedLookedAt()
      }
    }

    // nsfw info
    this.updateInfoNSFW()

    // determine: use zyklus or not
    this.updateZyklusUseOrNot()

    // determine: load count
    if (this.selectedFeed == 1) {
      this.countLoadItems = 14
    }

    // assure:
    // following feed does not exist for logged out users
    if (this.selectedFeed == 1 && !this.authService.isLoggedIn()) {
      this.selectedFeed = 0 // default
    }

    // show bar
    this.showFeedChoosingBar = true
  }

  saveAutoFeed_Latest(inp: number) {
    if (this.selectedFeed < 0 || this.selectedFeed > 4) {
      console.log(`saveAutoFeed_Latest: Bad input (${inp})`)
    } else {
      this.localstorageService.setItem('localSaved_LatestAutoFeed', String(inp))
    }
  }

  async loadPosts(triggeredByScroll: boolean = false) {
    // only on client side (yet)
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    // determine feed type
    this.feedDataService.feedType = -1

    const isRecent = this.selectedFeed == 2
    const isRecentNSFW = this.selectedFeed == 4
    const isFollowing = this.selectedFeed == 1

    if (isRecent) {
      this.feedDataService.feedType = 2
    } else if (isFollowing) {
      this.feedDataService.feedType = 1
    } else if (isRecentNSFW) {
      this.feedDataService.feedType = 4
    } else {
      if (this.isExplore) {
        this.feedDataService.feedType = -1
      } else {
        if (this.isNSFW) {
          // nsfw
          this.feedDataService.feedType = 3
        } else {
          // fy
          this.feedDataService.feedType = 0
        }
      }
    }
    // --

    // Feed state saving check
    if (this.feedDataService.feedType != -1 && !triggeredByScroll) {
      // check if there is a saved state for this feed type
      if (
        this.feedDataService.feedType == 0 &&
        this.feedDataService.fyList.length > 0
      ) {
        this.feedDataService.postList.push(...this.feedDataService.fyList)
        this.latestScore = this.feedDataService.latestScore_FY

        // for recycling reasons
        this.feedDataService.postList = [...this.feedDataService.postList]

        return
      }

      if (
        this.feedDataService.feedType == 1 &&
        this.feedDataService.followingList.length > 0
      ) {
        this.feedDataService.postList.push(
          ...this.feedDataService.followingList
        )
        this.latestScore = this.feedDataService.latestScore_Following

        // for recycling reasons
        this.feedDataService.postList = [...this.feedDataService.postList]
        return
      }

      if (
        this.feedDataService.feedType == 2 &&
        this.feedDataService.recentList.length > 0
      ) {
        this.feedDataService.postList.push(...this.feedDataService.recentList)
        this.latestScore = this.feedDataService.latestScore_Recent

        // for recycling reasons
        this.feedDataService.postList = [...this.feedDataService.postList]
        return
      }

      if (
        this.feedDataService.feedType == 3 &&
        this.feedDataService.nsfwList.length > 0
      ) {
        this.feedDataService.postList.push(...this.feedDataService.nsfwList)
        this.latestScore = this.feedDataService.latestScore_Nsfw

        // for recycling reasons
        this.feedDataService.postList = [...this.feedDataService.postList]
        return
      }

      if (
        this.feedDataService.feedType == 4 &&
        this.feedDataService.recentNsfwList.length > 0
      ) {
        this.feedDataService.postList.push(
          ...this.feedDataService.recentNsfwList
        )
        this.latestScore = this.feedDataService.latestScore_NsfwRecent

        // for recycling reasons
        this.feedDataService.postList = [...this.feedDataService.postList]
        return
      }
    }

    // no savings used, indicate loading
    this.indicateLoading = true

    if (this.selectedFeed == 1) {
      this.loadFollowingFeed()
      return
    }

    if (this.use_zyklus) {
      if (!this.latestScore_Init) {
        this.latestScore = this.feedScoreService.getLatestSavedScore(
          false,
          this.isNSFW
        )
        this.latestScore_Init = true

        if (this.latestScore == 0) {
          this.latestScore = Number.MAX_VALUE
        }
      }
    }

    if (this.postLoadingInProgress) {
      return
    }
    this.postLoadingInProgress = true

    let queryPathEnd = 'ForYou'
    if (this.isNSFW) {
      queryPathEnd = 'NSFW'
    }

    // get query
    let q = null
    let scoreNameString: string | null = null

    if (isRecent || isRecentNSFW) {
      scoreNameString = 'semiChronoScore'
      q = query(
        collection(
          this.db,
          `${StrHlp.CLOUD_PATH}/Discover/Trending/Posts/${queryPathEnd}`
        ),
        orderBy(scoreNameString, 'desc'),
        limit(this.countLoadItems),
        where('isVideo', '==', false),
        where('isRepost', '==', false),
        where(scoreNameString, '<', this.latestScore)
      )
    } else {
      let timeZyklusString: string | null = null
      if (this.explore_TimeOption == 0) {
        timeZyklusString = FeedComponent.getMonthZyklusString()
      }
      //console.log(`timeZyklusString: ${timeZyklusString}`);

      if (this.explore_MostLiked) {
        scoreNameString = 'likeCount_Real'
      } else if (this.explore_Trending) {
        scoreNameString = 'viewLikeRatioScore'
      } else if (this.explore_Views) {
        scoreNameString = 'viewCount_Real'
      } else {
        // means: its not explore, but a "default feed"
        scoreNameString = 'viewLikeRatioScore'
      }

      q = query(
        collection(
          this.db,
          `${StrHlp.CLOUD_PATH}/Discover/Trending/Posts/${queryPathEnd}`
        ),
        orderBy(scoreNameString, 'desc'),
        limit(this.countLoadItems),
        where('isVideo', '==', false),
        where(scoreNameString, '<', this.latestScore)
      )

      if (timeZyklusString !== null) {
        q = query(q, where('zyklus', '==', timeZyklusString))
      }
    }

    if (q === null || scoreNameString === null) {
      this.toast.error('An error has occurred')
      return
    }

    // get docs
    const querySnapshot = await getDocs(q)

    if (querySnapshot.size == 0 && this.feedDataService.postList.length == 0) {
      this.empty = true

      if (
        this.feedDataService.feedType == 0 ||
        this.feedDataService.feedType == 3
      ) {
        this.feedScoreService.resetLatestSavedScore(false, this.isNSFW)
      }
    }

    querySnapshot.forEach((doc) => {
      this.postLoadingInProgress = false

      const postID = doc.id

      this.postIDs.push(postID)

      // update last score
      this.latestScore = doc.data()[scoreNameString!]
      if (this.use_zyklus) {
        this.feedScoreService.updateLatestSavedScore(
          false,
          this.isNSFW,
          this.latestScore
        )
      }
      //console.log("latestScore: "  + this.latestScore);

      // update latest score in feed data service
      this.feedDataService.updateLatestScore(
        this.latestScore,
        this.feedDataService.feedType
      )

      // load the corresponding post
      get(child(this.rtdb, `${StrHlp.CLOUD_PATH}/Photo/${postID}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const post = snapshot.val()

            post.postID = postID

            if (!this.muteUsersService.isMuted(post.userID)) {
              // update feed data service
              this.feedDataService.appendNewPost(
                post,
                this.feedDataService.feedType
              )

              // load further info
              this.feedDataService.easyLoadPostInfo(
                this.feedDataService.postList.length - 1
              )

              this.indicateLoading = false
            }
          }
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }

  static getMonthZyklusString(): string {
    // we shift the time by 7 days to the past so we never have an empty feed on zyklus change (month change)
    const today = new Date()
    const dateAsString = new Date(
      today.getTime() - 7 * 24 * 60 * 60 * 1000
    ).toISOString() // '2022-05-27T14:51:06.157Z'
    const zyklusString_Monat = dateAsString.replaceAll('-', '').substring(0, 6) // 202205
    return zyklusString_Monat
  }

  onSelectForYou(): void {
    // block changing feeds while posts are loading
    if (this.indicateLoading) {
      return
    }

    if (
      !TimeLimitsService.isAllowed_Session(
        'change-feed',
        this.allowFeedChangeThreshold
      )
    ) {
      return
    }
    if (this.selectedFeed == 0) {
      // is in this feed already
      // scroll to top instead
      this.virtualScrollViewport.elementRef.nativeElement.scrollTop = 0
      return
    }

    this.selectedFeed = 0

    this.onFeedSelect()
    this.updateLatestFeedSelection()
  }

  onSelectNSFW(): void {
    // block changing feeds while posts are loading
    if (this.indicateLoading) {
      return
    }
    if (
      !TimeLimitsService.isAllowed_Session(
        'change-feed',
        this.allowFeedChangeThreshold
      )
    ) {
      return
    }
    if (this.selectedFeed == 3) {
      // is in this feed already
      // scroll to top instead
      this.virtualScrollViewport.elementRef.nativeElement.scrollTop = 0
      return
    }

    this.selectedFeed = 3

    this.onFeedSelect()
    this.updateLatestFeedSelection()
  }

  onSelectRecentNSFW(): void {
    // block changing feeds while posts are loading
    if (this.indicateLoading) {
      return
    }
    if (
      !TimeLimitsService.isAllowed_Session(
        'change-feed',
        this.allowFeedChangeThreshold
      )
    ) {
      return
    }
    if (this.selectedFeed == 4) {
      // is in this feed already
      // scroll to top instead
      this.virtualScrollViewport.elementRef.nativeElement.scrollTop = 0
      return
    }

    this.selectedFeed = 4

    this.onFeedSelect()
    this.updateLatestFeedSelection()
  }

  onSelectRecent(): void {
    // block changing feeds while posts are loading
    if (this.indicateLoading) {
      return
    }
    if (
      !TimeLimitsService.isAllowed_Session(
        'change-feed',
        this.allowFeedChangeThreshold
      )
    ) {
      return
    }
    if (this.selectedFeed == 2) {
      // is in this feed already
      // scroll to top instead
      this.virtualScrollViewport.elementRef.nativeElement.scrollTop = 0
      return
    }

    this.selectedFeed = 2

    this.onFeedSelect()
    this.updateLatestFeedSelection()
  }

  onSelectFollowing(): void {
    // block changing feeds while posts are loading
    if (this.indicateLoading) {
      return
    }
    if (
      !TimeLimitsService.isAllowed_Session(
        'change-feed',
        this.allowFeedChangeThreshold
      )
    ) {
      return
    }
    if (this.selectedFeed == 1) {
      // is in this feed already
      // scroll to top instead
      this.virtualScrollViewport.elementRef.nativeElement.scrollTop = 0
      return
    }

    this.selectedFeed = 1
    this.countLoadItems = 14

    this.onFeedSelect()
    this.updateLatestFeedSelection()
  }

  onFeedSelect() {
    this.updateInfoNSFW()
    this.updateZyklusUseOrNot()
    this.onFeedChange()

    // reset scroll, i.e. scroll to top
    //MainComponent.scrollTop_Static();
    this.mainStateService.emitScrollUpCall(0) // test

    // reload posts
    this.loadPosts()
  }

  updateLatestFeedSelection(): void {
    this.localstorageService.setItem(
      'localSaved_LatestAutoFeed',
      String(this.selectedFeed)
    )
    //this.localstorageService.setItem("foryou-following-last-opened", String(this.selectedFeed));
  }

  onFeedChange(): void {
    this.feedDataService.postList = []
    this.postIDs = []
    this.followingFeed_Posts = []
    this.followingFeed_PostListLoaded = false
    this.followingFeed_LatestIndexLoaded = 0
    this.latestScore_Init = false
    this.latestScore = Number.MAX_VALUE
    this.empty = false
    this.postLoadingInProgress = false
  }

  loadFollowingFeed(): void {
    if (this.followingFeed_PostListLoaded) {
      this.followingFeed_LoadFurtherPosts()
    } else {
      this.followingFeed_LoadPostList()
    }
  }

  async followingFeed_LoadFurtherPosts() {
    if (this.postLoadingInProgress) {
      return
    }
    this.postLoadingInProgress = true

    const countLoadItems = 14

    const startIndex = this.followingFeed_LatestIndexLoaded + 1
    for (
      let index = startIndex;
      index < this.followingFeed_Posts.length &&
      index - startIndex < countLoadItems;
      index++
    ) {
      this.postLoadingInProgress = false

      const postID = this.followingFeed_Posts[index].postID
      this.postIDs.push(postID)

      // update last score
      this.followingFeed_LatestIndexLoaded = index
      //console.log("latestIndexLoaded: "  + this.latestIndexLoaded);

      // load the corresponding post
      const snapshot = await get(
        child(this.rtdb, `${StrHlp.CLOUD_PATH}/Photo/${postID}`)
      )

      if (snapshot.exists()) {
        const post = snapshot.val()
        post.postID = postID

        if (!this.muteUsersService.isMuted(post.userID)) {
          // update feed data service
          this.feedDataService.appendNewPost(
            post,
            this.feedDataService.feedType
          )

          // load profile image and username
          this.feedDataService.easyLoadPostInfo(
            this.feedDataService.postList.length - 1
          )

          this.indicateLoading = false
        }
      }
    }
  }

  printTimeTrackingInfo(name: string = 'no-name') {
    const disabled = false
    if (disabled) {
      return
    }

    const curr = Date.now()

    const diff = curr - this.ttr_LastStamp

    //console.log("TimeTracking", this.ttr_Count, "stamp:", curr, "Elapsed:",diff, "["+name+"]");

    this.ttr_LastStamp = curr
    this.ttr_Count += 1
  }

  async followingFeed_LoadPostList() {
    // 'anteil-verfahren': First load all userIDs you are following, but limit this to 1000
    // and add yourself, so you see your own posts in here
    const followingUIDs: string[] = []
    followingUIDs.push(this.userID)

    const db = getDatabase()
    const followingMaxUserCount = 800

    const followingRef = queryRTDB(
      ref(db, `${StrHlp.CLOUD_PATH}/Following/${this.userID}`),
      limitToLast(followingMaxUserCount)
    )

    const snapshot = await get(followingRef)

    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key
        followingUIDs.push(childKey!)
      })

      // load the posts via 'anteil-verfahren'
      this.load_Via_AnteilVerfahren(followingUIDs)
    } else {
      this.empty = true
      this.indicateLoading = false
    }
  }

  async load_Via_AnteilVerfahren(followingUIDs: string[]) {
    // Create an array to hold all the promises
    const promises = followingUIDs.map(async (uid) => {
      const recentPostsRef = doc(
        this.db,
        StrHlp.CLOUD_PATH,
        'RecentPosts',
        'Users',
        uid
      )
      const snap = await getDoc(recentPostsRef)

      if (snap.exists()) {
        const data = snap.data().Posts

        if (typeof data !== 'undefined') {
          // iterate over the array
          try {
            for (let i = 0; i < data.length; i++) {
              const el = data[i]

              if (
                typeof el.arg1 !== 'undefined' &&
                typeof el.arg2 !== 'undefined'
              ) {
                // is arg1, arg2
                this.followingFeed_Posts.push({
                  postID: el.arg1,
                  stamp: el.arg2
                })
              } else if (
                typeof el.a !== 'undefined' &&
                typeof el.b !== 'undefined'
              ) {
                // is a, b
                this.followingFeed_Posts.push({
                  postID: el.a,
                  stamp: el.b
                })
              }
            }
          } catch (error) {
            console.log(error)
          }
        }
      }
    })

    // Wait for all promises to resolve
    // Note: They all load simultaneously now, instead waiting for the previous to finish
    // and then loading the next. This is a super big performance change.
    await Promise.all(promises)

    // we have all postIDs with their stamps now
    // sort the array by the stamps, most recent (largest) first
    this.followingFeed_Posts.sort(this.compare_AnteilVerfahrenHelper)

    this.followingFeed_PostListLoaded = true

    // make the first post loading
    this.followingFeed_LoadFurtherPosts()
  }

  // DESC
  compare_AnteilVerfahrenHelper(a: any, b: any) {
    return b.stamp - a.stamp
  }

  identify(index: number, item: any) {
    return item.postID
  }

  openExplore() {
    this.router.navigate(['/explore'])
  }
}
