<a
  class="subtle-button link-wrapper"
  role="button"
  [attr.aria-label]="ariaLabel"
  [routerLink]="link"
  (click)="handleClick()"
  [ngClass]="{
    selected: isSelected,
    'not-selected': !isSelected
  }"
>
  <div>
    {{ text }}

    @if (showInk) {
      <hr
        class="bottom-indicator"
        [ngClass]="{
          'opacity-1': isSelected,
          'opacity-0': !isSelected
        }"
      />
    }
  </div>
</a>
