import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { HotToastService } from '@ngneat/hot-toast'
import { StrHlp } from '../../shared/services/StringGetter/getstring.service'
import { child, get, getDatabase, push, ref } from 'firebase/database'
import { KeyHelperService } from 'src/app/shared/services/firebase/keyhelper.service'
import {
  getDownloadURL,
  getStorage,
  ref as refStorage,
  uploadString
} from 'firebase/storage'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { TimeLimitsService } from 'src/app/shared/services/timelimits/timelimits.service'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { LoadingDialogComponent } from '../dialogs/loading-dialog/loading-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { Router } from '@angular/router'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { BunnyserviceService } from 'src/app/shared/services/uploading/bunnyservice.service'
import { mediumToUpload } from '../templates/upload/mediumToUpload'
import { OnedialogserviceService } from 'src/app/shared/services/dialogs/onedialogservice.service'
import { FullscreenService } from 'src/app/shared/image/fullscreen.service'
import { KeyboardService } from 'src/app/shared/services/overlay/keyboard-service.service'
import { Observable, map, startWith } from 'rxjs'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { LocalstorageService } from 'src/app/shared/services/ssr/localstorage.service'

@Component({
  selector: 'app-makepost',
  templateUrl: './makepost.component.html',
  styleUrls: ['./makepost.component.css']
})
export class MakepostComponent implements OnInit {
  NSFW_POST_APPENDIX_old: string = 'nsfw'
  NSFW_POST_APPENDIX: string = 'na6Zxy'

  post_caption_draft_key: string = 'post_caption_draft_key'
  post_location_draft_key: string = 'post_location_draft_key'

  @ViewChild('captionTextarea') captionTextarea!: ElementRef

  userID: string | null = ''
  rtdb = ref(getDatabase())

  // medium choose helper
  downloadURL_OfImage: string = ''

  captionEntered: string = ''
  locationEntered: string = ''

  isPostNSFW: boolean = false

  dataReceived: any = null

  isRepost: boolean = false

  repostImageURL_Array: string[] = []
  repostVideoID: string = ''
  repostLocation: string = ''
  repostCaption: string = ''
  rePostID: string = ''

  showEmojiPicker: boolean = false
  isMobile: boolean = false
  isDarkmode: boolean = false

  postWasMade: boolean = false
  draftCaption: string | null = null
  draftLocation: string | null = null

  uploadInProgress: boolean = false

  maxPostLength: number = 2000

  myProfileImageURL: string = ''
  mediaChangedCallback: (arr: mediumToUpload[]) => void

  mediaToUpload: mediumToUpload[] = []

  profileImg$: Observable<string> | null = null

  constructor(
    private toast: HotToastService,
    public keyHelperService: KeyHelperService,
    private authService: AuthService,
    public strHlp: StrHlp,
    private dialog: MatDialog,
    private twobuttonsdialogService: TwobuttonsdialogService,
    public router: Router,
    private cacheService: CacheService,
    public imgHlp: ImageLoadingService,
    private bunnyService: BunnyserviceService,
    private oneButtonDialogService: OnedialogserviceService,
    private fullscreenHelper: FullscreenService,
    private keyboardService: KeyboardService,
    private seoHelper: SeoHelperService,
    private localstorageService: LocalstorageService
  ) {
    // This must be in the constructor, not later
    const currNav = this.router.getCurrentNavigation()
    this.dataReceived = currNav?.extras.state

    this.mediaChangedCallback = (arr: mediumToUpload[]) => {
      this.mediaToUpload = arr
    }
  }

  ngOnInit(): void {
    this.seoHelper.setForSomePage(
      'Make post',
      `Share content with the ${StrHlp.COMMUNITY_NAME} community worldwide. Make a post now and start a trend.`
    )

    this.userID = AuthService.getUID()

    this.isMobile = SystemService.isMobile()
    this.isDarkmode = SystemService.isDarkmode()

    if (this.userID) {
      this.profileImg$ = this.cacheService.getProfileImage(this.userID).pipe(
        startWith('/assets/default_profile_pic.jpg'),
        map((img) => this.imgHlp.do(img, 50))
      )
    }

    // check if is repost
    this.isRepost = this.dataReceived?.isRepost ?? false
    this.repostImageURL_Array = this.dataReceived?.repostImageURL_Array ?? ''
    this.repostVideoID = this.dataReceived?.repostVideoID ?? ''
    this.repostLocation = this.dataReceived?.repostLocation ?? ''
    this.repostCaption = this.dataReceived?.repostCaption ?? ''
    this.rePostID = this.dataReceived?.rePostID ?? ''

    if (this.isRepost) {
      this.captionEntered = this.repostCaption
      this.locationEntered = this.repostLocation
    } else {
      // check if there's drafted a caption and location
      this.draftCaption = this.localstorageService.getItem(
        this.post_caption_draft_key
      )

      if (this.draftCaption) {
        this.captionEntered = this.draftCaption
      } else {
        this.draftCaption = ''
      }

      this.draftLocation = this.localstorageService.getItem(
        this.post_location_draft_key
      )
      if (this.draftLocation) {
        this.locationEntered = this.draftLocation
      } else {
        this.draftLocation = ''
      }
    }
  }

  ngAfterViewInit(): void {
    this.setUpHandleDocumentClick()

    // focus on caption input
    //this.captionTextarea.nativeElement.focus();
  }

  setUpHandleDocumentClick() {
    // ssr-guarded
    if (typeof document === 'undefined') {
      return
    }

    const handleDocumentClick = (event: MouseEvent): void => {
      // Check if emoji keyboard is open, and if so, then close if the click is outside of it
      let isEmojiClassFound = false

      if (event && event.composedPath) {
        const path = event.composedPath()
        path.forEach((elem: EventTarget | null) => {
          if (elem && (elem as HTMLElement).classList) {
            const classList = (elem as HTMLElement).classList
            if (classList.contains('emoji-mart')) {
              isEmojiClassFound = true
            }
          }
        })
      }

      const targetID = (event.target as HTMLElement).id
      if (!isEmojiClassFound && targetID !== 'emojiButton') {
        this.hideEmojiKeyboard()
      }
    }

    document.addEventListener('click', handleDocumentClick, false)
  }

  async copyCaption() {
    if (this.captionEntered) {
      try {
        await navigator.clipboard.writeText(this.captionEntered)
        this.toast.success('Copied to clipboard')
      } catch (err) {
        console.error('Failed to copy')
      }
    }
  }

  emojiButtonClick() {
    this.showEmojiPicker = true

    this.keyboardService.openEmoji(
      (emj: string) => {
        this.addEmoji(emj)
      },
      () => {
        this.showEmojiPicker = false
      }
    )
  }

  hideEmojiKeyboard() {
    this.showEmojiPicker = false
  }

  addEmoji(emoji: string) {
    this.captionEntered = `${this.captionEntered}${emoji}`
  }

  async attemptPost(): Promise<void> {
    if (
      this.captionEntered.trim().length == 0 &&
      this.mediaToUpload.length == 0 &&
      this.repostVideoID === '' &&
      this.repostImageURL_Array.length == 0
    ) {
      return
    }

    // check if user logged in
    if (!this.authService.isLoggedIn()) {
      this.authService.showLoginDialog('/makepost')
      return
    }

    if (!TimeLimitsService.isAllowed_Session('make-post', 5000)) {
      this.toast.error("You're too fast")
      return
    }

    if (this.uploadInProgress) {
      return
    } else {
      this.uploadInProgress = true
    }

    // show loading dialog
    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    // check if user is email verified
    if (!this.authService.isEmailVerified()) {
      // show dialog that forces go-back
      this.twobuttonsdialogService.show(
        'Verify email',
        'You cannot continue without verifying your email. If you cannot find our email, please see the spam folder. If that does not help either, try to reload the page. If that does not help, you may have a typo in your email. Go to Settings > Manage Account > Email to check this and change it there.',
        () => {
          // nothing
        },
        () => {
          this.authService.sendVerificationMail()
        },
        'Okay',
        'Resend the email'
      )

      loadingDialogRef.close()
      this.uploadInProgress = false
      return
    }

    this.captionEntered = this.captionEntered.trim()
    this.locationEntered = this.locationEntered.trim()

    const hasMedium = this.mediaToUpload.length > 0

    // determine if post should be NSFW or not
    if (StrHlp.ALLOWS_NSFW) {
      if (hasMedium) {
        // check if user is forced to mark as NSFW
        const snapshot = await get(
          child(
            this.rtdb,
            `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/ForcePostsNSFW`
          )
        )
        let force = false
        if (snapshot.exists()) {
          force = snapshot.val()
        }

        if (force) {
          this.isPostNSFW = true
          this.makePost(loadingDialogRef)
        } else {
          // ask user if post contains nsfw or not
          this.twobuttonsdialogService.show(
            'Does this post contain nudity?',
            'Please answer carefully. If the post contains nudity but is not marked as such, it will be deleted and your account may be banned.',
            () => {
              this.isPostNSFW = true
              this.makePost(loadingDialogRef)
            },
            () => {
              this.isPostNSFW = false
              this.makePost(loadingDialogRef)
            },
            'Contains nudity',
            'No nudity',
            false
          )
        }
      } else {
        // pure text posts dont need to be marked
        this.makePost(loadingDialogRef)
      }
    } else {
      this.makePost(loadingDialogRef)
    }
  }

  async makePost(loadingDialogRef: any) {
    try {
      const functions = getFunctions()
      const cloudFunction = httpsCallable(functions, 'makePost')

      if (this.isRepost) {
        // Repost
        const imageURL =
          this.repostImageURL_Array.length > 0
            ? this.repostImageURL_Array[0]
            : ''
        const image2 =
          this.repostImageURL_Array.length > 1
            ? this.repostImageURL_Array[1]
            : ''
        const image3 =
          this.repostImageURL_Array.length > 2
            ? this.repostImageURL_Array[2]
            : ''
        const image4 =
          this.repostImageURL_Array.length > 3
            ? this.repostImageURL_Array[3]
            : ''
        const image5 =
          this.repostImageURL_Array.length > 4
            ? this.repostImageURL_Array[4]
            : ''

        await cloudFunction({
          hubname: StrHlp.CLOUD_PATH,
          caption: this.repostCaption,
          imageURL: imageURL,
          image2: image2,
          image3: image3,
          image4: image4,
          image5: image5,
          isNSFWPost: '' + (StrHlp.ALLOWS_NSFW && this.isPostNSFW),
          location: this.repostLocation,
          rePostID: this.rePostID,
          vid: this.repostVideoID,
          vidDuration: 0 // TODO
        })
      } else {
        if (this.mediaToUpload.length == 0) {
          // is pure text post
          await cloudFunction({
            hubname: StrHlp.CLOUD_PATH,
            caption: this.captionEntered,
            imageURL: '',
            isNSFWPost: '' + (StrHlp.ALLOWS_NSFW && this.isPostNSFW),
            location: this.locationEntered,
            rePostID: '',
            vid: '',
            vidDuration: 0
          })
        } else {
          // check whether video or image/images
          const firstMedium = this.mediaToUpload[0]

          if (firstMedium.isVideo) {
            // is video, and only one video (multiple not yet possible)

            // upload video
            const vidID = await this.bunnyService.do(firstMedium.videoFile)

            // is video post
            await cloudFunction({
              hubname: StrHlp.CLOUD_PATH,
              caption: this.captionEntered,
              imageURL: '',
              isNSFWPost: '' + (StrHlp.ALLOWS_NSFW && this.isPostNSFW),
              location: this.locationEntered,
              rePostID: '',
              vid: vidID,
              vidDuration: firstMedium.videoDuration
            })
          } else {
            // image/images
            // has an image, upload the image
            let imageURL = ''
            let image2 = ''
            let image3 = ''
            let image4 = ''
            let image5 = ''

            for (let i = 0; i < this.mediaToUpload.length; i++) {
              const medium = this.mediaToUpload[i]
              const downloadURL = await this.uploadImageToStorage(
                medium.imageAsString!
              )

              if (i == 0) {
                imageURL = downloadURL
              } else if (i == 1) {
                image2 = downloadURL
              } else if (i == 2) {
                image3 = downloadURL
              } else if (i == 3) {
                image4 = downloadURL
              } else if (i == 4) {
                image5 = downloadURL
              }
            }

            await cloudFunction({
              hubname: StrHlp.CLOUD_PATH,
              caption: this.captionEntered,
              imageURL: imageURL,
              image2: image2,
              image3: image3,
              image4: image4,
              image5: image5,
              isNSFWPost: '' + (StrHlp.ALLOWS_NSFW && this.isPostNSFW),
              location: this.locationEntered,
              rePostID: '',
              vid: '',
              vidDuration: 0
            })
          }
        }
      }

      // posting was successful
      this.afterPostMade(loadingDialogRef)
    } catch (error: any) {
      console.log(error)
      this.toast.error('An error has occurred')
      this.uploadInProgress = false
      loadingDialogRef.close()
    }
  }

  async uploadImageToStorage(imageAsString: string) {
    const storage = getStorage()

    let key = push(child(this.rtdb, 'Photo')).key
    if (this.isPostNSFW) {
      key += this.NSFW_POST_APPENDIX
    }

    const storageRef = refStorage(
      storage,
      `${StrHlp.CLOUD_PATH}/photos/users/${this.userID}/${key}/img_rsz_`
    )

    const snapshot = await uploadString(storageRef, imageAsString, 'data_url')
    const downloadURL = await getDownloadURL(snapshot.ref)

    return downloadURL
  }

  afterPostMade(loadingDialogRef: any) {
    this.uploadInProgress = false
    loadingDialogRef.close()
    this.toast.success('Posted')
    this.closeThis(true)
  }

  closeThis(postWasMade: boolean) {
    this.postWasMade = postWasMade

    if (postWasMade) {
      this.resetDraft()
    }

    history.back()
  }

  resetDraft() {
    this.localstorageService.removeItem(this.post_caption_draft_key)
    this.localstorageService.removeItem(this.post_location_draft_key)
  }

  ngOnDestroy() {
    // check if caption and location should be persisted
    if (!this.postWasMade && !this.isRepost) {
      let showToast = false

      const cap = this.captionEntered.trim()
      if (this.draftCaption !== cap) {
        this.localstorageService.setItem(this.post_caption_draft_key, cap)

        if (cap) {
          showToast = true
        }
      }

      const loc = this.locationEntered.trim()
      if (this.draftLocation !== loc) {
        this.localstorageService.setItem(this.post_location_draft_key, loc)

        if (loc) {
          showToast = true
        }
      }

      if (showToast) {
        this.toast.show('Post saved as draft')
      }
    }
  }

  textAreaAdjust(element: HTMLTextAreaElement) {
    element.style.height = '1px'
    element.style.height = 25 + element.scrollHeight + 'px'
  }

  openHelpDialog() {
    this.oneButtonDialogService.show(
      'Tips for posting',
      '• To post, simply type what’s on your mind and hit the post button! If you want to add images or videos, click the big square button above the text field.' +
        '<br><br>' +
        '• When posting multiple images, try to choose images with the same aspect ratio (width/height) to avoid grey space at the bottom of your post.' +
        '<br><br>' +
        '• To make it easier for others to find your post, include a caption with keywords that describe your post. Using hashtags is also a great way to make your post more discoverable.'
    )
  }

  openPreviewFullscreen(src: string) {
    this.fullscreenHelper.open(src, '', 'Preview', '')
  }
}
