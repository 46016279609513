import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { RouterModule } from '@angular/router'
import { Observable, of, startWith } from 'rxjs'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  selector: 'app-usernametemplate',
  templateUrl: './usernametemplate.component.html',
  styleUrls: ['./usernametemplate.component.css']
})

/**
 * The loading of username happens inside of here. Same for if verified.
 */
export class UsernametemplateComponent {
  defaultUsername = 'Loading...'

  userID: string = ''
  groupID: string = ''
  isGroup = false
  nameInp: string = ''
  verifiedInp: boolean = false
  clickAction: (() => void) | null = null
  disableClick: boolean = false
  smallType1: boolean = false
  bigType1: boolean = false
  bigType2: boolean = false
  darkModeLighter: boolean = false
  greyBackground: boolean = false
  whiteText: boolean = false
  textShadow: boolean = false

  //nameSubject: BehaviorSubject<string> = new BehaviorSubject<string>("Loading...");

  name$?: Observable<string>
  verified$?: Observable<boolean>

  createAsDiv: boolean = false

  @Input()
  set values(values: any) {
    this.userID = values.userID
    this.bigType1 = values.bigType1
    this.bigType2 = values.bigType2
    this.groupID = values.groupID
    this.nameInp = values.nameInp
    this.verifiedInp = values.verifiedInp
    this.clickAction = values.clickAction
    this.disableClick = values.disableClick
    this.smallType1 = values.smallType1
    this.darkModeLighter = values.darkModeLighter
    this.greyBackground = values.greyBackground
    this.whiteText = values.whiteText
    this.textShadow = values.textShadow
    this.createAsDiv = values.createAsDiv ?? false

    if (this.groupID) {
      this.isGroup = true
    } else {
      this.isGroup = false
    }

    this.itemChanged()
  }

  // needed for recycling reasons
  private itemChanged() {
    // check if name is specified or should be loaded
    if (this.verifiedInp) {
      this.verified$ = of(true)
    } else {
      if (this.isGroup) {
        this.verified$ = of(false)
      } else {
        // load verified
        this.verified$ = this.cacheService
          .getVerified(this.userID)
          .pipe(startWith(false))
      }
    }

    if (this.nameInp) {
      this.name$ = of(this.nameInp)
    } else {
      if (this.isGroup) {
        this.name$ = this.cacheService.getGroupName(this.groupID)
      } else if (this.userID) {
        this.name$ = this.cacheService.getUsername(this.userID)
      }
    }
  }

  constructor(public cacheService: CacheService) {}

  actionClick() {
    if (this.clickAction) {
      this.clickAction()
    } else {
      if (this.disableClick) {
        return
      }
    }
  }
}
