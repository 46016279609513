@if (
  newLikeNotifCount ||
  newCommentsNotifCount ||
  newFollowNotifCount ||
  newOtherNotifCount
  ) {
  <a
    [routerLink]="['notifications']"
    [state]="{ newNotificationsCount: newNotificationsCount }"
  [ngClass]="{
    'red-box-top': triangleAtTop,
    'red-box-bottom': !triangleAtTop
  }"
    >
    @if (newLikeNotifCount) {
      <div class="red-box-category">
        <img
          src="/assets/ic_heart_like_filled_black.png"
          class="red-box-category-image"
          alt="icon heart"
          />
        <span class="red-box-category-text">
          {{ newLikeNotifCount }}
        </span>
      </div>
    }
    @if (newCommentsNotifCount) {
      <div class="red-box-category">
        <img
          src="/assets/navbar_chat_selected_black.png"
          class="red-box-category-image"
          alt="icon comment"
          />
        <span class="red-box-category-text">
          {{ newCommentsNotifCount }}
        </span>
      </div>
    }
    @if (newFollowNotifCount) {
      <div class="red-box-category">
        <img
          src="/assets/profile_selected_2.png"
          class="red-box-category-image"
          alt="icon user"
          />
        <span class="red-box-category-text">
          {{ newFollowNotifCount }}
        </span>
      </div>
    }
    @if (newOtherNotifCount) {
      <div class="red-box-category">
        <img
          src="/assets/menu_waagerecht.png"
          class="red-box-category-image"
          alt="icon other"
          />
        <span class="red-box-category-text">
          {{ newOtherNotifCount }}
        </span>
      </div>
    }
  </a>
}
