import { Injectable } from '@angular/core'
import { IsBrowserService } from './isbrowser.service'

/**
 * Nodejs friendly localstorage plus a few helper additions
 * Needed because we now run SSR.
 */
@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
  private isBrowser

  constructor(private isBrowserService: IsBrowserService) {
    //const localStorageDefined = typeof localStorage !== 'undefined';
    this.isBrowser = this.isBrowserService.isBrowser()
  }

  getItem(key: string, value?: any) {
    if (this.isBrowser) {
      return localStorage.getItem(key) ?? value
    } else {
      return value
    }
  }

  setItem(key: string, value: any) {
    if (this.isBrowser) {
      localStorage.setItem(key, value)
    } else {
      return
    }
  }

  removeItem(key: string) {
    if (this.isBrowser) {
      localStorage.removeItem(key)
    } else {
      return
    }
  }
}
