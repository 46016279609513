import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { AuthService } from '../../shared/services/auth/auth.service'
import { StrHlp } from '../../shared/services/StringGetter/getstring.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['../sign-in/sign-in.component.css']
})
export class EmailComponent implements OnInit {
  emailEntered: string = ''
  passwordEntered: string = ''
  passwordVisible: boolean = false
  indicateLoading: boolean = false

  doRedirect: boolean = false
  redirect: string = ''

  errorHint: string = ''

  constructor(
    public authService: AuthService,
    public StrHlp: StrHlp,
    private route: ActivatedRoute,
    private twobuttonsdialogService: TwobuttonsdialogService,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage(
      'Log in',
      `Log in via email. ${this.seoHelper.getGenericDesc()}`
    )

    this.route.queryParams.subscribe((params) => {
      const redirectReceived = params['redirect']
      if (typeof redirectReceived === 'undefined') {
        this.redirect = '' // default
        this.doRedirect = false
      } else {
        this.redirect = redirectReceived
        this.doRedirect = true
      }
    })

    // check if already logged in
    if (this.authService.isLoggedIn()) {
      this.authService.afterLogInSuccess(this.redirect)
    }
  }

  public forgotPasswordDialog() {
    this.errorHint = ''

    if (!this.emailEntered) {
      this.errorHint = 'Enter email to continue'
      return
    }

    if (!this.isEmailValid()) {
      this.errorHint = 'Invalid email'
      return
    }

    this.twobuttonsdialogService.show(
      'Reset password?',
      'Do you want us to send you an email to reset your password?',
      () => {
        // nothing
      },
      () => {
        this.authService.forgotPassword(this.emailEntered)
      },
      'Cancel',
      'Okay'
    )
  }

  changePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible
  }

  login(): void {
    this.errorHint = ''

    // check if email is valid
    if (!this.isEmailValid()) {
      // show error hint
      this.errorHint = 'Invalid email'
      return
    }

    // show loading
    this.indicateLoading = true

    const loginFailCallback = (): void => {
      this.indicateLoading = false
    }

    this.indicateLoading = true

    // actual login
    this.authService.logInViaMail(
      this.emailEntered,
      this.passwordEntered,
      this.redirect,
      loginFailCallback
    )
  }

  isEmailValid(): boolean {
    const regex =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
    return regex.test(this.emailEntered)
  }
}
