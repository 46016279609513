<div #wrapper id="singe-post-page-wrapper" class="post-wrapper">
  <div class="header">
    <app-backbuttontemplate />
    <p class="single-post-title">
      <strong>Post</strong>
    </p>
  </div>

  @if (post) {
    <post-template
      [post]="{
        post: post,
        indexInList: 0,
        hideMuteButton: true,
        postEnv: 0
      }"
    />
  } @else {
    <app-loadingcircle id="loading-indicator" />
  }

  <!-- BRING BACK BELOW -->
  <!--
    <div id="comments" *ngIf="feedDataService.postList.length > 0">
        <app-commentpagetemplate
            #commentpage
            [isReplies]="false"
            [originalComment]="null"
            [post]="feedDataService.postList[0]"
            [openKeyboardAlready]="false"
            [showCancelButtonAtTop]="false">
        </app-commentpagetemplate>
    </div>
    -->
</div>
