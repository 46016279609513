import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'app-emptyhinttemplate',
  templateUrl: './emptyhinttemplate.component.html',
  styleUrls: ['./emptyhinttemplate.component.css']
})
export class EmptyhinttemplateComponent {
  @Input() text: string = 'Empty...'
  @Input() whiteShadowBG: boolean = false
  @Input() bigPadding: boolean = true
}
