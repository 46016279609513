import { inject } from '@angular/core'
import { ResolveFn } from '@angular/router'
import { GroupLoadingService } from '../shared/services/groups/group-loading.service'
import { GroupInfo } from '../shared/services/groups/GroupInfo'

export const GroupPageResolver: ResolveFn<any> = async (
  route,
  state
): Promise<GroupInfo | null> => {
  const paramMap = route.paramMap
  const groupIdInput = paramMap.get('groupID')!
  const linkIdInput = paramMap.get('link')!

  const groupLoadingService = inject(GroupLoadingService)
  const groupInfo = await groupLoadingService.load(groupIdInput, linkIdInput)

  return groupInfo
}
