import { Injectable } from '@angular/core'
import { OnedialogserviceService } from './dialogs/onedialogservice.service'
import { LocalstorageService } from './ssr/localstorage.service'

@Injectable({
  providedIn: 'root'
})
export class PinnedchatsService {
  private maxChats = 10
  private chatIDs: string[] = []

  storageKey = 'pinned-chatIDs'

  constructor(
    private oneButtonDialogService: OnedialogserviceService,
    private localstorageService: LocalstorageService
  ) {
    this.loadChats()
  }

  private loadChats(): void {
    const chats = this.localstorageService.getItem(this.storageKey)
    if (chats) {
      this.chatIDs = JSON.parse(chats)
    }
  }

  getChats(): string[] {
    return this.chatIDs
  }

  addChat(chatID: string): void {
    if (this.chatIDs.length >= this.maxChats) {
      this.oneButtonDialogService.show(
        'Limit reached',
        `You can only pin up to ${this.maxChats} chats.`
      )
      return
    }
    this.chatIDs.push(chatID)
    this.localstorageService.setItem(
      this.storageKey,
      JSON.stringify(this.chatIDs)
    )
  }

  removeChat(chatID: string): void {
    const index = this.chatIDs.indexOf(chatID)
    if (index > -1) {
      this.chatIDs.splice(index, 1)
      this.localstorageService.setItem(
        this.storageKey,
        JSON.stringify(this.chatIDs)
      )
    }
  }
}
