import { Injectable } from '@angular/core'
import { AuthService } from '../auth/auth.service'
import {
  child,
  get,
  getDatabase,
  onChildAdded,
  onChildRemoved,
  onValue,
  ref,
  set
} from 'firebase/database'
import { StrHlp } from '../StringGetter/getstring.service'
import { MainComponent } from 'src/app/components/main/main.component'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})

/**
 * data service for maincomponent related data
 */
export class MaindataService {
  userID: any = null

  lastSeenNotificationsPath = ''
  totalNotificationsPath = ''
  newAbbosPath = ''
  newLikesPath = ''
  newCommentsPath = ''

  loadedDetailedNotifInfo: boolean = false

  // vars are public (bad practice I guess)
  totalNotificationsCount: number = -1
  lastSeenNotificationsCount: number = -1
  newNotificationsCount: number = 0
  newChatsCount: number = 0

  newLikeNotifCount: number = 0
  newCommentsNotifCount: number = 0
  newFollowNotifCount: number = 0
  newOtherNotifCount: number = 0

  accountBanned = false
  IPBanned = false

  gcLastSeenCount: number = 0
  gcTotalMessagesCount: number = 0
  areThereNewGC: boolean = false
  // --

  // Callbacks are public (bad practice) so we can set them outside
  /*
  public callback_chatsCountIncrease: (() => void)|null = null;
  public callback_chatsCountDecrease: (() => void)|null = null;

  public callback_UpdateLastSeenCountGC: ((arg0: number) => void)|null = null;
  public callback_UpdateTotalCountGC: ((arg0: number) => void)|null = null;

  public callback_AfterMessagesChangedGC: (() => void)|null = null;

  public callback_AccountBanned: (() => void)|null = null;
  public callback_IPBanned: (() => void)|null = null;

  public callback_UpdateTotalNotifCount: ((arg0: number) => void)|null = null;
  public callback_UpdateLastSeenNotifCount: ((arg0: number) => void)|null = null;
  
  public callback_UpdateDetailedNotifInfo: ((newLikeNotifCount:number, 
                                          newCommentsNotifCount:number, 
                                          newFollowNotifCount:number) => void)|null = null;
                                          */
  // --

  constructor(private http: HttpClient) {
    this.userID = AuthService.getUID()

    if (this.userID) {
      this.lastSeenNotificationsPath = `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/LastNotifiesSeenCount`
      this.totalNotificationsPath = `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/TotalNotifiesCount`
      this.newLikesPath = `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/NewLikesCount`
      this.newCommentsPath = `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/NewCommentsCount`
      this.newAbbosPath = `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/NewAbbosCount`

      this.load()
    }
  }

  private load() {
    this.setUpChatCountLoader()
    this.checkIfBanned()
    this.setUpNotificationStuff()
  }

  setUpChatCountLoader(): void {
    const db = getDatabase()

    // child listener
    const chatsRef = ref(
      db,
      `${StrHlp.CLOUD_PATH}/Chats_AnzahlNeue/${this.userID}`
    )

    onChildAdded(chatsRef, (data) => {
      this.increaseNewChatsCount()
    })
    onChildRemoved(chatsRef, async (data) => {
      this.decreaseNewChatsCount()
    })

    get(
      ref(
        db,
        `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/HasLeftGC`
      )
    ).then((snapshot) => {
      let hasLeftGC = false
      if (snapshot.exists()) {
        hasLeftGC = snapshot.val()
      }

      if (!hasLeftGC) {
        // add always listeners for lastSeenMessagesCount and GC total messages
        const gcLastSeenCountRef = ref(
          db,
          `${StrHlp.CLOUD_PATH}/GlobalChat/${this.userID}/LastMessageCount`
        )
        onValue(gcLastSeenCountRef, async (snapshot) => {
          if (snapshot.exists()) {
            this.updateLastSeenCountGC(snapshot.val())
            this.afterMessagesChangedGC()
          }
        })

        const gcTotalMessagesCount = ref(
          db,
          `${StrHlp.CLOUD_PATH}/GlobalChat/MessageCount`
        )
        onValue(gcTotalMessagesCount, async (snapshot) => {
          if (snapshot.exists()) {
            this.updateTotalCountGC(snapshot.val())
            this.afterMessagesChangedGC()
          }
        })
      }
    })
  }

  checkIfBanned(): void {
    const db = getDatabase()
    const rtdb = ref(getDatabase())

    // user ban
    if (AuthService.isUserLoggedIn()) {
      const refLastSeenCount = ref(db, `BannedUsers/${this.userID}`)
      onValue(refLastSeenCount, (snapshot) => {
        if (snapshot.exists()) {
          // account is banned
          this.onAccountBanned()
        }
      })
    }
    // --

    // IP ban
    this.http
      .get('https://api.ipify.org/?format=json')
      .subscribe((data: any) => {
        if (data !== null) {
          let ip = data.ip

          if (ip !== null) {
            // for firebase rules
            ip = ip.replaceAll('.', '_')

            if (ip.length > 2) {
              // save the IP
              if (AuthService.isUserLoggedIn()) {
                set(child(rtdb, `TechnicalUserDetails/${this.userID}/ip`), ip)
              }

              // check if IP is banned (here only via single listener)
              get(child(rtdb, `BlockedIPs/${ip}`)).then((snapshot) => {
                if (snapshot.exists()) {
                  this.onIPBanned()
                }
              })
            }
          }
        }
      })
    // --

    if (AuthService.isUserLoggedIn()) {
      // check temporary chat ban
      const refTempChatBan = ref(
        db,
        `${StrHlp.CLOUD_PATH}/UserLimits/Blocked/${this.userID}/Chatting/Temp`
      )
      onValue(refTempChatBan, (snapshot) => {
        if (snapshot.exists()) {
          const bannedUntil = snapshot.val()
          if (Date.now() < bannedUntil) {
            MainComponent.tempChatBanned = true
          }
        }
      })
      // --

      // check permanent chat ban
      const refPermChatBan = ref(
        db,
        `${StrHlp.CLOUD_PATH}/UserLimits/Blocked/${this.userID}/Chatting/Permanently`
      )
      onValue(refPermChatBan, (snapshot) => {
        if (snapshot.exists()) {
          MainComponent.permChatBanned = true
        }
      })
      // --

      // check temporary GC ban
      const refTempChatBan_GC = ref(
        db,
        `${StrHlp.CLOUD_PATH}/UserLimits/Blocked/${this.userID}/Chatting/TempGC`
      )
      onValue(refTempChatBan_GC, (snapshot) => {
        if (snapshot.exists()) {
          const bannedUntil = snapshot.val()
          if (Date.now() < bannedUntil) {
            MainComponent.tempChatBanned_GC = true
          }
        }
      })
      // --

      // check permanent GC ban
      const refPermChatBan_GC = ref(
        db,
        `${StrHlp.CLOUD_PATH}/UserLimits/Blocked/${this.userID}/GlobalChat`
      )
      onValue(refPermChatBan_GC, (snapshot) => {
        if (snapshot.exists()) {
          const bannedUntil = snapshot.val()
          if (Date.now() < bannedUntil) {
            MainComponent.permChatBanned_GC = true
          }
        }
      })
      // --
    }
  }

  /**
   * System is simple:
   * Two always-listeners. One on the total-notif-count, and one on the last-seen-notif-count.
   * Each time one changes, populate the difference to the UI.
   * Whenever /notifications is opened, update the last-seen-notif-count to the total-notif-count.
   */
  setUpNotificationStuff() {
    const db = getDatabase()

    const refTotalCount = ref(db, this.totalNotificationsPath)
    onValue(refTotalCount, (snapshot) => {
      this.updateTotalNotifCount(snapshot.val())

      if (!this.loadedDetailedNotifInfo) {
        this.loadedDetailedNotifInfo = true

        // load detailed new notifs info only once (so only reloaded after an actual refresh)
        get(ref(db, this.newLikesPath)).then((snapshot) => {
          let newLikeNotifCount = 0
          if (snapshot.exists()) {
            newLikeNotifCount = snapshot.val()
          }

          get(ref(db, this.newAbbosPath)).then((snapshot) => {
            let newFollowNotifCount = 0
            if (snapshot.exists()) {
              newFollowNotifCount = snapshot.val()
            }

            get(ref(db, this.newCommentsPath)).then((snapshot) => {
              let newCommentsNotifCount = 0
              if (snapshot.exists()) {
                newCommentsNotifCount = snapshot.val()
              }
              this.updateDetailedNotifInfo(
                newLikeNotifCount,
                newCommentsNotifCount,
                newFollowNotifCount
              )
            })
          })
        })
      }
    })

    const refLastSeenCount = ref(db, this.lastSeenNotificationsPath)
    onValue(refLastSeenCount, (snapshot) => {
      this.updateLastSeenNotifCount(snapshot.val())
    })
  }

  onAccountBanned() {
    this.accountBanned = true
  }
  onIPBanned() {
    this.IPBanned = true
  }

  updateLastSeenCountGC(val: number) {
    this.gcLastSeenCount = val
  }
  updateTotalCountGC(val: number) {
    this.gcTotalMessagesCount = val
  }

  increaseNewChatsCount() {
    this.newChatsCount++
  }
  decreaseNewChatsCount() {
    this.newChatsCount--
  }

  updateLastSeenNotifCount(val: number) {
    this.lastSeenNotificationsCount = val
    this.afterTotalOrSeenNotifCountUpdate()
  }

  updateTotalNotifCount(val: number) {
    this.totalNotificationsCount = val
    this.afterTotalOrSeenNotifCountUpdate()
  }

  afterTotalOrSeenNotifCountUpdate() {
    if (
      this.totalNotificationsCount != -1 &&
      this.lastSeenNotificationsCount != -1
    ) {
      this.newNotificationsCount =
        this.totalNotificationsCount - this.lastSeenNotificationsCount
    }
  }

  afterMessagesChangedGC(): void {
    let newVal_AreThereNewGC = false
    if (this.gcTotalMessagesCount - this.gcLastSeenCount > 0) {
      // there are new messages
      newVal_AreThereNewGC = true
    }

    if (this.areThereNewGC) {
      if (!newVal_AreThereNewGC) {
        // decrease counter
        this.newChatsCount--
      }
    } else {
      if (newVal_AreThereNewGC) {
        // increase counter
        this.newChatsCount++
      }
    }

    this.areThereNewGC = newVal_AreThereNewGC
  }

  updateDetailedNotifInfo(
    newLikeNotifCount: number,
    newCommentsNotifCount: number,
    newFollowNotifCount: number
  ) {
    this.newLikeNotifCount = newLikeNotifCount
    this.newCommentsNotifCount = newCommentsNotifCount
    this.newFollowNotifCount = newFollowNotifCount

    this.newOtherNotifCount =
      this.newNotificationsCount -
      (this.newLikeNotifCount +
        this.newCommentsNotifCount +
        this.newFollowNotifCount)
    if (this.newOtherNotifCount < 0) {
      this.newOtherNotifCount = 0
    }
  }

  onOpenNotifTab() {
    const rtdb = ref(getDatabase())

    // update notif counts cloud
    set(
      child(rtdb, this.lastSeenNotificationsPath),
      this.totalNotificationsCount
    )
    // ... locally
    this.lastSeenNotificationsCount = this.totalNotificationsCount
    this.afterTotalOrSeenNotifCountUpdate()

    // update detailed notifs cloud
    set(child(rtdb, this.newLikesPath), 0)
    set(child(rtdb, this.newCommentsPath), 0)
    set(child(rtdb, this.newAbbosPath), 0)
    // ... locally
    this.updateDetailedNotifInfo(0, 0, 0)
  }
}
