<a
  class="main-branding-wrapper"
  routerLink="/home/auto"
  data-testid="mainbranding-anchor"
>
  <img
    class="main-branding-logo"
    src="/assets/applogo_transparent.png"
    alt="{{ name }} logo"
    data-testid="mainbranding-image"
  />

  <h1 id="branding-app-title" data-testid="mainbranding-name">
    {{ name }}
  </h1>
</a>
