import { Component, OnInit } from '@angular/core'
import { getDatabase } from 'firebase/database'
import { MatDialog } from '@angular/material/dialog'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { MuteUsersService } from 'src/app/shared/services/muteusers.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { take } from 'rxjs'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-managemutedusers',
  templateUrl: './managemutedusers.component.html',
  styleUrls: [
    '../../user/followers/followers.component.css',
    '../../feed/feed.component.css',
    '../../settings/settingspage/settings.component.css',
    '../../user/user.component.css'
  ]
})
export class ManagemutedusersComponent implements OnInit {
  userID: any = ''
  mutedusers: any[] = []
  isLoading: boolean = true
  isEmpty: boolean = false

  constructor(
    public dialog: MatDialog,
    private twobuttonsdialogService: TwobuttonsdialogService,
    public routingHelper: RoutinghelperService,
    public imgHlp: ImageLoadingService,
    private muteUsersService: MuteUsersService,
    private cacheService: CacheService,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage(
      'Manage muted users',
      'Manage muted users settings'
    )
    this.userID = AuthService.getUID()
  }

  ngAfterViewInit(): void {
    this.loadItems()
  }

  unmuteUser(
    username: string,
    uid: string,
    temp: boolean,
    index: number
  ): void {
    // first ask if sure to unblock
    this.twobuttonsdialogService.show(
      'Unmute',
      'Do you want to unmute ' + username + '?',
      () => {
        // nothing
      },
      () => {
        this.muteUsersService.unmuteUser(uid, temp)
        this.mutedusers.splice(index, 1)

        this.isEmpty = this.mutedusers.length == 0
      },
      'Cancel',
      'Unmute'
    )
  }

  async loadItems() {
    const db = getDatabase()

    if (this.userID !== null) {
      const tempList = this.muteUsersService.getList24h()
      const permaList = this.muteUsersService.getListPerma()

      this.isLoading = false
      this.isEmpty = tempList.length == 0 && permaList.length == 0

      for (let i = 0; i < tempList.length; i++) {
        const uid = tempList[i]

        this.cacheService
          .getUsername(uid)
          .pipe(take(1))
          .subscribe((username) => {
            this.mutedusers.push({
              userID: uid,
              username: username,
              temp: true
            })

            this.isLoading = false
          })
      }

      for (let i = 0; i < permaList.length; i++) {
        const uid = permaList[i]

        this.cacheService
          .getUsername(uid)
          .pipe(take(1))
          .subscribe((username) => {
            this.mutedusers.push({
              userID: uid,
              username: username,
              temp: true
            })

            this.isLoading = false
          })
      }
    }
  }
}
