<!-- Check if banned -->
@if (maindataService.accountBanned) {
  <app-account-banned />
}
@if (maindataService.IPBanned) {
  <app-ip-banned />
}

<!-- Main content if not banned -->
@if (!maindataService.accountBanned && !maindataService.IPBanned) {
  <div class="wrapper-outer full-screen-height">
    <!-- 
      Now consists of three rows.
      Its realized as two rows though, but the second row, the "main",
      is split into two rows again.
      Note: "Top bar"-row has been removed

      Each row is responsible for scrolling itself.

      This is not yet universally adapted. Project needs to be rewritten
      piece after piece. Do it hand it hand with SSR.
    -->
    <div class="header-main-container">
      <app-main-header class="main-header" />
      <app-main-row class="main-content" />
    </div>
  </div>
}
