<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate />
    <h1 class="title">Push notifications settings</h1>
  </header>

  <main class="fh-main content">
    <div class="below-text-wrapper">
      <label class="radio">
        <input
          name="radio"
          type="checkbox"
          (change)="update_UnreadChats()"
          [(ngModel)]="activated_UnreadChats"
          name="action"
        />

        <span class="radio-text">
          Receive hints for unread chats. No more than once per day.
        </span>
      </label>

      <label class="radio">
        <input
          name="radio"
          type="checkbox"
          name="action"
          (change)="update_UnreadNotifs()"
          [(ngModel)]="activated_UnreadNotifs"
        />

        <span class="radio-text">
          Receive hints about unread notifications. No more than once per day.
        </span>
      </label>

      <label class="radio">
        <input
          name="radio"
          type="checkbox"
          name="action"
          (change)="update_NewComments()"
          [(ngModel)]="activated_NewComments"
        />

        <span class="radio-text">
          Receive hints for new comments. No more than once per day.
        </span>
      </label>

      <label class="radio">
        <input
          name="radio"
          type="checkbox"
          name="action"
          (change)="update_ChatsGeneral()"
          [(ngModel)]="activated_ChatsGeneral"
        />

        <span class="radio-text">
          Receive push notifications for new chats. Don't use this option to
          mute single chats. This option will mute all your existing chats
          together. You can mute a single chat by opening it and clicking the
          bell icon at the top.
        </span>
      </label>
    </div>
  </main>
</div>
