import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { HotToastService } from '@ngneat/hot-toast'
import {
  get,
  getDatabase,
  increment,
  ref,
  remove,
  set
} from 'firebase/database'
import { FullscreenService } from 'src/app/shared/image/fullscreen.service'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { OnedialogserviceService } from 'src/app/shared/services/dialogs/onedialogservice.service'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { KeyHelperService } from 'src/app/shared/services/firebase/keyhelper.service'
import { EncodingService } from 'src/app/shared/services/encoding/encoding.service'
import { HTMLFormattingService } from 'src/app/shared/services/formatting/html/htmlformatting.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import firebase from 'firebase/compat/app'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { ReportComponent } from '../report/report.component'
import { LoadingDialogComponent } from '../dialogs/loading-dialog/loading-dialog.component'
import { ChatDataService } from 'src/app/shared/services/data/chatdata.service'
import { CreategroupComponent } from '../groups/creategroup/creategroup.component'
import { FollowersComponent } from '../user/followers/followers.component'
import { GroupserviceService } from 'src/app/shared/services/groups/groupservice.service'
import { ChatactionComponent } from '../dialogs/chataction/chataction.component'
import { ActivatedRoute, Router } from '@angular/router'
import { ReplaySubject, from, take } from 'rxjs'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { SITE_PROTOCOL } from 'src/app/shared/constants'
import { IsBrowserService } from 'src/app/shared/services/ssr/isbrowser.service'
import { TransferStateHelperService } from 'src/app/shared/services/ssr/transfer-state-helper.service'
import { GroupInfo } from 'src/app/shared/services/groups/GroupInfo'
import { MatBottomSheet } from '@angular/material/bottom-sheet'

// groupID, msgCount, name, image, areYouAdmin, desc, created, memberscount, areYouMember, showJoinSection

@Component({
  selector: 'app-groupinfo',
  templateUrl: './groupinfo.component.html',
  styleUrls: [
    './groupinfo.component.css',
    '../user/user.component.css',
    '../makepost/makepost.component.css'
  ]
})

/**
 * MAKE LOADING FASTER BY USING PROMISE.ALL
 * THOSE THAT ARE NOT REQUIRED FOR OTHERS TO LOAD SHOULD BE BUNDLED
 */
export class GroupinfoComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Since route might change w.o. reload of component, but just paramMap observable
   * firing, we need such a boolean. Th is will be set "true" at the end of a loading process.
   * A loading process will check if transfer state exists
   */
  stateAlreadyTransferred: boolean = false

  private bottomSheet = inject(MatBottomSheet)

  @ViewChild('descEl') descEl!: ElementRef
  @ViewChild('contentWrapper') contentWrapper!: ElementRef
  scrollIsAtTop = false

  rtdb = getDatabase()

  isLoggedIn = this.authService.isLoggedIn()
  myUID = this.authService.getUserID()
  isMobile: boolean = false

  // ---

  areYouGroupAdmin = false
  isGlobalChat = false

  groupInfo: GroupInfo

  descCollapsedLineCount = 15
  descLineCount = -1
  descExceedsLineCount = false
  descCollapsed = true
  descCount_Helper_LastInnerText = ''

  invitationLinkActivated = false
  inviteLink = ''
  inviteLinkID = ''
  linkVisible = 0 // -1 = hidden, 0 = loading, 1 = visible

  dataReceived: any = null

  areYouMember = false
  showJoinSection = false

  private destroyedSubject: ReplaySubject<void> = new ReplaySubject(1)

  constructor(
    public numberFormatService: NumberFormatService,
    public htmlFormattingService: HTMLFormattingService,
    public dialog: MatDialog,
    public keyHelperService: KeyHelperService,
    public imgHlp: ImageLoadingService,
    public encodingService: EncodingService,
    public authService: AuthService,
    public fullscreenHelper: FullscreenService,
    private oneButtonDialogService: OnedialogserviceService,
    private toast: HotToastService,
    private groupsService: GroupserviceService,
    private router: Router,
    private route: ActivatedRoute,
    private twobuttonsdialogService: TwobuttonsdialogService,
    private chatdataService: ChatDataService,
    private seoHelper: SeoHelperService,
    private isBrowserService: IsBrowserService
  ) {
    // ssr forced loading
    if (this.isBrowserService.isServer()) {
      //this.waitForService.waitFor(
      //() => this.startLoading(),
      //"group_info_comp"
      //);
    }

    this.groupInfo = this.route.snapshot.data['groupInfo']
  }

  ngOnInit() {
    this.isMobile = SystemService.isMobile()

    // TODO Make this work
    if (this.isBrowserService.isBrowser()) {
      /*
      this.route.paramMap
        .pipe(takeUntil(this.destroyedSubject))
        .subscribe({
          next: paramMap => {
            this.reset();

            this.groupInfo.groupId = paramMap.get('groupID')!;
            this.linkIdParam = paramMap.get('link')!;

            //this.loadGroupID();
          },
          error: e => {
            this.toast.error("Error occurred");
            console.log(e);
          }
        });
      }
      */
    }

    this.startLoading()
  }

  startLoading() {
    if (!this.groupInfo || this.groupInfo.groupId === null) {
      this.toast.error('Error occurred')
      this.onLoadingError()
      return
    }

    // SEO
    this.seoHelper.setGroupPage(
      this.groupInfo.groupId,
      this.groupInfo.desc,
      this.groupInfo.name,
      this.groupInfo.image
    )

    if (this.isBrowserService.isBrowser()) {
      this.loadInviteLinkAndJoinStuff()
    }
  }

  reset() {
    this.areYouGroupAdmin = false
    this.isGlobalChat = false
    this.descCollapsedLineCount = 15
    this.descLineCount = -1
    this.descExceedsLineCount = false
    this.descCollapsed = true
    this.descCount_Helper_LastInnerText = ''
    this.invitationLinkActivated = false
    this.inviteLink = ''
    this.inviteLinkID = ''
    this.linkVisible = 0
    this.dataReceived = null
    this.areYouMember = false
    this.showJoinSection = false
  }

  onLoadingError() {
    this.oneButtonDialogService.show(
      'Group does not exist!',
      'We could not find a group with that information... 😣',
      () => {
        this.router.navigate(['/groupchats'])
      },
      'OK',
      false
    )
  }

  openMessages() {
    this.router.navigateByUrl('/message/' + this.groupInfo.groupId)
  }

  attemptToJoin() {
    if (!this.isLoggedIn) {
      this.authService.showLoginDialog(this.router.url)
      return
    }

    if (this.isGlobalChat) {
      this.joinGC()
    } else {
      this.inviteLinkID = EncodingService.decodeFromURL(this.inviteLinkID)
      this.groupsService.attempJoinGroupWithInvLinkID('', this.inviteLinkID)
    }
  }

  async joinGC() {
    // join in DB
    await remove(
      ref(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.myUID}/HasLeftGC`
      )
    )

    // open chat now
    this.openMessages()
  }

  /**
   * IMPORTANT:
   * Called AFTER we know already if the group has an invitation link or not
   */
  async loadAreYouMember() {
    if (this.isLoggedIn) {
      const memberSnap = await get(
        ref(
          this.rtdb,
          `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupInfo.groupId}/Mitglieder/${this.myUID}`
        )
      )
      if (memberSnap.exists()) {
        this.areYouMember = true

        // also load if you are admin
        this.areYouGroupAdmin = memberSnap.val() == 1
      }
    }

    // now we know everything to determine:
    // showJoinSection iff we are not a member andif it has an invite link
    this.showJoinSection = !this.areYouMember && this.invitationLinkActivated
  }

  async loadInviteLinkAndJoinStuff() {
    // inv link stuff
    if (this.isGlobalChat) {
      this.invitationLinkActivated = false
      this.areYouMember = true
    } else {
      const existsSnap = await get(
        ref(
          this.rtdb,
          `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupInfo.groupId}/Infos/inviteLink`
        )
      )
      if (existsSnap.exists()) {
        this.invitationLinkActivated = true
        this.loadAreYouMember()

        this.inviteLink = GroupinfoComponent.getGroupInviteLinkFromID(
          existsSnap.val()
        )
        this.inviteLinkID = GroupinfoComponent.getInviteIdFromLink(
          this.inviteLink
        )

        // visibility
        const visibilitySnap = await get(
          ref(
            this.rtdb,
            `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupInfo.groupId}/Infos/inviteLinkVisible`
          )
        )

        if (visibilitySnap.exists()) {
          const visible = visibilitySnap.val()
          this.linkVisible = visible ? 1 : -1
        } else {
          this.linkVisible = 1 // default
        }
      }
    }

    if (this.isGlobalChat) {
      // Check if user has left GC. In that case, allow to join here
      if (this.myUID) {
        from(
          get(
            ref(
              this.rtdb,
              `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.myUID}/HasLeftGC`
            )
          )
        )
          .pipe(take(1))
          .subscribe({
            next: (hasLeftSnap) => {
              if (hasLeftSnap.exists() && hasLeftSnap.val()) {
                this.showJoinSection = true
              }
            }
          })
      }
    }

    // --

    this.groupInfo.isLoading = false
  }

  ngAfterViewInit() {
    if (this.isBrowserService.isBrowser()) {
      this.setUpScrollListener()
    }
  }

  ngAfterViewChecked() {
    this.countLinesDesc()
  }

  countLinesDesc() {
    if (!this.isBrowserService.isBrowser) {
      return
    }

    /*
    if (this.descEl) {
      const innerText = this.descEl.nativeElement.innerText;
      if (innerText !== this.descCount_Helper_LastInnerText) {
        const count = this.descEl.nativeElement.getClientRects().length;
        this.descLineCount = count;
        this.descExceedsLineCount =
          this.descLineCount > this.descCollapsedLineCount;
        this.descCount_Helper_LastInnerText = innerText;
      }
    }
    */
  }

  showTotalMembersDialog() {
    if (this.groupInfo.membersCount == 0) {
      return // not yet loaded
    }
    this.oneButtonDialogService.show(
      'Total members',
      `This group has a total of ${this.numberFormatService.numberWithCommas(
        this.groupInfo.membersCount
      )} members.`
    )
  }

  showTotalMessagesDialog() {
    this.oneButtonDialogService.show(
      'Total messages',
      `This group has a total of ${this.numberFormatService.numberWithCommas(
        this.groupInfo.messagesCount
      )} sent messages.`
    )
  }

  openImageFullscreen() {
    if (this.groupInfo.image && this.groupInfo.name) {
      this.fullscreenHelper.open(
        this.imgHlp.do(this.groupInfo.image, 1100),
        '',
        this.groupInfo.name,
        ''
      )
    }
  }

  viewMembers() {
    const data = {
      isGroupMembers: true,
      badgeNumber: this.groupInfo.membersCount,
      areYouGroupAdmin: this.areYouGroupAdmin,
      groupID: this.groupInfo.groupId
    }
    this.dialog.open(FollowersComponent, { data: data })
  }

  leaveGroupDialog() {
    this.twobuttonsdialogService.show(
      'Leave group',
      'Do you want to leave this group?',
      () => {},
      () => {
        this.leaveGroup()
      },
      'Cancel',
      'Yes'
    )
  }

  async leaveGroup() {
    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    // decrease member count
    await set(
      ref(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupInfo.groupId}/Infos/memberCount`
      ),
      increment(-1)
    )

    // remove from chatuebersicht list
    await remove(
      ref(
        this.rtdb,
        `${
          StrHlp.CLOUD_PATH
        }/ChatUebersichtListe/${this.authService.getUserID()}/${this.groupInfo.groupId}`
      )
    )

    // check if group is a public group
    // if so, also decrease the count in firestore
    const publicSnap = await get(
      ref(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupInfo.groupId}/Infos/Public`
      )
    )
    if (publicSnap.exists()) {
      const isPublic = publicSnap.val()

      if (isPublic) {
        const fs = getFirestore()
        const ref = doc(
          fs,
          `${StrHlp.CLOUD_PATH}/Discover/GroupChats/${this.groupInfo.groupId}`
        )
        await updateDoc(ref, {
          memberCount: firebase.firestore.FieldValue.increment(-1)
        })
      }
    }

    // Locally remove from chatDataService
    if (this.groupInfo.groupId) {
      this.chatdataService.locallyRemoveByID(this.groupInfo.groupId)
    }

    // UI: leave the dialog and chat
    loadingDialogRef.close()
    this.toast.success('You left this group')
    history.back()
  }

  report() {
    const data = {
      reportType: 5,
      reportID: this.groupInfo.groupId
    }
    this.bottomSheet.open(ReportComponent, {
      data: data
    })
  }

  setUpScrollListener() {
    this.contentWrapper.nativeElement.addEventListener('scroll', () => {
      const scrollTop = this.contentWrapper.nativeElement.scrollTop
      this.scrollIsAtTop = scrollTop == 0
    })
  }

  scrollTop() {
    if (this.contentWrapper) {
      this.contentWrapper.nativeElement.scrollTop = 0
    }
  }

  async copyGroupname() {
    try {
      if (this.groupInfo.name) {
        SystemService.hapticsImpactMedium()
        await navigator.clipboard.writeText(this.groupInfo.name)
        this.toast.success('Copied to clipboard')
      }
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  editGroup() {
    const data = {
      groupID: this.groupInfo.groupId,
      isForCreating: false,
      groupImageURL: this.groupInfo.image,
      groupName: this.groupInfo.name,
      groupDesc: this.groupInfo.desc
    }

    this.dialog.open(CreategroupComponent, { data: data })
  }

  async createLink() {
    if (this.groupInfo.groupId) {
      const key = await this.groupsService.createLink(this.groupInfo.groupId)

      // update UI
      this.inviteLink = GroupinfoComponent.getGroupInviteLinkFromID(key!)
      this.invitationLinkActivated = true
      this.inviteLinkID = key!
    }
  }

  async copyLink() {
    try {
      await navigator.clipboard.writeText(this.inviteLink)
      this.toast.success('Copied to clipboard')
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  shareToChat() {
    const data = {
      pageTitle: 'Share this post',
      disableAutoFocus: true,
      clickCallback: (itemChat: any) => {
        const text = `Join my ${StrHlp.APP_NAME} group: ${this.groupInfo.name} \n\n${this.inviteLink}`

        const dataForCallback = {
          chatID: itemChat.chatID,
          name: itemChat.name,
          verified: itemChat.verified,
          image: itemChat.image,
          isGroup: itemChat.isGroup,
          isPrivate: itemChat.isPrivate,
          otherUserID: itemChat.otherUserID,
          isMeUser1: itemChat.isMeUser1,
          newMessagesCount: 0,

          // chatOK does not work here
          chatOK1: true,
          chatOK2: true,

          replyMessageFrom: '',
          replyMessage: '',

          // Forward stuff
          forwardMessage: text,
          forwardImageURL: this.groupInfo.image
        }

        this.router.navigate(['msg'], { state: dataForCallback })
      }
    }

    this.bottomSheet.open(ChatactionComponent, {
      data: data
    })
  }

  static getGroupInviteLinkFromID(groupInviteLinkID: string) {
    const idEncoded = EncodingService.encodeForUrl(groupInviteLinkID)
    return `${SITE_PROTOCOL}://${StrHlp.APP_URL}/invite/${idEncoded}`
  }

  static getInviteIdFromLink(groupInviteLink: string) {
    const match = groupInviteLink.match(/\/invite\/(.+)/)
    const idEncoded = match![1]
    return EncodingService.decodeFromURL(idEncoded)
  }

  deactivateLink() {
    this.twobuttonsdialogService.show(
      'Deactivate link',
      'Do you want to deactivate this group invitation link?',
      () => {},
      () => {
        this.deactivateLinkActual()
      },
      'Cancel',
      'Yes'
    )
  }

  async deactivateLinkActual() {
    // simply delete all values
    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    await remove(
      ref(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupInfo.groupId}/Infos/inviteLink`
      )
    )
    await remove(
      ref(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/GroupInviteLinks/${this.inviteLinkID}`
      )
    )

    // update UI
    this.inviteLink = ''
    this.invitationLinkActivated = false

    loadingDialogRef.close()
  }

  async linkVisibilityChanged() {
    await set(
      ref(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/ChatGruppen/${this.groupInfo.groupId}/Infos/inviteLinkVisible`
      ),
      this.linkVisible
    )
  }

  ngOnDestroy(): void {
    this.destroyedSubject.next()
    this.destroyedSubject.complete()
  }
}
