import { Directive, ElementRef, Renderer2 } from '@angular/core'
import { IsBrowserService } from 'src/app/shared/services/ssr/isbrowser.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'

/**
 * This will make the host, should be dialog or mat menu,
 * be at the screen bottom and slide in like a normal
 * bottom sheet. No closing animation yet.
 * 
 * HOW TO USE:
 * 
 * MAT-MENU:
 * 
    <mat-menu
        #menu="matMenu" 
        class="... bottom-sheet-matmenu-wrapper"  (THIS CLASS AT THE END!!)
        backdropClass="black-backdrop-light"
    >
        (THIS MUST WRAP YOUR CONENT, WITH THIS DIRECTIVE)
        <div appBottomSheetDialogContainer>
          ...
        </div>
      </mat-menu>


  DIALOG:
  simply put this to wrap you dialog components template. The highest wrapping template element.
  <div appBottomSheetDialogContainer>
    ...
  </div>
 * 
 */
@Directive({
  standalone: true,
  selector: '[appBottomSheetDialogContainer]'
})
export class BottomSheetDialogContainerDirective {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private isBrowserService: IsBrowserService
  ) {
    if (this.isBrowserService.isBrowser()) {
      const isMobile = SystemService.isMobile()
      if (isMobile) {
        this.renderer.addClass(
          this.el.nativeElement,
          'bottom-sheet-dialog-container-hidden'
        )
        this.renderer.addClass(
          this.el.nativeElement,
          'bottom-sheet-dialog-container-appear-anim'
        )
      }

      // this one add always
      this.renderer.addClass(this.el.nativeElement, 'bottom-sheet-dialog-type2')
    }
  }
}
