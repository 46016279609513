import { Injectable } from '@angular/core'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { environment } from 'src/environments/environment'
import { StrHlp } from '../StringGetter/getstring.service'

@Injectable({
  providedIn: 'root'
})
export class InteractionhelperService {
  static alreadyViewedInSession: any = {}

  constructor() {}

  static triggerPostView(postID: string) {
    if (InteractionhelperService.alreadyViewedInSession[postID] == null) {
      // not yet viewed in this session
      InteractionhelperService.alreadyViewedInSession[postID] = true

      // trigger view via func
      const functions = getFunctions()
      const cloudFunction = httpsCallable(functions, 'triggerPostView')
      cloudFunction({
        hubname: StrHlp.CLOUD_PATH,
        postID: postID
      })
        .then((result) => {
          if (!environment.production) {
            //console.log("triggerPostView https func returned success");
          }
        })
        .catch((error) => {
          console.log('triggerPostView https func FAILED')
          console.log(error)
        })
    }
  }
}
