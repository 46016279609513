import { Directive, HostListener } from '@angular/core'
import { MatBottomSheetRef } from '@angular/material/bottom-sheet'

@Directive({
  selector: '[matBottomSheetClose]'
})
export class BottomSheetCloseDirective {
  constructor(private bottomSheetRef: MatBottomSheetRef<any>) {}

  @HostListener('click')
  close() {
    this.bottomSheetRef.dismiss()
  }
}
