import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { OneButtonDialog } from 'src/app/components/dialogs/one-button-dialog/one-button-dialog.component'

@Injectable({
  providedIn: 'root'
})
export class OnedialogserviceService {
  constructor(public dialog: MatDialog) {}

  show(
    title: string,
    desc: string,
    clickCallback: () => any = () => {},
    buttonText: string = 'OK',
    cancelable: boolean = true
  ) {
    const data = {
      title: title,
      desc: desc,
      clickCallback: clickCallback,
      buttonText: buttonText
    }

    this.dialog.open(OneButtonDialog, {
      disableClose: !cancelable,
      data: data
    })
  }
}
