import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  private static dayInMillis = 86400000

  constructor() {}

  static isToday(stamp: number) {
    return this.sameDay(stamp, Date.now())
  }
  static isYesterday(stamp: number) {
    return this.sameDay(stamp, Date.now() - TimeService.dayInMillis)
  }

  static sameDay(timestamp1: number, timestamp2: number): boolean {
    const date1 = new Date(timestamp1)
    const date2 = new Date(timestamp2)

    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDay() === date2.getDay()
    )
  }

  /**
   *
   * @returns for example "01_25_2023"
   */
  static getFormattedDateForStatistics(): string {
    let yourDate = new Date()
    const offset = yourDate.getTimezoneOffset()
    yourDate = new Date(yourDate.getTime() + offset * 60 * 1000)
    const s = yourDate.toISOString().split('T')[0]
    const arr = s.split('-')
    return arr[1] + '_' + arr[2] + '_' + arr[0]
  }
}
