import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class HTMLFormattingService {
  applyAll(inp: string, whiteHighlighting: boolean = false): string {
    // only the first one should have preventHTML=true
    return this.makeLinksClickable(
      this.makeHashtagsClickable(
        this.makeUsernamesClickable(inp, true, whiteHighlighting),
        false,
        whiteHighlighting
      ),
      false,
      whiteHighlighting
    )
  }

  /**
   * e.g: **hello** --> <b>hello</b>
   */
  applyTextFormatting(inp: string) {
    return inp
      .replace(/\B\*\*([^*]+)\*\*\B/g, '<b>$1</b>')
      .replace(/__([^_]+)__/g, '<u>$1</u>')
      .replace(/\B~~([^~]+)~~\B/g, '<s>$1</s>')
      .replace(/\B\$\$([^$]+)\$\$\B/g, '<code>$1</code>')
      .replace(/\B\/\/([^/]+)\/\/\B/g, '<i>$1</i>')
  }
  /**
   * e.g: **hello** --> hello
   */
  removeTextFormatting(inp: string) {
    return inp
      .replace(/\B\*\*([^*]+)\*\*\B/g, '$1')
      .replace(/__([^_]+)__/g, '$1')
      .replace(/\B~~([^~]+)~~\B/g, '$1')
      .replace(/\B\$\$([^$]+)\$\$\B/g, '$1')
      .replace(/\B\/\/([^/]+)\/\/\B/g, '$1')
  }

  makeLinksClickable(
    inp: string,
    preventHTML: boolean = true,
    whiteHighlighting: boolean
  ): string {
    // VERY IMPORTANT!
    // Since we will put the text to innerHTML, this opens up the issue of injection vulnerability
    // Therefore, we remove all
    if (preventHTML) {
      inp = inp.replaceAll(/</g, '&lt;').replaceAll(/>/g, '&gt;')
    }

    try {
      const regex =
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w\-_]*)?\??(?:[-\+=&;%@.\w_*\(\)\[\]\{\}\/]*)#?(?:[\w]*))?)/gi
      return (inp || '').replace(regex, (match, space, url) => {
        let hyperlink = match
        if (!/^https?:\/\//i.test(hyperlink)) {
          hyperlink = 'http://' + hyperlink
        }
        return `<a ${
          whiteHighlighting ? 'class="white-link-highlighting" ' : ''
        }href="${hyperlink}" target="_blank">${match}</a>`
      })
    } catch (error) {
      console.log(error)
      return inp
    }
  }

  makeUsernamesClickable(
    inp: string,
    preventHTML: boolean = true,
    whiteHighlighting: boolean
  ): string {
    // VERY IMPORTANT!
    // Since we will put the text to innerHTML, this opens up the issue of injection vulnerability
    // Therefore, we remove all
    if (preventHTML) {
      inp = inp.replaceAll(/</g, '&lt;').replaceAll(/>/g, '&gt;')
    }

    try {
      // The regex below needs this to find a username if its the very end of the string
      inp = inp + ' '

      // this regex has very bad browser support because of the lookbehind (at the beginning)
      //const regex = /(?<=\s|^)\@([a-zA-Z0-9._]+)(?=\s|$) /gim

      //const regex = /(^| )\@([a-zA-Z0-9._]+)(?=\s|$)($| )/gim;
      const regex = /(^| )\@([a-zA-Z0-9._]+)(?=\s|$)/gim
      return (inp || '').replace(regex, (match, name) => {
        const username = match.replace('@', '').trim()
        match = match.trim()
        return ` <a ${
          whiteHighlighting ? 'class="white-link-highlighting" ' : ''
        } href="@/${username}">${match}</a> `
      })
    } catch (error) {
      console.log(error)
      return inp
    }
  }

  makeHashtagsClickable(
    inp: string,
    preventHTML: boolean = true,
    whiteHighlighting: boolean
  ): string {
    // VERY IMPORTANT!
    // Since we will put the text to innerHTML, this opens up the issue of injection vulnerability
    // Therefore, we remove all
    if (preventHTML) {
      inp = inp.replaceAll(/</g, '&lt;').replaceAll(/>/g, '&gt;')
    }

    try {
      // The regex below needs this to find a hashtag if its the very end of the string
      inp = inp + ' '

      // this regex has very bad browser support because of the lookbehind (at the beginning)
      //const regex = /(?<=\s|^)\#([a-zA-Z0-9._]+)(?=\s|$) /gim

      //const regex = /(^| )\#([a-zA-Z0-9._]+)(?=\s|$)($| )/gim;
      const regex = /(^| )\#([a-zA-Z0-9._]+)(?=\s|$)/gim
      return (inp || '').replace(regex, (match, name) => {
        const tag = match.replace('#', '').trim()
        match = match.trim()
        return ` <a ${
          whiteHighlighting ? 'class="white-link-highlighting" ' : ''
        }href="tag/${tag}">${match}</a> `
      })
    } catch (error) {
      console.log(error)
      return inp
    }
  }
}
