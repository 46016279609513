import { Directive, ElementRef, Renderer2 } from '@angular/core'
import { IsBrowserService } from 'src/app/shared/services/ssr/isbrowser.service'

@Directive({
  selector: '[appPopUpFadeInDialog]',
  standalone: true
})
export class PopUpFadeInDialogDirective {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private isBrowserService: IsBrowserService
  ) {
    if (this.isBrowserService.isBrowser()) {
      this.renderer.addClass(
        this.el.nativeElement,
        'pop-up-fade-in-dialog-appear-anim'
      )
    }
  }
}
