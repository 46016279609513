import { Injectable, TransferState, makeStateKey } from '@angular/core'
import { child, get, getDatabase, ref } from 'firebase/database'
import { StrHlp } from '../../services/StringGetter/getstring.service'
import { IsBrowserService } from '../../services/ssr/isbrowser.service'

const KEY_TS_USER = makeStateKey<{ data: any }>('KEY_TS_USER')

@Injectable({
  providedIn: 'root'
})
export class UserLoaderService {
  rtdb = ref(getDatabase())

  constructor(
    private isBrowserService: IsBrowserService,
    private transferState: TransferState
  ) {}

  async loadUserIdFromUsername(username: string): Promise<string | null> {
    const usernamePath = username.replaceAll('.', '@')
    const path = `${StrHlp.CLOUD_PATH}/UsernameList/${usernamePath}`
    const dbChild = child(this.rtdb, path)

    try {
      const snapshot = await get(dbChild)

      if (snapshot.exists()) {
        return snapshot.val()
      }
    } catch (error) {
      console.error(error)
    }

    return null
  }

  async fetchUser(userId: string) {
    const path = `${StrHlp.CLOUD_PATH}/Users/${userId}`
    const dbChild = child(this.rtdb, path)
    const snapshot = await get(dbChild)

    if (snapshot.exists()) {
      return snapshot.val()
    } else {
      // profile doesnt exist
      return null
    }
  }

  async loadUser(userId: string | null, username: string | null) {
    if (!userId) {
      userId = await this.loadUserIdFromUsername(username!)
    }

    let user: any = null

    if (this.isBrowserService.isBrowser()) {
      user = this.transferState.get(KEY_TS_USER, null)
    }

    if (user === null) {
      user = await this.fetchUser(userId!)

      // transfer state logic
      if (this.isBrowserService.isServer()) {
        this.transferState.set(KEY_TS_USER, user)
      }
    }

    return user
  }
}
