import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild
} from '@angular/core'
import { Router } from '@angular/router'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { NotificationsdataService } from 'src/app/shared/services/data/notificationsdata.service'
import { HotToastService } from '@ngneat/hot-toast'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { MatMenuTrigger } from '@angular/material/menu'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-notificationitemtemplate',
  templateUrl: './notificationitemtemplate.component.html',
  styleUrls: ['./notificationitemtemplate.component.css']
})
export class NotificationitemtemplateComponent {
  name$?: Observable<string>
  image$?: Observable<string>

  private _item: any = null
  @Input()
  set item(value: any) {
    this._item = value.item
    //console.log(this._item);

    this.indexInList = value.indexInList
    this.showNewNotificationsHint = value.showNewNotificationsHint
    this.hideMenuButton = value.hideMenuButton
    this.newNotificationsCount = value.newNotificationsCount

    this.itemChanged()
  }

  get item(): any {
    return this._item
  }

  // needed for recycling reasons
  itemChanged() {
    const userID = this.item.userid
    //console.log(this.item);
    if (userID) {
      this.name$ = this.cacheService.getUsername(userID)
      this.image$ = this.cacheService.getProfileImage(userID)
    }
  }

  indexInList: number = -1
  hideMenuButton: boolean = false
  showNewNotificationsHint = false
  newNotificationsCount = 0

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger

  constructor(
    public imgHlp: ImageLoadingService,
    public routingHelper: RoutinghelperService,
    private router: Router,
    private twobuttonsdialogService: TwobuttonsdialogService,
    private notificationDataService: NotificationsdataService,
    private toast: HotToastService,
    private cacheService: CacheService
  ) {}

  triggerMenu() {
    // only allow on mobile, so when menu button is hidden
    if (this.hideMenuButton) {
      // haptic feedback
      SystemService.hapticsImpactMedium()

      this.menuTrigger.openMenu()
    }
  }

  openNotification(item: any) {
    if (item.ispost && item.postid && item.postid.length > 0) {
      this.router.navigate(['/p/' + item.postid])
    } else {
      // then open the profile of the user
      this.routingHelper.user(item.username, item.userid)
    }

    return false
  }

  openProfile() {
    this.routingHelper.user(this.item.username, this.item.userid)
  }

  deleteNotifDialog() {
    this.twobuttonsdialogService.show(
      'Delete notification',
      `Do you want to delete this notification? This cannot be undone.`,
      () => {},
      () => {
        this.deleteNotif()
      },
      'Cancel',
      'Yes'
    )
  }

  deleteNotif() {
    //const loadingDialogRef = this.dialog.open(LoadingDialogComponent, { disableClose: true });

    // delete notif
    // call cloud function
    const functions = getFunctions()
    const removeChatFromChatList = httpsCallable(
      functions,
      'deleteNotification'
    )

    removeChatFromChatList({
      notificationID: this.item.itemID,
      hubname: StrHlp.CLOUD_PATH
    })
      .then((result) => {
        // local for UI
        this.notificationDataService.itemList.splice(this.indexInList, 1)

        //loadingDialogRef.close();
        this.toast.success('Deleted')
      })
      .catch((error) => {
        console.log(error)
        //loadingDialogRef.close();

        this.toast.error('Failed')
      })
  }

  getDateHintString(dateHintType: number): string {
    if (dateHintType == 0) {
      return 'Today'
    } else if (dateHintType == 1) {
      return 'Yesterday'
    } else if (dateHintType == 2) {
      return '2 days ago'
    } else if (dateHintType == 3) {
      return '3 days ago'
    } else if (dateHintType == 4) {
      return 'Older'
    } else {
      // dateHintType is the timestamp in this case
      return new Date(dateHintType).toLocaleDateString('en-US')
    }
  }
}
