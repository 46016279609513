import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent implements OnInit {
  constructor(
    public router: Router,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage(
      'Not found - 404',
      this.seoHelper.getGenericDesc()
    )
  }

  navigateHome() {
    this.router.navigate([''])
  }
}
