import { Pipe, PipeTransform } from '@angular/core'
import { NumberFormatService } from '../shared/services/formatting/number/numberformat.service'

@Pipe({
  name: 'numberAbbr'
})

/**
 * Does two things: <0 not allowed. <0 will return 0.
 * Abbreviates, like so: 32000 -> 32K
 */
export class NumberAbbrPipe implements PipeTransform {
  constructor(private numberFormatService: NumberFormatService) {}

  transform(input: unknown, ...args: unknown[]): string {
    if (typeof input === 'number' && !isNaN(input)) {
      return this.numberFormatService.abbreviate(input as number)
    } else {
      return ''
    }
  }
}
