<div class="feedWrapper">
  <div
    class="header-likes-tab"
    [ngClass]="{ 'bottom-border-divider': !scrollIsAtTop }"
  >
    <app-backbuttontemplate />

    <h1 class="page-title">
      {{ title }}
    </h1>

    @if (itemsCount) {
      <div class="count-showing-div-wrapper count-wrapper">
        <div class="count-showing-div-text">
          {{ (itemsCount < 0 ? 0 : itemsCount) | numberCommaFormat }}
        </div>
      </div>
    }
  </div>

  <div class="content-wrapper">
    @if (empty) {
      <app-emptyhinttemplate />
    } @else {
      @if (feedDataService.postList.length == 0) {
        <app-loadingcircle />
      }

      <cdk-virtual-scroll-viewport
        #virtualScrollViewport
        class="post-list post-list-height"
        predictingVirtualScroll
        [data]="{
          items: feedDataService.postList,
          itemType: 0,
          idAttrName: 'postID'
        }"
      >
        <div
          class="post"
          *cdkVirtualFor="let post of feedDataService.postList; let i = index"
          [attr.data-hm-id]="post.postID"
        >
          <post-template
            [post]="{
              post: post,
              indexInList: i,
              postEnv: 0
            }"
          />
        </div>
      </cdk-virtual-scroll-viewport>
    }
  </div>
</div>
