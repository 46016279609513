import { inject } from '@angular/core'
import { ResolveFn } from '@angular/router'
import { TrendingdataService } from '../shared/services/data/trendingdata.service'

export const ExploreResolver: ResolveFn<any> = (route, state) => {
  const dataService = inject(TrendingdataService)
  if (!dataService.hasDoneInitLoading) {
    return dataService.load()
  } else {
    return null
  }
}
