<div
  class="wrapper respect-safe-area-bottom"
  [ngClass]="{ 'fh-page-wrapper': isMobile }"
>
  @if (userMuted) {
    <div class="muted-hint">You have muted this user</div>
  }

  <header
    class="user-header"
    [ngClass]="{
      'fh-header': isMobile,
      'bottom-border-divider': !scrollIsAtTop
    }"
  >
    <app-backbuttontemplate />

    <div class="username-wrapper">
      <h1
        class="username-text"
        (longTap)="copyUsername()"
        (click)="scrollTop()"
      >
        {{ username }}
      </h1>

      @if (verified$ | async) {
        <img
          class="verified-badge-big"
          src="/assets/ic_verified_blue.png"
          alt="Verified blue badge"
          routerLink="/settings/premium"
          matTooltip="Go premium"
          [matTooltipShowDelay]="1000"
        />
      }
    </div>

    <div class="options-wrapper">
      <button
        class="subtle-button action-button-wrapper menu-button"
        aria-label="push notifications"
        id="push-notif-button"
        (click)="notifSettingClick()"
        matTooltip="Notifications"
        [matTooltipShowDelay]="1000"
      >
        <img
          class="dark-icon push-notif-icon"
          src="{{
            subbedToPushNotifs
              ? '/assets/ic_notification_on.png'
              : '/assets/ic_notification.png'
          }}"
          alt="Notification icon"
        />
      </button>

      <button
        class="subtle-button action-button-wrapper menu-button"
        mat-button
        [matMenuTriggerFor]="menu"
        aria-label="Options"
        matTooltip="Options"
        [matTooltipShowDelay]="1000"
      >
        <img
          class="dark-icon menu-icon-user"
          src="/assets/menu.png"
          alt="Options icon"
        />
      </button>
    </div>

    <mat-menu
      class="standard-options-menu bottom-sheet-matmenu-wrapper"
      #menu="matMenu"
      backdropClass="black-backdrop-light"
    >
      <div appBottomSheetDialogContainer>
        <div class="standard-options-dialog-item" (click)="copyLink()">
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_set_copy.png"
              alt="copy button icon"
            />
          </span>
          <span class="options-text"> Copy link </span>
        </div>

        @if (
          otherUserID !== userID &&
          otherUserID !== 'nwnqQ1LieaaofxvtAg9ojOBRIeD2'
        ) {
          <div class="standard-options-dialog-item" (click)="askForBlockUser()">
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img"
                src="/assets/ic_alert7.png"
                alt="block user button icon"
              />
            </span>
            <span class="options-text"> Block user </span>
          </div>
        }

        @if (
          otherUserID !== userID &&
          !userMuted &&
          otherUserID !== 'nwnqQ1LieaaofxvtAg9ojOBRIeD2'
        ) {
          <div class="standard-options-dialog-item" (click)="showMuteDialog()">
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img"
                src="/assets/bottomsheet_ic_block.png"
                alt="mute button icon"
              />
            </span>
            <span class="options-text"> Mute user </span>
          </div>
        }

        @if (otherUserID !== userID && userMuted) {
          <div
            class="standard-options-dialog-item"
            (click)="showUnmuteDialog()"
          >
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img-type3"
                src="/assets/bottomsheet_ic_block.png"
                alt="unmute button icon"
              />
            </span>

            <span class="options-text"> Unmute user </span>
          </div>
        }

        @if (
          otherUserID !== userID &&
          otherUserID !== 'nwnqQ1LieaaofxvtAg9ojOBRIeD2'
        ) {
          <div class="standard-options-dialog-item" (click)="reportUser()">
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img"
                src="/assets/ic_set_bug.png"
                alt="report user icon"
              />
            </span>

            <span class="options-text"> Report </span>
          </div>
        }

        <app-bottomsheet-bottom-branding-spacer *appShowOnlyOnMobile />
      </div>
    </mat-menu>
  </header>

  <main
    #contentWrapper
    id="contentWrapper"
    class="fh-main user-content below-header-height"
    cdkVirtualScrollingElement
  >
    @if (!youAreBlocked && !youHaveBlocked) {
      <div class="profileInfoOuterWrapper">
        <section class="profileInfoWrapper1">
          <div id="profileImageWrapper">
            <img
              #profileImg
              class="profile-image profileImage"
              src="{{
                user && user.profilePhoto
                  ? profileImageUrl
                  : '/assets/default_profile_pic.jpg'
              }}"
              onerror="src='/assets/default_profile_pic.jpg';onerror='';"
              (click)="openProfileImageFullscreen()"
              alt="{{ username }} profile photo on {{ strHlp.getAppName() }}"
            />

            <div id="last-seen-text">{{ lastSeenString }}</div>
          </div>

          <section class="profileInfo">
            <div class="profileNumberBoxWrapper">
              <button
                class="subtle-button profileNumberWrapper"
                (click)="showTotalPostsDialog()"
              >
                <p class="profileNumberText">
                  {{ user ? (user.postCount | numberAbbr) : '0' }}
                </p>
                <p class="profileNumberTextDesc">Posts</p>
              </button>

              <button
                class="subtle-button profileNumberWrapper"
                (click)="openFollowersPage()"
              >
                <p class="profileNumberText">
                  {{ user ? (user.followerCount | numberAbbr) : '0' }}
                </p>
                <p class="profileNumberTextDesc">Followers</p>
              </button>

              <button
                class="profileNumberWrapper"
                (click)="openFollowingsPage()"
              >
                <p class="profileNumberText">
                  {{ user ? (user.followingCount | numberAbbr) : '0' }}
                </p>
                <p class="profileNumberTextDesc">Following</p>
              </button>
            </div>

            <div id="profile-buttons-wrapper">
              @if (!user || otherUserID !== userID) {
                <button
                  class="subtle-button interaction-button"
                  [ngClass]="{
                    'red-button': !areYouFollowing && !followingRequested,
                    'following-button': areYouFollowing || followingRequested
                  }"
                  (click)="followButtonClick()"
                >
                  <span>
                    {{
                      areYouFollowing
                        ? 'Following'
                        : followingRequested
                          ? 'Requested'
                          : 'Follow'
                    }}
                  </span>
                </button>
              }

              @if (authService.isEmailVerified() && otherUserID !== userID) {
                <button
                  class="subtle-button interaction-button message-button"
                  (click)="openDMs()"
                >
                  Message
                </button>
              }

              @if (user && otherUserID === userID) {
                <button
                  class="subtle-button interaction-button blue-button"
                  (click)="openEditProfile()"
                >
                  Edit profile
                </button>
              }
            </div>

            @if (areYouFollowing && !subbedToPushNotifs) {
              <button
                class="subtle-button interaction-button blue-button big-push-notifs-button"
                (click)="enablePushNotifsDialog()"
              >
                <!-- <img class="light-icon standard-action-button push-notif-icon" src="/assets/ic_notification.png"> -->
                Get notifications for posts
              </button>
            }
          </section>
        </section>

        <section class="profileInfoWrapper2">
          <h2 class="fullname">
            {{
              errorOccurred
                ? 'Failed'
                : user && user.fullName
                  ? user.fullName
                  : 'Loading...'
            }}
          </h2>

          <div>
            <h3
              #bioEl
              class="bio allow-user-selection"
              [ngClass]="{
                bioCollapsed: bioLineCount != -1 && bioCollapsed,
                bioExpanded: bioLineCount != -1 && !bioCollapsed
              }"
              [innerHTML]="
                user
                  ? htmlFormattingService.applyTextFormatting(
                      htmlFormattingService.applyAll(user.bio)
                    )
                  : ''
              "
            ></h3>

            @if (bioExceedsLineCount && bioCollapsed) {
              <button
                class="subtle-button seeMoreButton"
                (click)="bioCollapsed = false"
              >
                <b>See more...</b>
              </button>
            }
          </div>

          <div class="below-bio-stats reputation">
            Reputation: &nbsp;{{
              user ? (user.reputation | numberCommaFormat) : '-'
            }}
          </div>

          <div class="below-bio-stats joined-hint">
            Joined {{ user ? (user.timestampJoined | timeformat) : '-' }}
          </div>

          @if (isThisUserFollowingYou) {
            <div class="below-bio-stats joined-hint followingYouHint">
              <img
                class="dark-icon followingYouIcon"
                src="/assets/profile_selected_2.png"
                alt="follows you indicator icon"
              />
              <p>Follows you</p>
            </div>
          }
        </section>
      </div>
    }

    <hr class="divider" />

    <section id="postsWrapper">
      @if (
        !user &&
        !youAreBlocked &&
        !youHaveBlocked &&
        !errorOccurred &&
        feedDataService.postList.length == 0 &&
        !profileHasNoPosts
      ) {
        <app-loadingcircle></app-loadingcircle>
      }

      <cdk-virtual-scroll-viewport
        #virtualScrollViewport
        class="post-list"
        predictingVirtualScroll
        [data]="{
          items: feedDataService.postList,
          itemType: 0,
          idAttrName: 'postID'
        }"
      >
        <div
          class="post"
          *cdkVirtualFor="let post of feedDataService.postList; let i = index"
          [attr.data-hm-id]="post.postID"
        >
          <post-template
            [post]="{
              post: post,
              indexInList: i,
              hideMuteButton: true,
              postEnv: 0
            }"
          >
          </post-template>
        </div>
      </cdk-virtual-scroll-viewport>
    </section>

    @if (hideContent) {
      <div class="privateProfileHint">
        <img
          src="/assets/ic_private_profile.png"
          class="dark-icon privateHintIcon"
          alt="private profile icon"
        />
        <p>
          This profile is private. To see the posts you need to follow this user
        </p>
      </div>
    }

    @if (errorOccurred) {
      <div class="privateProfileHint">
        <img
          src="/assets/ic_warning.png"
          class="privateHintIcon"
          alt="Warning"
        />
        <p>{{ errorMessage }}</p>
      </div>
    }

    @if (youAreBlocked) {
      <app-emptyhinttemplate [text]="'You are blocked by this user'">
      </app-emptyhinttemplate>
    }

    @if (profileHasNoPosts) {
      <app-emptyhinttemplate [text]="'No posts yet'"> </app-emptyhinttemplate>
    }

    @if (youHaveBlocked) {
      <app-emptyhinttemplate [text]="'You have blocked this user'">
      </app-emptyhinttemplate>
    }

    @if (youHaveBlocked) {
      <div class="unblock-button" (click)="unblockUser()">Unblock</div>
    }
  </main>
</div>
