<a
  class="subtle-button explore-item-wrapper no-decor"
  routerLink="/location/{{ encodedLocation }}"
  role="link"
  attr.aria-label="Open location {{ location }}"
>
  <div class="explore-item-header-text">📍{{ location }}</div>

  <div class="explore-item-desc-text">
    {{ count | numberCommaFormat }} posts
  </div>
</a>
