import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class BunnyserviceService {
  private accessKey = '28d1fc8b-67fe-444a-b197d0603558-4bc0-492e'
  private libraryID = '148025'

  constructor() {}

  private generateUUID() {
    // Public Domain/MIT
    let d = new Date().getTime() // Timestamp
    let d2 =
      (typeof performance !== 'undefined' &&
        performance.now &&
        performance.now() * 1000) ||
      0 // Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        let r = Math.random() * 16 //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0
          d = Math.floor(d / 16)
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0
          d2 = Math.floor(d2 / 16)
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
      }
    )
  }

  async do(file: any) {
    const id = this.generateUUID()
    const videoName = `video_uuid_${id}_stamp_${Date.now()}`

    // create video in library
    const videoID = await this.createVideo(videoName)

    // upload data
    await this.upload(videoID, file)

    return videoID
  }

  private async createVideo(videoName: string) {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/*+json',
        AccessKey: this.accessKey
      },
      body: `{"title": "${videoName}"}`
    }

    const response = await fetch(
      `https://video.bunnycdn.com/library/${this.libraryID}/videos`,
      options
    )

    const json = await response.json()
    return json.guid // return videoID
  }

  private async upload(videoID: string, file: any) {
    const options = {
      method: 'PUT',
      headers: {
        accept: 'application/json',
        AccessKey: this.accessKey
      },
      body: file
    }

    const response = await fetch(
      `https://video.bunnycdn.com/library/${this.libraryID}/videos/${videoID}`,
      options
    )
    const json = await response.json()

    return json
  }
}
