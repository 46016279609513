import { Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { HotToastService } from '@ngneat/hot-toast'
import { get, getDatabase, ref, set } from 'firebase/database'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-chatsettings',
  templateUrl: './chatsettings.component.html',
  styleUrls: [
    './chatsettings.component.css',
    '../settingspage/settings.component.css'
  ]
})
export class ChatsettingsComponent {
  userID: any = null
  dbRTDB = getDatabase()

  hasLeftGC: boolean = false

  setting_DM_Restriction: number = 0

  constructor(
    private toast: HotToastService,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('Chat settings', 'Chat settings')

    this.userID = AuthService.getUID()
    this.loadSettings()

    this.loadLeftGC()
  }

  loadSettings(): void {
    // load the DM restriction setting
    // bad style: copied from chat-page
    get(
      ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/UserSettings/${this.userID}/DMRestrictionSettings`
      )
    )
      .then((snapshot) => {
        this.setting_DM_Restriction = 0
        if (snapshot.exists()) {
          this.setting_DM_Restriction = snapshot.val()
        }
      })
      .catch((error) => {
        console.log(error)
        this.toast.error('An error has occurred')
      })
  }

  saveSetting_DM_Restrict(setting_DM_Restriction_New: number): void {
    if (this.setting_DM_Restriction === setting_DM_Restriction_New) {
      return
    }

    this.setting_DM_Restriction = setting_DM_Restriction_New
    set(
      ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/UserSettings/${this.userID}/DMRestrictionSettings`
      ),
      setting_DM_Restriction_New
    )
  }

  loadLeftGC(): void {
    get(
      ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/HasLeftGC`
      )
    ).then((snapshot) => {
      if (snapshot.exists()) {
        this.hasLeftGC = snapshot.val()
      }
    })
  }

  joinGlobalChat(): void {
    set(
      ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/HasLeftGC`
      ),
      false
    )
      .then(() => {
        this.toast.success('Joined Global Chat')

        // ssr-guarded
        if (typeof window !== 'undefined') {
          // this is needed
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error)
        this.toast.error('An error has occurred')
      })
  }
}
