<a
  class="subtle-button explore-item-wrapper no-decor"
  routerLink="/tag/{{ encodedTag }}"
  role="link"
  attr.aria-label="Open hashtag {{ tag }}"
>
  <div class="explore-item-header-text">#{{ tag }}</div>

  <div class="explore-item-desc-text">
    {{ count | numberCommaFormat }} posts
  </div>
</a>
