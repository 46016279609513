import { Injectable, TransferState, makeStateKey } from '@angular/core'
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where
} from 'firebase/firestore'
import { getDatabase } from 'firebase/database'
import { StrHlp } from '../StringGetter/getstring.service'
import { CacheService } from '../caching/cache-service.service'
import {
  BehaviorSubject,
  Observable,
  interval,
  lastValueFrom,
  map,
  startWith
} from 'rxjs'
import { IsBrowserService } from '../ssr/isbrowser.service'

const KEY_TRENDING_HASHTAG_LIST = makeStateKey<any[]>(
  'KEY_TRENDING_HASHTAG_LIST'
)
const KEY_TRENDING_USERS_LIST = makeStateKey<any[]>('KEY_TRENDING_USERS_LIST')
const KEY_TRENDING_SEARCHES_LIST = makeStateKey<any[]>(
  'KEY_TRENDING_SEARCHES_LIST'
)

const ssrChunkSize = 20 // no longer doing this
const clientChunkSize = 20

/**
 * On Server side, we load a small chunk of hashtags and users.
 * So in the response site we always have them so google will then discover/crawl them.
 */

@Injectable({
  providedIn: 'root'
})
export class TrendingdataService {
  db = getDatabase()

  public hasDoneInitLoading: boolean = false

  hashtagScoreThreshold: number = Number.MAX_SAFE_INTEGER
  profileScoreThreshold: number = Number.MAX_SAFE_INTEGER
  searchQueryScoreThreshold = Number.MAX_SAFE_INTEGER

  public trendingHashtags: any[] = []
  public trendingProfiles: any[] = []
  public trendingSearchQueries: any[] = []

  public emptyTrending: boolean = false
  public hashtagsDisabledFetching: boolean = false
  public usersDisabledFetching: boolean = false
  public searchQueriesDisabledFetching = false

  currentlyFetchingHashtags: boolean = false
  currentlyFetchingUsers: boolean = false
  currentlyFetchingSearchQueries = false

  trendingHashtags_Overall: any[] = []
  trendingProfiles_Overall: any[] = []

  userID: any = null

  private suggestQueries_WasSetUp = false
  private currentSuggestedSearchQuery = new BehaviorSubject<string>('')
  private suggestedQuery_Frequency = 10_000

  constructor(
    private cacheService: CacheService,
    private isBrowserService: IsBrowserService,
    private transferState: TransferState
  ) {}

  transferStateData() {
    let wasStateTransferred = false

    const hashtagsTS = this.transferState.get(KEY_TRENDING_HASHTAG_LIST, [])
    if (hashtagsTS.length > 0) {
      this.trendingHashtags_Overall = hashtagsTS
      this.trendingHashtags = hashtagsTS
      wasStateTransferred = true
    }

    const usersTS = this.transferState.get(KEY_TRENDING_USERS_LIST, [])
    if (usersTS.length > 0) {
      this.trendingProfiles_Overall = usersTS
      this.trendingProfiles = usersTS
      wasStateTransferred = true
    }

    const searchesTS = this.transferState.get(KEY_TRENDING_SEARCHES_LIST, [])
    if (searchesTS.length > 0) {
      this.trendingSearchQueries = searchesTS
      wasStateTransferred = true
    }

    if (!wasStateTransferred && this.trendingHashtags_Overall.length == 0) {
      this.load()
    }
  }

  async load() {
    this.hasDoneInitLoading = true

    await this.loadTrendingHashtags()
    await this.loadTrendingProfiles()
    await this.loadTrendingSearchQueries()

    if (this.isBrowserService.isServer()) {
      // set transferstate
      this.transferState.set(
        KEY_TRENDING_HASHTAG_LIST,
        this.trendingHashtags_Overall
      )
      this.transferState.set(
        KEY_TRENDING_USERS_LIST,
        this.trendingProfiles_Overall
      )
      this.transferState.set(
        KEY_TRENDING_SEARCHES_LIST,
        this.trendingSearchQueries
      )
    }
  }

  getChunkSize() {
    if (this.isBrowserService.isBrowser()) {
      return clientChunkSize
    } else {
      return ssrChunkSize
    }
  }

  /**
   * In one place for the whole app.
   * Returns an Observable which will emit a suggested query each x seconds.
   *
   * Called after first init of trending tags, so this has something to work with in the
   * first interval and not only in the second. That would make a bad UX.
   */
  setUpSuggestedQueries() {
    if (this.suggestQueries_WasSetUp) {
      return
    } else {
      this.suggestQueries_WasSetUp = true
    }

    interval(this.suggestedQuery_Frequency)
      .pipe(
        startWith(this.getRandomTrendingTag()), // so we have a first value without delay
        map(() => this.getRandomTrendingTag())
      )
      .subscribe({
        // emit to subject
        next: (str) => this.currentSuggestedSearchQuery.next(str)
      })
  }

  getRandomTrendingTag() {
    const n = this.trendingHashtags_Overall.length

    if (n == 0) {
      return ''
    }

    // Generate a random index between 0 and n-1
    const i = Math.floor(Math.random() * n)

    return this.trendingHashtags_Overall[i].tag
  }

  get suggestedSearchQuery(): Observable<string> {
    return this.currentSuggestedSearchQuery
  }

  async loadTrendingSearchQueries() {
    if (this.currentlyFetchingSearchQueries || this.hashtagsDisabledFetching) {
      return
    }
    this.currentlyFetchingSearchQueries = true

    const dbFirestore = getFirestore()
    const countLoadItems = this.getChunkSize()

    const q = query(
      collection(dbFirestore, `${StrHlp.CLOUD_PATH}/Search/Queries`),
      orderBy('trendingScore_Week', 'desc'),
      where('trendingScore_Week', '<', this.searchQueryScoreThreshold),
      limit(countLoadItems)
    )

    // get docs
    const querySnapshot = await getDocs(q)

    if (querySnapshot.size == 0) {
      this.searchQueriesDisabledFetching = true
    }

    let tempArr: any[] = []
    querySnapshot.forEach((doc) => {
      const obj = {
        query: doc.data().query,
        count: doc.data().totalScore
      }
      tempArr.push(obj)

      this.searchQueryScoreThreshold = doc.data().trendingScore_Week
    })

    // shuffle the array
    tempArr = this.shuffleArray(tempArr)

    this.trendingSearchQueries.push(...tempArr)

    // for recycling reasons
    this.trendingSearchQueries = [...this.trendingSearchQueries]

    this.currentlyFetchingSearchQueries = false
  }

  async loadTrendingHashtags() {
    if (this.currentlyFetchingHashtags || this.hashtagsDisabledFetching) {
      return
    }
    this.currentlyFetchingHashtags = true

    const dbFirestore = getFirestore()
    const countLoadItems = 20

    const q = query(
      collection(dbFirestore, `${StrHlp.CLOUD_PATH}/Explore/Hashtags`),
      orderBy('trendingScore_Week', 'desc'),
      where('trendingScore_Week', '<', this.hashtagScoreThreshold),
      limit(countLoadItems)
    )

    // get docs
    const querySnapshot = await getDocs(q)

    if (querySnapshot.size == 0) {
      this.hashtagsDisabledFetching = true
    }

    let tempArr: any[] = []
    querySnapshot.forEach((doc) => {
      const obj = {
        tag: doc.data().hashtag,
        count: doc.data().totalScore
      }
      tempArr.push(obj)

      this.hashtagScoreThreshold = doc.data().trendingScore_Week
    })

    // shuffle the array
    tempArr = this.shuffleArray(tempArr)

    if (this.trendingHashtags.length == 0) {
      this.trendingHashtags.push(...tempArr)
    }

    this.trendingHashtags_Overall.push(...tempArr)

    // for recycling reasons
    this.trendingHashtags_Overall = [...this.trendingHashtags_Overall]

    this.currentlyFetchingHashtags = false

    // setup AFTER load of trending tags
    // ssr guarded
    if (this.isBrowserService.isBrowser()) {
      this.setUpSuggestedQueries()
    }
  }

  async loadTrendingProfiles() {
    if (this.currentlyFetchingUsers || this.usersDisabledFetching) {
      return
    }
    this.currentlyFetchingUsers = true

    const dbFirestore = getFirestore()
    const countLoadItems = 20

    const q = query(
      collection(dbFirestore, `${StrHlp.CLOUD_PATH}/Explore/Profiles`),
      orderBy('trendingScore_Week', 'desc'),
      where('trendingScore_Week', '<', this.profileScoreThreshold),
      limit(countLoadItems)
    )

    // get docs
    const querySnapshot = await getDocs(q)

    if (querySnapshot.size == 0) {
      this.usersDisabledFetching = true
    }

    let tempArr: any[] = []
    querySnapshot.forEach((doc) => {
      const obj = {
        userID: doc.data().profileUID,
        count: doc.data().totalScore
      }
      tempArr.push(obj)

      this.profileScoreThreshold = doc.data().trendingScore_Week
    })

    // get the usernames and images
    const promises = tempArr.map(async (arrEl) => {
      arrEl.username = await lastValueFrom(
        this.cacheService.getUsername(arrEl.userID)
      )

      arrEl.imgURL = await lastValueFrom(
        this.cacheService.getProfileImage(arrEl.userID)
      )
    })
    await Promise.all(promises)

    // shuffle the array
    tempArr = this.shuffleArray(tempArr)

    if (this.trendingProfiles.length == 0) {
      this.trendingProfiles.push(...tempArr)
    }

    this.trendingProfiles_Overall.push(...tempArr)

    // for recycling reasons
    this.trendingProfiles_Overall = [...this.trendingProfiles_Overall]

    this.currentlyFetchingUsers = false
  }

  shuffleArray<T>(array: T[]): T[] {
    // Copy the original array to avoid modifying it directly
    const newArray = [...array]

    // Iterate over the array from the end to the beginning
    for (let i = newArray.length - 1; i > 0; i--) {
      // Generate a random index between 0 and i
      const j = Math.floor(Math.random() * (i + 1))

      // Swap the elements at indices i and j
      ;[newArray[i], newArray[j]] = [newArray[j], newArray[i]]
    }

    return newArray
  }
}
