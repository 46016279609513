import { Injectable } from '@angular/core'
import { FeedComponent } from 'src/app/components/feed/feed.component'
import { LocalstorageService } from '../ssr/localstorage.service'

@Injectable({
  providedIn: 'root'
})

/**
 * Bad name, we use it as a feed score service in general
 */
export class ResetlatestscoresService {
  // consts
  private resetScoreAfterNumberDaysOfCheckingFeed = 8

  private dayStr = this.getDayAsString()

  constructor(private localstorageService: LocalstorageService) {}

  resetAllScores() {
    // clips
    this.resetLatestSavedScore(true, false)
    this.resetLatestSavedScore(true, true)

    // normal
    this.resetLatestSavedScore(false, true)
    this.resetLatestSavedScore(false, false)
  }

  resetLatestSavedScore(isClips: Boolean, isNSFW: Boolean): void {
    let path = this.getStoragePath(isClips, isNSFW)

    // set MAX NUMBER as init score
    this.localstorageService.setItem(path, String(Number.MAX_VALUE))

    // reset helper var 1
    path = this.getFeedResetCounterKey(isClips, isNSFW)
    this.localstorageService.setItem(path, '' + 0)

    // reset helper var 2
    path = this.getMostRecentCounterIncreaseDayStringKey(isClips, isNSFW)
    this.localstorageService.setItem(path, this.dayStr)
  }

  getLatestSavedScore(isClips: Boolean, isNSFW: Boolean): number {
    try {
      const path_MRI = this.getMostRecentCounterIncreaseDayStringKey(
        isClips,
        isNSFW
      )
      const path_FeedReset = this.getFeedResetCounterKey(isClips, isNSFW)

      const mostRecentCounterIncrease_DayString: string | null =
        this.localstorageService.getItem(path_MRI)

      let resetScore = false
      //console.log("DragoFeed", "mostRecentCounterIncrease_DayString:",mostRecentCounterIncrease_DayString);

      if (mostRecentCounterIncrease_DayString === null) {
        resetScore = true
      }

      if (
        mostRecentCounterIncrease_DayString === null ||
        mostRecentCounterIncrease_DayString !== this.dayStr
      ) {
        // counter was not increased today yet
        const countValString: string | null =
          this.localstorageService.getItem(path_FeedReset)

        let countVal = -1
        if (countValString) {
          countVal = +countValString
        }
        //console.log("DragoFeed", "countVal:",countVal);

        countVal++

        if (countVal >= this.resetScoreAfterNumberDaysOfCheckingFeed) {
          resetScore = true
        }

        // write new val
        this.localstorageService.setItem(path_FeedReset, '' + countVal)

        // write new val
        this.localstorageService.setItem(path_MRI, this.dayStr)
      }

      //console.log("DragoFeed", "resetScore:",resetScore);
      if (resetScore) {
        this.resetLatestSavedScore(isClips, isNSFW)
      }

      // return latest saved score
      const retString: string | null = this.localstorageService.getItem(
        this.getStoragePath(isClips, isNSFW)
      )

      let ret: number = Number.MAX_VALUE
      if (retString) {
        ret = +retString
      }

      return ret
    } catch (error) {
      console.error(error)
      return Number.MAX_VALUE
    }
  }

  updateLatestSavedScore(isClips: Boolean, isNSFW: Boolean, inp: number) {
    this.localstorageService.setItem(
      this.getStoragePath(isClips, isNSFW),
      String(inp)
    )
  }

  /**
   * Simple system:
   * Each call gets the current day like this "2023_08_09".
   * Checks whether or not the "counter" was increased already today.
   * If so, do nothing. Otherwise, increase it.
   * When the counter reaches X, reset the latest saved score.
   * Otherwise, just return the latest saved score.
   */
  private getDayAsString() {
    const dateAsString = new Date().toISOString() // '2022-05-27T14:51:06.157Z'
    return dateAsString.substring(0, 10) // '2022-05-27'
  }

  private getStoragePath(isClips: Boolean, isNSFW: Boolean) {
    if (isClips) {
      return 'clips_' + 'latest_score_seen_feed-' + isNSFW
    } else {
      return 'latest_score_seen_feed-' + isNSFW
    }
  }

  private getMostRecentCounterIncreaseDayStringKey(
    isClips: Boolean,
    isNSFW: Boolean
  ) {
    if (isClips) {
      return 'clips_' + 'mostRecentCounterIncrease_DayString_key-' + isNSFW
    } else {
      return 'mostRecentCounterIncrease_DayString_key-' + isNSFW
    }
  }

  private getFeedResetCounterKey(isClips: Boolean, isNSFW: Boolean) {
    if (isClips) {
      return 'clips_' + 'feed_Reset_Counter_key-' + isNSFW
    } else {
      return 'feed_Reset_Counter_key-' + isNSFW
    }
  }
}
