<div class="choosing-bar">
  <a
    class="subtle-button feed-choosing-bar-start-end-item-container on-mobile-only"
    [routerLink]="myUsername$ ? '/@' + (myUsername$ | async) : '/login'"
  >
    <!-- Profile icon has moved here instead of notifs tab -->
    <img
      class="profile-image feed-choosing-bar-start-end-item feed-choosing-bar-start-item"
      [src]="profileImg$ | async | imgScaleHelper: 50"
      alt="Profile photo"
      onerror="this.src='/assets/default_profile_pic.jpg';this.onerror='';"
    />
  </a>

  <div
    class="choosing-bar"
    [ngClass]="{
      'choosing-bar-scrollable': allowsNSFW
    }"
  >
    <app-choosing-bar-text-item
      class="choosing-bar-child"
      [isSelected]="selectedFeed == 0"
      text="For you"
      link="/home/foryou"
      ariaLabel="Go to For You Feed"
    />

    @if (isLoggedIn) {
      <app-choosing-bar-text-item
        class="choosing-bar-child"
        [isSelected]="selectedFeed == 1"
        text="Following"
        link="/home/following"
        ariaLabel="Go to Following Feed"
      />
    }

    <app-choosing-bar-text-item
      class="choosing-bar-child"
      [isSelected]="selectedFeed == 2"
      text="Recent"
      link="/home/recent"
      ariaLabel="Go to Recent Feed"
    />

    @if (allowsNSFW) {
      <app-choosing-bar-text-item
        class="choosing-bar-child"
        [isSelected]="selectedFeed == 3"
        text="NSFW"
        link="/adult/hentai"
        ariaLabel="Go to Hentai NSFW Feed"
      />

      <app-choosing-bar-text-item
        class="choosing-bar-child"
        [isSelected]="selectedFeed == 4"
        text="NSFW-Recent"
        link="/adult/hentai-recent"
        ariaLabel="Go to Hentai Recent NSFW Feed"
      />
    }
  </div>

  <!-- Explore has moved here for mobile devices because of clips -->
  <a
    class="subtle-button feed-choosing-bar-start-end-item-container feed-choosing-bar-end-item on-mobile-only"
    role="link"
    aria-label="Open explore page"
    routerLink="/explore"
  >
    <img
      class="dark-icon search-icon feed-choosing-bar-start-end-item"
      src="/assets/ic_search_5.png"
      alt="Search icon"
    />
  </a>
</div>
