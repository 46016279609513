<div class="container-followings" [ngClass]="{ 'fh-page-wrapper': isMobile }">
  <header class="fh-header header bottom-border-divider">
    <app-closebutton [historyBack]="false" mat-dialog-close />

    <h1 class="title">
      {{ title ? title : 'Loading...' }}
    </h1>

    @if (badgeNumber > 0) {
      <div class="count-showing-div-wrapper count-wrapper">
        <div class="count-showing-div-text">
          {{ badgeNumber }}
        </div>
      </div>
    }
  </header>

  <main
    #contentWrapper
    class="fh-main main"
    [ngClass]="{
      'bottom-border-divider': !scrollIsAtTop && isMobile
    }"
    >
    <div id="listWrapper">
      @for (item of items; track item; let i = $index) {
        <div class="item">
          <div class="itemStartWrapper">
            <a
              class="subtle-button"
              role="link"
              aria-label="user profile image"
              href="/user/{{ item.userID }}"
              routerLink="/user/{{ item.userID }}"
              mat-dialog-close
              >
              <img
                [src]="item.image$ | async | imgScaleHelper: 50"
                alt="profile photo"
                onerror="this.src='/assets/default_profile_pic.jpg';this.onerror='';"
                class="userImage profile-image"
                />
            </a>
            <div class="username-container">
              <app-usernametemplate
                mat-dialog-close
              [values]="{
                userID: item.userID
              }"
                />
              @if (isGroupMembers && item.isAdmin) {
                <p class="admin-hint">Admin</p>
              }
            </div>
          </div>
          <div class="buttons-at-end-wrapper">
            @if (userID === otherUserID && title === 'Followers') {
              <span class="removeButton" (click)="removeAsFollower(i)">
                Remove
                <div class="tooltiptext">Remove as follower</div>
              </span>
            }
            @if (areYouGroupAdmin) {
              <span class="removeButton" (click)="removeFromGroup(i)">
                Remove
                <div class="tooltiptext">Remove from group</div>
              </span>
            }
            @if (areYouGroupAdmin && !item.isAdmin) {
              <span class="removeButton" (click)="makeAdmin(i)">
                Make admin
                <div class="tooltiptext">Make this user a group admin</div>
              </span>
            }
          </div>
        </div>
      }
    </div>

    @if (!empty && items.length == 0) {
      <app-loadingcircle />
    }

    @if (empty) {
      <app-emptyhinttemplate
        [text]="
          title === 'Followers'
            ? 'This user does not have any followers...'
            : 'This user is not following anyone...'
        "
        />
    }
  </main>
</div>
