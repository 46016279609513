<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Dark mode settings</h1>
  </header>

  <main class="fh-main content">
    <h2 class="sub-title">Choose a theme:</h2>

    <label class="radio" (click)="update(false)">
      <input name="radio" type="radio" [checked]="!isDarkmode" />
      <span class="radio-text">Light theme</span>
    </label>
    <label class="radio" (click)="update(true)">
      <input name="radio" type="radio" [checked]="isDarkmode" />
      <span class="radio-text">Dark theme</span>
    </label>
  </main>
</div>
