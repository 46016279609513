<footer class="gif-picker-class fh-page-wrapper wrapper">
  <header class="gif-picker-class fh-header bottom-border-divider header">
    <div class="gif-picker-class nav-bar">
      <button
        class="gif-picker-class subtle-button"
        aria-label="search tab"
        (click)="switchToSearchTab()"
        >
        <img
          class="gif-picker-class dark-icon nav-icon"
          [ngClass]="{ 'nav-selected-button': dataService.currTab == 0 }"
          src="/assets/ic_search_5.png"
          alt="search"
          />
      </button>

      <button
        class="gif-picker-class subtle-button"
        aria-label="recent tab"
        (click)="switchToRecentTab()"
        >
        <img
          class="gif-picker-class dark-icon nav-icon"
          [ngClass]="{ 'nav-selected-button': dataService.currTab == 1 }"
          src="/assets/ic_clock.png"
          alt="curerent tab"
          />
      </button>

      <button
        class="gif-picker-class subtle-button"
        aria-label="favorites tab"
        (click)="switchToFavoritesTab()"
        >
        <img
          class="gif-picker-class dark-icon nav-icon"
          [ngClass]="{ 'nav-selected-button': dataService.currTab == 2 }"
          src="/assets/ic_star.png"
          alt="favorites tab"
          />
      </button>
    </div>

    @if (dataService.currTab == 0) {
      <div
        class="gif-picker-class search-wrapper"
        >
        @if (searchMode) {
          <app-backbuttontemplate
            class="gif-picker-class"
            [disableAction]="true"
            (click)="cancelSearch()"
            />
        }
        <app-searchbar
          class="gif-picker-class"
          id="search-bar"
          [hint]="'Search GIF on Tenor'"
          [searchCallback]="searchButtonCallback"
          [disableRecentsHint]="true"
          [hasBorder]="true"
          />
      </div>
    }
  </header>

  <main
    class="gif-picker-class fh-main content"
    (swiperight)="onSwipeRight()"
    (swipeleft)="onSwipeLeft()"
    >
    @if (dataService.currTab == 0 && searchMode) {
      <div
        class="gif-picker-class search-results"
        >
        <div class="gif-picker-class gifs-container">
          @for (item of dataService.searchResults; track item) {
            <div
              class="gif-picker-class gif-result-item"
              >
              <img
                class="gif-picker-class gif-preview"
                #gifImg
                alt="GIF preview"
                (click)="onGifClick(item, gifImg)"
                (longTap)="showDialog_AddToFavorites_Item(item)"
                src="{{ item['media_formats']['nanogif']['url'] }}"
                />
            </div>
          }
        </div>
        @if (emptySearch) {
          <app-emptyhinttemplate class="gif-picker-class" />
        }
      </div>
    }

    @if (dataService.currTab == 0 && !searchMode) {
      <div
        class="gif-picker-class features-gifs"
        >
        <div class="gif-picker-class gifs-container">
          @for (item of dataService.featuredGIFs; track item) {
            <div
              class="gif-picker-class gif-result-item"
              >
              <img
                class="gif-picker-class gif-preview"
                #gifImg
                alt="GIF preview"
                (click)="onGifClick(item, gifImg)"
                (longTap)="showDialog_AddToFavorites_Item(item)"
                src="{{ item['media_formats']['nanogif']['url'] }}"
                />
            </div>
          }
        </div>
        @if (emptyRecommended) {
          <app-emptyhinttemplate class="gif-picker-class">
          </app-emptyhinttemplate>
        }
      </div>
    }

    @if (dataService.currTab == 1) {
      <div
        class="gif-picker-class features-gifs"
        >
        <h2>Recently used</h2>
        <div class="gif-picker-class gifs-container">
          @for (gifURL of dataService.recentGIFs; track gifURL) {
            <div
              class="gif-picker-class gif-result-item"
              >
              <img
                class="gif-picker-class gif-preview"
                #gifImg
                alt="GIF preview"
            (click)="
              handleGifClick_wo_AspectRatio(gifURL, gifURL, null, gifImg)
            "
                (longTap)="showDialog_AddToFavorites(gifURL)"
                src="{{ gifURL }}"
                />
            </div>
          }
        </div>
        @if (dataService.emptyRecent) {
          <app-emptyhinttemplate
            class="gif-picker-class"
            >
          </app-emptyhinttemplate>
        }
      </div>
    }

    @if (dataService.currTab == 2) {
      <div
        class="gif-picker-class features-gifs"
        >
        <h2>Favorites</h2>
        <div class="gif-picker-class gifs-container">
          @for (gifURL of dataService.favorites; track gifURL) {
            <div
              class="gif-picker-class gif-result-item"
              >
              <img
                class="gif-picker-class gif-preview"
                #gifImg
                alt="GIF preview"
            (click)="
              handleGifClick_wo_AspectRatio(gifURL, gifURL, null, gifImg)
            "
                (longTap)="showDialog_RemoveFromFavorites(gifURL)"
                src="{{ gifURL }}"
                />
            </div>
          }
        </div>
        @if (dataService.emptyFavorites) {
          <app-emptyhinttemplate
            class="gif-picker-class"
            [text]="'Empty... Long tap a GIF to add it to your favorites!'"
            >
          </app-emptyhinttemplate>
        }
      </div>
    }

    @if (indicateLoading) {
      <app-loadingcircle></app-loadingcircle>
    }

    @if (!indicateLoading && dataService.currTab == 0) {
      <button
        class="gif-picker-class subtle-button load-more-button"
        (click)="loadMore()"
        >
        Load more...
      </button>
    }
  </main>
</footer>
