import { Injectable } from '@angular/core'
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  map,
  share,
  startWith,
  throttleTime,
  withLatestFrom,
  zip
} from 'rxjs'
import { SystemService } from '../system/systemservice.service'

// filter min vertical dist.
// important to filter out normal clicks for example.
export const clips_minimumVerticalDistForScroll = 30

export const clips_throttleTimePausePlay = 200
export const clips_throttleTimeTouchEvent = 70

@Injectable({
  providedIn: 'root'
})
export class ClipsStateService {
  isMuted = false //SystemService.isMobileIOS();

  /**
   * bool: true=pause, false=play
   */
  private pausePlaySubject = new Subject<void>()

  pausePlay$: Observable<void> | null = null

  touchStartSubject = new Subject<TouchEvent>()
  touchEndSubject = new Subject<TouchEvent>()

  // those touchends where the respective touchstart has a minimum vertical scroll dist
  // so we consider them scroll touchevents.
  scrollTouchEnds$ = new Observable<TouchEvent | null>()

  // values emitted here iif a clip becomes visible but was invisible before.
  // implies that concurrent values are distinct. init with 0.
  currentClipIndexSubject = new BehaviorSubject<number>(0)

  /**
   * combine latest. Not ideal but good enough. (other solutions like zip were not reliable)
   */
  touchEndEventCurrentClipIndex_Obs$: Observable<
    [number, TouchEvent | null]
  > | null = null

  closeSubject = new Subject<void>()

  constructor() {
    this.setUpPlayPauseLogic()
    this.setUpAutoplayLogic()
  }

  setUpAutoplayLogic() {
    this.scrollTouchEnds$ = zip([
      this.touchStartSubject,
      this.touchEndSubject
    ]).pipe(
      filter(([touchStartEvent, touchEndEvent]) =>
        this.areTouchEventsScroll(touchStartEvent, touchEndEvent)
      ),
      map(([_, touchEndEvent]) => touchEndEvent),
      throttleTime(clips_throttleTimeTouchEvent),
      share()
    )

    // autoplay-logic obs
    /**
     * new solution uses withLatestFrom. It will hence only emit on index update
     * and might use old events (max one-scroll-old though).
     *
     * We only use this logic for iOS devices due to their autoplay policy.
     *
     * On non-apple devices, we still use this approach, but we provide a default
     * value for scrollTouchEnds$, so it doesnt require to be clicked to start
     * playing initially.
     */
    const scrollObs$ = SystemService.isIOS()
      ? this.scrollTouchEnds$
      : this.scrollTouchEnds$.pipe(startWith(null), share())

    // Does not work in some sort-of-edge-cases
    // But it works well enough: Current prod solution
    this.touchEndEventCurrentClipIndex_Obs$ = this.currentClipIndexSubject.pipe(
      withLatestFrom(scrollObs$),
      share()
    )
    // --

    // BELOW ONES DONT WORK WELL AND ARE NOT PERFECT.
    // MY QUESTION ON SO WITH THE "ALMOST-ZIP" IS PROBABLY
    // NOT EVEN WHAT I ACTUALLY SHOULD WANT. ABOVE SOLUTION IS
    // LIKELY THE BETTER SOLUTION
    /**
     * What seems to be the best solution but remains to be implemented (TODO)
     * would sth like this:
     * Both subjects:

        CLIP-INDEX-SUBJECT
        1 ---------- 2 ----- 3 -- 4 ------------------- 5 ——--

        TOUCH-EVENT-SUBJECT
        ------- a -------------------- b --- c — d —-————--- e

        Goal observable:

        ------- 1a - 2a --- 3a - 4a -- 4b--------————-5d —- 5e

        So: Whenever clip-index updates, emit.
            When touch-event updates, emit only the first time. Reset that "counter"
            once clip-index has updated.

        Shouldnt be that hard to implement, take inspiration from the SO answers.
        TODO
     */
    /**
     * Does NOT work with piping
      //take(1),
      //repeat(),
      as suggested on SO for some reason.

      Note:
      We are now using combineLatest over withLatestFrom as you see above.
      Its yet to see if this will work better or worse in testing/production.
    this.touchEndEventCurrentClipIndex_Obs$ = combineLatest([
      this.currentClipIndexSubject,
      scrollObs$
    ]).pipe(take(1), repeat(), share())

    // Does NOT work
    this.touchEndEventCurrentClipIndex_Obs$ = almostZip(
      this.currentClipIndexSubject.asObservable(),
      scrollObs$
    ).pipe(share())
    */
  }

  areTouchEventsScroll(start: TouchEvent, end: TouchEvent) {
    const y1 = start.changedTouches[0].clientY
    const y2 = end.changedTouches[0].clientY
    const verticalDistance = Math.abs(y1 - y2)
    //console.log("vrSc. y1:",y1," || y2:",y2, " || verticalDistance:",verticalDistance);

    return verticalDistance >= clips_minimumVerticalDistForScroll
  }

  setUpPlayPauseLogic() {
    // pause play obs
    this.pausePlay$ = this.pausePlaySubject.pipe(
      throttleTime(clips_throttleTimePausePlay)
    )
  }

  emitPausePlay() {
    this.pausePlaySubject.next()
  }

  emitShownClipIndex(index: number) {
    this.currentClipIndexSubject.next(index)
  }

  emitTouchStartEvent(event: TouchEvent) {
    this.touchStartSubject.next(event)
  }

  emitTouchEndEvent(event: TouchEvent) {
    this.touchEndSubject.next(event)
  }

  emitInitialEvent(event: TouchEvent) {
    // skip the "filtering", emit to important obs right away
    this.touchStartSubject.next(event)
    this.touchEndSubject.next(event)
  }

  ngOnDestroy() {
    this.pausePlaySubject.complete()
  }
}
