import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  Output
} from '@angular/core'
import { onVirtualKeyboardClickCallback } from 'src/app/shared/services/overlay/keyboard-service.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'

@Component({
  selector: 'app-emoji-keyboard',
  templateUrl: './emoji-keyboard.component.html',
  styleUrls: ['./emoji-keyboard.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmojiKeyboardComponent {
  isDarkmode = SystemService.isDarkmode()
  isMobile = SystemService.isMobile()

  @Input()
  onClickCallback!: onVirtualKeyboardClickCallback
}
