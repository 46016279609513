import { Component, Input } from '@angular/core'
import { RouterModule } from '@angular/router'
import { NumberCommaFormatPipe } from 'src/app/pipes/number-comma-format.pipe'
import { EncodingService } from 'src/app/shared/services/encoding/encoding.service'

@Component({
  selector: 'app-explore-hashtag-item',
  standalone: true,
  imports: [RouterModule, NumberCommaFormatPipe],
  templateUrl: './explore-hashtag-item.component.html',
  styleUrls: ['./explore-hashtag-item.component.css', '../explore.styles.css']
})
export class ExploreHashtagItemComponent {
  @Input()
  tag!: string

  @Input()
  countInput?: number

  constructor(private encodingService: EncodingService) {}

  get encodedTag() {
    return this.encodingService.encodeForUrlArgument(this.tag)
  }

  get count() {
    return this.countInput ?? 0
  }
}
