import { Component } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { SITE_PROTOCOL } from 'src/app/shared/constants'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'

@Component({
  selector: 'app-pwa',
  templateUrl: './pwa.component.html',
  styleUrls: [
    './pwa.component.css',
    '../../versionhistory/versionhistory.component.css'
  ]
})
export class PwaComponent {
  isLoggedIn = this.authService.isLoggedIn()

  isMobile = false
  isAndroid = false
  isIPhone = false

  showAndroid = false
  showIos = false
  showDesktop = false
  showBottomInfoText = false

  isChrome = false
  isSafari = false

  android_HideFirstTwo = false
  safari_HideFirstTwo = false

  url: string = ''

  chrome_bar_url =
    'https://firebasestorage.googleapis.com/v0/b/nnkphbs.appspot.com/o/HubData%2FHubImages%2FPWA%2Fchrome_pwa_tut_img1_v2.jpg?alt=media&token=80c7436f-da4a-41e2-8bbb-2f2ba6ecc1b8'
  safari_bar_url =
    'https://firebasestorage.googleapis.com/v0/b/nnkphbs.appspot.com/o/HubData%2FHubImages%2FPWA%2FsafarI_pwa_tutorial_img1.jpg?alt=media&token=2421d469-60fd-4817-895e-495304fbe438'

  title = `${StrHlp.APP_NAME} App`

  constructor(
    private authService: AuthService,
    public strHlp: StrHlp,
    public imgHlp: ImageLoadingService,
    private seoHelper: SeoHelperService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const isAPK = this.router.url === '/apk'
    const isWebapp = this.router.url === '/webapp'

    let prefix = `Install the ${StrHlp.APP_NAME} app`
    if (isAPK) {
      this.title = `${StrHlp.APP_NAME} APK`
      prefix = `Install the ${StrHlp.APP_NAME} APK`
    } else if (isWebapp) {
      this.title = `${StrHlp.APP_NAME} Web App`
      prefix = `Install the ${StrHlp.APP_NAME} Web App`
    }

    this.seoHelper.setForSomePage(
      this.title,
      `${prefix}. The #1 app for the ${StrHlp.COMMUNITY_NAME} community! Fast, secure & private. APK download. | ${StrHlp.APP_NAME} App`
    )

    this.isMobile = SystemService.isMobile()
    this.isAndroid = SystemService.isAndroid()
    this.isIPhone = SystemService.isMobileIOS()
    this.isChrome = SystemService.isChrome()
    this.isSafari = SystemService.isSafari()

    if (this.isMobile) {
      this.showAndroid = this.isAndroid
      this.showIos = this.isIPhone
      this.showDesktop = false

      if (this.isAndroid && this.isChrome) {
        this.android_HideFirstTwo = true
      }

      if (this.isIPhone && this.isSafari) {
        this.safari_HideFirstTwo = true
      }
    } else {
      this.showAndroid = true
      this.showIos = true
      this.showDesktop = true
      this.showBottomInfoText = true
    }

    this.url = `${SITE_PROTOCOL}://${StrHlp.APP_URL}`
  }
}
