import { Component, Input } from '@angular/core'
import { ChoosingBarTextItemComponent } from '../../feed/choosing-bar-text-item/choosing-bar-text-item.component'
import { RouterModule } from '@angular/router'

import { AuthService } from 'src/app/shared/services/auth/auth.service'

@Component({
  selector: 'app-search-choosing-bar',
  standalone: true,
  imports: [
    ChoosingBarTextItemComponent,
    RouterModule,
    ChoosingBarTextItemComponent
],
  templateUrl: './search-choosing-bar.component.html',
  styleUrls: [
    './search-choosing-bar.component.css',
    '../../../stylesheets/feed-choosing-bar.css'
  ]
})
export class SearchChoosingBarComponent {
  @Input({ required: true })
  selectedMode!: number

  @Input({ required: true })
  onSelectTopMode!: () => void

  @Input({ required: true })
  onSelectUsersMode!: () => void

  @Input({ required: true })
  onSelectTagsMode!: () => void

  @Input({ required: true })
  onSelectLocationsMode!: () => void

  constructor() {}
}
