import { Component, OnInit } from '@angular/core'
import { StrHlp } from '../../../shared/services/StringGetter/getstring.service'
import { HotToastService } from '@ngneat/hot-toast'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { User } from 'firebase/auth'
import { Observable, startWith } from 'rxjs'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-manageaccount',
  templateUrl: './manageaccount.component.html',
  styleUrls: [
    './manageaccount.component.css',
    '../settingspage/settings.component.css'
  ]
})
export class ManageaccountComponent implements OnInit {
  versionString = ''

  yourUsername$: Observable<string> | null = null
  yourEmail = 'Loading...'
  createdProfileString = 'Loading...'

  constructor(
    private strHlp: StrHlp,
    private toast: HotToastService,
    private cacheService: CacheService,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('Manage account', 'Manage account settings')
    this.versionString = `${this.strHlp.getAppName()} ${this.strHlp.getVersionString()}`

    this.loadAccountInfo()
  }

  loadAccountInfo() {
    const userCallback = (user: User) => {
      if (user !== null) {
        const currentEmail = user.email
        if (currentEmail !== null) {
          this.yourEmail = currentEmail
        } else {
          this.yourEmail = '[Failed]'
        }

        this.createdProfileString = user.metadata.creationTime
          ? user.metadata.creationTime
          : '[Failed]'
      } else {
        this.yourEmail = '[Failed]'
        this.createdProfileString = '[Failed]'
      }
    }
    AuthService.registerAfterLoadedCallback(userCallback)

    const userID = AuthService.getUID()
    if (userID) {
      this.yourUsername$ = this.cacheService
        .getUsername(userID)
        .pipe(startWith('Loading...'))
    }
  }

  async copyAppVersion() {
    try {
      await navigator.clipboard.writeText(this.versionString)
      this.toast.success('Copied to clipboard')
    } catch (err) {
      console.error('Failed to copy')
    }
  }

  testCode(event: MouseEvent) {}
}
