import { Component, ElementRef, Inject, ViewChild } from '@angular/core'
import { HotToastService } from '@ngneat/hot-toast'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { DownloadserviceService } from 'src/app/shared/services/media/downloadservice.service'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { GifkeyboarddataService } from 'src/app/shared/services/data/gifkeyboarddata.service'
import { ZoomhelperService } from 'src/app/shared/services/images/zoomhelper.service'
import { CdkDragEnd } from '@angular/cdk/drag-drop'

const thresholdSwipeDownDistY = 90

@Component({
  selector: 'app-fullscreenimage',
  templateUrl: './fullscreenimage.component.html',
  styleUrls: ['./fullscreenimage.component.css']
})
export class FullscreenimageComponent {
  imageURL = ''
  gifURL = ''
  mediaURL = ''

  title = ''
  desc = ''

  zoomScale = 1

  no_Zoom_Threshold = 1.1
  considerZoomedIn = false

  hideHeader = false

  isGIF = false
  gifIsFavorized = false

  @ViewChild('image') image!: ElementRef

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: HotToastService,
    private downloadService: DownloadserviceService,
    private dialogRef: MatDialogRef<FullscreenimageComponent>,
    private gifDataService: GifkeyboarddataService
  ) {}

  ngOnInit(): void {
    if (this.data === undefined || this.data === null) {
      this.toast.error('An error occurred')
      return
    }
    this.imageURL = this.data.imageURL
    this.gifURL = this.data.gifURL

    this.title = this.data.title ? this.data.title : ''
    this.desc = this.data.desc ? this.data.desc : ''

    if (this.imageURL) {
      this.mediaURL = this.imageURL
    } else if (this.gifURL) {
      this.mediaURL = this.gifURL
    } else {
      this.toast.error('Error occurred')
    }

    this.isGIF =
      this.gifURL !== undefined && this.gifURL !== null && this.gifURL !== ''
    if (this.isGIF) {
      this.gifIsFavorized = this.gifDataService.isGifFavorized(this.gifURL)
    }
  }

  /**
   * Image returns to its normal position after being dragged
   */
  onDragEnd(e: CdkDragEnd) {
    e.source._dragRef.reset()

    if (Math.abs(e.distance.y) >= thresholdSwipeDownDistY) {
      if (!this.considerZoomedIn) {
        this.closeDialog()
      }
    }
  }

  downloadImage(): void {
    // check if GIF or image
    if (this.imageURL) {
      this.downloadService.downloadImage(
        this.imageURL,
        `${StrHlp.APP_NAME}_image`
      )
    } else {
      this.downloadService.downloadGIF(this.gifURL, `${StrHlp.APP_NAME}_gif`)
    }
  }

  closeDialog() {
    this.dialogRef.close()
  }

  unfavorizeGIF() {
    this.gifDataService.removeFavorite(this.gifURL)
    this.gifIsFavorized = false
  }
  favorizeGIF() {
    this.gifDataService.addFavorite(this.gifURL)
    this.gifIsFavorized = true
  }

  swapHeaderHiding() {
    this.hideHeader = !this.hideHeader
  }
}
