<div class="dialog">
  <div class="dialog-title">{{ title }}</div>
  <div class="dialog-description">{{ desc }}</div>
  <div class="dialog-divider"></div>
  <button class="dialog-button" (click)="onButton1Click()">
    {{ button1Text }}
  </button>
  <div class="dialog-divider"></div>
  <button class="dialog-button" (click)="onButton2Click()">
    {{ button2Text }}
  </button>
  <div class="dialog-divider"></div>
  <button class="dialog-button" (click)="onButton3Click()">
    {{ button3Text }}
  </button>
</div>
