import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { HotToastService } from '@ngneat/hot-toast'
import { StrHlp } from '../../../shared/services/StringGetter/getstring.service'
import { getAuth, updateEmail, User } from 'firebase/auth'
import firebase from 'firebase/compat/app'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { LocalstorageService } from 'src/app/shared/services/ssr/localstorage.service'

@Component({
  selector: 'app-emailsettings',
  templateUrl: './emailsettings.component.html',
  styleUrls: [
    '../settingspage/settings.component.css',
    '../../sign-in/sign-in.component.css'
  ]
})
export class EmailsettingsComponent implements OnInit {
  userID: any = null
  user: any = null
  currentEmail: string = 'Loading...'

  @ViewChild('email') email!: ElementRef
  @ViewChild('password') password!: ElementRef
  @ViewChild('emailInvalidError')
  emailInvalidError!: ElementRef
  @ViewChild('passwordInvalidError')
  passwordInvalidError!: ElementRef
  @ViewChild('showpassword') showpassword!: ElementRef
  @ViewChild('changeButton') changeButton!: ElementRef

  emailEntered: string = ''
  passwordEntered: string = ''
  passwordVisible: boolean = false

  constructor(
    private toast: HotToastService,
    private seoHelper: SeoHelperService,
    private localstorageService: LocalstorageService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('Email settings', 'Email settings')

    this.userID = AuthService.getUID()
    this.user = AuthService.getUser()
    this.currentEmail = this.user.email
  }

  changePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible

    if (this.passwordVisible) {
      this.showpassword.nativeElement.innerText = 'Hide password'
      this.password.nativeElement.type = 'text'
    } else {
      this.showpassword.nativeElement.innerText = 'Show password'
      this.password.nativeElement.type = 'password'
    }
  }

  changeEmail(): void {
    // check if entered info is valid
    if (!this.isValidInput()) {
      return
    }

    this.changeButton.nativeElement.innerText = 'Loading...'

    this.emailEntered = this.emailEntered.replaceAll(' ', '')

    // attempt to change the email
    firebase
      .auth()
      .signInWithEmailAndPassword(this.currentEmail, this.passwordEntered)
      .then((userCredential) => {
        const currUser = userCredential.user
        if (currUser) {
          updateEmail(currUser as any, this.emailEntered)
            .then(() => {
              this.toast.success('Email updated')

              // update in local storage
              this.user.email = this.emailEntered
              this.localstorageService.setItem(
                'user',
                JSON.stringify(this.user)
              )

              history.back()
            })
            .catch((error) => {
              this.toast.error('An error has occurred. Email was not updated')
              console.log(error)
            })
        } else {
          this.toast.error('An error has occurred. Email was not updated')
        }
      })
      .catch((error) => {
        this.toast.error(error.message)
        this.toast.error(
          'Logging in to your account failed. Likely the password you entered is wrong'
        )
      })
  }

  isValidInput(): boolean {
    // remove errors
    this.emailInvalidError.nativeElement.style.display = 'none'
    this.passwordInvalidError.nativeElement.style.display = 'none'

    if (!this.emailEntered) {
      this.emailInvalidError.nativeElement.innerText =
        'Enter new email to continue'
      this.emailInvalidError.nativeElement.style.display = 'block'
      return false
    }

    if (!this.isEmailValid()) {
      this.emailInvalidError.nativeElement.innerText = 'Invalid email'
      this.emailInvalidError.nativeElement.style.display = 'block'
      return false
    }

    if (!this.passwordEntered) {
      this.passwordInvalidError.nativeElement.innerText =
        'Enter your password to continue'
      this.passwordInvalidError.nativeElement.style.display = 'block'
      return false
    }

    if (this.passwordEntered.length < 6) {
      this.passwordInvalidError.nativeElement.innerText =
        'Password must be at least 6 characters'
      this.passwordInvalidError.nativeElement.style.display = 'block'
      return false
    }

    return true
  }

  isEmailValid(): boolean {
    const regex =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
    return regex.test(this.emailEntered)
  }
}
