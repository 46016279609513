<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Blocked users</h1>
  </header>

  <main class="fh-main content">
    <h2 class="sub-title" #title>Blocked Users</h2>

    @if (isLoading && !isEmpty) {
      <app-loadingcircle> </app-loadingcircle>
    }

    @if (!isLoading && isEmpty) {
      <app-emptyhinttemplate>
      </app-emptyhinttemplate>
    }

    <div id="listWrapper">
      @for (item of blockedusers; track item; let i = $index) {
        <div class="item">
          <div class="itemStartWrapper">
            <img
            src="{{
              item.profilePhoto
                ? (item.profilePhoto | imgScaleHelper: 50)
                : '/assets/default_profile_pic.jpg'
            }}"
              onerror="this.src='/assets/default_profile_pic.jpg';this.onerror='';"
              class="userImage"
              (click)="routingHelper.user(item.username, item.userID)"
              alt="{{ item.username }} photo"
              />
            <span
              class="username"
              (click)="routingHelper.user(item.username, item.userID)"
              >
              {{ item.username ? item.username : 'username' }}</span
              >
            </div>
            <span
              class="removeButton"
              (click)="unblockUser(item.username, item.userID, i)"
              >
              Unblock
              <div class="tooltiptext">Unblock this user</div>
            </span>
          </div>
        }
      </div>
    </main>
  </div>
