<div class="feedWrapper">
  <div
    class="hashtag-header"
    [ngClass]="{ 'bottom-border-divider': !scrollIsAtTop }"
    >
    <div class="subheader">
      <app-backbuttontemplate />

      <h1 class="page-title">
        {{ title }}
      </h1>

      @if (postCount) {
        <div class="count-showing-div-wrapper count-wrapper">
          <div class="count-showing-div-text">
            {{
            (postCount | numberAbbr) + (postCount == 1 ? ' post' : ' posts')
            }}
          </div>
        </div>
      }
    </div>

    <!--
    <h3>Sort and filter:</h3>
    -->

    <div class="hashtag-sort-filter-wrapper">
      @if (strHlp.getAllowsNSFW()) {
        <div class="sort-filter-wrapper">
          <button
            class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': !isNSFW,
              'sort-filter-button-inactive': isNSFW
            }"
            (click)="changeNSFW(false)"
            >
            Normal
          </button>

          <button
            class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': isNSFW,
              'sort-filter-button-inactive': !isNSFW
            }"
            (click)="changeNSFW(true)"
            >
            NSFW
          </button>
        </div>
      }

      @if (isHashtag) {
        @if (strHlp.getAllowsNSFW()) {
          <p
            class="sort-filter-options-group-divider"
            >
            •
          </p>
        }

        <div class="sort-filter-wrapper">
          <button
            class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': sortMode == 0,
              'sort-filter-button-inactive': sortMode != 0
            }"
            (click)="changeSortTo(0)"
            >
            Likes
          </button>

          <button
            class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': sortMode == 1,
              'sort-filter-button-inactive': sortMode != 1
            }"
            (click)="changeSortTo(1)"
            >
            Trending
          </button>

          <button
            class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': sortMode == 2,
              'sort-filter-button-inactive': sortMode != 2
            }"
            (click)="changeSortTo(2)"
            >
            Newest
          </button>
        </div>

        <p class="sort-filter-options-group-divider">•</p>

        <div class="sort-filter-wrapper">
          <button
            class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': contentType == 0,
              'sort-filter-button-inactive': contentType != 0
            }"
            (click)="changeContentType(0)"
            >
            All
          </button>

          <button
            class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': contentType == 1,
              'sort-filter-button-inactive': contentType != 1
            }"
            (click)="changeContentType(1)"
            >
            Video
          </button>

          <button
            class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': contentType == 2,
              'sort-filter-button-inactive': contentType != 2
            }"
            (click)="changeContentType(2)"
            >
            Image
          </button>

          <button
            class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': contentType == 3,
              'sort-filter-button-inactive': contentType != 3
            }"
            (click)="changeContentType(3)"
            >
            Only text
          </button>
        </div>

        <p class="sort-filter-options-group-divider">•</p>

        @if (sortMode != 2) {
          <div class="sort-filter-wrapper">
            <button
              class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': timeframe == 0,
              'sort-filter-button-inactive': timeframe != 0
            }"
              (click)="changeTimeFrame(0)"
              >
              All time
            </button>
            <button
              class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': timeframe == 1,
              'sort-filter-button-inactive': timeframe != 1
            }"
              (click)="changeTimeFrame(1)"
              >
              This year
            </button>
            <button
              class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': timeframe == 2,
              'sort-filter-button-inactive': timeframe != 2
            }"
              (click)="changeTimeFrame(2)"
              >
              This month
            </button>
            <button
              class="subtle-button sort-filter-button usual-border-radius"
            [ngClass]="{
              'sort-filter-button-active': timeframe == 3,
              'sort-filter-button-inactive': timeframe != 3
            }"
              (click)="changeTimeFrame(3)"
              >
              Today
            </button>
          </div>
        }
      }
    </div>
  </div>

  <div>
    @if (empty) {
      <app-emptyhinttemplate text="No posts yet" />
    }

    @if (!empty && feedDataService.postList.length == 0) {
      <app-loadingcircle />
    }

    <cdk-virtual-scroll-viewport
      #virtualScrollViewport
      class="post-list below-header-height"
      predictingVirtualScroll
      [data]="{
        items: feedDataService.postList,
        itemType: 0,
        idAttrName: 'postID'
      }"
      >
      <div
        class="post"
        *cdkVirtualFor="let post of feedDataService.postList; let i = index"
        [attr.data-hm-id]="post.postID"
        >
        <post-template
          [post]="{
            post: post,
            indexInList: i,
            postEnv: 0
          }"
          />
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
