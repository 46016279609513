import { Component } from '@angular/core'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-milestones',
  templateUrl: './milestones.component.html',
  styleUrls: [
    './milestones.component.css',
    '../../versionhistory/versionhistory.component.css'
  ]
})
export class MilestonesComponent {
  constructor(
    public strHlp: StrHlp,
    private seoHelper: SeoHelperService
  ) {}

  ngOnInit(): void {
    this.seoHelper.setForSomePage('Milestones', 'Milestones')
  }
}
