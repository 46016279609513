import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { LocalstorageService } from 'src/app/shared/services/ssr/localstorage.service'

@Component({
  selector: 'app-nsfw-confirmation',
  templateUrl: './nsfw-confirmation.component.html',
  styleUrls: ['./nsfw-confirmation.component.css']
})
export class NsfwConfirmationComponent {
  appName = StrHlp.APP_NAME

  constructor(
    public dialogRef: MatDialogRef<NsfwConfirmationComponent>,
    private localstorageService: LocalstorageService
  ) {}

  agreeToNSFW(): void {
    this.localstorageService.setItem('agreedToNSFW', 'true')
    this.dialogRef.close()
  }
}
