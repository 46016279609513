import { Pipe, PipeTransform } from '@angular/core'
import { ImageLoadingService } from '../shared/services/imageloading/imageloading.service'

@Pipe({
  name: 'imgScaleHelper',
  standalone: true
})
export class ImgScaleHelperPipe implements PipeTransform {
  constructor(private imgHlp: ImageLoadingService) {}

  transform(value: unknown, ...args: unknown[]): string {
    if (!value) {
      return value as string
    }

    const dimension = args && args.length > 0 ? (args[0] as number) : 50

    return this.imgHlp.do(value as string, dimension)
  }
}
