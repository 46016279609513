import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { HotToastService } from '@ngneat/hot-toast'
import { StrHlp } from '../../shared/services/StringGetter/getstring.service'
import { child, get, getDatabase, ref, set } from 'firebase/database'
import { User } from 'src/app/shared/services/auth/user'
import {
  getDownloadURL,
  getStorage,
  ref as refStorage,
  uploadString
} from 'firebase/storage'
import { Title } from '@angular/platform-browser'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { LoadingDialogComponent } from '../dialogs/loading-dialog/loading-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { mediumToUpload } from '../templates/upload/mediumToUpload'
import { KeyboardService } from 'src/app/shared/services/overlay/keyboard-service.service'
import { Observable, map, take } from 'rxjs'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: [
    './editprofile.component.css',
    '../settings/settingspage/settings.component.css',
    '../makepost/makepost.component.css'
  ]
})
export class EditprofileComponent implements OnInit {
  public static imageIsGif_Includes_Note = 'giff_nte_hb_gf_'

  user: User | null = null
  fullnameEntered: string = ''
  bioEntered: string = ''

  fullnameInit: string = ''
  bioInit: string = ''

  userID: string = ''
  rtdb = ref(getDatabase())

  @ViewChild('fullnameError') fullnameError!: ElementRef
  @ViewChild('profileImage') profileImage!: ElementRef
  @ViewChild('privateProfileCheck') privateProfileCheck!: ElementRef

  isPrivate: boolean = false
  imageUploadedCallback: (arr: mediumToUpload[]) => void
  onGifSelectedCallback = (arg0: string) => {
    this.onGifSelectedCallback(arg0)
  }

  isMobile: boolean = SystemService.isMobile()
  isDarkmode: boolean = false

  emojiPickerOnBio: boolean = false
  showEmojiPicker: boolean = false
  showGifKeyboard = false

  profileImage$: Observable<string> | null = null

  constructor(
    private toast: HotToastService,
    private dialog: MatDialog,
    private twobuttonsdialogService: TwobuttonsdialogService,
    public strHlp: StrHlp,
    private cacheService: CacheService,
    private keyboardService: KeyboardService,
    private seoHelper: SeoHelperService
  ) {
    this.imageUploadedCallback = (arr: mediumToUpload[]) => {
      const img = arr[0].imageAsString!
      this.startUploading(img)
    }
  }

  ngOnInit(): void {
    this.userID = AuthService.getUID()!
    this.isDarkmode = SystemService.isDarkmode()

    this.profileImage$ = this.cacheService.getProfileImage(this.userID)

    this.seoHelper.setForSomePage('Edit profile', 'Customize your profile!')
  }

  ngAfterViewInit(): void {
    this.loadUser()
    this.loadPrivate()
    this.setUpHandleDocumentClick()
  }

  loadPrivate() {
    get(
      child(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.userID}/PrivateProfile`
      )
    ).then((snapshot) => {
      if (snapshot.exists()) {
        this.isPrivate = snapshot.val()
      }
      this.privateProfileCheck.nativeElement.checked = this.isPrivate
    })
  }

  async onGifSelected(miniURL: string) {
    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    await set(
      child(this.rtdb, `${StrHlp.CLOUD_PATH}/Users/${this.userID}/gifURL`),
      miniURL
    )

    loadingDialogRef.close()
    this.toast.success('Profile image updated')
  }

  startUploading(stringRes: string): void {
    // UI
    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    const isGif = stringRes.includes('image/gif')
    const uniqueMaker = isGif
      ? `${EditprofileComponent.imageIsGif_Includes_Note}${Date.now()}`
      : `${Date.now()}`

    const storage = getStorage()
    const storageRef = refStorage(
      storage,
      `${StrHlp.CLOUD_PATH}/photos/users/${this.userID}/profilephoto/${uniqueMaker}/img_rsz_`
    )

    uploadString(storageRef, stringRes, 'data_url')
      .then((snapshot) => {
        console.log('Uploaded a data_url string!')

        // get download URL
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL)

          // update UI (here)
          if (this.user !== null) {
            this.user.profilePhoto = downloadURL
          }

          // cloud
          set(
            child(
              this.rtdb,
              `${StrHlp.CLOUD_PATH}/Users/${this.userID}/profilePhoto`
            ),
            downloadURL
          )

          this.toast.success('Profile image updated')

          // finish up UI
          loadingDialogRef.close()
        })
      })
      .catch((error) => {
        console.log(error)
        this.toast.error('An error has occurred')

        loadingDialogRef.close()
      })
  }

  loadUser(): void {
    get(child(this.rtdb, `${StrHlp.CLOUD_PATH}/Users/${this.userID}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.user = snapshot.val()

          this.fullnameEntered = this.user!.fullName
          this.fullnameInit = this.fullnameEntered

          this.bioEntered = this.user!.bio
          this.bioInit = this.bioEntered
        } else {
          this.toast.error('An error has occurred')
          history.back()
        }
      })
      .catch((error) => {
        console.error(error)
        this.toast.error('An error has occurred')
        history.back()
      })
  }

  saveInput(): void {
    // only: fullname and bio
    // the others (such as image and private) are immediately saved
    this.saveFullName() // starting point (do not start else where)
  }

  onPrivateProfileCheckClick(): void {
    // ask if sure
    this.twobuttonsdialogService.show(
      'Private profile',
      'Do you want to make your profile private?',
      () => {
        // call cloud func
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        const functions = getFunctions()
        const cloudFunction = httpsCallable(functions, 'makeProfilePrivate')
        cloudFunction({
          hubname: StrHlp.CLOUD_PATH
        })
          .then((result) => {
            loadingDialogRef.close()

            this.isPrivate = true
            this.toast.success('Your profile is now private')
            this.privateProfileCheck.nativeElement.checked = this.isPrivate
          })
          .catch((error) => {
            loadingDialogRef.close()
            console.log(error)
          })
      },
      () => {
        // call cloud func
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        const functions = getFunctions()
        const cloudFunction = httpsCallable(functions, 'makeProfilePublic')
        cloudFunction({
          hubname: StrHlp.CLOUD_PATH
        })
          .then((result) => {
            loadingDialogRef.close()

            this.isPrivate = false
            this.toast.success('Your profile is now public')
            this.privateProfileCheck.nativeElement.checked = this.isPrivate
          })
          .catch((error) => {
            loadingDialogRef.close()
            console.log(error)
          })
      },
      'Private profile',
      'Public profile',
      false
    )
  }

  saveBio(): void {
    this.bioEntered = this.bioEntered
      .replaceAll('\n\n', '\n')
      .replaceAll('  ', ' ')
    if (this.bioEntered !== this.bioInit) {
      set(
        child(this.rtdb, `${StrHlp.CLOUD_PATH}/Users/${this.userID}/bio`),
        this.bioEntered
      )
        .then(() => {
          this.afterSaving()
        })
        .catch((error) => {
          console.error(error)
          this.toast.error('Saving has failed')
        })
    } else {
      this.afterSaving()
    }
  }

  saveFullName(): void {
    this.fullnameEntered = this.fullnameEntered
      .replaceAll('\n', '')
      .replaceAll('  ', ' ')

    this.fullnameError.nativeElement.style.display = 'none'
    if (this.fullnameEntered.length == 0) {
      this.fullnameError.nativeElement.style.display = 'block'
      return
    }

    if (this.fullnameEntered !== this.fullnameInit) {
      set(
        child(this.rtdb, `${StrHlp.CLOUD_PATH}/Users/${this.userID}/fullName`),
        this.fullnameEntered
      )
        .then(() => {
          this.saveBio()
        })
        .catch((error) => {
          console.error(error)
          this.toast.error('Saving has failed')
        })
    } else {
      this.saveBio()
    }
  }

  afterSaving(): void {
    this.toast.success('Saved')
    history.back()
  }

  deleteProfileImage(): void {
    this.twobuttonsdialogService.show(
      'Delete profile image',
      'Do you want to delete your profile image?',
      () => {
        // nothing
      },
      () => {
        set(
          child(
            this.rtdb,
            `${StrHlp.CLOUD_PATH}/Users/${this.userID}/profilePhoto`
          ),
          ''
        )
          .then(() => {
            // UI
            this.user!.profilePhoto = ''
            this.toast.success('Deleted')
          })
          .catch((error) => {
            console.log(error)
            this.toast.error('Deleting failed')
          })
      },
      'Cancel',
      'Delete'
    )
  }

  emojiButtonClick_Bio() {
    this.showEmojiPicker = true
    this.emojiPickerOnBio = true

    this.keyboardService.openEmoji(
      (emj: string) => {
        this.addEmoji(emj)
      },
      () => {
        this.showEmojiPicker = false
      }
    )
  }

  emojiButtonClick_FullName() {
    this.showEmojiPicker = true
    this.emojiPickerOnBio = false

    this.keyboardService.openEmoji(
      (emj: string) => {
        this.addEmoji(emj)
      },
      () => {
        this.showEmojiPicker = false
      }
    )
  }

  hideEmojiKeyboard() {
    this.showEmojiPicker = false
  }

  addEmoji(emoji: string) {
    if (this.emojiPickerOnBio) {
      this.bioEntered = `${this.bioEntered}${emoji}`
    } else {
      this.fullnameEntered = `${this.fullnameEntered}${emoji}`
    }
  }

  setUpHandleDocumentClick() {
    // ssr-guarded
    if (typeof document === 'undefined') {
      return
    }

    const handleDocumentClick = (event: MouseEvent): void => {
      // Check if emoji keyboard is open, and if so, then close if the click is outside of it
      let isEmojiClassFound = false

      if (event && event.composedPath) {
        const path = event.composedPath()
        path.forEach((elem: EventTarget | null) => {
          if (elem && (elem as HTMLElement).classList) {
            const classList = (elem as HTMLElement).classList
            if (classList.contains('emoji-mart')) {
              isEmojiClassFound = true
            }
          }
        })
      }

      const targetID = (event.target as HTMLElement).id
      if (!isEmojiClassFound && targetID !== 'emojiButton') {
        this.hideEmojiKeyboard()
      }
    }

    document.addEventListener('click', handleDocumentClick, false)
  }

  chooseGifAsPfp() {
    this.showGifKeyboard = !this.showGifKeyboard
  }
}
