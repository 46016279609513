import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { VideoplayerComponent } from 'src/app/components/videoplayer/videoplayer.component'

@Injectable({
  providedIn: 'root'
})
export class OpenvideoplayerService {
  constructor(public dialog: MatDialog) {}

  show(vidID: string) {
    if (vidID.trim() === '') {
      return
    }

    const data = {
      videoID: vidID
    }

    this.dialog.open(VideoplayerComponent, {
      panelClass: 'no-max-width',
      data: data,
      width: '100vw',
      maxWidth: '100vw',
      //maxHeight: '90vw',
      height: '100%',
      backdropClass: 'bdrop'
    })
  }
}
