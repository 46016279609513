import { Injectable } from '@angular/core'
import { LocalstorageService } from '../ssr/localstorage.service'

@Injectable({
  providedIn: 'root'
})
export class RecentsearchesService {
  recentSearches: string[] = []

  constructor(private localstorageService: LocalstorageService) {
    try {
      const raw = this.localstorageService.getItem('recentSearches')
      if (raw) {
        this.recentSearches = JSON.parse(raw) || []
      }
    } catch (e) {}
  }

  addSearchQuery(query: string) {
    const currIndex = this.recentSearches.indexOf(query)
    console.log(this.recentSearches)
    console.log(currIndex)

    if (currIndex !== -1) {
      // list already contains query
      // swap it to the beginning
      this.recentSearches.splice(currIndex, 1)
      this.recentSearches.unshift(query)
    } else {
      if (this.recentSearches.length >= 5) {
        this.recentSearches.pop()
      }
      this.recentSearches.unshift(query)
      this.localstorageService.setItem(
        'recentSearches',
        JSON.stringify(this.recentSearches)
      )
    }
  }

  clearAllSearches() {
    this.recentSearches = []
    this.localstorageService.removeItem('recentSearches')
  }

  removeSearchQuery(index: number) {
    this.recentSearches.splice(index, 1)
    this.localstorageService.setItem(
      'recentSearches',
      JSON.stringify(this.recentSearches)
    )
  }
}
