<div class="dialog">
  <div class="dialog-title">{{ title }}</div>
  <div class="dialog-description">{{ desc }}</div>

  <div class="dialog-divider"></div>

  <div class="dialog-buttons">
    <button class="dialog-button dialog-button-left" (click)="onButton1Click()">
      {{ button1Text }}
    </button>
    <div class="dialog-button-divider"></div>
    <button
      class="dialog-button dialog-button-right"
      (click)="onButton2Click()"
    >
      {{ button2Text }}
    </button>
  </div>
</div>
