<div id="wrapper">
  <h1>Statistics {{ strHlp.getAppName() }}</h1>
  <p>
    This page displays a limited set of milestones from
    {{ strHlp.getAppName() }}'s history. It is important to acknowledge that
    this is not an exhaustive representation.
  </p>

  <p>Last updated on: 19/01/2024</p>

  <hr class="inline-divider" />
  <div class="version">
    <h2>Website</h2>
    <p>Achieved on: 30/09/2023</p>
    <ul>
      <li><b>Website total screen views:</b> 500.000</li>
    </ul>
  </div>
</div>
