import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router'
import { Observable } from 'rxjs'
import { LocalstorageService } from '../services/ssr/localstorage.service'

@Injectable({
  providedIn: 'root'
})
export class StartPageGuard {
  constructor(
    private router: Router,
    private localstorageService: LocalstorageService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const startPage = this.localstorageService.getItem('startPageSetting')
    switch (startPage) {
      // case 1 and 0 belong together from the settings-perspective.
      // a la YT shorts: if home (the default) is your start screen, then it will
      // show the normal feed or the clips, depending on what you were using the latest.
      // If something else is your setting, it will always show that setting as starting point.
      case '1':
        this.router.navigate(['/home/auto'])
        return false

      case '0':
        this.router.navigate(['/clips'])
        return false

      case '2':
        this.router.navigate(['/explore'])
        return false

      case '3':
        this.router.navigate(['/chats'])
        return false

      default:
        return true
    }
  }
}
