@if (!authService.isEmailVerified) {
  <div id="verify-email-hint">You need to verify your email to use chats</div>
} @else {
  <div class="chats-page-wrapper full-screen-height">
    <header
      id="header"
      [ngClass]="{
        'header-hidden': isHeaderHidden$ | async,
        'header-show': !(isHeaderHidden$ | async)
      }"
    >
      <div class="flexSpaceBetween header-part-1">
        <div class="flexAlignCenter">
          <h1 class="page-title" (click)="scrollTop()">
            {{ pageTitle }}
          </h1>
        </div>

        <div class="chats-options-wrapper">
          @if (isLoggedIn) {
            <button
              id="search-button"
              aria-label="search"
              class="subtle-button"
              (click)="searchButtonClick()"
              matTooltip="Search chat"
              [matTooltipShowDelay]="1000"
            >
              <img
                class="dark-icon settings-icon search-icon"
                src="/assets/ic_search_5.png"
                alt="search"
              />
            </button>
          }

          <a
            id="settings-wrapper"
            routerLink="/settings/chats"
            matTooltip="Chat settings"
            [matTooltipShowDelay]="1000"
          >
            <img
              class="dark-icon settings-icon"
              src="/assets/ic_settings2.png"
              alt="chat settings"
            />
          </a>

          <button
            class="subtle-button bottom-button"
            (click)="openCreateNewGroup()"
            aria-label="create group"
          >
            <img
              src="/assets/ic_group.png"
              class="light-icon bottom-button-icon"
              alt="Group"
            />
          </button>
        </div>
      </div>

      <!--
      <app-chat-choosing-bar
        id="feed-choosing-bar"
        [selectedMode]="selectedMode"
        [onSelectChatsMode]="onSelectChatsMode_AsParam"
        [onSelectGroupsMode]="onSelectGroupsMode_AsParam"
      />
      -->
    </header>

    <mat-tab-group
      class="chats-tab-group"
      #matTabGroup
      dynamicHeight
      disableRipple
      fitInkBarToContent
      appSwipeDetect
      (onSwipeLeftDetect)="onSwipeLeft()"
      (onSwipeRightDetect)="onSwipeRight()"
      [selectedIndex]="selectedTab"
      (selectedIndexChange)="selectedIndexChange($event)"
    >
      @if (isLoggedIn) {
        <!-- No more virtual scrolling in here for now -->
        <mat-tab id="listWrapper">
          <ng-template mat-tab-label>
            <app-choosing-bar-text-item
              class="choosing-bar-child"
              [isSelected]="selectedTab == 0"
              text="Chats"
              ariaLabel="Your chats"
              [showInk]="false"
            />
          </ng-template>

          <div #scrollContainerChats class="below-header-height y-scrollable">
            @for (
              item of chataDataService.chatList;
              track item.chatID;
              let i = $index
            ) {
              <app-chatitemtemplate
                class=""
                [item]="{
                  item: item,
                  indexInList: i,
                  hideMenuButton: isMobile,
                  allowLongTap: isMobile,
                  itemClickCallback: openChatCallback,
                  pinChatCallback: pinChatCallback,
                  unpinChatCallback: unpinChatCallback
                }"
              />
            }
          </div>
          @if (
            !chataDataService.listEmpty && chataDataService.chatList.length == 0
          ) {
            <div id="loading-indicator-wrapper">
              <app-loadingcircle />
            </div>
          }
        </mat-tab>
      }

      <mat-tab id="listWrapper">
        <ng-template mat-tab-label>
          <app-choosing-bar-text-item
            class="choosing-bar-child"
            [isSelected]="selectedTab == 1"
            text="Groups"
            ariaLabel="Group chats"
            [showInk]="false"
          />
        </ng-template>

        <div #scrollContainerGroups class="below-header-height y-scrollable">
          @for (
            item of groupdataService.itemList;
            track item.chatId;
            let i = $index
          ) {
            <app-groupitemtemplate
              [item]="{
                item: item,
                indexInList: i
              }"
            />
          }
        </div>

        @if (!groupdataService.empty && groupdataService.itemList.length == 0) {
          <div id="loading-indicator-wrapper">
            <app-loadingcircle />
          </div>
        }
      </mat-tab>
    </mat-tab-group>
  </div>
}
