import { Injectable } from '@angular/core'
import { StrHlp } from '../StringGetter/getstring.service'
import { CacheService } from '../caching/cache-service.service'
import { AuthService } from '../auth/auth.service'
import { MainComponent } from 'src/app/components/main/main.component'
import { take } from 'rxjs'

@Injectable({
  providedIn: 'root'
})

/**
 * super simple system.
 *
 * add() --> returns ad-post|null
 * If null, dont add an ad there, otherwise add obj (ad).
 *
 * Based on counters.
 */
export class FeedadsService {
  private count_Posts = 0
  private count_Inserted_SystemAds = 0
  private count = 0 // for postID

  private count_Posts_Insert_SystemAd_Modulo = 8 // each x-th is a system ad
  private count_Posts_Insert_SystemAd_Modulo_PREMIUM = 20

  private goPremAd = {
    isAd: true,
    isSystemAd: true,
    username: StrHlp.APP_NAME,
    caption: StrHlp.GO_PREMIUM_AD_TEXT,
    showButton: true,
    buttonText: 'Go premium',
    redButton: false,
    userID: 'nwnqQ1LieaaofxvtAg9ojOBRIeD2',
    hasImage: false,
    imagePath: '',
    actionURL: '/settings/premium',
    photoID: '-',
    location: '',
    videoPath: '',
    videoThumbnail: '',
    ffurl: '',
    captionShort: StrHlp.GO_PREMIUM_AD_TEXT,
    timestamp: Date.now(),
    likeCount: 0,
    commentCount: 0,
    shareCount: 0,
    bookmarkCount: 0,
    wasEdited: false,
    videoRatio: 0,
    rePostID: null,
    negativetimestamp: -Date.now(),
    showMonthlyDonationProgress: false,
    monthlyDonationProgressPercent: 0,
    postID: ''
  }

  private makeDonationAd = {
    isAd: true,
    isSystemAd: true,
    username: StrHlp.APP_NAME,
    caption: StrHlp.DONATION_TEXT,
    showButton: true,
    redButton: true,
    buttonText: 'Make a donation',
    userID: 'nwnqQ1LieaaofxvtAg9ojOBRIeD2',
    hasImage: true,
    imagePath: StrHlp.DONATION_IMAGE_URL,
    actionURL: '/donate',
    photoID: '-',
    location: '',
    videoPath: '',
    videoThumbnail: '',
    ffurl: '',
    captionShort: StrHlp.DONATION_TEXT,
    timestamp: Date.now(),
    likeCount: 0,
    commentCount: 0,
    shareCount: 0,
    bookmarkCount: 0,
    wasEdited: false,
    videoRatio: 0,
    rePostID: null,
    negativetimestamp: -Date.now(),
    showMonthlyDonationProgress: false,
    monthlyDonationProgressPercent: 0,
    w: 1000,
    h: 1000,
    postID: ''
  }

  private makeDonationAd_MonthlyProgress = {
    isAd: true,
    isSystemAd: true,
    username: StrHlp.APP_NAME,
    caption: `Help keep ${StrHlp.APP_NAME} online. Click the bar to make a donation.`,
    showButton: false,
    redButton: false,
    buttonText: '',
    userID: 'nwnqQ1LieaaofxvtAg9ojOBRIeD2',
    hasImage: true,
    imagePath: '',
    actionURL: '/donate',
    photoID: '-',
    location: '',
    videoPath: '',
    videoThumbnail: '',
    ffurl: '',
    captionShort: StrHlp.DONATION_TEXT,
    timestamp: Date.now(),
    likeCount: 0,
    commentCount: 0,
    shareCount: 0,
    bookmarkCount: 0,
    wasEdited: false,
    videoRatio: 0,
    rePostID: null,
    negativetimestamp: -Date.now(),
    showMonthlyDonationProgress: true,
    monthlyDonationProgressPercent:
      MainComponent.percentGoalReached_DonationsMonthly,
    postID: ''
  }

  areYouPrem = false

  constructor(cacheService: CacheService) {
    // load prem
    cacheService
      .getVerified(AuthService.getUID()!)
      .pipe(take(1))
      .subscribe((verified) => {
        this.areYouPrem = verified

        if (this.areYouPrem) {
          this.count_Posts_Insert_SystemAd_Modulo =
            this.count_Posts_Insert_SystemAd_Modulo_PREMIUM
        }
      })
  }

  add(): any | null {
    this.count_Posts++
    this.count++

    if (this.count_Posts % this.count_Posts_Insert_SystemAd_Modulo == 0) {
      // insert system ad. determine which one (go premium or make donation)
      this.count_Inserted_SystemAds++

      let mod = this.count_Inserted_SystemAds % 3
      if (mod == 0 && (this.areYouPrem || !AuthService.isUserLoggedIn())) {
        mod = 1 // dont show premium for already premium users
        // and only to logged in users
      }

      if (mod == 0) {
        const ret = this.goPremAd
        ret.postID = `ad_post_${this.count}`

        return ret
      } else if (mod == 1) {
        // update percentage
        const ret = this.makeDonationAd_MonthlyProgress
        ret.monthlyDonationProgressPercent =
          MainComponent.percentGoalReached_DonationsMonthly
        ret.postID = `ad_post_${this.count}`

        return ret
      } else {
        const ret = this.makeDonationAd
        ret.postID = `ad_post_${this.count}`

        return ret
      }
    } else {
      return null
    }
  }
}
