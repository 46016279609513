import {
  Component,
  ElementRef,
  OnInit,
  TransferState,
  ViewChild,
  makeStateKey
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  child,
  get,
  getDatabase,
  limitToFirst,
  onValue,
  orderByKey,
  query,
  ref,
  set,
  startAt,
  increment
} from 'firebase/database'
import { HotToastService } from '@ngneat/hot-toast'
import { StrHlp } from '../../shared/services/StringGetter/getstring.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { HTMLFormattingService } from 'src/app/shared/services/formatting/html/htmlformatting.service'
import { MatDialog } from '@angular/material/dialog'
import { KeyHelperService } from 'src/app/shared/services/firebase/keyhelper.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { TimeLimitsService } from 'src/app/shared/services/timelimits/timelimits.service'
import { ReportComponent } from '../report/report.component'
import { EncodingService } from 'src/app/shared/services/encoding/encoding.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { StringUsus } from 'src/app/shared/services/string/stringusus.service'
import { LastseenService } from 'src/app/shared/services/firebase/lastseen.service'
import { FullscreenService } from 'src/app/shared/image/fullscreen.service'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { LoadingDialogComponent } from '../dialogs/loading-dialog/loading-dialog.component'
import { OnedialogserviceService } from 'src/app/shared/services/dialogs/onedialogservice.service'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { ThreebuttonsdialogService } from 'src/app/shared/services/dialogs/threebuttonsdialogservice.service'
import { MuteUsersService } from 'src/app/shared/services/muteusers.service'
import { FeeddataService } from 'src/app/shared/services/data/feeddata.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { type DatabaseReference } from '@firebase/database'
import { FollowersComponent } from './followers/followers.component'
import { EditprofileComponent } from '../editprofile/editprofile.component'
import PullToRefresh from 'pulltorefreshjs'
import { TimeService } from 'src/app/shared/services/time/time.service'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { Observable, ReplaySubject } from 'rxjs'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { IsBrowserService } from 'src/app/shared/services/ssr/isbrowser.service'
import { WaitForService } from 'src/app/shared/services/ssr/wait-for.service'
import { SITE_PROTOCOL } from 'src/app/shared/constants'

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: [
    './user.component.css',
    '../feed/feed.component.css',
    '../settings/settingspage/settings.component.css'
  ]
})
export class UserComponent implements OnInit {
  diffThresholdForReachedBottom: number = 100

  userID: any = null
  otherUserID: any = null
  username: any = null
  user: any = null
  bioExpanded = false

  isSeoSetUpAlready = false

  verified$?: Observable<boolean>

  historyExists = false

  areYouFollowing = false
  isPrivateProfile = false
  followingRequested = false
  hideContent = false
  hideContent_Determined = false

  youAreBlocked = false
  youHaveBlocked = false

  rtdb = ref(getDatabase())
  dbRTDB = getDatabase()

  pinnedPostIDs: any[] = []
  postLoadingInProgress = false
  latestKey: string | null = '!'

  profileHasNoPosts = false
  firstLoadingPostsRound = true
  isThisUserFollowingYou = false

  lastSeenString: string = ''
  userMuted = false
  isDialogOpened = false

  alwaysListenersRef_List: any[] = []

  // helper list. The "real" postList is in feeddataservice
  postIDs: any[] = []

  @ViewChild('bioEl') bioEl?: ElementRef
  bioCollapsedLineCount: number = 15
  bioLineCount: number = -1
  bioExceedsLineCount = false
  bioCollapsed = true
  bioCount_Helper_LastInnerText: string = ''

  isMobile = true

  @ViewChild('contentWrapper') contentWrapper?: ElementRef
  scrollIsAtTop = true // init

  errorOccurred = false
  errorMessage: string = 'Unknown error occurred'

  dataReceived: any = null

  receivePushNotifsRef: DatabaseReference | null = null
  subbedToPushNotifs = false
  showBigSubNotifsButton = false

  profileImageUrl = ''
  profileImageUrl_Fullscreen = ''
  profileImageisGif = false

  pullToRefresh: any

  private closeSubject: ReplaySubject<void> = new ReplaySubject(1)

  constructor(
    private route: ActivatedRoute,
    private toast: HotToastService,
    public numberFormatService: NumberFormatService,
    public htmlFormattingService: HTMLFormattingService,
    public dialog: MatDialog,
    public keyHelperService: KeyHelperService,
    public imgHlp: ImageLoadingService,
    public encodingService: EncodingService,
    public authService: AuthService,
    private lastseenService: LastseenService,
    private router: Router,
    public fullscreenHelper: FullscreenService,
    private oneButtonDialogService: OnedialogserviceService,
    private twobuttonsdialogService: TwobuttonsdialogService,
    private threebuttonsdialogService: ThreebuttonsdialogService,
    private muteUsersService: MuteUsersService,
    public feedDataService: FeeddataService,
    public strHlp: StrHlp,
    private seoHelper: SeoHelperService,
    private cacheService: CacheService,
    private isBrowserService: IsBrowserService
  ) {
    const currNav = this.router.getCurrentNavigation()
    this.dataReceived = currNav?.extras.state
  }

  /**
   * Moved away from RxJS observables style for async/await on nodejs ssr side
   *
   * TODO:
   * couple this with observables somehow since we need that if the @... changes (issue we had before
   * and fixed using this obs architecture)
   * Do it like so:
   * - on client init the observable stuff and only reload when data has changed
   * - remember: put this in there:       // things have changed: this.resetData();
   */
  ngOnInit(): void {
    this.userID = AuthService.getUID()

    this.user = this.route.snapshot.data['userData']

    if (this.user) {
      this.setUpUserData()
      this.checkIfAllowed()
    }

    // empty curr shown post list on init
    this.feedDataService.clearShownPostList()

    this.isMobile = SystemService.isMobile()
  }

  resetData() {
    this.feedDataService.clearShownPostList()
    this.scrollTop()

    this.otherUserID = null
    this.username = null
    this.user = null
    this.bioExpanded = false
    this.historyExists = false
    this.areYouFollowing = false
    this.isPrivateProfile = false
    this.followingRequested = false
    this.hideContent = false
    this.hideContent_Determined = false
    this.youAreBlocked = false
    this.youHaveBlocked = false
    this.pinnedPostIDs = []
    this.postLoadingInProgress = false
    this.latestKey = '!'
    this.profileHasNoPosts = false
    this.firstLoadingPostsRound = true
    this.isThisUserFollowingYou = false
    this.lastSeenString = ''
    this.userMuted = false
    this.alwaysListenersRef_List = []
    this.postIDs = []
    this.bioCollapsedLineCount = 15
    this.bioLineCount = -1
    this.bioExceedsLineCount = false
    this.bioCollapsed = true
    this.bioCount_Helper_LastInnerText = ''
    this.errorOccurred = false
    this.errorMessage = 'Unknown error occurred'
    this.dataReceived = null
    this.receivePushNotifsRef = null
    this.subbedToPushNotifs = false
    this.showBigSubNotifsButton = false
    this.profileImageUrl = ''
    this.profileImageUrl_Fullscreen = ''
    this.profileImageisGif = false
  }

  ngAfterViewInit(): void {
    // only on browser
    if (this.isBrowserService.isBrowser()) {
      this.setUpScrollListener()

      // pull to refresh
      this.pullToRefresh = PullToRefresh.init({
        mainElement: '#contentWrapper',
        shouldPullToRefresh: () => {
          return (
            this.contentWrapper?.nativeElement.scrollTop == 0 &&
            !this.postLoadingInProgress &&
            this.dialog.openDialogs.length == 0
          )
        },
        onRefresh: () => {
          // ssr-guarded
          if (typeof window === 'undefined') {
            return
          }
          //console.log("Pull to refresh triggered in a profile.");
          window.location.reload()
        }
      })
    }
  }

  ngOnDestroy() {
    // remove all listeners
    for (let i = 0; i < this.alwaysListenersRef_List.length; i++) {
      try {
        const unsubCallback = this.alwaysListenersRef_List[i]
        unsubCallback()
      } catch (error) {
        console.log(error)
      }
    }

    // destroy pull to refresh to prevent memory leaks
    if (this.pullToRefresh) {
      this.pullToRefresh.destroy()
    }

    this.closeSubject.next()
    this.closeSubject.complete()
  }

  checkIfAllowed(): void {
    // reset
    this.youHaveBlocked = false
    this.youAreBlocked = false

    if (this.otherUserID === this.userID) {
      // just continue
      //this.getUser();
    } else {
      if (this.userID === null || this.userID === 'null') {
        // treat as not-blocked
        this.loadFollowing()
      } else {
        // check if you are blocked by this user
        get(
          child(
            this.rtdb,
            `${StrHlp.CLOUD_PATH}/BlockedUsers/${this.otherUserID}/${this.userID}`
          )
        )
          .then((snapshot) => {
            if (snapshot.exists()) {
              // you are blocked
              // show hint, and not allowed to continue
              this.youAreBlocked = true
            } else {
              // check if you have blocked this user yourself
              get(
                child(
                  this.rtdb,
                  `${StrHlp.CLOUD_PATH}/BlockedUsers/${this.userID}/${this.otherUserID}`
                )
              )
                .then((snapshot) => {
                  if (snapshot.exists()) {
                    // you have blocked this user
                    // show hint, and not allowed to continue
                    this.youHaveBlocked = true
                  } else {
                    // Next, check if you are already following this user
                    this.loadFollowing()
                  }
                })
                .catch((error) => {
                  console.error(error)
                  this.toast.error('An error has occurred')
                })
            }
          })
          .catch((error) => {
            console.error(error)
            this.toast.error('An error has occurred')
          })
      }
    }
  }

  /**
   * TODO. change the way this all works
   */
  loadFollowing(): void {
    // check if this user has a private profile
    get(
      child(
        this.rtdb,
        `${StrHlp.CLOUD_PATH}/UserEigenschaftenspeicher/${this.otherUserID}/PrivateProfile`
      )
    )
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.isPrivateProfile = snapshot.val()
        }

        if (!this.authService.isLoggedIn()) {
          this.areYouFollowing = false

          // start loading now that we have all info need
          //this.getUser();
          // send visited notif
          this.sendVisitedNotif()
        } else {
          // check if you are following this user
          get(
            child(
              this.rtdb,
              `${StrHlp.CLOUD_PATH}/Following/${this.userID}/${this.otherUserID}`
            )
          )
            .then((snapshot) => {
              this.areYouFollowing = snapshot.exists()

              if (this.isPrivateProfile) {
                if (this.areYouFollowing) {
                  // start loading the actual profile
                  //this.getUser();
                  // send visited notif
                  this.sendVisitedNotif()
                } else {
                  // check if following is requested
                  get(
                    child(
                      this.rtdb,
                      `${StrHlp.CLOUD_PATH}/FollowRequests/${this.otherUserID}/${this.userID}`
                    )
                  )
                    .then((snapshot) => {
                      this.followingRequested = snapshot.exists()

                      // start loading now that we have all info need
                      //this.getUser();
                      // send visited notif
                      this.sendVisitedNotif()
                    })
                    .catch((error) => {
                      console.error(error)
                      this.toast.error('An error has occurred')
                    })
                }
              } else {
                // start loading the actual profile
                //this.getUser();
                // send visited notif
                this.sendVisitedNotif()
              }
            })
            .catch((error) => {
              console.error(error)
              this.toast.error('An error has occurred')
            })
        }
      })
      .catch((error) => {
        console.error(error)
        this.toast.error('An error has occurred')
      })

    // check only now
    this.userMuted = this.muteUsersService.isMuted(this.otherUserID)
  }

  async getUserIDByUsername(): Promise<string | null> {
    const usernamePath = this.username.replaceAll('.', '@')

    const path = `${StrHlp.CLOUD_PATH}/UsernameList/${usernamePath}`
    const dbChild = child(this.rtdb, path)

    try {
      const snapshot = await get(dbChild)

      if (snapshot.exists()) {
        return snapshot.val()
      } else {
        return null
      }
    } catch (error) {
      console.error(error)
      return null
    }
  }

  statistics(): void {
    if (this.userID) {
      const datestring = TimeService.getFormattedDateForStatistics()
      set(
        child(
          this.rtdb,
          `${StrHlp.CLOUD_PATH}/Statistics/${datestring}/Interaction/ProfilesSeenCount`
        ),
        increment(1)
      )
    }
  }

  setUpProfileImage() {
    const raw: string = this.user.profilePhoto
    //console.log("DraugasD", raw);

    if (raw.includes(EditprofileComponent.imageIsGif_Includes_Note)) {
      this.profileImageUrl = this.imgHlp.getCDN_URL(raw)
      this.profileImageUrl_Fullscreen = this.imgHlp.getCDN_URL(raw)
      this.profileImageisGif = true
    } else {
      this.profileImageUrl = this.imgHlp.do(this.user.profilePhoto, 250)
      this.profileImageUrl_Fullscreen = this.imgHlp.do(
        this.user.profilePhoto,
        1100
      )
      this.profileImageisGif = false
    }
  }

  setUpUserData() {
    //console.log("usrp","setUpUserData...","user:",this.user);

    this.username = this.user.username
    this.otherUserID = this.user.userID

    if (!this.isSeoSetUpAlready) {
      this.setUpSEO()
    }

    // might run twice, but no problem since its a very light operation
    this.setUpProfileImage()

    // client side only
    if (this.isBrowserService.isBrowser()) {
      // check if content is allowed to be shown
      this.hideContent_Determined = true
      this.hideContent = this.isPrivateProfile && !this.areYouFollowing

      this.receivePushNotifsRef = ref(
        this.dbRTDB,
        `${StrHlp.CLOUD_PATH}/NotificationSettings/PostSubbedNotifications/${this.otherUserID}/${this.userID}`
      )

      this.setUpPushNotifs()

      this.verified$ = this.cacheService.getVerified(this.otherUserID)

      if (!this.hideContent) {
        this.loadPinnedPosts()
      }

      this.loadLastSeen()

      this.loadIsFollowingYou()

      // update stats
      this.statistics()
    }
  }

  setUpSEO() {
    this.seoHelper.setProfile(
      this.user.bio,
      this.user.fullName,
      this.username ?? '',
      this.user.profilePhoto
    )
  }

  ngAfterViewChecked() {
    // Call countLinesBio after the view has been checked and the DOM has been updated
    this.countLinesBio()
  }

  countLinesBio() {
    // ssr guarded
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    if (this.bioEl) {
      const innerText = this.bioEl.nativeElement.innerText
      if (innerText !== this.bioCount_Helper_LastInnerText) {
        const count = this.bioEl.nativeElement.getClientRects().length
        this.bioLineCount = count
        this.bioExceedsLineCount =
          this.bioLineCount > this.bioCollapsedLineCount
        this.bioCount_Helper_LastInnerText = innerText
      }
    }
  }

  loadPinnedPosts() {
    const pinnedRef = child(
      this.rtdb,
      `${StrHlp.CLOUD_PATH}/UserSettings/${this.otherUserID}/PinnedPostList`
    )
    get(pinnedRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnapshot) => {
            const postID = childSnapshot.key
            this.postIDs.push(postID)
            this.pinnedPostIDs.push(postID)

            // load the corresponding post
            get(child(this.rtdb, `${StrHlp.CLOUD_PATH}/Photo/${postID}`))
              .then((snapshot) => {
                this.postLoadingInProgress = false

                if (snapshot.exists()) {
                  const post = snapshot.val()
                  post.postID = postID
                  post.pinned = true

                  this.feedDataService.appendNewPost(post, -1)

                  // load profile image and username
                  this.feedDataService.easyLoadPostInfo(
                    this.feedDataService.postList.length - 1
                  )
                }
              })
              .catch((error) => {
                this.postLoadingInProgress = false
                console.error(error)
              })
          })

          // pinned posts were loaded. Load the other posts normally.
          this.loadPosts()
        } else {
          // user has no pinned posts
          this.loadPosts()
        }
      })
      .catch(() => {
        // loading the pinned posts failed, continue normally loading the posts
        this.loadPosts()
      })
  }

  loadIsFollowingYou() {
    const refFollowing = child(
      this.rtdb,
      `${StrHlp.CLOUD_PATH}/Following/${this.otherUserID}/${this.userID}`
    )
    get(refFollowing).then((snapshot) => {
      this.isThisUserFollowingYou = snapshot.exists()
    })
  }

  loadLastSeen() {
    // always listener
    const refOnOff = ref(
      this.dbRTDB,
      `${StrHlp.CLOUD_PATH}/onlinestatus/${this.otherUserID}`
    )
    const unsubCallback = onValue(refOnOff, async (snapShotOnline) => {
      let isOnline = false
      if (snapShotOnline.exists()) {
        isOnline = snapShotOnline.val()
      }

      // on/off already determined
      const alreadyDetVal = isOnline ? 1 : 2
      this.lastSeenString = await this.lastseenService.loadLastSeenString(
        this.otherUserID,
        alreadyDetVal
      )
    })
    this.alwaysListenersRef_List.push(unsubCallback)
  }

  async loadPosts(): Promise<void> {
    // we need this code because it can be triggered by scrolling
    if (!this.hideContent_Determined || this.hideContent) {
      return
    }

    if (this.postLoadingInProgress) {
      return
    }
    this.postLoadingInProgress = true

    //console.log('loadPosts()...');

    const countLoadItems = 10
    let counterLoadedPosts = 0

    //console.log(`latestKey: ${this.latestKey}`);
    const q = query(
      child(this.rtdb, `${StrHlp.CLOUD_PATH}/UserPostList/${this.otherUserID}`),
      orderByKey(),
      startAt(this.latestKey),
      limitToFirst(countLoadItems)
    )

    get(q)
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnapshot) => {
            const keyRaw = childSnapshot.key
            const val = childSnapshot.val()
            let postID = keyRaw

            if (String(val) !== 'true') {
              // means:
              // the key is the rawKey, and the val is the postID (happens for posts from private profiles and NSFW posts)
              postID = val
            }

            // Dont load posts more than once. Important check in particular because of pinned posts
            if (!this.pinnedPostIDs.includes(postID)) {
              this.postIDs.push(postID)

              // Update latest score:
              // Aappend ~ because 'xxx!' is the smallest lexicographically larger string than 'xxx'.
              // By doing this, we will still catch all posts, but never repeat the latest loaded one upon the next loading.
              this.latestKey = keyRaw + '!'

              //console.log("latestKey: "  + this.latestKey);

              // load the corresponding post
              get(child(this.rtdb, `${StrHlp.CLOUD_PATH}/Photo/${postID}`))
                .then((snapshot) => {
                  this.postLoadingInProgress = false

                  if (snapshot.exists()) {
                    const post = snapshot.val()
                    post.postID = postID

                    this.feedDataService.appendNewPost(post, -1)

                    // load profile image and username
                    this.feedDataService.easyLoadPostInfo(
                      this.feedDataService.postList.length - 1
                    )
                  }

                  counterLoadedPosts++
                })
                .catch((error) => {
                  console.error(error)
                  counterLoadedPosts++
                  this.postLoadingInProgress = false
                })
            }
          })
        } else {
          if (this.feedDataService.postList.length == 0) {
            this.profileHasNoPosts = true
          }
          this.postLoadingInProgress = false
        }
      })
      .catch((error) => {
        console.error(error)
        this.toast.error('Loading posts has failed')
        this.postLoadingInProgress = false
      })
  }

  async copyLink() {
    try {
      const link = `${SITE_PROTOCOL}://${StrHlp.APP_URL}/@${this.username}`
      await navigator.clipboard.writeText(link)
      this.toast.success('Copied to clipboard')
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  askForBlockUser(): void {
    if (!this.authService.isLoggedIn()) {
      this.authService.showLoginDialog()
      return
    }

    this.twobuttonsdialogService.show(
      'Block user',
      'Do you want to block this user? The user will no longer be able to send you private messages, comment on your posts, or visit your profile.',
      () => {
        // nothing
      },
      () => {
        // call cloud function
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        const functions = getFunctions()
        const blockUser = httpsCallable(functions, 'blockUser')

        blockUser({
          hubname: StrHlp.CLOUD_PATH,
          otherUserID: this.otherUserID
        })
          .then(() => {
            loadingDialogRef.close()
            this.toast.success('User blocked')

            // reload UI
            // TODO
            //this.getUserID();
          })
          .catch((error) => {
            loadingDialogRef.close()
            console.log(error)

            // show error message via dialog
            this.oneButtonDialogService.show('Failed', error.message)
          })
      },
      'Cancel',
      'Block'
    )
  }

  unblockUser(): void {
    this.twobuttonsdialogService.show(
      'Unblock',
      'Do you want to unblock this user?',
      () => {
        // nothing
      },
      () => {
        // call cloud function
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        const functions = getFunctions()
        const unblockUser = httpsCallable(functions, 'unblockUser')

        unblockUser({
          hubname: StrHlp.CLOUD_PATH,
          otherUserID: this.otherUserID
        })
          .then(() => {
            loadingDialogRef.close()
            this.toast.success('Unblocked')

            // reload UI
            // TODO: RELOAD
          })
          .catch((error) => {
            loadingDialogRef.close()
            console.log(error)

            // show error message via dialog
            this.oneButtonDialogService.show('Failed', error.message)
          })
      },
      'Cancel',
      'Unblock'
    )
  }

  startFollowing(callback: (() => void) | null = null): void {
    // call cloud func
    const functions = getFunctions()
    const functionToCall = httpsCallable(functions, 'followUser')
    functionToCall({
      hubname: StrHlp.CLOUD_PATH,
      otherUserID: this.otherUserID
    })
      .then(() => {
        if (callback === null) {
          this.showBigSubNotifsButton = true
        } else {
          callback()
        }
      })
      .catch((error) => {
        console.log(error)
        this.toast.error('Subscribing to this user failed')
      })

    // UI immediately for better UX
    this.areYouFollowing = true
    this.user.followerCount++
  }

  stopFollowing(): void {
    this.twobuttonsdialogService.show(
      'Unfollow user',
      'Do you want to stop following this user?',
      () => {
        // nothing
      },
      () => {
        // call cloud func
        const functions = getFunctions()
        const functionToCall = httpsCallable(functions, 'unfollowUser')
        functionToCall({
          hubname: StrHlp.CLOUD_PATH,
          otherUserID: this.otherUserID
        })
          .then(() => {})
          .catch((error) => {
            console.log(error)
            this.toast.error('Unsubscribing to this user failed')
          })

        // UI immediately for better UX
        this.areYouFollowing = false
        this.user.followerCount--
      },
      'Cancel',
      'Unfollow'
    )
  }

  followButtonClick(): void {
    if (!this.authService.isLoggedIn()) {
      this.authService.showLoginDialog()
      return
    }

    if (!TimeLimitsService.isAllowed_Session('un-follow-request-user', 1500)) {
      this.toast.error("You're too fast")
      return
    }

    if (this.areYouFollowing) {
      this.stopFollowing()
    } else if (this.followingRequested) {
      this.stopFollowRequest()
    } else {
      if (this.isPrivateProfile) {
        // send request
        this.requestFollowing()
      } else {
        // start following
        this.startFollowing()
      }
    }
  }

  requestFollowing() {
    // call cloud func
    const functions = getFunctions()
    const functionToCall = httpsCallable(
      functions,
      'requestUnrequestFollowUser'
    )
    functionToCall({
      hubname: StrHlp.CLOUD_PATH,
      otherUserID: this.otherUserID
    })
      .then(() => {})
      .catch((error) => {
        console.log(error)
        this.toast.error('Subscribing to this user failed')
      })

    // UI immediately for better UX
    this.followingRequested = true
  }

  stopFollowRequest() {
    // call cloud func
    const functions = getFunctions()
    const requestUnrequestFollowUser = httpsCallable(
      functions,
      'requestUnrequestFollowUser'
    )
    requestUnrequestFollowUser({
      hubname: StrHlp.CLOUD_PATH,
      otherUserID: this.otherUserID
    })
      .then(() => {
        //console.log("Worked");
      })
      .catch((error) => {
        console.log(error)
        //this.toast.error("Unsubscribing to this user failed");
      })

    // UI immediately for better UX
    this.followingRequested = false
  }

  reportUser(): void {
    if (!this.authService.isLoggedIn()) {
      this.authService.showLoginDialog()
      return
    }

    const data = {
      reportType: 1,
      reportID: this.otherUserID
    }

    this.dialog.open(ReportComponent, {
      panelClass: 'report-dialog',
      autoFocus: false,
      data: data
    })
  }

  openDMs(): void {
    let isMeUser1 = false
    if (StringUsus.CompareTo_Java(this.userID, this.otherUserID) < 0) {
      isMeUser1 = true
    }
    let chatID = ''
    if (isMeUser1) {
      chatID = this.userID + '_' + this.otherUserID
    } else {
      chatID = this.otherUserID + '_' + this.userID
    }

    const data = {
      chatID: chatID,
      name: this.username,
      image: this.user.profilePhoto,
      isGroup: false,
      isPrivate: true,
      otherUserID: this.otherUserID,
      isMeUser1: isMeUser1,
      newMessagesCount: 0,

      // chatOK does not work here
      chatOK1: true,
      chatOK2: true
    }

    this.router.navigate(['message'], { state: data })
  }

  openProfileImageFullscreen(): void {
    if (this.user.profilePhoto) {
      if (this.profileImageisGif) {
        this.fullscreenHelper.open(
          '',
          this.profileImageUrl_Fullscreen,
          `@${this.username}`,
          ''
        )
      } else {
        this.fullscreenHelper.open(
          this.profileImageUrl_Fullscreen,
          '',
          `@${this.username}`,
          ''
        )
      }
    }
  }

  showMuteDialog() {
    const mute24h = () => {
      this.muteUsersService.muteUser_24hours(this.otherUserID)
      this.userMuted = true
    }
    const mutePerma = () => {
      this.muteUsersService.muteUser(this.otherUserID)
      this.userMuted = true
    }

    this.threebuttonsdialogService.show(
      'Mute user',
      "Do you want to mute this user? You will no longer see any activity of this user. You will no longer see messages of this user in chat rooms. \n\nNote that this user can stil write private messages to you. If you don't want that, you need to block the user instead.",
      mute24h,
      mutePerma,
      () => {},
      'Mute user for 24 hours',
      'Mure user permanently',
      'Cancel'
    )
  }

  showUnmuteDialog() {
    const action = () => {
      this.muteUsersService.unmuteUser(this.otherUserID, true)
      this.muteUsersService.unmuteUser(this.otherUserID, false)
      this.userMuted = false
    }
    this.twobuttonsdialogService.show(
      'Unmute user',
      'Do you want to unmute this user?',
      () => {},
      action,
      'Cancel',
      'Unmute'
    )
  }

  showTotalPostsDialog() {
    this.oneButtonDialogService.show(
      'Total posts',
      `This user has a total of ${this.numberFormatService.numberWithCommas(
        this.user.postCount
      )} posts.`
    )
  }

  openFollowersPage() {
    if (this.isPrivateProfile && !this.areYouFollowing) {
      return
    }

    const data = {
      isFollowing: false,
      userID: this.otherUserID,
      badgeNumber: this.user.followerCount
    }
    this.dialog.open(FollowersComponent, { data: data })
  }

  openFollowingsPage() {
    if (this.isPrivateProfile && !this.areYouFollowing) {
      return
    }

    const data = {
      isFollowing: true,
      userID: this.otherUserID,
      badgeNumber: this.user.followingCount
    }
    this.dialog.open(FollowersComponent, { data: data })
  }

  /**
   * Only needed on mobile, since on mobile, its an "mb-page" and therefore no longer
   * connected to the main component and hence we cannot use the scroll listener from main.
   */
  setUpScrollListener() {
    this.contentWrapper?.nativeElement.addEventListener('scroll', () => {
      const scrollTop = this.contentWrapper!.nativeElement.scrollTop
      const scrollHeight = this.contentWrapper!.nativeElement.scrollHeight
      const clientHeight = this.contentWrapper!.nativeElement.clientHeight

      const diffToBottom = scrollHeight - scrollTop - clientHeight
      this.scrollIsAtTop = scrollTop == 0

      if (diffToBottom <= 200) {
        // load new items
        this.loadPosts()
      }
    })
  }

  async copyUsername() {
    if (this.username && this.isMobile) {
      try {
        SystemService.hapticsImpactMedium()

        await navigator.clipboard.writeText(this.username)
        this.toast.success('Copied to clipboard')
      } catch (err) {
        console.error('Failed to copy: ', err)
      }
    }
  }

  openEditProfile() {
    if (this.isMobile) {
      this.router.navigate(['mb-settings/editprofile'])
    } else {
      this.router.navigate(['settings/editprofile'])
    }
  }

  scrollTop() {
    if (this.contentWrapper) {
      this.contentWrapper.nativeElement.scrollTop = 0
    }
  }

  sendVisitedNotif() {
    // ssr-guarded
    if (!this.isBrowserService.isBrowser()) {
      return
    }
    if (!this.authService.isLoggedIn()) {
      return
    }

    if (this.otherUserID === this.userID) {
      return
    }

    const functions = getFunctions()
    const functionToCall = httpsCallable(functions, 'visitProfile')
    functionToCall({
      hubname: StrHlp.CLOUD_PATH,
      profileID: this.otherUserID
    }).catch((error) => {
      console.log(error)
    })
  }

  setUpPushNotifs() {
    // always listener on the setting
    if (this.receivePushNotifsRef !== null) {
      const unsubCallback = onValue(this.receivePushNotifsRef, (snapshot) => {
        this.subbedToPushNotifs = snapshot.exists()
      })
      this.alwaysListenersRef_List.push(unsubCallback)
    }
  }

  notifSettingClick() {
    if (this.subbedToPushNotifs) {
      this.disablePushNotifsDialog()
    } else {
      this.enablePushNotifsDialog()
    }
  }

  enablePushNotifsDialog() {
    if (this.areYouFollowing) {
      this.twobuttonsdialogService.show(
        'Receive notifications',
        'Do you want to receive notifications when this user makes a post?',
        () => {},
        () => {
          this.enablePushNotifs()
        },
        'Cancel',
        'Enable'
      )
    } else {
      this.twobuttonsdialogService.show(
        'Receive notifications',
        'Do you want to follow this user and receive notifications when this user makes a post?',
        () => {},
        () => {
          this.startFollowing(() => {
            this.enablePushNotifs()
          })
        },
        'Cancel',
        'Yes'
      )
    }
  }

  disablePushNotifs() {
    const loadRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    const functions = getFunctions()
    const cloudFunc = httpsCallable(functions, 'removeSubPushNotifications')

    cloudFunc({
      hubname: StrHlp.CLOUD_PATH,
      otherUserID: this.otherUserID
    })
      .then(() => {
        loadRef.close()
        this.showBigSubNotifsButton = true
        this.toast.success('Disabled')
      })
      .catch((error) => {
        loadRef.close()
        console.log(error)
        this.toast.error('Error occurred')
      })
  }

  enablePushNotifs() {
    if (!SystemService.pushNotifsCapable) {
      SystemService.setUpPushNotifs(true)
      return
    }

    const loadRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    const functions = getFunctions()
    const subToPushNotifications = httpsCallable(
      functions,
      'subToPushNotifications'
    )
    subToPushNotifications({
      hubname: StrHlp.CLOUD_PATH,
      otherUserID: this.otherUserID
    })
      .then(() => {
        loadRef.close()
        this.showBigSubNotifsButton = false
        this.toast.success('Enabled')
      })
      .catch((error) => {
        loadRef.close()
        console.log(error)
        this.toast.error('Error occurred')
      })
  }

  disablePushNotifsDialog() {
    this.twobuttonsdialogService.show(
      'Disable notifications',
      'Do you want to stop receiving notifications when this user makes a post?',
      () => {},
      () => {
        this.disablePushNotifs()
      },
      'Cancel',
      'Disable'
    )
  }
}
