<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Active subscriptions</h1>
  </header>

  <main class="fh-main content">
    @for (item of itemList; track item; let i = $index) {
      <div
        class="item subscription-item-wrapper"
        >
        <div class="subcr-start-wrapper">
          <h2 class="subscr-name">
            <strong>
              {{ item.displayName }}
            </strong>
          </h2>
          <p>
            {{ item.priceString }}
            <span class="simple_divider">•</span>
            {{ item.monthly ? 'Monthly' : 'Yearly' }}
          </p>
        </div>
        <button
          class="subtle-button removeButton cancel-subr-button"
          (click)="cancelSubscription(item, i)"
          >
          Cancel subscription
        </button>
      </div>
    }

    @if (isLoading) {
      <app-loadingcircle> </app-loadingcircle>
    }

    @if (empty) {
      <app-emptyhinttemplate> </app-emptyhinttemplate>
    }
  </main>
</div>
