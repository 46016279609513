import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-inputdialog',
  templateUrl: './inputdialog.component.html',
  styleUrls: [
    './inputdialog.component.css',
    '../../../stylesheets/dialogs/dialogstylesheet.css'
  ]
})
export class InputdialogComponent {
  hint: string = ''
  title: string = ''
  textPreInsert: string = ''

  actionButtonName: string = ''
  cancelButtonName: string = ''

  maxTextLength: number = 1000 // "default"

  textEntered: string = ''

  actionCallback: ((text: string) => any) | null = null
  cancelCallback: ((text: string) => any) | null = null

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<InputdialogComponent>
  ) {
    if (data.hint !== undefined) {
      this.hint = data.hint
      this.textPreInsert = data.textPreInsert
      this.actionButtonName = data.actionButtonName
      this.cancelButtonName = data.cancelButtonName
      this.maxTextLength = data.maxTextLength
      this.title = data.title

      this.cancelCallback = data.cancelCallback
      this.actionCallback = data.actionCallback
    }

    this.textEntered = this.textPreInsert
  }

  action(): void {
    if (this.actionCallback !== null) {
      this.actionCallback(this.textEntered)
    }
    this.dialogRef.close()
  }

  cancel(): void {
    if (this.cancelCallback !== null) {
      this.cancelCallback(this.textEntered)
    }
    this.dialogRef.close()
  }
}
