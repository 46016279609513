<section class="wrapper">
  <div class="nsfw-banner" #nsfwbanner>
    <div class="nsfw-title">Age verification</div>

    <div class="nsfw-desc">
      {{ appName }} is an adult community that contains age-restricted content.
      You must be 18 or older to participate.
    </div>

    <div class="consent-button-nsfw" (click)="agreeToNSFW()">
      I am 18 or older - Enter
    </div>
  </div>
</section>
