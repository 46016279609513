<div class="choosing-bar bg-choosing-bar">
  @if (isLoggedIn) {
    <app-choosing-bar-text-item
      class="choosing-bar-child"
      [isSelected]="selectedMode == 0"
      text="Chats"
      [handleClickInput]="onSelectChatsMode"
      ariaLabel="Your chats"
    />
  }

  <app-choosing-bar-text-item
    class="choosing-bar-child"
    [isSelected]="selectedMode == 1"
    text="Groups"
    [handleClickInput]="onSelectGroupsMode"
    ariaLabel="Chat groups"
  />
</div>
