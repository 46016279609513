import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { FullscreenimageComponent } from 'src/app/components/fullscreenimage/fullscreenimage.component'

@Injectable({
  providedIn: 'root'
})
export class FullscreenService {
  constructor(public dialog: MatDialog) {}

  open(
    imageURL: string,
    gifURL: string,
    title: string = '',
    desc: string = ''
  ) {
    this.dialog.open(FullscreenimageComponent, {
      panelClass: 'no-max-width',
      maxWidth: '100vw',
      //maxHeight: '90vw',
      height: '100%',
      width: '100%',
      data: {
        imageURL: imageURL,
        gifURL: gifURL,
        title: title,
        desc: desc
      },
      backdropClass: 'bdrop'
    })
  }
}
