import { inject } from '@angular/core'
import { ResolveFn } from '@angular/router'
import { UserLoaderService } from '../shared/data-loaders/user-loader/user-loader.service'

export const UserResolver: ResolveFn<any> = (route, state) => {
  const dataService = inject(UserLoaderService)

  let userId: string | null = null
  let username: string | null = null

  const paramMap = route.paramMap
  const url = route.url

  if (paramMap.has('userID')) {
    userId = paramMap.get('userID')
  } else {
    // username should be provided then
    if (paramMap.has('username')) {
      username = paramMap.get('username')?.toLocaleLowerCase() ?? null
    } else {
      // Must be "@..." format
      username = url[0].path.substring(1)
    }
  }
  return dataService.loadUser(userId, username) ?? null
}
