
import { Component, DestroyRef, OnInit, inject } from '@angular/core'
import { SearchbarComponent } from '../../templates/searchbar/searchbar.component'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MainBrandingComponent } from '../../templates/branding/main-branding/main-branding.component'
import { MainStateService } from 'src/app/shared/services/main/main-state.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { MainSideNavigationComponent } from '../../navigation/main-side-navigation/main-side-navigation.component'
import { RouterModule } from '@angular/router'

@Component({
  selector: 'app-main-header',
  standalone: true,
  imports: [
    SearchbarComponent,
    MatTooltipModule,
    MainBrandingComponent,
    MainSideNavigationComponent,
    RouterModule
],
  templateUrl: './main-header.component.html',
  styleUrl: './main-header.component.css'
})
export class MainHeaderComponent implements OnInit {
  selectedTab = 0
  isLoggedIn: boolean

  private destroyRef = inject(DestroyRef)

  constructor(
    private authService: AuthService,
    private mainStateService: MainStateService
  ) {
    this.isLoggedIn = this.authService.isLoggedIn()
  }

  ngOnInit(): void {
    this.setUpNavBarLogic()
  }

  setUpNavBarLogic() {
    this.mainStateService.currentlySelectedNavTabSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((newTabNumber) => (this.selectedTab = newTabNumber))
  }
}
