<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Go Premium</h1>
  </header>

  <main class="fh-main content">
    @if (getPremiumDetermined() && getIsPremium()) {
      <div
        id="alreadyPremiumWrapper"
        >
        <img id="alreadyPremiumCheck" src="/assets/check.png" alt="check" />
        <div class="alreadyPremiumTextWrapper">
          <p id="youArePremiumText">You are a premium user</p>
          <p id="youArePremiumExpires">
            Premium expires: {{ getPremiumLastsUntilAsString() }}
          </p>
        </div>
      </div>
    }

    @if (!getPremiumDetermined() || !getIsPremium()) {
      <div
        id="advantages-wrapper"
        >
        <div id="branding-wrapper">
          <div class="flex-align-items-middle">
            <img
              id="premium-benefit-verified"
              src="/assets/ic_verified_blue.png"
              alt="premium checkmark"
              />
            <img
              id="branding-logo"
              src="/assets/applogo_transparent.png"
              alt="{{ strHlp.getAppName() }} logo"
              />
          </div>
          <div>
            <div class="flex-align-items-middle">
              <span id="branding-text">{{ strHlp.getAppName() }}</span>
              <span id="branding-text-premium">Premium</span>
            </div>
          </div>
        </div>
        <hr class="inline-divider" />
        <div class="premium-desc">
          <p class="premium-benefit-desc">
            • <b>No ads.</b><br />
            Interrupted browsing and chatting.
          </p>
          <p class="premium-benefit-desc">
            • <b>Verified user.</b><br />
            Blue check mark next to your name.
          </p>
          <p class="premium-benefit-desc">
            • <b>Unlimited downloads.</b><br />Full offline access to all content.
          </p>
          <p class="premium-benefit-desc">
            • <b>Double reputation.</b><br />When you get reputation, you get
            double.
          </p>
        </div>
        <hr class="inline-divider" />
        <div id="pay-button-wrapper">
          <button class="payment-button" (click)="initiateCheckout(false)">
            <p>
              <strong>{{ 3.99 | currency }}</strong>
            </p>
            <p class="payment-frequency-text">per month</p>
          </button>
          <button class="payment-button" (click)="initiateCheckout(true)">
            <p>
              <strong>{{ 39.0 | currency }}</strong>
            </p>
            <p class="payment-frequency-text">per year</p>
          </button>
        </div>
        <hr class="inline-divider" />
      </div>
    }
  </main>
</div>
